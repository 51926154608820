import React, { useEffect, useState } from "react";
import Network from "../../utils/Network";
import {
  ArenaQuestResponse,
  ArenaRewardDetails,
} from "../../types/pages/MarketPlace";
import { Assets } from "../../constants/assets";
import Button from "../../components/Button";
import { CustomIcon } from "../../components/Icons";
import { AnimatePresence, motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ThousandsSeparator from "../../utils/thousands-separator";
import Dialog from "../../components/Dialog";
import CircularProgress from "../../components/CircularProgress";
import useToast from "../../hooks/useToast";
import useQuests from "../../hooks/useQuests";
import LevelBadge from "../../components/LevelBadge";
import useArenaShop from "../../hooks/useArenaShop";
import CountDown from "react-countdown";
type Props = {};

const requiredActions = {
  33: {
    icon: Assets.icons.bloodStone,
    title: "Blood Gem",
  },
  34: {
    icon: "/assets/images/icons/cleg.png",
    title: "Cleg",
  },
};

const ArenaShop = (props: Props) => {
  const axios = new Network();
  const { data, loading, getShopData } = useArenaShop();
  const [actionLoading, setActionLoading] = useState(false);
  const userInfo = useSelector((state: any) => state.userInfo);
  const { getMe } = useQuests();
  const openToast = useToast();
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    submit: null,
    title: "",
    msg: "",
  });

  const handleClaimReward = async (id: number) => {
    setActionLoading(true);
    const response = await axios.CallApi({
      url: "/quest/arenashops/claim",
      body: {
        QuestId: id,
      },
    });
    openToast(response);
    if (response.ok) {
      getShopData();
      getMe();
      setConfirmDialog((prev) => ({ ...prev, open: false }));
    }
    setActionLoading(false);
  };

  useEffect(() => {
    getShopData();
  }, []);

  if (loading)
    return (
      <div
        style={{ minHeight: 500 }}
        className="p-relative d-flex align-items-center justify-content-center"
      >
        <CircularProgress />
      </div>
    );
  const getTimer = (startTime: string, endTime: string) => {
    if (!startTime || !endTime) {
      return null;
    }
    const start = new Date(startTime);
    const end = new Date(endTime);
    if (Date.now() < start.getTime()) {
      return {time:start,title:'Starts in'};
    }
    if (Date.now() < end.getTime()) {
      return {time:end,title:'Ends in'};
    }
    if (Date.now() > end.getTime()) {
      return null;
    }
  };
  return (
    <div>
      <motion.div
        className="p-relative"
        initial={isMobile ? undefined : { opacity: 0, y: -110, zIndex: 10 }}
        animate={{ opacity: 1, y: 0, zIndex: 10 }}
        transition={{ duration: 0.3, delay: 0, zIndex: 10 }}
        exit={{ opacity: 0, y: 130 }}
      >
        <div
          style={{ minHeight: 70, marginTop: -20 }}
          className="tab-bar gap-2 pt-3 d-flex mb-4 justify-content-between align-items-center"
        >
          <div className="d-flex gap-2  align-items-center">
            <CustomIcon icon="info" width={20} height={20} />
            You can earn "Bloodgems" by participating in Arena battles.
          </div>
          <div className="d-flex align-items-center">
            <div className="status-frame d-flex align-items-center ms-3 ">
              <img
                width={35}
                height={40}
                style={{ marginLeft: -10 }}
                className="ms-n2"
                src={Assets.icons.energy}
              />
              <span
                className="d-block"
                style={{ fontSize: 18, marginRight: -15 }}
              >
                <CountUp
                  duration={1}
                  decimals={0}
                  separator=","
                  end={userInfo?.totalEnergy}
                />
              </span>
              <img
                width={30}
                height={30}
                className="opacity-0"
                src="/assets/images/buttons/add-button.png"
              />
            </div>
            <div className="status-frame d-flex align-items-center ms-3 ">
              <img
                width={35}
                height={40}
                style={{ marginLeft: -5 }}
                className="ms-n2"
                src={Assets.icons.bloodStone}
              />
              <span
                className="d-block"
                style={{ fontSize: 18, marginRight: -15 }}
              >
                <CountUp
                  duration={1}
                  decimals={0}
                  separator=","
                  end={userInfo?.totalBloodGem}
                />
              </span>
              <img
                width={20}
                height={30}
                className="opacity-0"
                src="/assets/images/buttons/add-button.png"
              />
            </div>
          </div>
        </div>
      </motion.div>
      <div style={{ maxWidth: 1170 }} className="row mx-auto mt-5">
        {data?.rewards.map((reward, index) => {
          const time = getTimer(reward.fromDate	, reward.toDate);
          return (
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div
                key={index}
                style={{ minHeight: 300 }}
                className="land-box py-4 d-flex   flex-column justify-content-between"
              >
                {time && (
                  <div style={{top:'32%',left:8,borderRadius:'0 10px 10px 0',background:'rgba(0,0,0,0.2)'}} className="position-absolute w-fit  p-1 d-flex align-items-center justify-content-center pt-1 light-yellow fs-med">
                    <CountDown
                      date={time.time}
                      renderer={({formatted}) => (
                        <div className="d-flex flex-column align-items-center justify-content-center fs-small">
                          <span className="fs-small bold">{time.title}</span>
                          <span>{formatted.days}<span className="fs-tiny">d</span></span>
                          <span>{formatted.hours}<span className="fs-tiny">h</span></span>
                          <span>{formatted.minutes}<span className="fs-tiny">m</span></span>
                          <span>{formatted.seconds}<span className="fs-tiny">s</span></span>
                         
                        </div>
                      )}
                    />
                  </div>
                )}
                {reward.availableQuantity > 0 && (
                  <div
                    className="position-absolute  ps-1 d-flex align-items-center justify-content-center pt-1 light-yellow fs-med bold"
                    style={{
                      top: -5,
                      left: 10,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: 77,
                      height: 44,
                      backgroundImage:
                        "url('/assets/images/badges/shop-badge.png')",
                    }}
                  >
                    {reward.availableQuantity} Left
                  </div>
                )}
                {reward.rewards[0]?.level && (
                  <div
                    style={{
                      width: 35,
                      height: 35,
                      right: "13px",
                      top: "5px",
                      marginInline: "0px !important",
                    }}
                    className="position-absolute"
                  >
                    <LevelBadge
                      level={reward.rewards[0].level}
                      style={{ zIndex: 10 }}
                      className="position-absolute  fs-big bold mx-0"
                    />
                  </div>
                )}
                <div
                  style={{ zIndex: 4 }}
                  className="lb-body mt-2 position-relative  d-flex flex-column align-items-center justify-content-around flex-1"
                >
                  <div
                    style={{ fontSize: 26, maxWidth: "100%" }}
                    className="light-yellow px-2 text-truncate  mb-2"
                  >
                    {reward.title}
                  </div>
                  <img
                    style={{ maxHeight: 150, maxWidth: "100%" }}
                    src={reward.icon}
                    alt={reward.title}
                  />
                  <div
                    className="text-center light-yellow"
                    dangerouslySetInnerHTML={{ __html: reward.description }}
                  ></div>
                </div>
                <div className="lb-button d-flex justify-content-center">
                  <Button
                    disabled={!reward.isEnabled}
                    onClick={() => {
                      setConfirmDialog({
                        open: true,
                        msg: "Are sure about claim this reward?",
                        submit: () => handleClaimReward(reward.id),
                        title: "Confirm",
                      });
                    }}
                    className="bg-100 gap-2"
                    variant="default"
                  >
                    <img
                      src={requiredActions[reward.requiredAction]?.icon}
                      width={20}
                      height={20}
                    />
                    {ThousandsSeparator(reward.requiredActionValue)}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Dialog
        loading={actionLoading}
        title={confirmDialog?.title}
        closeLabel="No"
        submitLabel="Yes"
        onSubmit={confirmDialog?.submit}
        open={confirmDialog?.open}
        onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
      >
        {confirmDialog?.msg}
      </Dialog>
    </div>
  );
};

export default ArenaShop;
