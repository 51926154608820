import { actionTypes } from './action';

export const tourDefaultState = {
    isStared: false,
    isDone: false,
    pages: {
        home: false,
        referral: false,
        marketplace: false,
        profile: false,
        adventure: false,
        treasure: false,
        preFight: false,
        myLands: false,
    }
};

export const TourReducer = (state = tourDefaultState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_TOUR:
            return action.payload;
        default:
            return state;
    }
};
