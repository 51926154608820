import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import GuildTabs from "./GuildTabs";
import { motion } from "framer-motion";
import Explore from "./sections/Explore";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import Build from "./sections/Build";
import { useDispatch, useSelector } from "react-redux";
import GuildDetail from "./sections/GuildDetail";
import UpdateGuild from "./sections/UpdateGuild";
import Network, { BaseURL } from "../../utils/Network";
import AdventureHistory from "../Adventure";
import { setUserInfo } from "../../redux/user/action";
type Props = {};

export type GuildTabType = {
  title: string;
  icon: string;
  value: string;
  component: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
};

const GuildPage = (props: Props) => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const id = searchParameters.get("id");
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector((state: any) => state.userInfo);
  const isGuildAvailable = Boolean(userInfo?.guild?.id);
  const axios = new Network();
  const [images, setImages] = useState({ images: [""], banners: [""] });
  const handleGetImages = async () => {
    const response = await axios.CallApi({
      url: "/guild/images",
      method: "get",
    });
    setImages(response.data);
  };
  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };
  const changeQuery = (val: number | string | boolean, name: string) => {
    val ? searchParameters.set(name, val + "") : searchParameters.delete(name);
    setSearchParameters(searchParameters);
  };
  useEffect(() => {
    getMe();
    handleGetImages();
  }, []);

  const Tabs: GuildTabType[] = [
    {
      title: "Explore",
      icon: "/assets/images/icons/scout.png",
      value: "explore",
      // component: <Build />,
      component: <Explore />,
      disabled: false,
    },
    {
      title: "My Guild",
      icon: "/assets/images/icons/castle.png",
      value: "my-guild",
      component: <GuildDetail id={userInfo?.guild?.id} />,
      disabled: !isGuildAvailable,
    },
    {
      title: "Raids",
      icon: "/assets/images/icons/active-raid.png",
      value: "raids",
      component: <AdventureHistory id={userInfo?.guild?.id} />,
      disabled: !isGuildAvailable,
    },

    {
      title: "Guild",
      icon: "/assets/images/icons/scout.png",
      value: "guild",
      hidden: true,
      component: id && <GuildDetail id={+id!} />,
    },
    {
      title: "Build",
      icon: "/assets/images/icons/scout.png",
      value: "create",
      hidden: true,
      component: (
        <Build
          images={images}
          onSuccess={() => {
            changeQuery("my-guild", "tab");
            getMe();
          }}
        />
      ),
    },
    {
      title: "Setting",
      icon: "/assets/images/icons/scout.png",
      value: "setting",
      hidden: true,
      component: (
        <UpdateGuild
          id={id!}
          onSuccess={() => {
            getMe();
          }}
        />
      ),
    },
  ];
  const tabValue = searchParameters.get("tab");

  const value = tabValue;

  const handleSetValue = () => {
    searchParameters.set(
      "tab",
      userInfo?.guild?.id ? Tabs[1].value : Tabs[0].value
    );

    setSearchParameters(searchParameters);
  };
  
  useEffect(() => {
    if (!value && location.pathname.includes("/guild")) {
      handleSetValue();
    }
    if (value === "guild" && !id) {
      searchParameters.set("tab", "explore");
      setSearchParameters(searchParameters);
    }
  }, [value, id]);
  const activeComponent = Tabs.find((item) => item.value === value)?.component;
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{
        opacity: 0,
      }}
    >
      <Helmet>
        <style>
          {`
          .main-layout{
            background-image:url(${
              isGuildAvailable && userInfo?.guild?.banner
                ? userInfo?.guild?.banner?.includes("chainoflegends.com")
                  ? userInfo?.guild?.banner
                  : BaseURL + userInfo?.guild?.banner
                : "/assets/images/backgrounds/guild-hall.jpg"
            })
          }
          `}
        </style>
      </Helmet>
      <GuildTabs value={value!} tabs={Tabs} />
      <div className="guild-box">{activeComponent}</div>
    </motion.div>
  );
};

export default GuildPage;
