import React, { useEffect, useRef, useState } from "react";
import MainBox from "../components/Boxes/MainBox";

import { motion } from "framer-motion";
import Dialog from "../components/Dialog";
import Button from "../components/Button";

import Network, { ApiUrl, FTPURL, IsBeta } from "../utils/Network";
import useToast from "../hooks/useToast";
import { roundFloat } from "../utils/round-float";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import useWindowSize from "../hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../redux/user/action";
import { isMobile } from "react-device-detect";
import Decimal from "decimal.js-light";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Alert,
  Badge,
  Card,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import fetch from "axios";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Slider from "rc-slider";
import ThousandsSeparator from "../utils/thousands-separator";
import { setBuildingData } from "../redux/buildings/action";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import MenuItem from "@mui/material/MenuItem";

type Props = {};

const Home = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [dontShow, setDontShow] = useState(false);
  const userInfo = useSelector((state: any) => state?.userInfo);
  const buildingData = useSelector((state: any) => state?.buildings);
  const location = useLocation();
  const [offerDialog, setOfferDialog] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [maxSupply, setMaxSupply] = useState(0);
  const [publicLoading, setPublicLoading] = useState<boolean | number>(false);
  const stoneRef = useRef();
  const [confimDialog, setConfirmDialog] = useState<any>({
    open: false,
    submit: null,
    title: "",
    msg: "",
  });
  const [data, setData] = useState<any>();
  const openToast = useToast();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [whitelistDialog, setWhiteListDialog] = useState(false);
  const [swiper, setSwiper] = useState<any>(null);
  const [detailDialog, setDetailDialog] = useState<any>({
    open: false,
    item: null,
    buildable: false,
  });
  const [activeTab, setActiveTab] = useState(0);
  const [clegCount, setClegCount] = useState(0);
  const [stoneCount, setStoneCount] = useState(0);
  const [ironCount, setIronCount] = useState(0);
  const axios = new Network();

  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };
  useEffect(() => {
    if (data && location.hash === "#upgrade-barracks")
      getBuilding(data[3]?.id, data[3]);
    if (data && location.hash === "#upgrade-iron")
      getBuilding(data[2]?.id, data[2]);
    if (data && location.hash === "#upgrade-stone")
      getBuilding(data[1]?.id, data[1]);
    if (data && location.hash === "#upgrade-cleg")
      getBuilding(data[0]?.id, data[0]);
    if (data && location.hash === "#repair") {
      getBuilding(data[0]?.id, data[0]);
      setActiveTab(1);
    }
  }, [data, location.hash]);

  const slideTo = (index: number) => swiper?.slideTo(index);
  useEffect(() => {
    if (!swiper) return;
    if (location.hash === "#cleg") {
      slideTo(0);
    }
    if (location.hash === "#stone") {
      slideTo(1);
    }
    if (location.hash === "#iron") {
      slideTo(2);
    }
    if (location.hash === "#barracks") {
      slideTo(3);
    }
  }, [location.hash]);

  const handleCheckWhiteList = async () => {
    try {
      const newUserInfo = await fetch.get(
        "https://dev.chainoflegends.com/api/v1/player",
        {
          headers: {
            authorization: localStorage.getItem("user.token") || undefined,
          },
        }
      );
      if (
        newUserInfo?.data?.state === 7 &&
        !localStorage.getItem("whiteListAlertv2") &&
        !IsBeta
      ) {
        setWhiteListDialog(true);
      }
    } catch (e) {}
  };
  // useEffect(() => {if(!IsBeta)handleCheckWhiteList()}, [])
  useEffect(() => {
    if (
      IsBeta &&
      !localStorage.getItem("beta-leaderboard-2") &&
      new Date(" Jul 15 2023 00:00:00 GMT")?.getTime() > Date.now()
    ) {
      setOfferDialog(true);
      localStorage.setItem("beta-leaderboard-2", "shown");
    }
  }, [data]);
  const getData = async () => {
    setLoading(true);
    const response = await axios.CallApi({
      url: "/building",
      method: "get",
    });
    openToast(response);
    if (response?.ok) {
      const allData = response?.data?.map((item: any) => ({
        ...item,
        id: item?.id,
        title: item?.name,
        image: FTPURL + "/" + item?.photo,
        health: roundFloat(item?.currentHealth),
        capacity: roundFloat(item?.currentMaxCapacity),
        filled: roundFloat(item?.currentCapacity),
        count: item?.currentLevel,
        video: FTPURL + "/" + item?.video,
        status: item?.state,
        upgradeTime: item?.nextEventDate,
        currentDailyProduction: item.currentDailyProduction,
        disabled: item?.state === -1,
      }));
      dispatch(setBuildingData(allData));
      setData(allData);
    }
    //  setTimeout(()=>{
    //   setIsOpen(true)
    //  },1000)
    setLoading(false);
  };

  useEffect(() => {
    if (clegCount === maxSupply) {
      setIronCount(0);
      setStoneCount(0);
    }
    if (
      +roundFloat(
        stoneCount * detailDialog?.item?.stoneMarketPrice,
        2,
        Decimal.ROUND_UP
      ) === maxSupply
    ) {
      setIronCount(0);
      setClegCount(0);
    }
    if (
      +roundFloat(
        ironCount * detailDialog?.item?.ironMarketPrice,
        2,
        Decimal.ROUND_UP
      ) === maxSupply
    ) {
      setStoneCount(0);
      setClegCount(0);
    }
  }, [stoneCount, clegCount, ironCount]);

  const handleChangeStone = (stone: number) => {
    setStoneCount(stone);
    const totalAmount =
      clegCount +
      stone * detailDialog?.item?.stoneMarketPrice +
      ironCount * detailDialog?.item?.ironMarketPrice;
    if (totalAmount > maxSupply) {
      const remaining = totalAmount - maxSupply;
      if (+roundFloat(clegCount - remaining / 2) > 0)
        setClegCount((prev) => +roundFloat(prev - remaining / 2));
      if (+roundFloat(ironCount - remaining / 2) > 0)
        setIronCount(
          (prev) =>
            +roundFloat(
              prev - remaining / 2 / detailDialog?.item?.ironMarketPrice
            )
        );
    }
  };

  const handleChangeIron = (iron: number) => {
    setIronCount(iron);
    const totalAmount =
      clegCount +
      stoneCount * detailDialog?.item?.stoneMarketPrice +
      iron * detailDialog?.item?.ironMarketPrice;
    if (totalAmount > maxSupply) {
      const remaining = totalAmount - maxSupply;
      if (+roundFloat(clegCount - remaining / 2) > 0)
        setClegCount((prev) => +roundFloat(prev - remaining / 2));
      if (+roundFloat(stoneCount - remaining / 2) > 0)
        setStoneCount(
          (prev) =>
            +roundFloat(
              prev - remaining / 2 / detailDialog?.item?.stoneMarketPrice
            )
        );
    }
  };

  const handleChangeCleg = (clg: number) => {
    setClegCount(clg);
    const totalAmount =
      clegCount +
      stoneCount * detailDialog?.item?.stoneMarketPrice +
      ironCount * detailDialog?.item?.ironMarketPrice;
    if (totalAmount > maxSupply) {
      let remaining = totalAmount - maxSupply;
      // if(+roundFloat(stoneCount -(remaining / 2)) set
      if (+roundFloat(stoneCount - remaining / 2) > 0) {
        setStoneCount(
          (prev) =>
            +roundFloat(
              prev - remaining / 2 / detailDialog?.item?.stoneMarketPrice
            )
        );
      } else {
        setStoneCount(0);
        if (ironCount - remaining / detailDialog?.item?.ironMarketPrice < 0)
          return;
        setIronCount(
          (prev) =>
            +roundFloat(prev - remaining / detailDialog?.item?.ironMarketPrice)
        );
        return;
      }
      if (+roundFloat(ironCount - remaining / 2) > 0)
        setIronCount(
          (prev) =>
            +roundFloat(
              prev - remaining / 2 / detailDialog?.item?.ironMarketPrice
            )
        );
      else {
        setIronCount(0);
      }
    }
  };
  const getBuilding = async (id: number | string, item = {}) => {
    if (!id && !parseInt(id?.toString())) {
      setDetailDialog((prev) => ({
        ...prev,
        open: true,
        item: { id: id, ...item },
        buildable: true,
      }));
      return;
    }

    setDetailDialog((prev) => ({
      ...prev,
      open: true,
    }));
    setDetailLoading(true);
    const response = await axios.CallApi({
      url: "/building/" + id,
      method: "get",
    });
    openToast(response);
    setDetailLoading(false);
    if (!response.ok) return;
    setDetailDialog((prev) => ({
      ...prev,
      open: true,
      item: {
        ...item,
        id: id,
        ...response?.data,
      },
      buildable: false,
    }));
    if (response?.data?.buildingId !== 6) {
      let totalAmount =
        response?.data?.repairCost +
        response?.data?.repairIronCost * response?.data?.ironMarketPrice +
        response?.data?.repairStoneCost * response?.data?.stoneMarketPrice;
      totalAmount = +roundFloat(totalAmount);
      setMaxSupply(totalAmount);
      // const totalAmount = response?.data?.repairCost
      setClegCount(response?.data?.repairCost);
      setIronCount(response?.data?.repairIronCost);
      setStoneCount(response?.data?.repairStoneCost);
    }
  };
  // console.log(detailDialog)
  useEffect(() => {
    if (!localStorage.getItem("user.token")) return;
    getData();
  }, []);
  const UpgradeBuilding = async (id: number | string) => {
    setPublicLoading(true);
    const response = await axios.CallApi({
      url: `/building/${id}/repair-upgrade`,
      method: "get",
    });
    openToast(response);
    if (response.ok) {
      setConfirmDialog((prev) => ({
        ...prev,
        open: false,
      }));
      setDetailDialog((prev) => ({
        ...prev,
        open: false,
      }));
      getData();
      getMe();
    }

    setPublicLoading(false);
  };

  const RepairMiner = async (
    id: number | string,
    options?: {
      clegUse: number | string;
      ironUse: number | string;
      stoneUse: number | string;
    }
  ) => {
    setPublicLoading(true);
    const response = await axios.CallApi({
      url: `/building/${id}/repair${
        options
          ? `?clegUse=${options?.clegUse}&stoneUse=${options.stoneUse}&ironUse=${options.ironUse}`
          : ""
      }`,
      method: "get",
    });
    openToast(response);
    if (response.ok) {
      setConfirmDialog((prev) => ({
        ...prev,
        open: false,
      }));
      setDetailDialog((prev) => ({
        ...prev,
        open: false,
      }));
      getData();
      getMe();
    }
    setPublicLoading(false);
  };

  function handleCalculateRemaining() {
    try {
      const remaining = new Decimal(clegCount || 0)
        .plus(stoneCount * detailDialog?.item?.stoneMarketPrice)
        .plus(ironCount * detailDialog?.item?.ironMarketPrice);
      const pertentage = new Decimal(100)
        .minus(detailDialog?.item?.health || 0)
        .times(100)
        .dividedBy(remaining)
        .toDecimalPlaces(2)
        .plus(detailDialog?.item?.health)
        .toNumber();
      const newPertentage = new Decimal(
        new Decimal(100).minus(detailDialog?.item?.health || 0)
      )
        .dividedBy(maxSupply)
        .times(remaining)
        ?.plus(detailDialog?.item?.health)
        ?.toDecimalPlaces(2)
        .toNumber();
      return newPertentage;
    } catch (e) {
      // console.log(e)
      return 100;
    }
  }

  const isOfferActive =
    new Date("2023-10-01T00:00:00.024Z")?.getTime() < Date.now() &&
    detailDialog?.item?.buildingId === 6 &&
    detailDialog?.item?.bonusLevel > 0 &&
    new Date("2023-10-09T00:00:00.024Z")?.getTime() > Date.now();

  function getOff() {
    try {
      const remaining = new Decimal(clegCount || 0)
        .plus(stoneCount * detailDialog?.item?.stoneMarketPrice)
        .plus(ironCount * detailDialog?.item?.ironMarketPrice);
      const pertentage = new Decimal(100)
        .minus(detailDialog?.item?.health || 0)
        .times(100)
        .dividedBy(remaining)
        .toDecimalPlaces(2)
        .plus(detailDialog?.item?.health)
        .toNumber();
      const newPertentage = new Decimal(
        new Decimal(100).minus(detailDialog?.item?.health || 0)
      )
        .dividedBy(maxSupply)
        .times(remaining)
        ?.toDecimalPlaces(2)
        .toNumber();
      if (newPertentage >= 70) {
        return "50% OFF";
      }
      if (newPertentage >= 50) {
        return "30% OFF";
      }
      if (newPertentage >= 20) {
        return "10% OFF";
      }
      return false;
    } catch (e) {}
  }
  function handleCalculateHP(input: number, final?: boolean) {
    try {
      const remaining = new Decimal(clegCount || 0)
        .plus(stoneCount * detailDialog?.item?.stoneMarketPrice)
        .plus(ironCount * detailDialog?.item?.ironMarketPrice);
      const pertentage = new Decimal(100)
        .minus(detailDialog?.item?.health || 0)
        .times(100)
        .dividedBy(remaining)
        .toDecimalPlaces(2)
        .plus(detailDialog?.item?.health)
        .toNumber();
      const newPertentage = new Decimal(
        new Decimal(100).minus(detailDialog?.item?.health || 0)
      )
        .dividedBy(maxSupply)
        .times(remaining)
        ?.toDecimalPlaces(2)
        .toNumber();
      if (newPertentage < 0) {
        return 0;
      }
      if (newPertentage >= 70) {
        return (input - (input * 50) / 100)?.toFixed(2);
      }
      if (newPertentage >= 50) {
        return (input - (input * 30) / 100)?.toFixed(2);
      }
      if (newPertentage >= 20) {
        return (input - (input * 10) / 100)?.toFixed(2);
      }
      if (final) {
        return input;
      }
      return false;
    } catch (e) {
      if (final) {
        return input;
      }
      return 0;
    }
  }
  const CollectMine = async (id: number | string) => {
    setPublicLoading(parseInt(id?.toString()));
    const response = await axios.CallApi({
      url: `/building/${id}/collect`,
      method: "get",
    });
    openToast(response);
    if (response.ok) {
      setConfirmDialog((prev) => ({
        ...prev,
        open: false,
      }));
      setDetailDialog((prev) => ({
        ...prev,
        open: false,
      }));
      getMe();
      getData();
    }
    setPublicLoading(false);
  };

  const handleCollectMine = (id: number | string) => {
    return CollectMine(id);
    setConfirmDialog({
      open: true,
      submit: () => CollectMine(id),
      msg: `Are you sure to Collect from?`,
      title: "Are you Sure?",
    });
  };
  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);

  const handleBuildServer = async (id: number | string) => {
    setPublicLoading(true);
    const response = await axios.CallApi({
      url: `/building/${id}/build`,
      method: "get",
    });
    openToast(response);
    if (response.ok) {
      setConfirmDialog((prev) => ({
        ...prev,
        open: false,
      }));
      getData();
      getMe();
    }
    setPublicLoading(false);
  };

  const handleBuild = (id: number | string, name: string, item?: any) => {
    setConfirmDialog({
      open: true,
      submit: () => handleBuildServer(id),
      msg: (
        <>
          Are you sure to Build {name}? <br />
          <div style={{ marginBottom: -40 }} className="d-flex mt-3 ">
            <div className="">Cost:</div>

            {item?.firstLevelBuildingInfo?.clegCost && item?.discount ? (
              <div className="d-flex ms-2 gap-1 align-items-center">
                <img
                  src={"/assets/images/icons/cleg.png"}
                  width={25}
                  height={25}
                />
                <s className="fs-tiny">
                  {item?.firstLevelBuildingInfo?.clegCost}{" "}
                </s>
                <b className="text-success-light">
                  {roundFloat(
                    (item?.firstLevelBuildingInfo?.clegCost * (100-item?.discount)) /
                      100
                  ) }
                </b>
              </div>
            ) : (
              Boolean(item?.firstLevelBuildingInfo?.clegCost) && (
                <div className="d-flex ms-3 align-items-center">
                  <img
                    src={"/assets/images/icons/cleg.png"}
                    width={25}
                    height={25}
                  />
                  {item?.firstLevelBuildingInfo?.clegCost}
                </div>
              )
            )}
            {item?.firstLevelBuildingInfo?.stoneCost && item?.discount ? (
              <div className="d-flex ms-2 gap-1 align-items-center">
                <img
                  src={"/assets/images/icons/stone.png"}
                  width={25}
                  height={25}
                />
                <s className="fs-tiny">
                  {item?.firstLevelBuildingInfo?.stoneCost}{" "}
                </s>
                <b className="text-success-light">
                  {roundFloat(
                    (item?.firstLevelBuildingInfo?.stoneCost * (100-item?.discount)) /
                      100
                  )}
                </b>
              </div>
            ) : (
              Boolean(item?.firstLevelBuildingInfo?.stoneCost) && (
                <div className="d-flex ms-2 align-items-center">
                  <img
                    src={"/assets/images/icons/stone.png"}
                    width={25}
                    height={25}
                  />
                  {item?.firstLevelBuildingInfo?.stoneCost}
                </div>
              )
            )}

            {item?.firstLevelBuildingInfo?.ironCost && item?.discount ? (
              <div className="d-flex ms-2 gap-1 align-items-center">
                <img
                  src={"/assets/images/icons/metal.png"}
                  width={25}
                  height={25}
                />
                <s className="fs-tiny">
                  {item?.firstLevelBuildingInfo?.ironCost}{" "}
                </s>
                <b className="text-success-light">
                  {roundFloat(
                    (item?.firstLevelBuildingInfo?.ironCost * (100-item?.discount)) /
                      100
                  )}
                </b>
              </div>
            ) : (
              Boolean(item?.firstLevelBuildingInfo?.ironCost) && (
                <div className="d-flex ms-2 align-items-center">
                  <img
                    src={"/assets/images/icons/metal.png"}
                    width={25}
                    height={25}
                  />
                  {item?.firstLevelBuildingInfo?.ironCost}
                </div>
              )
            )}
          </div>
        </>
      ),
      title: "Are you Sure?",
    });
  };

  return (
    <>
      <Helmet>
        <title key={"title"}>Home | Chain Of Legends</title>
      </Helmet>
      <div className="row">
        {windowSize?.width > 768 &&
          buildingData?.map((item: any, key: number) => (
            <motion.div
              transition={{ duration: 0.5, delay: key * 0.1 }}
              initial={isMobile ? undefined : { x: 1600 }}
              animate={{ x: 0 }}
              exit={{ x: -1600 }}
              className="col-12 col-sm-12 col-md-6 col-lg-4  col-xl-3"
            >
              <MainBox
                key={item?.id}
                rawDetail={item}
                offerAction={() => navigate("/home#upgrade-barracks")}
                loading={publicLoading === item?.id}
                submit={() =>
                  item?.status === 0
                    ? handleBuild(item?.buildingId, item?.name, item)
                    : item?.buildingId === 6
                    ? navigate("/barracks")
                    : handleCollectMine(item?.id)
                }
                currentDailyProduction={item.currentDailyProduction}
                refresh={getData}
                getMe={getMe}
                repair={() =>
                  setConfirmDialog({
                    msg: `Are you sure to repair ${item?.title}?`,
                    open: true,
                    submit: () => RepairMiner(item?.id),
                    title: "Are you sure?",
                  })
                }
                onDetail={(item: any) => {
                  getBuilding(item.id, item?.rawDetail);
                }}
                countDown={undefined}
                className={"step-" + (key + 1)}
                {...item}
                // countDown={key === 2 && 'Tue Nov 08 2022 12:00:00 GMT+0330'}
                count={item?.count?.toString()}
              />
            </motion.div>
          ))}
      </div>
      <div>
        {windowSize?.width <= 768 && (
          <motion.div
            transition={{ duration: 0.5, delay: 0.1 }}
            initial={isMobile ? undefined : { y: 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 200, opacity: 0 }}
          >
            <Swiper
              effect={"cards"}
              modules={[EffectCards]}
              cardsEffect={{
                slideShadows: false,
              }}
              onSwiper={setSwiper}
              spaceBetween={50}
              centeredSlides
              centerInsufficientSlides
              centeredSlidesBounds
              slidesPerView={1}
            >
              {buildingData?.map((item: any, key) => (
                <SwiperSlide>
                  <MainBox
                    offerAction={() => navigate("/home#upgrade-barracks")}
                    key={item?.id}
                    rawDetail={item}
                    submit={() =>
                      item?.status === 0
                        ? handleBuild(item?.buildingId, item?.name, item)
                        : item?.buildingId === 6
                        ? navigate("/barracks")
                        : handleCollectMine(item?.id)
                    }
                    currentDailyProduction={item.currentDailyProduction}
                    refresh={getData}
                    getMe={getMe}
                    repair={() =>
                      setConfirmDialog({
                        msg: `Are you sure to repair ${item?.title}?`,
                        open: true,
                        submit: () => RepairMiner(item?.id),
                        title: "Are you sure?",
                      })
                    }
                    onDetail={(item: any) => {
                      getBuilding(item.id, item?.rawDetail);
                    }}
                    countDown={undefined}
                    className={"mx-auto step-" + (key + 1)}
                    {...item}
                    // countDown={key === 2 &&  (Date.now()>=new Date('Tue Nov 08 2022 12:00:00 GMT+0330').getTime() ? false:'Tue Nov 08 2022 12:00:00 GMT+0330')}
                    count={item?.count?.toString()}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
        )}
      </div>

      {/* Detail Dialog */}
      <Dialog
        layoutLoading={detailLoading}
        noFooter
        size="lg"
        title={detailDialog?.item?.name}
        open={detailDialog.open}
        onClose={() => {
          setDetailDialog((prev) => ({ ...prev, open: false }));
          if (location?.hash) navigate(window.location.pathname);
        }}
      >
        <div className="row">
          {isOfferActive && (
            <div className="col-12 mb-2">
              <img
                style={{ borderRadius: "10px", marginTop: "-15px" }}
                width={"100%"}
                src={`/assets/images/barracks-offers-v2/${detailDialog?.item?.bonusLevel}.png`}
              />
            </div>
          )}
          <div className="col-12 col-lg-4 d-none d-sm-block ">
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-12 offset-lg-0">
              <div className="d-flex justify-content-center">
                <div className="main-box-item text-light py-5 ">
                  <div className="mb-title text-center pt-3">
                    <h3 className="h6 fw-bold fs-med">
                      Level
                      <button
                        className="mb-title-btn fw-bold text-light mx-2"
                        name="lblLevel"
                      >
                        {detailDialog?.item?.level || 0}
                      </button>
                    </h3>
                  </div>
                  <div className="mb-body px-4 my-2">
                    <div className="image px-2 text-center">
                      <img
                        src={
                          FTPURL +
                          "/" +
                          (detailDialog?.item?.photoFile ||
                            detailDialog?.item?.photo)
                        }
                        className="img-fluid my-2"
                        alt="CLEG Token Mine"
                      />
                    </div>
                  </div>
                  <div className="mb-button text-center">
                    {detailDialog?.item?.status === 0 ? (
                      <Button
                      badgeStyles={{right:0,top:3}}
                        badge={
                          detailDialog?.item?.discount > 0 ? (
                            <>
                              <div className="dark-yellow fs-lg">
                                {detailDialog.item.discount}%
                              </div>
                              <small className="dark-yellow">OFF</small>
                            </>
                          ):""
                        }
                        onClick={() =>
                          handleBuild(
                            detailDialog?.item?.buildingId,
                            detailDialog?.item?.name,
                            detailDialog?.item
                          )
                        }
                        // disabled={props.disabled}
                        variant="rounded"
                        className="form-btn lightbrown-btn mb-4 mx-auto "
                      >
                        <span className="b-text fs-6 px-5">Build</span>
                      </Button>
                    ) : detailDialog?.item?.buildingId === 6 ? (
                      <Button
                        onClick={() => {
                          setDetailDialog((prev: any) => ({
                            ...prev,
                            open: false,
                          }));
                          navigate("/barracks");
                        }}
                        // disabled={props.disabled}
                        variant="rounded"
                        className="form-btn lightbrown-btn mb-4 mx-auto "
                      >
                        <span className="b-text fs-6 px-5">Open</span>
                      </Button>
                    ) : (
                      <div className="snow-layer">
                        <Button
                          onClick={() =>
                            handleCollectMine(
                              detailDialog?.item?.id,
                              detailDialog?.item?.name
                            )
                          }
                          // disabled={props.disabled}
                          variant="rounded"
                          className="form-btn lightbrown-btn  mb-4 mx-auto "
                        >
                          <span className="b-text fs-6 px-5">Collect</span>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="row mb-0 mb-sm-2">
              <div style={{ flex: 1 }} className="col-12 col-sm-8 col-lg-9">
                <div className="form-processbar fs-med">
                  <div className="mt-3">
                    <label className="d-flex justify-content-between mb-1">
                      <span>
                        {detailDialog?.item?.buildingId === 6
                          ? "Queue"
                          : "Health"}
                      </span>
                      <span>
                        {detailDialog?.item?.buildingId === 6
                          ? detailDialog?.item?.trainingQueue
                          : roundFloat(detailDialog?.item?.health)}{" "}
                        /{" "}
                        {detailDialog?.item?.buildingId === 6
                          ? detailDialog?.item?.maxTrainingQueue
                          : "100"}
                        {detailDialog?.item?.buildingId !== 6 && (
                          <>
                            <img
                              width={20}
                              height={20}
                              className={"mx-2"}
                              src={"/assets/images/icons/arrow-right.svg"}
                            />
                            <span>
                              {handleCalculateRemaining() > 100
                                ? 100
                                : handleCalculateRemaining()}
                            </span>
                          </>
                        )}
                      </span>
                    </label>
                    <div className="progress wide">
                      <div
                        className={`progress-bar progress-bar-striped ${
                          detailDialog?.item?.health >= 80
                            ? "green"
                            : detailDialog?.item?.health >= 60
                            ? "blue"
                            : detailDialog?.item?.health >= 40
                            ? "orange"
                            : detailDialog?.item?.health >= 20
                            ? "darkOrange"
                            : detailDialog?.item?.health < 20
                            ? "red"
                            : "green"
                        }`}
                        style={{
                          width:
                            (detailDialog?.item?.buildingId === 6
                              ? (
                                  (parseInt(
                                    String(detailDialog?.item?.trainingQueue)
                                  ) /
                                    detailDialog?.item?.maxTrainingQueue) *
                                  100
                                ).toString()
                              : detailDialog?.item?.health) + "%",
                        }}
                      ></div>
                    </div>
                    {detailDialog?.item?.repairCost ? (
                      <div className="d-flex flex-wrap mt-2 fs-small align-items-center ">
                        <div>Repair Cost:</div>
                        {detailDialog?.item?.repairCost && (
                          <div className="d-flex ms-2 align-items-center">
                            <img
                              src={"/assets/images/icons/cleg.png"}
                              className="me-1"
                              width={20}
                              height={20}
                            />
                            {roundFloat(detailDialog?.item?.repairCost, 2)}
                          </div>
                        )}
                        {detailDialog?.item?.repairStoneCost ? (
                          <div className="d-flex ms-2 align-items-center">
                            <img
                              src={"/assets/images/icons/stone.png"}
                              className="me-1"
                              width={20}
                              height={20}
                            />
                            {roundFloat(detailDialog?.item?.repairStoneCost, 2)}
                          </div>
                        ) : (
                          ""
                        )}
                        {detailDialog?.item?.repairIronCost ? (
                          <div className="d-flex ms-2 align-items-center">
                            <img
                              src={"/assets/images/icons/metal.png"}
                              className="me-1"
                              width={20}
                              height={20}
                            />
                            {roundFloat(detailDialog?.item?.repairIronCost, 2)}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {detailDialog?.item?.buildingId !== 6 && (
                <div className="col-12 col-sm-4 col-lg-3 pt-3 mb-1 mb-md-2 text-center text-md-right">
                  <Button
                    disabled={
                      detailLoading ||
                      detailDialog?.item?.status === 0 ||
                      activeTab === 1
                    }
                    onClick={() => {
                      setConfirmDialog({
                        msg: `Are you sure to repair ${detailDialog?.item?.name}?`,
                        open: true,
                        submit: () => RepairMiner(detailDialog?.item?.id),
                        title: "Are you sure?",
                      });
                    }}
                    variant="default"
                  >
                    Repair
                  </Button>
                </div>
              )}
            </div>
            <div className="row mb-2 mb-md-4">
              <div className="col-12 col-sm-7 col-md-7 mb-3 mb-md-0 order-1 order-sm-0">
                <div className="d-flex">
                  <button
                    onClick={() => setActiveTab(0)}
                    className="theme-btn button"
                    name="upgradeTab"
                  >
                    <img
                      className="img-fluid"
                      src={
                        activeTab === 0
                          ? "/assets/images/buttons/active-tab-btn.png"
                          : "/assets/images/buttons/off-tab-btn.png"
                      }
                    />
                    <div className="b-text">
                      <span className="text-light fs-med">Upgrade</span>
                    </div>
                  </button>
                  {detailDialog?.item?.buildingId !== 6 ? (
                    <button
                      onClick={() => setActiveTab(1)}
                      className="theme-btn button p-relative"
                      name="infoTab"
                    >
                      <img
                        className="img-fluid"
                        src={
                          activeTab === 1
                            ? "/assets/images/buttons/active-tab-btn.png"
                            : "/assets/images/buttons/off-tab-btn.png"
                        }
                      />
                      <div className="b-text">
                        <span className="text-light fs-med">Repair</span>
                        {new Date("Tue Apr 25 2023 00:00:00 GMT").getTime() >
                          new Date()?.getTime() && (
                          <Badge className="bg-danger new-badge">🔥Hot</Badge>
                        )}
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => setActiveTab(1)}
                      className="theme-btn button p-relative"
                      name="infoTab"
                    >
                      <img
                        className="img-fluid"
                        src={
                          activeTab === 1
                            ? "/assets/images/buttons/active-tab-btn.png"
                            : "/assets/images/buttons/off-tab-btn.png"
                        }
                      />
                      <div className="b-text">
                        <span className="text-light fs-med">Info</span>
                      </div>
                    </button>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-5 col-md-5 d-flex justify-content-center align-items-center mb-3 mb-md-0 order-0 order-sm-1">
                <img className="img-fluid" src="assets/images/brown-sep.png" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 position-relative">
                {activeTab === 1 && detailDialog?.item?.buildingId !== 6 && (
                  <div className="tab-box-body sec p-3 p-lg-4">
                    <div className="d-flex align-items-center  flex-wrap">
                      <span style={{ minWidth: 50 }}>Cleg</span>
                      <Slider
                        className="mx-3"
                        style={{ flex: 1, minWidth: 100 }}
                        value={clegCount}
                        onChange={(val) => handleChangeCleg(val)}
                        min={0}
                        max={maxSupply}
                      />
                      <div
                        style={{ minWidth: 170 }}
                        className="fs-med text-end text-md-start dark-yellow ms-auto mt-1 mt-md-0 "
                      >
                        <img
                          src={"/assets/images/icons/cleg.png"}
                          className="me-2"
                          width={20}
                          height={20}
                        />
                        <span className="">
                          {ThousandsSeparator(roundFloat(clegCount))}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center flex-wrap">
                      <span style={{ minWidth: 50 }}>Stone</span>
                      <Slider
                        className="mx-3"
                        style={{ flex: 1, minWidth: 100 }}
                        value={stoneCount}
                        onChange={(val) => {
                          handleChangeStone(val);
                        }}
                        min={0}
                        max={
                          +roundFloat(
                            maxSupply / detailDialog?.item?.stoneMarketPrice
                          )
                        }
                      />
                      <div
                        style={{ minWidth: 170 }}
                        className="fs-med text-end text-md-start  ms-auto mt-1 mt-md-0  dark-yellow"
                      >
                        <img
                          src={"/assets/images/icons/stone.png"}
                          width={20}
                          height={20}
                          className="me-1"
                        />
                        <span className=" p ">
                          {ThousandsSeparator(roundFloat(stoneCount))}
                        </span>
                        <span className="fs-med">
                          &nbsp;(1{" "}
                          <img
                            src={"/assets/images/icons/stone.png"}
                            width={16}
                            height={16}
                            className="me-1"
                          />
                          ={detailDialog?.item?.stoneMarketPrice}
                          <img
                            src={"/assets/images/icons/cleg.png"}
                            width={20}
                            height={20}
                            className="ms-1"
                          />
                          )
                        </span>
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center flex-wrap">
                      <span style={{ minWidth: 50 }}>Iron</span>
                      <Slider
                        className="mx-3"
                        style={{ flex: 1, minWidth: 100 }}
                        value={ironCount}
                        onChange={(val) => handleChangeIron(val)}
                        min={0}
                        max={
                          +roundFloat(
                            maxSupply / detailDialog?.item?.ironMarketPrice
                          )
                        }
                      />
                      <div
                        style={{ minWidth: 170 }}
                        className="fs-med ms-auto text-end text-md-start  mt-1 mt-md-0 dark-yellow"
                      >
                        <img
                          src={"/assets/images/icons/metal.png"}
                          width={20}
                          height={20}
                          className="me-1"
                        />
                        <span className=" p ">
                          {ThousandsSeparator(roundFloat(ironCount))}
                        </span>
                        <span className="fs-med">
                          &nbsp;(1{" "}
                          <img
                            src={"/assets/images/icons/metal.png"}
                            width={16}
                            height={16}
                            className="me-1"
                          />
                          ={detailDialog?.item?.ironMarketPrice}
                          <img
                            src={"/assets/images/icons/cleg.png"}
                            width={20}
                            height={20}
                            className="ms-1"
                          />
                          )
                        </span>
                      </div>
                    </div>
                    <br />
                    {new Date("Tue Apr 25 2023 00:00:00 GMT").getTime() >
                      new Date()?.getTime() &&
                    detailDialog?.item?.health < 99 &&
                    (handleCalculateHP(clegCount) ||
                      handleCalculateHP(stoneCount) ||
                      handleCalculateHP(ironCount)) ? (
                      <motion.div
                        transition={{ duration: 0.5 }}
                        initial={isMobile ? undefined : { opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ x: -1600 }}
                        className="mt-3 "
                      >
                        <Alert variant="success" className="pt-3 ">
                          <Badge
                            style={{
                              position: "absolute",
                              top: "-11px",
                              right: 0,
                              left: 0,
                              marginInline: "auto",
                              width: "fit-content",
                            }}
                            bg="danger"
                          >
                            Special Offer{" "}
                            <small style={{ fontWeight: 600 }}>
                              (
                              <Countdown
                                zeroPadTime={0}
                                date={new Date("Tue Apr 25 2023 00:00:00 GMT")}
                                renderer={({ formatted }) => (
                                  <>
                                    {formatted.hours}:{formatted.minutes}:
                                    {formatted.seconds} left
                                  </>
                                )}
                              />
                              {/* <Countdown daysInHours date={new Date('Tue Apr 25 2023 00:00:00 GMT+0330')} /> */}
                              )
                            </small>
                          </Badge>
                          <div className="row text-center justify-content-center">
                            {handleCalculateHP(clegCount) ? (
                              <div className="col-6 col-md-4 ">
                                <div className=" fs-tiny">
                                  <span className="text-decoration-line-through text-secondary  ">
                                    {clegCount}
                                  </span>
                                  &nbsp;
                                  <b className="text-success">{getOff()}</b>
                                </div>
                                <div className="bold ">
                                  <img
                                    src={"/assets/images/icons/cleg.png"}
                                    width={20}
                                    height={20}
                                    className="me-1"
                                  />
                                  {handleCalculateHP(clegCount)}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {handleCalculateHP(stoneCount) ? (
                              <div className="col-6 col-md-4">
                                <div className=" fs-tiny">
                                  <span className="text-decoration-line-through text-secondary">
                                    {stoneCount}
                                  </span>
                                  &nbsp;
                                  <b className="text-success">{getOff()}</b>
                                </div>
                                <div className="bold ">
                                  <img
                                    src={"/assets/images/icons/stone.png"}
                                    width={20}
                                    height={20}
                                    className="me-1"
                                  />
                                  {handleCalculateHP(stoneCount)}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {handleCalculateHP(ironCount) ? (
                              <div className="col-6 col-md-4">
                                <div className=" fs-tiny">
                                  <span className="text-decoration-line-through text-secondary">
                                    {ironCount}
                                  </span>
                                  &nbsp;
                                  <b className="text-success">{getOff()}</b>
                                </div>
                                <div className="bold ">
                                  <img
                                    src={"/assets/images/icons/metal.png"}
                                    width={20}
                                    height={20}
                                    className="me-1"
                                  />
                                  {handleCalculateHP(ironCount)}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </Alert>
                      </motion.div>
                    ) : (
                      ""
                    )}

                    <div className="mt-3 text-center w-100">
                      <Button
                        onClick={() =>
                          setConfirmDialog({
                            open: true,
                            submit: () =>
                              RepairMiner(detailDialog?.item?.id, {
                                clegUse: roundFloat(
                                  handleCalculateHP(clegCount, true),
                                  4,
                                  Decimal.ROUND_DOWN
                                ),
                                ironUse: roundFloat(
                                  handleCalculateHP(ironCount, true),
                                  4,
                                  Decimal.ROUND_DOWN
                                ),
                                stoneUse: roundFloat(
                                  handleCalculateHP(stoneCount, true),
                                  4,
                                  Decimal.ROUND_DOWN
                                ),
                              }),
                            msg: `Are you sure to repair your ${detailDialog?.item?.name} ?`,
                            title: "Are you Sure?",
                          })
                        }
                        className="mx-auto"
                        variant="wide-primary"
                      >
                        Repair {detailDialog?.item?.name}
                      </Button>
                    </div>
                  </div>
                )}

                {activeTab === 0 && (
                  <div className="tab-box-body p-3 p-lg-4 pb-lg-2">
                    {detailDialog?.buildable ? (
                      <div className="land-info-list text-light fs-small mb-2">
                        <motion.div
                          initial={
                            isMobile
                              ? undefined
                              : {
                                  opacity: 0,
                                  x: 200,
                                }
                          }
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.4, delay: 0.1 }}
                          exit={{
                            opacity: 0,
                            x: -200,
                          }}
                          className="land-info-item d-flex justify-content-between"
                        >
                          <div className="title fw-normal">Capacity</div>
                          <div className="value fw-bold">
                            {roundFloat(
                              detailDialog?.item?.firstLevelBuildingInfo
                                ?.capacity
                            )}
                            {/* <span className="color-blue ms-1">+{roundFloat(detailDialog?.item?.thisLevelCapacity)}</span> */}
                          </div>
                        </motion.div>
                        <motion.div
                          initial={
                            isMobile
                              ? undefined
                              : {
                                  opacity: 0,
                                  x: 200,
                                }
                          }
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.4, delay: 0.2 }}
                          exit={{
                            opacity: 0,
                            x: -200,
                          }}
                          className="land-info-item d-flex justify-content-between"
                        >
                          <div className="title fw-normal">
                            Daily Production
                          </div>
                          <div className="value fw-bold">
                            {roundFloat(
                              detailDialog?.item?.firstLevelBuildingInfo
                                ?.dailyProduction
                            )}
                          </div>
                        </motion.div>
                        <motion.div
                          initial={
                            isMobile
                              ? undefined
                              : {
                                  opacity: 0,
                                  x: 200,
                                }
                          }
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.4, delay: 0.3 }}
                          exit={{
                            opacity: 0,
                            x: -200,
                          }}
                          className="d-flex "
                        >
                          <div className="d-flex mt-3 ">
                            <div className="">Build Cost:</div>
                            {detailDialog?.item?.firstLevelBuildingInfo
                              ?.clegCost && (
                              <div className="d-flex ms-2 align-items-center">
                                <img
                                  src={"/assets/images/icons/cleg.png"}
                                  width={25}
                                  height={25}
                                />
                                {
                                  detailDialog?.item?.firstLevelBuildingInfo
                                    ?.clegCost
                                }
                              </div>
                            )}
                            {detailDialog?.item?.firstLevelBuildingInfo
                              ?.stoneCost ? (
                              <div className="d-flex ms-3 align-items-center">
                                <img
                                  src={"/assets/images/icons/stone.png"}
                                  width={25}
                                  height={25}
                                />
                                {
                                  detailDialog?.item?.firstLevelBuildingInfo
                                    ?.stoneCost
                                }
                              </div>
                            ) : (
                              ""
                            )}
                            {detailDialog?.item?.firstLevelBuildingInfo
                              ?.ironCost ? (
                              <div className="d-flex ms-3 align-items-center">
                                <img
                                  src={"/assets/images/icons/metal.png"}
                                  width={25}
                                  height={25}
                                />
                                {
                                  detailDialog?.item?.firstLevelBuildingInfo
                                    ?.ironCost
                                }
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </motion.div>
                      </div>
                    ) : (
                      <div className="land-info-list text-light fs-small mb-2">
                        <motion.div
                          initial={
                            isMobile
                              ? undefined
                              : {
                                  opacity: 0,
                                  x: 200,
                                }
                          }
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.4, delay: 0.1 }}
                          exit={{
                            opacity: 0,
                            x: -200,
                          }}
                          className="land-info-item d-flex justify-content-between"
                        >
                          <div className="title fw-normal">
                            {detailDialog?.item?.buildingId === 6
                              ? "Troops Capacity"
                              : "Capacity"}
                          </div>
                          <div className="value fw-bold">
                            {roundFloat(detailDialog?.item?.thisLevelCapacity)}
                            {detailDialog?.item?.level !== 8 && (
                              <span className="color-blue ms-1">
                                +
                                {roundFloat(
                                  detailDialog?.item?.nextLevelCapacity -
                                    detailDialog?.item?.thisLevelCapacity
                                )}
                              </span>
                            )}
                          </div>
                        </motion.div>
                        <motion.div
                          initial={
                            isMobile
                              ? undefined
                              : {
                                  opacity: 0,
                                  x: 200,
                                }
                          }
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.4, delay: 0.2 }}
                          exit={{
                            opacity: 0,
                            x: -200,
                          }}
                          className="land-info-item d-flex justify-content-between"
                        >
                          <div
                            style={{ zIndex: 10 }}
                            className="title fw-normal"
                          >
                            {detailDialog?.item?.buildingId === 6 ? (
                              <OverlayTrigger
                                placement="right"
                                trigger={"hover"}
                                overlay={
                                  <Tooltip {...props}>
                                    Total heal to all present troops
                                  </Tooltip>
                                }
                                delay={{ show: 100, hide: 200 }}
                              >
                                <span>
                                  Daily Healing{" "}
                                  <img
                                    width={17}
                                    height={17}
                                    src={"/assets/images/icons/info.svg"}
                                  />
                                </span>
                              </OverlayTrigger>
                            ) : (
                              "Daily Production"
                            )}
                          </div>
                          <div className="value fw-bold">
                            {roundFloat(
                              detailDialog?.item?.thisLevelDailyProduction
                            )}
                            <span className="color-blue ms-1">
                              {detailDialog?.item?.level !== 8 && (
                                <>
                                  +
                                  {roundFloat(
                                    detailDialog?.item
                                      ?.nextLevelDailyProduction -
                                      detailDialog?.item
                                        ?.thisLevelDailyProduction
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        </motion.div>
                        {detailDialog?.item?.buildingId === 6 && (
                          <>
                            <motion.div
                              initial={
                                isMobile
                                  ? undefined
                                  : {
                                      opacity: 0,
                                      x: 200,
                                    }
                              }
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.4, delay: 0.2 }}
                              exit={{
                                opacity: 0,
                                x: -200,
                              }}
                              className="land-info-item d-flex justify-content-between"
                            >
                              <div
                                style={{ zIndex: 10 }}
                                className="title fw-normal"
                              >
                                <OverlayTrigger
                                  placement="right"
                                  flip
                                  overlay={
                                    <Tooltip className="fs-tiny" {...props}>
                                      Will add to all troop's Damage, Armor,
                                      Magic Resistance, and Healing Power.
                                    </Tooltip>
                                  }
                                  delay={{ show: 100, hide: 200 }}
                                >
                                  <span>
                                    Bonus Stats{" "}
                                    <img
                                      width={17}
                                      height={17}
                                      src={"/assets/images/icons/info.svg"}
                                    />
                                  </span>
                                </OverlayTrigger>
                              </div>
                              <div className="value fw-bold">
                                {detailDialog?.item?.bonusStats}%
                                {detailDialog?.item?.level !== 8 && (
                                  <span className="color-blue ms-1">
                                    +
                                    {detailDialog?.item?.nextLevelBonusStats -
                                      detailDialog?.item?.bonusStats}
                                    %
                                  </span>
                                )}
                              </div>
                            </motion.div>
                            <motion.div
                              initial={
                                isMobile
                                  ? undefined
                                  : {
                                      opacity: 0,
                                      x: 200,
                                    }
                              }
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.4, delay: 0.25 }}
                              exit={{
                                opacity: 0,
                                x: -200,
                              }}
                              className="land-info-item d-flex justify-content-between"
                            >
                              <div
                                style={{ zIndex: 10 }}
                                className="title fw-normal"
                              >
                                <span>Training Queue</span>
                              </div>
                              <div className="value fw-bold">
                                {detailDialog?.item?.maxTrainingQueue}
                                {detailDialog?.item?.level !== 8 && (
                                  <span className="color-blue ms-1">
                                    +
                                    {detailDialog?.item
                                      ?.nextLevelMaxTrainingQueue -
                                      detailDialog?.item?.maxTrainingQueue}
                                  </span>
                                )}
                              </div>
                            </motion.div>
                          </>
                        )}
                      </div>
                    )}

                    {!detailDialog?.buildable &&
                      detailDialog?.item?.level !== 8 && (
                        <motion.div
                          initial={
                            isMobile ? undefined : { opacity: 0, scale: 0 }
                          }
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.4, delay: 0.3 }}
                          exit={{ opacity: 0, scale: 0 }}
                          className="text-center"
                        >
                          <div className="d-flex flex-column align-items-center">
                            <div
                              style={{ marginBottom: 8 }}
                              className="d-flex mt-3 gap-2 flex-wrap align-items-end justify-content-center"
                            >
                              <div className="">Cost:</div>
                              {detailDialog?.item?.upgradeCost && (
                                <div className="d-flex ms-2 gap-2 flex-wrap  align-items-end">
                                  {detailDialog?.item?.lastUpgradeCost &&
                                  detailDialog?.item?.lastUpgradeCost !==
                                    detailDialog?.item?.upgradeCost ? (
                                    <div>
                                      <s>
                                        {roundFloat(
                                          detailDialog?.item?.lastUpgradeCost +
                                            detailDialog?.item?.repairCost,
                                          3
                                        )}
                                      </s>
                                      <div className="text-success-light">
                                        <img
                                          src={"/assets/images/icons/cleg.png"}
                                          width={22}
                                          height={22}
                                        />
                                        &nbsp;
                                        {roundFloat(
                                          detailDialog?.item?.upgradeCost +
                                            detailDialog?.item?.repairCost,
                                          2
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <img
                                        src={"/assets/images/icons/cleg.png"}
                                        width={22}
                                        height={22}
                                      />
                                      {roundFloat(
                                        detailDialog?.item?.upgradeCost +
                                          detailDialog?.item?.repairCost,
                                        3
                                      )}
                                    </>
                                  )}
                                  {detailDialog?.item?.lastUpgradeStoneCost &&
                                  detailDialog?.item?.lastUpgradeStoneCost !==
                                    detailDialog?.item?.upgradeStoneCost ? (
                                    <div>
                                      <s>
                                        {roundFloat(
                                          detailDialog?.item
                                            ?.lastUpgradeStoneCost +
                                            detailDialog?.item?.repairStoneCost,
                                          3
                                        )}
                                      </s>
                                      <div className="text-success-light">
                                        <img
                                          src={"/assets/images/icons/stone.png"}
                                          width={22}
                                          height={22}
                                        />
                                        &nbsp;
                                        {roundFloat(
                                          detailDialog?.item?.upgradeStoneCost +
                                            detailDialog?.item?.repairStoneCost,
                                          2
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <img
                                        src={"/assets/images/icons/stone.png"}
                                        width={22}
                                        height={22}
                                      />
                                      {roundFloat(
                                        detailDialog?.item?.upgradeStoneCost +
                                          detailDialog?.item?.repairStoneCost,
                                        3
                                      )}
                                    </>
                                  )}

                                  {detailDialog?.item?.lastUpgradeIronCost &&
                                  detailDialog?.item?.lastUpgradeIronCost !==
                                    detailDialog?.item?.upgradeIronCost ? (
                                    <div>
                                      <s>
                                        {roundFloat(
                                          detailDialog?.item
                                            ?.lastUpgradeIronCost +
                                            detailDialog?.item?.repairIronCost,
                                          3
                                        )}
                                      </s>
                                      <div className="text-success-light">
                                        <img
                                          src={"/assets/images/icons/metal.png"}
                                          width={22}
                                          height={22}
                                        />
                                        &nbsp;
                                        {roundFloat(
                                          detailDialog?.item?.upgradeIronCost +
                                            detailDialog?.item?.repairIronCost,
                                          2
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <img
                                        src={"/assets/images/icons/metal.png"}
                                        width={22}
                                        height={22}
                                      />
                                      {roundFloat(
                                        detailDialog?.item?.upgradeIronCost +
                                          detailDialog?.item?.repairIronCost,
                                        2
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                            <Button
                              badge={
                                detailDialog?.item?.discount > 0 && (
                                  <>
                                    <div className="dark-yellow fs-lg">
                                      {detailDialog.item.discount}%
                                    </div>
                                    <small className="dark-yellow">OFF</small>
                                  </>
                                )
                              }
                              onClick={() => {
                                setConfirmDialog({
                                  open: true,
                                  submit: () =>
                                    UpgradeBuilding(detailDialog?.item?.id),
                                  msg: `Are you sure about ${
                                    detailDialog?.item?.buildingId !== 6
                                      ? "repairing and"
                                      : ""
                                  } upgrading ${
                                    detailDialog?.item?.name
                                  } to level ${
                                    parseInt(detailDialog?.item?.level) + 1
                                  }?`,
                                  title: "Are you Sure?",
                                });
                              }}
                              variant="wide-primary"
                            >
                              {detailDialog?.item?.buildingId === 6 ? (
                                <span>Upgrade Barracks</span>
                              ) : (
                                <span>Repair & Upgrade</span>
                              )}
                            </Button>
                          </div>
                          {/* // <button disabled={detailLoading || detailDialog?.item?.status === 0}
                      //   onClick={() => {
                      //     setConfirmDialog({
                      //       open: true,
                      //       submit: () => UpgradeBuilding(detailDialog?.item?.id),
                      //       msg: `Are you sure about repairing and upgrading ${detailDialog?.item?.name} to level ${parseInt(detailDialog?.item?.level) + 1}?`,
                      //       title: "Are you Sure?"
                      //     })
                      //   }}
                      //   className={"theme-btn button " + (detailDialog?.item?.status === 0 && 'd-none')} name="btnRepairUpgrade">
                      //   <img className="img-fluid" width={190} src="assets/images/buttons/blue-btn.png" />
                      //   <div className="b-text flex-column">
                      //     <span className="text-light d-block">Repair &amp; Upgrade</span>
                      //     <div className="b-subtitle text-light">cost: <span className="color-orange" >{roundFloat(detailDialog?.item?.repairUpgradeCost, 3, Decimal.ROUND_UP)}</span>
                      //       <img width={25} height={25} className="img-fluid b-sicon" src="/assets/images/icons/cleg.png" /> </div>
                      //   </div>
                      // </button> */}
                        </motion.div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        loading={publicLoading}
        title={confimDialog?.title}
        closeLabel="No"
        submitLabel="Yes"
        onSubmit={confimDialog?.submit}
        open={confimDialog?.open}
        onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
      >
        {confimDialog?.msg}
      </Dialog>

      <Dialog
        size="lg"
        className="text-center"
        open={whitelistDialog}
        onClose={() => {
          setWhiteListDialog(false);
          if (dontShow) localStorage.setItem("whiteListAlertv2", "shown");
        }}
        title="WhiteList"
      >
        <div className="text-center">
          <img
            width={"100%"}
            style={{ maxWidth: 450, borderRadius: 15, marginTop: -30 }}
            src="/assets/images/backgrounds/tr-cover.jpg"
          />
        </div>
        <p className="fs-big mt-3 text-center bold">
          We are Full of Admiration for Your Whitelist Efforts!
        </p>
        <div>
          It's an honor to have you Whitelisted for our Beta Contest; Treasure
          Islands (PvP Mode).
        </div>
        <p className="fs-big mt-3  bold">General INFO</p>
        <p className="fs-med">
          🔹 You'll have a fresh new account to start your adventure with a 100k
          CLEG!
          <br />
          🔹 First week is a test phase. So, there won't be any prize! <br />
          🔹 Game speed is 100x. Strategize & play as fast as you can and build
          up your empire.
          <br />
          🔹 Send all your Comments and Bug Reports only to the link below👇
          <br />
          <a
            href="https://discord.com/channels/929829884438650960/1121346282812874782"
            target="_blank"
          >
            <Button className="mx-auto" variant="primary">
              {" "}
              Discord
            </Button>
          </a>
          🔹 Keep in touch & communicate with your comrades.
          <br />
          <b>🥳 Enjoy the PvP Mode & Win NFT Heroes & NFT Items 🥳</b>
        </p>
        <a
          className="mx-auto mb-2 d-block text-center"
          href="https://beta.chainoflegends.com/"
        >
          <Button className="mx-auto" variant="wide-primary">
            PLAY BETA NOW
          </Button>
        </a>
        <div
          style={{ marginBottom: -60 }}
          className="pb-2 d-flex fs-med justify-content-center"
        >
          <Form.Check
            type="checkbox"
            label="Don't show this again"
            checked={dontShow}
            onClick={() => setDontShow(!dontShow)}
          />
        </div>
      </Dialog>

      <Dialog
        onClose={() => {
          setOfferDialog(false);
        }}
        title="Special Offer"
        open={offerDialog}
      >
        <img
          className="rounded"
          width={"100%"}
          src="/assets/images/tr-contest.jpg"
        />
        <h5 className="mt-3 text-center">🎉 Beta Contest; Round 2 🎉</h5>
        <p className="fs-med  mt-3" style={{ textAlign: "justify" }}>
          ✔️ Second round has just been kicked off!
          <br />
          Everyone has 200,000 CLEG,Stone, & Iron as a head start. Compete for
          the highest income from Treasure Islands.
        </p>
        <p className="fs-med" style={{ textAlign: "justify" }}>
          📊 You can watch your ranking here in the "Leaderboard"
        </p>
        <div className="fs-med">
          ⚠️ Pay attention that in the second round only bug reports on Discord
          channel will be observed and counted for the reward distribution.
        </div>
        <div className="fs-med mt-2">
          ⚙️ Send your bug reports and suggestions here👇
        </div>
        <a
          href="https://discord.com/channels/929829884438650960/1121346282812874782"
          target="_blank"
        >
          <Button className="mx-auto" variant="primary">
            {" "}
            Discord
          </Button>
        </a>
        <div className="fs-tiny mt-2 text-center dark-gray">
          🥳 Compete for the first Rank in the Leaderboard of the Beta Contest
          🥳
        </div>
        <hr />
        <Link to="/home">
          <Button
            onClick={() => {
              setOfferDialog(false);
            }}
            style={{ marginBottom: -60 }}
            variant="wide-primary"
            className="mx-auto"
          >
            Play Now
          </Button>
        </Link>
      </Dialog>
    </>
  );
};

export default Home;
