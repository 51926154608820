import { useCallback } from "react";
import EventBus, { EventNames, EventMap } from "../utils/event-bus";

const useTriggerEvent = <K extends keyof EventMap>() => {
  const triggerEvent = useCallback((eventName: K, payload: EventMap[K]) => {
    EventBus.emit(eventName, payload);
  }, []);

  return triggerEvent;
};

export default useTriggerEvent;