export const actionTypes = {
    SET_BUILDING_DATA: 'SET_BUILDING_DATA',
    REMOVE_BUILDING_DATA: 'REMOVE_BUILDING_DATA',

};

export const setBuildingData = (payload:any) => ({
    type: actionTypes.SET_BUILDING_DATA,
    payload,
});

export const removeBuildingData = () => ({
    type: actionTypes.REMOVE_BUILDING_DATA,
});
