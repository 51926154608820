import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { OpenAlert } from '../redux/alert/action'
import Network, { ChainID } from '../utils/Network'
import Dialog from './Dialog'
import { ethers } from 'ethers'
import useToast from '../hooks/useToast'
import { setUserInfo } from '../redux/user/action'
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi'
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
type Props = {
  open: boolean;
  onClose: Function;
  onSign?: Function;
};

const Connector = (props: Props) => {
  const axios = new Network();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const openToast = useToast();
//   const { connectAsync } = useConnect();
  const { disconnect } = useDisconnect();
  const isDone = useRef(false);
  const { open, close } = useWeb3Modal();
  const { open: isOpen } = useWeb3ModalState();
  const { isConnected, address, isConnecting } = useAccount();
  const { signMessageAsync } = useSignMessage();

  useEffect(() => {
    if (isConnected && !isDone.current && props.open) {
      isDone.current = true;
      handleVerifyMessage();
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (props.open ) open()
  }, [props.open]);

  useEffect(() => {
    if (!isOpen) props.onClose();
  }, [isOpen]);
  const handleVerifyMessage = async () => {
    const message = await getMessageContent();
    // setWaitingForSign(true)
    openToast(
      {
        ok: true,
        message: "Please Sign the message with your wallet",
      },
      "warning"
    );
    const signedMessage = await signMessageAsync({ message: message });
    handleConnectApi(signedMessage, address!, "walletconnect");
  };

  const getMessageContent = async () => {
    const signMessage = await axios.CallApi({
      url: "/player/message-to-sign",
      method: "get",
    });

    return signMessage;
  };


  const handleConnectApi = async (
    sign: string,
    account: string,
    connector: string
  ) => {
    if (props.onSign) {
      return props.onSign({
        sign,
        account,
        connector,
      });
    }
    const body = {
      walletAddress: account,
      signedMessage: sign,
      refCode: localStorage.getItem("refCode"),
    };
    const response = await axios.CallApi({
      url: "/player/connectwallet",
      body,
    });
    openToast(response);
    if (response?.ok) {
      const user = await axios.CallApi({
        url: "/player",
        method: "get",
        token: response?.data?.token,
      });
      dispatch(setUserInfo(user?.data));
      localStorage.setItem("connector", connector);
      props?.onClose();
      setLoading(false);
    } else {
      disconnect();
    }
  };

  return (
    <></>
    // <Dialog title="Connect Wallet" onClose={props.onClose} open={props.open}>
    //     <div onClick={()=>handleLogin()}  className='log-box'>
    //         <img width={70} src="/assets/images/icons/metamask-icon.webp" />
    //         <div className='mt-2'>
    //             MetaMask
    //         </div>
    //     </div>
    //     <div onClick={()=>{
    //         isDone.current=false;
    //         open()

    //         }}  className='log-box mt-4'>
    //         <img width={70} src="/assets/images/icons/walletconnect.png" />
    //         <div className='mt-2 cw'>
    //             Wallet Connect

    //         </div>
    //     </div>
    // </Dialog>
  );
};

export default Connector;
