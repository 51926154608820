import React, { useEffect, useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "./Button";

type Props = {
  loaded: number;
  total: number;
  error?: boolean;
};

const MainLoader = (props: Props) => {
  const now = parseInt(((props.loaded * 100) / props.total)?.toString()) || 1;
  const isDone = props.total > 0 && props.total === props.loaded;
  const containerRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isDone)
      setTimeout(() => {
        containerRef.current.style.display = "none";
      }, 1000);
  }, [isDone]);
  return (
    <div
      ref={containerRef}
      className={"main-loader gap-2 " + (isDone && "hidden")}
    >
      {!props.error ? (
        <>
          <img src="/assets/images/chainoflegends-logo.png" width={"100px"} />
          <ProgressBar
            className="mt-3"
            animated
            striped
            variant="warning"
            style={{
              minWidth: 190,
              height: 15,
              color: "#000",
              background: "rgba(0,0,0,.09)",
            }}
            now={now}
            label={`${now}%`}
          />
          <p>Loading...</p>
        </>
      ) : (
        <>
          <img
            src="/assets/images/error.png"
            width={"80%"}
            style={{ maxWidth: 250 }}
          />
          <p className="text-center">
            This link is broken; You can get back to{" "}
            <Link to="/adventure"> adventure history </Link> page and click on
            replay again.
          </p>
          <Link to="/adventure">
            <Button variant="default">Back</Button>
          </Link>
        </>
      )}
      {/* {props.loaded}  /   {props.total} /  */}
    </div>
  );
};

export default MainLoader;
