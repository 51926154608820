import models from "../data/models";
import { ModelData } from "../types";

const defaultModelId = 8;
const getModelsData = (id: number) => {
    const modelData: ModelData = models[id];
    if (!modelData) return models[defaultModelId];
    return modelData;
};

export default getModelsData;