import { useEffect, useMemo, useRef, useState } from 'react'
import { useBattleContext } from '../context';


const useArenaLiveChecking = () => {
    const { gameInfo } = useBattleContext();
    const [refresh, setRefresh] = useState(false);
    const gameInfoRef = useRef(gameInfo);
    const isFindMatchOpen = useMemo(() => {
        if (!gameInfoRef.current) return false;
        return (
            new Date(gameInfoRef.current?.openTime).getTime() < Date.now() &&
            Date.now() < new Date(gameInfoRef.current.closeTime).getTime()
        );
    }, [refresh]);

    
    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh((prev) => !prev);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (gameInfo) {
            gameInfoRef.current = gameInfo;
        }
    }, [gameInfo]);

    return (
        {
            isFindMatchOpen

        })
}

export default useArenaLiveChecking