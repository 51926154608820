import React, {  useRef, useState } from "react";
import {
  Alert,
  Badge,
  Col,
  OverlayTrigger,
  Popover,
  ProgressBar,
  Row,
  Tooltip,
} from "react-bootstrap";
import { motion } from "framer-motion";
import classNames from "classnames";
import MidBox from "../components/Boxes/MidBox";
import { Link, useParams } from "react-router-dom";
import Network from "../utils/Network";
import ThousandsSeparator from "../utils/thousands-separator";
import { roundFloat } from "../utils/round-float";
import { MapPositions } from "../constants/positions";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../hooks/useToast";
import PerfectScrollbar from "react-perfect-scrollbar";
import { isMobile } from "react-device-detect";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { appendScout } from "../redux/scout/action";
import { CustomIcon } from "../components/Icons";
import TimeAgo from "javascript-time-ago";
import LottiePlayer from "lottie-react";
import AttackAnimation from "../animations/attack.json";
import TrCountDown from "../components/TrCountDown";
import { Helmet } from "react-helmet";

import Decimal from "decimal.js-light";
type Props = {};

const TreasureDetail = (props: Props) => {
  const axios = new Network();
  const { treasureID } = useParams();
  const [data, setData] = React.useState<any>();
  const openToast = useToast();
  const [scoutDialog, setScoutDialog] = useState({
    open: false,
    step: 0,
    id: 0,
  });
  const [scoutData, setScoutData] = useState<any>({});
  const timeAgo = new TimeAgo('en');
  const myScouts = useSelector((state:any) => state.myScouts);

  const [loading, setLoading] = useState(false);
  const [adventures, setAdventures] = React.useState<any>();
  const [pendingTime, setPendingTime] = React.useState<any>(null);
  const pendingRef = React.useRef<any>(null);
  const [selectStep, setSelectStep] = useState(1);
  const [show, setShow] = useState({
    open: false,
    target: null,
    item: null,
  });
  const target = useRef(null);
  const dispatch = useDispatch();
  const [selectDialog, setSelectDialog] = useState({ open: false, id: 0 });
  const [findDialog, setFindDialog] = useState({
    open: false,
    isCaptain: false,
  });
  const handleScout = async (id: number) => {
    if (!scoutDialog.open) return;
    const response = await axios.CallApi({
      url: `/adventure/${id}/scout`,
      method: "get",
    });
    openToast(response);
    if (!response.ok) return;
    setScoutData(response.data);
    dispatch(
      appendScout({
        ...response.data,
        id,
        date: Date.now(),
      })
    );
    setScoutDialog((prev) => ({ ...prev, step: 2 }));
  };
  const handleCloseSelect = () => {
    setSelectDialog((prev) => ({ ...prev, open: false }));
  };
  const handleOpenSelect = (id: number) => {
    setSelectDialog((prev) => ({ ...prev, open: true, id }));
  };

  const handleCloseFindDialog = () => {
    setFindDialog((prev) => ({ ...prev, open: false }));
  };
  const handleOpenFindDialog = (isCaptain?: boolean) => {
    setFindDialog((prev) => ({
      ...prev,
      open: true,
      isCaptain: Boolean(isCaptain),
    }));
  };
  const handleGetData = async () => {
    setLoading(true);
    const response = await axios.CallApi({
      url: "/treasure/" + treasureID,
      method: "get",
    });
    // if (!response.ok) console.log(response)  f
    const id = parseInt(treasureID || "0");
    // const positions = MapPositions[id]?.sort(() => .5 - Math.random())
    const positions = MapPositions[9];
    setAdventures(
      response?.data?.adventures?.map((item, key) => ({
        ...item,
        ref: React.createRef(),
        position: positions[key],
      }))
    );

    setData({
      ...response?.data,
      treasureDetails: JSON.parse(response.data.jsonData),
    });
    setLoading(false);
  };
  React.useEffect(() => {
    handleGetData();
  }, [treasureID]);

  const captainAvailability = React.useMemo(() => {
    if (adventures?.find((adv) => adv.competition)) {
      return false;
    }
    return true;
  }, [adventures]);

  return (
    <SkeletonTheme
      enableAnimation={!isMobile}
      baseColor="#493e2f"
      highlightColor="#5f503b"
    >
      {data && (
        <Helmet>
          <title key={"title"}>
            {data?.treasureDetails?.name || ""} | Chain Of Legends
          </title>
        </Helmet>
      )}
      <div onClick={() => setShow((prev) => ({ ...prev, open: false }))}>
        <br />
        <br />
        <br />
        <MidBox noTitle modal className="container">
          <>
            <div className="">
              <Row className="flex-wrap-reverse align-items-end">
                <Col className="h-100" xl={3} lg={4} md={6} xs={12}>
                  {loading ? (
                    <div className="tr-data gap-5 d-flex h-100 flex-column  mt-3 mt-md-0">
                      <div className="text-center bt-line py-2 fs-big">
                        <Skeleton width={70} height={15} />
                      </div>
                      <div>
                        <div className="tr-status-box ">
                          <Skeleton height={15} width={80} />
                        </div>
                      </div>
                      <div className="">
                        {[1, 2, 3].map((item, i) => (
                          <Skeleton
                            height={"50px"}
                            borderRadius={0}
                            className="mt-2"
                          />
                        ))}
                      </div>
                      <div className="px-2  d-flex mb-3">
                        <div className="tr-status-time-box text-center fs-tiny">
                          <div>
                            <Skeleton width={50} count={1} />
                          </div>
                        </div>
                        <div className="tr-status-arr-box text-center fs-tiny">
                          <div>
                            {/* <img width={20} height={20} src="/assets/images/icons/icon-dart.png" /> */}

                            <Skeleton width={50} count={1} />
                          </div>
                        </div>
                      </div>

                      <div className="px-2 mb-2  text-justify dark-gray fs-tiny mt-3">
                        <Skeleton
                          width={"100%"}
                          className="mb-2"
                          height={14}
                          borderRadius={4}
                          count={3}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="tr-data d-flex  flex-column gap-4 h-100 mt-3 mt-md-0">
                      <div className="text-center bt-line py-2 fs-big">
                        {data?.treasureDetails?.name}
                      </div>
                      <div>
                        {/* {userInfo?.doubleRewardTreasure && userInfo.doubleRewardTreasure===+treasureID!&&
                 (
                    <OverlayTrigger trigger={"click"}
                      overlay={
                        <Popover>
                          <Popover.Body>
                          Capture this treasure Island to receive <span className="bold text-danger">
                            2x Reward </span> for first time without extra collecting time!   <Countdown date={Date.UTC(2023,9,7,12,0,0,0)}/>
                          
                          <br/>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div
                        className="badge-red mx-auto pointer rounded"
                        style={{
                          zIndex: 10,
                          width:'fit-content'
                        }}
                      >
                        2x Reward
                      </div>
                    </OverlayTrigger>
                  )} */}
                        {+treasureID! === 13 && (
                          <div>
                            <TrCountDown
                              className="bg-gradient-red py-2 fs-med"
                              styles={{
                                textAlign: "center",
                                borderRadius: 0,
                                textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                              }}
                            />
                          </div>
                        )}
                        <div className="tr-status-box ">
                          <div>
                            <img
                              width={28}
                              height={28}
                              src="/assets/images/icons/cleg.png"
                            />
                            <img
                              className="mt-n2"
                              width={28}
                              height={28}
                              src="/assets/images/icons/stone.png"
                            />
                            <img
                              className="ms-n2"
                              width={28}
                              height={28}
                              src="/assets/images/icons/metal.png"
                            />
                          </div>

                          <span className="fs-lg">
                            {ThousandsSeparator(roundFloat(data?.clegReward))}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        {data?.rewardTroops?.map((item) => (
                          <div
                            className="d-flex px-2 my-3 justify-content-between align-items-center"
                            style={{
                              flex: 1,
                              minWidth: 100,
                              background: "#454036",
                            }}
                          >
                            <div>
                              <img
                                height={50}
                                width="auto"
                                src={item.icon}
                                style={{ borderRadius: "50%" }}
                              />
                            </div>
                            <div className="fs-tiny">{item.name}</div>
                            <div className="text-secondary fs-tiny">
                              Chance : {item.chance} %
                            </div>
                            <div className="text-secondary fs-tiny">
                              Level : {item.level}
                            </div>
                          </div>
                        ))}
                        <div></div>

                        {/* <PerfectScrollbar >
                                        <div style={{ flexWrap: 'nowrap' }} className="row text-center gap-1 py-1 " >
                                            {data?.rewardTroops?.map((item) => (
                                                <div className='d-flex flex-column  align-items-center'
                                                    style={{ background: '#454036', flex: 1, minWidth: 100 }}
                                                >
                                                    <div>
                                                        <img height={50} width="auto" src={item.icon} style={{ borderRadius: '50%' }} />
                                                    </div>
                                                    <div className="fs-tiny">
                                                        {item.name}
                                                    </div>
                                                    <div className='text-secondary fs-tiny'>
                                                        Chance  :   {item.chance} %
                                                    </div>
                                                    <div className='text-secondary fs-tiny'>
                                                        Level  :   {item.level}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </PerfectScrollbar> */}
                        {isMobile && [
                          <div className="fs-lg mt-2 mb-1 text-center">
                            Item Rewards
                          </div>,
                          <PerfectScrollbar
                            style={{ maxHeight: 230 }}
                            className="px-2 pe-3"
                          >
                            <div className="row  text-center justify-content-center py-1 ">
                              {data?.rewardItems?.map((item) => (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip className="fs-tiny" {...props}>
                                      {item.name} - LVL {item.level} Chance:{" "}
                                      {item.chance}%
                                    </Tooltip>
                                  }
                                  delay={{ show: 100, hide: 200 }}
                                >
                                  <div
                                    className="d-flex ms-2 mb-2 p-relative flex-column justify-content-center align-items-center"
                                    style={{
                                      background: "#454036",
                                      width: 60,
                                      height: 60,
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <div>
                                      <img
                                        height={50}
                                        width="auto"
                                        src={item.icon}
                                      />
                                    </div>
                                    <div className="text-secondary fs-tiny"></div>
                                    <Badge
                                      bg="none"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        width: "fit-content",
                                        bottom: -6,
                                        fontSize: 12,
                                        borderRadius: "50%",
                                        marginInline: "auto",
                                        userSelect: "none",
                                        backgroundColor: "#342f24",
                                      }}
                                    >
                                      {item.chance} %
                                    </Badge>
                                    <Badge
                                      bg="none"
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                        fontSize: 12,
                                        borderRadius: "50%",
                                        userSelect: "none",
                                        backgroundColor: "#342f24",
                                      }}
                                    >
                                      {item.level}
                                    </Badge>
                                  </div>
                                </OverlayTrigger>
                              ))}
                            </div>
                          </PerfectScrollbar>,
                        ]}
                      </div>
                      <div className="px-2 mt-3 mt-md-0 d-flex mb-3">
                        <div className="tr-status-time-box fs-tiny">
                          <div>
                            <img
                              width={20}
                              height={20}
                              src="/assets/images/icons/timer.png"
                            />
                            Time: {roundFloat(data?.minTravelMinutes / 60, 1)}{" "}
                            Hours
                          </div>
                        </div>
                        <div className="tr-status-arr-box fs-tiny">
                          <div>
                            {/* <img width={20} height={20} src="/assets/images/icons/icon-dart.png" /> */}
                            Available : {data?.availablePerDay} <>(per day)</>
                          </div>
                        </div>
                      </div>
                      {/* {data && !isMobile && (
                        <CaptainCampaignSingle
                          disabled={!captainAvailability}
                          onAttack={() => handleOpenFindDialog(true)}
                          id={treasureID}
                          difficulty={data.difficulty}
                        />
                      )} */}

                      <div className="px-2 mb-2  text-justify dark-gray fs-tiny ">
                        {data?.treasureDetails?.Description}
                      </div>
                    </div>
                  )}
                </Col>

                {isMobile && (
                  <Col xs={12}>
                    {/* <CaptainCampaignSingle
                      disabled={!captainAvailability}
                      onAttack={handleOpenFindDialog}
                      id={treasureID}
                      difficulty={data?.difficulty}
                    /> */}
                    <div className="mt-4 ">Active Adventures</div>
                    <PerfectScrollbar
                      style={{ maxHeight: 400 }}
                      className="mt-1"
                    >
                      <div className="d-flex my-2 flex-column ">
                        {adventures?.map((adv: any, key) =>
                          adv.underAttack ? (
                            <div
                              className={classNames(
                                { "bg-highlight": adv.competition },
                                "pointer d-flex justify-content-between align-items-center fs-small bg-2 p-2 rounded my-1"
                              )}
                            >
                              <div>
                                <img
                                  width={30}
                                  height={30}
                                  className="me-1"
                                  src={
                                    adv.competition
                                      ? "/assets/images/chest/" +
                                        data.difficulty +
                                        ".webp"
                                      : "/assets/images/icons/active-treasure.png"
                                  }
                                />
                                {adv.competition ? (
                                  "Captain Treasure"
                                ) : (
                                  <>Adventure #{adv.id}</>
                                )}
                              </div>

                              <div className=" p-2 d-flex align-items-center disabled rounded fs-small">
                                <LottiePlayer
                                  style={{ width: 30, height: 30 }}
                                  animationData={AttackAnimation}
                                  loop
                                  autoPlay
                                />{" "}
                                Under Attack
                              </div>
                            </div>
                          ) : adv.state === 2 ? (
                            <div
                              onClick={(e) => {
                                // e.stopPropagation();
                                handleOpenSelect(adv.id);
                              }}
                              className={classNames(
                                { "bg-highlight": adv.competition },
                                "pointer d-flex justify-content-between align-items-center fs-small bg-2 p-2 rounded my-1"
                              )}
                            >
                              <div>
                                <img
                                  width={30}
                                  height={30}
                                  className="me-1"
                                  src={
                                    adv.competition
                                      ? "/assets/images/chest/" +
                                        data.difficulty +
                                        ".webp"
                                      : "/assets/images/icons/active-treasure.png"
                                  }
                                />
                                {adv.competition ? (
                                  "Captain Treasure"
                                ) : (
                                  <>Adventure #{adv.id}</>
                                )}
                              </div>

                              <div className=" p-2 rounded bg-2">
                                <img
                                  width={20}
                                  className="me-1"
                                  src="/assets/images/icons/attack.png"
                                />{" "}
                                Attack
                              </div>
                            </div>
                          ) : (
                            <motion.div
                              initial={{ scale: 0 }}
                              animate={{ scale: 1 }}
                              transition={{ delay: (key + 1) / 20 }}
                            >
                              <div
                                // onClick={(e) => {
                                //     // e.stopPropagation();
                                //     // setShow({ open: true, target: adv?.ref, item: adv })
                                // }}
                                className={classNames(
                                  { "bg-highlight": adv.competition },
                                  "pointer d-flex justify-content-between align-items-center fs-small bg-2 p-2 rounded my-1"
                                )}
                              >
                                <div>
                                  <img
                                    width={30}
                                    height={30}
                                    className="me-1"
                                    src={
                                      adv.competition
                                        ? "/assets/images/chest/" +
                                          data.difficulty +
                                          ".webp"
                                        : "/assets/images/icons/active-raid.png"
                                    }
                                  />
                                  {adv.competition ? (
                                    "Captain Treasure Raid"
                                  ) : (
                                    <> Raid #{adv.id}</>
                                  )}
                                </div>

                                <Link to={"/treasure/join/" + adv.id}>
                                  <div className=" p-2 rounded bg-2">
                                    <img
                                      width={20}
                                      className="me-1"
                                      src="/assets/images/icons/join-raid.png"
                                    />{" "}
                                    Join
                                  </div>
                                </Link>
                              </div>
                            </motion.div>
                          )
                        )}
                      </div>
                    </PerfectScrollbar>
                  </Col>
                )}
                <Col xl={9} lg={8} md={12} xs={12}>
                  <motion.div
                    transition={{ delay: 0.3 }}
                    initial={{ opacity: 0, x: 200, minHeight: "100%" }}
                    className="p-relative text-center h-100 d-flex flex-column justify-content-between align-items-center"
                    animate={{ opacity: 1, x: 0 }}
                  >
                    <Link
                      to="/treasure/select"
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                      }}
                      className="icon-btn"
                    >
                      <img
                        height={10}
                        src="/assets/images/icons/arrow.png"
                        style={{ transform: "rotate(90deg)" }}
                      />
                    </Link>
                    <div
                      onClick={() => handleOpenFindDialog()}
                      className="find-treasure"
                    >
                      <img
                        width={25}
                        height={25}
                        color="#000"
                        src="/assets/images/icons/find.png"
                      />
                      <div className="txt">Find a Treasury</div>
                    </div>

                    {!isMobile &&
                      adventures?.map((adv: any, key) =>
                        adv.underAttack ? (
                          <div>
                            <OverlayTrigger
                              rootClose
                              flip
                              trigger="click"
                              placement="top"
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Header className="bold" as="h3">
                                    <CustomIcon icon="blades" />
                                    Under Attack
                                  </Popover.Header>
                                  <Popover.Body>
                                    You can't attack to this treasure. It is
                                    already under attack by other player(s).
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                style={{
                                  ...adv?.position?.xs,
                                  position: "absolute",
                                }}
                                className={"pointer bg-dark button pulse  m-0 "}
                              >
                                {adv.competition ? (
                                  <>
                                    <img
                                      width={35}
                                      src={
                                        "/assets/images/chest/" +
                                        data.difficulty +
                                        ".webp"
                                      }
                                    />
                                    <LottiePlayer
                                      style={{
                                        width: 20,
                                        height: 20,
                                        position: "absolute",
                                        right: 0,
                                        marginInline: "auto",
                                        zIndex: 10,
                                        left: 0,
                                        bottom: -15,
                                      }}
                                      animationData={AttackAnimation}
                                      loop
                                      autoPlay
                                    />
                                  </>
                                ) : (
                                  <LottiePlayer
                                    style={{ width: 30, height: 30 }}
                                    animationData={AttackAnimation}
                                    loop={false}
                                    autoPlay={false}
                                  />
                                )}
                              </motion.div>
                            </OverlayTrigger>
                          </div>
                        ) : adv.state === 2 ? (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            onClick={(e) => {
                              // e.stopPropagation();
                              handleOpenSelect(adv.id);
                            }}
                            style={{
                              ...adv?.position?.xs,
                              position: "absolute",
                            }}
                            className={
                              "pointer button pulse  m-0 " +
                              (myScouts[adv.id] && "bg-info")
                            }
                          >
                            {adv.competition ? (
                              <img
                                width={35}
                                src={
                                  "/assets/images/chest/" +
                                  data.difficulty +
                                  ".webp"
                                }
                              />
                            ) : (
                              <img
                                width={30}
                                src="/assets/images/icons/active-treasure.png"
                              />
                            )}
                          </motion.div>
                        ) : (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                          >
                            <OverlayTrigger
                              rootClose
                              flip
                              trigger="click"
                              placement="top"
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Header as="h3">
                                    Ongoing Raid to Free/Occupied Treasure
                                  </Popover.Header>
                                  <Popover.Body>
                                    Join ongoing raid and get a share of reward.
                                  </Popover.Body>
                                  <Link to={"/treasure/join/" + adv.id}>
                                    <Button
                                      className="mb-2 mx-auto "
                                      variant="wide-primary"
                                    >
                                      <img
                                        width={28}
                                        className="me-2"
                                        src="/assets/images/icons/join-raid.png"
                                      />{" "}
                                      Join Raid
                                    </Button>
                                  </Link>
                                </Popover>
                              }
                            >
                              <div
                                // onClick={(e) => {
                                //     // e.stopPropagation();
                                //     // setShow({ open: true, target: adv?.ref, item: adv })
                                // }}
                                style={{
                                  ...adv?.position?.xs,
                                  position: "absolute",
                                }}
                                className="pointer button  m-0 pulse"
                              >
                                {adv.competition ? (
                                  <img
                                    width={35}
                                    src={
                                      "/assets/images/chest/" +
                                      data.difficulty +
                                      ".webp"
                                    }
                                  />
                                ) : (
                                  <img
                                    width={30}
                                    height={30}
                                    src="/assets/images/icons/active-raid.png"
                                  />
                                )}
                              </div>
                            </OverlayTrigger>
                          </motion.div>
                        )
                      )}
                    <img
                      src={`/assets/images/lands/treasure/${treasureID}.png`}
                      style={{
                        maxHeight: 800,
                        flex: 1,
                        marginInline: "auto",
                        maxWidth: "100%",
                      }}
                    />
                    {!isMobile && (
                      <div className="d-flex tr-data align-items-center me-4 w-100 mt-4 flex-row">
                        <div className="fs-lg px-2 text-center">Items</div>
                        <PerfectScrollbar
                          style={{ maxWidth: "100%" }}
                          className="px-2 pe-3"
                        >
                          <div
                            style={{ flexWrap: "nowrap" }}
                            className="row  text-center  py-1 "
                          >
                            {data?.rewardItems?.map((item) => (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip className="fs-tiny" {...props}>
                                    {item.name} - LVL {item.level} Chance:{" "}
                                    {item.chance}%
                                  </Tooltip>
                                }
                                delay={{ show: 100, hide: 200 }}
                              >
                                <div
                                  className="d-flex ms-2 mb-2 p-relative flex-column justify-content-center align-items-center"
                                  style={{
                                    background: "#454036",
                                    width: 60,
                                    height: 60,
                                    borderRadius: "50%",
                                  }}
                                >
                                  <div>
                                    <img
                                      height={50}
                                      width="auto"
                                      src={item.icon}
                                    />
                                  </div>
                                  <div className="text-secondary fs-tiny"></div>
                                  <Badge
                                    bg="none"
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      right: 0,
                                      width: "fit-content",
                                      bottom: -6,
                                      fontSize: 12,
                                      borderRadius: "50%",
                                      marginInline: "auto",
                                      userSelect: "none",
                                      backgroundColor: "#342f24",
                                    }}
                                  >
                                    {item.chance} %
                                  </Badge>
                                  <Badge
                                    bg="none"
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      top: 0,
                                      fontSize: 12,
                                      borderRadius: "50%",
                                      userSelect: "none",
                                      backgroundColor: "#342f24",
                                    }}
                                  >
                                    {item.level}
                                  </Badge>
                                </div>
                              </OverlayTrigger>
                            ))}
                          </div>
                        </PerfectScrollbar>
                      </div>
                    )}
                  </motion.div>
                </Col>
              </Row>
            </div>
          </>
        </MidBox>
        <Dialog
          noFooter
          title="Solo? Or Multiplayer?"
          open={selectDialog.open}
          onClose={() => {
            handleCloseSelect();
            setSelectStep(1);
          }}
        >
          {selectStep === 1 ? (
            <>
              <p className="fs-med dark-yellow">
                This Treasury is already occupied by another squad. Do you
                intend to attack Solo? Or Multiplayer? "Scout" button helps you
                decide!
              </p>
              <div
                className="d-flex justify-content-center align-items-center flex-wrap mt-2 gap-2"
                style={{ marginBottom: -50 }}
              >
                <Link
                  to={`/treasure/order?treasureId=${treasureID}&adventureMode=1&adventureId=${selectDialog.id}`}
                >
                  <Button
                    variant="secondary"
                    className="d-flex align-items-center"
                    style={{ backgroundSize: "100% 100%" }}
                  >
                    <img
                      width={22}
                      height={22}
                      src="/assets/images/icons/attack.png"
                    />
                    &nbsp;Attack
                  </Button>
                </Link>
                <Button
                  onClick={() => setSelectStep(2)}
                  style={{
                    backgroundSize: "100% 100%",
                    minHeight: 20,
                    padding: "10px 10px 14px 10px",
                  }}
                  variant="wide"
                >
                  <img
                    width={25}
                    className="me-2"
                    height={25}
                    src="/assets/images/icons/raid.png"
                  />{" "}
                  Create Raid
                </Button>
                <Button
                  onClick={() => {
                    handleCloseSelect();
                    setScoutDialog({
                      open: true,
                      step: 0,
                      id: selectDialog.id,
                    });
                    setSelectStep(1);
                  }}
                  variant="primary"
                  className="d-flex align-items-center"
                  style={{ backgroundSize: "100% 100%" }}
                >
                  <img
                    width={28}
                    height={22}
                    src="/assets/images/icons/scout.png"
                  />
                  &nbsp;Scout
                </Button>
              </div>
            </>
          ) : (
            <>
              <a
                style={{
                  position: "absolute",
                  left: 40,
                  top: 50,
                }}
                className="icon-btn"
                onClick={() => setSelectStep(1)}
              >
                <img
                  height={10}
                  src="/assets/images/icons/arrow.png"
                  style={{ transform: "rotate(90deg)" }}
                />
              </a>
              <p className="fs-med dark-yellow">
                You have close friends; choose "Private Raid" and share your
                link with them. Otherwise, create "Public Raid" and wait for
                others to join you.
              </p>
              <div
                className="d-flex justify-content-center align-items-center flex-wrap mt-2 gap-2"
                style={{ marginBottom: -40 }}
              >
                <Link
                  to={`/treasure/order?treasureId=${treasureID}&adventureMode=4&raid=true&adventureId=${selectDialog.id}`}
                >
                  <Button
                    style={{ backgroundSize: "100% 100%" }}
                    variant="primary"
                  >
                    Public Raid
                  </Button>
                </Link>
                <Link
                  to={`/treasure/order?treasureId=${treasureID}&adventureMode=2&raid=true&adventureId=${selectDialog.id}`}
                >
                  <Button
                    style={{ backgroundSize: "100% 100%" }}
                    variant="default"
                  >
                    Private Raid
                  </Button>
                </Link>
                <Link
                  to={`/treasure/order?treasureId=${treasureID}&adventureMode=3&raid=true&adventureId=${selectDialog.id}`}
                >
                  <Button
                    style={{ backgroundSize: "100% 100%" }}
                    variant="success"
                  >
                    Guild Raid
                  </Button>
                </Link>
              </div>
            </>
          )}
        </Dialog>
        <Dialog
          noFooter
          title={selectStep === 1 ? "Solo? Or Multiplayer?" : "Raid Type"}
          open={findDialog.open}
          onClose={() => {
            handleCloseFindDialog();
            setSelectStep(1);
          }}
        >
          {selectStep === 1 ? (
            <>
              <p className="fs-med dark-yellow">
                Choose one of the following Attack types; Choose "Solo" if you
                are capable enough! If not, create a "Raid"!
              </p>
              <div
                className="d-flex justify-content-center align-items-center flex-wrap mt-2 gap-2"
                style={{ marginBottom: -40 }}
              >
                <Link
                  to={`/treasure/order?treasureId=${treasureID}&adventureMode=1${
                    findDialog.isCaptain ? "&captainTreasure=true" : ""
                  }`}
                >
                  <Button
                    variant="default"
                    style={{ backgroundSize: "100% 100%" }}
                  >
                    <img
                      width={22}
                      height={22}
                      src="/assets/images/icons/attack.png"
                    />
                    &nbsp;Solo
                  </Button>
                </Link>
                <Button
                  onClick={() => setSelectStep(2)}
                  style={{
                    backgroundSize: "100% 100%",
                    minHeight: 20,
                    padding: "10px 10px 14px 10px",
                  }}
                  variant="wide"
                >
                  <img
                    width={25}
                    className="me-2"
                    height={25}
                    src="/assets/images/icons/raid.png"
                  />{" "}
                  Create Raid
                </Button>
              </div>
            </>
          ) : (
            <>
              <a
                style={{
                  position: "absolute",
                  left: 40,
                  top: 50,
                }}
                className="icon-btn"
                onClick={() => setSelectStep(1)}
              >
                <img
                  height={10}
                  src="/assets/images/icons/arrow.png"
                  style={{ transform: "rotate(90deg)" }}
                />
              </a>
              <p className="fs-med dark-yellow">
                You have close friends; choose "Private Raid" and share your
                link with them. Otherwise, create "Public Raid" and wait for
                others to join you.{" "}
              </p>
              <div
                className="d-flex justify-content-center align-items-center flex-wrap mt-2 gap-2"
                style={{ marginBottom: -40 }}
              >
                <Link
                  to={`/treasure/order?treasureId=${treasureID}&adventureMode=4&raid=true${
                    findDialog.isCaptain ? "&captainTreasure=true" : ""
                  }`}
                >
                  <Button
                    style={{ backgroundSize: "100% 100%" }}
                    variant="primary"
                  >
                    Public Raid
                  </Button>
                </Link>
                <Link
                  to={`/treasure/order?treasureId=${treasureID}&adventureMode=3&raid=true&adventureId=${selectDialog.id}`}
                >
                  <Button
                    style={{ backgroundSize: "100% 100%" }}
                    className="px-2"
                    variant="success"
                  >
                    Guild Raid
                  </Button>
                </Link>
                <Link
                  to={`/treasure/order?treasureId=${treasureID}&adventureMode=2&raid=true${
                    findDialog.isCaptain ? "&captainTreasure=true" : ""
                  }`}
                >
                  <Button
                    style={{ backgroundSize: "100% 100%" }}
                    variant="default"
                  >
                    Private Raid
                  </Button>
                </Link>
              </div>
            </>
          )}
        </Dialog>
        {scoutDialog.open && (
          <Dialog
            title="Scout"
            noFooter
            open={scoutDialog.open}
            onClose={() =>
              setScoutDialog((prev) => ({ ...prev, open: false, step: 0 }))
            }
          >
            <div
              style={{ marginBottom: -50, position: "relative", zIndex: 20 }}
            >
              {scoutDialog.step === 0 ? (
                <div className="d-flex align-items-center flex-column gap-3">
                  {myScouts?.[scoutDialog?.id] && (
                    <div className="rounded d-flex align-items-center fs-med w-100 p-2 bg-2">
                      <CustomIcon width={45} icon="scout" /> Last Scout :{" "}
                      {timeAgo.format(
                        new Date(myScouts?.[scoutDialog?.id]?.date)
                      )}
                      <Button
                        onClick={() => {
                          setScoutDialog((prev) => ({ ...prev, step: 2 }));
                          setScoutData(myScouts?.[scoutDialog?.id]);
                        }}
                        className="ms-auto"
                        size="small"
                        variant="primary"
                      >
                        View
                      </Button>
                    </div>
                  )}
                  <div className="fs-med ">
                    Scout means Information! By paying{" "}
                    {ThousandsSeparator(
                      roundFloat(data?.scoutCost, 3, Decimal.ROUND_UP)
                    )}{" "}
                    $CLEG, you can see the formation of enemy troops and their
                    status! If you are sure, click on "Continue"!
                  </div>
                  <Badge
                    style={{ width: "fit-content", fontSize: 15 }}
                    className="text-dark fs-med"
                    bg="warning"
                  >
                    Cost :{" "}
                    <img width={25} src="/assets/images/icons/cleg.png" />{" "}
                    {ThousandsSeparator(
                      roundFloat(data?.scoutCost, 3, Decimal.ROUND_UP)
                    )}{" "}
                    CLEG
                  </Badge>
                  <Alert className="py-2 px-2" variant="warning">
                    <div style={{ fontSize: 12 }}>
                      If you attack to this treasure, player will be informed
                      and he may send reinforcement unit to the battle.       
                    </div>
                  </Alert>
                  <Button
                    onClick={() => {
                      setScoutDialog((prev) => ({ ...prev, step: 1 }));
                      setPendingTime(0);
                      pendingRef.current = 0;
                      const calc = () => {
                        if (pendingRef.current === 15) {
                          handleScout(scoutDialog.id);
                          return;
                        }
                        setPendingTime((prev) => prev + 0.25);
                        pendingRef.current = pendingRef.current + 0.25;
                        setTimeout(() => {
                          calc();
                        }, 250);
                      };
                      calc();
                    }}
                    variant="primary"
                  >
                    Continue
                  </Button>
                </div>
              ) : scoutDialog.step === 1 ? (
                <>
                  <ProgressBar
                    className="mt-3"
                    animated
                    striped
                    variant="warning"
                    style={{
                      minWidth: 190,
                      height: 15,
                      color: "#000",
                      background: "rgba(0,0,0,.09)",
                    }}
                    now={(pendingTime * 100) / 15}
                  />
                  <div className="text-center mt-3">Scouting ...</div>
                </>
              ) : (
                <>
                  <div className="d-flex w-100 flex-column gap-2 align-items-center">
                    <div className="w-100 d-flex align-items-center gap-2">
                      <div style={{ minWidth: 120 }}>Collected Cleg</div>
                      <ProgressBar
                        animated
                        striped
                        variant="primary"
                        style={{
                          flex: 1,
                          height: 10,
                          color: "#000",
                          background: "rgba(0,0,0,.09)",
                        }}
                        now={
                          (scoutData?.collectedCleg / scoutData?.totalCleg) *
                          100
                        }
                      />
                      <div className="text-end ms-2 fs-small">
                        {scoutData?.totalCleg} / {scoutData?.collectedCleg}
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center gap-2">
                      <div style={{ minWidth: 120 }}>Collected Stone</div>
                      <ProgressBar
                        animated
                        striped
                        variant="primary"
                        style={{
                          flex: 1,
                          height: 10,
                          color: "#000",
                          background: "rgba(0,0,0,.09)",
                        }}
                        now={
                          (scoutData?.collectedStone / scoutData?.totalStone) *
                          100
                        }
                      />
                      <div className="text-end ms-2 fs-small">
                        {scoutData?.totalStone} / {scoutData?.collectedStone}
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center gap-2">
                      <div style={{ minWidth: 120 }}>Collected Iron</div>
                      <ProgressBar
                        animated
                        striped
                        variant="primary"
                        style={{
                          flex: 1,
                          height: 10,
                          color: "#000",
                          background: "rgba(0,0,0,.09)",
                        }}
                        now={
                          (scoutData?.collectedIron / scoutData?.totalIron) *
                          100
                        }
                      />
                      <div className="text-end ms-2 fs-small">
                        {scoutData?.totalIron} / {scoutData?.collectedIron}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-2 flex-wrap gap-2 align-items-center">
                    <div>Troops:</div>
                    {scoutData?.formation?.map((troop, index: number) => (
                      <OverlayTrigger
                        // popperConfig={{}}
                        placement={"top"}
                        key={index + "p" + troop?.x + troop?.y}
                        overlay={
                          <Tooltip id={`tooltip-${"top"}`}>
                            {troop?.playerTroop?.troop?.name} (Level{" "}
                            {troop?.level || troop?.playerTroop?.level})
                          </Tooltip>
                        }
                      >
                        <div style={{ padding: 3 }} className={`dark-badge`}>
                          <img
                            style={{
                              borderRadius: 50,
                              zIndex: 10,
                              position: "relative",
                            }}
                            width={40}
                            height={40}
                            src={troop?.playerTroop?.troop?.icon}
                          />
                          <div className="units fs-tiny">
                            {troop?.level || troop?.playerTroop?.level}
                          </div>
                        </div>
                      </OverlayTrigger>
                    ))}
                  </div>
                  <div className="d-flex mt-3 justify-content-center">
                    <Button
                      className=" bg-100"
                      onClick={() =>
                        setScoutDialog((prev) => ({ ...prev, step: 0 }))
                      }
                      variant="default"
                    >
                      Back
                    </Button>
                    <Button
                      className="bg-100"
                      onClick={() =>
                        setScoutDialog((prev) => ({
                          ...prev,
                          open: false,
                          step: 0,
                        }))
                      }
                      variant="primary"
                    >
                      Done
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Dialog>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default TreasureDetail;
