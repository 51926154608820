import React from 'react'

type Props = {
    style?:React.CSSProperties;

}

const CircularProgress = ({style,}: Props) => {
    return (
        <img
        width={25}
        height={25}
      style={{marginRight:5,...style}}
            src="/assets/images/Gear-loading.svg"
        />
    )
}

export default CircularProgress