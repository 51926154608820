import React from "react";
import WatchBox from "./watch";
import CampBox from "./camp";
import RankingBox from "./ranking";
import ShopBox from "./shop";
import MessageLogger from "../home/message-logger";
import { motion } from "framer-motion";
type Props = {};

const ArenaDashboard = (props: Props) => {
  return (
    <div className="arena-dashboard pb-5 pb-md-2 ">
      <MessageLogger/>
      <div  style={{minHeight:'100%', alignItems:'center'}} className="row gap-4 gap-md-0 ">
        <div className="col-lg-9">
          <div className="row gap-4 gap-md-0">
            <motion.div 
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: "easeInOut",delay:0 }}
            className="col-lg-6 p-0">
              <WatchBox />
            </motion.div>
            <motion.div 
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: "easeInOut" ,delay:0.1}}
            className="col-lg-6 p-0">  
              <CampBox />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, ease: "easeInOut", delay:.15}}
              className="col-lg-12">
             <ShopBox/>
            </motion.div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" ,delay:.2}}
        className="col-lg-3 p-0 pt-1">
          <RankingBox />
        </motion.div>
      </div>
    </div>
  );
};

export default ArenaDashboard;
