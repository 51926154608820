import React from 'react'
import { useSelector } from 'react-redux'
import events from '../constants/events'


const useEvents = () => {
    const userInfo = useSelector((state: any) => state.userInfo)
    const halloweenEvent = React.useMemo(() => {
        const status = {
            isStarted: false, isEnded: false, isActive: false,
            extraTreasureRewardMultiplier: 0,
            extraRewardTreasureId: 0,
            startDate: '',
            endDate: '',
        }
        if (!userInfo) return status
        if (new Date(userInfo.extraTreasureRewardStartDate).getTime() < Date.now()) status.isStarted = true
        if (new Date(userInfo.extraTreasureRewardEndDate).getTime() < Date.now()) status.isEnded = true
        if (status.isStarted && !status.isEnded) status.isActive = true
        if (status.isActive) {
            status.extraRewardTreasureId = userInfo.extraRewardTreasureId
            status.extraTreasureRewardMultiplier = userInfo.extraTreasureRewardMultiplier
        }
        status.startDate = userInfo.extraTreasureRewardStartDate
        status.endDate = userInfo.extraTreasureRewardEndDate
        return status
    }, [userInfo])

    const isPassed = (date: string | number) => {
        return new Date(date).getTime() <= Date.now()
    }

    const allEvents = React.useMemo(() => {
        let allData: {
            date: string | Date | number,
            image: string,
            link: string,
            isStarted: boolean;
            isEnded: boolean;
            id: string;
            description: string;
            timerSrc?: string;
        }[] = []
        for (let event of events) {
            const data: any = {}
            data.id = event.id
            data.description = event.description
            data.timerSrc = event.timer
            if (event.cycles) {
                event.cycles.map((cycle: any, i) => {
                    if (isPassed(cycle.startDate)) {
                        data.isStarted = true
                        data.image = cycle.asset
                        data.link = cycle.link || event.link
                        if (event.cycles?.[i + 1]) {
                            data.date = event.cycles[i + 1].startDate
                        } else {
                            data.date = event.end.date
                        }
                      return
                    }
                })
                allData.push(data)

            }
            else if (isPassed(event.end.date) && event.remove) {
                data.isEnded = true
                data.image = event.remove.asset
                data.date = event.remove.date
                allData.push(data)
            }
            else if (isPassed(event.start.date)) {
                data.isStarted = true
                data.image = event.end.asset
                data.date = event.end.date
                allData.push(data)
            }
            else {
                data.image = event.start.asset
                data.date = event.start.date
                allData.push(data)
            }

        }
        return allData;
    }, [events])
    return { halloweenEvent, events: allEvents }
}

export default useEvents