import React, { useEffect } from "react";
import { Toast as BTToast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CloseAlert } from "../redux/alert/action";
import { ToastType } from "../redux/alert/reducer";
type Props = {};

const Toast = (props: Props) => {
  const alert: ToastType = useSelector((state: any) => state?.alert);
  const dispatch = useDispatch();

  function closeToast() {
    dispatch(
      CloseAlert({
        ...alert,
        open: false,
      })
    );
  }

  return (
    <ToastContainer containerPosition="fixed" position="bottom-start">
      <BTToast
        bg={alert?.variant === "error" ? "danger" : alert?.variant}
        onClose={closeToast}
        show={alert?.open}
        delay={7000}
        autohide
      >
        <BTToast.Header>
          {/* <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />*/}

          <strong className="me-auto">
            {alert?.title
              ? alert.title
              : alert?.variant === "error"
              ? "Oops..!"
              : alert?.variant === "warning"
              ? "Warning !"
              : alert?.variant === "success"
              ? "Successful!"
              : "Coming Soon..!"}
          </strong>
          {/* <small>11 mins ago</small> */}
        </BTToast.Header>
        <BTToast.Body>{alert?.msg}</BTToast.Body>
      </BTToast>
    </ToastContainer>
  );
};

export default Toast;
