
export const actionTypes = {
    SET_QUESTS: 'SET_QUESTS',
    CLEAR_QUESTS: 'CLEAR_QUESTS',
};

export const SetQuests = (payload) => ({
    type: actionTypes.SET_QUESTS,
    payload,
});

export const CloseAlert = () => ({
    type: actionTypes.CLEAR_QUESTS,
});
