import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

class ModelCache {
    private static instance: ModelCache;
    private loadedModels: Record<string, boolean> = {};

    private constructor() { }

    // Singleton pattern: Ensures only one instance of the cache exists
    public static getInstance(): ModelCache {
        if (!ModelCache.instance) {
            ModelCache.instance = new ModelCache();
        }
        return ModelCache.instance;
    }

    // Check if a model is already loaded
    public isModelLoaded(src: string): boolean {
        return !!this.loadedModels[src];
    }

    // Mark a model as loaded
    public markModelAsLoaded(src: string): void {
        this.loadedModels[src] = true;
    }

    // Reset the cache (useful if you want to clear all loaded models)
    public resetLoadedModels(): void {
        this.loadedModels = {};
    }

    // Optionally, you can add methods to preload models or manage other aspects
    public preloadModel(src: string): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.isModelLoaded(src)) {
                resolve();
                return;
            }

            const loader = new GLTFLoader();
            loader.load(
                src,
                () => {
                    this.markModelAsLoaded(src);
                    resolve();
                },
                undefined,
                (error) => {
                    console.error("Error loading model:", error);
                    reject(error);
                }
            );
        });
    }
}


export default ModelCache;