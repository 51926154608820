import React, { FC } from "react";
import { Assets } from "../../../constants/assets";
import { RankingType } from "../../types";
import { motion } from "framer-motion";
import moment from "moment";
import { Tournament } from "./types";
import ThousandsSeparator from "../../../utils/thousands-separator";
import CountDown from "react-countdown";
type Props = {
  rankingData?: RankingType & Tournament;
  isLegion?: boolean;
};

const LeaderBoardBanner: FC<Props> = ({ rankingData, isLegion }) => {
  return (
    <motion.div
      // initial={{ y: 200, opacity: 0 }}
      // animate={{ y: 0, opacity: 1 }}
      // transition={{ duration: 0.5, delay: 0.1 }}
      className="leaderboard-banner mt-5 py-3"
    >
      <div className="vector-1" />
      <div>
        <img src={Assets.leaderBoard.cup} style={{ maxWidth: 120 }} />
      </div>
      <div className="d-flex flex-column gap-1 flex-1">
        <div>
          <img
            src={
              isLegion
                ? Assets.leaderBoard.legionBannerTitle
                : Assets.leaderBoard.bannerTitle
            }
            style={{ maxWidth: 280 }}
          />
        </div>
        <div className="light-yellow fs-small">
          {moment(rankingData?.startDate).format("MMM D ")} -{" "}
          {moment(rankingData?.endDate).format("MMM D, YYYY")}
        </div>
        <div className=" fs-small">{rankingData?.description}</div>
      </div>
      {rankingData?.theterAmount && rankingData?.bloodStoneAmount && (
        <div className="d-flex flex-column fs-big ">
          <div className="status-frame    d-flex align-items-center my-0 text-center  bg-100 py-2">
            <CountDown
              className="text-center text-danger w-100"
              date={new Date(rankingData?.endDate)}
            />
          </div>
          <div className="status-frame    d-flex align-items-center my-0 ">
            <img
              width="35"
              height="40"
              style={{ marginLeft: -7 }}
              className="mt-2"
              src={"/assets/images/icons/tether.png"}
            />
            <span>
              <span className="light-yellow">
                {ThousandsSeparator(rankingData?.theterAmount)}
              </span>
            </span>
            <img
              width="20"
              height="30"
              className="opacity-0"
              src="/assets/images/buttons/add-button.png"
            />
          </div>
          <div className="status-frame   d-flex align-items-center my-0 ">
            <img
              width="30"
              height="30"
              style={{ marginLeft: -5 }}
              className="ms-n2"
              src={Assets.icons.bloodStone}
            />
            <span>
              <span className="light-yellow">
                {ThousandsSeparator(rankingData?.bloodStoneAmount!)}
              </span>
            </span>
            <img
              width="20"
              height="30"
              className="opacity-0"
              src="/assets/images/buttons/add-button.png"
            />
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default LeaderBoardBanner;
