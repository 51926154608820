// ConnectorContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';
import Connector from './signalr-connection';  // Adjust the path as needed
import { useSelector } from 'react-redux';
import { useBattleContext } from '../context';

interface ConnectorContextType {
    connector: Connector | null;
    connectionStatus: string;
}

const ConnectorContext = createContext<ConnectorContextType | undefined>(undefined);

interface ConnectorProviderProps {
    hubUrl: string;
    children: ReactNode;
}

export const ConnectorProvider: React.FC<ConnectorProviderProps> = ({  hubUrl, children }) => {
    const [connector, setConnector] = useState<Connector | null>(null);
    const {connectionStatus,setConnectionStatus}=useBattleContext()   
    const isCalled = useRef<boolean>(false);
    const token = useSelector((state: any) => state.userToken);


    useEffect(() => {
        if(!token || !hubUrl ) return ;
        // type check
        isCalled.current = true;
        const newConnector = new Connector(token, hubUrl);
        newConnector.start(setConnectionStatus);
        setConnector(newConnector);
        return () => {
            if (newConnector.connection) {
                newConnector.connection.stop();
            }
            newConnector.clearEventHandlers();
        };
    }, [token, hubUrl,isCalled.current]);

    return (
        <ConnectorContext.Provider value={{ connector, connectionStatus }}>
            {children}
        </ConnectorContext.Provider>
    );
};

export const useConnector = (): ConnectorContextType => {
    const context = useContext(ConnectorContext);
    if (context === undefined) {
        throw new Error('useConnector must be used within a ConnectorProvider');
    }
    return context;
};
