import React from "react";
import classNames from "classnames";
type Props = {
  title?: React.ReactNode;
  id?: string;
  children: React.ReactNode;
  style?: React.CSSProperties | null;
  className?: string;
  containerClass?: string;
  modal?: boolean;
  noTitle?: boolean;
  containerStyle?: React.CSSProperties | null;
  noMargin?: boolean;
};

const MidBox = (props: Props) => {
  return (
    <div
      id={props.id}
      className={`mid-box ${props.className}`}
      style={props.style ? props.style : undefined}
    >
      {!props.noTitle && (
        <div className="mid-box-title d-flex align-items-center justify-content-center">
          {props.title}
        </div>
      )}
      <div className={`mid-box-top ${props.modal && "modal-top"}`} />
      <div
        className={classNames(
          `mid-box-body px-4 px-sm-3 px-md-5  `,
          {'no-margin': props.noMargin},
          { 'modal-body': props.modal },
          props.containerClass
        )}
        style={props.containerStyle ? props.containerStyle : undefined}
      >
        {props.children}
      </div>
      <div className={`mid-box-bott ${props.modal && "modal-bottom"}`} />
    </div>
  );
};

export default MidBox;
