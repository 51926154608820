import React, { useEffect, useState } from 'react'
import Network, { IsBeta } from '../utils/Network'
import { CustomIcon } from './Icons'
import Button from './Button'
import { roundFloat } from '../utils/round-float'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
type Props = {
    id: string | number;
    treasureType: number
}

const TreasureInfo = (props: Props) => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = React.useState<any>()
    const axios = new Network()
    const handleGetTrData = async () => {
        setLoading(true)
        const response = await axios.CallApi({
            url: '/treasure/' + props.id,
            method: 'get'
        })
        setData(response?.data)
        setLoading(false)
    }

    useEffect(() => {
        if (props.id) handleGetTrData()
    }, [props.id])

    if (loading||!props.id) return <></>
    return (
        <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            className='tr-info pe-3'>
            <div className='d-flex align-items-center gap-2'>
                <img className='tr-img' src={props.treasureType === 2 ? `/assets/images/lands/treasure/${props.id}.png` : `/assets/images/icons/dungeon/${props.id}.png`} style={{
                    width: props.treasureType === 2 ? 88 : 70,
                    marginInline: 'auto', maxWidth: '100%'
                }} />
                <div className='fs-small'>
                    <div>
                        <CustomIcon className='me-1' width={20} height={20} icon="blades" />
                        Difficulty: {data?.difficulty}
                    </div>
                    <div>
                        <CustomIcon className='me-1' width={20} height={20} icon="cleg" />
                        Resource:   {roundFloat(data?.clegReward, 0, 2)}
                    </div>
                    <a target='_blank' href={props.treasureType === 2 ? `/treasure/${props.id}` : "/dungeon/select"}>
                        <div className=' d-flex fw-normal text-info align-items-center  pointer'  >
                            <CustomIcon className='me-1' width={20} height={20} icon="info" />
                            Details
                        </div>
                    </a>
                </div>
            </div>


        </motion.div>
    )
}

export default TreasureInfo