import truncateString from "./truncate-string";

function displayUserName(input: string,maxNum=10): string {
    if (!input) return ''
    // Check if input starts with '0x'
    if (input.startsWith('0x')) {
        // Assuming we want to keep the '0x' and the next 10 characters.
        return truncateString(input)
    }
    else {
        // If the string is more than 10 characters long, truncate it to 10
        if (input.length > maxNum) {
            return input.substring(0, maxNum) + "...";
        }
        else {
            // Else if it's 10 characters or less, return the input string as it is.
            return input;
        }
    }
}

export default displayUserName