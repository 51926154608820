import { Reducer, combineReducers } from "redux";
import { AlertReducer } from "./alert/reducer";
import { buildingReducer } from "./buildings/reducer";
import {
  channelReducer,
  chatActiveData,
  chatMetaDataReducer,
  chatSideBarReducer,
  messageReducer,
  readMessagesReducer,
} from "./chat/reducers";
import { cycleReducer } from "./cycle/reducer";
import { EffectsReducer } from "./effects/reducer";
import { audioPlayableReducer } from "./music/reducer";
import { QuestReducer } from "./quests/reducer";
import { ratesReducer } from "./rates/reducer";
import { scoutReducer } from "./scout/reducer";
import { userSettingsReducer } from "./settings/reducer";
import { TourReducer } from "./tour/reducer";
import { userInfoReducer, userTokenReducer } from "./user/reducer";
import { barracksSlice } from "./pages/barracks/barracksSlice";

export const rootReducer = combineReducers({
  userToken: userTokenReducer as Reducer,
  userInfo: userInfoReducer as Reducer,
  alert: AlertReducer as Reducer,
  userSettings: userSettingsReducer as Reducer,
  audioPlayable: audioPlayableReducer as Reducer,
  tour: TourReducer as Reducer,
  buildings: buildingReducer as Reducer,
  myScouts: scoutReducer as Reducer,
  currentCycle: cycleReducer as Reducer,
  quests: QuestReducer as Reducer,
  effects: EffectsReducer as Reducer,
  rates: ratesReducer as Reducer,
  activeChat: chatActiveData as Reducer,
  channels: channelReducer as Reducer,
  readMessages: readMessagesReducer as Reducer,
  chatState: chatSideBarReducer as Reducer,
  messages: messageReducer as Reducer,
  usersMetaData: chatMetaDataReducer as Reducer,
  barracks: barracksSlice.reducer,
});
export type RootState = ReturnType<typeof rootReducer>;
