import React, { FC } from "react";
import { RankingDataType, RankingType } from "../../types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProfileURL } from "../../../utils/Network";
import { Assets } from "../../../constants/assets";
import { roundFloat } from "../../../utils/round-float";
import { isMobile } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactPaginate from "react-paginate";
import LeaderBoardBanner from "./banner";

type Props = {
  loading: boolean;
  data: RankingDataType[];
  myRank: number;
  myScore: number;
  handleGetLeaderboard: (page: number) => void;
  page: any;
};

const LeaderboardDataTable: FC<Props & RankingType> = ({
  loading,
  data,
  myRank,
  myScore,
  handleGetLeaderboard,
  page,
  description,
  endDate,
  startDate,
  totalCount,
}) => {
  const userInfo = useSelector((state: any) => state.userInfo);
  return (
    <SkeletonTheme
      enableAnimation={!isMobile}
      baseColor="#352d22"
      highlightColor="#3e362d"
    >
      <LeaderBoardBanner
        rankingData={{
          data,
          description,
          endDate,
          myRank,
          myScore,
          startDate,
          totalCount,
        }}
      />
      <PerfectScrollbar className="mt-3" style={{ maxHeight: "500px" }}>
        <table className="table  table-borderless m-0   text-white   ">
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 10,
              backdropFilter: "blur(10px)",
            }}
            className="border-bottom border-secondary "
          >
            <tr>
              <th
                className="fs-lg"
                style={{ borderBottomLeftRadius: 0 }}
                scope="col"
              >
                Player/Score
              </th>
              <th className="fs-lg" scope="col"></th>
              <th className="fs-lg" scope="col">
                Matches
              </th>
              {!isMobile && (
                <th className="fs-lg" scope="col">
                  Win%
                </th>
              )}
              <th className="fs-lg" scope="col">
                Wins
              </th>
              <th
                className="fs-lg"
                style={{ borderBottomRightRadius: 0 }}
                scope="col"
              >
                <img src={Assets.icons.bloodStone} width={20} height={20} />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading &&
              [...Array(52)].map((d, i) => (
                <tr
                  style={{
                    background: i % 2 === 0 ? undefined : "rgba(0,0,0,0.1)",
                  }}
                >
                  <td>
                    <div className="d-flex align-items-center ">
                      <div>
                        <Skeleton circle width={60} height={60} />
                      </div>
                      <div className="fs-med ps-2">
                        <div
                          style={{ maxWidth: 120 }}
                          className="text-truncate"
                        >
                          <Skeleton width={90} />
                        </div>

                        <div className="mt-1 fs-small text-secondary">
                          <Skeleton width={60} />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{/* <Skeleton width={70} /> */}</td>
                  <td>
                    <Skeleton width={40} />
                  </td>
                  {!isMobile && (
                    <td>
                      <Skeleton width={50} />
                    </td>
                  )}

                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={20} />
                  </td>
                </tr>
              ))}
            {!loading &&
              data?.map((d, i) => (
                <tr
                  style={{
                    background:
                      d.playerId === userInfo?.id
                        ? "#ffd94717"
                        : i % 2 === 0
                        ? undefined
                        : "rgba(0,0,0,0.1)",
                  }}
                  className={d.playerId === userInfo?.id ? "dark-orange" : ""}
                >
                  <td>
                    <div className="d-flex align-items-center ">
                      <Link
                        to={"/profile/" + d.playerId}
                        className="p-relative"
                      >
                        {d?.photoFile ? (
                          <div className="p-relative">
                            <div
                              style={{
                                backgroundImage:
                                  "url(/assets/images/avatar-bg.png)",
                                width: 60,
                                height: 60,
                              }}
                              className={"main-avatar"}
                            />

                            <img
                              src={`${ProfileURL}/${d?.playerId}/${d?.photoFile}`}
                              width={"70%"}
                              height={"70%"}
                              style={{
                                position: "absolute",
                                borderRadius: "50%",
                                top: "12%",
                                right: 0,
                                left: "12%",
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{ width: 60, height: 60 }}
                            className={"main-avatar"}
                          />
                        )}
                        {d.rank <= 3 ? (
                          <span
                            style={{
                              position: "absolute",
                              zIndex: 10,
                              bottom: -12,
                              right: 0,
                            }}
                            className="fs-big"
                          >
                            <img
                              width={25}
                              src={`/assets/images/icons/${
                                i === 0 ? "first" : i === 1 ? "second" : "third"
                              }-place.png`}
                            />
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                      <div className="fs-med ps-3 d-flex flex-column ">
                        <Link
                          to={"/profile/" + d.playerId}
                          style={{ maxWidth: 140 }}
                          className="text-truncate text-info"
                        >
                          {d.username && !d.username?.startsWith("0x")
                            ? d.username
                            : "Player#" + d.playerId}
                        </Link>
                        <div>
                          {d.score}{" "}
                          <img
                            src={"/assets/images/icons/cup.png"}
                            width={20}
                            height={20}
                          />
                        </div>
                        {d.rank > 3 && (
                          <div className="color-orange ">#{d.rank}</div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td>{d.matches}</td>
                  <td>{roundFloat((d.wins * 100) / d.matches)}%</td>
                  <td>{d.wins}</td>
                  <td>{d.bloodGems}</td>
                </tr>
              ))}

            {/*  TODO: ADD MY SCORE */}

            {/* {!loading &&
          data?.[data?.length - 1]?.rank < myRank && (
            <>
              <tr className="dark-yellow">
                <td>
                  .<br />
                  .<br />
                  .<br />
                </td>
              </tr>
              <tr style={{ background: "#ffd94717" }}>
                <td>
                  <div className="d-flex align-items-center ">
                    <Link to={"/profile/" + userInfo.id} className="p-relative">
                      {userInfo?.photoFile ? (
                        <div className="p-relative">
                          <div
                            style={{
                              backgroundImage:
                                "url(/assets/images/avatar-bg.png)",
                              width: 60,
                              height: 60,
                            }}
                            className={"main-avatar"}
                          />

                          <img
                            src={`${ProfileURL}/${userInfo?.id}/${userInfo?.photoFile}`}
                            width={"70%"}
                            height={"70%"}
                            style={{
                              position: "absolute",
                              borderRadius: "50%",
                              top: "12%",
                              right: 0,
                              left: "12%",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{ width: 60, height: 60 }}
                          className={"main-avatar"}
                        />
                      )}
                    </Link>
                    <div className="fs-med dark-orange ps-3">
                      <div>#{myRank}</div>
                      <Link
                        to={"/profile/" + userInfo.id}
                        style={{ maxWidth: 140 }}
                        className="text-truncate text-info bold"
                      >
                        {userInfo?.username &&
                        !userInfo?.username?.startsWith("0x")
                          ? userInfo?.username
                          : "Player#" + userInfo.id}
                      </Link>
                      {userInfo?.walletAddress?.startsWith("0x") && (
                        <div className="mt-1 fs-small text-secondary">
                          {truncateString(userInfo?.walletAddress)}
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="dark-orange">
                  {ThousandsSeparator(myScore.toFixed(0))}
                </td>
              </tr>
            </>
          )} */}
          </tbody>
        </table>
      </PerfectScrollbar>
      <ReactPaginate
        className="pagination pagination-sm mt-2  justify-content-center"
        activeClassName="active-pg fs-tiny"
        pageClassName="page-item fs-tiny"
        pageLinkClassName="page-link"
        breakClassName="page-item"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLinkClassName="page-link"
        previousLinkClassName="page-link"
        previousClassName="page-item"
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        initialPage={0}
        onClick={(e) => {
          handleGetLeaderboard((e.nextSelectedPage ?? e.selected) + 1);
        }}
        pageRangeDisplayed={3}
        pageCount={page.total / 50}
      />
    </SkeletonTheme>
  );
};

export default LeaderboardDataTable;
