import React, { useEffect, useRef, useState } from "react";
import Network, { IsBeta } from "../../../utils/Network";
import { WinnerProfile } from "./legion";
import moment from "moment";
import { CustomIcon } from "../../../components/Icons";
import { Link } from "react-router-dom";
import { getProfileURL } from "../../../utils/profile-url";
import truncateString from "../../../utils/truncate-string";
import { GroupItem, Tournament } from "./types";
import { PlayerInfo } from "../../types";

type Props = {};

const Legends = (props: Props) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const isCalled = useRef(false);
  const axios = new Network();

  const handleGetLegends = async () => {
    setLoading(true);
    const response = await axios.CallApi<Tournament>({
      customHost: `https://${
        IsBeta ? "dev" : "core"
      }.chainoflegends.com/api/v2`,
      url: "/Tournament/GetLegends",
      method: "get",
    });
    if (response.ok) setData(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    handleGetLegends();
  }, []);

  const getMatchPlayerWinner = (match: Tournament["myMatches"][0]) => {
    if (match.firstPlayerId === match.winnerPlayerId) {
      return {
        winnerUsername: match.firstUsername,
        winnerAvatar: match.firstUserAvatarUrl,
      };
    } else if (match.secondPlayerId === match.winnerPlayerId) {
      return {
        winnerUsername: match.secondUsername,
        winnerAvatar: match.secondUserAvatarUrl,
      };
    }
  };
  return (
    <div className="py-5">
      <div className="text-center">
        <img
          src="https://core.chainoflegends.com/assets/images/legends-rewards.png"
          alt="rewards"
          width={"100%"}
          style={{ maxWidth: 440 }}
        />
      </div>
      <hr/>
      <div style={{ maxWidth: 700 }} className="row w-full  mb-3 mt-3 mx-auto">
        <div className="flex-column align-items-end   col-3 gap-3 d-flex ">
          {(data?.groups?.[0]?.items || new Array(8).fill(0)).map(
            (_: GroupItem, index) => (
              <div className="item-frame mx-1 flex-column">
                {_.userAvatarUrl && (
                  <img
                    src={getProfileURL(_.playerId, _.userAvatarUrl)!}
                    width={40}
                    height={40}
                    className="circle"
                  />
                )}
                {_.playerId ? (
                  <Link
                    to={`/profile/${_.playerId}`}
                    className="fs-small mt-1 text-info bold text-truncate"
                    style={{ maxWidth: 70 }}
                  >
                    {_.username?.startsWith("0x")
                      ? truncateString(_.username)
                      : _.username || "#" + _.playerId}
                  </Link>
                ) : (
                  "X"
                )}
              </div>
            )
          )}
        </div>
        <div
          style={{ alignItems: "center" }}
          className="flex-column col-3  gap-3 d-flex  justify-content-around "
        >
          {(data?.groups?.[1]?.items || new Array(4).fill(0))?.map(
            (_: GroupItem, index) => (
              <div className="d-flex align-items-center">
                <svg
                  width="96"
                  height="118"
                  viewBox="0 0 96 118"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.416992 0.803711H44.8807C48.7581 0.803711 51.9013 3.94693 51.9013 7.8243V110.208C51.9013 114.085 48.7581 117.228 44.8807 117.228H0.416992"
                    stroke="#FFDFAF"
                    stroke-width="1.1701"
                  />
                  <path
                    d="M52.4854 58.1387H95.1939"
                    stroke="#FFDFAF"
                    stroke-width="1.1701"
                  />
                </svg>
                <div className="item-frame mx-1 flex-column">
                  {_.userAvatarUrl && (
                    <img
                      src={getProfileURL(_.playerId, _.userAvatarUrl)!}
                      width={40}
                      height={40}
                      className="circle"
                    />
                  )}
                  {_.playerId ? (
                    <Link
                      to={`/profile/${_.playerId}`}
                      className="fs-small mt-1 text-info bold text-truncate"
                      style={{ maxWidth: 70 }}
                    >
                      {_.username?.startsWith("0x")
                        ? truncateString(_.username)
                        : _.username || "#" + _.playerId}
                    </Link>
                  ) : (
                    "X"
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <div
          style={{ alignItems: "center" }}
          className="flex-column col-3  gap-3 d-flex  justify-content-around "
        >
          {(data?.groups?.[2]?.items || new Array(2).fill(0))?.map(
            (_: GroupItem, index) => (
              <div className="d-flex align-items-center">
                <svg
                  width="96"
                  height="261"
                  viewBox="0 0 96 261"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.780273 1.13867H45.244C49.1213 1.13867 52.2646 4.2819 52.2646 8.15926V252.71C52.2646 256.587 49.1213 259.73 45.244 259.73H0.780273"
                    stroke="#FFDFAF"
                    stroke-width="1.1701"
                  />
                  <path
                    d="M52.2686 128.68H95.5622"
                    stroke="#FFDFAF"
                    stroke-width="1.1701"
                  />
                </svg>

                <div className="item-frame mx-1 flex-column">
                  {_.userAvatarUrl && (
                    <img
                      src={getProfileURL(_.playerId, _.userAvatarUrl)!}
                      width={40}
                      height={40}
                      className="circle"
                    />
                  )}
                  {_.playerId ? (
                    <Link
                      to={`/profile/${_.playerId}`}
                      className="fs-small mt-1 text-info bold text-truncate"
                      style={{ maxWidth: 70 }}
                    >
                      {_.username?.startsWith("0x")
                        ? truncateString(_.username)
                        : _.username || "#" + _.playerId}
                    </Link>
                  ) : (
                    "X"
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <div
          style={{ alignItems: "center" }}
          className="flex-column col-3  gap-3 d-flex  justify-content-around "
        >
          {(data?.groups?.[3]?.items || [0]).map((_, index) => (
            <div className="d-flex align-items-center">
              <svg
                width="96"
                height="516"
                viewBox="0 0 96 516"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.318359 1.33984H44.7821C48.6594 1.33984 51.8027 4.48307 51.8027 8.36043V507.992C51.8027 511.87 48.6594 515.013 44.7821 515.013H0.318359"
                  stroke="#FFDFAF"
                  stroke-width="1.1701"
                />
                <path
                  d="M51.8027 255.25H95.0964"
                  stroke="#FFDFAF"
                  stroke-width="1.1701"
                />
              </svg>
              <div className="text-center">
                <div className="item-frame active-slot mx-1">
                  {_.userAvatarUrl && (
                    <img
                      src={getProfileURL(_.playerId, _.userAvatarUrl)!}
                      width={40}
                      height={40}
                      className="circle"
                    />
                  )}
                  {_.playerId ? (
                    <Link
                      to={`/profile/${_.playerId}`}
                      className="fs-small mt-1 text-info bold text-truncate"
                      style={{ maxWidth: 70 }}
                    >
                      {_.username?.startsWith("0x")
                        ? truncateString(_.username)
                        : _.username || "#" + _.playerId}
                    </Link>
                  ) : (
                    "???"
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <CustomIcon width={30} height={30} icon="cup" />
                  The Winner !
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="my-4">
        <div className="tournament-group-frame m-0 ">
          <div className="title mine">Match Schedule</div>
          <table className="table  table-borderless m-0   text-white   ">
            <tr>
              <th align="center" className="text-center">
                Player 1
              </th>
              <th></th>
              <th align="center" className="text-center">
                Player 2
              </th>
              <th align="center" className="text-center">
                Group
              </th>
              <th align="center" className="text-center">
                Date
              </th>
              <th align="center" className="text-center">
                Result
              </th>
            </tr>
            <tbody>
              {/* {item.items.length === 0 && (
                        <tr>
                          <td colSpan={4}>
                            <div className="w-100 d-flex py-5 fs-small align-items-center gap-1 dark-yellow justify-content-center">
                              <CustomIcon icon="info" width={20} height={20} />
                              There is not data on this group yet !
                            </div>
                          </td>
                        </tr>
                      )} */}
              {data?.matchesSchedules?.map((match) => {
                const winner =
                  match.winnerPlayerId && getMatchPlayerWinner(match);
                return (
                  <tr>
                    <td align="center" className="">
                      <Link
                        to={"/profile/" + match.firstPlayerId}
                        className="d-flex gap-1 flex-column align-items-center justify-content-center"
                      >
                        <img
                          width={77}
                          className="circle"
                          src={
                            match.firstUserAvatarUrl
                              ? getProfileURL(
                                  match.firstPlayerId,
                                  match.firstUserAvatarUrl
                                )!
                              : "/assets/images/empty-avatar.png"
                          }
                        />
                        <span className="fs-small text-info bold">
                          {match?.firstUsername?.startsWith("0x")
                            ? truncateString(match.firstUsername)
                            : match?.firstUsername || "#" + match.firstPlayerId}
                        </span>
                      </Link>
                    </td>
                    <td align="center" className="bold fs-bigger">
                      VS
                    </td>
                    <td align="center" className="">
                      <Link
                        to={"/profile/" + match.secondPlayerId}
                        className="d-flex gap-1 flex-column align-items-center justify-content-center"
                      >
                        <img
                          width={77}
                          className="circle"
                          src={
                            match.secondUserAvatarUrl
                              ? getProfileURL(
                                  match.secondPlayerId,
                                  match.secondUserAvatarUrl
                                )!
                              : "/assets/images/empty-avatar.png"
                          }
                        />
                        <span className="fs-small text-info bold">
                          {match?.secondUsername?.startsWith("0x")
                            ? truncateString(match.secondUsername)
                            : match.secondUsername ||
                              "#" + match.secondPlayerId}
                        </span>
                      </Link>
                    </td>
                    <td align="center">
                      <a
                        href={"#" + match.groupName}
                        className="d-flex gap-1 text-white link justify-content-center align-items-center"
                      >
                        <CustomIcon icon="glory" width={20} height={20} />
                        {match.groupName}{" "}
                      </a>
                    </td>
                    <td align="center">
                      <span className="fs-tiny">
                        {moment(match.dates).format("MMMM Do YYYY, h:mm a")}
                      </span>
                    </td>
                    <td align="center">
                      {winner ? (
                        <WinnerProfile
                          winner={{
                            ...winner,
                            winnerId: match.winnerPlayerId!,
                          }}
                        />
                      ) : (
                        match.result || (
                          <span className="dark-yellow fs-small">Upcoming</span>
                        )
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Legends;
