import React from "react";
import Button from "../../../../components/Button";
import { Link } from "react-router-dom";
type Props = {};

const CampBoxEmptyState: React.FC<Props> = () => {
  return (
    <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
      <div className="dark-yellow">You don't have any Troops!</div>
      <Link to="/barracks">
        <Button variant="default" className="bg-100 text-yellow-2 px-lg-3 ">
          Add Troops
        </Button>
      </Link>
    </div>
  );
};

export default CampBoxEmptyState;
