import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Network from '../utils/Network'
import Button from '../components/Button'
import useWindowSize from '../hooks/useWindowSize'
import { roundFloat } from '../utils/round-float'
import { Link, useParams } from 'react-router-dom'
import useToast from '../hooks/useToast'
import MidBox from '../components/Boxes/MidBox'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import CircularProgress from '../components/CircularProgress'
import { isMobile } from 'react-device-detect'
import NotFoundError from '../components/NotFoundError'
import { Helmet } from 'react-helmet'

type Props = {
    handlePlay: Function;
}

const AdventureResult = (props: Props) => {
    const { id } = useParams()
    const [attackers, setAttackers] = useState<any>([])
    const [defenders, setDefenders] = useState<any>([])
    const [battleReport, setBattleReport] = useState<any>()
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const axios = new Network()
    const openToast = useToast()
    const windowSize = useWindowSize();


    async function getData() {
        setLoading(true)
        const response = await axios.CallApi({
            url: '/adventure/info', body: {
                replayId: id
            }, method: 'post'
        })
        if (!response.ok) {
            setLoading(false)
            setError(true)
            return;
        }
        let formation: any = []
        let sortedDefenders: any = []
        let oldFormDeffenders: any = JSON.parse(response?.data?.battleReport)?.Defenders
        let oldFormation: any = JSON.parse(response?.data?.formation)
        let newArrayList: any = []
        let Defs: any = []
        setBattleReport(JSON.parse(response?.data?.battleReport))
        JSON.parse(response?.data?.battleReport)?.Attackers?.map(((item: any) => {
            // const detectedItem = newArrayList?.find((subItem) =>subItem?.displayX===item?.displayX &&subItem?.y === item?.y )
            // const newItem={...detectedItem,...item}
            let defaultData: any = {}
            for (let key in JSON.parse(response?.data?.battleReport)?.Actions[0]) {
                if (item.playerTroopId === JSON.parse(response?.data?.battleReport)?.Actions[0][key]?.playerTroopId) {
                    defaultData = JSON.parse(response?.data?.battleReport)?.Actions[0][key]
                }
            }
            newArrayList.push({
                ...item,
                initialX: defaultData?.displayX || item.displayX,
                initialY: defaultData?.displayY || item.displayY,
                ...defaultData,
            })
            // console.log(newItem)
            // if (detectedItem){
            // console.log(newItem)
            //     newArrayList = [...defaultArray.filter((rec)=>rec.displayX!==detectedItem?.displayX&&rec.y!==detectedItem?.y),{
            //         ...newItem
            //     }]?.sort((a, b) => a.index - b.index)
            // }
        }))
        setAttackers(newArrayList)
        JSON.parse(response?.data?.battleReport)?.Defenders?.map(((item: any) => {
            let defaultData: any = {}
            for (let key in JSON.parse(response?.data?.battleReport)?.Actions[0]) {
                if (item.playerTroopId === JSON.parse(response?.data?.battleReport)?.Actions[0][key]?.playerTroopId) {
                    defaultData = JSON.parse(response?.data?.battleReport)?.Actions[0][key]
                }
            }
            Defs.push({
                ...item,
                initialX: defaultData?.displayX || item.displayX,
                initialY: defaultData?.displayY || item.displayY, ...defaultData,
            })
        }))
        oldFormation?.map((theItem: any) => {
            if (!formation?.find((unit: any) => unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId)) {
                formation.push({
                    ...theItem
                })
            }
            else {
                formation = [...formation?.filter((dd: any) => dd?.playerTroop?.TroopId !== theItem?.playerTroop?.TroopId), {
                    ...formation?.find((unit: any) => unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId),
                    qty: formation?.find((unit: any) => unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId)?.qty ? (formation?.find((unit: any) => unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId)?.qty + 1) : 2
                }]
            }

        });

        // console.log( JSON.parse(item?.battleReport))
        oldFormDeffenders?.map((theItem: any) => {
            if (!sortedDefenders?.find((unit: any) => unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId)) {
                sortedDefenders.push({
                    ...theItem
                })
            }
            else {
                sortedDefenders = [...sortedDefenders?.filter((dd: any) => dd?.playerTroop?.TroopId !== theItem?.playerTroop?.TroopId), {
                    ...sortedDefenders?.find((unit: any) => unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId),
                    qty: sortedDefenders?.find((unit: any) => unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId)?.qty ? (sortedDefenders?.find((unit: any) => unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId)?.qty + 1) : 2
                }]
            }

        });
        // console.log(JSON.parse(response?.data?.jsonData)))
        setData({
            ...response.data,
            jsonData: response?.data?.jsonData ? JSON.parse(response?.data?.jsonData) : null,
            sortedAttackers: formation,
            sortedDefenders: sortedDefenders
        })
        setDefenders(Defs)
        props.handlePlay(
            response?.data?.result
        )
        setLoading(false)
    }

    useEffect(() => {
        if (id) getData()
    }, [id])

    if (loading) return <div style={{ minHeight: '100vh' }} className="d-flex align-items-center justify-content-center">
        <CircularProgress style={{ width: 50, height: 50 }} />
        Loading ...
    </div>
    if (error) return <>
        <NotFoundError action={{
            title: 'Adventures',
            link: '/adventure'
        }} />
    </>

    const ResultHeader = () => <>
        <img
            style={{ marginTop: -200 }}
            className=''
            width={'100%'}
            src={data?.result === 1 ? '/assets/images/battle/victory.png' : '/assets/images/battle/defeat.png'}
        />

        <div style={{ marginTop: -20 }} className='d-flex flex-wrap justify-content-center'>
            {data?.jsonData && Array.isArray(data?.jsonData) ? data?.jsonData?.map((troop: any, index: number) => (
                <div >
                    <OverlayTrigger
                        // popperConfig={{}}
                        placement={"top"}
                        key={index + "x43xxp"}
                        overlay={
                            <Tooltip id={`tooltip-${'top'}`}>
                                {troop?.Troop?.Name} - Level {troop?.Level}
                            </Tooltip>
                        }
                    >
                        <div
                            style={{ padding: 2 }}
                            className={`dark-badge ms-3  ` + (index !== 0 && "ms-1")}
                        >
                            <img style={{ borderRadius: 50, zIndex: 10, position: 'relative' }} width={40} height={40} src={troop?.Troop?.Icon} />
                            {troop?.Level && <div className='units fs-tiny'>
                                {troop?.Level}
                            </div>}
                        </div>
                    </OverlayTrigger>
                </div>)
            ) : data?.jsonData?.CapturedTroops?.map((troop: any, index: number) => (
                <div >
                    <OverlayTrigger
                        // popperConfig={{}}
                        placement={"top"}
                        key={index + "x43xxp"}
                        overlay={
                            <Tooltip id={`tooltip-${'top'}`}>
                                {troop?.Troop?.Name} - Level {troop?.Level}
                            </Tooltip>
                        }
                    >
                        <div
                            style={{ padding: 2 }}
                            className={`dark-badge ms-3  ` + (index !== 0 && "ms-1")}
                        >
                            <img style={{ borderRadius: 50, zIndex: 10, position: 'relative' }} width={40} height={40} src={troop?.Troop?.Icon} />
                            {troop?.Level && <div className='units fs-tiny'>
                                {troop?.Level}
                            </div>}
                        </div>
                    </OverlayTrigger>
                </div>))
            }
        </div>

        <div style={{ opacity: data?.result === 1 ? 1 : 0.5 }} className='d-flex mt-3 flex-wrap justify-content-center'>
            <div>
                <img alt="cleg" src="/assets/images/icons/cleg.png" width={25} />
                {data?.collectedCleg}&nbsp;<small>
                    CLEG
                </small>
            </div>
            <div className='ms-2'>
                <img alt="cleg" src="/assets/images/icons/stone.png" width={25} />
                {data?.collectedStone}&nbsp;<small>
                    Stone
                </small>
            </div>
            <div className='ms-2'>
                <img alt="cleg" src="/assets/images/icons/metal.png" width={25} />
                {data?.collectedIron}&nbsp;<small>
                    Iron
                </small>
            </div>
        </div>
        <div className='text-center mt-3 fs-big'>
            <img style={{ marginRight: 5, marginTop: -3 }} width={17} src="/assets/images/icons/arrow-up.svg" />
            {data?.result === 1 ? '+10 XP' : '+5 XP'}
        </div>
    </>

    return (
        <div style={{ paddingBottom: 110 }} className='result-container'>
            <Helmet>
                <title key={'title'}>
                    Adventure Result | Chain Of Legends
                </title>
            </Helmet>

            {data?.result === 1 ? <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className='success-back' /> : <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className='failed-back' />}
            <div
                style={{ paddingTop: 190 }}
                className='container'>

                <motion.div
                    exit={{ opacity: 0, y: 200 }}
                    transition={{ duration: 0.4 }}
                    initial={isMobile ? undefined : { opacity: 0, y: 200 }} animate={{ opacity: 1, y: 0 }}
                >

                    <MidBox
                        noTitle
                        modal
                        containerClass='p-relative'
                    >
                        <div className='row justify-content-center align-items-center '>
                            {windowSize?.width < 768 &&
                                <div className='col-12 col-md-6  col-lg-4'>
                                    <ResultHeader />
                                    <hr />
                                </div>}
                            <div className='col-12 col-md-6  col-lg-4'>
                                <div dir="rtl" className='d-flex align-items-center justify-content-center justify-content-md-start  flex-wrap '>
                                    <img className='scaleX ms-2' width={50} height={60} src="/assets/images/icons/skull.png" />

                                    {attackers?.filter((item) => item?.playerTroop?.Health === 0)?.map((troop, index) => index < 7 ?
                                        <OverlayTrigger
                                            // popperConfig={{}}
                                            placement={"bottom"}
                                            key={index + "p" + troop?.x + troop?.y}
                                            overlay={
                                                <Tooltip id={`tooltip-${'top'}`}>
                                                    {troop?.playerTroop?.Troop?.Name} - Level     {troop?.playerTroop?.Level}

                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                style={{ padding: 3 }}
                                                className={`dark-badge mt-1 ms-2   ` + (index !== 0 && "ms-1")}
                                            >
                                                <img className='scaleX' style={{ borderRadius: 50, zIndex: 10, position: 'relative' }} width={55} height={55} src={troop?.playerTroop?.Troop?.Icon} />

                                            </div>
                                        </OverlayTrigger> : index === 8 && <OverlayTrigger
                                            // popperConfig={{}}
                                            placement={"bottom"}
                                            key={index + "p" + troop?.x + troop?.y}
                                            overlay={
                                                <Tooltip id={`tooltip-${'top'}`}>
                                                    +{attackers?.filter((item) => item?.playerTroop?.Health === 0)?.length - 7} more
                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                style={{ padding: 3, width: 55, height: 55 }}
                                                className={`dark-badge mt-1 ms-2  d-flex justify-content-center align-items-center ` + (index !== 0 && "ms-1")}
                                            >
                                                +{data?.sortedAttackers?.length - 7}
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                </div>

                            </div>
                            {windowSize?.width >= 768 ?
                                <div className='col-12 col-md-6  col-lg-4'>
                                    <ResultHeader />
                                </div> :
                                <div className='text-center fs-big my-3'>
                                    <hr />
                                </div>
                            }
                            <div className='col-12 col-md-6  col-lg-4'>
                                <div className='d-flex align-items-center justify-content-center justify-content-md-start flex-wrap '>
                                    <img width={50} height={60} src="/assets/images/icons/skull.png" />

                                    {defenders?.filter(item => item?.playerTroop?.Health === 0)?.map((troop, index) => index < 7 ?
                                        <OverlayTrigger
                                            // popperConfig={{}}
                                            placement={"bottom"}
                                            key={index + "p" + troop?.x + troop?.y}
                                            overlay={
                                                <Tooltip id={`tooltip-${'top'}`}>
                                                    {troop?.playerTroop?.Troop?.Name} - Level   {troop?.playerTroop?.Level}
                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                style={{ padding: 3 }}
                                                className={`dark-badge mt-1 ms-2   ` + (index !== 0 && "ms-1")}    >
                                                <img style={{ borderRadius: 50, zIndex: 10, position: 'relative' }} width={55} height={55} src={troop?.playerTroop?.Troop?.Icon} />
                                                {troop?.qty && <div className='units'>
                                                    {troop?.qty}x
                                                </div>}
                                            </div>
                                        </OverlayTrigger> : index === 8 && <OverlayTrigger
                                            // popperConfig={{}}
                                            placement={"bottom"}
                                            key={index + "p" + troop?.x + troop?.y}
                                            overlay={
                                                <Tooltip id={`tooltip-${'top'}`}>
                                                    +{defenders?.filter((item) => item?.playerTroop?.Health === 0)?.length - 7} more
                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                style={{ padding: 3, width: 55, height: 55 }}
                                                className={`dark-badge mt-1 ms-2  d-flex justify-content-center align-items-center ` + (index !== 0 && "ms-1")}
                                            >
                                                +{data?.sortedAttackers?.length - 7}
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </div>
                        </div>
                    </MidBox>
                </motion.div>

                <motion.div
                    exit={{ opacity: 0, y: 200 }}
                    transition={{ duration: 0.4, delay: 0.2 }}
                    initial={isMobile ? undefined : { opacity: 0, y: 200 }} animate={{ opacity: 1, y: 0 }}
                    className='row '>
                    <div className='col-12 col-md-5'>
                        <div className='row justify-content-center justify-content-md-end'>
                            {data?.sortedAttackers?.map((troop) => (
                                <div className='col-4  col-md-6  col-lg-4  col-xl-3 '>
                                    <div className='fight-placement' style={{ minHeight: 200, alignItems: 'flex-end', paddingBottom: 20, }}>
                                        <img
                                            draggable={false} className="mx-auto scaleX"
                                            style={{ maxWidth: '100%', maxHeight: 100 }} src={troop?.playerTroop?.Troop?.Image} />

                                        {troop?.qty && <div className='units'>
                                            {troop?.qty}x
                                        </div>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='col-md-2 text-center'>
                        <img style={{ maxWidth: '100%' }} src={data?.result === 1 ? "/assets/images/battle/success-vs.png" : "/assets/images/battle/defeat-vs.png"} />
                    </div>
                    <div className='col-12 col-md-5'>
                        <div className='row justify-content-center justify-content-md-start'>
                            {data?.sortedDefenders?.map((troop) => (
                                <div className='col-4  col-md-6  col-lg-4  col-xl-3'>
                                    <div className='fight-placement' style={{ minHeight: 200, alignItems: 'flex-end', paddingBottom: 20, }}>
                                        <img
                                            draggable={false} className="mx-auto "
                                            style={{ maxWidth: '100%', maxHeight: 100 }} src={troop?.playerTroop?.Troop?.Image} />

                                        {troop?.qty && <div className='units'>
                                            {troop?.qty}x
                                        </div>}
                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                </motion.div>

            </div>
            <div className='result-action-bar'>
                <div className='ms-auto d-flex '>
                    <Link to="/">
                        <img width={windowSize?.width > 800 ? 120 : 90} height={windowSize?.width > 800 ? 95 : 75} alt="home" src="/assets/images/buttons/home.png" className='button' />
                    </Link>

                    <img onClick={() => window.location.href = `/battle/treasure/${encodeURIComponent(id!)}`} width={windowSize?.width > 800 ? 120 : 90} height={windowSize?.width > 800 ? 95 : 75} alt="home" src="/assets/images/buttons/repeat.png" className='button' />

                </div>
            </div>
        </div>
    )
}

export default AdventureResult;