import React, { useEffect, useRef, useState } from "react";
import Network, { BaseURL } from "../../../utils/Network";
import { GuildJoinTypeEnum, GuildType } from "../types";
import TimeAgo from "javascript-time-ago";
import { motion } from "framer-motion";
import Button from "../../../components/Button";
import { Form } from "react-bootstrap";
import { GuildJoinValues, GuildLanguages } from "../data";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { formatNumber } from "../../../utils/format-number";
import useWindowSize from "../../../hooks/useWindowSize";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactPaginate from "react-paginate";

type Props = {};

const Explore = (props: Props) => {
  const axios = new Network();
  const [guilds, setGuilds] = useState<GuildType[]>();
  const timeAgo = new TimeAgo("en");
  const windowSize = useWindowSize();
  const [paginations, setPaginations] = useState({ total: 1, page: 1 });
  const [searchParameters, setSearchParameters] = useSearchParams();
  const joinType = searchParameters.get("joinType");
  const language = searchParameters.get("language");
  const minScore = searchParameters.get("minScore");
  const hasFreeCapacity = searchParameters.get("hasFreeCapacity");
  const firstRender = useRef(true);

  const [debouncedValue] = useDebounce(searchParameters, 1000);
  const handleGuilds = async (page = 1) => {
    const response = await axios.CallApi({
      url: `/guild?page=${page}&count=15&` + searchParameters.toString(),
      method: "get",
    });
    setGuilds(response.data?.data);
    setPaginations({ total: Math.ceil(response.data?.totalCount / 15), page });
  };
  useEffect(() => {
    // if (firstRender.current) {
    //   firstRender.current = false;
    //   return;
    // }
    handleGuilds();
  }, [debouncedValue]);

  const changeQuery = (val: number | string | boolean, name: string) => {
    val ? searchParameters.set(name, val + "") : searchParameters.delete(name);
    setSearchParameters(searchParameters);
  };

  return (
    <div className="row pb-4 mt-4 px-0 px-md-4 flex-md-wrap">
      <div className="col-12 mb-5 ">
        <div className="d-flex align-items-center flex-wrap flex-row gap-4 mt-3 px-2 h-100">
          <div className="d-flex align-items-center gap-1 ">
            <label className=" d-flex align-items-center gap-1 dark-yellow">
              <img
                width={24}
                height={24}
                src={"/assets/images/icons/letter.png"}
              />
              Type:
            </label>
            <Form.Select
              value={+joinType! || ""}
              onChange={(e: any) => {
                changeQuery(e.target.value, "joinType");
              }}
              aria-label="Default select example"
            >
              <option value="" selected>
                All
              </option>
              {Object.entries(GuildJoinValues).map(([key, value]) => (
                <option value={key}>{value}</option>
              ))}
            </Form.Select>
          </div>
          <div className="d-flex align-items-center gap-1 ">
            <label className=" d-flex align-items-center gap-1 dark-yellow">
              <img
                width={24}
                height={24}
                src={"/assets/images/icons/earth.png"}
              />
              Language:
            </label>
            <Form.Select
              value={language!}
              onChange={(e: any) => changeQuery(e.target.value, "language")}
              aria-label="Default select example"
            >
              <option value="" selected>
                All
              </option>
              {GuildLanguages.map((lang) => (
                <option value={lang}>{lang}</option>
              ))}
            </Form.Select>
          </div>

          <div className="d-flex align-items-center gap-1 ">
            <label
              style={{ minWidth: 110 }}
              className=" d-flex align-items-center gap-1 dark-yellow"
            >
              <img
                width={24}
                height={24}
                src={"/assets/images/icons/crown-flag.png"}
              />
              Join Score:
            </label>
            <Form.Control
              size="sm"
              value={minScore!}
              placeholder="eg: 300"
              onChange={(e: any) => changeQuery(e.target.value, "minScore")}
              aria-label="Default select example"
            />
          </div>

          <div style={{ minWidth: 200 }}>
            <Form.Check
              checked={!hasFreeCapacity}
              onChange={(e) =>
                changeQuery(!e.target.checked, "hasFreeCapacity")
              }
              className="fs-med dark-yellow"
              type="checkbox"
              label="Show Full Capacity Guilds"
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-8 pb-4">
        <PerfectScrollbar
          style={{ maxHeight: 480, height: "auto" }}
          className="px-3 mb-3 "
        >
          {guilds?.map((guild, i) => (
            <motion.div
              initial={{ x: -800, opacity: 0 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                delay: i * 0.1 + 0.05,
              }}
              exit={{
                opacity: 0,
              }}
              className="guild-row align-items-center px-2 px-md-0 py-2 row mb-2"
            >
              <div className="col-12 col-md-4 d-flex align-items-center gap-2">
                <img
                  width={60}
                  height={60}
                  className="circle"
                  src={
                    guild.image
                      ? guild.image.includes("chainoflegends.com")
                        ? guild.image
                        : BaseURL + guild.image
                      : "/assets/images/avatar-bg.png"
                  }
                />
                <div>
                  <h6 className="text-yellow">{guild.name}</h6>
                  <div className="fs-tiny">
                    Created {timeAgo.format(new Date(guild.registerDate))}
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3 ">
                <div className="d-flex fs-med align-items-center gap-1">
                  <img
                    width={30}
                    height={26}
                    src={"/assets/images/icons/crown.png"}
                  />
                  Worth: {formatNumber(guild.score)}
                </div>
                <div className="d-flex mt-1 fs-med align-items-center gap-1">
                  <img
                    width={30}
                    height={30}
                    src={"/assets/images/icons/letter.png"}
                  />
                  Type: {GuildJoinValues[guild.joinType]}
                </div>
              </div>
              <div className="col-6 col-md-3 ">
                <div className="d-flex fs-med align-items-center gap-1">
                  <img
                    width={30}
                    height={30}
                    src={"/assets/images/icons/earth.png"}
                  />
                  Language: {guild.language}
                </div>
                <div className="d-flex mt-1 fs-med align-items-center gap-1">
                  <img
                    width={30}
                    height={28}
                    src={"/assets/images/icons/crown-flag.png"}
                  />
                  Join Score : {guild.minRankScore}
                </div>
              </div>
              <div className="col-12 col-md-2 ">
                <div className="mx-md-auto d-flex justify-content-between flex-md-column  fs-med align-items-center gap-1">
                  <Button
                    onClick={() => {
                      changeQuery("guild", "tab");
                      changeQuery(guild.id, "id");
                    }}
                    variant="primary"
                  >
                    Open
                  </Button>
                  <div className="d-flex mt-1 fs-med align-items-center gap-1">
                    <img
                      width={30}
                      height={28}
                      className="circle"
                      src={"/assets/images/icons/warrior.png"}
                    />
                    Capacity: {guild.playersCount}/{guild.capacity}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </PerfectScrollbar>
        {paginations.total > 1 && guilds && (
          <ReactPaginate
            className="pagination justify-content-center"
            activeClassName="active-pg"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            breakClassName="page-item"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLinkClassName="page-link"
            previousLinkClassName="page-link"
            previousClassName="page-item"
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            onPageChange={(e) => handleGuilds(e.selected + 1)}
            pageRangeDisplayed={3}
            pageCount={paginations.total}
            // renderOnZeroPageCount={null}
          />
        )}
      </div>
      <div className="col-12 col-md-4">
        <div className="d-flex h-100  ">
          <img
            src="/assets/images/divider-vertical.png"
            className="d-none mx-auto d-md-block"
            height={"100%"}
          />
          <div>
            <div className="d-flex justify-content-center align-items-end">
              <div className="text-center ">
                <img
                  src="/assets/images/castle.png"
                  width={windowSize.width < 500 ? "120px" : "240px"}
                />
              </div>
            </div>
            <div className="bg-success-50 mb-4 mb-md-0 rounded mt-3 p-2 glass bg-2 ">
              <h5 className="bold mb-0 mt-1">Ready for Leading ?</h5>
              <div className="d-flex flex-wrap  align-items-center">
                <span className="fs-small">
                  Create a guild get ready to lead your people and raids.
                </span>
                <Button
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    changeQuery("create", "tab");
                  }}
                  className="bg-100 ms-auto mt-3 fs-lg"
                >
                  Build a Guild
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
