import React from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import MidBox from "./Boxes/MidBox";
import Button from "./Button";
import CircularProgress from "./CircularProgress";
type Props = {
  open: boolean;
  title?: string;
  className?: string;
  noFooter?: boolean;
  loading?: boolean;
  force?: boolean;
  layoutLoading?: boolean;
  submitStyle?: React.CSSProperties;
  onClose?: () => void;
  children: React.ReactNode;
  onSubmit?: React.EventHandler;
  closeLabel?: string;
  submitLabel?: string;
  submitVariant?: string;
  disableSubmit?: boolean;
  size?: "sm" | "lg" | "xl";
  style?: React.CSSProperties;
};

const Dialog = ({
  open,
  title,
  onClose,
  submitStyle,
  submitVariant,
  disableSubmit,
  force,
  size,
  className,
  noFooter,
  loading,
  layoutLoading,
  children,
  onSubmit,
  closeLabel,
  submitLabel,
  style,
}: Props) => {
  return (
    <Modal
      size={size || undefined}
      show={open}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      contentClassName={"bg-transparent border-0"}
      centered
      style={{ background: "transparent", ...style }}
    >
      {layoutLoading && (
        <CircularProgress
          style={{
            position: "absolute",
            right: 0,
            zIndex: 100,
            width: 50,
            height: 50,
            top: "45%",
            left: 0,
            marginInline: "auto",
          }}
        />
      )}
      <MidBox
        style={{
          transition: "0.4s all",
          filter: layoutLoading ? "blur(6px)" : "",
        }}
        modal
        title={title || ""}
        className={className}
      >
        {!force && (
          <button onClick={onClose} className="button btn-close"></button>
        )}
        {children}

        {!noFooter && (
          <>
            <br />
            <br />
            <div
              style={{
                position: "absolute",
                bottom: 33,
                right: 0,
                width: "100%",
              }}
              className="d-flex mt-4  justify-content-center"
            >
              {onSubmit && (
                <Button
                  disabled={disableSubmit}
                  style={submitStyle}
                  className="me-2"
                  loading={loading}
                  onClick={onSubmit}
                  variant={submitVariant! || "primary"}
                >
                  {submitLabel || "Submit"}
                </Button>
              )}
              <Button onClick={onClose} variant="default">
                {closeLabel || "Close"}
              </Button>
            </div>
          </>
        )}
      </MidBox>
    </Modal>
  );
};

export default Dialog;
