import React, { act, useEffect, useMemo, useState } from "react";
import MidBox from "../../../components/Boxes/MidBox";
import { useBattleContext } from "../../context";
import { AnimatePresence, motion } from "framer-motion";
import {
  BattleModeInfo,
  FormationCampArmy,
  Troop,
} from "../../types";
import classNames from "classnames";
import Button from "../../../components/Button";
import { CustomIcon } from "../../../components/Icons";
import ThousandsSeparator from "../../../utils/thousands-separator";
import CountDown from "react-countdown";
import { createTempID } from "../../screens/troops-formation";
import useWindowSize from "../../../hooks/useWindowSize";

import PerfectScroll from "react-perfect-scrollbar";
import PreviewerContent from "./preview-content";

type Props = {
  onSelect: (troop: Troop | FormationCampArmy) => void;
  selectedTroops: Troop[];
  activeTroop?: Troop | FormationCampArmy;
  onSubmit: (troop: Troop) => void;
  changeLevel: (level: number) => void;
  onRemove: (troop: Troop) => void;
  isReady: boolean;
  onReadyCheck: () => void;
  onNotReady: () => void;
  battleModeInfo?: BattleModeInfo;
  army?: FormationCampArmy[];
  isMyArmyOnly?: boolean;
};

const Timer = ({
  date,
  onComplete,
}: {
  date: number;
  onComplete?: () => void;
}) => {
  return (
    <div className="formation-timer px-2">
      <CountDown
        date={date}
        onComplete={onComplete}
        renderer={({ minutes, completed, formatted }) => {
          if (completed) {
            return <div className="text-center">Time's up!</div>;
          }
          return (
            <>
              <CustomIcon icon={"timer"} type="png" width={20} height={20} />
              {minutes}:{formatted.seconds}
            </>
          );
        }}
      />
    </div>
  );
};
const TroopSelection = ({
  onSelect,
  selectedTroops,
  activeTroop,
  army,
  changeLevel,
  onRemove,
  onSubmit,
  isReady,
  onReadyCheck,
  battleModeInfo,
  onNotReady,
  isMyArmyOnly,
}: Props) => {
  const { battleState, gameInfo, messages } = useBattleContext();
  const { troops } = battleState;
  const troopLength = selectedTroops.length;

  const { width: windowWidth } = useWindowSize();
  const myArmy = useMemo(() => {
    let newArmy = [...(army || [])];
    newArmy.map(({ troopId, level }, i) => {
      const troop = troops.find((t) => t.id === troopId);
      newArmy[i].icon = troop?.icon;
      newArmy[i].clegCost = troop?.clegCost;
      newArmy[i].tempId = createTempID();
      return troop;
    });
    return newArmy;
  }, [army, troops]);

  const MaxCost = battleModeInfo?.armyValue || 100000;

  const totalCost = useMemo(
    () => selectedTroops.reduce((acc, curr) => acc + curr.clegCost, 0),
    [selectedTroops]
  );

  const formationTimeOut = useMemo(() => {
    if (!gameInfo?.gameRules) return 0;
    return new Date(
      Date.now() + gameInfo?.gameRules?.formationTimeout!
    ).getTime();
  }, [gameInfo]);
  useEffect(() => {}, [selectedTroops]);

  const CharacterMap = () => (
    <>
      {isMyArmyOnly ? (
        myArmy.length === 0 ? (
          <div
            style={{ marginBottom: -60 }}
            className="d-flex  justify-content-center align-items-center"
          >
            <div className="fs-md bg-dark-500 d-flex align-items-center bg-blur p-2 rounded text-white">
              <CustomIcon
                icon="warning"
                className="scaleX me-1"
                width={20}
                height={20}
              />{" "}
              You have no troops in your army
            </div>
          </div>
        ) : (
          myArmy.map((troop, index) => (
            <div
              className={
                windowWidth > 768 ? "col-3 col-md-6 col-lg-4 col-xl-3 p-0" : ""
              }
              key={index}
            >
              <motion.div
                onClick={() =>
                  !Boolean(
                    selectedTroops.find(
                      (t) => t.playerTroopId === troop.playerTroopId
                    )
                  ) && onSelect(troop)
                }
                initial={{ scale: 0.5 }}
                animate={{
                  scale: activeTroop?.tempId === troop.tempId ? 1.06 : 1,
                }}
                className={classNames("troop-frame position-relative", {
                  disabled: Boolean(
                    selectedTroops.find(
                      (t) => t.playerTroopId === troop.playerTroopId
                    )
                  ),
                  selected: activeTroop?.tempId === troop.tempId,
                })}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    top: 5,
                    right: 5,
                    zIndex: 20,
                  }}
                  className="level d-flex align-items-center justify-content-center bg-dark-yellow circle position-absolute  text-dark"
                >
                  {troop.level}
                </div>
                <img
                  className="circle scaleX m-1 "
                  width={windowWidth < 768 ? 40 : "100%"}
                  alt={troop.tempId + ""}
                  src={troop.icon}
                />
              </motion.div>
            </div>
          ))
        )
      ) : (
        troops.map((troop, index) => (
          <div
            className={
              windowWidth > 768 ? "col-3 col-md-6 col-lg-4 col-xl-3 p-0" : ""
            }
            key={index}
          >
            <motion.div
              onClick={() => onSelect(troop)}
              animate={{
                scale: activeTroop?.id === troop.id ? 1.06 : 1,
              }}
              whileHover={{ scale: 1.1, filter: "brightness(1.3)" }}
              className={classNames("troop-frame", {
                selected: activeTroop?.id === troop.id,
              })}
            >
              <img
                className="circle scaleX m-1 "
                width={windowWidth < 768 ? 40 : "100%"}
                alt={troop.name}
                src={troop.icon}
              />
            </motion.div>
          </div>
        ))
      )}
    </>
  );
  const CharacterSelection = () => (
    <MidBox modal title="Character Selection" className="w-100">
      <div className="row ">
        {isMyArmyOnly ? (
          myArmy.length === 0 ? (
            <div
              style={{ marginBottom: -60 }}
              className="d-flex  justify-content-center align-items-center"
            >
              <div className="fs-md bg-dark-500 d-flex align-items-center bg-blur p-2 rounded text-white">
                <CustomIcon
                  icon="warning"
                  className="scaleX me-1"
                  width={20}
                  height={20}
                />{" "}
                You have no troops in your army
              </div>
            </div>
          ) : (
            myArmy.map((troop, index) => (
              <div
                className="col-12 col-md-6 col-lg-4 col-xl-3 p-0"
                key={index}
              >
                <motion.div
                  onClick={() =>
                    !Boolean(
                      selectedTroops.find(
                        (t) => t.playerTroopId === troop.playerTroopId
                      )
                    ) && onSelect(troop)
                  }
                  initial={{ scale: 0.5 }}
                  animate={{
                    scale: activeTroop?.tempId === troop.tempId ? 1.06 : 1,
                  }}
                  className={classNames("troop-frame position-relative", {
                    disabled: Boolean(
                      selectedTroops.find(
                        (t) => t.playerTroopId === troop.playerTroopId
                      )
                    ),
                    selected: activeTroop?.tempId === troop.tempId,
                  })}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      top: 5,
                      right: 5,
                      zIndex: 20,
                    }}
                    className="level d-flex align-items-center justify-content-center bg-dark-yellow circle position-absolute  text-dark"
                  >
                    {troop.level}
                  </div>
                  <img
                    className="circle scaleX w-100 m-1 "
                    alt={troop.tempId + ""}
                    src={troop.icon}
                  />
                </motion.div>
              </div>
            ))
          )
        ) : (
          troops.map((troop, index) => (
            <div
              style={{
                maxWidth: windowWidth < 768 ? "50px" : "auto",
              }}
              className={classNames("col-3 col-md-6 col-lg-4 col-xl-3 p-0", {
                "w-auto": windowWidth < 768,
              })}
              key={index}
            >
              <motion.div
                onClick={() => onSelect(troop)}
                initial={{ scale: 0.5 }}
                animate={{
                  scale: activeTroop?.id === troop.id ? 1.06 : 1,
                }}
                whileHover={{ scale: 1.1, filter: "brightness(1.3)" }}
                className={classNames("troop-frame", {
                  selected: activeTroop?.id === troop.id,
                })}
              >
                <img
                  className="circle scaleX w-100 m-1 "
                  alt={troop.name}
                  src={troop.icon}
                />
              </motion.div>
            </div>
          ))
        )}
      </div>
    </MidBox>
  );

  const CharacterSelectionRow = () => {
    return (
      <PerfectScroll style={{ maxWidth: "100%" }} className="d-flex">
        <div className="d-flex">
          <CharacterMap />
        </div>
        ;
      </PerfectScroll>
    );
  };

  const SelectedArmy = () => (
    <>
      <MidBox
        modal
        title="Army"
        className="w-100 px-1 p-relative"
        containerClass="px-md-2"
        containerStyle={{ padding: "0px !important" }}
      >
        <div className="d-flex justify-content-center flex-wrap gap-2">
          {selectedTroops.map((troop, index) => (
            <div
              // onClick={() => selectUnit(troop.playerTroopId)}

              className={classNames("game-bar-troop-slot p-0 ", {})}
              key={troop.tempId}
            >
              <div className="level bg-dark-yellow text-dark">
                {troop.level}
              </div>
              <div
                onClick={() => onRemove(troop)}
                className="dismiss position-absolute circle bg-danger p-1 top-0 right-0"
              >
                <CustomIcon icon={"close"} type="svg" width={10} height={10} />
              </div>
              <div className="content">
                <img
                  className="circle"
                  width={"100%"}
                  src={troop.icon}
                  alt={troop.name}
                />
              </div>
            </div>
          ))}
          {troopLength < 10 &&
            new Array(10 - troopLength).fill(0).map((_, index) => (
              <div className="game-bar-troop-slot disabled p-0 ">
                <div className="content"></div>
              </div>
            ))}
        </div>
        <div className="army-action-bar">
          <div className="troop-cost-container d-flex justify-content-center align-items-center flex-row">
            Total Army Cost:{" "}
            <span
              className={classNames("d-inline", {
                "text-danger bold": totalCost > MaxCost,
              })}
            >
              {ThousandsSeparator(totalCost || 0)}{" "}
            </span>{" "}
            / {ThousandsSeparator(MaxCost)} CLEG
          </div>
        </div>
      </MidBox>
      {messages && messages[messages.length - 1] && (
        <div className="mt-4 justify-content-center align-items-center bg-dark-500 bg-blur p-2 rounded text-white">
          {messages[messages.length - 1]?.message}
        </div>
      )}
      <div className="d-flex mt-2 justify-content-center align-items-center  gap-2">
        <Button
          variant="primary"
          onClick={onReadyCheck}
          disabled={
            isReady || selectedTroops.length === 0 || totalCost > MaxCost
          }
        >
          Ready&nbsp;{" "}
          <CustomIcon icon={"blades"} type="png" width={20} height={20} />
        </Button>

        {isReady && (
          <Button variant="default" onClick={onNotReady} className="bg-100">
            Not Ready
          </Button>
        )}
      </div>
      {totalCost > MaxCost && (
        <div className="p-2 bg-dark-500 mt-3 fs-small bg-blur text-danger rounded ">
          <CustomIcon
            icon="warning"
            className="scaleX me-1"
            width={15}
            height={15}
          />{" "}
          You can't ready this match, you have not enough resources
        </div>
      )}
    </>
  );

  if (windowWidth < 768) {
    return (
      <div>
        <div className="formation-model-preview position-relative">
          {Boolean(formationTimeOut) && (
            <Timer
              date={formationTimeOut}
              onComplete={() => {
                if (!isReady) onReadyCheck();
              }}
            />
          )}
          <PreviewerContent changeLevel={changeLevel} onSubmit={onSubmit} activeTroop={activeTroop} isMyArmyOnly={isMyArmyOnly} windowWidth={windowWidth} />
        </div>
        <div className="py-2 formation-row">
          <CharacterSelectionRow />
        </div>
        <div>
          <SelectedArmy />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "row w-100 justify-content-start align-items-center",
        {}
      )}
    >
      {Boolean(formationTimeOut) && (
        <Timer
          date={formationTimeOut}
          onComplete={() => {
            if (!isReady) onReadyCheck();
          }}
        />
      )}
      {windowWidth > 768 && (
        <div className="col-12 col-md-6 col-lg-4">
          <CharacterSelection />
        </div>
      )}
      <div className="col-12 col-md-6 col-lg-4 flex relative">
        <PreviewerContent changeLevel={changeLevel} onSubmit={onSubmit} activeTroop={activeTroop} isMyArmyOnly={isMyArmyOnly} windowWidth={windowWidth} />
      </div>
      <div className="col-12 col-md-6 col-lg-4 flex ">
        <SelectedArmy />
      </div>
    </div>
  );
};




export default TroopSelection;
