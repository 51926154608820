export const GuildLanguages = [
  "English",
  "Spanish",
  "Portugues",
  "Arabic",
  "Chinees",
  "Japanese",
  "Russian",
  "Phillipino",
  "Turkish"
]

export const GuildJoinValues = {
  1: 'Public',
  2: 'Permit',
  3: 'Invite Pass',
  4: 'Commander Pass'

}
export const GuildPlayerState = {
  1: 'Owner',
  2: 'Admin',
  5: 'Member',
  10: 'Invited',
  11: 'InviteRejected',
  12: 'JoinRequest',
  13: 'Left',
  14: 'Kicked'
}
