import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { GuildJoinValues, GuildLanguages } from "../data";
import Button from "../../../components/Button";
import { Formik } from "formik";
import { GuildJoinTypeEnum } from "../types";
import Network, { ApiUrl, BaseURL } from "../../../utils/Network";
import useToast from "../../../hooks/useToast";
import Dialog from "../../../components/Dialog";
import classNames from "classnames";
type GuildCreateType = {
  id?: string;
  name: string;
  description: string;
  language: string;
  joinType: GuildJoinTypeEnum;
  image: string;
  banner: string;
  minRankScore: number;
};
type Props = {
  initialValue?: GuildCreateType;
  onSuccess: () => void;
  images?: {
    images: string[];
    banners: string[];
  };
};

const Build = ({ onSuccess, initialValue, images }: Props) => {
  const axios = new Network();
  const openToast = useToast();
  const [selectDialog, setSelectDialog] = useState({
    open: false,
    images: [""],
    key: "banner",
    onSubmit: () => undefined,
  });
  const isUpdateMode = Boolean(initialValue);

  const initialValues: GuildCreateType = {
    name: initialValue?.name ?? "",
    description: initialValue?.description ?? "",
    language: initialValue?.language ?? GuildLanguages[0],
    joinType: initialValue?.joinType ?? GuildJoinTypeEnum.Public,
    image: initialValue?.image ?? "",
    banner: initialValue?.banner ?? "",
    minRankScore: initialValue?.minRankScore ?? 0,
  };

  const handleCreateGuild = async (body: GuildCreateType) => {
    const response = await axios.CallApi({ url: "/guild/create", body });
    openToast(response);
    if (response.ok) {
      onSuccess();
    }

  };

  const handleUpdateGuild = async (body: GuildCreateType) => {
    const response = await axios.CallApi({
      url: `/guild/${initialValue?.id}/update`,
      body,
    });
    openToast(response);
    if (response.ok) {
      onSuccess();
    }
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(v: GuildCreateType, { setSubmitting }) =>
          !isUpdateMode
            ? handleCreateGuild(v).then(() => setSubmitting(false))
            : handleUpdateGuild(v).then(() => setSubmitting(false))
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row px-3 pb-3 justify-content-center align-items-center">
              <div className="col-12 col-md-4">
                <div className="flex-1 d-flex flex-column gap-3 mt-3 px-2 h-100">
                  <div className="d-flex flex-1 align-items-center w-100 justify-content-between">
                    <img
                      src={
                        values.image
                          ? values.image.includes("chainoflegends")
                            ? values.image
                            : BaseURL + values.image
                          : "/assets/images/avatar-bg.png"
                      }
                      className="circle"
                      width={130}
                      height={130}
                    />
                    <div>
                      <Button
                        type="button"
                        onClick={() => {
                          setSelectDialog((prev) => ({
                            ...prev,
                            open: true,
                            key: "image",
                            images: images?.images!,
                          }));
                        }}
                        className="bg-100"
                        variant="primary"
                      >
                        Select Avatar
                      </Button>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-1 align-items-center w-100 justify-content-between">
                    <img
                      style={{ borderRadius: 26 }}
                      src={
                        values.banner
                          ? values.banner.includes("chainoflegends")
                            ? values.banner
                            : BaseURL + values.banner
                          : "/assets/images/backgrounds/guild-hall.jpg"
                      }
                      className=""
                      width={"100%"}
                    />
                    <div>
                      <Button
                        style={{ marginTop: -20 }}
                        className="bg-100"
                        variant="primary"
                        type="button"
                        onClick={() => {
                          setSelectDialog((prev) => ({
                            ...prev,
                            open: true,
                            key: "banner",
                            images: images?.banners!,
                          }));
                        }}
                      >
                        Select Theme
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="flex-1 d-flex flex-column gap-2 gap-lg-3  mt-3 px-2 h-100">
                  <div className="d-flex flex-column gap-1 w-100">
                    <label className=" d-flex align-items-center gap-1 dark-yellow">
                      Name
                    </label>
                    <Form.Control
                      size="sm"
                      name={"name"}
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center gap-1 w-100">
                    <label className=" d-flex align-items-center gap-1 dark-yellow">
                      <img
                        width={24}
                        height={24}
                        src={"/assets/images/icons/letter.png"}
                      />
                      Type:
                    </label>
                    <Form.Select
                      style={{ maxWidth: 200 }}
                      name={"joinType"}
                      value={values.joinType}
                      onChange={(e)=>setFieldValue('joinType',+e.target.value)}
                    >
                      <option disabled value="">
                        Choose
                      </option>
                      {Object.entries(GuildJoinValues).map(([key, value]) => (
                        <option value={+key}>{value}</option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="d-flex justify-content-between align-items-center gap-1 w-100">
                    <label className=" d-flex align-items-center gap-1 dark-yellow">
                      <img
                        width={24}
                        height={24}
                        src={"/assets/images/icons/earth.png"}
                      />
                      Language:
                    </label>
                    <Form.Select
                      style={{ maxWidth: 200 }}
                      name={"language"}
                      value={values.language}
                      onChange={handleChange}
                    >
                      <option disabled value="">
                        Choose
                      </option>
                      {GuildLanguages.map((lang) => (
                        <option value={lang}>{lang}</option>
                      ))}
                    </Form.Select>
                  </div>

                  <div className="d-flex justify-content-between align-items-center gap-1 w-100">
                    <label className=" d-flex align-items-center gap-1 dark-yellow">
                      <img
                        width={24}
                        height={24}
                        src={"/assets/images/icons/glory.png"}
                      />
                      Min. Score:
                    </label>
                    <Form.Control
                      size="sm"
                      name={"minRankScore"}
                      value={values.minRankScore}
                      onChange={handleChange}
                      style={{ maxWidth: 200 }}
                      placeholder="eg: 300"
                    />
                  </div>
                  <div className="d-flex flex-column pt-2 pt-lg-1 gap-1 w-100">
                    <label className=" d-flex align-items-center gap-1 dark-yellow">
                      Description
                    </label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      size="sm"
                      name={"description"}
                      value={values.description}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3 text-center">
                <Button
                  loading={isSubmitting}
                  type="submit"
                  className="mx-auto"
                  variant="wide-success"
                >
                  {isUpdateMode ? "Save Changes" : "Create Guild"}
                </Button>
              </div>
            </div>
            <Dialog
              size="lg"
              submitLabel="Save"
              open={selectDialog.open}
              onSubmit={()=>   setSelectDialog((prev) => ({ ...prev, open: false }))}
              onClose={() =>
                setSelectDialog((prev) => ({ ...prev, open: false }))
              }
            >
              <div style={{marginBottom:-40}} className="row">
                {selectDialog.images.map((src) => (
                  <div
                    key={src}
                    className={classNames(
                      "col-6 border-warning col-md-4 rounded p-1",
                      {
                        border: src === values[selectDialog.key],
                      }
                    )}
                  >
                    <img
                      onClick={() => setFieldValue(selectDialog.key, src)}
                      className="rounded image-hover"
                      src={BaseURL + src}
                      width={"100%"}
                    />
                  </div>
                ))}
              </div>

              <div></div>
            </Dialog>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Build;
