import React, { useEffect } from "react";
import { leaderBoardTabs } from "./data";
import { CustomIcon } from "../../../components/Icons";
import { useNavigate,useParams } from "react-router-dom";
import classNames from "classnames";
type Props = {};

const LeaderBoardTabs = (props: Props) => {
  const [active, setActive] = React.useState(leaderBoardTabs[0].id);
  const navigate = useNavigate();
  const {type} =useParams()
  const handleChange = (tab:typeof leaderBoardTabs[0]) => {
    navigate(tab.link);
    setActive(tab.id);
  };

  useEffect(()=>{
    if(type){
      setActive(leaderBoardTabs.find(i=>i.type===type)?.id)
    }
  },[])
  
  return (
    <div style={{marginTop:-30}}>
        <div className="d-flex gap-2 flex-wrap justify-content-center align-items-end">
          {leaderBoardTabs?.map((item, i) => (
            <>
              <div onClick={() => !item.disabled &&  handleChange(item)} className={classNames("d-flex ",{
              })}>
                <div className={classNames("main-tab-btn mb-2 p-0 pointer ",{
                  'button-disabled': item.disabled,
                })}>
                  <img
                    className="img-fluid"
                    src={`/assets/images/buttons/${
                      active === item.id
                        ? "active-tab-btn.png"
                        : "off-tab-btn.png"
                    }`}
                  />
                  <div className="b-text d-flex align-items-center justify-content-center px-3 text-light">
                    <span>{item.name}</span>
                  </div>
                </div>
              </div>
              {i + 1 !== leaderBoardTabs?.length && (
                  <CustomIcon icon="arrow" type="svg" />
              )}
            </>
          ))}
        </div>
    </div>
  );
};

export default LeaderBoardTabs;
