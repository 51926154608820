import { ethers } from "ethers";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { Badge, Image } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAccount, useWalletClient } from "wagmi";
import MidBox from "../../components/Boxes/MidBox";
import Button from "../../components/Button";
import Connector from "../../components/Connector";
import UsdToClegDialog from "../../components/Dialogs/UsdToClegDialog";
import useToast from "../../hooks/useToast";
import { setUserInfo } from "../../redux/user/action";
import abi from "../../utils/contractABI.json";
import Network, { ContractAddress } from "../../utils/Network";
import { roundFloat } from "../../utils/round-float";

const DepositCard = () => {
  const axios = new Network();

  const userInfo = useSelector((state: any) => state.userInfo);

  const [clegBalance, setClegBalance] = useState<any>("");
  const [depositAmount, setDepositAmount] = useState("");
  const [buyDialog, setBuyDialog] = useState(false);
  const [btnLoading, setBtnLoading] = useState("");

  const [searchParams] = useSearchParams();
  const [connectorDialog, setConnectorDialog] = useState(false);

  const { data: provider } = useWalletClient();
  const openToast = useToast();
  const navigate = useNavigate();
  const { address } = useAccount();
  const location = useLocation();
  const dispatch = useDispatch();

  const usdAvailable = searchParams?.get("mode") === "usd";

  const getContractBalance = useCallback(async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://bsc-rpc.publicnode.com"
      );
      const contract = new ethers.Contract(ContractAddress, abi, provider);
      const balance = await contract.balanceOf(userInfo?.walletAddress);
      const parsedBalance =
        parseFloat(ethers.utils?.formatUnits(balance)) > 0
          ? ethers.utils?.formatUnits(balance)
          : 0;
      setClegBalance(parsedBalance);
    } catch (e) {
      console.log(e);
    }
  }, [userInfo?.walletAddress]);

  const validateInput = (
    userInfo: any,
    web3Provider: any,
    depositAmount: string,
    address: string | null,
    clegBalance: string
  ) => {
    let message;

    if (parseFloat(depositAmount || "0") <= 0) {
      message = "Sorry, Minimum deposit is 1 CLEG";
    } else if (+clegBalance?.toString() < +depositAmount?.toString()) {
      message = "You don't have enough cleg to deposit";
    } else if (!userInfo) {
      message = "Cannot find user details, try again or reload the page.";
    } else if (!web3Provider) {
      message = "Cannot find wallet provider please report the issue.";
    } else if (
      address?.toLocaleLowerCase() !==
      userInfo?.walletAddress?.toLocaleLowerCase()
    ) {
      message =
        "Your connected wallet does not match you account wallet address";
    }
    return message;
  };

  function handleShowConnector() {
    openToast({
      ok: false,
      message: "Please Connect your Wallet",
    });
    if (!userInfo?.walletAddress?.startsWith("0x")) {
      return navigate({
        pathname: location?.pathname,
        hash: "#settings",
      });
    }
    setConnectorDialog(true);
  }

  const getData = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };

  async function confirmDeposit(tx: string) {
    setBtnLoading("deposit");
    const response = await axios.CallApi({
      url: "/player/deposit?TransactionHash=" + tx,
      method: "get",
    });
    if (response.ok) {
      getContractBalance();
      getData();
      if (!response.data.message)
        response.data.message = "Deposit submitted successfully";
    }
    openToast(response);
    setBtnLoading("");
  }

  async function web3Deposit(web3Provider: any) {
    const errorMessage = validateInput(
      userInfo,
      web3Provider,
      depositAmount,
      address || null,
      clegBalance
    );
    if (errorMessage) {
      return openToast({
        ok: false,
        message: errorMessage,
      });
    }

    try {
      const provider = new ethers.providers.Web3Provider(web3Provider);
      const signer = provider.getSigner(userInfo?.walletAddress);
      const contract = new ethers.Contract(ContractAddress, abi, signer);
      setBtnLoading("deposit");
      const depositHex = ethers?.utils
        ?.parseUnits(depositAmount)
        ?.toHexString();

      const [gasLimit, gasPrice] = await Promise.all([
        contract.estimateGas.transfer(ContractAddress, depositHex),
        provider.getGasPrice(),
      ]);

      const result = await contract.transfer(ContractAddress, depositHex, {
        gasLimit: gasLimit,
        gasPrice: gasPrice,
      });
      await confirmDeposit(result?.hash);
      setBtnLoading("");
    } catch (e) {
      setBtnLoading("");
      e?.message && openToast({ ok: false, message: e?.message });
    }
  }

  useEffect(() => {
    getContractBalance();
  }, [getContractBalance]);

  return (
    <motion.div
      initial={isMobile ? undefined : { opacity: 0, x: -800 }}
      transition={{
        duration: 0.3,
        delay: 0.2,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{ opacity: 0, x: -800 }}
      className="col-12 col-md-6"
    >
      <MidBox modal title="Deposit" id="deposit" className="text-center">
        <div className="mb-2">
          <Image
            style={{ transform: "rotate(180deg)" }}
            className="img-fluid"
            src="/assets/images/icons/withdraw.png"
            alt="Deposit"
          />
        </div>
        <div className="mb-3  prof-stp1 seventeen-step">
          <div className="text-light fw-normal fs-big mb-1">Wallet Balance</div>
          <div className="t-balance dark-yellow fw-bold fs-lg text-space">
            <span id="spanWalletBalance">
              {roundFloat(clegBalance, 3) || 0}
            </span>{" "}
            CLEG
          </div>
        </div>
        <div className="prof-step2">
          <div className="px-1  px-xl-4   mb-3 text-start">
            <div className="d-flex flex-wrap justify-content-between dark-bg rounded-10 px-3 px-md-4 py-2 mb-2">
              <div>
                <div className="fs-small">Amount(CLEG)</div>
                <input
                  onKeyDown={(event) => {
                    if (!/^[0-9.]$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={depositAmount}
                  placeholder="Enter Amount"
                  onChange={(e) => {
                    try {
                      const value = e.target.value;
                      if (value?.split(".")?.length > 2) {
                        return;
                      }
                      setDepositAmount(roundFloat(value)?.toString());
                    } catch (e) {
                      return;
                    }
                  }}
                  type="number"
                  className="dark-yellow fw-med fs-med transparent-input"
                />
              </div>
              <Button
                onClick={() => setDepositAmount(clegBalance)}
                style={{ marginLeft: "auto" }}
              >
                <span className="fs-med pointer fw-normal teal-color text-decoration-underline d-block">
                  Total
                </span>
              </Button>
            </div>
          </div>
          <a
            className="mb-3 d-block"
            referrerPolicy="no-referrer"
            href="https://jumper.exchange/exchange?toChain=56&toToken=0x4027d91eCD3140e53AE743d657549adfeEbB27AB"
            target="_blank"
            rel="noreferrer"
          >
            <div className="d-flex flex-wrap align-items-center justify-content-evenly p-vi bg-promote text-white">
              Buy CLEG with Cryptocurrency
              <div className="px-2">
                {CryptoList?.map((crypto) => {
                  return (
                    <Image
                      key={crypto?.id}
                      style={{ marginRight: -10 }}
                      width={25}
                      alt={crypto?.id}
                      src={crypto?.src}
                    />
                  );
                })}
              </div>
            </div>
          </a>

          {usdAvailable && (
            <Button onClick={() => setBuyDialog(true)}>
              <div
                style={{ borderColor: "rgba(255,255,255,0.4)" }}
                className="p-vi  text-white"
              >
                Buy CLEG with credit card {isMobile ? "" : "|"}{" "}
                <Image
                  width={70}
                  src="/assets/images/icons/visa.png"
                  alt="Visa Card"
                />
                <Badge className="bg-danger ms-2">New</Badge>
              </div>
            </Button>
          )}
          <br />
          <div className="fs-tiny dark-gray">
            Deposits may take a few minutes to reflect in your balance
          </div>
          <br />
        </div>
        <Button
          loading={btnLoading === "deposit"}
          onClick={() =>
            localStorage.getItem("connector") === "email" && !address
              ? handleShowConnector()
              : web3Deposit(provider)
          }
          variant="wide-primary"
          style={{ width: "240px" }}
          className="mx-auto"
        >
          <Image
            width={30}
            height={30}
            className="img-fluid"
            src="/assets/images/logos/metamask-circle.png"
            alt="Metamask"
          />
           <span className="text-light d-block">Deposit</span>
        </Button>
        <Link to="/transaction-history">
          <div className="dark-yellow prof-step3 eighteen-step mt-3">
            <Image
              width={20}
              height={20}
              alt="tx"
              className="img-fluid"
              src="/assets/images/icons/tx.svg"
            />
             Transaction History
          </div>
        </Link>

        <Connector
          onClose={() => setConnectorDialog(false)}
          open={connectorDialog}
          onSign={({ sign, account, connector }) => {
            setConnectorDialog(false);
            if (
              account?.toLowerCase() !== userInfo?.walletAddress?.toLowerCase()
            ) {
              return openToast({
                ok: false,
                message: "You can deposit with your connected wallet only",
              });
            }
            localStorage.setItem("connector", connector);
            web3Deposit(provider);
          }}
        />
        <UsdToClegDialog open={buyDialog} onClose={() => setBuyDialog(false)} />
      </MidBox>
    </motion.div>
  );
};

const CryptoList = [
  {
    id: "ltc",
    src: "/assets/images/icons/coins/ltc.png",
  },
  {
    id: "usdt",
    src: "/assets/images/icons/coins/usdt.png",
  },
  {
    id: "eth",
    src: "/assets/images/icons/coins/eth.png",
  },
  {
    id: "bnb",
    src: "/assets/images/icons/coins/bnb.svg",
  },
  {
    id: "btc",
    src: "/assets/images/icons/coins/btc.svg",
  },
];

export default DepositCard;
