import React, { useRef } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { roundFloat } from "../../utils/round-float";
import "react-loading-skeleton/dist/skeleton.css";
import useWindowSize from "../../hooks/useWindowSize";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { ClegIcon } from "../Icons";

type Props = {
  action?: React.ReactNode;
  rate: string | number;
  tokenID?: string | number;
  name: string;
  cleg?: string | number;
  stone?: string | number;
  qty?: number;
  metal?: string | number;
  image?: string;
  description?: string;
  nft?: boolean;
  cycleNumber?: number;
  state?: number;
  clegPrice: number;
  hint: boolean;
  loading?: boolean;
  PricePerCleg?: string | number;
  tabIndex?: number;
  price?: number;
  className: string;
  type: number | 1 | 2;
};

const LandBox = (props: Props) => {
  const ref = useRef<any>();
  const windowSize = useWindowSize();
  const getWidth = () => ref?.current?.offsetWidth;

  return (
    <SkeletonTheme
      enableAnimation={!isMobile}
      baseColor="#352d22"
      highlightColor="#3e362d"
    >
      {!props.type || props.type === 1 ? (
        <div
          tabIndex={props.tabIndex}
          ref={ref}
          className={"land-box py-4 text-light " + props.className}
        >
          {props.qty && <div className="lands-count">x{props.qty}</div>}
          {!props.nft && !props.loading && (
            <span className="badge-cycle mrkt-stp3 cycle-3">
              Cycle {props.cycleNumber}
            </span>
          )}
          <div className="lb-body px-2 mb-3">
            <div
              ref={ref}
              className={"text-center mb-4 " + (!props.loading && "d-none")}
            >
              <span>
                <Skeleton
                  width={getWidth() - 75}
                  height={getWidth() - 75}
                  borderRadius={15}
                  circle={windowSize.width >= 1400}
                />
              </span>
            </div>
            {!props.loading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="image px-3 text-center"
              >
                <img
                  style={{
                    marginBottom: props.nft ? 20 : 0,
                  }}
                  src={props.image}
                  alt={props.name}
                  className="img-fluid w-100"
                />
              </motion.div>
            )}
            <div className="lb-title">
              <h3 className="h5 mb-0">
                {props.loading ? (
                  <Skeleton width={"30%"} borderRadius={10} height={20} />
                ) : (
                  props.name
                )}{" "}
              </h3>
              <div className="d-flex align-items-center justify-content-between">
                {props.loading ? (
                  <>
                    <Skeleton width={100} borderRadius={10} height={15} />
                    <Skeleton width={30} borderRadius={10} height={20} />
                  </>
                ) : (
                  <>
                    <h5 className="fs-med m-0">
                      {props.nft ? "Token ID :" : "Production Boost:"}
                    </h5>
                    <span className="fs-small purple-bg percent-badge">
                      {props.tokenID ||
                        (parseInt(props.rate.toString()) > 0
                          ? `+${props.rate}`
                          : props.rate) + "%"}
                    </span>
                  </>
                )}
              </div>
            </div>
            {props.loading ? (
              <div className="lb-list fs-med px-4 my-3">
                <div className="lb-list-item mb-1">
                  <div>
                    <Skeleton width={70} borderRadius={5} height={18} />
                  </div>
                  <div className="fs-med">
                    {" "}
                    <Skeleton width={40} borderRadius={5} height={18} />
                  </div>
                </div>
                <div className="lb-list-item mb-1">
                  <div>
                    <Skeleton width={70} borderRadius={5} height={18} />
                  </div>
                  <div className="fs-med">
                    {" "}
                    <Skeleton width={40} borderRadius={5} height={18} />
                  </div>
                </div>
                <div className="lb-list-item mb-1">
                  <div>
                    <Skeleton width={70} borderRadius={5} height={18} />
                  </div>
                  <div className="fs-med">
                    {" "}
                    <Skeleton width={40} borderRadius={5} height={18} />
                  </div>
                </div>
              </div>
            ) : props.state === 4 ? (
              <div className="lb-list fs-med px-4 my-3 text-center">
                <p>For Sale :</p>
                <h3 className="dark-yellow">
                  {roundFloat(props.clegPrice)}
                  &nbsp;<span className="fs-small">CLEG</span>
                </h3>
              </div>
            ) : !props.nft ? (
              <div className="lb-list mrkt-stp5 fs-med px-4 my-3">
                <div className="lb-list-item mb-1">
                  <div>
                    <img src="/assets/images/icons/cleg.png" alt="Cleg" className="me-1"/>
                    CLEG
                  </div>
                  <div className="fs-med">{props.cleg}</div>
                </div>
                <div className="lb-list-item mb-1">
                  <div>
                    <img
                      className="me-1"
                      src="/assets/images/icons/stone.png"
                      alt="Stone"
                    />
                    Stone
                  </div>
                  <div className="fs-med">{props.stone}</div>
                </div>
                <div className="lb-list-item mb-1">
                  <div>
                    <img
                      className="me-1"
                      src="/assets/images/icons/metal.png"
                      alt="Metal"
                    />
                    Iron
                  </div>
                  <div className="fs-med">{props.metal}</div>
                </div>
                {props.PricePerCleg && (
                  <div
                    style={{ marginBottom: -10 }}
                    className="text-center mrkt-stp6"
                  >
                    Price Per{" "}
                    <img
                      width={20}
                      height={20}
                      src="/assets/images/icons/cleg.png"
                      alt="Cleg"
                    />{" "}
                    Cleg: {props.PricePerCleg}
                  </div>
                )}
              </div>
            ) : (
              <div className="fs-med px-4 my-3 dark-yellow">
                {props.description}
              </div>
            )}
          </div>
          {/* <div className="text-center d-none">
                Please Wait <img src="/assets/images/Gear-loading.svg" />
            </div> */}

          <div className="lb-button text-center px-2">
            {props.loading ? (
              <div className="mt-1" style={{ marginBottom: -50 }}>
                <Skeleton width={"90%"} borderRadius={10} height={50} />
              </div>
            ) : (
              props.action
            )}
            {/* <button name="btnBuyPlayerLand"  className="theme-btn brown-btn ">
                    <img className="img-fluid" src="/assets/images/Webbit/brown-btn-wide.png"/>
                        <div className="b-text">
                            <span className="text-light" >
                                Buy <span className="purple-bg text-light ms-2 rounded-10 px-2">(110.2 CLEG)</span>
                            </span>
                        </div>
                </button> */}
          </div>
        </div>
      ) : (
        <div
          tabIndex={props.tabIndex}
          ref={ref}
          className={"land-box-tiny pt-2 text-light " + props.className}
        >
          {/* {!props.nft && !props.loading && <span className="badge-cycle mrkt-stp3 cycle-3">Cycle {props.cycleNumber}</span>} */}
          <div className="d-flex align-items-center justify-content-between  px-2 mb-0">
            <div className="d-flex align-items-center ">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="image px-1 text-center"
              >
                {props.loading ? (
                  <Skeleton width={80} borderRadius={"50%"} height={80} />
                ) : (
                  <img
                    style={{
                      maxWidth: 80,
                      height: "auto",
                      marginBottom: props.nft ? 20 : 0,
                    }}
                    src={props.image}
                    alt={props.name}
                    className="img-fluid w-100"
                  />
                )}
              </motion.div>
              <div
                style={{ marginLeft: props.loading ? 0 : -8 }}
                className="lb-title"
              >
                <h3 className="h5 mb-0">
                  {props.loading ? (
                    <Skeleton width={"30%"} borderRadius={10} height={20} />
                  ) : (
                    props.name
                  )}

                  {props.qty && (
                    <div
                      style={{ top: 0, bottom: 0, left: 0 }}
                      className="lands-count ms-1 fs-small py-0 p-relative m-0 d-inline-block"
                    >
                      x{props.qty}
                    </div>
                  )}
                </h3>
                <div className="d-flex align-items-center justify-content-between">
                  {props.loading ? (
                    <>
                      <Skeleton width={100} borderRadius={10} height={15} />
                      {/* <Skeleton width={30} borderRadius={10} height={20} /> */}
                    </>
                  ) : (
                    <div className="mt-2 d-flex  ">
                      <div className=" fs-tiny ">
                        {props.nft ? "Token ID :" : "Boost:"}
                      </div>
                      <span className="fs-tiny purple-bg rounded px-1 ms-1  percent-badge">
                        {props.tokenID ||
                          (parseInt(props.rate.toString()) > 0
                            ? `+${props.rate}`
                            : props.rate) + "%"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="lb-action pe-2">
              {props.loading ? (
                <div className="mt-1">
                  <Skeleton width={"90%"} borderRadius={10} height={50} />
                </div>
              ) : (
                props.action
              )}
            </div>
          </div>
          {props.PricePerCleg && (
            <div className="text-center fs-small px-3 mrkt-stp6 mb-2 d-flex justify-content-between">
              <div>
                Price Per{" "}
                <img
                  width={20}
                  height={20}
                  src="/assets/images/icons/cleg.png"
                  alt="Cleg"
                />{" "}
                Cleg: {props.PricePerCleg}
              </div>
              <span className="purple-bg ms-2 rounded ps-1 pe-2 ">
                <ClegIcon width={20} height={20} /> {props.price}
              </span>
            </div>
          )}
          {props.loading ? (
            <div className="lb-list fs-med px-5 pt-3 pb-4 mb-5">
              <div className="lb-list-item mb-1">
                <div>
                  <Skeleton width={70} borderRadius={5} height={18} />
                </div>
                &nbsp;
                <div className="fs-med">
                  {" "}
                  <Skeleton width={40} borderRadius={5} height={18} />
                </div>
                &nbsp;
                <div className="fs-med">
                  {" "}
                  <Skeleton width={40} borderRadius={5} height={18} />
                </div>
              </div>
            </div>
          ) : props.state === 4 ? (
            <div className="lb-list w-100 justify-content-center fs-med px-4 text-center">
              <div>For Sale :</div>
              <div className="dark-yellow">
                {roundFloat(props.clegPrice)}
                &nbsp;<span className="fs-small">CLEG</span>
              </div>
            </div>
          ) : !props.nft ? (
            <div className="lb-list mrkt-stp5 mt-2 fs-tiny px-3 justify-content-around ">
              <div className="lb-list-item mb-1">
                <div>
                  <img className="ms-1" src="/assets/images/icons/cleg.png" alt="Cleg" />
                </div>
                <div className="fs-med">{props.cleg}</div>
              </div>
              <div className="lb-list-item mb-1">
                <div>
                  <img className="ms-1" src="/assets/images/icons/stone.png" alt="Stone" />
                </div>
                <div className="fs-med">{props.stone}</div>
              </div>
              <div className="lb-list-item mb-1">
                <div>
                  <img className="ms-1" src="/assets/images/icons/metal.png" alt="Metal" />
                </div>
                <div className="fs-med">{props.metal}</div>
              </div>
            </div>
          ) : (
            <div className="fs-med px-4 my-3 dark-yellow">
              {props.description}
            </div>
          )}
          {/* <div className="text-center d-none">
                Please Wait <img src="/assets/images/Gear-loading.svg" />
            </div> */}
        </div>
      )}

      {props.hint && (
        <div className="dark-orange ms-2 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-exclamation-diamond-fill"
            viewBox="0 0 16 16"
          >
            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
          </svg>
          &nbsp; Cleg Mine 0 can't mine this land.
        </div>
      )}
    </SkeletonTheme>
  );
};

export default LandBox;
