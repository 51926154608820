import React from "react";
import Network from "../../utils/Network";
import { roundFloat } from "../../utils/round-float";
import {useDispatch} from 'react-redux'
import { setCurrentCycle } from "../../redux/cycle/action";
import moment from 'moment'
type Props = {};

const Cycles = (props: Props) => {
  const axios = new Network();
  const [cycle, setCycle] = React.useState<any>();

  const dispatch = useDispatch()
  const handleGetCycle = async () => {
    const response = await axios.CallApi({
      url: "/cycles/current",
      method: "get",
    });
    setCycle(response.data);
    dispatch(setCurrentCycle(response.data))
  };

  React.useEffect(() => {
    handleGetCycle();
  }, []);

  const nextCycle = React.useMemo(() => {
    const startDate = new Date(cycle?.StartDate!);
    const currentTime = new Date(Date.now());
    const diff = moment(currentTime).diff(moment(startDate), 'days')
    const now = (diff * 100) / cycle?.MinDays;
    return now;
  }, [cycle]);

  return (
    <div className="text-white pt-5">
      <div className="text-center fs-big">Current Cycle: {cycle?.Number}</div>
      <div className="form-processbar mt-3 align-items-center justify-content-center d-flex">
        <div className="me-3">Next Cycle ({roundFloat(nextCycle, 1)}%) :</div>
        <div
          style={{ maxWidth: "400px", flex: 1, minHeight: 25 }}
          className="progress"
        >
          <div
            style={{
              width: nextCycle + "%",
              minHeight: 16.5,
            }}
            className="progress-bar mx-2 progress-bar-striped blue"
          ></div>
        </div>
      </div>
      <div className="mt-4">
          2000 lands (Common, Uncommon, Rare, and Legendary) will be available
          for buying every day. At the beginning of the game These lands have
          1,300,000 tokens in total to mine. After each cycle, tokens of new
          lands will be reduced by 5%. Token mining rate and adventure rewards
          will also reduce by 5% after each cycle. The duration of the first
          cycle is at least 10 days up to all the lands sold out, the second
          cycle is 11 days, and every cycle is one day longer than the previous
          one. This is a part of Chain of Legends deflationary system which
          limits token mining, and supply, burning tokens aggressively and it’s
          reducing mining rewards over time. As a result, game tokens will
          become more and more rare and valuable. Learn More about{" "}
          <a
            href="https://whitepaper.chainoflegends.com/price-stability"
            target="_blank"
          >
            CLEG price Stability.
          </a>
        </div>
        <div className="text-center mt-3">
          <img style={{maxWidth:'800px'}} width={"100%"} src="/assets/images/daily-chart.jpg"/>
        </div>
    </div>
  );
};

export default Cycles;
