import { actionTypes } from "./action";

export const initialRates = {
  clegInUsd:0,
  expiresAt:undefined
};

export const ratesReducer = (state = initialRates, action: any) => {
  switch (action.type) {
    case actionTypes.SET_RATE:
      return { ...state, ...action.payload };
    case actionTypes.CLEAR_RATES:
      return {};
    default:
      return state;
  }
};
