import React, { Component, ErrorInfo, ReactNode } from "react";
import Button from "../components/Button";
import Network from "../utils/Network";
import { browserName, browserVersion, isDesktop, isMobile, isTablet, osName, osVersion } from 'react-device-detect'
interface Props {
    children?: ReactNode;
    openToast: Function
}

interface State {
    hasError: boolean;
    isSent: boolean;
    loading: boolean;
    error: string;
}

class ErrorBoundary extends Component<Props, State> {


    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isSent: false,
            loading: false,
            error: ''
        };
        this.ReportCrash = this.ReportCrash.bind(this)
        this.componentDidCatch = this.componentDidCatch.bind(this)
    }

    public static getDerivedStateFromError(_: Error) {
        // Update state so the next render will show the fallback UI.

        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
        this.setState({
            error: error.message
        })
    }

    async ReportCrash() {
        this.setState({
            loading: true
        })
        const axios = new Network()
        const report = await axios.CallApi({
            url: '/report/crash',
            secApi: true,
            body: {
                error: this.state?.error || 'Unknown',
                device: {
                    browser: `${browserName}-${browserVersion}`,
                    device: `${isDesktop ? 'Dekstop' : isTablet ? 'Tablet' : 'Mobile'} ${osName}-${osVersion}`
                },
                path: window.location.pathname
            }
        })
        this.props.openToast(report)
        this.setState({
            loading: false,
            isSent: true
        })
    }

    public render() {
        if (this.state.hasError) {
            return <div style={{ minHeight: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <img
                    src="/assets/images/error.png"
                    width={"80%"}
                    style={{ maxWidth: 300 }}
                />
             <p className='dark-yellow text-center fs-big'>
                    Opps Something went wrong ...!
                </p>
                {this.state.error?.includes('insertBefore') && <p className='text-danger bold text-center fs-big'>
                    Please Turn off your Browser Translator
                </p>}
                <div className="d-flex">
                    <Button
                        disabled={this.state.isSent}
                        loading={this.state.loading}
                        onClick={this.ReportCrash} className="mt-3" variant="primary">
                        Report
                    </Button>&nbsp;<Button
                        onClick={() => window.location.reload()} className="mt-3" variant="default">
                        Reload
                    </Button>
                </div>

            </div>

        }

        return this.props.children;
    }
}

export default ErrorBoundary;
