import { ItemType } from "../items"

export type Quest = {
    id: number
    title: string
    description: string
    link: string
    icon: string
    state: QuestState
    rewards: string
    requiredAction: number
    durtaion?: number
    requiredActionValue: number
}
export enum QuestState {
    Open = 1,
    Done,
    Claimed,
    Expired
}
export enum QuestAction {
    Deposit = 1,
    ReferralDeposit = 2,
    ClegMineLevel = 3,
    StoneMineLevel = 4,
    IronMineLevel = 5,
    BarracksLevel = 6,
    WonTreasureId = 7,
    CaptureTroop = 8,
    CompeleteProfile = 9,
    CompleteTourGuide = 10,
    EnableNotification = 11,
    GetDesertLand = 12,
    BuyLand = 13,
    TrainTroop = 14,
    MergeTroop = 15,
    UpgradeTroop = 16,
    BuyStone = 17,
    BuyIron = 18,
    BuyTroop = 19,
    BuyItem = 20,
    SellStone = 21,
    SellIron = 22,
    SellTroop = 23,
    SellItem = 24,
    UseItem = 25,
    FindItemInTreasure = 26,
    SuccessfulRaid = 27,
    SuccessfulDefends = 28,
    SuccessfulRaidLeader = 29
}

export enum QuestRewardType {
    Stone = 1,
    Iron,
    Cleg,
    Land,
    Troop,
    Item
}
export interface ChallengeQuestItemType {
    id: number
    title: string
    description: string
    link: string
    icon: string
    state: number
    rewards: string;
    requiredAction: number
    requiredActionValue: number
}
export interface ChallengeQuest {
    totalScore: number,
    availableScore: number,
    rewards: Array<ChallengeQuestItemType>,
}

