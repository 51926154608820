import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { PrimitiveProps, useFrame, useLoader } from "@react-three/fiber";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
  useAnimations,
  Text,
  RoundedBox,
} from "@react-three/drei";
import * as THREE from "three";
import useGameState from "../hooks/useGameState";
import useGameLogic from "../hooks/useGameLogic";
import { CoolDownsType, ModelData, StoredModelData, Unit } from "../types";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import EventBus, { EventNames } from "../utils/event-bus";
import DamageText from "./troop-model/damage-text";
import { a, useSpring } from "@react-spring/three";
import loadModelFromArrayBuffer from "../utils/load-model-from-array-buffer";
import LoaderIndicator from "./troop-model/loader-indicator";

//FIXME: make dynamic speed

interface ThreeIconProps {
  iconSrc: string;
  position?: PrimitiveProps["position"];
  castShadow?: PrimitiveProps["castShadow"];
  rotation?: PrimitiveProps["rotation"];
  scale?: PrimitiveProps["scale"];
  disableAnimation?: boolean;
  show?: boolean;
}
const ThreeIcon: FC<ThreeIconProps> = ({
  iconSrc,
  disableAnimation,
  show,
  ...rest
}) => {
  const { scene } = useLoader(GLTFLoader, iconSrc);
  const iconRef = useRef<THREE.Object3D>(null);

  //TODO: make dynamic speed
  useFrame(({}) => {
    if (iconRef.current && !disableAnimation) {
      iconRef.current.rotation.y += 0.02; // Adjust the rotation speed here
    }
  });

  const clonedScene = useMemo(() => {
    const clone = SkeletonUtils.clone(scene); // Ensure deep clone for skinned meshes
    clone.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (child.isMesh) child.castShadow = true; // Ensure materials are cloned
      }
    });
    return clone;
  }, [scene]);

  return (
    <>
      <primitive
        ref={iconRef}
        castShadow
        scale={show ? [0.5, 0.5, 0.5] : [0, 0, 0]}
        position={[0.4, -1.2, 0.6]}
        object={clonedScene}
        rotation={[1, 0, 1]}
        {...rest}
      />
    </>
  );
};

const HealthBar = ({ health, maxHealth, isFriend }) => {
  const width = (health / maxHealth) * 2;
  const fixedWidth = 2;

  return (
    <>
      <RoundedBox
        args={[fixedWidth, 0.2, 0.05]}
        radius={0.05}
        smoothness={4}
        position={[0, 1.2, 0]}
      >
        <meshBasicMaterial color={"grey"} />
      </RoundedBox>
      <RoundedBox
        args={[width, 0.2, 0.05]}
        radius={0.05}
        smoothness={4}
        position={[-(fixedWidth - width) / 2, 1.2, 0.01]}
      >
        <meshBasicMaterial color={isFriend ? "green" : "red"} />
      </RoundedBox>
    </>
  );
};

const LevelBadge = ({ position, level }) => {
  const badgeTexture = useLoader(
    THREE.TextureLoader,
    "/assets/images/arena/level-bg.png"
  );
  return (
    <mesh position={position}>
      <planeGeometry args={[1, 1]} />
      <meshBasicMaterial map={badgeTexture} transparent={true} />
      <Text
        fontSize={0.5}
        position={[0, 0, 0.1]}
        color="#FFFFFF"
        anchorX="center"
        anchorY="middle"
      >
        {level.toString()}
      </Text>
    </mesh>
  );
};

const TempTargetIndicator = ({ targetInfo }: { targetInfo?: Unit }) => {
  return (
    <>
      {targetInfo && (
        <mesh position={[0, 1.8, 0]}>
          <Text
            fontSize={0.4}
            position={[0, 0, 0.1]}
            color="#FFFFFF"
            anchorX="center"
            anchorY="middle"
          >
            {targetInfo.troop.name} - Level {targetInfo.level}
          </Text>
        </mesh>
      )}
    </>
  );
};

const TroopModel = ({
  unit,
  modelData,
  target,
  model,
  targetInfo,
  coolDown,
  devMode,
}: {
  unit: Unit;
  modelData: ModelData;
  target?: THREE.Vector3;
  model: StoredModelData;
  targetInfo?: Unit;
  coolDown?: CoolDownsType["troops"][number];
  devMode?: boolean;
}) => {
  // const { animations, scene } = useLoader(GLTFLoader, modelData.src);
  const modelRef = useRef<THREE.Group>(null);
  const { state } = useGameState();
  const groupRef = useRef<THREE.Group>(null);
  const meshRef = useRef<THREE.Mesh>(null);
  const [isAttackCoolingDown, setIsAttackCoolingDown] = useState(false);
  const [isMoveCoolingDown, setIsMoveCoolingDown] = useState(false);
  const [isHealingCoolingDown, setIsHealingCoolingDown] = useState(false);
  const [damageTexts, setDamageTexts] = useState<
    { id: number; damage: number; position: THREE.Vector3 }[]
  >([]);
  const animations = model?.animations || [];
  const scene = model?.scene;

  // console.log(animations.map((anim) => anim.name));
  const modelPosition = useMemo(() => {
    return (
      state.slots.find((slot) => slot.id === `${unit.y}-${unit.x}`)?.position ||
      new THREE.Vector3()
    );
  }, [state.slots, unit.y, unit.x]);

  const health = unit.health;
  const maxHealth = unit.maxHealth;
  const { actions } = useAnimations(animations, modelRef);
  useFrame(({ camera }) => {
    if (groupRef.current && meshRef.current) {
      groupRef.current.position.lerp(modelPosition, 0.06);
      // groupRef.current.lookAt(new THREE.Vector3(modelPosition.x, -40, 10));
      meshRef.current.lookAt(
        new THREE.Vector3(modelPosition.x, camera.position.y, 10)
      );
    }
  });

  useFrame(() => {
    if (modelRef.current && target) {
      modelRef.current.lookAt(
        new THREE.Vector3(modelPosition.x, modelPosition.y, 1)
      );
      // Step 1: Calculate the direction vector
      const dx = target.x - modelPosition.x;
      const dy = target.z - modelPosition.z;

      // Step 2: Calculate the angle using atan2
      const angleInRadians = Math.atan2(dy, dx);
      // Convert to degrees
      let angleInDegrees = angleInRadians * (180 / Math.PI);

      // Normalize to 0-360 degrees
      angleInDegrees = (angleInDegrees + 360) % 360;
      // console.log(THREE.MathUtils.degToRad(angleInDegrees));
      // modelRef.current.rotation.y += THREE.MathUtils.degToRad(angleInDegrees);
      // const isUpper = target.z < modelPosition.z;
      // const isDown = target.z > modelPosition.z;
      // if (target.x < modelPosition.x) {
      //   if (isUpper || isDown) {
      //     return (modelRef.current.rotation.y = isUpper
      //       ? -2.5
      //       : isDown
      //       ? 2.5
      //       : 1.5);
      //   }

      //   return (modelRef.current.rotation.y = -1.5);
      // }

      // if (target.x > modelPosition.x) {
      //   if (isUpper || isDown) {
      //     return (modelRef.current.rotation.y = isUpper
      //       ? 2.5
      //       : isDown
      //       ? 2.5
      //       : -1.5);
      //   }
      //   return (modelRef.current.rotation.y = 1.5);

      // }

      modelRef.current.rotation.y = unit.isAttacker ? 1.5 : -1.5;
      //     console.log(angle);
      //  modelRef.current.rotation.y = angle
    }
  });

  useEffect(() => {
    const mixer = actions[Object.keys(actions)[0]]?.getMixer();
    // Function to play the idle animation
    const playIdleAnimation = () => {
      const idleAction = actions["Idle"] || actions[Object.keys(actions)[1]];
      if (idleAction) {
        idleAction.play();
      }
    };

    // Function to play the attack animation
    const playAttackAnimation = () => {
      const attackAction = actions[1] || actions[Object.keys(actions)[0]];
      if (attackAction) {
        attackAction.reset().play();
        // attackAction.clampWhenFinished = true; // Stop the animation at the end
        attackAction.loop = THREE.LoopOnce; // Play the animation only once
        // Set up an event listener for when the animation finishes
        mixer?.addEventListener("finished", playIdleAnimation);
      }
    };
    // Initial play of the idle animation when the component mounts
    playIdleAnimation();

    // Event handler for starting the attack animation
    const handleStartAttack = ({ modelId }: { modelId: string }) => {
      if (modelId === unit.playerTroopId + "") {
        playAttackAnimation();
      }
    };

    // Register the event listener for attack events
    EventBus.on(EventNames.StartAttack, handleStartAttack);
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      EventBus.off(EventNames.StartAttack, handleStartAttack);

      // Clean up the mixer event listener
      mixer?.removeEventListener("finished", playIdleAnimation);
    };
  }, [actions, unit.playerTroopId]);

  const clonedScene = useMemo(() => {
    if (!scene) return;
    const clone = SkeletonUtils.clone(scene); // Ensure deep clone for skinned meshes
    clone.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (child.isMesh) child.castShadow = true; // Ensure materials are cloned
      }
    });
    return clone;
  }, [scene]);

  useEffect(() => {
    if (modelRef.current) {
      // Force scene update
      modelRef.current.updateMatrixWorld();
    }
  }, [modelRef, clonedScene]);

  function applyDamage({
    modelId,
    damage,
  }: {
    modelId: string;
    damage: number;
  }) {
    if (modelId === unit.playerTroopId + "") {
      const newDamageText = {
        id: Date.now(),
        damage,
        position: new THREE.Vector3(0, 1, 0), // Adjust this for correct positioning
      };
      setDamageTexts((prev) => [...prev, newDamageText]);
    }
  }

  useEffect(() => {
    EventBus.on(EventNames.Damage, applyDamage);
    return () => {
      EventBus.off(EventNames.Damage, applyDamage);
    };
  }, []);

  // Periodically check cooldowns
  useEffect(() => {
    const interval = setInterval(() => {
      const isAttackCoolingDownState = coolDown
        ? new Date(coolDown.actionCoolDownDate).getTime() > Date.now()
        : false;
      const isMoveCoolingDownState = coolDown
        ? new Date(coolDown.moveCoolDownDate).getTime() > Date.now()
        : false;
      const isHealingCoolingDownState = coolDown
        ? new Date(coolDown.healingCoolDownDate).getTime() > Date.now()
        : false;
      setIsAttackCoolingDown(isAttackCoolingDownState);
      setIsMoveCoolingDown(isMoveCoolingDownState);
      setIsHealingCoolingDown(isHealingCoolingDownState);
    }, 500);
    return () => clearInterval(interval);
  }, [coolDown]);

  return (
    <group
      onClick={(e) => {
        // e.stopPropagation();
        // selectUnit(unit.playerTroopId);
      }}
      ref={groupRef}
      renderOrder={1}
      scale={[0.5, 0.5, 0.5]}
    >
      {clonedScene ?
      <primitive
        position={[0, 0, 0]}
        ref={modelRef}
        object={clonedScene}
        rotation={[5, 5, 5]}
        scale={[
          modelData.defaultScale,
          modelData.defaultScale,
          modelData.defaultScale,
        ]}
      /> :<LoaderIndicator  image={unit.troop.icon} size={2} />}
      {!isAttackCoolingDown && (
        <ThreeIcon
          show={!isAttackCoolingDown}
          iconSrc="/assets/models/icons/attack-icon.glb"
          scale={[0.3, 0.3, 0.3]}
          position={[0.8, 0.2, 0]}
          rotation={[0, 0, 0]}
        />
      )}

      {!isMoveCoolingDown && (
        <ThreeIcon
          show={!isMoveCoolingDown}
          iconSrc="/assets/models/icons/move.glb"
          position={[-0.9, 0.2, 0]}
          rotation={[0, 0, 0]}
          scale={[0.25, 0.25, 0.25]}
        />
      )}
      {unit?.troopId === 5 && !isHealingCoolingDown && (
        <ThreeIcon
          iconSrc="/assets/models/icons/heal.glb"
          position={[0, 0, 0.4]}
          rotation={[0, 0, 0]}
          scale={[0.25, 0.25, 0.25]}
        />
      )}
      <mesh ref={meshRef} position={[0, 1, -0.4]} scale={[0.5, 0.5, 0.5]}>
        {devMode && <TempTargetIndicator targetInfo={targetInfo} />}
        <HealthBar
          isFriend={unit.isFriend}
          health={health}
          maxHealth={maxHealth}
        />
        <LevelBadge position={[-1, 1.2, 0.1]} level={unit.level} />
      </mesh>
      {damageTexts.map(({ id, damage, position }) => (
        <DamageText key={id} damage={damage} position={position} />
      ))}
    </group>
  );
};

export default TroopModel;
