import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Form, ProgressBar } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import MidBox from '../components/Boxes/MidBox'
import Button from '../components/Button'
import { roundFloat } from '../utils/round-float'
import useToast from '../hooks/useToast'
import Network from '../utils/Network'
import { motion } from 'framer-motion'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Countdown from 'react-countdown'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'

type Props = {}

const levels = {
    1: 0,
    2: 1,
    3: 2,
    4: 4,
    5: 8,
    6: 16,
    7: 32,
    8: 64
}

const DaoDetails = (props: Props) => {
    const userInfo = useSelector((state: any) => state.userInfo)
    const [data, setData] = useState<any>()
    const [myVote, setMyVote] = useState<any>()
    const [loading, setLoading] = useState<any>(false)
    const [btnLoading, setBtnLoading] = useState<any>(false)
    const [selectedOption, setSelectedOption] = useState<any>()
    const [minerLevel, setMinerLevel] = useState<any>(1)
    const buildings = useSelector((state: any) => state.buildings)
    const axios = new Network()
    const navigate = useNavigate()
    const { id } = useParams()
    const openToast = useToast()
    const getPolls = async () => {
        const response = await axios.CallApi({
            url: '/poll/' + id,
            secApi: true,
            method: 'get'
        })
        setData(response)
    }
    const getMinerLevel = async () => {
        const response = await axios.CallApi({
            url: '/building',
            method: 'get'
        })
        setMinerLevel(response?.data[0]?.currentLevel)
    }

    function getVoteWeight() {
        try {
            if (!buildings) return 0
            let voteWeight = 0
            for (let key in buildings) {
                const level = buildings[key]?.currentLevel || 0
                if (level !== 0) {
                    voteWeight += Math.pow(2, level - 1)
                }

            }
            return voteWeight
        }
        catch (e) {
            console.log(e)
            return 0
        }
    }
    const getVote = async () => {
        const response = await axios.CallApi({
            url: '/poll/myVote/' + id,
            method: 'get',
            secApi: true
        })
        if (response.ok) {
            setMyVote(response.data)
            setSelectedOption(response.data?.pollOptionId)
        }
    }
    const vote = async (id: string) => {
        if (!selectedOption) {
            return openToast({
                ok: false, message: "Please Select an option"
            })
        }
        setBtnLoading(true)

        const response = await axios.CallApi({
            url: '/poll/vote/' + id,
            method: 'post',
            secApi: true
        })
        openToast(response)
        if (response.ok) {
            await getPolls()
            setMyVote(response.vote)
            setSelectedOption(response.vote?.pollOptionId)

        }
        setBtnLoading(false)

    }

    async function getInitialData() {
        setLoading(true)
        await getPolls()
        await getMinerLevel()
        await getVote()
        setLoading(false)
    }

    useEffect(() => {
        getInitialData()
    }, [])


    return (
        <SkeletonTheme baseColor='#2d271b' highlightColor='#3e382b'>
            <Helmet>
                <title key={'title'}>
                    DAO Details | Chain Of Legends
                </title>
            </Helmet>
            <motion.div
                exit={{ opacity: 0, y: 200 }}
                transition={{ duration: 0.4 }} className='row' initial={isMobile ? undefined : { opacity: 0, y: 200 }} animate={{ opacity: 1, y: 0 }} >
                <MidBox modal title="Poll Details  ">
                    {loading ? <div className='px-2 px-lg-4 '>
                        <p className='text-center fs-big mb-3'>
                            <Skeleton
                                width={"50%"}
                            />
                        </p>
                        <div className='row align-items-center'>
                            <div className='col-12 col-md-9'>
                                <div className='row'>
                                    {[1, 2, 3, 4].map((item: any) => (
                                        <div className='col-12 '>
                                            <Skeleton
                                                width={item * 25 + "%"}

                                            />
                                        </div>))}

                                </div>
                            </div>
                            <div className='col-12 col-md-3 text-center'>
                                <Skeleton
                                    width={100}
                                    height={50}
                                />
                                <Skeleton
                                    width={150}
                                />
                            </div>

                        </div>
                        <div style={{ width: '100%', borderBottom: '1px solid rgba(255,255,255,0.1)' }} className="pt-3" />
                        <br />
                        <Skeleton
                            width={"100%"}

                        />
                        <Skeleton
                            width={"100%"}
                        />
                        <Skeleton
                            width={"60%"}
                        />
                        <Skeleton
                            width={"70%"}
                        />
                        <Skeleton
                            width={"90%"}
                        />
                    </div> : <div className='px-2 px-lg-4 '>

                        <p className='text-center fs-big mb-2'>
                            {data?.poll?.title}
                            {myVote && <p className='mt-1 fs-med text-success'>
                                Total Vote (Including Points): {data?.poll?.totalVotes}
                            </p>}
                        </p>
                        <div className='py-2 d-flex w-100 justify-content-between mb-2 flex-wrap align-items-center' >
                            <div className='text-secondary fs-small'>
                                <>
                                    Start Date : {moment(new Date(data?.poll?.startDate)).format('HH:mm - MM/DD/YYYY')}
                                </>
                            </div>
                            <div style={{
                                padding: '8px 13px',
                                width: 'fit-content',
                                borderRadius: 50,
                                backgroundColor: new Date(data?.poll?.startDate).getTime() > Date.now() ? 'var(--theme-color-blue)' :
                                    new Date(data?.poll?.endDate).getTime() < Date.now() ? 'var(--theme-color-red)' : 'var(--theme-color-green)'
                            }} className='fs-med'>
                                {
                                    new Date(data?.poll?.startDate).getTime() > Date.now() ? "Upcoming"
                                        : new Date(data?.poll?.endDate).getTime() < Date.now() ? "Ended" : "Active"
                                }
                            </div>

                            <div className='text-secondary fs-small'>
                                <>

                                    End Date : {moment(new Date(data?.poll?.endDate)).format('HH:mm - MM/DD/YYYY')}
                                </>
                            </div>
                        </div>
                        {!myVote ? new Date(data?.poll?.endDate).getTime() < Date.now() ?
                            <div className='row '>
                                {data?.options?.map((item: any) => (
                                    <div className='col-12 col-lg-6 py-2 fs-big mb-3'>
                                        <Form.Check
                                            inline
                                            readOnly
                                            checked={false}
                                            value={item._id}
                                            label={item.title + ` (${roundFloat(item.votes / data?.poll?.totalVotes * 100, 3)}%)`}
                                            name="option"
                                            type={"radio"}
                                            id={`inline-${item._id}-1`}
                                        />
                                        <ProgressBar
                                            className='mt-2'
                                            style={{ height: 15, backgroundColor: '#2d271b' }}
                                            striped
                                            variant={"success"}
                                            now={parseFloat(roundFloat(item.votes * 100 / data?.poll?.totalVotes, 2))}
                                            label={`${roundFloat(item.votes * 100 / data?.poll?.totalVotes, 2)}%`} />
                                    </div>
                                ))}

                            </div> : <div className='row align-items-center'>
                                <div className='col-12 col-md-9'>
                                    <div className='row'>
                                        {new Date(data?.poll?.startDate).getTime() > Date.now() && <div className='col-12 fs-big text-danger'>
                                            Poll has not started yet
                                        </div>}
                                        {data?.options?.map((item: any) => (
                                            <div className='col-12 fs-big'>
                                                <Form.Check
                                                    inline
                                                    disabled={new Date(data?.poll?.startDate).getTime() > Date.now()}
                                                    className=''
                                                    value={item._id}
                                                    label={item.title}
                                                    onChange={(e) => setSelectedOption(e.target.value)}
                                                    name="option"
                                                    type={"radio"}
                                                    id={`inline-${item._id}-1`}
                                                />
                                            </div>
                                        ))}

                                    </div>
                                </div>

                                <div className='col-12 col-md-3 text-center d-flex flex-column align-items-center'>
                                    {new Date(data?.poll?.startDate).getTime() > Date.now() &&
                                        <>
                                            <div className='text-secondary mt-3 mt-md-0  mb-2' >
                                                Poll starts in :
                                            </div>
                                            <Countdown
                                                date={new Date(data?.poll?.startDate)}
                                                onComplete={() => setTimeout(() => {
                                                    getInitialData()
                                                }, 1000)}
                                                renderer={({ formatted }) => (
                                                    <div className='mb-2 d-flex'>
                                                        <div className='time-badge'>
                                                            {formatted.days}
                                                            <span className='dark-yellow'>
                                                                Days
                                                            </span>
                                                        </div>
                                                        <div className='ms-2 time-badge'>
                                                            {formatted.hours}
                                                            <span className='dark-yellow'>
                                                                Hours
                                                            </span>
                                                        </div>
                                                        <div className='ms-2 time-badge'>
                                                            {formatted.minutes}
                                                            <span className='dark-yellow'>
                                                                Minutes
                                                            </span>
                                                        </div>
                                                        <div className='ms-2 time-badge'>
                                                            {formatted.seconds}
                                                            <span className='dark-yellow'>
                                                                Seconds
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </>
                                    }
                                    <Button
                                        disabled={new Date(data?.poll?.startDate).getTime() > Date.now() || new Date(data?.poll?.endDate).getTime() < Date.now()}
                                        onClick={() => vote(selectedOption)} loading={btnLoading} className='mx-auto' variant='primary'>
                                        Submit
                                    </Button>
                                    <span>
                                        My Vote Points is : {getVoteWeight()?.toString()}
                                    </span>
                                </div>
                            </div> : <div className='row '>
                            {data?.options?.map((item: any) => (
                                <div className='col-12 col-lg-6 py-2 fs-big d-flex justify-content-between flex-column'>
                                    <Form.Check
                                        inline
                                        readOnly
                                        checked={selectedOption === item?._id}
                                        value={item._id}
                                        label={item.title + ` (${roundFloat(item.votes / data?.poll?.totalVotes * 100, 3)}%)`}
                                        name="option"
                                        type={"radio"}
                                        id={`inline-${item._id}-1`}
                                    />

                                    <ProgressBar
                                        className='mt-2'
                                        style={{ height: 15, backgroundColor: '#2d271b' }}
                                        striped variant={selectedOption === item?._id ? "success" : "success"}
                                        now={parseFloat(roundFloat(item.votes * 100 / data?.poll?.totalVotes, 2))} label={`${roundFloat(item.votes * 100 / data?.poll?.totalVotes, 2)}%`} />
                                </div>
                            ))}

                        </div>}

                        <div style={{ width: '100%', borderBottom: '1px solid rgba(255,255,255,0.1)' }} className="pt-3" />
                        <div className='pt-3 pb-4'
                            dangerouslySetInnerHTML={{ __html: data?.poll?.description || '' }}
                        />
                    </div>}
                </MidBox>

            </motion.div>
        </SkeletonTheme>

    )
}

export default DaoDetails