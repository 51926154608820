import classNames from "classnames";
import React, { FC, HTMLAttributes } from "react";

type Props = {
  title?: string;
  imageSrc: string;
  className?: string;
  description?: string;
  imageProps?: HTMLAttributes<HTMLImageElement>;
  descriptionProps?: HTMLAttributes<HTMLParagraphElement>;
  action?: React.ReactNode;
};

const EmptyState: FC<Props> = ({
  className,
  title,
  description,
  imageSrc,
  imageProps,
  descriptionProps,
  action,
}) => {
  return (
    <div
      className={classNames(
        className,
        "d-flex flex-column gap-2 align-items-center jusityf-content-center"
      )}
    >
      <img {...imageProps} alt={title} src={imageSrc} />
      <h6 className="bold">{title}</h6>
      <p className={"text-secondary"} {...descriptionProps}>
        {description}
      </p>
      {action}
    </div>
  );
};

export default EmptyState;
