import {
  MarketPlaceLandTabsType,
  MarketPlaceMainTabsType,
} from "../types/pages/MarketPlace";
import { IsBeta } from "../utils/Network";
import { Assets } from "./assets";

export const LAND_TABS: MarketPlaceLandTabsType[] = [
  {
    title: "Desert",
    imageSrc:
      "https://core.chainoflegends.com/Admin/files/Photos/Lands/1/land-desert.png",
    id: 1,
    className: "third-step",
  },
  {
    title: "Common",
    imageSrc:
      "https://core.chainoflegends.com/Admin/files/Photos/Lands/2/land-common.png",
    id: 2,
    className: "mrkt-stp2",
  },
  {
    title: "Uncommon",
    imageSrc:
      "https://core.chainoflegends.com/Admin/files/Photos/Lands/3/land-uncommon.png",
    id: 3,
  },
  {
    title: "Rare",
    imageSrc:
      "https://core.chainoflegends.com/Admin/files/Photos/Lands/4/land-rare.png",
    id: 4,
  },
  {
    title: "Legendary",
    imageSrc:
      "https://core.chainoflegends.com/Admin/files/Photos/Lands/5/land-legendary.png",
    id: 5,
  },
];
export const MAIN_TABS: MarketPlaceMainTabsType[] = [
  // TODO: Add Ember
  {
    title:"Ember Shop",
    imageSrc:Assets.icons.ember,
    type:7,
    disabled:false
  },
  {
    title:"Arena Shop",
    imageSrc:Assets.icons.market,
    type:6,
    disabled:false
  },
  {
    title: "Lands",
    type: 0,
    imageSrc:
      "https://core.chainoflegends.com/Admin/files/Photos/Lands/5/land-legendary.PNG",
  },
  {
    title: "Stone",
    type: 1,
    imageSrc: "/assets/images/icons/stone.png",
    className: "mrkt-stp6-2",
  },
  {
    title: "Iron",
    type: 2,
    imageSrc: "/assets/images/icons/metal.png",
  },
  {
    title: "Troops",
    type: 3,
    className: "mrkt-stp7",
    imageSrc: "https://core.chainoflegends.com/assets/troops/icon/warrior.png",
  },
  {
    title: "Items",
    type: 5,
    imageSrc: "/assets/images/icons/helmet-item.png",
  },
];
