import React, { useEffect, useState } from 'react'
import Network from '../utils/Network';
import { ArenaQuestResponse, ArenaRewardDetails } from '../types/pages/MarketPlace';

type Props = {
  isEmber?: boolean
}

const useArenaShop = (props?: Props) => {
  const { isEmber } = props || {}
  const [data, setData] = useState<ArenaQuestResponse>();
  const [loading, setLoading] = useState(false);
  const axios = new Network()
  const handleGetShopData = async () => {
    setLoading(true);
    const res = await axios.CallApi<ArenaQuestResponse>({
      url: isEmber?"/quest/embershops": "/quest/arenashops",
      method: "get",
    });
    if (res.ok)
      setData({
        ...res.data,
        rewards: res.data.rewards.map((d) => ({
          ...d,
          rewards: JSON.parse(d.rewards as any) as ArenaRewardDetails[],
        })),
      });
    setLoading(false);
  };

  return (
    {
      getShopData: handleGetShopData,
      data,
      loading,
    }
  )
}

export default useArenaShop