import { useEffect, useState } from 'react';
import eventBus, { EventMap } from '../utils/event-bus';

/**
 * Custom hook to listen to the EventBus for a specific event.
 * @param event The event name to listen for from the EventBus.
 * @param initialState Initial state value if needed for the listener.
 * @param cb callback function to be called when the event is emitted
 * @returns The current state updated by the event.
 */

export function useEventBus<K extends keyof EventMap>(event: K, initialState: EventMap[K] | null = null,cb?:(data:EventMap[K])=>void) {
  const [data, setData] = useState<EventMap[K] | null>(initialState);

  useEffect(() => {
    const handler = (payload: EventMap[K]) => {
      console.log('payload', payload);
      setData(payload);  // Update state when the event is emitted
      cb?.(payload) 
    };

    // Subscribe to the event
    eventBus.on(event, handler);

    // Cleanup listener when component unmounts
    return () => {
      eventBus.off(event, handler);
    };
  }, [event]);

  return data;  // Return the current data for the component to use
}