import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MidBox from '../components/Boxes/MidBox'
import { roundFloat } from '../utils/round-float'
import Network from '../utils/Network'
import { motion } from 'framer-motion'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import TipList from '../components/TipList'
import Button from '../components/Button'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'


type Props = {}


const DAO = (props: Props) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const axios = new Network()
    const navigate = useNavigate()
    const getPolls = async () => {
        setLoading(true)
        const response = await axios.CallApi({
            url: '/poll/search',
            secApi: true,
            method: 'get'
        })
        setData(response.results)
        setLoading(false)
    }

    useEffect(() => {
        getPolls()
    }, [])
    return (
        <SkeletonTheme baseColor='#2d271b' highlightColor='#3e382b'>
            <Helmet>
                <title key={'title'}>
                    DAO | Chain Of Legends
                </title>
            </Helmet>
            <motion.div
                exit={{ opacity: 0, y: 200 }}
                transition={{ duration: 0.4 }} className='row' initial={isMobile ? undefined : { opacity: 0, y: 200 }} animate={{ opacity: 1, y: 0 }} >
                <MidBox modal title="Polls">

                    <div style={{ overflow: 'auto' }} className="pe-1 pt-3 pb-5 ps-1 pe-sm-3 ps-sm-3 pb-4 table-box">
                        <table className="table table-borderless m-0 text-white" > <thead>
                            <tr>
                                <th scope="col">Poll title</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>

                                <th scope="col">Status</th>
                                <th >

                                </th>
                            </tr>
                        </thead>
                            <tbody>
                                {loading && [1, 2, 3].map((item) =>
                                    <tr key={"loading" + item} className='pointer'
                                    >
                                        <td width={"50%"}>
                                            <Skeleton width={'100%'} />
                                        </td>
                                        <td> <Skeleton width={'60px'} /> </td>
                                        <td> <Skeleton width={'60px'} /></td>
                                        <td >
                                            <Skeleton width={'100px'}
                                                height={25}
                                            />
                                        </td>

                                    </tr>
                                )}
                                {!loading && data?.map((item, key) => (
                                    <>

                                        <tr className='pointer'
                                            onClick={() => navigate(`/dao/${item._id}`)}
                                            key={"row" + key}>
                                            <td style={{ minWidth: 200 }}><span
                                                className='fs-big'
                                            >
                                                {item?.title}</span></td>
                                            <td>{moment(new Date(item?.startDate)).format('MM/DD/YYYY')} </td>
                                            <td>{moment(new Date(item?.endDate)).format('MM/DD/YYYY')}</td>
                                            <td width={100} >
                                                <div style={{
                                                    padding: '8px 13px',
                                                    width: 'fit-content',
                                                    borderRadius: 50,
                                                    backgroundColor: new Date(item?.startDate).getTime() > Date.now() ? 'var(--theme-color-blue)' :
                                                        new Date(item?.endDate).getTime() < Date.now() ? 'var(--theme-color-red)' : 'var(--theme-color-green)'
                                                }} className=''>
                                                    {
                                                        new Date(item?.startDate).getTime() > Date.now() ? "Upcoming"
                                                            : new Date(item?.endDate).getTime() < Date.now() ? "Ended" : "Active"
                                                    }
                                                </div>
                                            </td>
                                            <td >
                                                <Button variant='default'>
                                                    Open
                                                </Button>
                                            </td>
                                        </tr>
                                        {isMobile && <tr>
                                            <td>
                                                <Button
                                                    onClick={() => navigate(`/dao/${item._id}`)}
                                                    variant='default'>
                                                    Open
                                                </Button>
                                            </td>
                                        </tr>
                                        }
                                    </>
                                ))}

                            </tbody></table>
                    </div>
                </MidBox>
            </motion.div>
            <motion.div exit={{ opacity: 0, y: 200 }}
                transition={{ duration: 0.4 }} className='row' initial={isMobile ? undefined : { opacity: 0, y: 200 }} animate={{ opacity: 1, y: 0 }}>
                <p>
                    Chain Of Legends, like many other projects and games, needs Development (adding new features) and System Optimization that was not foreseen at the beginning of the project.<br />
                    Since this game is a blockchain game, we are introducing the DAO system to make such modifications based on the votes and opinions of our community.

                </p>

                <p> The votes of players have value in the #DAO system based on the following terms:
                </p>
                <br />


                <TipList
                    item={[
                        "All Buildings Level 0️⃣'s vote has 0 point",
                        "All Buildings Level 1️⃣'s vote has 1 point",
                        "All Buildings Level 2️⃣'s vote has 2 points",
                        "All Buildings Level 3️⃣'s vote has 4 points",
                        "All Buildings Level 4️⃣'s vote has 8 points",
                        "All Buildings Level 5️⃣'s vote has 16 points",
                        "All Buildings Level 6️⃣'s vote has 32 points",
                        "All Buildings Level 7️⃣'s vote has 64 points",
                        "All Buildings Level 8️⃣'s vote has 128 points"
                    ]}
                    icon="/assets/images/icons/icon-dart.png"
                />

            </motion.div>
        </SkeletonTheme>
    )
}

export default DAO