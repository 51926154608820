
export const actionTypes = {
    SET_RATE: 'SET_RATE',
    CLEAR_RATES: 'CLEAR_RATES',

};

export const setRate = (payload:any) => ({
    type: actionTypes.SET_RATE,
    payload,
});

export const clearRates = () => ({
    type: actionTypes.CLEAR_RATES,
});

