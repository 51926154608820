// context/BattleContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";
import { BattleContextProps, BattleState, CoolDownsType, GameInfo, MessageType } from "../types";
import { ConnectionStatus, ViewState } from "../types/enum";
import { useSelector } from "react-redux";

const initialState: BattleState = {
  playerId: 0,
  authToken: "",
  units: [],
  attackers: [],
  defenders: [],
  troops: [],
  battleId: "",
  addCooldown: 1,
  selectedArmy: [],
  selectedTroop: undefined,
  ping: 0,
  viewState: ViewState.Home,
  username: localStorage.getItem("username") || "guest",
  photoFile: "",
  playerIsAttacker: false,
  toggleRecentBattles: false,
  connectionStatus: "Disconnected",
  emojis: [
    "🤬",
    "⚔️",
    "😜",
    "🤪",
    "😁",
    "😂",
    "😅",
    "😭",
    "😢",
    "👏",
    "👊",
    "💪",
    "🏳️",
  ],
};

const BattleContext = createContext<BattleContextProps | undefined>(undefined);

export const BattleProvider = ({ children }: { children: ReactNode }) => {
  const token = useSelector((state: any) => state.userToken);
  const [viewState, setViewState] = useState<ViewState>(ViewState.FightMenu);
  const [gameInfo, setGameInfo] = useState<GameInfo | undefined>();
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [coolDowns, setCoolDowns] = useState<CoolDownsType>({troopCoolDownDate: new Date(), troops: {}});
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.Connecting);
  const [ping, setPing] = useState<number>(0);
  const [isFindingMatch, setIsFindingMatch] = useState<boolean>(false);
  const [suggestedMatch, setSuggestedMatch] = useState<any>();
  const [battleState, setBattleState] = useState<BattleState>({
    ...initialState,
    authToken: token,
  });
  return (
    <BattleContext.Provider
      value={{
        viewState,
        setViewState,
        battleState,
        setBattleState,
        suggestedMatch,
        setSuggestedMatch,
        coolDowns,
        setCoolDowns,
        gameInfo,
        messages,
        setMessages,
        setGameInfo,
        ping,
        setPing,
        connectionStatus,
        setConnectionStatus,
        isFindingMatch,
        setIsFindingMatch,
      }}
    >
      {children}
    </BattleContext.Provider>
  );
};

export const useBattleContext = () => {
  const context = useContext(BattleContext);
  if (!context) {
    throw new Error("useBattleContext must be used within a BattleProvider");
  }
  return context;
};
