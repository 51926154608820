import React from 'react'
import { motion } from 'framer-motion'
import LottiePlayer from 'lottie-react';
import notFound from '../animations/404.json';
import { Link } from 'react-router-dom';
import Button from './Button';
type Props = {
    message?: string | React.ReactNode;
    onBack?: () => void;
    image?: string;
    action: {
        title: string;
        link: string;
    }
}

const NotFoundError = (props: Props) => {

    return (
        <motion.div>

            <div className='p-absolute not-found' >
                <LottiePlayer

                    animationData={notFound}
                    loop
                    style={{ width: 300 }}
                    autoPlay
                />
                {props.message || <>
                    Sorry, there is no data with this id. please make sure
                </>}
                <Link to={props.action.link}>
                    <Button className='fs-med' variant='primary'>
                        {props.action.title}
                    </Button>
                
                </Link>

            </div>

        </motion.div>
    )
}

export default NotFoundError