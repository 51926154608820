import { Channel, ChatMessage, ChatSections, ChatUserMetaData } from "../../types/chat";
import { actionTypes, addMessage, joinChannel, setActiveChat, setMetaData, } from "./actions";
import { Action } from 'redux'
export const initialMessages: Record<string,ChatMessage> = {};
export const initialReadMessages = {};
export const initialChannels: Channel[] = [];
export const initialMetaData: Record<string, ChatUserMetaData> = {};
export const initialActiveChat: { section: ChatSections, targetId?: string } = { section: 'channels' };


export const messageReducer = (state = initialMessages, action: ReturnType<typeof addMessage>) => {
    switch (action.type) {
        case actionTypes.ADD_MESSAGE:
            return { ...state, [action.payload._id]: action.payload };
        case actionTypes.REMOVE_MESSAGE:
            let { [action.payload._id]: _, ...newState } = state;
            return newState;
        case actionTypes.EDIT_MESSAGE: 
            return {...state, [action.payload._id]: {...state[action.payload._id], ...action.payload}}
        case actionTypes.IGNORE_USER:
            return Object.values(state).filter((val) => val.senderId !== action.payload.senderId);
        case actionTypes.CLEAR_MESSAGES:
            return initialMessages;
        default:
            return state;
    }
};


export const readMessagesReducer = (state = initialReadMessages, action: ReturnType<typeof addMessage>) => {
    switch (action.type) {
        case actionTypes.READ_MESSAGE: {
            let newState = { ...state }
            newState[action.payload._id] = true
            return newState
        };
        default:
            return state;
    }
};

export const chatMetaDataReducer = (state = initialMetaData, action: ReturnType<typeof setMetaData>) => {
    switch (action.type) {
        case actionTypes.SET_META_DATA:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
};



export const chatSideBarReducer = (state = false, action) => {
    switch (action.type) {
        case actionTypes.OPEN_CHAT:
            return true;
        case actionTypes.CLOSE_CHAT:
            return false;
        default:
            return state;
    }
};
export const chatPageReducer = (state = 'channels', action) => {
    switch (action.type) {
        case actionTypes.OPEN_CHAT:
            return true;
        case actionTypes.CLOSE_CHAT:
            return false;
        default:
            return state;
    }
};



export const channelReducer = (state = initialChannels, action: ReturnType<typeof joinChannel>) => {
    switch (action.type) {
        case actionTypes.JOIN_CHANNEL:
            return [...state, action.payload];
        case actionTypes.SET_ACTIVE_CHANNEL:
            return [...state.filter(items => items?.chatId !== action.payload.chatId).map((item) => ({
                ...item,
                isActive: false
            })), { ...action.payload, isActive: true }]
        case actionTypes.LEAVE_CHANNEL: {
            return state.filter((val) => val.chatId !== action.payload.chatId)
        }
        case actionTypes.CLEAR_CHANNELS:
            return initialChannels
        default:
            return state;
    }
};


export const chatActiveData = (state = initialActiveChat, action: ReturnType<typeof setActiveChat>) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_CHAT:
            return action.payload;
        default:
            return state;
    }
};
