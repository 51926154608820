import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import MidBox from "../components/Boxes/MidBox";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  addMessage,
  closeChat,
  joinChannel,
  leaveChannel,
  openChat,
  setActiveChat,
  setMetaData,
} from "../redux/chat/actions";
import {
  Channel,
  ChannelsType,
  ChatListType,
  ChatMessage,
  ChatSections,
  ChatUserMetaData,
} from "../types/chat";
import PerfectScrollbar, { ScrollBarProps } from "react-perfect-scrollbar";
import Network, { IsBeta, ProfileURL } from "../utils/Network";
import scrollIntoMessage from "../utils/scroll-to-message";
import { CustomIcon } from "../components/Icons";
import { motion } from "framer-motion";
import Channels from "../containers/chat/Channels";
import useWindowSize from "../hooks/useWindowSize";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dialog from "../components/Dialog";
import { ConfirmDialogType } from "../types/global";
import EmptyState from "../components/EmptyState";
import useSocket from "../hooks/useSocket";
import Chat from "../containers/chat/Chat";
import ChatItem from "../containers/chat/ChatItem";
import { getProfileURL } from "../utils/profile-url";
import { initialActiveChat } from "../redux/chat/reducers";
import { generateChatId } from "../utils/generate-chat-id";
import displayUserName from "../utils/display-username";
import { ChatEmitsEnum } from "../containers/chat/enums";
type Props = {};

const ChatWrapper = ({}: Props) => {
  const socket = useSocket();
  const userInfo = useSelector((state: any) => state?.userInfo);
  const channels = useSelector((state: any) => state?.channels);
  const messages: ChatMessage[] = useSelector((state: any) => state?.messages);
  const usersMetaData = useSelector((state: any) => state?.usersMetaData);
  const activeChat: typeof initialActiveChat = useSelector(
    (state: any) => state?.activeChat
  );
  const [section, setSection] = useState<ChatSections>("channels");
  const [activeChatData, setActiveChatData] = useState<ChatListType>();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const isHistoryThere = React.useRef(false);
  const [onlineUsers, setOnlineUsers] = React.useState<Record<number, boolean>>(
    {}
  );
  const [loading, setLoading] = React.useState(false);
  const isOpen: boolean = useSelector((state: any) => state.chatState);
  const chatSec: ChatSections = useSelector((state: any) => state.chatPage);
  const metaRef = useRef({});
  const [allChannels, setAllChannels] = useState<ChannelsType>();
  const [chatList, setChatList] = useState<ChatListType[]>();
  const [confimDialog, setConfirmDialog] = useState<ConfirmDialogType>({
    open: false,
    submit: () => console.log(""),
    title: "",
    msg: "",
  });

  const activeChannel: Channel = React.useMemo(
    () => channels.find((channel) => channel?.isActive),
    [channels]
  );
  const usersMetaDataRef = useRef<Record<number, ChatUserMetaData>>();

  useEffect(() => {
    usersMetaDataRef.current = usersMetaData;
  }, [usersMetaData]);

  const toggleDrawer = () => {
    isOpen ? dispatch(closeChat()) : dispatch(openChat());
  };

  const axios = new Network();

  useEffect(() => {
    if (allChannels?.channels && socket) {
      socket?.emit(
        "joinGroups",
        allChannels.channels.map((c: any) => c.chatId)
      );
    }
  }, [allChannels, socket]);

  useEffect(() => {
    if (chatList && socket) {
      const emitFunc = () => {
        socket?.emit(
          ChatEmitsEnum.CHECK_ONLINE_USERS,
          chatList.map((c) => c.user.id.toString())
        );
      };

      emitFunc();

      const intervalId = setInterval(emitFunc, 15000); // 15000 milliseconds equals 15 seconds

      // Cleanup function to clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [chatList]);

  useEffect(() => {
    if (socket) {
      socket?.on(
        ChatEmitsEnum.ONLINE_USERS,
        (data: Record<number, boolean>) => {
          setOnlineUsers(data);
          console.log(data);
        }
      );
      // socket?.on(ChatEmitsEnum.CONVERSATION_CHANGED, (data: ChatListType) => {
      //   setChatList((prev) =>
      //     [
      //       ...(prev?.filter((pre) => pre?._id !== data._id) || []),
      //       data ,
      //     ].sort(
      //       (a,b) =>
      //         new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      //     )
      //   );
      //   console.log(data);
      // });
    }
  }, [socket]);

  const getAllChannels = async () => {
    setLoading(true);
    const response = await axios.CallApi({
      url: "/player/chat/channels",
      method: "get",
    });
    setAllChannels(response.data);
    setLoading(false);
  };

  const getChats = async () => {
    setLoading(true);
    const response = await axios.CallApi({
      url: "/conversation/all",
      secApi: true,
      method: "get",
    });
    if(response.ok)  setChatList(response?.results);
   
    setLoading(false);
  };

  useEffect(() => {
    if (allChannels) {
      if (channels.length === 0) {
        dispatch(joinChannel(allChannels[0]));
      }
      if (channels.length === 1) {
        dispatch(joinChannel(allChannels[0]));
      }
    }
  }, [allChannels]);

  const getUserMetaData = async (id: number) => {
    if (usersMetaDataRef.current?.[id]) return usersMetaDataRef.current?.[id];
    const data = await axios.CallApi({
      url: "/player/summury?playerid=" + id,
      method: "get",
    });
    console.log(data);
    if (!data.ok) return;
    dispatch(setMetaData(data.data as ChatUserMetaData));
    return data.data;
  };

  const getGroupHistory = async (channel?: Channel) => {
    if (!channel && messages.length !== 0) return;
    const response = await axios.CallApi({
      secApi: true,
      url: `/message/chat?groupId=${channel?.chatId}&page=1&limit=50`,
      method: "get",
    });
    response.data?.map(async (msg: ChatMessage, i) => {
      console.log(msg);
      dispatch(
        addMessage({
          ...msg,
        })
      );
      if (i + 1 === response.data?.length) {
        setTimeout(() => {
          scrollIntoMessage(msg._id);
        }, 500);
      }
    });
    for (let msg of response.data) {
      await getUserMetaData(msg.senderId);
    }
  };

  const getHistory = async (userId: number, chatId?: string) => {
    const response = await axios.CallApi({
      secApi: true,
      url: `/message/chat?chatId=${chatId}&userId=${userId}&page=1&limit=50`,
      method: "get",
    });
    response.data?.map(async (msg: ChatMessage, i) => {
      dispatch(
        addMessage({
          ...msg,
        })
      );
      if (i === 0) {
        setTimeout(() => {
          scrollIntoMessage(msg._id);
        }, 600);
      }
    });
    await getUserMetaData(userId!);
  };

  React.useEffect(() => {
    if (isHistoryThere.current) return;
    isHistoryThere.current = true;
    getGroupHistory();
    getAllChannels();
    // getChats();
  }, []);

  useEffect(() => {
    if (section === "channels") {
      getChats();
    }
  }, [section]);

  React.useEffect(() => {
    openDynamicChat();
  }, [isOpen, activeChat]);
  const openDynamicChat = async () => {
    if (activeChat.targetId) {
      setSection(activeChat.section);
      dispatch(setActiveChat({ section: "chat", targetId: undefined }));
      console.log(activeChat);
      const user: ChatUserMetaData = await getUserMetaData(
        +activeChat.targetId
      );
      console.log(user);
      if (user) {
        const chatId = generateChatId(userInfo.id, user.id);
        setActiveChatData({
          chatId,
          user: user,
          _id: "",
          unread: 0,
        });
        getHistory(+activeChat.targetId, chatId);
      }
    }
  };

  const handleOpenChat = (chat: ChatListType) => {
    // if (
    //   Object.values(messages).filter(
    //     (msg: ChatMessage) => msg.chatId === chat?.lastMessage?.chatId
    //   )?.length === 0
    // ) {
    getHistory(chat?.user.id!, chat.chatId);
    // }
    setSection("chat");
    setActiveChatData(chat);
  };

  return (
    <SkeletonTheme baseColor="#2d271b" highlightColor="#3e382b">
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        zIndex={1800}
        size={
          isMobile
            ? windowSize.width < 420
              ? windowSize.width - 10
              : 400
            : 400
        }
        className="bg-transparent "
      >
        <MidBox
          style={{ minHeight: "90%" }}
          className="transition overflow-hidden"
          title="Chat"
          containerClass="px-lg-4 "
        >
          <button
            onClick={toggleDrawer}
            className="button hard btn-close"
          ></button>
          {/* {!IsBeta ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "77vh" }}
            >
              <EmptyState
                imageProps={{ style: { maxWidth: 270 } }}
                title="Chat System is under maintenance"
                imageSrc="/assets/images/under-maintenance.png"
              />
            </div>
          ) : ( */}
          <>
            {section === "channels" &&
              (loading ? (
                [1, 2, 3, 4, 5, 6, 7].map((i) => (
                  <div
                    className={
                      "rounded bg-2 d-flex align-items-center px-2 py-2 " +
                      (i !== 7 && "mb-2")
                    }
                  >
                    <Skeleton height={50} circle width={50} className="me-3" />{" "}
                    <div className="w-100">
                      <Skeleton height={15} width={"100%"} className="mt-1" />
                    </div>
                  </div>
                ))
              ) : (
                <PerfectScrollbar
                  style={{ minHeight: "70vh", maxHeight: "80vh" }}
                >
                  <ChatItem
                    data={chatList!}
                    onlineUsers={onlineUsers}
                    onSelect={(chat) => {
                      handleOpenChat(chat);
                    }}
                  />
                  <Channels
                    onJoin={(channel) => {
                      if (
                        Object.values(messages).filter(
                          (msg: ChatMessage) => msg.groupId === channel?.chatId
                        )?.length === 0
                      ) {
                        getGroupHistory(channel);
                      }
                    }}
                    onSelect={() => {
                      setSection("group");
                    }}
                    data={allChannels}
                  />
                </PerfectScrollbar>
              ))}

            {section === "group" && (
              <div className="px-0 px-lg-1">
                <div className="d-flex gap-2">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2222 }} placement="bottom">
                        Back
                      </Tooltip>
                    }
                  >
                    <div
                      onClick={() => {
                        setSection("channels");
                      }}
                      className="rounded d-flex pointer align-items-center px-2 dark-bg"
                    >
                      <CustomIcon
                        style={{ transform: "rotate(90deg)" }}
                        icon="arrow"
                        height={20}
                        width={30}
                      />
                    </div>
                  </OverlayTrigger>

                  <div
                    onClick={() => setSection("channels")}
                    className=" dark-bg mx-auto gap-2 rounded-10 px-4 py-2  d-flex pointer align-items-center"
                  >
                    <img src={activeChannel?.iconUrl} width={30} height={30} />
                    <div className="fs-big dark-yellow bold">
                      {activeChannel?.name}
                    </div>
                  </div>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2222 }} placement="bottom">
                        Leave Chat
                      </Tooltip>
                    }
                  >
                    <div
                      onClick={() => {
                        setConfirmDialog({
                          title: "Are you sure ?",
                          msg: "Are you sure about leaving this channel?",
                          open: true,
                          submit: () => {
                            dispatch(leaveChannel(activeChannel));
                            setSection("channels");
                            setConfirmDialog((prev) => ({
                              ...prev,
                              open: false,
                            }));
                          },
                        });
                      }}
                      className="rounded d-flex pointer align-items-center ps-2 px-1 dark-bg"
                    >
                      <CustomIcon
                        icon="exit-outline"
                        type="svg"
                        height={35}
                        width={30}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: 30 }}
                  className="text-center mt-1 fs-small text-secondary d-flex align-items-center justify-content-center"
                >
                  <div className="green-dot me-1 " />{" "}
                  {onlineUsers[activeChannel?.chatId]?.length} Online
                </motion.div>
                <div className="mt-2 d-flex flex-column">
                  <Chat socket={socket} />
                </div>
              </div>
            )}

            {section === "chat" && (
              <div className="px-0 px-lg-1">
                <div className="d-flex gap-2">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2222 }} placement="bottom">
                        Back
                      </Tooltip>
                    }
                  >
                    <div
                      onClick={() => setSection("channels")}
                      className="rounded d-flex pointer align-items-center px-2 dark-bg"
                    >
                      <CustomIcon
                        style={{ transform: "rotate(90deg)" }}
                        icon="arrow"
                        height={20}
                        width={30}
                      />
                    </div>
                  </OverlayTrigger>

                  <div
                    onClick={() => setSection("channels")}
                    className=" dark-bg mx-auto  gap-1 rounded-10 px-2 py-2  d-flex pointer align-items-center"
                  >
                    <img
                      className="circle me-1"
                      src={
                        activeChatData?.user.photoFile
                          ? getProfileURL(
                              activeChatData?.user.id!,
                              activeChatData?.user.photoFile
                            )
                          : "/assets/images/avatar-bg.png"
                      }
                      width={40}
                      height={40}
                    />
                    <div className="fs-lg dark-yellow bold">
                      {displayUserName(activeChatData?.user?.username!)}
                    </div>
                  </div>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2222 }} placement="bottom">
                        Profile
                      </Tooltip>
                    }
                  >
                    <Link
                      onClick={() => dispatch(closeChat())}
                      to={"/profile/" + activeChatData?.user.id}
                      className="rounded d-flex pointer align-items-center px-2 dark-bg"
                    >
                      <CustomIcon icon="info" height={30} width={30} />
                    </Link>
                  </OverlayTrigger>
                </div>
                {onlineUsers[activeChatData?.user?.id!] && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 30 }}
                    className="text-center mt-1 fs-small text-secondary d-flex align-items-center justify-content-center"
                  >
                    <div className="green-dot me-1 " /> Online
                  </motion.div>
                )}
                <div className="mt-2 d-flex flex-column">
                  <Chat
                    isPersonal
                    receiverId={activeChatData?.user.id}
                    chatId={activeChatData?.chatId}
                    socket={socket}
                  />
                </div>
              </div>
            )}
          </>
          {/* )} */}
        </MidBox>
      </Drawer>

      <Dialog
        title={confimDialog?.title}
        closeLabel="No"
        style={{ zIndex: 2222 }}
        submitLabel="Yes"
        onSubmit={confimDialog?.submit}
        open={confimDialog?.open}
        onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
      >
        {confimDialog?.msg}
      </Dialog>
    </SkeletonTheme>
  );
};

export default ChatWrapper;
