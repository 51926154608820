
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MidBox from '../components/Boxes/MidBox'
import { roundFloat } from '../utils/round-float'
import Network from '../utils/Network'
import { motion } from 'framer-motion'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Button from '../components/Button'
import { isMobile } from 'react-device-detect'
import Dialog from '../components/Dialog'
import useToast from '../hooks/useToast'
import { Alert, Form, OverlayTrigger, Pagination, Tooltip } from 'react-bootstrap'
import ThousandsSeparator from '../utils/thousands-separator'
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import truncateString from '../utils/truncate-string'
import { stat } from 'fs'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'

type Props = {}
export const WithdrawStates = {
    1: "Pending",
    2: "Transfered",
    3: "Transfered",
    4: "Rejected",
}


const ReferralLeaderBoard = (props: Props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [paginations, setPaginations] = useState({ page: 1, count: 100, total: 1 })
    const [markLoading, setMarkLoading] = useState(false)
    const [offerDialog, setOfferDialog] = useState(false)
    const [stats, setStats] = useState({
        all: 0,
        poolCharge: 0,
        poolPrize: 0,
        dragons: 0,
        mages: 0
    })

    const userInfo = useSelector((state: any) => state.userInfo)
    const [detailDialog, setDetailDialog] = useState({
        open: false,
        data: {}
    })

    const openToast = useToast()
    const axios = new Network()
    const navigate = useNavigate();

    const getData = async (page = 1) => {
        const response = await axios.CallApi({
            url: '/Bonus/ReferralContest20230315',
            method: 'get'
        })
        if (response.ok) {
            setData(response?.data || [])
            let all = 0;
            let poolCharge = 0
            let poolPrize = 0
            let dragons = 0
            let mages = 0
            for (let key in response?.data) {

                all += (response?.data[key]?.inviteCount)
                poolCharge += (response?.data[key]?.rewardPoolShare)
                // poolPrize += (response?.data[key]?.rewardPoolPrize)
                poolPrize += (response?.data[key]?.inviteCount) * 100
                if (response?.data[key]?.troopName === "Mage") mages += (Math.pow(2, response?.data[key]?.level - 1)) * 2
                if (response?.data[key]?.troopName === "Dragon") dragons += Math.pow(2, response?.data[key]?.level - 1)
            }
            setStats({
                all, poolCharge, poolPrize, dragons, mages
            })
            setPaginations({ total: Math.ceil(response.data.totalCount / 100), page, count: 100 })
        }
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        getData()
    }, [])
    return (
        <SkeletonTheme baseColor='#2d271b' highlightColor='#3e382b'>
                 <Helmet>
                <title key={'title'}>
                   Leaderboard | Chain Of Legends
                </title>
            </Helmet>
            <motion.div
                exit={{ opacity: 0, y: 200 }}
                transition={{ duration: 0.4 }}
                initial={isMobile ? undefined : { opacity: 0, y: 200 }} animate={{ opacity: 1, y: 0 }}
                className='row'
            >

                <MidBox modal title="Leaderboard">
                    <div className='row px-2 px-md-3 align-items-end'>
                        {/* <div className='col-6 col-md-4 col-lg-2 '>
                            <label className='mb-2 dark-yellow'>
                                State:
                            </label>
                            <Form.Select value={state} onChange={(e) => setState(e.target.value)} aria-label="Default select example">
                                <option value={0}>{"All"}</option>
                                {Object.entries(WithdrawStates)?.map(([key, title]) =>
                                    <option key={key} value={key}>{title}</option>
                                )}
                            </Form.Select>
                        </div> */}

                    </div>
                    <img style={{ position: 'absolute', top: 10, left: 10 }} className="" src="/assets/images/icons/referral.png" width={80} height="auto" />
                    <img style={{ position: 'absolute', top: 10, right: 10 }} className="" src="/assets/images/icons/referral.png" width={80} height="auto" />
                    <div className='text-center d-flex  justify-content-center flex-wrap align-items-center'>
                        <h3>
                            Referral Rampage: Mage & Dragon Quest
                        </h3>&nbsp;
                        <Button onClick={() => setOfferDialog(true)} style={{ paddingInline: 5, minWidth: 80 }} variant='primary'>
                            <img src="/assets/images/icons/info.png" width={22} />    Info
                        </Button>
                    </div>
                    <div className='d-flex mt-3 flex-wrap align-items-center justify-content-center'>
                      {/* <Alert  variant='danger'>
                      Event Has Ended
                      </Alert> */}
                        <div className='text-center  text-danger'>
                            Remaining Time : 
                        </div>
                        <div className='ms-2'>
                            <Countdown
                                date={new Date('Thu, 11 Jul 2023 00:00:00 GMT')}
                                renderer={({ formatted, days }) => (
                                    <div className='fs-big d-flex'>  
                                        {days ?
                                            <div className='time-badge'>
                                                {formatted.days}
                                                <span className='dark-yellow'>
                                                    Days
                                                </span>
                                            </div> : ''}
                                        <div className='ms-2 time-badge'>
                                            {formatted.hours}
                                            <span className='dark-yellow'>
                                                Hours
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.minutes}
                                            <span className='dark-yellow'>
                                                Minutes
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.seconds}
                                            <span className='dark-yellow'>
                                                Seconds
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className='row mx-0 mx-md-3 mt-4'>
                        <div className='col-12 my-2 my-md-0 col-md-3'>
                            <div className='stats-box flex-row'>
                                <div style={{ width: 80 }}>
                                    <img width={80} src="https://core.chainoflegends.com/assets/troops/image/mage.webp" />
                                </div>
                                <div>
                                    <h6>
                                        Granted Mages
                                    </h6>
                                    <h4 className=' mt-3'>
                                        {stats.mages}
                                    </h4>
                                </div>

                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className='stats-box 
                            flex-column flex-md-row
                            '>
                                <div style={{ width: 80, height: 110 }} className="d-flex align-items-center">
                                    <img width={50} src="/assets/images/icons/cleg.png" />
                                </div>
                                <div>
                                    <h5>
                                        Total Successful Referrals:&nbsp;&nbsp;{stats.all}
                                    </h5>

                                    <h5>
                                        Reward Pool :&nbsp;&nbsp;{stats.poolPrize}
                                    </h5>

                                    <div className='fs-tiny'>
                                        Reward Pool Prize will be distributed among all players based on their Ranking on the table below
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-12 my-2 my-md-0 col-md-3'>
                            <div className='stats-box flex-row'>
                                <div>
                                    <img width={100} height={110} src="/assets/images/dragon-flying.webp" />
                                </div>
                                <div>
                                    <h6>
                                        Granted Dragons
                                    </h6>
                                    <h4 className=' mt-3'>
                                        {ThousandsSeparator(stats.dragons)}
                                    </h4>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ overflow: 'auto' }} className="pe-1 pt-3 pb-5 ps-1 mt-2 pe-sm-3 ps-sm-3 pb-4 table-box">
                        <table className="table table-borderless m-0 text-white" > <thead>
                            <tr>
                                <th scope="col">Rank</th>
                                <th style={{ textAlign: 'center' }} align='center'>
                                    Successful Referrals
                                    <OverlayTrigger
                                        trigger="click"
                                        // popperConfig={{}}
                                        placement={"top"}

                                        overlay={
                                            <Tooltip >
                                                Total number of your referrals who have built their barracks during this Event.
                                            </Tooltip>
                                        }
                                    >
                                        <img className='ms-1 pointer' src="/assets/images/icons/info.png" width={20} />
                                    </OverlayTrigger>
                                </th>
                                <th style={{ textAlign: 'center' }} scope="col">Bonus Troop

                                </th>
                                <th style={{ textAlign: 'center' }} scope="col">Remaining Invites
                                    <OverlayTrigger
                                        // popperConfig={{}}
                                        placement={"top"}
                                        trigger="click"
                                        overlay={
                                            <Tooltip >
                                                Remaining Successful Referral you need to win the next LVL prize.
                                            </Tooltip>
                                        }
                                    >
                                        <img className='ms-1 pointer' src="/assets/images/icons/info.png" width={20} />
                                    </OverlayTrigger>
                                </th>
                                <th style={{ textAlign: 'center' }} scope="col">Reward Pool Share</th>
                                <th style={{ textAlign: 'center' }} scope="col">Reward Pool Prize
                                    <OverlayTrigger
                                        // popperConfig={{}}
                                        placement={"top"}
                                        trigger="click"
                                        overlay={
                                            <Tooltip >
                                                Your Reward Pool Share X Accumulated Reward Pool
                                            </Tooltip>
                                        }
                                    >
                                        <img className='ms-1 pointer' src="/assets/images/icons/info.png" width={20} />
                                    </OverlayTrigger>
                                </th>
                                <th scope="col">Wallet Address</th>
                            </tr>
                        </thead>
                            <tbody>
                                {loading && [1, 2, 3].map((item) =>
                                    <tr key={"loading" + item} className=''
                                    >
                                        <td width={"50%"}>
                                            <Skeleton width={'100%'} />
                                        </td>
                                        <td> <Skeleton width={'60px'} /> </td>
                                        <td> <Skeleton width={'60px'} /></td>
                                        <td >
                                            <Skeleton width={'100px'}
                                                height={25}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {data.length === 0 && !loading && <tr>
                                    <td colSpan={4} style={{ textAlign: 'center', paddingTop: 40, fontSize: 20, paddingBottom: 40, }} className="dark-yellow">
                                        Sorry no data Available right now ..
                                    </td>
                                </tr>}
                                {!loading && data?.map((item, key) => (
                                    <>
                                        <tr className=''
                                            onClick={() => { }}
                                            style={{ opacity: !item?.isRead ? 1 : 0.7 }}
                                            key={"row" + key}>

                                            <td >
                                                <span className='fs-big' >
                                                    {key < 3 ? <img width={25} src={`/assets/images/icons/${key === 0 ? 'first' : key === 1 ? 'second' : 'third'}-place.png`} />
                                                        : <>#{key + 1}</>
                                                    }
                                                </span>
                                            </td>
                                            <td align='center' >
                                                <span className='fs-big bold' >
                                                    {item.inviteCount}
                                                </span>
                                            </td>
                                            <td align='center'  >
                                                <span className='fs-big'>
                                                    <span className={item?.troopName === 'Mage' ? 'text-pink' : 'text-danger'}>
                                                        {item?.troopName}
                                                    </span>  <small>
                                                        LVL {item?.level}
                                                    </small>
                                                </span>
                                            </td>
                                            <td align='center'  >
                                                <span className='fs-big'>
                                                    {item?.remainingInvites}
                                                </span>
                                            </td>
                                            <td align='center'  >
                                                <span
                                                    style={{
                                                        color: key === 0 ? '#f6e89f' : key === 1 ? '#87dde0' : key === 2 ? '#e19b7b' : ''
                                                    }}
                                                    className='fs-big'>
                                                    {item?.rewardPoolShare}%
                                                </span>
                                            </td>
                                            <td align='center'  >
                                                <span
                                                    style={{
                                                        color: key === 0 ? '#f6e89f' : key === 1 ? '#87dde0' : key === 2 ? '#e19b7b' : ''
                                                    }}
                                                    className='fs-big'>
                                                    {item?.rewardPoolPrize}&nbsp;<small>
                                                        CLEG
                                                    </small>
                                                </span>
                                            </td>
                                            <td >
                                                <div className='fs-big d-flex align-items-center'>
                                                    {item?.walletAddress?.toLowerCase() === userInfo?.walletAddress?.toLowerCase() && <img width={20} className="me-2" height={20} src="/assets/images/icons/star.svg" />}
                                                    {truncateString(item?.walletAddress)}
                                                </div>
                                            </td>
                                        </tr>

                                    </>
                                ))}

                            </tbody>
                        </table>
                        <br />
                        {/* <ReactPaginate

                            className='pagination justify-content-center'
                            activeClassName='active-pg'
                            pageClassName='page-item'
                            pageLinkClassName='page-link'
                            breakClassName='page-item'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            breakLinkClassName='page-link'
                            previousLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            onPageChange={(e) => getData(e.selected + 1)}
                            pageRangeDisplayed={3}
                            pageCount={paginations.total}
                        // renderOnZeroPageCount={null}
                        />*/}
                    </div>
                </MidBox>
            </motion.div>


            <Dialog onClose={() => {
                setOfferDialog(false)
                localStorage.setItem('ref-contest', 'true')
            }}
                title="REFERRAL RAMPAGE"
                open={offerDialog}>

                <img className='rounded' width={'100%'} src="/assets/images/ref-rampage.jpg" />
                <h4 className='mt-3 text-center'>
                    ⚔️ REFERRAL RAMPAGE ⚔️
                </h4>
                <h5 className='mt-3 bold text-center dark-yellow'>
                    Mage & Dragon Quest
                </h5>
                <p className='fs-med' >
                    ✅ "Chain Of Legends" grand Airdrop  event officially kicks off on Saturday! 🎉
                </p>
                <p className='fs-med' style={{ textAlign: 'justify' }}>
                    To honor your precious efforts, we started a grand airdrop of Mages and Dragons. You just need to refer your friends with your referral link and tell them to build their Barracks.
                </p>
                <h5 className='mt-3  dark-yellow'>
                    📆 Start Date: 10/May/2023<br />
                    📆 End Date: 10/June/2023
                </h5>
                <hr />
                <div>
                    🏃‍♂️ Refer to the most... Great Prizes comes with Great Efforts
                </div>
                <Link to="/referral-leaderboard">
                    <Button
                        onClick={() => {
                            setOfferDialog(false)
                            localStorage.setItem('ref-contest', 'true')
                        }}
                        style={{ marginBottom: -60 }} variant='wide-primary' className='mx-auto' >
                        REFERRAL LEADERBOARD
                    </Button>
                </Link>
            </Dialog>

            <Dialog title="Detail" open={detailDialog.open} onClose={() => setDetailDialog((prev) => ({ ...prev, open: false }))}>
                <div className='row flex-column'>
                    <div className='d-flex'>


                    </div>
                </div>
                {detailDialog?.data?.message}
            </Dialog>
        </SkeletonTheme >
    )
}

export default ReferralLeaderBoard