import React, { useState } from 'react'
import Countdown from 'react-countdown';
import TimeAgo from 'javascript-time-ago';
import { Link, useNavigate } from 'react-router-dom';
import Button from './Button';
import LevelBadge from './LevelBadge';
import { ItemBonusStat, ItemBonusStatIcons, ItemSlotNames, ItemStateEnum, ItemStateType, PlayerItemType } from '../types/items';
import Skeleton from 'react-loading-skeleton';
import { Badge } from 'react-bootstrap';
import { ClegIcon, CustomIcon } from './Icons';
import { roundFloat } from '../utils/round-float';
import ThousandsSeparator from '../utils/thousands-separator';
import useWindowSize from '../hooks/useWindowSize';

type Props = {
    loading?: boolean;
    data?: PlayerItemType;
    isMarket?: boolean;
    Ask?: number;
    Bid?: number;
    onSell?: (item: PlayerItemType) => void;
    onMerge?: (item: PlayerItemType) => void;
    onUnuse?: (item: PlayerItemType) => void;
    onCancel?: (item: PlayerItemType) => void;
    refreshList?: () => void;
    noAction?: boolean;
}

const ItemCard = ({ data, refreshList, loading, onSell, onMerge, onCancel, isMarket, Ask, Bid, onUnuse, noAction }: Props) => {
    const navigate = useNavigate()
    const isForSale = React.useMemo(() => data?.state === ItemStateEnum.ForSale, [data])
    const isInUse = React.useMemo(() => data?.state === ItemStateEnum.InUse, [data])
    const windowSize = useWindowSize()
    if (data && !loading) return (
        <div onClick={() => isMarket && navigate(`/marketplace/orderbook/item?itemSlots=${data?.item.slot}&levels=${data?.level}&itemStats=${data?.item.bonusStat}&itemId=${data?.itemId}`)} className={'adventure-card ' + (isMarket && 'pointer')}>

            <div className='d-flex'>
                <div className='adventure-card-content'     >
                    <div className='d-flex gap-2 align-items-center '>
                        <div className='p-relative'>
                            <img
                                style={{
                                    maxWidth: windowSize.width < 700 ? 60 : 99,
                                    maxHeight: 150
                                }}

                                src={data.image}
                            />

                            {!isMarket && (isInUse || isForSale) && <Badge
                                className='item-badge'
                                bg={isInUse ? 'warning' : 'danger'}
                            >
                                {ItemStateType[data.state]}
                            </Badge>}

                        </div>

                        <div className='d-flex w-100 gap-1 flex-column '>
                            <div className='d-flex w-100 align-items-center justify-content-between'>
                                <div className='fs-med'>
                                    {data.item?.name}
                                </div>
                                <LevelBadge style={{ position: 'relative', zIndex: 10, marginInline: 0 }} level={data.level} />
                            </div>
                            <div style={{ maxWidth: '170px' }} className='fs-small text-truncate'>

                                <img src={ItemBonusStatIcons[data.item.bonusStat]}
                                    width={20} height={24}
                                    className='me-1'
                                    alt={ItemBonusStat[data.item.bonusStat]} />

                                +{roundFloat(data.bonusValue)} {data.itemId === 18 ? "Magic Damage" : ItemBonusStat[data.item.bonusStat]}

                            </div>
                            {!isMarket && isForSale && <div className='fs-small mt-2 d-flex align-items-center'>
                                <ClegIcon width={20} height={20} />  {ThousandsSeparator(roundFloat(data.sellOrder?.clegPrice!))} CLEG
                                <Button onClick={() => onCancel && onCancel(data)} size="small" className='ms-2' variant='default'>
                                    Cancel
                                </Button>
                            </div>}
                            <div>
                                {!isMarket && !isForSale && !noAction && <div className='d-flex mt-1  gap-2 align-items-center'>
                                    {data.state === ItemStateEnum.InUse ?
                                        <Button onClick={() => onUnuse && onUnuse(data)} size="small" className='px-2' variant='default'>
                                            <img height={15} width={14} className='me-1' src="/assets/images/icons/remove.png" />
                                            Detach
                                        </Button>
                                        : <Button onClick={() => onSell && onSell(data)} size="small" variant='default'>
                                            <img width={15} className='me-1' src="/assets/images/icons/offer.png" />
                                            Sell
                                        </Button>}

                                    <Button onClick={() => onMerge && onMerge(data)} size="small" variant='primary'>
                                        <img width={15} className='me-1' src="/assets/images/icons/merge.png" />
                                        Merge
                                    </Button>
                                </div>}
                            </div>
                            {isMarket && <div className='fs-small'>
                                <div>
                                    Buyer Price: <span className='text-success bold'>
                                        {ThousandsSeparator(Bid?.toString() || '0') || 0}  <small className='dark-gray'>
                                            <ClegIcon width={20} height={20} /> CLEG
                                        </small>
                                    </span>
                                </div>

                                <div>
                                    Seller Price: <span className='text-danger bold'>
                                        {ThousandsSeparator(Ask?.toString() || '0')} <small className='dark-gray'>
                                            <ClegIcon width={20} height={20} /> CLEG
                                        </small>
                                    </span>
                                </div>
                            </div>}

                        </div>
                    </div>

                </div>
            </div>

        </div >
    )

    return <>
        <div className='adventure-card'>

            <div className='d-flex'>
                <div
                    className='adventure-card-content'
                >
                    <div className='d-flex gap-2 '>
                        <Skeleton width={90} height={90} circle />
                        <div className='d-flex w-100 flex-column '>
                            <div className='d-flex w-100 align-items-center justify-content-between'>
                                <div className='fs-lg'>
                                    <Skeleton width={111} />
                                </div>
                            </div>
                            <div className='fs-med'>
                                <Skeleton width={50} count={2} />
                            </div>
                            <div>
                                <div className='d-flex mt-2 gap-2 align-items-center'>
                                    <Skeleton width={50} />
                                    <Skeleton width={50} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div >
    </>
}

export default ItemCard;