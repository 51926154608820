import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { EnvConfig } from "../constants/EnvConfig";
import { defaultState } from "./alert/reducer";
import { defaultBuilding } from "./buildings/reducer";
import {
  initialActiveChat,
  initialChannels,
  initialMessages,
  initialMetaData,
} from "./chat/reducers";
import { defaultCycle } from "./cycle/reducer";
import { effectsDefaultState } from "./effects/reducer";
import { audioDefaultState } from "./music/reducer";
import { defaultQuestState } from "./quests/reducer";
import { initialRates } from "./rates/reducer";
import { rootReducer } from "./RootReducer";
import { initialScouts } from "./scout/reducer";
import { userDefaultSettings } from "./settings/reducer";
import storage from "./sync-storage";
import { tourDefaultState } from "./tour/reducer";
import { userInfo, userToken } from "./user/reducer";

//config
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "userToken",
    "userInfo",
    "userSettings",
    "buildings",
    "tour",
    "myScouts",
    "currentCycle",
    "channels",
    "readMessages",
    "quests",
  ],
  blacklist: [
    "alert",
    "messages",
    "usersMetaData",
    "audioPlayable",
    "chatState",
    "effects",
    "rates",
    "activeChat",
  ],
};

//combine
const persistedReducer = persistReducer(persistConfig, rootReducer);

//state values
const initState: any = {
  userToken,
  userInfo,
  activeChat: initialActiveChat,
  userSettings: userDefaultSettings,
  alert: defaultState,
  rates: initialRates,
  chatState: false,
  audioPlayable: audioDefaultState,
  tour: tourDefaultState,
  quests: defaultQuestState,
  myScouts: initialScouts,
  buildings: defaultBuilding,
  currentCycle: defaultCycle,
  channels: initialChannels,
  messages: initialMessages,
  effects: effectsDefaultState,
  usersMetaData: initialMetaData,
};

export const myStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
        ignoredPaths: ["register"],
      },
    }),
  preloadedState: initState,
  devTools: EnvConfig.NODE_ENV === "development",
});

export const persistor = persistStore(myStore);
