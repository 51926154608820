import React, { useEffect, useContext, useCallback, useMemo } from "react";
import { Cloud, OrbitControls, useCamera, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { SlotContext } from "./slot/context";
import TroopModel from "./troop-model";
import { Slot, Unit } from "../types";
import SlotNode from "./slot";
import { Actions } from "../types/enum";
import useGameLogic from "../hooks/useGameLogic";
import { useBattleContext } from "../context";
import getModelsData from "../utils/get-model-data";
import { findNearestTarget } from "../utils/find-target";
import { useModelLoader } from "../context/model-loader-context";

interface Props {
  devMode: boolean;
}
const PolygonGrid: React.FC<Props> = ({ devMode }) => {
  const { state, dispatch } = useContext(SlotContext);
  const { battleState, coolDowns } = useBattleContext();
  const { troopAction, selectUnit } = useGameLogic();
  const { selectedTroop, units } = battleState;
  // const [show, setShow] = React.useState(false);
  const rows = 6;
  const cols = 10;
  const spacingX = 1; // Adjust the spacing on the x-axis
  const spacingZ = 0.8; // Adjust the spacing on the z-axis
  const offset = 0.5; // Adjust the offset for the odd rows
  const { models, progress } = useModelLoader();
  console.log(battleState.isWatcher)
  useEffect(() => {
    if (state.slots.length > 0) return;
    let temp: Slot[] = [];
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        const xOffset = i % 2 === 1 ? offset : 0; // Apply offset to odd rows
        temp.push({
          id: `${i}-${j}`,
          position: new THREE.Vector3(
            j * spacingX - (cols * spacingX) / 2 + xOffset,
            0,
            i * spacingZ - (rows * spacingZ) / 2
          ),
        });
      }
    }

    dispatch({ type: Actions.ADD_SLOTS, payload: temp });
  }, [rows, cols, spacingX, offset, dispatch]);

  const handleSlotClick = useCallback(
    ({ id, modelId, position }: Slot) => {
      if (selectedTroop) {
        if (battleState.isWatcher) return;
        return troopAction(selectedTroop, {
          x: +id.split("-")[1],
          y: +id.split("-")[0],
        });
      }
      const relatedUnit = units.find(
        (unit) =>
          unit.health > 0 &&
          unit.x === +id.split("-")[1] &&
          unit.y === +id.split("-")[0]
      );
      if (relatedUnit) selectUnit(relatedUnit.playerTroopId);
    },
    [troopAction, selectedTroop, battleState.isWatcher]
  );

  const allUnits = useMemo(
    () => units.filter((unit) => unit.x >= 0 && unit.y >= 0 && unit.health > 0),
    [units]
  );

  function getTargetPosition(unit?: Unit) {
    if (!unit) return new THREE.Vector3();
    return state.slots.find((slot) => slot.id === `${unit.y}-${unit.x}`)
      ?.position;
  }
  return (
    <>
      {/* <pointLight position={[5, 5, 80]} /> */}
      {devMode && [
        <OrbitControls />,
        <axesHelper args={[5]} />,
        <gridHelper args={[8, 8]} />,
      ]}
      {state.slots.map((slot) => (
        <SlotNode
          key={slot.id}
          id={slot.id}
          position={slot.position}
          onClick={() => handleSlotClick(slot)}
          userData={slot}
          model={allUnits.find(
            (unit) =>
              unit.x === +slot.id.split("-")[1] &&
              unit.y === +slot.id.split("-")[0]
          )}
        />
      ))}
      {state.slots.length &&
        allUnits.length &&
        allUnits.map((unit, i) => (
          <TroopModel
            devMode={devMode}
            key={unit.playerTroopId}
            target={getTargetPosition(
              findNearestTarget(
                unit,
                allUnits.filter((u) => unit.isFriend !== u.isFriend)
              )!
            )}
            targetInfo={
              findNearestTarget(
                unit,
                allUnits.filter((u) => unit.isFriend !== u.isFriend)
              )!
            }
            model={models.find((m) => +m.id === unit.troopId)}
            modelData={getModelsData(unit.troopId)}
            unit={unit}
            coolDown={coolDowns.troops[unit.playerTroopId]}
          />
        ))}

      {/* <Gates /> */}
    </>
  );
};

export default PolygonGrid;
