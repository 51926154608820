import React, { useEffect, useRef, useState } from 'react'
import useWindowSize from '../hooks/useWindowSize'
import Network from '../utils/Network';
import Button from '../components/Button';
import { Badge, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import useToast from '../hooks/useToast';
import { motion } from 'framer-motion'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Dialog from '../components/Dialog';
import moment from 'moment';
import Countdown from 'react-countdown';
import { isMobile, isTablet } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { setPlayable } from '../redux/music/action';
import { useDispatch } from 'react-redux';
import { createRef } from 'react';
import { defaultBattlePositions } from '../utils/battle-positions';
import LevelBadge from '../components/LevelBadge';
import Slider from 'rc-slider';
import LeaderShare from '../components/LeaderShare';
import { makeUpperCase } from './ModifyTreasure';
import TreasureInfo from '../components/TreasureInfo';
import { Helmet } from 'react-helmet';
import { ItemBonusStat } from '../types/items';

type Props = {}



const JoinTreasure = (props: Props) => {
    const [startLoading, setStartLoading] = useState(false)
    const windowSize = useWindowSize()
    const contaierRef = useRef()
    const { id } = useParams()
    const overFlowContainer = useRef()
    const supplyContainer = useRef()
    const [selectMode, setSelectMode] = useState(false)
    const [selectProperties, setSelectProperties] = useState({ checkRoom: 0, battlePlace: 0 })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [data,setData]=useState()
    const [show, setShow] = useState(0);
    const [arrayList, setArrayList] = useState(defaultBattlePositions)
    const [supplyList, setSupplyList] = useState<any>()
    const [result, setResult] = useState<any>()
    let [searchParams, setSearchParams] = useSearchParams();
    const width = contaierRef?.current?.offsetWidth;
    const [leaderShare, setLeaderShare] = useState(0)
    const containerHeight = contaierRef?.current?.offsetHeight;
    const calculatedSize = (width - 400) / (windowSize?.width < 800 ? 10 : 10)
    const elementSize = calculatedSize > 200 ? 200 : calculatedSize
    const axios = new Network()
    const [activePopper, setActivePopper] = useState<any>({})
    const [allTroops, setAllTroops] = useState([])
    const [dragging, setDragging] = useState(false)
    const openToast = useToast()
    const [resultDialog, setResultDialog] = useState(false)
    const [defaultFormation, setDefaultFormation] = useState([])
    const isRaid = Boolean(searchParams.get('raid'))
    const [selectDialog, setSelectDialog] = useState({
        open: false,
        item: null
    })

    async function getTroops() {
        const response = await axios.CallApi({ url: '/troop', method: 'get' })
        setAllTroops(response?.data)
    }
    async function getAdv() {
        const response = await axios.CallApi({ url: '/adventure/' + id, method: 'get' })
        const formation = JSON.parse(response?.data?.formation)
        setLeaderShare(response?.data?.leaderShare)
        for (let key in formation) {
            const target = arrayList.find((item) =>
                (item?.displayX === formation[key].displayX)
                &&
                (item?.displayY === formation[key].displayY))
            setArrayList((prev) => ([...prev?.filter((item) => item.index !== target?.index), {
                ...target,
                ...formation[key], isLocked: formation[key]?.troopId ? false : true
            }]?.sort((a, b) => a.index - b.index)))
        }
        setData(response.data)
    }

    useEffect(() => {
        getAdv()
        getTroops()

    }, [])


    const handleOnMouseEnter = (id) => {
        setShow(id);
    }
    const handleOnMouseLeave = () => {
        setShow(0);
    }

    function handleSelectCheckRoom(id: number) {
        setSelectMode(true)
        // if (selectProperties?.checkRoom) {
        //     setSelectProperties(prev => ({ ...prev, checkRoom: id }))
        //     setSelectMode(false)
        //     return
        // }
        if (selectProperties?.battlePlace) {
            // TODO CHANGE

            return
        }
        setSelectProperties({ battlePlace: 0, checkRoom: id })

    }
    function handleBattlePlace(id?: number | string, i?: number, item) {
        if (selectProperties.checkRoom) {
            if (item.isLocked) return;
            if (!item.troopId) return;
            if (item.troopId) {
                const troop = supplyList?.find((item: any) => item.id === selectProperties.checkRoom)
                if (item?.troopId !== troop.troopId) {
                    openToast({
                        ok: false,
                        message: allTroops?.find((e) => e.id === item.troopId)?.name + ' Required'
                    })
                    return
                }
                if (item?.level > troop.level) {
                    openToast({
                        ok: false,
                        message: 'Minimum Level Is ' + item?.level
                    })
                    return
                }

            }


            const playerTroop = supplyList?.find((item: any) => item.id === selectProperties.checkRoom)
            setActivePopper({ ...makeUpperCase(playerTroop), Troop: makeUpperCase(playerTroop.troop) })
            setArrayList((prev: any) => ([...prev?.filter(item => item?.index !== i), {
                ...prev?.find(irt => irt?.index === i),
                playerTroopId: selectProperties.checkRoom,
                troopId: 0,
                isOwner: true,
                isLocked: false,
                playerTroop: { ...makeUpperCase(playerTroop), Troop: makeUpperCase(playerTroop.troop) }
            }]?.sort((a, b) => a.index - b.index)))
            setTimeout(() => {
                handleOnMouseEnter(selectProperties.checkRoom)
            }, 1000);
            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
            setSelectMode(false)
            return
        }
        if (selectProperties.battlePlace) {
            // if (item.playerTroopId) return;

            if (id && id === selectProperties.battlePlace) {
                setSelectProperties({ battlePlace: 0, checkRoom: 0 })
                setSelectMode(false)
                return
            };

            setArrayList((prev: any) => ([...prev?.filter(item => item?.index !== i && item?.playerTroopId !== selectProperties.battlePlace),
            {
                ...arrayList?.find(irt => irt?.index === i),
                playerTroopId: selectProperties.battlePlace,
                playerTroop: arrayList?.find(irt => irt?.playerTroopId === selectProperties.battlePlace)?.playerTroop

            },
            {
                ...arrayList?.find(irt => irt?.playerTroopId === selectProperties.battlePlace),
                playerTroopId: undefined,
            }
            ]?.sort((a, b) => a.index - b.index)))
            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
            setSelectMode(false)
            return
        }
        if (id) {
            if (arrayList?.find(item => item.playerTroopId === id)) return
            if (arrayList?.find(item => item.playerTroopId === id)?.isLocked) return
            setSelectMode(true)
            setSelectProperties({ battlePlace: parseInt(id?.toString()), checkRoom: 0 })
        }
    }

    const popperActiveItem = arrayList?.find((item) => item?.playerTroopId === activePopper?.Id)

    function changeTargetPriority(id: number, value: number) {
        const oldObj: any = arrayList?.find((item) => item?.playerTroopId === id)
        setArrayList(prev => ([...prev?.filter(item => item?.playerTroopId !== id), { ...oldObj, targetPriority: value }]?.sort((a, b) => a.index - b.index)))
    }

    const getPlayerToops = async () => {
        const response = await axios.CallApi({ url: '/playertroop?count=50', method: 'GET' })
        if (response.ok) setSupplyList(response?.data?.data)
    }


    useEffect(() => {
        getPlayerToops()
    }, [])
    useEffect(() => {
        if (overFlowContainer) {
            overFlowContainer.current.scrollLeft = (windowSize.width < 600 ? contaierRef?.current?.offsetWidth / 3.6 : 200)
        }
    }, [])

    useEffect(() => {
        dispatch(setPlayable(false))
    }, [])
    const startAdventure = async () => {

        setStartLoading(true)

        const activeArray = [...arrayList?.filter(item => (Boolean(item.playerTroopId) && !item?.isLocked))]

        const response = await axios.CallApi({
            url: "/adventure/" + id + '/join',
            body: {
                "playerTroopIds": activeArray?.map((item) => (item.playerTroopId)),
                formation: activeArray?.map((item) => ({
                    ...item,
                    playerTroopId: item?.playerTroopId || 0,
                    playerTroop: supplyList?.find((detail: any) => detail?.id === item.playerTroopId) || undefined
                }))
            }
        })

        if (response.ok) {
            navigate(`/adventure/waiting/treasure/${response?.data?.result?.id}`)
            // setResult(response?.data?.result)
            // setResultDialog(true)
        }
        openToast(response)
        setStartLoading(false)
    }

    function openSelect(item) {
        setSelectDialog({ open: true, item: { troopId: 1, level: 1, ...item, } })
    }

    function closeSelectDialog() {
        setSelectDialog(prev => ({ ...prev, open: false }))
    }

    const popover = (
        <Popover
            onClick={(e) => e.stopPropagation()}
            id="popover-basic" style={{
                backgroundColor: 'transparent',
                backgroundImage: 'url(/assets/images/backgrounds/popper-bg.png)',
                backgroundSize: '100% 100%',
                minHeight: 210,
                width: 250,
                color: '#fff',
                border: 'none'
            }}

        >
            <Popover.Header
                style={{
                    backgroundColor: 'transparent',
                    border: 'none'
                }}
                className="p-4 bold d-flex align-items-center" as="h3">
                <div className='icon-badge'>
                    <img src={activePopper?.troop?.icon}
                        width={35}
                        height={35}
                        className=""
                    />
                </div>
                Target priority
            </Popover.Header>
            <Popover.Body style={{ marginTop: -20 }} className='text-white ps-4'>
                <div style={{ marginTop: -10, marginBottom: 10 }} className="fs-tiny">
                    <span>
                        HP: {activePopper?.health}/{activePopper?.maxHealth} | Damage: {activePopper?.damage} | Armor: {activePopper?.armor} | Magic Resistance: {activePopper?.magicResistance}
                    </span>
                </div>
                <div className='d-flex flex-wrap gap-1 mb-2'>
                    {activePopper?.items?.map((item) =>
                        <OverlayTrigger overlay={<Tooltip>
                            {item?.item?.name} - level {item?.level}  <div className='text-success-light'>
                                +{item?.bonusValue} {ItemBonusStat[item?.item?.bonusStat]}
                            </div>
                        </Tooltip>}>
                            <div className='circle border p-1'>
                                <img
                                    width={25}
                                    height={25}
                                    className='circle'
                                    src={item.image}
                                />
                            </div>
                        </OverlayTrigger>
                    )}

                </div>
                <Form.Check
                    type={"radio"}
                    value={1}
                    checked={popperActiveItem?.targetPriority === 1}
                    label={`Closest`}
                    onClick={(e) => changeTargetPriority(activePopper?.Id, 1)}
                />
                <Form.Check
                    type={"radio"}
                    value={2}
                    checked={popperActiveItem?.targetPriority === 2}
                    onClick={(e) => changeTargetPriority(activePopper?.Id, 2)}
                    label={`Lowest HP`}
                />
                <Form.Check
                    type={"radio"}
                    value={3}
                    checked={popperActiveItem?.targetPriority === 3}
                    onClick={(e) => changeTargetPriority(activePopper?.Id, 3)}
                    label={`Highest Damage`}
                />
                <br />
            </Popover.Body>
        </Popover>
    );



    // LongPress
    const timer = useRef<any>(null)
    function handleButtonPress(e: any, item) {
        timer.current = setTimeout(() => {
            if (!isMobile && !isTablet) return;
            setActivePopper(item?.playerTroop)
            if (item.isLocked && !item.isOwner) return;
            handleOnMouseEnter(item?.playerTroopId)
        }
            , 300);
    }

    function handleButtonRelease() {
        clearTimeout(timer.current);
    }


    return (
        <div onClick={() => handleOnMouseLeave()} ref={overFlowContainer} style={{ overflow: 'auto' }}>
            <Helmet>
                <title key={'title'}>
                    Join Adventure | Chain Of Legends
                </title>
            </Helmet>
            <TreasureInfo treasureType={2} 
             id={data?.treasure?.id}/>
            <div style={{
                display: 'flex',
                minHeight: windowSize.width > 1000 ? windowSize.width > 1800 ? elementSize*8.5 : '950px' : elementSize*10.4,
                justifyContent: windowSize.width < 990 ? 'flex-start' : ''
            }} ref={contaierRef} className='treasure ' >
                <div style={{
                    maxWidth: windowSize.width < 990 ? 'none' : '',
                    paddingInlineStart: windowSize.width > 1500 ? '8%' : windowSize.width < 990 ? '8%' : '10%',
                }} className='w-100  transition pe-3 '>
                    <div style={{ minWidth: 800 }} className='row '>
                        <div style={{ position: 'relative' }} className="d-flex mt-4 align-items-center col-12">
                            {arrayList?.map((item, i) => {
                                const ref = createRef()
                                return (
                                    <div key={i}
                                        className='tr-polygon transition d-flex pf-step4 justify-content-center'
                                        onClick={() => (isMobile || isTablet) ? handleBattlePlace(item?.playerTroopId, item?.index, item) : null}
                                        onDragEnter={(e) => {
                                            const id = parseInt(e.dataTransfer.getData("text/plain"));
                                        }}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                        }}
                                        onDrop={(e) => {
                                            setDragging(false)
                                            if (item?.isLocked || !item.troopId) return;
                                            const id = parseInt(e.dataTransfer.getData("text/plain"));
                                            if (item?.troopId) {
                                                const troop = supplyList?.find((item) => item?.id === id)
                                                if (item?.troopId !== troop.troopId) {
                                                    openToast({
                                                        ok: false,
                                                        message: allTroops?.find((e) => e.id === item.troopId)?.name + ' Required'
                                                    })
                                                    return
                                                }

                                                if (item?.level > troop.level) {
                                                    openToast({
                                                        ok: false,
                                                        message: 'Minimum Level Is ' + item?.level
                                                    })
                                                    return
                                                }

                                            }
                                            if (arrayList?.find((item) => item.playerTroopId === id)) {
                                                setArrayList(prev => ([...prev?.filter(item => item?.playerTroopId !== id), { ...arrayList?.find((item) => item.playerTroopId === id), playerTroopId: undefined }]?.sort((a, b) => a.index - b.index)))
                                            }
                                            if (!id) return

                                            setArrayList(prev => ([...prev?.filter(item => item?.index !== i), {
                                                ...item, playerTroopId: id,
                                                playerTroop: {
                                                    ...makeUpperCase(supplyList?.find((item) => item.id === id)),
                                                    Troop: makeUpperCase(supplyList?.find((item) => item.id === id).troop)
                                                },
                                                troopId: 0
                                            }]?.sort((a, b) => a.index - b.index)))
                                        }}
                                        style={{
                                            opacity: selectMode ? (!item?.playerTroopId && item.troopId ? 1 : 0.5) : dragging ? (item?.troopId ? 1 : 0.2) : (item?.playerTroopId || item?.troopId) ? 1 : 0.5,
                                            width: elementSize, height: elementSize,
                                            backgroundImage: item?.troopId ? 'url(/assets/images/battle/rd-polygon.png)' : '',
                                            // transform:    `translateY(${elementSize / 2}px)`,
                                            position: 'absolute',
                                            top: item?.y * elementSize + (windowSize?.width < 1200 ? containerHeight / 7 : containerHeight / 7),
                                            textAlign: 'center',
                                            left: (item?.displayX) * elementSize + 70,
                                            // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                                        }}
                                    >
                                        {/* {isRaid && item?.playerTroopId !== 0 && !item?.playerTroopId && !item?.troopId && <div
                                            style={{
                                                position: 'absolute', background: "#e8a55f",
                                                boxShadow: 'inset 1px 1px 8px  #be7134',
                                                bottom: 0
                                            }}
                                            onClick={() => openSelect(item)}
                                            className='pointer px-2 rounded'>
                                            +
                                        </div>} */}
                                        {item?.troopId ? <motion.div
                                            initial={{ scale: 0 }}
                                            animate={{ scale: 1 ,opacity:0.7}}
                                        >
                                            <img
                                                className='scaleX transition'
                                                // onDragStart={(event) => {
                                                //     event
                                                //         .dataTransfer
                                                //         .setData('text/plain', item?.playerTroopId)
                                                // }}
                                                draggable={false}
                                                style={{
                                                    maxWidth: '100%',
                                                    marginTop: -20,
                                                    width: dragging ? '50px' : 'auto',
                                                    maxHeight: windowSize?.width > 1700 ? 150 : windowSize?.width < 800 ? 88 : 100
                                                }}
                                                src={allTroops?.find(detail => detail?.id === item?.troopId)?.image}
                                            />
                                            <Badge style={{
                                                position: 'absolute', top: 0,
                                                right: -2,
                                                width: 'fit-content',
                                                fontSize: windowSize?.width < 800 ? 8 : 12,
                                                transform: 'rotate(29deg)',
                                                opacity: 0.6
                                            }}>
                                                Request
                                            </Badge>
                                            <LevelBadge level={item?.level} />
                                            {/* <div

                                                style={{
                                                    position: 'absolute', background: "#e8a55f",
                                                    boxShadow: 'inset 1px 1px 8px  #be7134',

                                                    bottom: 0, width: 'fit-content', right: 0, left: 0
                                                }}
                                                className='pointer px-1 rounded mx-auto'>
                                                    
                                            <img draggable={false} width={15} src={'/assets/images/icons/edit.svg'} /> 
                                            </div> */}
                                        </motion.div> : ''}
                                        {item?.playerTroopId ? <OverlayTrigger

                                            key={item?.playerTroopId}
                                            show={show === item?.playerTroopId}
                                            container={ref}
                                            placement="right"
                                            overlay={popover}>
                                            <div ref={ref}
                                                onTouchStart={(e) => handleButtonPress(e, item)}
                                                onTouchEnd={handleButtonRelease}
                                                onMouseDown={(e) => handleButtonPress(e, item)}
                                                onMouseUp={handleButtonRelease}
                                                onMouseLeave={handleButtonRelease}
                                                onClick={(e) => {
                                                    if (isTablet || isMobile) return
                                                    if (item.isLocked) return;
                                                    e.stopPropagation()
                                                    setActivePopper(item?.playerTroop)
                                                    handleOnMouseEnter(item?.playerTroopId)
                                                }}


                                            >
                                                <div
                                                    style={{ height: '40%', width: 5, top: "30%", left: 0, borderRadius: 5, position: 'absolute', display: 'flex', alignItems: 'flex-end' }}
                                                    className="p-relative bg-dark-500 overflow-hidden"
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: (item?.playerTroop?.Health * 100 / item?.playerTroop?.MaxHealth) + "%"
                                                            ,
                                                            backgroundColor: 'var(--theme-color-green-dark)'
                                                        }}
                                                    />
                                                </div>
                                                <LevelBadge isReq={!supplyList?.find((troop) => troop.id === item.playerTroopId)} level={item?.playerTroop?.Level} />
                                                <img
                                                    className='scaleX'
                                                    onDragStart={(event) => {
                                                        if (item?.isLocked) return;
                                                        event
                                                            .dataTransfer
                                                            .setData('text/plain', item?.playerTroopId)
                                                    }}
                                                    draggable={!item?.isLocked}
                                                    style={{
                                                        maxWidth: '100%',
                                                        marginTop: -10,
                                                        maxHeight: windowSize?.width > 1700 ? 150 : windowSize?.width < 800 ? 88 : 100
                                                    }}
                                                    src={item.playerTroop?.Troop?.Image}
                                                />
                                            </div>
                                        </OverlayTrigger> : ''}
                                    </div>

                                    // <div style={{
                                    //     width: elementSize,
                                    //     height: elementSize,
                                    //     transform:    `translateY(${elementSize / 2}px)`,
                                    // }} id={`position${item?.index}`} className='pre-polygon'>
                                    // </div>


                                )
                            })
                            }
                            {defaultBattlePositions?.map((item) => <div className='tr-polygon'
                                style={{
                                    width: elementSize, height: elementSize,
                                    // transform:    `translateY(${elementSize / 2}px)`,
                                    position: 'absolute',
                                    opacity: 0.4,
                                    top: item?.y * elementSize + (windowSize?.width < 1200 ? containerHeight / 7 : containerHeight / 7),
                                    left: (item?.displayX + 5) * elementSize + 70,
                                    // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'

                                }}
                            >


                            </div>)}
                        </div>

                    </div>
                </div>

                {windowSize?.width < 800 ? <div className='text-center px-3 gap-2 d-flex' style={{ position: 'fixed', bottom: "140px", right: 0, left: 0,zIndex:500 , }}>
                    {selectMode && selectProperties.battlePlace ? <Button
                    size='small' style={{flex:1}}
                        onClick={() => {
                            setArrayList((prev: any) => (
                                [...prev?.filter(item => item?.playerTroopId !== selectProperties.battlePlace),
                                {
                                    ...prev?.find(irt => irt?.playerTroopId === selectProperties.battlePlace),
                                    playerTroopId: undefined
                                }
                                ]?.sort((a, b) => a.index - b.index)))
                            setSelectMode(false)
                            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
                            
                        }}
                        variant='wide' className='mx-auto '>
                        Remove
                    </Button> : ''}

                    <Button size='small' style={{flex:1}} loading={startLoading} onClick={startAdventure} className='mx-auto bg-100 ' variant='wide-primary'>
                        Join adventure
                    </Button>
                    <Button size='small'style={{flex:1}} disabled={startLoading} onClick={getAdv} className=' mx-auto  bg-100' variant='wide'>
                        Reset
                    </Button>
                </div> : ''}

                <LeaderShare readOnly value={leaderShare} onChange={setLeaderShare} />
                <div onDragOver={(e) => {
                    e.preventDefault();
                }}
                    onDrop={(e) => {
                        return;
                        const id = parseInt(e.dataTransfer.getData("text/plain"));
                        if (arrayList?.find((item) => item.playerTroopId === id)) {
                            const troopId =
                                setArrayList((prev: any) => ([...prev?.filter(item => item?.playerTroopId !== id), { ...arrayList?.find((item) => item.playerTroopId === id), playerTroopId: undefined }]?.sort((a, b) => a.index - b.index)))
                        }
                        if (!id) return
                        // setArrayList(prev => ([...prev?.filter(item => item?.index !== i), { ...item, playerTroopId: id }]?.sort((a, b) => a.index - b.index)))
                    }}

                    className='picker-bar pf-step2 align-items-center'>

                    <div style={{ flex: 1, height: '100%', padding: '15px 0' }} ref={supplyContainer}>
                        <PerfectScrollbar style={{ width: supplyContainer?.current?.offsetWidth }} >

                            <div style={{ height: '100%' }} className='d-flex'>
                                {supplyList?.length === 0 && <div>
                                    No troop available
                                </div>}
                                {supplyList?.map((item: any) => {
                                    if (!arrayList?.find(obj => obj.playerTroopId === item?.id) && item?.state === 1) return (
                                        <div style={{
                                            width: windowSize?.width > 800 ? (width / 10 - 5) : 120,
                                            minWidth: 120,
                                            height: '100%',
                                            opacity: selectMode ? (selectProperties?.battlePlace ? 1 : selectProperties?.checkRoom === item?.id ? 1 : 0.4) : 1
                                        }} className='fight-placement pf-step3'>

                                            <div
                                                onClick={() => isMobile || isTablet ? handleSelectCheckRoom(item.id) : null}
                                                onDragEnd={() => {
                                                    setDragging(false)
                                                }}
                                                onDragStart={(event) => {
                                                    setDragging(true)
                                                    event
                                                        .dataTransfer
                                                        .setData('text/plain', item.id)
                                                }} draggable={true}
                                                className='d-flex w-100 h-100 text-center p-relative justify-content-center align-items-center'>

                                                <div
                                                    style={{ height: '40%', width: 5, top: "30%", left: "17%", transform: 'rotate(12deg)', borderRadius: 5, position: 'absolute', display: 'flex', alignItems: 'flex-end' }}
                                                    className="p-relative bg-dark-500 overflow-hidden"
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: (item?.health * 100 / item?.maxHealth) + "%",
                                                            backgroundColor: 'var(--theme-color-green-dark)'
                                                        }}
                                                    />
                                                </div>
                                                <LevelBadge level={item?.level} />
                                                <img
                                                    draggable={false} className="mx-auto scaleX"

                                                    style={{ maxWidth: '75%', maxHeight: 100 }} src={item?.troop?.image} />

                                            </div>
                                        </div>
                                    )
                                    else {
                                        return ''
                                    }
                                })}
                            </div>

                        </PerfectScrollbar>


                    </div>


                    {windowSize?.width > 800 && <div className='d-flex flex-column'>


                        <Button loading={startLoading} onClick={startAdventure} className='ms-auto mx-2' variant='wide-primary'>
                            Join adventure
                        </Button>
                        <Button disabled={startLoading} onClick={getAdv} className='ms-auto mx-2' variant='wide'>
                            Reset
                        </Button>

                    </div>}
                </div>
                <Dialog closeLabel='Return' title="Adventure Result" open={resultDialog} onClose={() => { setResultDialog(false); navigate('/adventure') }}>

                    <p className='fs-big text-center bold dark-yellow'>
                        Troops sent successfully
                    </p>
                    <div style={{ marginBottom: -30 }} className='row mt-5 flex-column'>
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            {/* <img width={80} height={90} src={}/> */}
                            <span>
                                <img src={"/assets/images/icons/timer.png"} width={25} height={25} />  Arrival time
                            </span>
                            <Countdown
                                date={new Date(result?.arriveDate)}
                                renderer={({ formatted, days }) => (
                                    <div className='fs-big d-flex'>
                                        {days ?
                                            <div className='time-badge me-2'>
                                                {formatted.days}
                                                <span className='dark-yellow'>
                                                    Days
                                                </span>
                                            </div> : ""}
                                        <div className='time-badge'>
                                            {formatted.hours}
                                            <span className='dark-yellow'>
                                                Hours
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.minutes}
                                            <span className='dark-yellow'>
                                                Minutes
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.seconds}
                                            <span className='dark-yellow'>
                                                Seconds
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <hr />
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            {/* <img width={80} height={90} src={}/> */}
                            <span>
                                <img src={"/assets/images/icons/timer.png"} width={25} height={25} />  Return time
                            </span>
                            <Countdown
                                date={new Date(result?.returnDate)}
                                renderer={({ formatted, days }) => (
                                    <div className='fs-big d-flex'>
                                        {days ?
                                            <div className='time-badge me-2'>
                                                {formatted.days}
                                                <span className='dark-yellow'>
                                                    Days
                                                </span>
                                            </div> : ""}
                                        <div className='time-badge'>
                                            {formatted.hours}
                                            <span className='dark-yellow'>
                                                Hours
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.minutes}
                                            <span className='dark-yellow'>
                                                Minutes
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.seconds}
                                            <span className='dark-yellow'>
                                                Seconds
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                    </div>
                </Dialog>

                <Dialog onSubmit={() => {
                    setArrayList(prev => ([...prev?.filter(item => item.index !== selectDialog.item.index), selectDialog.item]?.sort((a, b) => a?.index - b?.index)))
                    setSelectDialog(prev => ({ ...prev, open: false }))
                }} open={selectDialog.open} title="Select Troop" onClose={closeSelectDialog}>
                    <div >
                        Selected Troop :  {allTroops?.find((item) => item?.id === selectDialog?.item?.troopId)?.name}
                    </div>
                    <PerfectScrollbar style={{ maxWidth: '100%' }} className='d-flex mt-2 pb-2'>
                        {allTroops?.map((troop: any) => (
                            <img onClick={() => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, troopId: troop.id } }))}
                                className={`select-troop ${selectDialog?.item?.troopId === troop.id && 'active'}`} src={troop.icon} />
                        ))}

                    </PerfectScrollbar>

                    <div className='d-flex mt-2 align-items-center'>
                        <div style={{ minWidth: 111 }} className='pe-2 fs-med mt-1'>
                            Minimum Level
                        </div>
                        <Slider
                            marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10, 11: 11, 12: 12 }}
                            max={12}
                            value={selectDialog?.item?.level}
                            min={1}
                            className={'mx-auto '}
                            onChange={(val) => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, level: val } }))}
                            style={{ width: ' 100%', maxWidth: 400 }}
                            step={1} />
                    </div>
                    <div className='mt-5 mb-2'>
                        Target Priority :
                    </div>
                    <div className='d-flex  align-items-center gap-2'>
                        <div onClick={(e) => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, targetPriority: 1 } }))}>
                            <Form.Check
                                type={"radio"}
                                value={1}
                                checked={selectDialog?.item?.targetPriority === 1}
                                label={`Closest`}
                            />
                        </div>
                        <div onClick={(e) => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, targetPriority: 2 } }))}>

                            <Form.Check
                                type={"radio"}
                                value={2}
                                checked={selectDialog?.item?.targetPriority === 2}
                                label={`Lowest HP`}
                            />
                        </div>
                        <div onClick={(e) => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, targetPriority: 3 } }))}>
                            <Form.Check
                                type={"radio"}
                                value={3}
                                checked={selectDialog?.item?.targetPriority === 3}
                                label={`Highest Damage`}
                            />
                        </div>

                    </div>
                    {arrayList?.find((item) => item?.index === selectDialog?.item?.index)?.troopId &&
                        <div style={{ marginBottom: -60 }} className='text-center mt-2 w-100'>
                            <Button onClick={() => {
                                setArrayList(prev => ([...prev?.filter(item => item.index !== selectDialog.item.index), { ...selectDialog.item, level: undefined, troopId: undefined, targetPriority: undefined }]?.sort((a, b) => a?.index - b?.index)))
                                setSelectDialog(prev => ({ ...prev, open: false }))
                            }} variant='wide-secondary' className='mx-auto' style={{ minWidth: 70 }} >
                                <img height={18} src={'/assets/images/icons/trash.svg'} />
                                Delete Troop
                            </Button>
                        </div>}
                </Dialog>
            </div >
        </div>

    )
}

export default JoinTreasure