import React, { useEffect, useRef, useState } from "react";
import { useBattleContext } from "../../context";
import { AnimatePresence, motion } from "framer-motion";
import { CustomIcon } from "../../../components/Icons";

type Props = {};

const MessageLogger = (props: Props) => {
  const { messages } = useBattleContext();
  const [messageQueue, setMessageQueue] = useState<
    Array<{ id: string; message: string }>
  >([]);

  const initialMessageCount = useRef(messages ? messages.length : 0); // Store initial message count

  useEffect(() => {
    if (messages && messages.length > initialMessageCount.current) {
      const newMessage = {
        id: Date.now().toString(),
        message: messages[messages.length - 1].message,
      };

      setMessageQueue((prevQueue) => [...prevQueue, newMessage]);

      // Set a timer to remove the message after 2 seconds
      setTimeout(() => {
        setMessageQueue((prevQueue) =>
          prevQueue.filter((msg) => msg.id !== newMessage.id)
        );
      }, 2000);
    }
  }, [messages]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 138,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
      }}
    >
      <AnimatePresence>
        {messageQueue.map((msg) => (
          <motion.div
            key={msg.id}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            style={{
              maxWidth: 360,
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              padding: "8px 16px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              color: "white",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
            className="d-flex align-items-center gap-2"
          >
            <CustomIcon
              icon={"notification"}
              type="png"
              width={20}
              height={20}
            />
            {msg.message}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default MessageLogger;
