import { ReactNode } from "react";
import { PlayerItemType } from "../items";

export enum targetAttribute {
  Health = 0,
  Armor = 1,
  Healing = 3,
  MagicResistance = 4,
  Damage = 5,
  Range = 6,
}
export const targetAttributeName = {
  0: "Health",
  1: "Armor",
  3: "Healing",
  4: "MagicResistance",
  5: "Damage",
  6: "Range",
};
export interface TroopType {
  id: number;
  name: string;
  image: string;
  icon: string;
  idleAnimation: string;
  attackAnimation: string;
  maxHealth: number;
  armor: number;
  magicResistance: number;
  trainable?: boolean;
  damage: number;
  damageType: number;
  range: number;
  clegCost: number;
  ironCost: number;
  stoneCost: number;
  requiredBarracksLevel: number;
  jsonData: string;
  abilities?:string;
}

export interface TroopItemsDialogStateType {
  open: boolean;
  troop: PlayerTroopType | undefined;
};


export interface PlayerTroopType {
  id: number;
  playerId: number;
  troopId: number;
  level: number;
  health: number;
  maxHealth: number;
  armor: number | ReactNode ;
  magicResistance: number | ReactNode;
  damage: number | ReactNode;
  damageType: number;
  range: number | ReactNode;
  state: PlayerTroopStateEnum;
  xp: number;
  targetPriority: number;
  lastUpdate: string;
  registerDate: string;
  inReserve: boolean;
  troop: TroopType;
  upgradeMinutes: number;
  instantUpgradeCost: number;
  hourlyHealing: number;
  marketOrderId: number;
  marketOrderPrice: number;
  maxXp: number;
  clegUpgradeCost?: number;
  clegLastUpgradeCost?: number;
  ironUpgradeCost?: number;
  ironLastUpgradeCost?: number;
  stoneUpgradeCost?: number;
  stoneLastUpgradeCost?: number;
  items?: PlayerItemType[];
  abilities?:string;
}

export enum PlayerTroopStateEnum {
  Ready = 1,
  Training = 2,
  Upgrading = 3,
  InAdventure = 4,
  Merged = 5,
  ForSale = 6,
}
