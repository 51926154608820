import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { SlotContext } from '../components/slot/context';
import { Actions } from '../types/enum';
import { useConnector } from '../connector/context';
import { Slot, Unit } from '../types';
import { useBattleContext } from '../context';
import { useSelector } from 'react-redux';
import useTriggerEvent from './useTriggerEvent';
import { EventNames } from '../utils/event-bus';

type Props = {}

const useGameLogic = () => {

    const { state, dispatch } = useContext(SlotContext);
    const { battleState, setBattleState } = useBattleContext()
    const { connector } = useConnector()
    const stateRef = useRef(battleState);
    const triggerEvent = useTriggerEvent<EventNames>();
    const userInfo = useSelector((state: any) => state.userInfo);
    useEffect(() => {
        stateRef.current = battleState;
    }, [battleState]);

    const selectUnit = (id: number) => {
        if (stateRef.current.selectedTroop?.playerTroopId === id) return;
        if (stateRef.current.selectedTroop) {
            const newUnit = stateRef.current.units.find(
                (unit) => unit.playerTroopId === id
            );

            return troopAction(stateRef.current.selectedTroop, { x: newUnit?.x!, y: newUnit?.y! });
        }
        const unit = stateRef.current.units.find(
            (unit) => unit.playerTroopId === id
        );
        setBattleState(prev => ({
            ...prev,
            selectedTroop: unit,
        }))
    }


    const troopAction = (unit: Unit, newPosition: { x: number, y: number }) => {
        if (!unit || !connector) return;
        const targetUnit = stateRef.current.units.find((u) => u.x === newPosition.x && u.y === newPosition.y);
        if (targetUnit && !targetUnit.isFriend) {
            // triggerEvent(EventNames.StartAttack, {
            //     modelId: unit.playerTroopId + "",
            // });
        };
        connector.action(stateRef.current.battleId, {
            playerId: userInfo.id,
            playerTroopId: unit.playerTroopId,
            targetX: newPosition.x,
            targetY:  newPosition.y,
        });
        setBattleState((prevState) => ({
            ...prevState,
            selectedTroop: undefined,
        }));
    };

    const updateModelPosition = useCallback((modelId: string, destinationSlotId: string, prevSlot: Slot, lookAt?: THREE.Vector3, rotation?: THREE.Euler) => {
        const slot = state.slots.find(({ id }) => id === destinationSlotId);
        if (!slot || slot.modelId) return;
        dispatch({
            type: Actions.MOVE_MODEL, payload: {
                id: modelId,
                slotId: slot.id,
                lookAt,
                rotation,
            }
        });
        dispatch({
            type: Actions.UPDATE_SLOT, payload: {
                id: slot.id, position: slot.position, modelId: modelId,
            }
        });
        dispatch({
            type: Actions.UPDATE_SLOT, payload: {
                ...prevSlot, modelId: undefined,
            }
        });
    }, [dispatch, state]);

    function selectSlot(id: string) {
        dispatch({ type: Actions.SELECT_SLOT, payload: { id, selected: true } });
    }

    function deselectSlot(id: string) {
        dispatch({ type: Actions.SELECT_SLOT, payload: { id, selected: false } });
    }


    function removeSlot(id: string) {
        // dispatch({ type: Actions.REMOVE_SLOT, payload: { id } });
    }

    return {
        updateModelPosition,
        selectSlot,
        deselectSlot,
        removeSlot,
        state,
        selectUnit,
        troopAction
    }

}

export default useGameLogic