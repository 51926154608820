import React, { useEffect, useState } from "react";
import ArenaNavigator from "../components/home";
import * as Sentry from "@sentry/react";
import ArenaHomeLayout from "../layouts/arena-layout";
function FallbackComponent() {
  return <ArenaHomeLayout error > 
    
  </ArenaHomeLayout>;
}
//FIXME: Delete this FIle


type Props = {};

const GameNavigator = (props: Props) => {
  return (
    <div>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <ArenaNavigator />
      </Sentry.ErrorBoundary>
    </div>
  );
};

export default GameNavigator;
