import React from "react";
import { useModelLoader } from "../context/model-loader-context";
import { ProgressBar } from "react-bootstrap";
import modelsData from "../data/models";
import { AnimatePresence,motion } from "framer-motion";
import { roundFloat } from "../../utils/round-float";

type Props = {};

const LoaderProgress = (props: Props) => {
  const { models, progress } = useModelLoader();
  const now = (models.length * 100) / Object.keys(modelsData).length;
  return (
    <AnimatePresence exitBeforeEnter>
      {now < 100 && (
        <motion.div
        key={"loader"}
        initial={{y:200}}
        animate={{y:0,scale:1}}
        exit={{scale:0}}
        transition={{duration: 0.5,delay:.2}}
          style={{ bottom: 10,right:0,left:0 ,width:'fit-content',zIndex:1000 }}
          className="position-fixed bg-3 bg-blur rounded py-2 px-2  mx-auto  text-center "
        >
          <ProgressBar
            className="progress-dark-orange"
            variant="warning"
            style={{
              minWidth: 150,
              height: 15,
              color: "#000",
              background: "rgba(0,0,0,.2)",
            }}
            now={now}
            label={`${roundFloat(now, 0)}%`}
          />
          <div className="text-white mt-1 fs-small">
            Loading Models...
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoaderProgress;
