import React, { useEffect, useState } from 'react'
import { PlayerItemType } from '../../types/items'
import Network from '../../utils/Network'
import ReactPaginate from 'react-paginate'
import MidBox from '../../components/Boxes/MidBox'
import Button from '../../components/Button'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

type Props = {}

const GameUpdates = (props: Props) => {
    const axios = new Network();
    const [data, setData] = React.useState<any>([]);
    const [level, setLevel] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [paginations, setPaginations] = useState({ page: 1, count: 10, total: 1 })

    const handleGetData = async (page = 1) => {
        setLoading(true)
        const response = await axios.CallApi({
            url: '/notification/gameupdatelist?count=' + 15 + `&page=${page}`,
            method: 'get'
        })
        setData(response.data?.data)
        setPaginations({ total: Math.ceil(response.data.totalCount / 15), page, count: 15 })
        setLoading(false)
    }

    useEffect(() => {
        handleGetData()
    }, [])

    return (
        <SkeletonTheme enableAnimation={!isMobile} baseColor="#352d22" highlightColor="#3e362d">
            <div>
                <MidBox modal className='mt-5' title="Game Updates">
                    <div style={{ overflow: 'auto' }} className="pe-1 pt-3 pb-5 ps-1 pe-sm-3 ps-sm-3 pb-4 table-box">
                        <table className="table table-borderless m-0 text-white" > <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Title</th>
                                <th scope="col">Message</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                            <tbody>
                                {loading && [1, 2, 3].map((item) =>
                                    <tr key={"loading" + item} className='pointer'
                                    >
                                        <td width={"50%"}>
                                            <Skeleton width={'100%'} />
                                        </td>
                                        <td> <Skeleton width={'60px'} /> </td>
                                        <td> <Skeleton width={'60px'} /></td>
                                        <td >
                                            <Skeleton width={'100px'}
                                                height={25}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {data.length === 0 && !loading && <tr>
                                    <td colSpan={4} style={{ textAlign: 'center', paddingTop: 40, fontSize: 20, paddingBottom: 40, }} className="dark-yellow">
                                        You have no notification here ...
                                    </td>
                                </tr>}
                                {!loading && data?.map((item, key) => (
                                    <>


                                        <tr className='pointer'

                                            // style={{ opacity: !item?.isRead ? 1 : 0.7 }}
                                            key={"row" + key}>
                                            <td>
                                                {item.iconUrl && <img src={item?.iconUrl} width={40} />}
                                            </td>
                                            <td >
                                                <div className='fs-lg'>
                                                    {item.title}
                                                </div>
                                            </td>

                                            <td style={{ minWidth: 200 }}>

                                                <span
                                                    className='fs-big'
                                                >
                                                    {!item?.isRead && <div className='badge-dot' />}   {item?.message}</span></td>

                                            <td>{moment(new Date(item?.registerDate)).format('HH:mm - MM/DD/YYYY')}</td>

                                        </tr>

                                    </>
                                ))}

                            </tbody>
                        </table>
                        <br />
                        <br />
                        <ReactPaginate
                            className='pagination justify-content-center'
                            activeClassName='active-pg'
                            pageClassName='page-item'
                            pageLinkClassName='page-link'
                            breakClassName='page-item'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            breakLinkClassName='page-link'
                            previousLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            onPageChange={(e) => handleGetData(e.selected + 1)}
                            pageRangeDisplayed={3}
                            pageCount={paginations.total}
                        // renderOnZeroPageCount={null}
                        />
                    </div>
                </MidBox>
            </div>
        </SkeletonTheme>
    )
}

export default GameUpdates