import React, { useEffect, useState } from 'react'
import Dialog from './Dialog'
import { PlayerTroopType } from '../types/troop';
import { Col, Row } from 'react-bootstrap';
import Network from '../utils/Network';
import { ItemBonusStat, ItemSlotEnum, ItemSlotNames, ItemStateEnum, PlayerItemType } from '../types/items';
import useToast from '../hooks/useToast';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LevelBadge from './LevelBadge';
import { roundFloat } from '../utils/round-float';
import { CustomIcon } from './Icons';
import { motion } from 'framer-motion'
import CircularProgress from './CircularProgress';
type Props = {
  open: boolean;
  onClose: () => void;
  troop: PlayerTroopType | undefined;
  onRefresh: () => void;

}
type SlotType = {
  [ItemSlotEnum.Helmet]: PlayerItemType | undefined;
  [ItemSlotEnum.Chest]: PlayerItemType | undefined;
  [ItemSlotEnum.Legs]: PlayerItemType | undefined;
  [ItemSlotEnum.Boots]: PlayerItemType | undefined;
  [ItemSlotEnum.Gloves]: PlayerItemType | undefined;
  [ItemSlotEnum.Weapon]: PlayerItemType | undefined;
}

const TroopItemsDialog = ({ open, onClose, troop, onRefresh }: Props) => {
  const [fetchLoading, setFetchLoading] = useState(false)
  const [useLoading, setUseLoading] = useState(false)
  const [unuseLoading, setUnuseLoading] = useState(false)
  const [playerItems, setPlayerItems] = useState<PlayerItemType[] | undefined>();
  const [slots, setSlots] = useState<SlotType>({
    [ItemSlotEnum.Helmet]: undefined,
    [ItemSlotEnum.Chest]: undefined,
    [ItemSlotEnum.Legs]: undefined,
    [ItemSlotEnum.Boots]: undefined,
    [ItemSlotEnum.Gloves]: undefined,
    [ItemSlotEnum.Weapon]: undefined,
  });
  const [selectSlot, setSelectSlot] = useState<ItemSlotEnum | undefined>();
  const axios = new Network();
  const openToast = useToast()
  const handleGetAllItems = React.useCallback(async (loading?: boolean) => {

    if (loading) setFetchLoading(true)
    const response = await axios.CallApi({
      url: `/playeritem?states=${ItemStateEnum.Ready}&page=1&count=500`,
      method: 'get'
    })
    setFetchLoading(false)
    if (!response.ok) return openToast(response)
    setPlayerItems(response?.data?.data)
  }, [])

  useEffect(() => {
    if (open && troop) {
      if (troop.items?.length !== 0) {
        let newSlots: SlotType | {} = {};
        for (let key in troop.items) {
          newSlots[troop.items[key].item?.slot] = troop.items[key]
        }
        setSlots(prev => ({ ...prev, ...newSlots }))
      }
    }
  }, [open, troop])
  const handleUseItem = async (item: PlayerItemType, troop: PlayerTroopType, callBack: () => void) => {
    setUseLoading(true)
    const response = await axios.CallApi({
      url: `/playeritem/${item.id}/use?PlayerTroopId=${troop.id}`,
      method: 'get'
    })
    openToast(response)
    if (response.ok && !response.data?.error) {
      callBack()
    }
    setUseLoading(false)

  }

  const handleUnUseItem = async (item: PlayerItemType, callBack: () => void) => {
    setUnuseLoading(true)
    const response = await axios.CallApi({
      url: `/playeritem/${item.id}/unuse`,
      method: 'delete'
    })
    openToast(response)
    if (response.ok && !response.data?.error) {
      callBack()
    }
    setUnuseLoading(false)

  }



  const toggleSelect = (slot: ItemSlotEnum) => {
    if (slot === selectSlot) {
      setSelectSlot(undefined)
    }
    else {
      setSelectSlot(slot)

    }
  }
  function handleSelect(item: PlayerItemType) {
    if (!useLoading && selectSlot) {
      handleUseItem(item, troop!, () => {
        setSlots(prev => ({
          ...prev!,
          [selectSlot]: item,
        }));
        setSelectSlot(undefined);
        handleGetAllItems()
      })
    }
  }



  useEffect(() => {
    if (open && !playerItems) {
      handleGetAllItems(true)
    }
  }, [open])

  const filteredItems = React.useMemo(() => {
    if (playerItems && selectSlot)
      return playerItems.filter((item) => item.item.slot === selectSlot && item.item.troopIds.split(',').find(troopId => +troopId === troop?.troopId))?.sort((a, b) => b.level - a.level);

    return playerItems?.sort((a, b) => b.level - a.level)
  }, [playerItems, selectSlot])

  const getItemSlot = (slot: ItemSlotEnum) => {
    if (slots?.[slot]) {
      return <>
        <img src={slots?.[slot]?.image} style={{ maxHeight: '90%', maxWidth: '80%' }} />
        <CustomIcon
          onClick={() => handleUnUseItem(slots?.[slot]!,
            () => {
              setSlots((prev) => ({ ...prev, [slot]: undefined }));
              handleGetAllItems()
            })
          }
          icon="remove" width={50} height={55} className='button remove-item'
        />
      </>
    }
    else return <>
      <CustomIcon onClick={() => toggleSelect(slot)} icon="add" width={70} height={70} className='button' />
    </>
  }

  return (<Dialog title={troop?.troop?.name} size='lg' open={open} onClose={onClose}>
    <div style={{ marginBottom: -40 }}>
      <Row className=''>
        <Col md={6} xs={12}>
          <Row >
            <Col xs={4} >
              <div className='circle p-relative p-2 bg-3 text-center'>
                <img className='circle' src={troop?.troop.icon} width={70} height={70} style={{ objectFit: 'cover' }} />
                <LevelBadge level={troop?.level!} />
              </div>
            </Col>
            <Col xs={4} >
              <div className={'item-frame ' + (selectSlot === ItemSlotEnum.Helmet && 'active-slot')}>
                {getItemSlot(ItemSlotEnum.Helmet)}
              </div>
            </Col>
            <Col xs={4} />
            <Col xs={4} >
              <div className={'item-frame ' + (selectSlot === ItemSlotEnum.Weapon && 'active-slot')}>
                {getItemSlot(ItemSlotEnum.Weapon)}
              </div>
            </Col>
            <Col xs={4} >
              <div className={'item-frame ' + (selectSlot === ItemSlotEnum.Chest && 'active-slot')}>
                {getItemSlot(ItemSlotEnum.Chest)}
              </div>
            </Col>
            <Col xs={4} >
              <div className={'item-frame ' + (selectSlot === ItemSlotEnum.Gloves && 'active-slot')}>
                {getItemSlot(ItemSlotEnum.Gloves)}
              </div>
            </Col>
            <Col xs={4} />
            <Col xs={4} >
              <div className={'item-frame ' + (selectSlot === ItemSlotEnum.Legs && 'active-slot')}>
                {getItemSlot(ItemSlotEnum.Legs)}
              </div>
            </Col>
            <Col xs={4} />
            <Col xs={4} />
            <Col xs={4} >
              <div className={'item-frame ' + (selectSlot === ItemSlotEnum.Boots && 'active-slot')}>
                {getItemSlot(ItemSlotEnum.Boots)}
              </div>
            </Col>
            <Col xs={4} />
          </Row>
        </Col>
        <Col md={6} xs={12}>
          <PerfectScrollbar style={{ maxHeight: 440 }}>
            <div className='d-flex ps-3 gap-2 flex-column'>
              {selectSlot && <motion.div initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: 'auto' }} className='fs-lg'>
                Select  {ItemSlotNames[selectSlot]}
              </motion.div>}
              {!selectSlot && <motion.div
                initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }}
                className='p-2 rounded bg-3 d-flex align-items-center  item-list'>
                  <CustomIcon icon="info" width={20} height={20} className='me-2 scaleX' />
                Please Select a slot
              </motion.div>}
              {fetchLoading && <div style={{ minHeight: 400 }} className='d-flex justify-content-center align-items-center'>
                <CircularProgress />
              </div>}
              {selectSlot && filteredItems && filteredItems.length === 0 &&
                <div
                  className='transition'
                  style={{
                    opacity: useLoading ? 0.5 : 'unset'
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }}
                    className='p-2 rounded bg-3 d-flex align-items-center  item-list'>
                    {selectSlot ? <>
                      <CustomIcon icon="warning" className='me-2 scaleX' />
                      Sorry there is no item matching with {troop?.troop.name} {selectSlot && ItemSlotNames[selectSlot]}
                    </> : <>
                      Sorry can not find any items!
                    </>
                    }
                  </motion.div>
                </div>
              }
              {selectSlot && filteredItems?.map((item, key) => (
                <div
                  className='transition'
                  style={{
                    opacity: useLoading ? 0.5 : 'unset'
                  }}
                >
                  <motion.div

                    onClick={() => handleSelect(item)} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: key * 0.02, duration: 0.2 }} className='p-2 rounded bg-3 d-flex align-items-center pointer item-list'>
                    <img src={item.image} width={70} alt={item.item.name} />
                    <div>
                      <div className='fs-med bold'>
                        {item.item.name} (Level {item.level})
                      </div>
                      <div>
                        + {roundFloat(item.bonusValue)}  {ItemBonusStat[item.item.bonusStat]}
                      </div>
                    </div>
                  </motion.div>
                </div>
              ))}

            </div>
          </PerfectScrollbar>
        </Col>
      </Row>
    </div>
  </Dialog>
  )
}

export default TroopItemsDialog;