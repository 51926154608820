import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { roundFloat } from "../utils/round-float";
import useToast from "../hooks/useToast";
import { useLocation } from "react-router-dom";

type Props = {
  children: React.ReactElement;
};

const SoundContainer = (props: Props) => {
  var AudioContext = window.AudioContext || (window as any).webkitAudioContext;
  let audioCtx = useRef(new AudioContext())?.current;
  const userSettings = useSelector((state: any) => state.userSettings);
  const isPlayable = useSelector((state: any) => state.audioPlayable);
  let gainNode = audioCtx.createGain();
  let bufferSource = audioCtx.createBufferSource();
  const AudioBufferState = useRef<any>();
  const IsFirst = useRef(true);
  const openToast = useToast();
  const isPlayableRef = useRef(isPlayable);

  useEffect(() => {
    isPlayableRef.current = isPlayable;
  }, [isPlayable]);

  function playSound(audioBuffer: any) {
    gainNode.gain.value = parseFloat(
      roundFloat(userSettings?.backgroundMusicVolume / 10, 1)
    );
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(gainNode).connect(audioCtx.destination);
    gainNode.connect(audioCtx.destination);
    bufferSource.loop = true;
    if (isPlayableRef.current) {
      bufferSource.start(0);
    }
  }

  function initSound() {
    if (userSettings?.backgroundMusicVolume > 0)
      fetch("/sounds/background-music.bgmc")
        .then((resp) => resp.arrayBuffer())
        .then((arrayBuffer) => audioCtx.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          playSound(audioBuffer);
          AudioBufferState.current = audioBuffer;
        });
  }

  function handleDetectRoute() {
    if (
      window.location.pathname.includes("replay") ||
      window.location.pathname.includes("/battle/order") ||
      window.location.pathname.includes("/battle/treasure")
    ) {
      if (audioCtx.state === "running") {
        audioCtx.suspend();
        audioCtx.close();
      }
    } else {
      handleResume();
    }
  }
  const handleStop = () => {
    if (audioCtx.state !== "closed") {
      audioCtx.suspend();
    }
  };
  const handleResume = () => {
    audioCtx.resume();
  };

  useEffect(() => {
    handleDetectRoute();
  }, [isPlayableRef.current, isPlayable]);

  useEffect(() => {
    if (!IsFirst.current) return;
    IsFirst.current = false;
    setTimeout(() => {
      if (document.visibilityState === "visible") initSound();
    }, 5000);

    document.addEventListener("visibilitychange", (evt) => {
      if (document.hidden) {
        // bufferSource.stop(audioCtx.currentTime)
        // bufferSource.disconnect()
        handleStop();
      } else {
        if (userSettings?.backgroundMusicVolume == 0) return;
        if (
          window.location.pathname.includes("/replay") ||
          window.location.pathname.includes("/battle/order") ||
          window.location.pathname.includes("/battle/treasure")
        )
          return;
        !AudioBufferState?.current ? initSound() : handleResume();

        // const currenctTime = audioCtx.currentTime
        // audioCtx.close();
        // audioCtx = new AudioContext()
        // gainNode = audioCtx.createGain();
        // bufferSource = audioCtx.createBufferSource();
        // console.log(parseInt(currenctTime?.toString()))
        // bufferSource = audioCtx.createBufferSource()
        // gainNode.gain.value = parseFloat(roundFloat(userSettings?.backgroundMusicVolume / 10, 1))
        // bufferSource.buffer = audioBuffer
        // bufferSource.connect(gainNode).connect(audioCtx.destination);
        // gainNode.connect(audioCtx.destination)
        // bufferSource.start()
      }
    });
    //  return()=> bufferSource.stop(0);
  }, []);
  // useEffect(()=>{
  //     gainNode.gain.value = userSettings?.backgroundMusicVolume /10
  // },[userSettings])

  // useEffect(() => {
  //     setTimeout(() => {
  //         openToast({ ok: false, message: "This is beta test version. Game Speed is 300x. Tokens, resources, and assets have no real value and they are only for test purposes." }, "warning")
  //     }, 5000)
  // }, [])

  return props.children;
};

export default SoundContainer;
