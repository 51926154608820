import React, { useState } from 'react'
import Dialog from './Dialog';
import Slider from 'rc-slider';
import Button from './Button';

type Props = {
    value?: number | undefined;
    onChange: (val: number | number[]) => void;
    readOnly?: boolean;
}

const LeaderShare = (props: Props) => {
    const [dialog, setDialog] = useState(false)
    return (<>
        <div onClick={() => {
            if (props.readOnly) return;
            setDialog(true)
        }} className='leader-share gap-2'>
            <img width={28} src="/assets/images/icons/treasure.png" />
            Leader Share :  {props.value}%
        </div>
        <Dialog title='Leader Share' noFooter open={dialog} onClose={() => setDialog(false)}>
            <div>
                <span>

                </span>
                <Slider
                    marks={{ 1: 1, 5: 5, 10: 10, 15: 15, 20: 20, 25: 25, 30: 30, }}
                    max={30}
                    value={props.value}
                    min={0}
                    className={'mx-auto '}
                    onChange={(val) => props.onChange(val)}
                    style={{ width: ' 100%', maxWidth: 400 }}
                    step={1} />
                <br />
                <div className='dark-brown mt-3 fs-med'>
                    Decide a share of perks that you think you deserve for your Leadership skills!
                </div>
                <div className='text-center'>
                    <Button onClick={() => setDialog(false)} style={{ marginBottom: -50, zIndex: 200, position: 'relative' }} className='mt-2 mx-auto' variant="primary">
                        Done
                    </Button>
                </div>

            </div>
        </Dialog>
    </>
    )
}

export default LeaderShare