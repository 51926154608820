import React from 'react'
import Countdown from 'react-countdown';
import { Link, useParams } from 'react-router-dom';
import Button from '../components/Button';
import Network from '../utils/Network';
import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect';
import CircularProgress from '../components/CircularProgress';
import { Helmet } from 'react-helmet';
const DungeonWating = () => {
    const { id } = useParams()

    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState(true)
    const axios = new Network()
    const handleGetData = async () => {
        const response = await axios.CallApi({
            url: '/adventure/' + id,
            method: 'get'
        })
        setData(response?.data)


        setLoading(false)
    }
    React.useEffect(() => {
        handleGetData()
    }, [id])
    return (
        <div style={{  paddingBottom:60}} className="d-flex align-items-center justify-content-center">
               <Helmet>
                <title key={'title'}>
                    Adventure Pending | Chain Of Legends
                </title>
            </Helmet>
            <video
                controls={false}
                loop
                autoPlay
                poster='/assets/images/backgrounds/wv1-poster.jpg'
                className='video-bg'
                src={isMobile ? '/videos/wv1-mobile.mp4' : '/videos/wv1.mp4'}
            />

            {!loading ? <motion.div
                exit={{ opacity: 0, y: 200 }}
                transition={{ duration: 0.4 }}
                initial={isMobile ? undefined : { opacity: 0, y: 200 }}
                animate={{ opacity: 1, y: 0 }}
                className='w-box'>
                <div className='w-100 mb-2 text-center'>
                    <img width={75} src={`/assets/images/icons/dungeon/${data?.treasure?.id}.png`} />
                </div>
                <div className='row flex-column'>

                    <div className='d-flex mb-2 align-items-center justify-content-between'>
                        {/* <img width={80} height={90} src={}/> */}
                        <span>
                            <img src={"/assets/images/icons/timer.png"} width={25} height={25} />  Arrival time
                        </span>
                        <Countdown
                            date={new Date(data?.arriveDate)}
                            renderer={({ formatted, days }) => (
                                <div className='fs-big d-flex'>
                                    {days ?
                                        <div className='time-badge me-2'>
                                            {formatted.days}
                                            <span className='dark-yellow'>
                                                Days
                                            </span>
                                        </div> : ""}
                                    <div className='time-badge'>
                                        {formatted.hours}
                                        <span className='dark-yellow'>
                                            Hours
                                        </span>
                                    </div>
                                    <div className='ms-2 time-badge'>
                                        {formatted.minutes}
                                        <span className='dark-yellow'>
                                            Minutes
                                        </span>
                                    </div>
                                    <div className='ms-2 time-badge'>
                                        {formatted.seconds}
                                        <span className='dark-yellow'>
                                            Seconds
                                        </span>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <hr />
                    <div className='d-flex mb-2 align-items-center justify-content-between'>
                        {/* <img width={80} height={90} src={}/> */}
                        <span>
                            <img src={"/assets/images/icons/timer.png"} width={25} height={25} />  Return time &nbsp;
                        </span>
                        <Countdown
                            date={new Date(data?.returnDate)}
                            onComplete={handleGetData}
                            renderer={({ formatted, days }) => (
                                <div className='fs-big d-flex'>
                                    {days ?
                                        <div className='time-badge me-2'>
                                            {formatted.days}
                                            <span className='dark-yellow'>
                                                Days
                                            </span>
                                        </div> : ""}
                                    <div className='time-badge'>
                                        {formatted.hours}
                                        <span className='dark-yellow'>
                                            Hours
                                        </span>
                                    </div>
                                    <div className='ms-2 time-badge'>
                                        {formatted.minutes}
                                        <span className='dark-yellow'>
                                            Minutes
                                        </span>
                                    </div>
                                    <div className='ms-2 time-badge'>
                                        {formatted.seconds}
                                        <span className='dark-yellow'>
                                            Seconds
                                        </span>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <div className='text-center'>
                        {data?.state === 4 ?
                            <Link to={"/battle/replay/"+id}>
                                <Button className='mx-auto mt-2' variant='wide-primary'>
                                    Replay
                                </Button>
                            </Link>
                            : <Link to={"/adventure"}>
                                <Button className='mx-auto mt-2' variant='wide-primary'>
                                    Adventures
                                </Button>
                            </Link>}

                    </div>


                </div>
            </motion.div>:
            <motion.div
            exit={{ opacity: 0, y: 200 }}
            transition={{ duration: 0.4 }}
            initial={isMobile ? undefined : { opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            
            style={{width:'fit-content',position:'absolute',right:0,left:0,marginInline:'auto',top:'50%',zIndex:200}}>
                <CircularProgress/>
            </motion.div>
            }
        </div>
    );
}

export default DungeonWating;