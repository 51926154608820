import React from "react";
import { CustomIcon } from "./Icons";
import { getProfileURL } from "../utils/profile-url";
import Button from "./Button";
import Network from "../utils/Network";
import { GuildPlayerState } from "../pages/Guild/data";
import { GuildPlayerStateEnum } from "../pages/Guild/types";
import truncateString from "../utils/truncate-string";
import { Link } from "react-router-dom";

interface GuildPlayer {
  joinDate: string;
  state: number;
  playerId: number;
  username: string;
  score: number;
  photoFile: string;
  rank: number;
}

type Props = {
  guildId: number;
  isOwner?: boolean;
  playerRole: GuildPlayerStateEnum;
  onChangeRole: (state: number, id: number) => void;
  onKick: (id: number) => void;
  onAccept: (id: number) => void;
  onReject: (id: number) => void;
};

const roleData = {
  [GuildPlayerStateEnum.Owner]: {
    image: "https://core.chainoflegends.com/assets/troops/image/knight.webp",
    title: "Lord",
  },
  [GuildPlayerStateEnum.Admin]: {
    image: "https://core.chainoflegends.com/assets/troops/image/mage.webp",
    title: "Commander",
  },
  [GuildPlayerStateEnum.Member]: {
    image: "https://core.chainoflegends.com/assets/troops/image/warrior.webp",
    title: "Sergeant",
  },
};

const GuildMemberBox = ({
  guildId,
  playerRole,
  onChangeRole,
  onAccept,
  onReject,
  ...props
}: Props & GuildPlayer) => {
  const axios = new Network();

  return (
    <div className="row bg-2 border border-dark rounded m-0  p-2">
      <div className="col-8 p-2">
        <div className="d-flex  gap-3 align-items-center">
          <Link to={"/profile/" + props.playerId}>
            <div
              style={{ backgroundImage: "url(/assets/images/avatar-bg.png)" }}
              className={"main-avatar"}
            >
              {props?.photoFile && (
                <img
                  src={getProfileURL(props?.playerId, props?.photoFile)}
                  width={"70%"}
                  height={"70%"}
                  style={{
                    position: "absolute",
                    borderRadius: "50%",
                    top: "12%",
                    right: 0,
                    left: "12%",
                  }}
                />
              )}
            </div>
          </Link>
          <div>
            <Link to={"/profile/" + props.playerId}>
              <h6
                style={{ maxWidth: "140px" }}
                className="text-truncate text-white d-block overflow-hidden w-100"
              >
                {props?.username?.startsWith("0x")
                  ? truncateString(props?.username)
                  : props?.username}
              </h6>
            </Link>
            <p>
              {props?.state > GuildPlayerStateEnum.Member
                ? GuildPlayerState[props?.state]
                : roleData[props?.state].title}
            </p>
          </div>
        </div>
      </div>
      <div className="col-4 d-flex align-items-center justify-content-end">
        {props?.state > GuildPlayerStateEnum.Member ? (
          <div style={{ width: 110 }}></div>
        ) : (
          <img
            style={{ maxHeight: 100,maxWidth:120, objectFit: "contain" }}
            src={roleData[props.state].image}
          />
        )}
      </div>
      <div className="col-12 col-md-6 d-flex">
        <div className="d-flex gap-2 flex-column">
          <div className="d-flex gap-2  align-items-center">
            <CustomIcon width={35} icon="crown" />
            Rank: #{props?.rank}
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 text-end">
        {Boolean(playerRole) &&
          playerRole < GuildPlayerStateEnum.Member &&
          props?.state <= GuildPlayerStateEnum.Member &&
          props?.state !== GuildPlayerStateEnum.Owner && (
            <div className="d-flex">
              {props.isOwner && (
                <Button
                  onClick={() => onChangeRole(props?.state, props?.playerId)}
                  size="small"
                  variant="primary"
                >
                  Change Role
                </Button>
              )}
              <Button
                onClick={() => props.onKick(props?.playerId)}
                size="small"
                variant="secondary"
              >
                Kick
              </Button>
            </div>
          )}
        {Boolean(playerRole) &&
          playerRole < GuildPlayerStateEnum.Member &&
          props?.state === GuildPlayerStateEnum.JoinRequest && (
            <div className="d-flex">
              <Button
                onClick={() => onAccept(props?.playerId)}
                size="small"
                variant="primary"
              >
                Accept
              </Button>
              <Button
                onClick={() => onReject(props?.playerId)}
                size="small"
                variant="secondary"
              >
                Reject
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export default GuildMemberBox;
