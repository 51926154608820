import React from 'react'
import MidBox from '../components/Boxes/MidBox'
import Button from '../components/Button'

type Props = {}

const Trade = (props: Props) => {
    return (<div className="container-lg">
        <div className="row">
            <div className="col-12 col-md-4">
                <MidBox title="Order Book">
                    <div className=" text-start">
                        <label className="mb-3 fw-med fs-med text-light">
                            SELL ORDERS
                        </label>
                        <table className="table table-borderless m-0">
                            <thead>
                                <tr>
                                    <th className="text-light" scope="col">Price</th>
                                    <th className="text-light" scope="col">Token</th>
                                    <th className="text-light" scope="col">In BUSD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="color-red">1500</td>
                                    <td>CLEG</td>
                                    <td>$125</td>
                                </tr>
                                <tr>
                                    <td className="color-red">1500</td>
                                    <td>CLEG</td>
                                    <td>$125</td>
                                </tr>
                                <tr className="selected">
                                    <td className="color-red">1500</td>
                                    <td>CLEG</td>
                                    <td>$125</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="text-center my-3">
                            <img className="img-fluid" src="/assets/images/brown-sep.png" />
                            <div className="py-3"><span className="fw-med fs-med text-light">0.934825 ≈ 0.934918 USD</span></div>
                            <img className="img-fluid" src="/assets/images/brown-sep.png" />
                        </div>
                        <label className="mb-3 fw-med fs-med text-light">
                            BUY ORDERS
                        </label>
                        <table className="table table-borderless m-0">
                            <thead>
                                <tr>
                                    <th className="text-light" scope="col">Price</th>
                                    <th className="text-light" scope="col">Token</th>
                                    <th className="text-light" scope="col">In BUSD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="teal-color">1500</td>
                                    <td>CLEG</td>
                                    <td>$125</td>
                                </tr>
                                <tr>
                                    <td className="teal-color">1500</td>
                                    <td>CLEG</td>
                                    <td>$125</td>
                                </tr>
                                <tr className="selected">
                                    <td className="teal-color">1500</td>
                                    <td>CLEG</td>
                                    <td>$125</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </MidBox>

            </div>
            <div className="col-12 col-md-8 aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="750">
                <div className="row">
                    <div className="col-12 col-md-12 mb-2 mb-md-3">
                        <MidBox title='' noTitle modal>

                            <div className="px-3">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div className="text-center">
                                            <img className="img-fluid s-small  mb-1" src="/assets/images/archer-circular.png" />
                                            <div className="h6 fw-bold mb-2">
                                                Level<div className="title-box s-micro fw-bold text-light mx-2">
                                                    <img width={"35px"} height={"36px"} className="img-fluid" src="/assets/images/buttons/active-btn.png" />
                                                    <div className="b-text">
                                                        <span className="text-light d-block">1</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dark-bg-light position-relative pe-2 px-4 py-3 mb-4 mb-lg-0">
                                            <h4 className="fw-med fs-small dark-brown left-border mb-1">THE ARCHER</h4>
                                            <h3 className="fw-med fs-big m-0">ARCHER GHOST</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-8">
                                        <div className="d-flex">
                                            <button className="theme-btn btn-exsmall btn-tab-style selected-arrow">
                                                <img className="img-fluid" src="/assets/images/buttons/off-tab-btn.png" />
                                                <div className="b-text">
                                                    <span className="text-light fw-med fs-big">Info</span>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="dark-bg-light rounded-10 p-3 p-lg-4">
                                            <h4 className="h6 fw-med text-shadow color-orange mb-3">THE ARCHER</h4>
                                            <p className="fs-small text-shadow text-light mb-0">Iron ores are rocks and minerals from which metallic iron can be economically extracted. The ores are usually rich in iron oxides and vary in color from dark grey, bright yellow, or deep purple to rusty red. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MidBox>

                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <MidBox containerClass='py-lg-0 py-0' noTitle>
                            <div >
                                <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-3 mb-2">
                                    <div className="dark-yellow fw-med fs-med">Price : 1500</div>
                                    <div className="text-light fw-normal fs-small">CLEG</div>
                                </div>
                                <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-2 mb-4">
                                    <div className="dark-yellow fw-med fs-med my-2">QUANTITY : 1</div>
                                    <div className="d-flex flex-column justify-content-between">
                                        <button className="theme-btn s-nano lh-1">
                                            <img width={20} className="img-fluid" src="/assets/images/icons/arrow-up.png" />
                                        </button>
                                        <button className="theme-btn s-nano lh-1">
                                            <img width={20} className="img-fluid" src="/assets/images/icons/arrow-down.png" />
                                        </button>
                                    </div>
                                </div>

                                <button className="theme-btn button py-0 btn-large px-0">
                                    <img className="img-fluid" src="/assets/images/buttons/blue-btn-wide.png" />
                                    <div className="b-text">
                                        <span className="text-light  d-block">BUY</span>
                                    </div>
                                </button>
                            </div>
                        </MidBox>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <MidBox noTitle>
                            <div>
                                <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-3 mb-2">
                                    <div className="dark-yellow fw-med fs-med">Price : 1500</div>
                                    <div className="text-light fw-normal fs-small">CLEG</div>
                                </div>
                                <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-2 mb-4">
                                    <div className="dark-yellow fw-med fs-med my-2">QUANTITY : 1</div>
                                    <div className="d-flex flex-column justify-content-between">
                                        <button className="theme-btn s-nano lh-1">
                                            <img width={20} className="img-fluid" src="/assets/images/icons/arrow-up.png" />
                                        </button>
                                        <button className="theme-btn s-nano lh-1">
                                            <img width={20} className="img-fluid" src="/assets/images/icons/arrow-down.png" />
                                        </button>
                                    </div>
                                </div>
                                <button className="theme-btn button py-0 btn-large px-0">
                                    <img className="img-fluid" width={"100%"} src="/assets/images/buttons/brown-btn-wide.png" />
                                    <div className="b-text">
                                        <span className="text-light  d-block">Sell</span>
                                    </div>
                                </button>
                            </div>
                        </MidBox>

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Trade