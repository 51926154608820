import Slider from "rc-slider";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import OneSignalReact from "react-onesignal";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../hooks/useToast";
import { setSettings } from "../redux/settings/action";
import Button from "./Button";
import Dialog from "./Dialog";
import { GoogleLogin as ReactGoogleLogin } from "@react-oauth/google";
import Cropper, { ReactCropperElement, ReactCropperProps } from "react-cropper";
import "cropperjs/dist/cropper.css";
import Network, { IsBeta, ProfileURL } from "../utils/Network";
import Connector from "./Connector";
import { setUserInfo } from "../redux/user/action";
import useQuests from "../hooks/useQuests";
import { QuestAction, QuestState } from "../types/quests/indext";

type Props = {
  open: boolean;
  onClose: Function;
};

const SettingsDialog = (props: Props) => {
  const userSettings = useSelector((state: any) => state.userSettings);
  const userInfo = useSelector((state: any) => state.userInfo);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [connectorDialog, setConnectorDialog] = useState(false);
  const [cropDialog, setCropDialog] = useState(false);
  const [cropper, setCropper] = useState<any | undefined>();
  const [avatar, setAvatar] = useState<any | undefined>();
  const [isEnabled, setIsEnabled] = useState(false);
  const [avatarUrl, setNewAvatarUrl] = useState();
  const [email, setEmail] = useState("here");
  const [username, setUsername] = useState("");
  const inputRef = useRef();
  const openToast = useToast();
  const axios = new Network();
  const { quests, handleDone } = useQuests();

  const changeSettings = (fieldName: string, value: any) => {
    dispatch(
      setSettings({
        ...userSettings,
        [fieldName]: value,
      })
    );
  };
  useEffect(() => {
    setEmail(userInfo?.email);
    setUsername(userInfo?.username);
  }, [userInfo]);

  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };
  const loginWithGoogleApi = async (token: string) => {
    const response = await axios.CallApi({
      url: `/player/google-login?jwt=${token}&refCode=${
        localStorage.getItem("refCode") || undefined
      }`,
      method: "get",
    });
    openToast(response);
    if (response?.ok) {
      getMe();
    }
  };

  const checkOneSingal = async () => {
    const is = await OneSignalReact.isPushNotificationsEnabled();
    const is2 = await OneSignalReact.getNotificationPermission();
    const isGranted = Boolean(is && is2 === "granted");
    changeSettings("notification", isGranted);
    if (is2 === "granted" && !is)
      await OneSignalReact.registerForPushNotifications().then(() =>
        checkOneSingal()
      );
    const Quest = quests.find((quest) => quest?.questId === 3);
    if (isGranted && Quest && Quest.state === QuestState.Open) {
      handleDone(QuestAction.EnableNotification, 1);
    }
  };
  useEffect(() => {
    checkOneSingal();
  }, [props.open]);

  const updateData = async () => {
    const body = new FormData();
    body.append("Username", username);
    avatar && body.append("Photo", avatar);
    setLoading(true);
    const response = await axios.CallApi({
      url: "/player/update",
      content: "multipart/form-data",
      body,
    });

    openToast(response);
    getMe();
    setLoading(false);
  };
  const getNewAvatarUrl = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setNewAvatarUrl(URL.createObjectURL(e.target.files[0]));
      setCropDialog(true);
    }
  };
  const getCropData = async () => {
    if (cropper) {
      const file = await fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], userInfo?.walletAddress + ".png", {
            type: "image/png",
          });
        });
      setCropDialog(false);
      if (file) {
        setNewAvatarUrl(URL.createObjectURL(file));
        setAvatar(file);
      }
    }
  };

  return (
    <>
      <Dialog
        title="Settings"
        open={props.open}
        onClose={() => props.onClose()}
      >
        <div style={{ marginTop: -30, marginBottom: -50 }} className="row ">
          <>
            <div className="col-12">
              <div className="mx-auto text-center">
                <img
                  width={110}
                  style={{
                    borderRadius: "50%",
                    border: avatarUrl ? "1px solid #6f553c" : "",
                  }}
                  className=""
                  src={
                    avatarUrl
                      ? avatarUrl
                      : userInfo?.photoFile
                      ? `${ProfileURL}/${userInfo?.id}/${userInfo?.photoFile}`
                      : "/assets/images/avatar.png"
                  }
                />
              </div>
              <div className="d-flex mt-3 justify-content-center">
                <input type={"file"} className="d-none" />
                <Button
                  onClick={() => inputRef.current?.click()}
                  variant="primary"
                >
                  Change
                </Button>
                <input
                  id="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={getNewAvatarUrl}
                />

                <Button
                  disabled={!avatarUrl}
                  onClick={() => {
                    setNewAvatarUrl(undefined);
                    setAvatar(undefined);
                    setCropper(undefined);
                  }}
                  style={{ fontSize: 14 }}
                  variant="default"
                >
                  Remove
                </Button>
              </div>
            </div>
            {/* <ImageCropper /> */}
            <div className="col-12 mt-3">
              <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-2 mb-2">
                <div>
                  <div className="fs-small">Username</div>
                  <input
                    value={username}
                    placeholder="Enter your username..."
                    onChange={(e) => setUsername(e.target.value?.trim())}
                    className="dark-yellow fw-med fs-med transparent-input"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex flex-wrap justify-content-between dark-bg rounded-10 px-4 py-2 mb-2">
                <div>
                  <div className="fs-small">Email</div>
                  <input
                    readOnly
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="*Email is not connected "
                    className="dark-yellow fw-med fs-med transparent-input"
                  />
                </div>
                {userInfo?.email ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="fs-med fw-normal teal-color ">
                      <b>•</b> Connected
                    </a>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="fs-med fw-normal text-danger ">
                      <b>•</b> Not Connected
                    </a>
                  </div>
                )}
              </div>
            </div>
            {!email && (
              <div className="d-flex justify-content-between align-items-center">
                <div>Connect Email {">>"}</div>{" "}
                <div>
                  <ReactGoogleLogin
                    shape="pill"
                    size="large"
                    theme="filled_black"
                    onSuccess={(credentialResponse) => {
                      loginWithGoogleApi(credentialResponse.credential);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
              </div>
            )}
            <div className="col-12 mt-1">
              <div className="d-flex justify-content-between dark-bg flex-wrap rounded-10 px-4 py-2 mb-2">
                <div style={{ flex: 1 }}>
                  <div className="fs-small">Wallet Address</div>
                  <input
                    readOnly
                    style={{ flex: 1 }}
                    value={
                      userInfo?.walletAddress?.startsWith("0x")
                        ? userInfo?.walletAddress
                        : ""
                    }
                    type="text"
                    placeholder="*Wallet is not connected "
                    className="dark-yellow fw-med fs-med transparent-input"
                  />
                </div>
                {userInfo?.walletAddress?.startsWith("0x") ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="fs-med fw-normal teal-color ">
                      <b>•</b> Connected
                    </a>
                  </div>
                ) : (
                  <div>
                    <div className="d-flex align-items-center justify-content-center">
                      <a className="fs-med fw-normal text-danger ">
                        <b>•</b> Not Connected
                      </a>
                    </div>
                    <div className="text-center mt-1">
                      <button
                        className="connect-btn "
                        onClick={() => setConnectorDialog(true)}
                      >
                        Connect
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {userInfo && userInfo?.walletAddress?.startsWith("0x") && (
              <div className="d-flex justify-content-between align-items-center">
                <div></div>
              </div>
            )}

            <div className="col-12 text-center">
              <Button
                loading={loading}
                onClick={() => updateData()}
                className="mx-auto"
                variant="wide-primary"
              >
                Save Changes
              </Button>
            </div>
          </>

          <div className="col-12">
            <hr />
          </div>
          <div className="col-12">
            <div className="row justify-content-between align-items-center">
              <div style={{ flex: 1 }} className="dark-yellow">
                <img
                  width={40}
                  height="auto"
                  src="/assets/images/icons/sound.png"
                />
                Background Sound
              </div>
              <div style={{ maxWidth: "200px" }}>
                <Slider
                  onChange={(value) => {
                    changeSettings("backgroundMusicVolume", value);
                    openToast(
                      {
                        ok: false,
                        message: "Reload page in order to perform changes",
                      },
                      "warning"
                    );
                  }}
                  value={userSettings?.backgroundMusicVolume}
                  max={10}
                  min={0}
                  className="w-100 mb-2"
                  step={1}
                />
              </div>
            </div>
            <div className="row mt-2 justify-content-between align-items-center">
              <div style={{ flex: 1 }} className="dark-yellow">
                <img
                  width={40}
                  height="auto"
                  src="/assets/images/icons/music.png"
                />
                Sound Effects
              </div>
              <div style={{ maxWidth: "200px" }}>
                <Slider
                  max={10}
                  onChange={(value) => {
                    changeSettings("soundEffects", value);
                  }}
                  value={userSettings?.soundEffects}
                  defaultValue={5}
                  min={0}
                  className="w-100 mb-2"
                  step={1}
                />
              </div>
            </div>
            <hr />
            <div className="row mt-2 justify-content-between align-items-center">
              <div style={{ flex: 1 }} className="dark-yellow">
                <img
                  width={40}
                  height="auto"
                  src="/assets/images/icons/notif.png"
                />
                Notification
              </div>
              <div style={{ width: "fit-content" }}>
                <Form.Check
                  checked={userSettings?.notification}
                  onChange={async () => {
                    if (userSettings?.notification)
                      return openToast({
                        ok: false,
                        message:
                          "You can disable notifications in site settings",
                      });

                    if (!userSettings?.notification) {
                      localStorage.removeItem("onesignal-notification-prompt");
                      await OneSignalReact.showSlidedownPrompt({ force: true });
                      checkOneSingal();
                    }
                  }}
                  type="switch"
                  id="custom-switch"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        noFooter
        title="Crop Image"
        onClose={() => setCropDialog(false)}
        open={cropDialog}
      >
        <div style={{ marginBottom: -50, zIndex: 50 }} className="p-relative">
          <Cropper
            src={avatarUrl}
            style={{ height: 400, width: 400 }}
            initialAspectRatio={4 / 4}
            aspectRatio={4 / 4}
            minCropBoxHeight={200}
            minCropBoxWidth={200}
            guides={true}
            checkOrientation={true}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <Button
            className="mt-3 mx-auto"
            variant="primary"
            onClick={getCropData}
          >
            Crop Image
          </Button>
        </div>
      </Dialog>
      {connectorDialog && (
        <Connector
          onClose={() => setConnectorDialog(false)}
          open={connectorDialog}
        />
      )}
    </>
  );
};

export default SettingsDialog;
