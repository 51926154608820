import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import Network, { IsBeta } from "../utils/Network";
import Button from "../components/Button";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Countdown from "react-countdown";
import { roundFloat } from "../utils/round-float";
import { useDispatch, useSelector } from "react-redux";
import { setPlayable } from "../redux/music/action";
import LevelBadge from "../components/LevelBadge";
import MainLoader from "../components/MainLoader";
import { targetAttribute, targetAttributeName } from "../types/troop";
import TreasureInfo from "../components/TreasureInfo";
import { Helmet } from "react-helmet";

type Props = {};

enum PlayerTroopTargetPriority {
  Closest = 1,
  LowestHealth = 2,
  HighestDamage = 3,
}

const troopTypes = {
  1: "Physical",
  2: "Magical",
  3: "PhysicalAoe",
  4: "MagicalAoe",
};

const defaultTargetPriority = 1;

interface arr {
  index: number;
  x: number;
  displayX: number;
  y: number;
  playerTroopId: number | undefined;
  targetPriority: PlayerTroopTargetPriority;
}
const defaultArray: arr[] = [
  {
    index: 0,
    x: 0,
    displayX: 0,
    y: 0,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 1,
    x: 1,
    displayX: 1,
    y: 0,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 2,
    x: 2,
    displayX: 2,
    y: 0,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 3,
    x: 3,
    displayX: 3,
    y: 0,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 4,
    x: 4,
    displayX: 4,
    y: 0,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 5,
    x: 0,
    displayX: 0.5,
    y: 1,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 6,
    x: 1,
    displayX: 1.5,
    y: 1,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 7,
    x: 2,
    displayX: 2.5,
    y: 1,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 8,
    x: 3,
    displayX: 3.5,

    y: 1,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 9,
    x: 4,
    displayX: 4.5,
    y: 1,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 10,
    x: 0,
    displayX: 0,
    y: 2,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 11,
    displayX: 1,
    x: 1,
    y: 2,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 12,
    displayX: 2,
    x: 2,
    y: 2,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 13,
    displayX: 3,
    x: 3,
    y: 2,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 14,
    displayX: 4,
    x: 4,
    y: 2,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 15,
    displayX: 0.5,
    x: 0,
    y: 3,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 16,
    displayX: 1.5,
    x: 1,
    y: 3,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 17,
    displayX: 2.5,
    x: 2,
    y: 3,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 18,
    displayX: 3.5,
    x: 3,
    y: 3,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 19,
    displayX: 4.5,
    x: 4,
    y: 3,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 20,
    displayX: 0,
    x: 0,
    y: 4,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 21,
    displayX: 1,
    x: 1,
    y: 4,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 22,
    displayX: 2,
    x: 2,
    y: 4,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 23,
    displayX: 3,
    x: 3,
    y: 4,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 24,
    displayX: 4,
    x: 4,
    y: 4,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 25,
    displayX: 0.5,
    x: 0,
    y: 5,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 26,
    displayX: 1.5,
    x: 1,
    y: 5,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 27,
    displayX: 2.5,
    x: 2,
    y: 5,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 28,
    displayX: 3.5,
    x: 3,
    y: 5,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
  {
    index: 29,
    displayX: 4.5,
    x: 4,
    y: 5,
    playerTroopId: undefined,
    targetPriority: defaultTargetPriority,
  },
];

const defaultSupply = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
function isEven(n: string) {
  return parseInt(n) % 2 === 0 ? true : parseInt(n) === 0 ? true : false;
}

const TreasureReplay = (props: Props) => {
  const [startLoading, setStartLoading] = useState(false);
  const windowSize = useWindowSize();
  const contaierRef = useRef<HTMLDivElement>(null);
  const overFlowContainer = useRef<HTMLDivElement>(null);
  const [isTreasure, setTreasure] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [selectProperties, setSelectProperties] = useState({
    checkRoom: 0,
    battlePlace: 0,
  });
  const navigate = useNavigate();
  const [arrayList, setArrayList] = useState(defaultArray);
  const [supplyList, setSupplyList] = useState(defaultSupply);
  let [searchParams, setSearchParams] = useSearchParams();
  const width = contaierRef?.current?.offsetWidth;
  const containerHeight = contaierRef?.current?.offsetHeight;
  const calculatedSize = (width! - 400) / (windowSize?.width < 800 ? 10 : 10);
  const elementSize = calculatedSize > 200 ? 200 : calculatedSize;
  const axios = new Network();
  const [activePopper, setActivePopper] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [battleReport, setBattleReport] = useState<any>();
  const [attackers, setAttackers] = useState<any>([]);
  const [defenders, setDefenders] = useState<any>([]);
  const [data, setData] = useState<any>();
  const [attacking, setAttacking] = useState<any>("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [popover, setPopover] = useState(0);
  const [error, setError] = useState(false);
  const timeoutRef = useRef<any>(null);
  const isPaused = useRef(false);
  const isStoped = useRef(false);
  const isMount = useRef(false);
  const [isPausedState, setIsPausedState] = useState(false);
  const [isCountDown, setIsCountDown] = useState<Date | number>();
  const [buffers, setBuffers] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const isPlayable = useSelector((state: any) => state.audioPlayable);
  let AudioContext = window.AudioContext || (window as any).webkitAudioContext;
  const location = useLocation();
  const attackersRef = useRef(null);
  const defendersRef = useRef(null);
  var backgroundMusic = "";
  const userSettingsRedux = useSelector((state: any) => state.userSettings);
  const settingsRef = useRef(userSettingsRedux);
  const userSettings = settingsRef.current;
  const [mainLoading, setMainLoading] = useState({
    loaded: 0,
    total: 0,
  });

  useEffect(() => {
    attackersRef.current = attackers;
  }, [attackers]);
  useEffect(() => {
    defendersRef.current = defenders;
  }, [defenders]);

  useEffect(() => {
    settingsRef.current = userSettingsRedux;
  }, [userSettingsRedux]);

  const initilizeAllImages = async (array: any[]) => {
    for (let key in array) {
      setMainLoading((prev) => ({ ...prev, total: prev.total + 2 }));
      if (+key + 1 === array.length) {
        setMainLoading((prev) => ({
          loaded: prev.loaded + 5,
          total: prev.total + 5,
        }));
      }
    }
    for (let key in array) {
      const attackAnimation = new Image();
      attackAnimation.src = array[key]?.playerTroop?.Troop?.AttackAnimation;
      attackAnimation.onload = () =>
        setMainLoading((prev) => ({ ...prev, loaded: prev.loaded + 1 }));
      const idleAnimation = new Image();
      idleAnimation.src = array[key]?.playerTroop?.Troop?.IdleAnimation;
      idleAnimation.onload = () =>
        setMainLoading((prev) => ({ ...prev, loaded: prev.loaded + 1 }));
    }
  };

  async function getData() {
    setLoading(true);
    const response = await axios.CallApi({
      url: "/adventure/info",
      body: {
        replayId: id
      }
    });

    if (!response.ok) {
      setError(true)
    }
    let newArrayList: any = [];
    let Defs: any = [];
    setBattleReport(JSON.parse(response?.data?.battleReport));
    JSON.parse(response?.data?.battleReport)?.Attackers?.map((item: any) => {
      let defaultData: any = {};
      for (let key in JSON.parse(response?.data?.battleReport)?.Actions[0]) {
        if (
          item.playerTroopId ===
          JSON.parse(response?.data?.battleReport)?.Actions[0][key]
            ?.playerTroopId
        ) {
          defaultData = JSON.parse(response?.data?.battleReport)?.Actions[0][
            key
          ];
        }
      }
      newArrayList.push({
        ...item,
        initialX: defaultData?.displayX?.toString()
          ? defaultData?.displayX
          : item.displayX,
        initialY: defaultData?.displayY?.toString()
          ? defaultData?.displayY
          : item.displayY,
        ...defaultData,
        Health: item?.InitialHealth,
      });
    });
    setAttackers(newArrayList);
    JSON.parse(response?.data?.battleReport)?.Defenders?.map((item: any) => {
      let defaultData: any = {};
      for (let key in JSON.parse(response?.data?.battleReport)?.Actions[0]) {
        if (
          item.playerTroopId ===
          JSON.parse(response?.data?.battleReport)?.Actions[0][key]
            ?.playerTroopId
        ) {
          defaultData = JSON.parse(response?.data?.battleReport)?.Actions[0][
            key
          ];
        }
      }

      Defs.push({
        ...item,
        ...defaultData,
        initialX: defaultData?.displayX?.toString()
          ? defaultData?.displayX
          : item.displayX,
        initialY: defaultData?.displayY?.toString()
          ? defaultData?.displayY
          : item.displayY,
        Health: item?.InitialHealth,
      });
    });
    setDefenders(Defs);
    setData(response.data);
    initilizeAllImages([...newArrayList, ...Defs]);
    setLoading(false);
  }
  function handleTime() {
    setTimeout(() => {
      handlePlay();
    }, 1000);
  }
  useEffect(() => {
    if (!isMount.current) getData();
    isMount.current = true;
  }, []);

  useEffect(() => {
    if (!loading) {
      if (searchParams?.get("action") === "play") {
        // handleTime()
      }
    }
  }, [loading]);

  useEffect(() => {
    dispatch(setPlayable(false));
  }, []);
  const popperActiveItem = arrayList?.find(
    (item) => item?.playerTroopId === activePopper?.id
  );

  function changeTargetPriority(id: number, value: number) {
    const oldObj: any = arrayList?.find((item) => item?.playerTroopId === id);
    setArrayList((prev) =>
      [
        ...prev?.filter((item) => item?.playerTroopId !== id),
        { ...oldObj, targetPriority: value },
      ]?.sort((a, b) => a.index - b.index)
    );
  }

  const getPlayerToops = async () => {
    const response = await axios.CallApi({
      url: "/playertroop?count=50",
      method: "GET",
    });
    if (response.ok) setSupplyList(response?.data?.data);
  };

  useEffect(() => {
    getPlayerToops();
  }, []);

  useEffect(() => {
    if (overFlowContainer.current && contaierRef.current) {
      overFlowContainer.current.scrollLeft =
        windowSize.width < 600 ? contaierRef.current.offsetWidth / 3.6 : 200;
    }
  }, []);

  function playSound(audioBuffer: any, audioCtx: any) {
    try {
      let gainNode = audioCtx.createGain();
      let bufferSource = audioCtx.createBufferSource();
      gainNode.gain.value = parseFloat(
        roundFloat(settingsRef.current?.soundEffects / 10, 1)
      );
      bufferSource.buffer = audioBuffer;
      bufferSource.connect(gainNode).connect(audioCtx.destination);
      gainNode.connect(audioCtx.destination);
      bufferSource.loop = false;
      bufferSource.start(0);
    } catch (e) {
      console.log(e);
    }
  }

  function handleVisibility() {
    if (document.hidden) {
      setIsPausedState(true);
      isPaused.current = true;
    } else {
      setIsPausedState(false);
      isPaused.current = false;
    }
  }

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibility, false);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibility, false);
  }, []);

  function initSound(src: string) {
    var myAudioElement = new Audio(src);
    myAudioElement.volume = parseFloat(
      roundFloat(settingsRef.current?.soundEffects / 10, 1)
    );
    // myAudioElement.addEventListener("canplaythrough", (event) => {
    myAudioElement.play();
    setTimeout(() => {
      myAudioElement.remove();
    }, 3000);
    // });
    return;

    // return;
    let audioCtx = new AudioContext();
    if (userSettings?.soundEffects > 0)
      fetch(src)
        .then((resp) => resp.arrayBuffer())
        .then((arrayBuffer) => audioCtx.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          // setBuffers(prev=>({}))
          playSound(audioBuffer, audioCtx);
        });
  }
  let audioCtx = new AudioContext();

  // useEffect(() => {
  //   setTimeout(() => {
  //     initSound("/sounds/battle.bgmc",true);
  //   }, 5000);
  //   return () => audioCtx && audioCtx?.suspend();
  // }, []);

  function getPositionAtCenter(element: HTMLElement) {
    const { top, left, width, height } = element.getBoundingClientRect();
    return {
      x: left + width / 2,
      y: top + height / 2,
    };
  }

  function getDistanceBetweenElements(a: HTMLElement, b: HTMLElement) {
    const aPosition = getPositionAtCenter(a);
    const bPosition = getPositionAtCenter(b);
    // return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
    const x = aPosition.x - bPosition.x;
    const y = aPosition.y - bPosition.y;
    return { x, y };
  }

  function getAnglesBetweenTwoEl(
    p1: { x: number; y: number },
    p2: { x: number; y: number }
  ) {
    var angleDeg = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
    return angleDeg;
  }
  function handleAttackEffect(attacker: any, victimID: number) {
    const attackerElement = document.getElementById(
      attacker?.playerTroopId + ""
    );
    const victimElement = document.getElementById(victimID + "");
    const angle = getAnglesBetweenTwoEl(
      getPositionAtCenter(attackerElement!),
      getPositionAtCenter(victimElement!)
    );
    const jsonData = JSON.parse(attacker?.playerTroop?.Troop?.JsonData);
    let projectili = document.createElement("img");
    let effect = document.createElement("img");
    projectili.className = "effect-bt";
    effect.className = "effect-bt";

    projectili.id =
      attacker?.playerTroopId?.toString() + "-effect-" + victimID + "";
    effect.id = victimID?.toString() + "-effected";
    effect.style.zIndex = "500";
    projectili.style.transform = `rotate(${parseInt(
      angle + ""
    )}deg) scaleX(-1)`;

    if (attacker?.playerTroopId > 0) {
    } else {
      // projectili.style.transform = `rotate(${parseInt(angle)}deg)  scaleX(-1)`
    }

    if (attacker?.playerTroop?.Troop?.Id === 1) {
      //Warrior
      effect.src = "/assets/images/battle/effects/sword-hit.webp";
      effect.width = elementSize;
      effect.height = elementSize;
    }
    if (attacker?.playerTroop?.Troop?.Id === 2) {
      //ARCHER
      projectili.width = 50;
      projectili.height = 30;
      projectili.src = "/assets/images/battle/effects/arrow.png";
    }
    if (attacker?.playerTroop?.Troop?.Id === 3) {
      //Warrior
      effect.src = "/assets/images/battle/effects/sword-hit.webp";
      effect.width = elementSize;
      effect.height = elementSize;
    }
    if (attacker?.playerTroop?.Troop?.Id === 4) {
      //Knight
      effect.src = "/assets/images/battle/effects/sword-hit.webp";
      effect.width = elementSize;
      effect.height = elementSize;
    }
    if (attacker?.playerTroop?.Troop?.Id === 5) {
      //MAGE
      projectili.width = 75;
      projectili.height = 50;
      projectili.src = "/assets/images/battle/effects/mage-item.webp";
      effect.width = elementSize;
      effect.height = elementSize;
      effect.src = "/assets/images/battle/effects/magic-explode.webp";
    }
    if (attacker?.playerTroop?.Troop?.Id === 6) {
      //Capult
      projectili.width = 25;
      projectili.height = 25;
      projectili.src = "/assets/images/battle/effects/stone.png";
      effect.src = "/assets/images/battle/effects/stone-explode.webp";
      effect.width = elementSize;
      effect.height = elementSize;
    }

    if (attacker?.playerTroop?.Troop?.Id === 7) {
      //Dragon
      projectili.width = 50;
      projectili.height = 50;
      projectili.src = "/assets/images/battle/effects/dragon-fire.webp";
      effect.src = "/assets/images/battle/effects/dragon-fire-explode.webp";
      effect.width = elementSize;
      effect.height = elementSize;
    }
    if (attacker?.playerTroop?.Troop?.Id === 8) {
      //Goblin
      effect.src = "/assets/images/battle/effects/sword-hit.webp";
      effect.width = elementSize;
      effect.height = elementSize;
    }

    if (attacker?.playerTroop?.Troop?.Id === 9) {
      //XBOW
      projectili.width = 50;
      projectili.height = 30;
      projectili.src = "/assets/images/battle/effects/arrow.png";
    }

    if (attacker?.playerTroop?.Troop?.Id === 12) {
      //cz
      projectili.width = 50;
      projectili.height = 50;
      projectili.src = "/assets/images/battle/effects/dragon-fire.webp";
      effect.src = "/assets/images/battle/effects/dragon-fire-explode.webp";
      effect.width = elementSize;
      effect.height = elementSize;
    }
    if (effect.src) {
      setTimeout(() => {
        victimElement?.append(effect);
      }, 1.05 * 1000);
      setTimeout(() => {
        document.getElementById(victimID?.toString() + "-effected")?.remove();
      }, 1.3 * 1000);
    }
    if (!projectili.src) return;
    attackerElement?.append(projectili);
    const throwingElement = document.getElementById(projectili.id);
    const positions = getDistanceBetweenElements(
      victimElement!,
      throwingElement!
    );
    // throwingElement.style.transitionDuration = (jsonData?.attackAnimationTime-300) ?.toString()
    if (throwingElement)
      throwingElement.style.transform = `translateX(${positions.x
        }px) translateY(${positions.y}px) rotate(${parseInt(
          angle + ""
        )}deg) scaleX(${attacker?.playerTroopId > 0 ? "-1" : "-1"}) `;

    setTimeout(() => {
      const el = document.getElementById(projectili.id);
      el?.remove();
    }, 0.95 * 1000);
  }
  function handleHealTroop(
    healingTarget,
    health: number,
    attackTime: number,
    isDefender?: boolean
  ) {
    const targetElement: any = document.getElementById(
      healingTarget?.playerTroopId?.toString()
    );

    targetElement.style.backgroundImage =
      "url(/assets/images/battle/blue-polygon.png)";
    const effectEl = document.createElement("img");
    effectEl.src = "/assets/images/battle/effects/heals.webp";
    effectEl.width = elementSize;
    effectEl.height = elementSize;
    effectEl.id = "heal-" + healingTarget?.playerTroopId?.toString();
    effectEl.className = "effect-mg";
    targetElement.append(effectEl);
    const troopItem = isDefender
      ? defendersRef.current.find(
        (it) => it.playerTroopId === healingTarget?.playerTroopId
      )
      : attackersRef.current.find(
        (it) => it.playerTroopId === healingTarget?.playerTroopId
      );
    const newElement = document.createElement("div");
    newElement.className = "heal-info";
    newElement.textContent = "+" + (health - troopItem.Health);

    setTimeout(() => {
      if (isDefender) {
        setDefenders((prev) => [
          ...prev?.filter(
            (nw) => nw?.playerTroopId !== healingTarget.playerTroopId
          ),
          {
            ...prev.find(
              (itm) => +itm?.playerTroopId === +healingTarget.playerTroopId
            ),
            Health: health,
          },
        ]);
      } else {
        setAttackers((prev) => [
          ...prev?.filter(
            (nw) => nw?.playerTroopId !== healingTarget.playerTroopId
          ),
          {
            ...prev.find(
              (itm) => +itm?.playerTroopId === +healingTarget.playerTroopId
            ),

            Health: health,
          },
        ]);
      }
      if (health !== troopItem.Health) {
        targetElement?.appendChild(newElement);
        setTimeout(() => {
          targetElement?.removeChild(newElement);
        }, 1500);
      }

      const el = document.getElementById(
        "heal-" + healingTarget?.playerTroopId?.toString()
      );
      el?.remove();
      // troopImage.style.transform = `scaleX(-1)`
      if (targetElement.style.backgroundImage?.includes("blue-")) {
        targetElement.style.backgroundImage = "";
      }
    }, attackTime - 100);
  }
  function handleNext(currentStp = 0) {
    const actions = battleReport.Actions[currentStp];
    let counter = 0;
    try {
      function handlePerformRound() {
        if (!isPaused.current) {
          const key = counter;
          const attacker = attackers?.find(
            (item) => item?.playerTroopId === actions[key].playerTroopId
          );
          const defender = defenders?.find(
            (item) => item?.playerTroopId === actions[key].playerTroopId
          );
          let attackTime = 1000;
          if (attacker) {
            const newItem: any = document.getElementById(
              actions[key].playerTroopId?.toString()
            );
            const isGoingToMove = Boolean(
              newItem.style.transform !==
              `translateX(${actions[key]?.displayToX * elementSize -
              attacker?.initialX * elementSize
              }px) translateY(${actions[key]?.displayToY * elementSize -
              attacker?.initialY * elementSize
              }px)`
            );
            newItem.style.transform = `translateX(${actions[key]?.displayToX * elementSize -
              attacker?.initialX * elementSize
              }px) translateY(${actions[key]?.displayToY * elementSize -
              attacker?.initialY * elementSize
              }px)`;
            const elementToolTip: any = document.getElementById(
              actions[key].playerTroopId?.toString() + "-tooltip"
            );
            elementToolTip.style.transform = `translateX(${actions[key]?.displayToX * elementSize -
              attacker?.initialX * elementSize
              }px) translateY(${actions[key]?.displayToY * elementSize -
              attacker?.initialY * elementSize
              }px)`;
            if (actions[key]?.targets?.length !== 0) {
              const jsonData = JSON.parse(
                attacker?.playerTroop?.Troop?.JsonData
              );
              if (jsonData?.attackSound?.length > 0) {
                initSound(jsonData?.attackSound[0]);
              }
              for (let key2 in actions[key]?.targets) {
                const attackerTarget = defenders?.find(
                  (attItem) =>
                    attItem?.playerTroopId === actions[key]?.targets[key2]?.id
                );
                const healingTarget = attackers?.find(
                  (attItem) =>
                    attItem?.playerTroopId === actions[key]?.targets[key2]?.id
                );
                if (attackerTarget) {
                  // if(attackerTarget.displayToX > attacker.displayToX){
                  //     newItem.style.transform="scaleX(1)"
                  // }
                  //Handling Attack Effect

                  if (
                    actions[key]?.targets[key2]?.targetAttribute !==
                    targetAttribute.Health
                  ) {
                    setDefenders((prev) => [
                      ...prev?.filter(
                        (nw) =>
                          nw?.playerTroopId !== attackerTarget.playerTroopId
                      ),
                      {
                        ...prev.find(
                          (itm) =>
                            +itm?.playerTroopId ===
                            +attackerTarget.playerTroopId
                        ),
                        [targetAttributeName[
                          actions[key]?.targets[key2]?.targetAttribute
                        ]]: actions[key]?.targets[key2]?.newValue,
                      },
                    ]);
                  } else {
                    const timeEffect = isGoingToMove ? 500 : 0;
                    setTimeout(() => {
                      handleAttackEffect(
                        attacker,
                        attackerTarget?.playerTroopId
                      );
                    }, timeEffect);
                    const targetElement: any = document.getElementById(
                      attackerTarget?.playerTroopId?.toString()
                    );

                    attackTime = jsonData?.attackAnimationTime * 1000;

                    setAttacking(actions[key]?.playerTroopId);
                    // const targetElement = document.getElementById(`${attackerTarget?.displayToY}y-${attackerTarget?.displayToX}x`)
                    targetElement.style.backgroundImage =
                      "url(/assets/images/battle/red-polygon.png)";
                    setTimeout(() => {
                      setDefenders((prev) => [
                        ...prev?.filter(
                          (nw) =>
                            nw?.playerTroopId !== attackerTarget.playerTroopId
                        ),
                        {
                          ...prev.find(
                            (itm) =>
                              +itm?.playerTroopId ===
                              +attackerTarget.playerTroopId
                          ),
                          Health: actions[key]?.targets[key2]?.newValue,
                        },
                      ]);
                      const targetTroopData = defendersRef.current.find(
                        (tr) =>
                          tr.playerTroopId === attackerTarget.playerTroopId
                      );
                      handleShowDPS(
                        actions[key]?.targets[key2]?.newValue,
                        attackerTarget.playerTroopId,
                        targetTroopData
                      );
                      if (
                        targetElement.style.backgroundImage?.includes("red-")
                      ) {
                        targetElement.style.backgroundImage = "";
                      }
                    }, attackTime - 100);
                  }
                }

                if (healingTarget) {
                  if (
                    actions[key]?.targets[key2].targetAttribute ===
                    targetAttribute.Health
                  ) {
                    handleHealTroop(
                      healingTarget,
                      actions[key]?.targets[key2]?.newValue,
                      attackTime
                    );
                  } else {
                    setAttackers((prev) => [
                      ...prev.filter(
                        (item) =>
                          +item.playerTroopId !== +healingTarget.playerTroopId
                      ),
                      {
                        ...prev.find(
                          (itm) =>
                            +itm?.playerTroopId === +healingTarget.playerTroopId
                        ),
                        [targetAttributeName[
                          actions[key]?.targets[key2].targetAttribute
                        ]]: actions[key]?.targets[key2]?.newValue,
                      },
                    ]);
                  }
                } // const itemPlace = document.getElementById(`${actions[key]?.displayToY}y-${actions[key]?.displayToX}x`)
              }
            }
            newItem.style.backgroundImage = `url(/assets/images/battle/green-polygon.png)`;
            setTimeout(() => {
              setAttacking("");

              if (newItem.style.backgroundImage?.includes("green-"))
                newItem.style.backgroundImage = ``;
            }, attackTime - 100);
          }
          if (defender) {
            const newItem: any = document.getElementById(
              actions[key].playerTroopId?.toString()
            );
            const troopImage = document.getElementById(
              actions[key].playerTroopId?.toString() + "-img"
            );
            newItem.style.transform = `translateX(${actions[key]?.displayToX * elementSize -
              defender?.initialX * elementSize
              }px) translateY(${actions[key]?.displayToY * elementSize -
              defender?.initialY * elementSize
              }px)`;
            if (actions[key]?.targets?.length !== 0) {
              const jsonData = JSON.parse(
                defender?.playerTroop?.Troop?.JsonData
              );
              if (jsonData?.attackSound?.length > 0) {
                initSound(jsonData?.attackSound[0]);
              }
              for (let key2 in actions[key]?.targets) {
                const defenderTarget = attackers?.find(
                  (attItem) =>
                    attItem?.playerTroopId === actions[key]?.targets[key2]?.id
                );
                const healingTarget = defenders?.find(
                  (attItem) =>
                    attItem?.playerTroopId === actions[key]?.targets[key2]?.id
                );
                if (defenderTarget) {
                  if (
                    actions[key]?.targets[key2]?.targetAttribute !==
                    targetAttribute.Health
                  ) {
                    setAttackers((prev) => [
                      ...prev?.filter(
                        (nw) =>
                          nw?.playerTroopId !== defenderTarget.playerTroopId
                      ),
                      {
                        ...prev.find(
                          (itm) =>
                            itm?.playerTroopId === defenderTarget.playerTroopId
                        ),
                        [targetAttributeName[
                          actions[key]?.targets[key2]?.targetAttribute
                        ]]: actions[key]?.targets[key2]?.newValue,
                      },
                    ]);
                  } else {
                    setAttacking(actions[key]?.playerTroopId);
                    //Handling Attack Effect

                    setTimeout(() => {
                      handleAttackEffect(
                        defender,
                        defenderTarget?.playerTroopId
                      );
                    }, 500);

                    attackTime = jsonData?.attackAnimationTime * 1000;
                    const targetElement: any = document.getElementById(
                      defenderTarget?.playerTroopId?.toString()
                    );

                    targetElement.style.backgroundImage =
                      "url(/assets/images/battle/red-polygon.png)";

                    setTimeout(() => {
                      setAttackers((prev) => [
                        ...prev?.filter(
                          (nw) =>
                            nw?.playerTroopId !== defenderTarget.playerTroopId
                        ),
                        {
                          ...prev.find(
                            (itm) =>
                              itm?.playerTroopId ===
                              defenderTarget.playerTroopId
                          ),
                          Health: actions[key]?.targets[key2]?.newValue,
                        },
                      ]);
                      const targetTroopData = attackersRef.current.find(
                        (tr) =>
                          tr.playerTroopId === defenderTarget.playerTroopId
                      );

                      handleShowDPS(
                        actions[key]?.targets[key2]?.newValue,
                        defenderTarget.playerTroopId,
                        targetTroopData
                      );

                      const newElement: any = document.getElementById(
                        defenderTarget?.playerTroopId?.toString()
                      );

                      if (newElement.style.backgroundImage?.includes("red-")) {
                        newElement.style.backgroundImage = "";
                      }
                    }, attackTime - 100);
                  }
                }

                if (healingTarget) {
                  if (
                    actions[key]?.targets[key2].targetAttribute ===
                    targetAttribute.Health
                  ) {
                    handleHealTroop(
                      healingTarget,
                      actions[key]?.targets[key2]?.newValue,
                      attackTime,
                      true
                    );
                  } else {
                    setDefenders((prev) => [
                      ...prev.filter(
                        (item) =>
                          +item.playerTroopId !== +healingTarget.playerTroopId
                      ),
                      {
                        ...prev.find(
                          (itm) =>
                            +itm?.playerTroopId === +healingTarget.playerTroopId
                        ),
                        [targetAttributeName[
                          actions[key]?.targets[key2].targetAttribute
                        ]]: actions[key]?.targets[key2]?.newValue,
                      },
                    ]);
                  }
                }
              }
            }
            // const itemPlace = document.getElementById(`${actions[key]?.displayToY}y-${actions[key]?.displayToX}x`)
            newItem.style.backgroundImage = `url(/assets/images/battle/green-polygon.png)`;
            setTimeout(() => {
              setAttacking("");
              if (newItem.style.backgroundImage?.includes("green-"))
                newItem.style.backgroundImage = ``;
            }, attackTime - 100);
          }
          counter += 1;
          if (counter === actions?.length) {
            if (battleReport.Actions?.length - 1 !== currentStp) {
              timeoutRef.current = setTimeout(
                () => handleNext(currentStp + 1),
                attackTime + 100
              );
            } else {
              setTimeout(() => {
                navigate("/battle/result/" + encodeURIComponent(id!));
              }, attackTime);
              setIsPlaying(false);
            }
          } else {
            setTimeout(() => {
              handlePerformRound();
            }, attackTime);
          }
        } else {
          if (isStoped?.current) {
            return;
          } else {
            setTimeout(() => {
              handlePerformRound();
            }, 1500);
          }
        }
      }

      handlePerformRound();
    } catch (e) {
      console.log(e);
    }
  }

  const handleShowDPS = (value, target: number, troopItem) => {
    const troop = document.getElementById(target.toString());
    const newElement = document.createElement("div");
    newElement.className = "dps-info";
    newElement.textContent = "-" + (troopItem.Health - value);
    troop?.appendChild(newElement);

    setTimeout(() => {
      troop?.removeChild(newElement);
    }, 1500);

  };

  async function handleReplay() {
    window.location.href = `/battle/treasure/${id}?action=play`;
  }
  function handlePlay() {
    setIsCountDown(Date.now() + 3000);
    setIsPausedState(false);
    isPaused.current = false;
    isStoped.current = false;
    setIsPlaying(true);
    setTimeout(() => {
      handleNext();
    }, 3000);
  }

  const OverlayStats = ({ item }: any) => {
    const width =
      windowSize.width > 1440
        ? elementSize * 1.67 < 240
          ? 240
          : elementSize * 1.67
        : 240;
    const left = width === 240 ? -width / 3 : -elementSize / 3;
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: popover === item?.playerTroopId ? "block" : "none",
          top: "-180px",
          position: "absolute",
          width: width,
          left: left,
          marginInline: "auto",
          zIndex: 1900,
          // animation: 'fadeIn 0.2s forwards'
        }}
        className="bg-dark fs-sm-med p-relative rounded p-2 arrow-down"
      >
        <b>
          {item?.playerTroop?.Troop?.Name} - Level {item?.playerTroop?.Level}
        </b>
        <div className="fs-med">
          <div className="d-flex align-items-center">
            <hr style={{ flex: 1, marginInlineEnd: 5 }} />
            {troopTypes[item?.playerTroop?.DamageType]}
            <hr style={{ flex: 1, marginInlineStart: 5 }} />
          </div>

          <div className="d-flex align-items-center">
            <img
              width={20}
              src="/assets/images/icons/damage.png"
              className="me-1"
            />
            Damage
            <hr style={{ flex: 1 }} className="mx-2 dashed my-0" />
            {roundFloat(item?.playerTroop?.Damage)}
          </div>
          <div className="d-flex align-items-center">
            <img
              width={20}
              src="/assets/images/icons/health.png"
              className="me-1"
            />
            Health
            <hr style={{ flex: 1 }} className="mx-2 dashed my-0" />
            {roundFloat(item?.Health)} /{" "}
            {roundFloat(item?.playerTroop?.MaxHealth)}
          </div>
          <div className="d-flex align-items-center">
            <img
              width={20}
              src="/assets/images/icons/armor.png"
              className="me-1"
            />
            Armor
            <hr style={{ flex: 1 }} className="mx-2 dashed my-0" />
            {roundFloat(item?.Armor)} / (
            {roundFloat((1 / (200 / item?.Armor + 1.25)) * 100)}%)
          </div>
          <div className="d-flex align-items-center">
            <img
              width={20}
              src="/assets/images/icons/range.png"
              className="me-1"
            />
            Range
            <hr style={{ flex: 1 }} className="mx-2 dashed my-0" />
            {roundFloat(item?.playerTroop?.Range)}
          </div>

          {item?.MagicResistance ? (
            <div className="d-flex align-items-center">
              <img
                width={20}
                src="https://core.chainoflegends.com/assets/images/team-magic-resist.png"
                className="me-1"
              />
              Magic Resistance:
              <hr style={{ flex: 1 }} className="mx-2 dashed my-0" />
              {roundFloat(item?.MagicResistance)} (
              {roundFloat((1 / (200 / item?.MagicResistance + 1.25)) * 100, 0)}
              %)
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const treasureType = data?.treasure?.treasureType;

  return (
    <div
      onClick={() => setPopover(0)}
      ref={overFlowContainer}
      style={{ overflow: "auto" }}
    >
      <Helmet>
        <title key="title">
          Adventure Replay | Chain Of Legends
        </title>
      </Helmet>
      <MainLoader loaded={mainLoading.loaded} total={mainLoading.total} error={error} />
      <TreasureInfo treasureType={treasureType} id={data?.treasure?.id!} />
      <div
        style={{
          display: "flex",
          justifyContent: windowSize.width < 990 ? "flex-start" : "",
        }}
        ref={contaierRef}
        className={treasureType === 2 ? "treasure " : "pre-fight "}
      >
        {isCountDown && (
          <div className="battle-countdown">
            <Countdown
              onComplete={() => setIsCountDown(undefined)}
              date={isCountDown}
              renderer={({ seconds }) => (
                <div>
                  <p style={{ fontSize: 70 }}>{seconds}</p>
                </div>
              )}
            />
          </div>
        )}
        <div
          style={{
            filter: isCountDown ? "blur(5px)" : "",
            maxWidth: windowSize.width < 990 ? "none" : "",
            paddingInlineStart:
              windowSize.width > 1500
                ? "8%"
                : windowSize.width < 990
                  ? "8%"
                  : "10%",
          }}
          className="w-100 transition pe-3 "
        >
          <div style={{ minWidth: 800 }} className="row ">
            <div
              style={{ position: "relative" }}
              className="d-flex  align-items-center col-12"
            >
              {arrayList?.map((item, i) => (
                <div
                  key={i}
                  className={`${treasureType === 2 ? "tr-polygon" : "pre-polygon "
                    } d-flex  justify-content-center`}
                  id={`${item?.y}y-${item?.displayX}x`}
                  style={{
                    width: elementSize,
                    height: elementSize,
                    opacity: 0.7,
                    filter:
                      selectMode && !item?.playerTroopId
                        ? "invert(30%) sepia(99%) saturate(323%)"
                        : "",
                    position: "absolute",
                    top:
                      item?.y * elementSize +
                      (windowSize?.width < 1200
                        ? treasureType === 2
                          ? containerHeight! / 7
                          : containerHeight! / 5
                        : treasureType === 2
                          ? containerHeight! / 7
                          : containerHeight! / 5),
                    textAlign: "center",
                    left: item?.displayX * elementSize + 70,
                  }}
                />
              ))}

              {defaultArray?.map((item) => (
                <div
                  className={`${treasureType === 2 ? "tr-polygon" : "pre-polygon "
                    }`}
                  id={`${item?.y}y-${item?.displayX + 5}x`}
                  style={{
                    width: elementSize,
                    height: elementSize,
                    // transform: `scaleX(15deg)`,
                    opacity: 0.7,
                    position: "absolute",
                    top:
                      item?.y * elementSize +
                      (windowSize?.width < 1200
                        ? treasureType === 2
                          ? containerHeight! / 7
                          : containerHeight! / 5
                        : treasureType === 2
                          ? containerHeight! / 7
                          : containerHeight! / 5),
                    left: (item?.displayX + 5) * elementSize + 70,
                  }}
                ></div>
              ))}
              {attackers.map((item, i) => (
                <div key={item?.playerTroopId}>
                  <div
                    id={item?.playerTroopId}
                    style={{
                      width: elementSize,
                      height: elementSize,
                      transition: "0.7s all ease-in-out",
                      opacity: item?.Health === 0 ? 0 : 1,
                      display: item?.Health === 0 ? "none !important" : "flex",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      position: "absolute",
                      top:
                        item?.initialY * elementSize +
                        (windowSize?.width < 1200
                          ? treasureType === 2
                            ? containerHeight! / 7
                            : containerHeight! / 5
                          : treasureType === 2
                            ? containerHeight! / 7
                            : containerHeight! / 5),
                      textAlign: "center",
                      left: item?.initialX * elementSize + 70,
                      zIndex: popover === item?.playerTroopId ? 600 : "unset",
                    }}
                    className=" d-flex  justify-content-center"
                  >
                    <div className="p-relative">
                      <div
                        style={{
                          height: "40%",
                          width: 5,
                          top: "30%",
                          left: 0,
                          borderRadius: 5,
                          position: "absolute",
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                        className="p-relative bg-dark-500 overflow-hidden"
                      >
                        <div
                          className="transition"
                          style={{
                            width: "100%",
                            height:
                              (item?.Health * 100) /
                              item?.playerTroop?.MaxHealth +
                              "%",
                            backgroundColor: "var(--theme-color-green-dark)",
                          }}
                        />
                      </div>
                      <LevelBadge level={item?.playerTroop?.Level} />
                      <img
                        id={item?.playerTroopId + "-img"}
                        className="scaleX"
                        style={{
                          maxWidth: "100%",
                          marginTop: windowSize?.width < 800 ? -50 : -66,
                          maxHeight:
                            windowSize?.width < 800
                              ? 88
                              : windowSize?.width < 1400
                                ? 95
                                : windowSize?.width < 1500
                                  ? 120
                                  : windowSize?.width > 1600
                                    ? 140
                                    : 120,
                          transform: "scaleX(-1) scale(2)",
                        }}
                        src={
                          attacking === item?.playerTroopId
                            ? item?.playerTroop?.Troop?.AttackAnimation
                            : item?.playerTroop?.Troop?.IdleAnimation
                        }
                      />
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setPopover(item?.playerTroopId);
                        }}
                        id={item?.playerTroopId + "-tooltip"}
                        style={{
                          position: "absolute",
                          width: elementSize,
                          height: elementSize,
                          top: 0,
                          left: 0,
                          zIndex: 1500,
                        }}
                      ></div>
                    </div>
                    <OverlayStats item={item} />
                  </div>
                </div>
              ))}

              {defenders.map((item, i) => (
                <div
                  key={item?.playerTroopId}
                  id={item?.playerTroopId}
                  style={{
                    width: elementSize,
                    height: elementSize,
                    opacity: item?.Health === 0 ? 0 : 1,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    transition: "0.7s all",
                    // transform:    `translateY(${elementSize / 2}px)`,
                    // filter: selectMode && !item?.playerTroopId ? "invert(30%) sepia(99%) saturate(323%)" : '',
                    position: "absolute",
                    top:
                      item?.initialY * elementSize +
                      (windowSize?.width < 1200
                        ? treasureType === 2
                          ? containerHeight! / 7
                          : containerHeight! / 5
                        : treasureType === 2
                          ? containerHeight! / 7
                          : containerHeight! / 5),
                    textAlign: "center",
                    zIndex: popover === item?.playerTroopId ? 600 : "unset",
                    left: item?.initialX * elementSize + 70,
                    // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                  }}
                  className=" d-flex   justify-content-center"
                >
                  <div className="p-relative">
                    <div
                      style={{
                        height: "40%",
                        width: 5,
                        top: "30%",
                        right: 0,
                        borderRadius: 5,
                        position: "absolute",
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                      className="p-relative bg-dark-500 overflow-hidden"
                    >
                      <div
                        className="transition"
                        style={{
                          width: "100%",
                          height:
                            (item?.Health * 100) /
                            item?.playerTroop?.MaxHealth +
                            "%",
                          backgroundColor: "var(--theme-color-green-dark)",
                        }}
                      />
                    </div>
                    <LevelBadge isDef level={item?.playerTroop?.Level} />
                    <img
                      style={{
                        maxWidth: "100%",
                        position: "relative",
                        zIndex: 20,
                        marginTop: windowSize?.width < 800 ? -50 : -66,
                        maxHeight:
                          windowSize?.width < 800
                            ? 88
                            : windowSize?.width < 1400
                              ? 95
                              : windowSize?.width < 1500
                                ? 120
                                : windowSize?.width > 1600
                                  ? 140
                                  : 120,
                        transform: "scale(2)",
                      }}
                      src={
                        item?.playerTroop?.Troop?.Id === 9 &&
                          item?.initialY === 5
                          ? attacking === item?.playerTroopId
                            ? item?.playerTroop?.Troop?.AttackAnimation?.replace(
                              "top",
                              "bottom"
                            )
                            : item?.playerTroop?.Troop?.IdleAnimation?.replace(
                              "top",
                              "bottom"
                            )
                          : attacking === item?.playerTroopId
                            ? item?.playerTroop?.Troop?.AttackAnimation
                            : item?.playerTroop?.Troop?.IdleAnimation
                      }
                    // src={item?.playerTroop?.Troop?.IdleAnimation || "https://core.chainoflegends.com/assets/troops/idle/goblin.webp"}
                    />

                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setPopover(item?.playerTroopId);
                      }}
                      id={item?.playerTroopId + "-tooltip"}
                      style={{
                        position: "absolute",
                        width: elementSize,
                        height: elementSize,
                        top: 0,
                        left: 0,
                        zIndex: 1500,
                      }}
                    ></div>
                  </div>
                  <OverlayStats item={item} />
                </div>
              ))}
              {/* {defenders.map((item, i) => (
                                <>
                                    {item?.Health === 0 ? '' : <OverlayTrigger placement="top" trigger={"click"} overlay={<Tooltip>
                                        <b>
                                            {item?.playerTroop?.Troop?.Name} - Level         {item?.playerTroop?.Level}
                                        </b>
                                        <div className='text-start'>
                                            <div className='d-flex align-items-center'>
                                                <hr style={{ flex: 1, marginInlineEnd: 5 }} />
                                                {item?.playerTroop?.DamageType === 1 ? 'Physic' : "Magic"}
                                                <hr style={{ flex: 1, marginInlineStart: 5 }} />
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/damage.png" />   Damage:    {roundFloat(item?.playerTroop?.Damage)}
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/health.png" />    Health:    {roundFloat(item?.Health)} / {roundFloat(item?.playerTroop?.MaxHealth)}
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/armor.png" />   Armor:    {roundFloat(item?.Armor)} ({roundFloat(1 / (200 / item?.Armor + (1.25)) * 100)}%)
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/range.png" />   Range:    {item?.playerTroop?.Range}
                                            </div>
                                            {item?.MagicResistance ?
                                                <div >
                                                    <img width={20} src="https://core.chainoflegends.com/assets/images/team-magic-resist.png" /> Magic Resistance: {roundFloat(item?.MagicResistance)} ({roundFloat(1 / (200 / item?.MagicResistance + (1.25)) * 100, 0)}%)
                                                </div> : ''}
                                        </div>

                                    </Tooltip>}>
                                        <div
                                            id={item?.playerTroopId + '-tooltip'}

                                            style={{
                                                position: 'absolute',
                                                width: elementSize, height: elementSize,
                                                top: item?.initialY * elementSize + (windowSize?.width < 1200 ? containerHeight! / 7 : containerHeight! / 7),
                                                left: (item?.initialX) * elementSize + 70,
                                                zIndex: 1000
                                            }}>
                                        </div>
                                    </OverlayTrigger>}
                                </>
                            ))} */}
            </div>
          </div>
        </div>
        <div
          style={{ zIndex: 1550, position: "fixed", bottom: 10, left: 20 }}
          className="d-flex w-100"
        >
          {isPlaying ? (
            <Button
              onClick={() => {
                isPaused.current = !isPaused.current;
                setIsPausedState((prev) => !prev);
              }}
              variant="default"
            >
              <img
                className="transition"
                src={
                  isPausedState
                    ? "/assets/images/icons/play.svg"
                    : "/assets/images/icons/pause.svg"
                }
                width={isPausedState ? 10 : 12}
              />
              &nbsp;
              {isPausedState ? "Play" : "Pause"}
            </Button>
          ) : (
            <Button
              disabled={loading || isPlaying}
              onClick={handlePlay}
              variant="default"
              style={{ position: "fixed", bottom: 10, left: 20 }}
            >
              <img src="/assets/images/icons/play.svg" width={10} />
              &nbsp; Play
            </Button>
          )}

          {isPlaying && (
            <Button variant="primary" onClick={handleReplay}>
              <img src={"/assets/images/icons/replay.svg"} width={15} />
              &nbsp;Replay
            </Button>
          )}
          <div className="ms-auto me-3 me-md-5">
            <img
              onClick={() => navigate("/battle/treasure/" + id + "#settings")}
              width={60}
              height={55}
              style={{ padding: 0 }}
              className="button "
              src="/assets/images/icons/setting.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreasureReplay;
