import React, { useEffect, useState } from "react";
import MidBox from "../components/Boxes/MidBox";
import Slider from "rc-slider";
import { motion } from "framer-motion";
import Network from "../utils/Network";
import Skeleton from "react-loading-skeleton";
import { roundFloat } from "../utils/round-float";
import ThousandsSeparator from "../utils/thousands-separator";
import { isMobile } from "react-device-detect";
type data = {
  totalWithdraw: number;
  buildingsValue: number;
  treasureLose: number;
  totalDeposit: number;
};

const TreasureLose = () => {
  const axios = new Network();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<data>();
  const handleGetData = async () => {
    const response = await axios.CallApi({
      url: "/player/treasure-lose",
      method: "get",
    });
    setData(response.data);
    setLoading(false);
  };
  useEffect(() => {
    handleGetData();
  }, []);
  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 0,
          left: 0,
          top: 0,
          height: "100%",
          background: "rgba(0,0,0,0.24)",
        }}
      />
      <motion.div
        initial={{ opacity: 0, y: 300 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 300 }}
      >
        <MidBox modal title="Treasure Lose">
          <div className="text-center">
            Pirates and goblins are seeking for vulnerable players to steal
            their treasures. See how much they steal from you in Dungeons and
            Treasure Islands.
          </div>
          <br />
          <br />
          <br />
          <div className="d-flex flex-column align-items-end gap-sm-4 gap-2 justify-content-center">
            <div className="row align-items-start w-100">
              <div className="text-md-end col-sm-6 col-12">
                <div className="fs-big">Total Withdrawals</div>
                <div className="fs-tiny text-secondary">
                  Sum of your withdrawals
                </div>
              </div>
              <div className="fs-big col-sm-6 col-12">
                {loading ? (
                  <Skeleton width={111} />
                ) : (
                  <>
                    <b>{ThousandsSeparator(roundFloat(data?.totalWithdraw!))}</b>
                    &nbsp; CLEG
                  </>
                )}
              </div>
              {isMobile && <hr className="w-100 mt-4" />}
            </div>
            <div className="row align-items-start w-100">
              <div className="text-md-end col-sm-6 col-12">
                <div className="fs-big">Total Deposits</div>
                <div className="fs-tiny text-secondary"> Sum of your deposits</div>
              </div>
              <div className="fs-big col-sm-6 col-12">
                {loading ? (
                  <Skeleton width={111} />
                ) : (
                  <>
                    <b>{ThousandsSeparator(roundFloat(data?.totalDeposit!))}</b>
                    &nbsp; CLEG
                  </>
                )}
              </div>
              {isMobile && <hr className="w-100 mt-4" />}
            </div>
            <div className="row align-items-start w-100">
              <div className="text-md-end  col-sm-6 col-12">
                <div className="fs-big">Building Value</div>
                <div className="fs-tiny text-secondary">
                  Total CLEG you spent to upgrade your buildings
                </div>
              </div>
              <div className="fs-big col-sm-6 col-12">
                {loading ? (
                  <Skeleton width={111} />
                ) : (
                  <>
                    <b>
                      {ThousandsSeparator(roundFloat(data?.buildingsValue!))}
                    </b>
                    &nbsp; CLEG
                  </>
                )}
              </div>
              {isMobile && <hr className="w-100 mt-4" />}
            </div>

            <div className="row align-items-center w-100">
              <div className="text-md-end col-sm-6 col-12">
                <div className="fs-big">Your Treasure Loss</div>
                <div className="fs-tiny text-secondary">
                  How much of resources you will lose in dungeons and treasure
                  islands
                </div>
              </div>
              <div className="fs-big col-sm-6 col-12">
                {loading ? (
                  <Skeleton width={"80%"} />
                ) : (
                  <>
                    <Slider
                      range
                      marks={{
                        0: <span className="ms-4 text-white">0%</span>,
                        [+roundFloat(data?.treasureLose!)]: (
                          <b className="fs-big color-orange ">
                            {roundFloat(data?.treasureLose!, 1, 2)}%
                          </b>
                        ),
                        20: <span className="text-white me-4">20%</span>,
                      }}
                      max={20}
                      value={data?.treasureLose}
                      min={0}
                      className={" mt-2 mb-3"}
                      style={{ width: " 100%", maxWidth: 458 }}
                      step={1}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div style={{ maxWidth: 800 }} className="fs-med text-center mx-auto">
            This mechanism is beneficial for all players even those who have the
            highest treasure loss to keep the ecosystem healthy and keep CLEG
            price in a constant uptrend.
          </div>
        </MidBox>
      </motion.div>
    </div>
  );
};
export default TreasureLose;
