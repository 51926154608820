import React from "react";
import YouTube from "react-youtube";
import index from "../Barracks";
type Props = {};

const data = [
  {
    index: 0,
    title: "Game Play overview",
    videoId: "KXGCR8731sU",
  },
  {
    index: 1,
    title: "Create a Wallet, Buy, Deposit, Withdraw and Sell CLEG",
    videoIds: [
      {
        title: "for PC",
        id: "L_tQBctzsCU",
      },
      {
        title: "for Mobile",
        id: "xgb-vumhf3E",
      },
    ],
  },
  {
    index: 2,
    title: "Referral Program",
    videoId: "QZXe60Hb9UM",
  },
  {
    index: 3,
    title: "Barracks and Dungeon Adventures",
    videoId: "RCnLJ9Dqdc0",
  },
  {
    index: 4,
    title: "Treasure Island",
    videoId: "61d0Z94jYfo",
  },
  {
    index: 5,
    title: "CLEG Tokenomics (Economy of the game)",
    videoId: "kpS4dyrw3wU",
  },
  {
    index: 6,
    title: "Guilds",
    videoId: "jfrbQnfEcOQ",
  },
  {
    index: 7,
    title: "Arena Battles",
    videoId: "j1LtJgsvSCU",
  }
];

const HowToPlay = (props: Props) => {
  return (
    <div className="py-4 text-white">
      <div className=" text-center">
        Here is a list of short videos about how to play Chain of Legends
      </div>
      <div className="row justify-content-center">
        {data.map(({ index, title, videoId, videoIds }) => (
          <div
            className={
              (index > 1 ? " col-lg-6 col-12" : "col-12") +
              " mt-5 d-flex align-items-center flex-column justify-content-center"
            }
          >
            <div className="fs-lg mb-3 ">
              {index + 1}.{title}
            </div>
            {videoIds ? (
              <div className="w-100 justify-content-between d-flex align-items-center">
                {videoIds?.map((vid) => (
                  <div className="text-center">
                    <YouTube
                      style={{ height: 300, width: 500 }}
                      iframeClassName="w-100 h-100"
                      videoId={vid.id}
                    />
                    <div className="mt-3">{vid.title}</div>
                  </div>
                ))}
              </div>
            ) : (
              <YouTube
                style={{ height: 300, width: 500 }}
                iframeClassName="w-100 h-100"
                videoId={videoId}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToPlay;
