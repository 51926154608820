import React, { createContext, useReducer, ReactNode, Dispatch } from "react";
import { GameContextActions, State } from "../../types";
import { Actions } from "../../types/enum";

const initialState: State = {
  slots: [],
  models: [],
};

const SlotContext = createContext<{
  state: State;
  dispatch: Dispatch<GameContextActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const slotReducer = (state: State, action: GameContextActions): State => {
  switch (action.type) {
    case Actions.MOVE_MODEL:
      return {
        ...state,
        models: state.models.map((model) =>
          model.id === action.payload.id
            ? { ...model, ...action.payload }
            : model
        ),
      };
    case Actions.SELECT_SLOT:
      return {
        ...state,
        slots: state.slots.map((slot) =>
          slot.id === action.payload.id
            ? { ...slot, ...action.payload }
            : { ...slot, selected: false }
        ),
      };
    case Actions.UPDATE_SLOT:
      return {
        ...state,
        slots: state.slots.map((slot) =>
          slot.id === action.payload.id ? { ...slot, ...action.payload } : slot
        ),
      };
    case Actions.ADD_SLOTS:
      return {
        ...state,
        slots: [...state.slots, ...action.payload],
      };
    case Actions.SET_MODELS:
      return {
        ...state,
        models: action.payload,
      };
    default:
      return state;
  }
};

const SlotProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(slotReducer, initialState);

  return (
    <SlotContext.Provider value={{ state, dispatch }}>
      {children}
    </SlotContext.Provider>
  );
};

export { SlotContext, SlotProvider };
