import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DepositCard from "./DepositCard";
import SecurityAlert from "./SecurityAlert";
import WalletAddressBox from "./WalletAddressBox";
import WithdrawCard from "./WithdrawCard";

type Props = {};

const ProfilePage = (props: Props) => {
  
  useEffect(() => {
    setTimeout(() => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) element.scrollIntoView();
      }
    }, 1000);
  }, []);

  return (
    <Row>
      <Helmet>
        <title key={"title"}>Profile | Chain Of Legends</title>
      </Helmet>
      <Col>
        <Row>
          <WalletAddressBox />
          <SecurityAlert />
        </Row>
        <Row>
          <DepositCard />
          <WithdrawCard />
        </Row>
      </Col>
    </Row>
  );
};

export default ProfilePage;
