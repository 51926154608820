import { useTour } from "@reactour/tour";
import DOMPurify from "dompurify";
import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CountUp from "react-countup";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useToast from "../hooks/useToast";
import useWindowSize from "../hooks/useWindowSize";
import { setCurrentCycle } from "../redux/cycle/action";
import { setUserInfo } from "../redux/user/action";
import Network, { IsBeta, ProfileURL } from "../utils/Network";
import ThousandsSeparator from "../utils/thousands-separator";
import Board from "./Board";
import CircularProgress from "./CircularProgress";
import { CopyTooltip } from "./CopyTooltip";
import { CustomIcon } from "./Icons";

type Props = {
  toggleDrawer: Function;
  handleLogout: Function;
};

const Navbar = (props: Props) => {
  const userInfo = useSelector((state: any) => state.userInfo);
  const userToken = useSelector((state: any) => state.userToken);
  const currentCycle = useSelector((state: any) => state.currentCycle);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openToast = useToast();
  const { setIsOpen } = useTour();
  const windowSize = useWindowSize();
  const axios = new Network();
  const location = useLocation();
  const [notifLoading, setNotifLoading] = useState(false);
  const getCycle = async () => {
    const response = await axios.CallApi({
      url: "/cycles/current",
      method: "get",
    });
    if (response.ok) {
      dispatch(setCurrentCycle(response?.data));
    }
  };
  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };
  const unreadNotifications = useMemo(() => {
    return userInfo?.unreadNotifications;
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userToken) {
      getCycle();
    }
  }, []);
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const markAsRead = async (id: number, redirect?: boolean) => {
    setNotifLoading(true);
    await axios.CallApi({
      url: "/notification/read",
      method: "post",
      body: [id],
    });
    if (!redirect) {
      await getMe();
      setNotifLoading(false);
    } else {
      if (!userInfo.newNotification.url) {
        await getMe();
        setNotifLoading(false);
        return;
      }
      window.location.href = userInfo.newNotification.url;
    }
  };
  const isThereNotif = useMemo(
    () => Boolean(userInfo?.newNotification),
    [userInfo]
  );

  const NavbarNotification = () => (
    <>
      <motion.div
        // initial={isMobile ? undefined : { y: -200 }}
        // animate={{ y: 0 }}
        exit={{ y: -200 }}
        className="d-flex w-100 notif-bar align-items-center rounded gap-2"
      >
        {notifLoading ? (
          <CircularProgress style={{ height: 30, width: 30 }} />
        ) : (
          <div
            onClick={() => markAsRead(userInfo?.newNotification?.id)}
            className="icon-btn small border "
          >
            <CustomIcon icon="close" type="svg" width={15} height={15} />
          </div>
        )}
        <a
          onClick={() => markAsRead(userInfo?.newNotification?.id, true)}
          className="text-white link fs-sm-med"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(userInfo?.newNotification?.message, {
                ALLOWED_TAGS: ["a"],
              }),
            }}
          />
        </a>
        <div className="flex-grow-1" />
        <Link to="/notifications">
          {unreadNotifications ? (
            <div className="badge-dot p-2 absolute fs-tiny">
              {unreadNotifications > 99 ? "+99" : unreadNotifications}
            </div>
          ) : (
            ""
          )}
          <img className="p-icon" src="/assets/images/icons/notification.png" />
        </Link>
      </motion.div>
    </>
  );

  return (
    <div
      style={{ zIndex: 10 }}
      className="row p-relative py-2 py-md-4 mb-2 align-items-end align-items-md-center text-white"
    >
      <div className="col-4 col-sm-6  col-md-2 ">
        <motion.div
          transition={{ duration: 0.3 }}
          initial={isMobile ? undefined : { y: -200 }}
          animate={{ y: 0 }}
          exit={{ y: -200 }}
          className="p-relative "
        >
          <Link
            to="/ranking"
            className="status-badge pointer d-flex align-items-center gap-1"
          >
            <CustomIcon width={25} height={25} className="mb-0" icon="crown" />
            {ThousandsSeparator(userInfo?.rank)}
          </Link>
          {userInfo?.photoFile ? (
            <div
              style={{ backgroundImage: "url(/assets/images/avatar-bg.png)" }}
              className={"main-avatar"}
            >
              <img
                src={`${ProfileURL}/${userInfo?.id}/${userInfo?.photoFile}`}
                width={"70%"}
                height={"70%"}
                style={{
                  position: "absolute",
                  borderRadius: "50%",
                  top: "12%",
                  right: 0,
                  left: "12%",
                }}
              />
              <img
                onClick={() => props.toggleDrawer()}
                width={75}
                height={70}
                style={{ position: "absolute", zIndex: 10 }}
                className="button settings pgtr-11"
                src="/assets/images/icons/setting.png"
              />
              {currentCycle && (
                <Link to={"/learn?category=cycles"}>
                  <motion.div
                    initial={isMobile ? undefined : { opacity: 0, scaleX: 0 }}
                    animate={{ opacity: 1, scaleX: 1 }}
                    className="cycle-badge-3 pgtr-12 font-weight-normal"
                  >
                    <span
                      style={{
                        filter: "brightness(10%) sepia(100%) contrast(1.5)",
                        fontWeight: 500,
                      }}
                    >
                      Cycle {currentCycle?.Number}
                    </span>
                  </motion.div>
                </Link>
              )}
            </div>
          ) : (
            <div className={"main-avatar"}>
              <img
                onClick={() => props.toggleDrawer()}
                width={75}
                height={70}
                style={{ position: "absolute", zIndex: 10 }}
                className="button settings pgtr-11"
                src="/assets/images/icons/setting.png"
              />
              {currentCycle && (
                <Link to={"/learn?category=cycles"}>
                  <motion.div
                    initial={isMobile ? undefined : { opacity: 0, scaleX: 0 }}
                    animate={{ opacity: 1, scaleX: 1 }}
                    className="cycle-badge-3 pgtr-12 font-weight-normal"
                  >
                    <span
                      style={{
                        filter: "brightness(10%) sepia(100%) contrast(1.5)",
                        fontWeight: 500,
                      }}
                    >
                      Cycle {currentCycle?.Number}
                    </span>
                  </motion.div>
                </Link>
              )}
            </div>
          )}
        </motion.div>
      </div>

      {windowSize?.width < 768 && (
        <div className="col-8 col-sm-6 d-block">
          <div className="btn-list-box d-flex flex-wrap flex-row-reverse justify-content-start justify-content-lg-start">
            {!isThereNotif && (
              <Link to="/notifications">
                <motion.div
                  transition={{ duration: 0.3, delay: 0.45 }}
                  initial={isMobile ? undefined : { y: -150 }}
                  animate={{ y: 0 }}
                  exit={{ y: -200 }}
                >
                  <div className="btn-list-item d-flex align-items-center ms-1 pgtr-10 ">
                    <button
                      className="polygon-btn blue"
                      name="btnShowNotification"
                    >
                      {unreadNotifications ? (
                        <div className="badge-dot absolute">
                          {unreadNotifications}
                        </div>
                      ) : (
                        ""
                      )}
                      <img
                        className="p-icon"
                        src="/assets/images/icons/notification.png"
                      />
                    </button>
                  </div>
                </motion.div>
              </Link>
            )}
            <motion.div
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={isMobile ? undefined : { y: -150 }}
              animate={{ y: 0 }}
              exit={{ y: -200 }}
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Tour Guide</Tooltip>}
              >
                <div
                  onClick={() => setIsOpen(true)}
                  className="btn-list-item pgtr-11-1 d-flex align-items-center ms-1"
                >
                  <div
                    style={{ minWidth: 50 }}
                    className="polygon-btn px-0 yellow"
                  >
                    <img
                      className="p-icon"
                      width={35}
                      src="/assets/images/icons/info.png"
                    />
                  </div>
                </div>
              </OverlayTrigger>
            </motion.div>
            {/* <div className="btn-list-item d-flex align-items-center ms-1 d-none">
                        <button className="polygon-btn brown" name="btnShowMessage"><img className="p-icon" src="/assets/images/icons/icon-notification.png"/></button>
                    </div> */}
            <motion.div
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={isMobile ? undefined : { y: -150 }}
              animate={{ y: 0 }}
              exit={{ y: -200 }}
            >
              <Link to="/learn">
                <div className="btn-list-item d-flex align-items-center ms-1">
                  <div className="polygon-btn green">
                    <img width={25} src="/assets/images/icons/learn.png" />
                    <span className="text-light fs-med mb-0 mx-1 mx-md-3">
                      Learn
                    </span>
                  </div>
                </div>
              </Link>
            </motion.div>
          </div>
        </div>
      )}

      <div className="col-12 mt-3 mt-md-0 col-md-10 d-flex align-items-center justify-content-between">
        {/* <div className="col-12 mt-3 mt-md-0 col-md-10"> */}
        {windowSize.width > 1200 &&  <Board />}
        <div className="ms-auto">
          <div className="d-flex  flex-wrap flex-row-reverse justify-content-center justify-content-lg-start mb-3 mb-md-4 ms-auto">
            <motion.div
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={isMobile ? undefined : { y: -200 }}
              animate={{ y: 0 }}
              exit={{ y: -200 }}
            >
              <div className="status-frame d-flex align-items-center ms-3 ">
                <img
                  width={40}
                  height={40}
                  style={{ marginLeft: -8 }}
                  className="ms-n2"
                  src="/assets/images/icons/cleg.png"
                />
                <span style={{ fontSize: 18 }}>
                  <CountUp
                    duration={1}
                    decimals={0}
                    end={userInfo?.totalCleg || 0}
                  />
                </span>
                <Link
                  to={IsBeta ? "#" : "/profile"}
                  className={
                    "button p-relative sixteen-step mr-n2 p-0 " +
                    (IsBeta && "button-disabled")
                  }
                >
                  <img
                    width={30}
                    height={30}
                    src="/assets/images/buttons/add-button.png"
                  />
                </Link>
              </div>
            </motion.div>
            <motion.div
              transition={{ duration: 0.3, delay: 0.2 }}
              initial={isMobile ? undefined : { y: -150 }}
              animate={{ y: 0 }}
              exit={{ y: -200 }}
            >
              <div className="status-frame d-flex align-items-center ms-3">
                <img
                  width={40}
                  height={40}
                  style={{ marginLeft: -8 }}
                  className="ms-n2"
                  src="/assets/images/icons/metal.png"
                />
                <span style={{ fontSize: 18 }}>
                  <CountUp
                    duration={1}
                    decimals={0}
                    end={userInfo?.totalIron || 0}
                  />
                </span>
                <Link to="/marketplace?type=2" className="button  mr-n2 p-0">
                  <img
                    width={30}
                    height={30}
                    src="/assets/images/buttons/add-button.png"
                  />
                </Link>
              </div>
            </motion.div>
            <motion.div
              transition={{ duration: 0.3, delay: 0.1 }}
              initial={isMobile ? undefined : { y: -150 }}
              animate={{ y: 0 }}
              exit={{ y: -200 }}
            >
              <div className="status-frame d-flex align-items-center ms-3">
                <img
                  width={40}
                  height={40}
                  style={{ marginLeft: -8 }}
                  className="ms-n2"
                  src="/assets/images/icons/stone.png"
                />
                <span style={{ fontSize: 18 }}>
                  <CountUp
                    duration={1}
                    decimals={0}
                    end={userInfo?.totalStone || 0}
                  />
                </span>
                <Link to="/marketplace?type=1" className="button  mr-n2 p-0">
                  <img
                    width={30}
                    height={30}
                    src="/assets/images/buttons/add-button.png"
                  />
                </Link>
              </div>
            </motion.div>
          </div>
          {isThereNotif && <NavbarNotification />}

          {!isThereNotif && (
            <div className="btn-list-box d-none d-md-flex flex-wrap flex-row-reverse justify-content-center justify-content-lg-start">
              <Link to="/notifications">
                <motion.div
                  transition={{ duration: 0.3, delay: 0.45 }}
                  initial={isMobile ? undefined : { y: -200 }}
                  animate={{ y: 0 }}
                  exit={{ y: -200 }}
                >
                  <div className="btn-list-item d-flex pgtr-10 align-items-center ms-3 twentythree-step">
                    <button
                      className="polygon-btn blue"
                      name="btnShowNotification"
                    >
                      {unreadNotifications ? (
                        <div className="badge-dot p-2 absolute fs-tiny">
                          {unreadNotifications > 99
                            ? "+99"
                            : unreadNotifications}
                        </div>
                      ) : (
                        ""
                      )}
                      <img
                        className="p-icon"
                        src="/assets/images/icons/notification.png"
                      />
                    </button>
                  </div>
                </motion.div>
              </Link>
              {/* <div className="btn-list-item d-flex align-items-center ms-3 d-none">
                        <button className="polygon-btn brown" name="btnShowMessage"><img className="p-icon" src="/assets/images/icons/icon-notification.png"/></button>
                    </div> */}
              <motion.div
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={isMobile ? undefined : { y: -200 }}
                animate={{ y: 0 }}
                exit={{ y: -200 }}
              >
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Tour Guide</Tooltip>}
                >
                  <div
                    onClick={() => setIsOpen(true)}
                    className="btn-list-item d-flex align-items-center ms-3"
                  >
                    <div
                      style={{ minWidth: 50 }}
                      className="polygon-btn  pgtr-11-1 px-0 yellow"
                    >
                      <img
                        className="p-icon"
                        width={35}
                        src="/assets/images/icons/info.png"
                      />
                    </div>
                  </div>
                </OverlayTrigger>
              </motion.div>
              <motion.div
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={isMobile ? undefined : { y: -200 }}
                animate={{ y: 0 }}
                exit={{ y: -200 }}
              >
                <Link to="/learn">
                  <div className="btn-list-item d-flex align-items-center ms-3">
                    <div className="polygon-btn green">
                      <img width={25} src="/assets/images/icons/learn.png" />
                      <span className="text-light fs-med mb-0 mx-1 mx-md-3">
                        Learn
                      </span>
                    </div>
                  </div>
                </Link>
              </motion.div>
              <motion.div
                transition={{ duration: 0.3, delay: 0.35 }}
                initial={isMobile ? undefined : { y: -200 }}
                animate={{ y: 0 }}
                exit={{ y: -200 }}
              >
                <div className="btn-list-item d-flex align-items-center ms-3">
                  <CopyTooltip text={userInfo?.walletAddress?.toString()}>
                    <span
                      style={{
                        fontSize: "initial",
                        width: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      {userInfo?.walletAddress?.slice(0, 7)}...
                      {userInfo?.walletAddress?.slice(
                        userInfo?.walletAddress?.length - 7,
                        userInfo?.walletAddress?.length
                      )}
                    </span>
                  </CopyTooltip>
                </div>
              </motion.div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {windowSize.width <= 1200 && !IsBeta && <Board />}
      </div>
    </div>
  );
};

export default Navbar;
