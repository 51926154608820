import React, { useEffect, useRef } from 'react'
import { useConnector } from '../connector/context'
import { useBattleContext } from '../context'
import { ConnectionStatus } from '../types/enum'

type Props = {}

const usePing = () => {
    const { connectionStatus, setConnectionStatus } = useBattleContext()
    const { connector } = useConnector()
    const pingIntervalRef  = useRef<NodeJS.Timeout | null>(null)
    const pong = () => {
        clearTimeout(pingIntervalRef.current as NodeJS.Timeout)
        pingIntervalRef.current = null
    }
    useEffect(() => {
        if (!connector) return

        const ping = () => {
            if (connectionStatus !== ConnectionStatus.Connected) return

            connector.ping()

            // Set a timeout to update the status if there's no response
            pingIntervalRef.current = setTimeout(() => {
                if (connectionStatus === ConnectionStatus.Connected) {
                    setConnectionStatus(ConnectionStatus.Connecting)
                }
            }, 8000)
        }

        // Clear any existing intervals and set up a new one
        pingIntervalRef.current = setInterval(ping, 5000)

        return () => {
            // Clear both the interval and timeout on cleanup
            clearInterval(pingIntervalRef.current as NodeJS.Timeout)
            pong()
        }
    }, [connectionStatus, connector])

    return { timer: pingIntervalRef.current, pong }
}

export default usePing