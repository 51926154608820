import React from 'react'
import Lottie from "lottie-react";
import animationData from '../animations/error-404.json'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet';

type Props = {}

const NotFound = (props: Props) => {
    return (
        <div style={{ minHeight: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Helmet>
                <title key="title">
                    Page Not Found | Chain Of Legends
                </title>
            </Helmet>
            <motion.div
                transition={{ duration: 0.3, delay: 0.1 }} initial={{ y: 250, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -250, opacity: 0 }}
            >
                <Lottie
                    animationData={animationData}
                    style={{ width: 300, height: 'auto' }}
                    autoPlay
                    loop={true}
                />
            </motion.div>
            <motion.p
                transition={{ duration: 0.3, delay: 0.2 }} initial={{ y: 250, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 250, opacity: 0 }}
                className='dark-yellow text-center fs-big'>
                Sorry , the page you are looking for doesn't exist .
            </motion.p>

        </div >
    )
}

export default NotFound