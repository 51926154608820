import React, { FC, PropsWithChildren, useEffect } from "react";
import { useConnector } from "../connector/context";
import useEventHandler from "../hooks/useEventHandler";

const EventsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { connector } = useConnector();
  const eventHandlers = useEventHandler();

  useEffect(() => {
    if (connector) {
      connector.setEventHandlers(eventHandlers);
    }
  }, [connector, eventHandlers]);

  return children;
};

export default EventsProvider;
