import React, { useState } from "react";
import MidBox from "../../../components/Boxes/MidBox";
import { motion } from "framer-motion";
import Button from "../../../components/Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSearchParams } from "react-router-dom";
import Share from "../../../components/Share";

type Props = {};

// TODO: connect to backend

const HOST_URL = "/arena/join-match";

const FriendlyMatchBox = (props: Props) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [isShareOpen, setIsShareOpen] = useState(false);
  const link = window.location.origin + HOST_URL + "?id=" + id;
  const onCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <motion.div
      initial={{ maxWidth: 0, opacity: 0 }}
      animate={{ opacity: 1, maxWidth: 450 }}
      className="mx-auto overflow-hidden"
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      <MidBox
        noMargin
        style={{ maxWidth: 450, opacity: 0.95 }}
        title="Friendly Match"
        className="mx-auto "
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="d-flex gap-2 flex-column  justify-content-center "
        >
          <ul>
            <li>Create match to play with a friend.</li>
            <li>Share your battle link with your friend.</li>
          </ul>
          <div>
            <span>Link</span>
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-${"top"}`}>
                  {isCopied ? (
                    <span className="text-success-light">
                      {" "}
                      Successfully Copied
                    </span>
                  ) : (
                    <>Copy To Clipboard</>
                  )}
                </Tooltip>
              }
            >
              <CopyToClipboard onCopy={onCopy} text={link}>
                <div className="bg-3 link-border pointer p-2  fs-small rounded-4 gap-2 d-flex bg-2 align-items-center justify-content-center">
                  {link}
                </div>
              </CopyToClipboard>
            </OverlayTrigger>
          </div>

          <div className="d-flex mt-3 align-items-center justify-content-center">
            <CopyToClipboard onCopy={onCopy} text={link}>
              <Button variant="default" className="bg-100">
                Copy
              </Button>
            </CopyToClipboard>
            <Button
              onClick={() => setIsShareOpen(true)}
              variant="primary"
              className="bg-100"
            >
              Share
            </Button>
          </div>
        </motion.div>
      </MidBox>
      <Share
        shareNote="Join me for a friendly battle on Chain of Legends! Let’s see who reigns supreme—are you up for the challenge? 🛡️🔥"
        open={isShareOpen}
        onClose={() => setIsShareOpen(false)}
        link={link}
      />
    </motion.div>
  );
};

export default FriendlyMatchBox;
