import React, { useState } from "react";
import Countdown from "react-countdown";
import CircularProgress from "./CircularProgress";
import { Badge, Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import TimeAgo from "javascript-time-ago";
import { roundFloat } from "../utils/round-float";
import IconButton from "./IconButton";
import { Link, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Button from "./Button";

type Props = {
  loading?: boolean;
  data: any;
  refreshList: () => void;
  onShare: () => void;
  onDetail?: () => void;
  onCancel?: (id: number) => void;
  detailLoading?: boolean | number;
  allTroops?: any[];
};

const AdventureCard = ({
  data,
  refreshList,
  loading,
  onShare,
  onCancel,
  onDetail,
  detailLoading,
  allTroops,
}: Props) => {
  const timeAgo = new TimeAgo("en-US");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  if (data && !loading)
    return (
      <div
        style={{
          opacity: data?.state === 5 ? 0.99 : 1,
          filter: data?.state === 5 ? "grayscale(50%)" : "",
        }}
        className="adventure-card mx-auto
        "
      >
        {data?.underAttack && (
          <div className=" fs-tiny under-attack-badge text-center">
            Under Attack
            {/* (<Countdown
                            onComplete={() => refreshList()}
                            date={new Date(data?.underAttack)}
                            renderer={({ days, hours, minutes, seconds }) => ((<>
                                {days}D {hours}h {minutes}m {seconds}s
                            </>))}
                        />) */}
          </div>
        )}

        <div className="d-flex">
          <div className="adventure-card-content">
            <div className="d-flex gap-2 align-items-center">
              <img
                style={{
                  maxWidth: 90,
                  maxHeight: 90,
                }}
                src={
                  data?.treasure?.treasureType == 1
                    ? `/assets/images/icons/dungeon/${data?.treasure?.id}.png`
                    : `/assets/images/lands/treasure/${data?.treasure?.id}.png`
                }
              />
              <div className="d-flex flex-column ">
                {data?.state === 5 && (
                  <div className="text-danger bold">Canceled</div>
                )}
                <div className="fs-lg bold">
                  {data?.state === 4 &&
                    (data?.result === 1 ? (
                      <span className="text-success me-1">
                        {(data?.defeats > 0 || data?.victories > 1) &&
                          data?.victories}{" "}
                        Victory
                      </span>
                    ) : (
                      <span className="text-danger">Defeat</span>
                    ))}
                  {data?.state === 4 &&
                    data?.defeats > 0 &&
                    data?.victories > 0 && (
                      <>
                        |
                        <span className="text-danger ms-1">
                          {data?.defeats} Defeat
                        </span>
                      </>
                    )}
                </div>
                <div>
                  {data?.state === 0 && (
                    <div className="fs-med  ">
                      <div>
                        <CircularProgress />
                        Waiting for others <br /> to contribute
                      </div>
                    </div>
                  )}
                  {data?.state === 1 && (
                    <div className=" fs-lg  ">
                      <>
                        Remaining
                        <br />{" "}
                        <Countdown
                          onComplete={() => refreshList()}
                          date={
                            data?.treasure?.treasureType === 2
                              ? new Date(data?.arriveDate)
                              : new Date(data?.returnDate)
                          }
                          renderer={({ days, hours, minutes, seconds }) => (
                            <>
                              {days}D {hours}h {minutes}m {seconds}s
                            </>
                          )}
                        />
                      </>
                    </div>
                  )}
                  {data?.state === 2 && (
                    <div className=" fs-lg">
                      <b>Collecting </b>
                      <br />{" "}
                      <Countdown
                        onComplete={() => refreshList()}
                        date={new Date(data?.returningDate)}
                        renderer={({ days, hours, minutes, seconds }) => (
                          <>
                            {days}D {hours}h {minutes}m {seconds}s
                          </>
                        )}
                      />
                    </div>
                  )}

                  {data?.state === 3 && (
                    <div className="mt-1 fs-lg">
                      <b>Returning </b>
                      <br />(
                      <Countdown
                        onComplete={() => refreshList()}
                        date={new Date(data?.returnDate)}
                        renderer={({ days, hours, minutes, seconds }) => (
                          <>
                            {days}D {hours}h {minutes}m {seconds}s
                          </>
                        )}
                      />
                      )
                    </div>
                  )}

                  {data?.state === 4 && (
                    <div className="text-secondary fs-small">
                      {timeAgo.format(new Date(data?.startDate))}
                    </div>
                  )}
                  {data?.state === 0 && (
                    <Link to={"/treasure/modify/" + data?.id}>
                      <Button variant="wide" size="small" className="px-3 mt-1">
                        Edit Adventure
                      </Button>
                    </Link>
                  )}
                  {data?.state === 2 && (
                    <Link to={"/treasure/modify/" + data?.id}>
                      <Button variant="wide" size="small" className="px-3 mt-1">
                        Defend Adventure
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-1">
              {data?.state === 4 &&
                data?.result && [
                  <div>
                    <div
                      style={{
                        fontSize: 12,
                        zIndex: 10,
                        padding: "2px 6px",
                        borderRadius: "7px",
                        background: "#2b271e",
                        height: "fit-content",
                        verticalAlign: "bottom",
                      }}
                      className=""
                    >
                      <img
                        style={{ position: "relative", zIndex: 11 }}
                        className="me-1"
                        src="/assets/images/icons/cleg.png"
                        width={15}
                        height={15}
                      />
                      x{roundFloat(data?.collectedCleg)}
                    </div>
                  </div>,
                  <div>
                    <div
                      style={{
                        fontSize: 12,
                        zIndex: 10,
                        padding: "2px 6px",
                        borderRadius: "7px",
                        background: "#2b271e",
                        height: "fit-content",
                        verticalAlign: "bottom",
                      }}
                      className=""
                    >
                      <img
                        style={{ position: "relative", zIndex: 11 }}
                        className="me-1"
                        src="/assets/images/icons/stone.png"
                        width={15}
                        height={15}
                      />
                      x{roundFloat(data?.collectedStone)}
                    </div>
                  </div>,
                  <div>
                    <div
                      style={{
                        fontSize: 12,
                        zIndex: 10,
                        padding: "2px 6px",
                        borderRadius: "7px",
                        background: "#2b271e",
                        height: "fit-content",
                        verticalAlign: "bottom",
                      }}
                      className=""
                    >
                      <img
                        style={{ position: "relative", zIndex: 11 }}
                        className="me-1"
                        src="/assets/images/icons/metal.png"
                        width={15}
                        height={15}
                      />
                      x{roundFloat(data?.collectedIron)}
                    </div>
                  </div>,
                ]}
              {data?.state === 0 && (
                <Button
                  onClick={() => onCancel && onCancel(data?.id)}
                  loading={false}
                  className="ms-auto me-2"
                  size="small"
                  variant="secondary"
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <IconButton
              disabled={data?.state === 0 || data?.state === 1}
              onClick={() =>
                (window.location.href = `/battle/treasure/${encodeURIComponent(
                  data?.replayId
                )}`)
              }
              style={{ width: 33, height: 33 }}
              icon={<img width={10} src="/assets/images/icons/play.svg" />}
            />
            <IconButton
              disabled={
                data?.treasure?.treasureType === 1 ||
                data?.state === 1 ||
                data?.state === 5
              }
              onClick={onShare}
              style={{ width: 33, height: 33 }}
              icon={<img width={12} src="/assets/images/icons/share.svg" />}
            />

            {data?.treasure?.treasureType === 2 && (
              <IconButton
                loadingStyle={{ width: 14, height: 14 }}
                loading={Boolean(detailLoading)}
                onClick={onDetail}
                style={{ width: 33, height: 33 }}
                icon={<img width={14} src="/assets/images/icons/info.png" />}
              />
            )}
          </div>
        </div>
        <div>
          {!open && (
            <Collapse in={!open} className="fs-tiny dark-yellow text-center ">
              <div
                onClick={() => setOpen(true)}
                className="d-flex align-items-center justify-content-center"
              >
                <img width={20} src="/assets/images/icons/arrow-down.png" />{" "}
                Show Troops
              </div>
            </Collapse>
          )}
          <Collapse in={open}>
            <PerfectScrollbar style={{ width: "100%", maxWidth: "100%" }}>
              <div
                style={{ width: "100%", maxWidth: "100%" }}
                className="d-flex align-items-center justify-content-end pb-2"
              >
                {data?.formation?.map((troop: any, index) => {
                  if (troop.troopId) {
                    const troopData = allTroops?.find(
                      (item) => item?.id === troop.troopId
                    );
                    return (
                      <OverlayTrigger
                        placement={"bottom"}
                        key={index + "p" + troop?.x + troop?.y}
                        overlay={
                          <Tooltip id={`tooltip-${"top"}`}>
                            {troopData?.name}
                          </Tooltip>
                        }
                      >
                        <div
                          style={{ padding: 3 }}
                          className={
                            `dark-badge me-2   ` + (index !== 0 && "ms-1")
                          }
                        >
                          <Badge
                            style={{
                              position: "absolute",
                              bottom: -8,
                              right: 0,
                              left: 0,
                              zIndex: 12,
                              width: "fit-content",
                              fontSize: "9px !important",
                            }}
                            className="mx-auto fs-tiny"
                          >
                            Req
                          </Badge>
                          <img
                            style={{
                              borderRadius: 50,
                              zIndex: 10,
                              position: "relative",
                            }}
                            width={33}
                            height={33}
                            src={troopData?.icon}
                          />
                          {troop?.qty && (
                            <div
                              style={{ top: 0, bottom: "unset" }}
                              className="units fs-tiny"
                            >
                              {troop?.qty}x
                            </div>
                          )}
                        </div>
                      </OverlayTrigger>
                    );
                  }
                  return (
                    <OverlayTrigger
                      // popperConfig={{}}
                      placement={"bottom"}
                      key={index + "p" + troop?.x + troop?.y}
                      overlay={
                        <Tooltip id={`tooltip-${"top"}`}>
                          {troop?.playerTroop?.Troop?.Name}
                        </Tooltip>
                      }
                    >
                      <div
                        style={{ padding: 1 }}
                        className={`dark-badge me-1 ` + (index !== 0 && "me-0")}
                      >
                        <img
                          style={{
                            borderRadius: 50,
                            zIndex: 10,
                            position: "relative",
                          }}
                          width={33}
                          height={33}
                          src={troop?.playerTroop?.Troop?.Icon}
                        />
                        {troop?.qty && (
                          <div className="units fs-tiny">{troop?.qty}x</div>
                        )}
                      </div>
                    </OverlayTrigger>
                  );
                })}
              </div>
            </PerfectScrollbar>
          </Collapse>
        </div>
      </div>
    );

  return <></>;
};

export default AdventureCard;
