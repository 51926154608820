import React from 'react'
import { useDispatch } from 'react-redux'
import { OpenAlert } from '../redux/alert/action'


const useToast = () => {
    const dispatch = useDispatch()
    function openToast(response: { ok?: boolean, message?: string,data?:any ,title?:string}, variant?: 'primary' | 'success' | 'error'|'warning') {
        const message = (response?.ok && !response?.message)? response.data.message:response.message;
        if (!message&&response?.ok) return;
        dispatch(OpenAlert({
            open: true,
            msg:(typeof message ==='object'?message[0]:message) || (!response?.ok ? "Oops..! Operation Failed": ''),
            variant: variant || (response?.ok ? 'success' : 'error'),
            title:response?.title
        }))
    }
    return openToast
}

export default useToast