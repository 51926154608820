
export const leaderBoardTabs = [
    {
        id: 1,
        name: "Lords",
        type :'lords',
        link: "/arena/leader-board/lords",
    },
    {
        id: 2,
        type :'legion',
        name: "Legion",
        link: "/arena/leader-board/legion",
    },
    {
        id: 3,
        name: "Legends",
        type :'legends',
        link: "/arena/leader-board/legends",
    },
];