export type GuildType = {
    id: number;
    ownerId: number;
    level: number;
    name: string;
    description: string;
    joinType: GuildJoinTypeEnum;
    minRankScore: number;
    registerDate: string;
    capacity: number;
    language: string;
    image: string;
    banner: string;
    power: number;
    score: number;
    playersCount: number;
    invited?:boolean;
}

export enum GuildPlayerStateEnum{
    Owner=1,
    Admin=2,
    Member=5,
    JoinRequest = 9,
    Invited=10,
    InviteRejected =11,
    Left=13,
    Kicked=14
  }

  export enum GuildJoinTypeEnum{
    Public = 1,
    JoinRequest = 2,
    InviteOnly = 3,
    AdminInviteOnly = 4
  }

