import React from 'react'
import { motion } from 'framer-motion'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { roundFloat } from '../utils/round-float';
type Props = {
    transparent:boolean;
}

const PlainNavBar = ({transparent}: Props) => {
    const userInfo = useSelector((state: any) => state.userInfo)
    const userToken = useSelector((state: any) => state.userToken)
    return (
        <motion.div 
        style={transparent?{
            position:'absolute',
            backgroundColor:'transparent',
            width:'100%',
            zIndex:300,
        }:undefined}
        transition={{ duration: 0.2, delay: 0 }} initial={isMobile ? undefined : { y: -100 }} animate={{ y: 0 }} exit={{ y: -200 }} className='nav2 px-4 d-flex align-items-center'>
            <Link to="/">
                <motion.div transition={{ duration: 0.3, delay: 0 }} initial={isMobile ? undefined : { y: -100 }} animate={{ y: 0 }} exit={{ y: -200 }} className='d-flex dark-yellow bold align-items-center pointer'>
                    <img width={35}
                        height={35}
                        src="/assets/images/icons/home.png" className='me-2' />
                    Home

                </motion.div>
            </Link>
{/* 
            <div className='d-flex h-auto flex-wrap flex-row-reverse justify-content-center justify-content-lg-start ms-auto'>
                <motion.div transition={{ duration: 0.3, delay: 0.3 }} initial={isMobile ? undefined : { y: -100 }} animate={{ y: 0 }} exit={{ y: -200 }}>
                    <div className='status-frame d-flex align-items-center ms-3'>
                        <img width={40} height={40} style={{ marginLeft: -8 }} className="ms-n2" src="/assets/images/icons/cleg.png" />
                        <span style={{ fontSize: 18 }}>
                            <CountUp duration={1} decimals={0} end={userInfo?.totalCleg || 0} />
                        </span>
                        <Link to="#" className='button button-disabled  mr-n2 p-0'>
                            <img width={30} height={30} src="/assets/images/buttons/add-button.png" />
                        </Link>
                    </div>
                </motion.div>
                <motion.div transition={{ duration: 0.3, delay: 0.2 }} initial={isMobile ? undefined : { y: -100 }} animate={{ y: 0 }} exit={{ y: -200 }}>
                    <div className='status-frame d-flex align-items-center ms-3'>
                        <img width={40} height={40} style={{ marginLeft: -8 }} className="ms-n2" src="/assets/images/icons/metal.png" />
                        <span>
                            {roundFloat(userInfo?.totalIron,0) || 0}
                        </span>
                        <a className='button button-disabled mr-n2 p-0'>
                            <img width={30} height={30} src="/assets/images/buttons/add-button.png" />
                        </a>
                    </div>
                </motion.div>
                <motion.div transition={{ duration: 0.3, delay: 0.1 }} initial={isMobile ? undefined : { y: -100 }} animate={{ y: 0 }} exit={{ y: -200 }}>
                    <div className='status-frame d-flex align-items-center ms-3'>
                        <img width={40} height={40} style={{ marginLeft: -8 }} className="ms-n2" src="/assets/images/icons/stone.png" />
                        <span>
                            {roundFloat(userInfo?.totalStone,0) || 0}
                        </span>
                        <a className='button button-disabled mr-n2 p-0'>
                            <img width={30} height={30} src="/assets/images/buttons/add-button.png" />
                        </a>
                    </div>
                </motion.div>
            </div> */}

        </motion.div>
    )
}

export default PlainNavBar;