const prepareTroops = (troops, playerIsAttacker, isAttacker, stateRef) => {
    return troops.map((a, i) => ({
        ...a,
        position: { x: a.x >= 0 ? a.x : i, y: a.y >= 0 ? 5-a.y : 6 },
        isAttacker,
        y:a.y,
        x: a.x,
        attackKey: 0,
        moveKey: 0,
        healKey: 0,
        isFriend: playerIsAttacker === isAttacker,
        troop: stateRef.current.troops.find((t) => t.id === troops[i].troopId),
    }));
};

export default prepareTroops;