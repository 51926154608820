import { actionTypes } from './action';

export type ToastType = {
    open: boolean;
    variant: 'success' | 'error' | 'warning' | 'info' | "primary";
    msg: string;
    title?: string;
}

export const defaultState: ToastType = { open: false, variant: 'success', msg: '' };

export const AlertReducer = (state = defaultState, action: { type: string, payload: ToastType }) => {
    switch (action.type) {
        case actionTypes.SET_ALERT_OPEN:
            return action.payload;
        case actionTypes.SET_ALERT_CLOSE:
            return action.payload;
        default:
            return state;
    }
};
