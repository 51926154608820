import { actionTypes } from './action';

export const defaultQuestState = [];

export const QuestReducer = (state = defaultQuestState, action) => {
    switch (action.type) {
        case actionTypes.SET_QUESTS:
            return action.payload;
        case actionTypes.CLEAR_QUESTS:
            return defaultQuestState;
        default:
            return state;
    }
};
