import React, { useEffect, useState } from "react";
// import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigator from "./components/Navigator";
import { Provider } from "react-redux";
import { myStore } from "./redux/store";
import Toast from "./components/Toast";
import OneSignal from "react-onesignal";
import SoundContainer from "./components/SoundContainer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { IsBeta } from "./utils/Network";
import { Helmet } from "react-helmet";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { bsc } from "wagmi/chains";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";

import setupAxios from "./lib/axios/SetupAxios";
import axios from "axios";

setupAxios(axios, myStore);

TimeAgo.addDefaultLocale(en);
const metadata = {
  name: "Chain Of legends" + (IsBeta ? " (Beta)" : ""),
  description:
    "Chain of Legends is strategic play to earn and free to play game with Valuable NFTs and strong tokenomic and deflationary system. CLEG Token cryptocurrency",
  url: `https://${IsBeta ? "beta" : "play"}.chainoflegends.com`,
  icons: ["https://play.chainoflegends.com/logo192.png"],
};

const chains = [bsc];

const projectId = process.env.REACT_APP_WEB3MODAL_PROJECT_ID!;
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    "--w3m-z-index": 25555,
  },
  featuredWalletIds: [
    "ad2eff108bf828a39e5cb41331d95861c9cc516aede9cb6a95d75d98c206e204",
  ],
});

function App() {
  async function initOneSignal() {
    await OneSignal.init({
      appId: IsBeta
        ? "34e2ea82-9aa6-49d0-a351-58099b2bcff3"
        : "a39af6e5-5348-495c-8ec3-178494ff94e3",
      allowLocalhostAsSecureOrigin: true,
      serviceWorkerPath: "/OneSignalSDKWorker.js",
    });
    await OneSignal.showSlidedownPrompt();
  }

  useEffect(() => {
    setTimeout(() => {
      initOneSignal();
    }, 10000);
  }, []);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Provider store={myStore}>
          <Helmet>
            <script async>
              {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer',${
                IsBeta ? "'GTM-TVCSH3G'" : "'GTM-N3PX7NP'"
              });`}
            </script>
          </Helmet>
          <GoogleOAuthProvider clientId="171069001826-m8e7blc79scl8e87goo5nqhk55t2hk5u.apps.googleusercontent.com">
            <SoundContainer>
              <>
                <Navigator />
                <Toast />
              </>
            </SoundContainer>
          </GoogleOAuthProvider>
        </Provider>
      </WagmiConfig>
    </>
  );
}

export default App;
