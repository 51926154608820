import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MidBox from "../components/Boxes/MidBox";
import Button from "../components/Button";
import { roundFloat } from "../utils/round-float";
import Network, { IsBeta } from "../utils/Network";
import { motion } from "framer-motion";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { isMobile } from "react-device-detect";
import { Badge, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Countdown from "react-countdown";
import ReactPaginate from "react-paginate";
import CircularProgress from "../components/CircularProgress";
import PerfectScrollbar from "react-perfect-scrollbar";
import Dialog from "../components/Dialog";
import { useSelector } from "react-redux";
import useToast from "../hooks/useToast";
import Share from "../components/Share";
import TimeAgo from "javascript-time-ago";
import AdventureCard from "../components/AdventureCard";
import { PlayerItemType } from "../types/items";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import isValidJson from "../utils/is-valid-json";

type Props = {
  isPage?: boolean;
  id?: number;
};

enum AdventureStateEnum {
  Going = 1,
  Collecting = 2,
  Returning = 3,
  Ended = 4,
}

const AdventureModeEnum = {
  1: "Solo",
  2: "Private Raid",
  3: "Guild Raid",
  4: "Public Raid",
};

export const AdventureStates = {
  0: "Organizing",
  1: "Going",
  2: "Collecting",
  3: "Returning",
  4: "Ended",
  5: "Canceled",
};
const TroopLoot = ({ troop, index, star, className }: any) => {
  return (
    <OverlayTrigger
      // popperConfig={{}}
      placement={"top"}
      key={index + "xxp"}
      overlay={
        <Tooltip id={`tooltip-${"top"}`}>
          {troop?.Troop?.Name} - Level {troop?.Level}
        </Tooltip>
      }
    >
      <div
        style={{ padding: 2 }}
        className={`dark-badge  ${className} ` + (index !== 0 && "ms-1")}
      >
        <img
          style={{ borderRadius: 50, zIndex: 10, position: "relative" }}
          width={40}
          height={40}
          src={troop?.Troop?.Icon}
        />
        {troop?.Level &&
          (star ? (
            <div className="star  fs-tiny">{troop?.Level}</div>
          ) : (
            <div className="units  fs-tiny">{troop?.Level}</div>
          ))}
      </div>
    </OverlayTrigger>
  );
};

const ItemLoot = ({ item, index, className }: any) => {
  return (
    <div
      style={{ padding: 2 }}
      className={`dark-badge  ${className} ` + (index !== 0 && "ms-1")}
    >
      <img
        style={{ borderRadius: 50, zIndex: 10, position: "relative" }}
        width={40}
        height={40}
        src={item?.Image}
      />
      {item?.Level && <div className="units  fs-tiny">{item?.Level}</div>}
    </div>
  );
};
const AdventureHistory = (props: Props) => {
  const { isPage, id } = props;
  const axios = new Network();
  const firstRender = useRef(true);
  const [data, setData] = useState([]);
  const userInfo = useSelector((state: any) => state?.userInfo);
  const [detailLoading, setDetailLoading] = useState<boolean | number>(false);
  const timeAgo = new TimeAgo("en-US");
  const [shareDialog, setShareDialog] = useState({
    open: false,
    link: "",
  });
  const [detailDialog, setDetailDialog] = useState<any>({
    open: false,
    adventure: null,
  });
  const [loading, setLoading] = useState(false);
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const [paginations, setPaginations] = useState({
    page: 1,
    count: 10,
    total: 1,
  });
  const [isReplayDisabled, setIsReplayDisabled] = useState(false);
  const [allTroops, setAllTroops] = useState([]);
  const openToast = useToast();
  const [confimDialog, setConfirmDialog] = useState<any>({
    open: false,
    submit: () => "",
    title: "",
    msg: "",
  });

  const cancelAdventure = async (id: number) => {
    setDetailLoading(id);
    const response = await axios.CallApi({
      url: "/adventure/" + id + "/cancel",
      method: "delete",
    });
    openToast(response);

    if (response.ok) {
      setConfirmDialog((prev) => ({ ...prev, open: false }));
      handleGetList(paginations.page);
    }
    setDetailLoading(false);
  };

  async function getTroops() {
    const response = await axios.CallApi({ url: "/troop", method: "get" });
    setAllTroops(response?.data);
  }
  const handleAdv = async (id: number) => {
    setDetailLoading(id);
    const response = await axios.CallApi({
      url: "/adventure/" + id,
      method: "get",
    });
    if (response.ok) {
      let formation: any = [];
      let array: any =  isValidJson(response?.data?.formation)? JSON.parse(response?.data?.formation) :[]; 
      array?.map((theItem: any) => {
        const targetTroop = formation?.find(
          (unit: any) =>
            unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId
        );
        const reqTroop = formation?.find(
          (unit: any) => unit?.troopId === theItem?.troopId
        );
        if (theItem?.troopId) {
          if (reqTroop)
            formation = [
              ...formation?.filter(
                (dd: any) => dd?.troopId !== theItem?.troopId
              ),
              {
                ...reqTroop,
                qty: reqTroop?.qty ? reqTroop?.qty + 1 : 2,
              },
            ];
          else {
            formation.push({
              ...theItem,
            });
          }
        } else if (!targetTroop) {
          formation.push({
            ...theItem,
          });
        } else {
          formation = [
            ...formation?.filter(
              (dd: any) =>
                dd?.playerTroop?.TroopId !== theItem?.playerTroop?.TroopId
            ),
            {
              ...targetTroop,
              qty: targetTroop?.qty ? targetTroop?.qty + 1 : 2,
            },
          ];
        }
      });
      let jsonData = isValidJson(response?.data?.jsonData) ? JSON.parse(response?.data?.jsonData) : undefined;
      
      if (jsonData) {
        if (Array.isArray(jsonData)) {
          if (jsonData[0]?.PlayerId !== userInfo.id) {
            jsonData = [];
          }
        } else {
          if (
            response?.data.adventureMode !== 1 &&
            jsonData?.CapturedTroops?.[0]?.PlayerId !== userInfo.id
          ) {
            jsonData.CapturedTroops = [];
          }
          if (
            response?.data.adventureMode !== 1 &&
            jsonData?.DropedItems?.[0]?.PlayerId !== userInfo.id
          ) {
            jsonData.DropedItems = [];
          }
        }
      }
      setDetailDialog((prev) => ({
        open: true,
        adventure: {
          ...response?.data,
          formation,
          jsonData,
        },
      }));
    }
    setDetailLoading(false);
  };

  const handleGetList = async (page = 1) => {
    setLoading(true);
    const searchParams = new URLSearchParams();
    if (id) searchParams.append("GuildId", id + "");
    searchParams.append("page", page.toString());
    searchParams.append("count", 10 + "");
    const response = await axios.CallApi({
      url: "/adventure?" + searchParams.toString(),
      method: "get",
    });
    if (response.ok) {
      const theData = response?.data?.data?.map((item: any) => {
        let formation: any = [];
        let array: any =  isValidJson(item?.formation)? JSON.parse(item?.formation) :[];
        // console.log( JSON.parse(item?.battleReport))
        let victories = item?.result === 1 ? 1 : 0;
        let defeats = item?.result === 2 ? 1 : 0;
        if (item.state === 4) {
          item?.battles?.forEach((element) => {
            if (element?.result) {
              if (element?.result === 1) victories += 1;
              if (element?.result === 2) defeats += 1;
            }
          });
        }

        array?.map((theItem: any) => {
          const targetTroop = formation?.find(
            (unit: any) =>
              unit?.playerTroop?.TroopId === theItem?.playerTroop?.TroopId
          );
          const reqTroop = formation?.find(
            (unit: any) => unit?.troopId === theItem?.troopId
          );
          if (theItem?.troopId) {
            if (reqTroop)
              formation = [
                ...formation?.filter(
                  (dd: any) => dd?.troopId !== theItem?.troopId
                ),
                {
                  ...reqTroop,
                  qty: reqTroop?.qty ? reqTroop?.qty + 1 : 2,
                },
              ];
            else {
              formation.push({
                ...theItem,
              });
            }
          } else if (!targetTroop) {
            formation.push({
              ...theItem,
            });
          } else {
            formation = [
              ...formation?.filter(
                (dd: any) =>
                  dd?.playerTroop?.TroopId !== theItem?.playerTroop?.TroopId
              ),
              {
                ...targetTroop,
                qty: targetTroop?.qty ? targetTroop?.qty + 1 : 2,
              },
            ];
          }
        });
        let jsonData = isValidJson(item?.jsonData) ? JSON.parse(item?.jsonData) : undefined;
        if (jsonData) {
          if (Array.isArray(jsonData)) {
            if (jsonData?.[0]?.PlayerId !== userInfo.id) {
              jsonData = [];
            }
          } else {
            if (
              item.adventureMode !== 1 &&
              jsonData?.CapturedTroops?.[0]?.PlayerId !== userInfo.id
            ) {
              jsonData.CapturedTroops = [];
            }
            if (
              item.adventureMode !== 1 &&
              jsonData?.DropedItems?.[0]?.PlayerId !== userInfo.id
            ) {
              jsonData.DropedItems = [];
            }
          }
        }

        return {
          ...item,
          oldForm: array,
          victories,
          defeats,
          formation,
          jsonData: isValidJson(item?.jsonData) ? JSON.parse(item?.jsonData):undefined,
        };
      });
      setData(theData);
      setPaginations({
        total: Math.ceil(response.data.totalCount / 10),
        page,
        count: 10,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!firstRender.current) return;
    firstRender.current = false;
    handleGetList();
    getTroops();
  }, []);

  const DesktopHistory = () => (
    <div
      style={{ overflow: "auto" }}
      className="pe-1 pt-3 pb-5 ps-1 pe-sm-3 ps-sm-3 pb-4 table-box"
    >
      <table className="table table-borderless m-0 text-white">
        <tbody>
          {loading &&
            [1, 2, 3, 5, 6, 7].map((item) => (
              <tr key={"loading" + item} className="">
                <td className="d-flex align-items-center" align="left">
                  <Skeleton
                    style={{ borderRadius: "100%" }}
                    height={100}
                    width={"100px"}
                  />
                  <div className="ms-2">
                    <Skeleton height={20} width={150} />
                    <Skeleton height={20} width={70} />
                    <Skeleton height={20} width={50} />
                  </div>
                </td>
                <td>
                  {" "}
                  <Skeleton height={20} width={"100px"} />{" "}
                </td>
                <td>
                  {" "}
                  <Skeleton height={20} width={"100px"} />
                </td>
                <td>
                  <Skeleton height={20} width={"100px"} />
                </td>
              </tr>
            ))}
          {!loading && data?.length === 0 && (
            <tr>
              <td colSpan={5}>
                <div className="py-4 fs-big text-center dark-yellow">
                  You haven't started any adventures yet ...
                </div>
              </td>
            </tr>
          )}
          {!loading &&
            data?.map((item: any, key) => (
              <tr
                className=""
                // onClick={() => { navigate('/battle/order?treasureId=' + item?.id) }}
                style={{
                  opacity: item?.state !== 5 ? 1 : 0.7,
                }}
                key={"row" + item?.id}
              >
                <td align="center">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        width={80}
                        height={"auto"}
                        src={
                          item?.treasure?.id? 
                          item?.treasure?.treasureType == 1
                            ? `/assets/images/icons/dungeon/${item?.treasure?.id}.png`
                            : `/assets/images/lands/treasure/${item?.treasure?.id}.png`
                            : '/assets/images/icons/dungeon/2.png'
                        }
                      />
                      <br />
                    </div>

                    <OverlayTrigger
                      // popperConfig={{}}
                      placement={"right"}
                      overlay={
                        <Tooltip id={`tooltip-${"top"}`}>Difficulty</Tooltip>
                      }
                    >
                      <span className="d-flex">
                        <img
                          className="ms-2 me-1"
                          src="/assets/images/icons/blades.png"
                          width={20}
                          height={20}
                        />
                        {item?.treasure?.difficulty}
                      </span>
                    </OverlayTrigger>
                  </div>
                </td>

                <td className="" align="center">
                  <div className="d-flex align-items-end justify-content-center">
                    <div className="ms-3 text-left fs-big">
                      {item?.state === 0 && (
                        <div className="mt-2 fs-med  ">
                          <div>
                            <CircularProgress />
                            Waiting for others to contribute
                          </div>
                        </div>
                      )}
                      {item?.state === 1 && (
                        <div className="mt-2 fs-big  ">
                          {item?.treasure?.treasureType === 2 ? (
                            <>
                              Remaining (
                              <Countdown
                                onComplete={() => handleGetList()}
                                date={new Date(item?.arriveDate)}
                                renderer={({
                                  days,
                                  hours,
                                  minutes,
                                  seconds,
                                }) => (
                                  <>
                                    {days}D {hours}h {minutes}m {seconds}s
                                  </>
                                )}
                              />
                              )
                            </>
                          ) : (
                            <>
                              Remaining (
                              <Countdown
                                onComplete={() => handleGetList()}
                                date={new Date(item?.returnDate)}
                                renderer={({
                                  days,
                                  hours,
                                  minutes,
                                  seconds,
                                }) => (
                                  <>
                                    {days}D {hours}h {minutes}m {seconds}s
                                  </>
                                )}
                              />
                              )
                            </>
                          )}
                          <br />
                        </div>
                      )}
                      {item?.state === 2 && (
                        <div className="mt-2 fs-big">
                          <b>Collecting </b> (
                          <Countdown
                            onComplete={() => handleGetList()}
                            date={new Date(item?.returningDate)}
                            renderer={({ days, hours, minutes, seconds }) => (
                              <>
                                {days}D {hours}h {minutes}m {seconds}s
                              </>
                            )}
                          />
                          )
                        </div>
                      )}
                      {
                        item?.underAttack && (
                          // new Date(item?.underAttack).getTime() > Date.now() && (
                          <div className="mt-2 fs-big">
                            <Badge bg="danger">
                              <>
                                Under Attack
                                {/* ( <Countdown
                                  onComplete={() => handleGetList()}
                                  date={new Date(item?.underAttack)}
                                  renderer={({
                                    days,
                                    hours,
                                    minutes,
                                    seconds,
                                  }) => (
                                    <>
                                      {days}D {hours}h {minutes}m {seconds}s
                                    </>
                                  )}
                                />
                                ) */}
                              </>
                            </Badge>
                          </div>
                        )
                        // )
                      }
                      {item?.state === 3 && (
                        <div className="mt-2 fs-big">
                          Returning (
                          <Countdown
                            onComplete={() => handleGetList()}
                            date={new Date(item?.returnDate)}
                            renderer={({ days, hours, minutes, seconds }) => (
                              <>
                                {days}D {hours}h {minutes}m {seconds}s
                              </>
                            )}
                          />
                          )
                        </div>
                      )}
                      <div className="d-flex gap-2 justify-content-center">
                        {item?.state === 4 &&
                          (Array.isArray(item?.jsonData)
                            ? item?.jsonData?.map((troop: any, index) => (
                                <TroopLoot troop={troop} index={index} />
                              ))
                            : item?.jsonData?.CapturedTroops?.map(
                                (troop: any, index) => (
                                  <TroopLoot troop={troop} index={index} />
                                )
                              ))}
                        {item?.state === 4 &&
                          (!Array.isArray(item?.jsonData) ? (
                            item?.jsonData?.DropedItems?.map(
                              (item: any, index) => (
                                <ItemLoot item={item} index={index} />
                              )
                            )
                          ) : (
                            <></>
                          ))}
                      </div>

                      {item?.state === 4 && item?.result && (
                        <>
                          <div></div>
                          <div
                            style={{
                              opacity: item?.result === 1 ? 1 : 0.5,
                            }}
                            className="d-flex align-items-end mt-2"
                          >
                            <OverlayTrigger
                              // popperConfig={{}}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${"top"}`}>
                                  {roundFloat(item?.collectedCleg)} Cleg
                                </Tooltip>
                              }
                            >
                              <div className="d-flex align-items-end">
                                <div
                                  style={{
                                    borderRadius: "50% 50% 0 50%",
                                  }}
                                  className="dark-badge"
                                >
                                  <img
                                    style={{
                                      position: "relative",
                                      zIndex: 11,
                                    }}
                                    src="/assets/images/icons/cleg.png"
                                    width={30}
                                    height={30}
                                  />
                                  <div
                                    style={{ fontSize: 12 }}
                                    className=""
                                  ></div>
                                </div>
                                <div
                                  style={{
                                    fontSize: 14,
                                    marginLeft: -8,
                                    zIndex: 10,
                                    padding: "4px 6px",
                                    borderRadius: "0 10px 10px 0",
                                    background: "#2b271e",
                                    height: "fit-content",
                                    verticalAlign: "bottom",
                                  }}
                                  className=""
                                >
                                  x{roundFloat(item?.collectedCleg)}
                                </div>
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              // popperConfig={{}}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${"top"}`}>
                                  {roundFloat(item?.collectedStone)} Stone
                                </Tooltip>
                              }
                            >
                              <div className="d-flex align-items-end">
                                <div
                                  style={{
                                    borderRadius: "50% 50% 0 50%",
                                  }}
                                  className="dark-badge ms-2"
                                >
                                  <img
                                    style={{
                                      position: "relative",
                                      zIndex: 11,
                                    }}
                                    src="/assets/images/icons/stone.png"
                                    width={30}
                                    height={30}
                                  />
                                  <div
                                    style={{ fontSize: 14 }}
                                    className=""
                                  ></div>
                                </div>
                                <div
                                  style={{
                                    fontSize: 14,
                                    marginLeft: -8,
                                    zIndex: 10,
                                    padding: "4px 6px",
                                    borderRadius: "0 10px 10px 0",
                                    background: "#2b271e",
                                    height: "fit-content",
                                    verticalAlign: "bottom",
                                  }}
                                  className=""
                                >
                                  x{roundFloat(item?.collectedStone)}
                                </div>
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              // popperConfig={{}}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${"top"}`}>
                                  {roundFloat(item?.collectedIron)} Iron
                                </Tooltip>
                              }
                            >
                              <div className="d-flex align-items-end">
                                <div
                                  style={{
                                    borderRadius: "50% 50% 0 50%",
                                  }}
                                  className="dark-badge ms-2"
                                >
                                  <img
                                    style={{
                                      position: "relative",
                                      zIndex: 11,
                                    }}
                                    src="/assets/images/icons/metal.png"
                                    width={30}
                                    height={30}
                                  />
                                </div>
                                <div
                                  style={{
                                    fontSize: 14,
                                    marginLeft: -8,
                                    zIndex: 10,
                                    padding: "4px 6px",
                                    borderRadius: "0 10px 10px 0",
                                    background: "#2b271e",
                                    height: "fit-content",
                                    verticalAlign: "bottom",
                                  }}
                                  className=""
                                >
                                  x{roundFloat(item?.collectedIron)}
                                </div>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </>
                      )}
                      {item?.state === 5 && (
                        <div className="mt-2 fs-lg  ">
                          <div>Adventure Canceled</div>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td align="center">
                  <div className="">
                    {item.state > 2 && (
                      <div style={{ fontSize: 25 }} className=" bold mb-2">
                        {item?.state <= 3 && (
                          <div style={{ fontSize: 20 }} className="fs-big bold">
                            {AdventureStates[item?.state]}
                          </div>
                        )}
                        {item?.state === 4 &&
                          (item?.result === 1 ? (
                            <span className="text-success me-1">
                              {(item?.defeats > 0 || item?.victories > 1) &&
                                item?.victories}{" "}
                              Victory
                            </span>
                          ) : (
                            <span className="text-danger">Defeat</span>
                          ))}
                        {item?.state === 4 &&
                          item?.defeats > 0 &&
                          item?.victories > 0 && (
                            <>
                              |
                              <span className="text-danger ms-1">
                                {item?.defeats} Defeat
                              </span>
                            </>
                          )}

                        <div className="mb-3" />
                      </div>
                    )}
                  </div>
                  <PerfectScrollbar
                    style={{
                      marginInline: "auto",
                      width: "fit-content",
                      maxWidth: isMobile ? 200 : 350,
                      paddingBottom: 15,
                      paddingTop: 12,
                      paddingInline: "5px",
                    }}
                  >
                    <div className="d-flex mt-md-0 mt-0 ">
                      {item?.formation?.map((troop: any, index) => {
                        if (troop.troopId) {
                          const troopData: any = allTroops.find(
                            (item: any) => item?.id === troop.troopId
                          );
                          return (
                            <OverlayTrigger
                              // popperConfig={{}}
                              placement={"bottom"}
                              key={index + "p" + troop?.x + troop?.y}
                              overlay={
                                <Tooltip id={`tooltip-${"top"}`}>
                                  {troopData?.name}
                                </Tooltip>
                              }
                            >
                              <div
                                style={{ padding: 3 }}
                                className={
                                  `dark-badge ms-2 ${
                                    item.result && "vGS ms-3 "
                                  }  ` + (index !== 0 && "ms-1")
                                }
                              >
                                <Badge
                                  style={{
                                    position: "absolute",
                                    fontSize: 9,
                                    bottom: -8,
                                    right: 0,
                                    fontWeight: 500,
                                    left: 0,
                                    zIndex: 12,
                                    width: "fit-content",
                                  }}
                                  className="mx-auto"
                                >
                                  Req
                                </Badge>
                                {item.state === 4 && (
                                  <div className="unit-xp d-flex align-items-center">
                                    <img
                                      style={{
                                        marginRight: 1,
                                        marginTop: -2,
                                      }}
                                      width={7}
                                      height={8}
                                      src="/assets/images/icons/arrow-up.svg"
                                    />
                                    {item.result === 1 ? (
                                      <span>+10 XP</span>
                                    ) : (
                                      <span>+5 XP</span>
                                    )}
                                  </div>
                                )}
                                <img
                                  style={{
                                    borderRadius: 50,
                                    zIndex: 10,
                                    position: "relative",
                                  }}
                                  width={40}
                                  height={40}
                                  src={troopData?.icon}
                                />
                                {troop?.qty && (
                                  <div
                                    style={{ top: 0, bottom: "unset" }}
                                    className="units"
                                  >
                                    {troop?.qty}x
                                  </div>
                                )}
                              </div>
                            </OverlayTrigger>
                          );
                        }
                        return (
                          <OverlayTrigger
                            // popperConfig={{}}
                            placement={"bottom"}
                            key={index + "p" + troop?.x + troop?.y}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                {troop?.playerTroop?.Troop?.Name}
                              </Tooltip>
                            }
                          >
                            <div
                              style={{ padding: 3 }}
                              className={
                                `dark-badge ms-2 ${
                                  item.result && "vGS ms-3 "
                                }  ` + (index !== 0 && "ms-1")
                              }
                            >
                              {item.state === 4 && (
                                <div className="unit-xp d-flex align-items-center">
                                  <img
                                    style={{
                                      marginRight: 1,
                                      marginTop: -2,
                                    }}
                                    width={7}
                                    height={8}
                                    src="/assets/images/icons/arrow-up.svg"
                                  />
                                  {item.result === 1 ? (
                                    <span>+10 XP</span>
                                  ) : (
                                    <span>+5 XP</span>
                                  )}
                                </div>
                              )}
                              <img
                                style={{
                                  borderRadius: 50,
                                  zIndex: 10,
                                  position: "relative",
                                }}
                                width={40}
                                height={40}
                                src={troop?.playerTroop?.Troop?.Icon}
                              />
                              {troop?.qty && (
                                <div className="units">{troop?.qty}x</div>
                              )}
                            </div>
                          </OverlayTrigger>
                        );
                      })}
                    </div>
                  </PerfectScrollbar>
                </td>
                <td align="center">
                  {item.state === 0 ? (
                    <>Hasn't started yet</>
                  ) : item.state === 5 ? (
                    <></>
                  ) : (
                    <>
                      Started in{" "}
                      {moment(new Date(item?.startDate)).format(
                        "MM/DD/YYYY - HH:mm"
                      )}
                    </>
                  )}
                </td>
                <td align="center">
                  {item?.state < 3 ? (
                    item?.state === 0 ? (
                      <Link to={`/treasure/modify/${item?.id}`}>
                        <Button variant="default">Modify</Button>
                      </Link>
                    ) : item?.state === 2 ? (
                      <Link to={`/treasure/modify/${item?.id}`}>
                        <Button variant="default">Defend</Button>
                      </Link>
                    ) : (
                      <Link
                        to={
                          item?.treasure?.treasureType === 2
                            ? `/adventure/waiting/treasure/${item?.id}`
                            : `/adventure/waiting/${item?.id}`
                        }
                      >
                        <Button variant="default">Details</Button>
                      </Link>
                    )
                  ) : item?.state === 5 ? (
                    <Button
                      style={{ backgroundSize: "100% 100%" }}
                      // onClick={() => navigate("/battle/replay/" + item?.id)}
                      variant="secondary"
                    >
                      Canceled
                    </Button>
                  ) : (
                    <>
                      {/* <Link style={{ width: 'fit-content' }} aria-disabled={item?.state < 3}
                                                to={item?.treasure?.treasureType === 2 ? `/battle/treasure/${item?.id}` : `/battle/replay/${item?.id}`}
                                            > */}
                      <Button
                        disabled={item?.state < 3}
                        onClick={() =>
                          (window.location.href = `/battle/treasure/${encodeURIComponent(
                            item?.replayId
                          )}`)
                        }
                        // onClick={() => navigate("/battle/replay/" + item?.id)}
                        variant="primary"
                      >
                        Replay
                      </Button>
                      {/* </Link> */}
                    </>
                  )}
                  {item?.state === 2 && (
                    // <Link
                    //   style={{ width: "fit-content" }}
                    //   to={
                    //     item?.treasure?.treasureType === 2
                    //       ? `/battle/treasure/${item?.id}`
                    //       : `/battle/replay/${item?.id}`
                    //   }
                    // >
                    <Button
                      onClick={() =>
                        (window.location.href = `/battle/treasure/${encodeURIComponent(
                          item?.replayId
                        )}`)
                      }
                      // onClick={() => navigate("/battle/replay/" + item?.id)}
                      variant="primary"
                    >
                      Replay
                    </Button>
                    // </Link>
                  )}
                  {item?.state === 0 && (
                    <Button
                      className="mt-1"
                      onClick={() =>
                        setShareDialog({
                          open: true,
                          link: `https://${
                            IsBeta ? "beta" : "play"
                          }.chainoflegends.com/treasure/join/${item?.id}`,
                        })
                      }
                      variant="primary"
                    >
                      Share
                    </Button>
                  )}
                  {item?.treasure?.treasureType === 2 && item?.state === 0 && (
                    <Button
                      loading={detailLoading === item.id}
                      onClick={() =>
                        setConfirmDialog({
                          open: true,
                          msg: "Are you sure about canceling adventure ?",
                          title: "Confirmation",
                          submit: () => cancelAdventure(item.id),
                        })
                      }
                      style={{ backgroundSize: "100% 100%" }}
                      className="mt-1"
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                  )}
                  {item?.treasure?.treasureType === 2 &&
                    item?.state > 2 &&
                    item?.state < 5 && (
                      <Button
                        loading={detailLoading === item.id}
                        onClick={() => handleAdv(item?.id)}
                        className="mt-2"
                        variant="default"
                      >
                        Detail
                      </Button>
                    )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <ReactPaginate
        className="pagination mt-3 justify-content-center"
        activeClassName="active-pg"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        breakClassName="page-item"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLinkClassName="page-link"
        previousLinkClassName="page-link"
        previousClassName="page-item"
        previousLabel="<"
        nextLabel=">"
        forcePage={paginations.page - 1}
        breakLabel="..."
        onPageChange={(e) => handleGetList(e.selected + 1)}
        pageRangeDisplayed={3}
        pageCount={paginations.total}
        // renderOnZeroPageCount={null}
      />
    </div>
  );

  return (
    <SkeletonTheme baseColor="#2d271b" highlightColor="#3e382b">
      <Helmet>
        <title key={"title"}>Adventure History | Chain Of Legends</title>
      </Helmet>
      <motion.div
        exit={{ opacity: 0, y: 200 }}
        transition={{ duration: 0.4 }}
        initial={isMobile ? undefined : { opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        className="row"
      >
        {isMobile ? (
          <div className={classNames({ "pt-4": !isPage })}>
            {isPage && (
              <h4
                className={classNames(
                  "text-center d-flex  align-items-center my-4 dark-yellow",
                  { "justify-content-center": !isPage }
                )}
              >
                <img width={30} src="/assets/images/icons/timer.png" />{" "}
                Adventure History
              </h4>
            )}
            {loading && (
              <div className="text-center my-5 py-5">
                <CircularProgress />
              </div>
            )}
            {!loading &&
              data?.map((item: any, key) => (
                <AdventureCard
                  data={item}
                  key={key}
                  detailLoading={detailLoading}
                  allTroops={allTroops}
                  refreshList={handleGetList}
                  onCancel={(id) =>
                    setConfirmDialog({
                      open: true,
                      msg: "Are you sure about canceling adventure ?",
                      title: "Confirmation",
                      submit: () => cancelAdventure(id),
                    })
                  }
                  onShare={() =>
                    setShareDialog({
                      open: true,
                      link:
                        item?.state > 0
                          ? `https://${
                              IsBeta ? "beta" : "play"
                            }.chainoflegends.com/battle/treasure/${encodeURIComponent(
                              item?.replayId
                            )}`
                          : `https://${
                              IsBeta ? "beta" : "play"
                            }.chainoflegends.com/treasure/join/${item?.id}`,
                    })
                  }
                  onDetail={() => handleAdv(item?.id)}
                />
              ))}
            <ReactPaginate
              className="pagination mt-3 justify-content-center"
              activeClassName="active-pg"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              breakClassName="page-item"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              previousClassName="page-item"
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              onPageChange={(e) => handleGetList(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={paginations.total}
              // renderOnZeroPageCount={null}
            />
          </div>
        ) : isPage ? (
          <MidBox modal title="Adventure History">
            <DesktopHistory />
          </MidBox>
        ) : (
          <DesktopHistory />
        )}
      </motion.div>
      <Dialog
        size="lg"
        open={detailDialog.open}
        title="Adventure Details"
        onClose={() => setDetailDialog((prev) => ({ ...prev, open: false }))}
      >
        <Row style={{ marginBottom: -40 }}>
          <Col md={6} className="p-relative" xs={12}>
            <Badge
              bg={
                detailDialog?.adventure?.state > 3
                  ? "danger"
                  : detailDialog?.adventure?.state > 1
                  ? "success"
                  : detailDialog?.adventure?.state === 0
                  ? "dark"
                  : "info"
              }
              style={{ position: "absolute", right: 0, top: 0 }}
            >
              {AdventureStates[detailDialog?.adventure?.state]}
            </Badge>
            <Badge
              bg={"secondary"}
              style={{ position: "absolute", left: 10, top: 0 }}
            >
              {AdventureModeEnum[detailDialog?.adventure?.adventureMode]}
            </Badge>
            <div className="p-relative">
              <motion.img
                transition={{
                  delay: 0.2,
                  opacity: 0,
                  duration: 0.3,
                }}
                initial={{
                  scale: 0,
                  opacity: 0.2,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                }}
                width={"100%"}
                src={`/assets/images/lands/treasure/${detailDialog?.adventure?.treasure?.id}.png`}
              />
              {detailDialog?.adventure?.state > 1 && (
                // <Link to={`/battle/treasure/${detailDialog?.adventure?.id}`}>

                <div
                  onClick={() => {
                    setDetailDialog((prev) => ({ ...prev, open: false }));
                    window.location.href = `/battle/treasure/${encodeURIComponent(
                      detailDialog?.adventure?.replayId
                    )}`;
                  }}
                  className="pointer button"
                  style={{
                    position: "absolute",
                    top: "45%",
                    right: 0,
                    left: 0,
                    marginInline: "auto",
                    width: 50,
                    height: 50,
                    backdropFilter: "blur(10px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 200,
                    borderRadius: "50%",
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                >
                  <img
                    style={{ marginRight: -3 }}
                    src="/assets/images/icons/play.svg"
                    width={20}
                  />
                </div>
                // </Link>
              )}
            </div>
            <div className="d-flex mt-2">
              <div style={{ flex: 1 }} className=" bg-2  fs-med rounded p-2">
                <img width={25} src="/assets/images/icons/blades.png" />{" "}
                Difficulty : {detailDialog?.adventure?.treasure?.difficulty}
              </div>
            </div>
            <div className=" bg-2 mt-2 fs-med rounded p-2">
              <img width={20} src="/assets/images/icons/timer.png" /> Travel
              Time :
              <Countdown
                date={moment()
                  .add(detailDialog?.adventure?.treasure?.minTravelMinutes, "m")
                  ?.toDate()}
                autoStart={false}
                renderer={({ formatted }) => (
                  <span>
                    {" "}
                    {formatted.days} days{" "}
                    {parseFloat(formatted.hours) ? (
                      <>{formatted.hours} hr</>
                    ) : (
                      ""
                    )}
                  </span>
                )}
              />
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="d-flex flex-column gap-2">
              <div>
                <div className="p-2 bg-2 rounded">
                  <div className="text-center d-flex align-items-center fs-lg">
                    <hr className="hr-l" />
                    Collected Resources
                    <hr className="hr-r" />
                  </div>
                  <div
                    style={{
                      opacity: detailDialog?.adventure?.result === 1 ? 1 : 0.5,
                    }}
                    className="d-flex justify-content-center align-items-end mt-2"
                  >
                    <OverlayTrigger
                      // popperConfig={{}}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-${"top"}`}>
                          {detailDialog?.adventure?.collectedCleg} Cleg
                        </Tooltip>
                      }
                    >
                      <div className="d-flex align-items-end">
                        <div
                          style={{ borderRadius: "50% 50% 0 50%" }}
                          className="dark-badge"
                        >
                          <img
                            style={{ position: "relative", zIndex: 11 }}
                            src="/assets/images/icons/cleg.png"
                            width={30}
                            height={30}
                          />
                          <div style={{ fontSize: 12 }} className=""></div>
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            marginLeft: -8,
                            zIndex: 10,
                            padding: "4px 6px",
                            borderRadius: "0 10px 10px 0",
                            background: "#2b271e",
                            height: "fit-content",
                            verticalAlign: "bottom",
                          }}
                          className=""
                        >
                          x{roundFloat(detailDialog?.adventure?.collectedCleg)}
                        </div>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      // popperConfig={{}}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-${"top"}`}>
                          {roundFloat(detailDialog?.adventure?.collectedStone)}{" "}
                          Stone
                        </Tooltip>
                      }
                    >
                      <div className="d-flex align-items-end">
                        <div
                          style={{ borderRadius: "50% 50% 0 50%" }}
                          className="dark-badge ms-2"
                        >
                          <img
                            style={{ position: "relative", zIndex: 11 }}
                            src="/assets/images/icons/stone.png"
                            width={30}
                            height={30}
                          />
                          <div style={{ fontSize: 14 }} className=""></div>
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            marginLeft: -8,
                            zIndex: 10,
                            padding: "4px 6px",
                            borderRadius: "0 10px 10px 0",
                            background: "#2b271e",
                            height: "fit-content",
                            verticalAlign: "bottom",
                          }}
                          className=""
                        >
                          x{roundFloat(detailDialog?.adventure?.collectedStone)}
                        </div>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      // popperConfig={{}}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-${"top"}`}>
                          {roundFloat(detailDialog?.adventure?.collectedIron)}{" "}
                          Iron
                        </Tooltip>
                      }
                    >
                      <div className="d-flex align-items-end">
                        <div
                          style={{ borderRadius: "50% 50% 0 50%" }}
                          className="dark-badge ms-2"
                        >
                          <img
                            style={{ position: "relative", zIndex: 11 }}
                            src="/assets/images/icons/metal.png"
                            width={30}
                            height={30}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            marginLeft: -8,
                            zIndex: 10,
                            padding: "4px 6px",
                            borderRadius: "0 10px 10px 0",
                            background: "#2b271e",
                            height: "fit-content",
                            verticalAlign: "bottom",
                          }}
                          className=""
                        >
                          x{roundFloat(detailDialog?.adventure?.collectedIron)}
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {detailDialog?.adventure?.jsonData &&
                  detailDialog?.adventure?.jsonData?.DropedItems &&
                  detailDialog?.adventure?.jsonData?.DropedItems?.length !==
                    0 && (
                    <div style={{ flex: 1 }} className="p-2 bg-2 rounded">
                      <div className="text-center d-flex align-items-center fs-med">
                        <hr className="hr-l" />
                        Droped Items
                        <hr className="hr-r" />
                      </div>
                      <div
                        style={{
                          opacity:
                            detailDialog?.adventure?.result === 1 ? 1 : 0.5,
                        }}
                        className="d-flex justify-content-center align-items-end mt-2"
                      >
                        {detailDialog?.adventure?.jsonData?.DropedItems?.map(
                          (item: any) => (
                            <div className="p-1 bg-2 p-relative circle text-center">
                              <img
                                width={40}
                                height={40}
                                className={"circle"}
                                src={item?.Image}
                              />
                              <div className="item-level">{item?.Level}</div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                {detailDialog?.adventure?.state === 4 &&
                  (Array.isArray(detailDialog?.adventure?.jsonData)
                    ? detailDialog?.adventure?.jsonData?.length > 0
                    : detailDialog?.adventure?.jsonData?.CapturedTroops
                        ?.length > 0) && (
                    <div style={{ flex: 1 }} className="p-2 bg-2 rounded ms-2">
                      <div className="text-center d-flex align-items-center fs-med">
                        <hr className="hr-l" />
                        Captured Troops
                        <hr className="hr-r" />
                      </div>
                      <div className="align-items-center w-100 d-flex justify-content-center">
                        {detailDialog?.adventure?.state === 4 &&
                          (Array.isArray(detailDialog?.adventure?.jsonData)
                            ? detailDialog?.adventure?.jsonData?.map(
                                (troop: any, index) => (
                                  <TroopLoot star troop={troop} index={index} />
                                )
                              )
                            : detailDialog?.adventure?.jsonData?.CapturedTroops?.map(
                                (troop: any, index) => (
                                  <TroopLoot
                                    className="ms-1 mt-2"
                                    star
                                    style={{
                                      backgroundColor:
                                        "var(--theme-color-orange) !important",
                                      margin: 0,
                                    }}
                                    troop={troop}
                                    index={index}
                                  />
                                )
                              ))}
                      </div>
                    </div>
                  )}
              </div>

              <div className="p-2 bg-2 rounded">
                <PerfectScrollbar
                  style={{ maxWidth: "100%" }}
                  className="d-flex py-3 gap-1"
                >
                  {detailDialog?.adventure?.formation?.map(
                    (troop: any, index) => {
                      if (troop.troopId) {
                        const troopData = allTroops.find(
                          (item) => item?.id === troop.troopId
                        );
                        return (
                          <OverlayTrigger
                            // popperConfig={{}}
                            placement={"bottom"}
                            key={index + "p" + troop?.x + troop?.y}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                {troopData?.name}
                              </Tooltip>
                            }
                          >
                            <div
                              style={{
                                padding: 3,
                                marginBottom:
                                  detailDialog?.adventure.state === 4 ? -10 : 0,
                              }}
                              className={
                                `dark-badge ms0 ${
                                  detailDialog?.adventure.result && "vGS ms-3 "
                                }  ` + (index !== 0 && "ms-1")
                              }
                            >
                              <Badge
                                style={{
                                  position: "absolute",
                                  bottom: -8,
                                  fontSize: 9,
                                  right: 0,
                                  left: 0,
                                  fontWeight: 500,
                                  zIndex: 12,
                                  width: "fit-content",
                                }}
                                className="mx-auto"
                              >
                                Req
                              </Badge>
                              {detailDialog?.adventure.state === 4 && (
                                <div className="unit-xp d-flex align-items-center">
                                  <img
                                    style={{ marginRight: 1, marginTop: -2 }}
                                    width={7}
                                    height={8}
                                    src="/assets/images/icons/arrow-up.svg"
                                  />
                                  {detailDialog?.adventure.result === 1 ? (
                                    <span>+10 XP</span>
                                  ) : (
                                    <span>+5 XP</span>
                                  )}
                                </div>
                              )}
                              <img
                                style={{
                                  borderRadius: 50,
                                  zIndex: 10,
                                  position: "relative",
                                }}
                                width={40}
                                height={40}
                                src={troopData?.icon}
                              />
                              {troop?.qty && (
                                <div
                                  style={{ top: 0, bottom: "unset" }}
                                  className="units fs-tiny"
                                >
                                  {troop?.qty}x
                                </div>
                              )}
                            </div>
                          </OverlayTrigger>
                        );
                      }
                      return (
                        <OverlayTrigger
                          // popperConfig={{}}
                          placement={"bottom"}
                          key={index + "p" + troop?.x + troop?.y}
                          overlay={
                            <Tooltip id={`tooltip-${"top"}`}>
                              {troop?.playerTroop?.Troop?.Name}
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              padding: 3,
                              marginBottom:
                                detailDialog?.adventure.state === 4 ? -10 : 0,
                            }}
                            className={
                              `dark-badge ms-2 ${
                                detailDialog?.adventure.result && "vGS ms-3 "
                              }  ` + (index !== 0 && "ms-1")
                            }
                          >
                            {detailDialog?.adventure.state === 4 && (
                              <div className="unit-xp d-flex align-items-center">
                                <img
                                  style={{ marginRight: 1, marginTop: -2 }}
                                  width={7}
                                  height={8}
                                  src="/assets/images/icons/arrow-up.svg"
                                />
                                {detailDialog?.adventure.result === 1 ? (
                                  <span>+10 XP</span>
                                ) : (
                                  <span>+5 XP</span>
                                )}
                              </div>
                            )}
                            <img
                              style={{
                                borderRadius: 50,
                                zIndex: 10,
                                position: "relative",
                              }}
                              width={40}
                              height={40}
                              src={troop?.playerTroop?.Troop?.Icon}
                            />
                            {troop?.qty && (
                              <div className="units fs-tiny">{troop?.qty}x</div>
                            )}
                          </div>
                        </OverlayTrigger>
                      );
                    }
                  )}
                </PerfectScrollbar>
              </div>
              {detailDialog?.adventure?.adventureMode > 1 && (
                <div className="d-flex align-items-center ">
                  {Boolean(
                    detailDialog?.adventure?.adventurePlayers?.find(
                      (item) => item?.playerId === userInfo.id
                    )?.rewardShare
                  ) && (
                    <div className="col-6 pe-2 ">
                      <div className=" bg-2  fs-med rounded p-2">
                        Reward Share :{" "}
                        {
                          detailDialog?.adventure?.adventurePlayers?.find(
                            (item) => item?.playerId === userInfo.id
                          )?.rewardShare
                        }
                      </div>
                    </div>
                  )}
                  {detailDialog?.adventure?.adventurePlayers?.find(
                    (item) => item?.playerId === userInfo.id
                  )?.impact && (
                    <div className="col-6 ">
                      <div className=" bg-2 tl-300 fs-med rounded p-2">
                        Impact :{" "}
                        {
                          detailDialog?.adventure?.adventurePlayers?.find(
                            (item) => item?.playerId === userInfo.id
                          )?.impact
                        }
                        {detailDialog?.adventure?.adventurePlayers?.find(
                          (item) => item?.playerId === userInfo.id
                        )?.impactReport && (
                          <OverlayTrigger
                            placement="top"
                            flip
                            overlay={
                              <Tooltip {...props} className="tl-300">
                                <div>
                                  Damage Done :{" "}
                                  {
                                    isValidJson(
                                      detailDialog?.adventure?.adventurePlayers?.find(
                                        (item) => item?.playerId === userInfo.id
                                      )?.impactReport
                                    )?
                                    JSON.parse(
                                      detailDialog?.adventure?.adventurePlayers?.find(
                                        (item) => item?.playerId === userInfo.id
                                      )?.impactReport
                                    )?.damageDone : "0"
                                  }{" "}
                                  | Damage Blocked :{" "}
                                  {
                                    isValidJson( 
                                      detailDialog?.adventure?.adventurePlayers?.find(
                                        (item) => item?.playerId === userInfo.id
                                      )?.impactReport)?
                                    JSON.parse(
                                      detailDialog?.adventure?.adventurePlayers?.find(
                                        (item) => item?.playerId === userInfo.id
                                      )?.impactReport
                                    )?.damageBlocked : "0"
                                  }{" "}
                                  | Damage Received :{" "}
                                  {
                                    isValidJson(
                                      detailDialog?.adventure?.adventurePlayers?.find(
                                        (item) => item?.playerId === userInfo.id
                                      )?.impactReport)
                                      ?
                                    JSON.parse(
                                      detailDialog?.adventure?.adventurePlayers?.find(
                                        (item) => item?.playerId === userInfo.id
                                      )?.impactReport
                                    )?.damageReceived : "0"
                                  }{" "}
                                  | Healing :{" "}
                                  {
                                    isValidJson(
                                      detailDialog?.adventure?.adventurePlayers?.find(
                                        (item) => item?.playerId === userInfo.id
                                      )?.impactReport)
                                      ?
                                    JSON.parse(
                                      detailDialog?.adventure?.adventurePlayers?.find(
                                        (item) => item?.playerId === userInfo.id
                                      )?.impactReport
                                    )?.HealingDone : "0"
                                  }
                                </div>
                              </Tooltip>
                            }
                            delay={{ show: 100, hide: 200 }}
                          >
                            <span>
                              <img
                                width={15}
                                height={15}
                                className="pointer ms-1 "
                                src={"/assets/images/icons/info.svg"}
                              />
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {detailDialog?.adventure?.startDate && (
                <div className=" bg-2  fs-med rounded p-2">
                  <img src="/assets/images/icons/timer.png" width={20} />{" "}
                  Started At :{" "}
                  {moment(new Date(detailDialog?.adventure?.startDate)).format(
                    "MM/DD/YYYY - HH:mm"
                  )}
                </div>
              )}
              {detailDialog?.adventure?.battles &&
                detailDialog?.adventure?.battles?.length > 0 && (
                  <>
                    <div className="p-2 bg-2 rounded">
                      <div className="text-center d-flex align-items-center fs-lg">
                        <hr className="hr-l" />
                        Battles ({detailDialog?.adventure?.battles?.length + 1})
                        <hr className="hr-r" />
                      </div>
                      <PerfectScrollbar style={{ maxHeight: 140 }}>
                        {detailDialog?.adventure?.battles?.map(
                          (battle, key) => (
                            <div
                              key={battle.id}
                              className="d-flex bg-2 rounded p-2 justify-content-between align-items-center mt-2"
                            >
                              <div
                                className={
                                  battle?.result === 1
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                <b>
                                  {battle?.result === 1 ? "Victory" : "Defeat"}
                                </b>
                              </div>
                              <div className="text-secondary fs-tiny">
                                {timeAgo.format(new Date(battle?.battleDate))}
                              </div>
                              <a
                                onClick={() => {
                                  setDetailDialog((prev) => ({
                                    ...prev,
                                    open: false,
                                  }));
                                  window.location.href =
                                    "/battle/treasure/" +
                                    encodeURIComponent(battle.replayId);
                                }}
                                className="pointer text-info"
                              >
                                <img
                                  className="me-2 mb-1"
                                  src="/assets/images/icons/play.svg"
                                  width={10}
                                />
                                Play
                              </a>
                            </div>
                          )
                        )}
                        <div className="d-flex bg-2 rounded p-2 justify-content-between align-items-center mt-2">
                          <div
                            className={
                              detailDialog?.adventure?.result === 1
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            <b>
                              {detailDialog?.adventure?.result === 1
                                ? "Victory"
                                : "Defeat"}
                            </b>
                          </div>
                          <div className="text-secondary fs-tiny">
                            {timeAgo.format(
                              new Date(
                                detailDialog?.adventure?.startDate || null
                              )
                            )}
                          </div>
                          <a
                            onClick={() =>
                              (window.location.href =
                                "/battle/treasure/" +
                                encodeURIComponent(
                                  detailDialog?.adventure.replayId
                                ))
                            }
                            className="pointer text-info"
                          >
                            <img
                              className="me-2 mb-1"
                              src="/assets/images/icons/play.svg"
                              width={10}
                            />
                            Play
                          </a>
                        </div>
                      </PerfectScrollbar>
                    </div>
                  </>
                )}
            </div>
          </Col>
        </Row>
      </Dialog>
      <Dialog
        loading={Boolean(detailLoading)}
        title={confimDialog?.title}
        closeLabel="No"
        submitLabel="Yes"
        onSubmit={confimDialog?.submit}
        open={confimDialog?.open}
        onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
      >
        {confimDialog?.msg}
      </Dialog>
      <Share
        open={shareDialog.open}
        link={shareDialog.link}
        description="Choose a Social Media & Share it with your friends."
        onClose={() => setShareDialog((prev) => ({ ...prev, open: false }))}
        shareNote={`Hey fellows!!
I'm about to hit the sea to find some Treasuries! But I'd prefer to have a few extra Swords.
Hop on my ship & Join me if you are a trouble seeker!`}
      />
    </SkeletonTheme>
  );
};

export default AdventureHistory;
