import { AxiosInstance } from "axios";
import { Store } from "redux";

export default function setupAxios(axios: AxiosInstance, store: Store) {
  axios.defaults.timeout = 90000;
  let globalConfig = {
    notInterceptor: false,
  };

  axios.interceptors.request.use((config: any) => {
    globalConfig = config;
    if (config?.notInterceptor) return config;

    const token = String(store.getState().userToken);

    axios.defaults.withCredentials = !config?.secApi;

    config.headers["Content-Type"] = config?.content || "application/json";
    config.headers["x-language"] = "en";
    config.headers["Access-Control-Allow-Origin"] = "*";
    if (token) config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  });
  axios.interceptors.response.use(
    (response: any) => {
      globalConfig = response?.config;
      if (response?.config?.notInterceptor) return response;

      if (response.ok && response?.config?.notify) {
        // this.Notify(data);
      }
      if (typeof response === "string" || response?.config?.secApi) {
        return { ok: true, data: response };
      } else {
        return { ok: true, data: response?.data } as any;
      }
    },
    (error: any) => {
      if (globalConfig?.notInterceptor) return Promise.reject(error);
      const token = String(store.getState().userToken);
      if (error.response?.status === 401 && token) {
        store.dispatch({ type: "REMOVE_USER_TOKEN" });
        store.dispatch({ type: "REMOVE_USER_INFO" });
        store.dispatch({ type: "CLEAR_MESSAGES" });
        // store.dispatch({ type: 'CLEAR_CHANNELS' })
        localStorage.removeItem("user.token");
        if (window.location.pathname !== "/login") {
          document.location.href = `/login?return=${window.location.pathname}`;
        }
      }
      let messages =
        error?.response?.data && (error.response?.data?.message || null);
      if (error?.response?.status === 503 || error?.response?.status === 502) {
        messages = "Server is updating please try again a few moments later.";
        store.dispatch({
          type: "SET_ALERT_OPEN",
          payload: {
            open: true,
            msg: "Server is updating please try again a few moments later.",
            variant: "error",
          },
        });
      }

      if (messages) {
        messages = typeof messages === "object" ? messages[0] : messages;
      } else {
        messages = `Request Failed - ${
          error?.response?.status || "Connection Error !"
        }`;
      }
      const errorObj = {
        ok: false,
        status: error?.response?.status,
        message: messages,
      };
      return Promise.reject(errorObj);
    }
  );
}
