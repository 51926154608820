import { actionTypes } from './action';

export const userToken=null;
export const userInfo=null;

export const userTokenReducer = (state = userToken, action:any) => {
    switch (action.type) {
        case actionTypes.SET_USER_TOKEN:
            return action.payload;
        case actionTypes.REMOVE_USER_TOKEN:
            return null;
        default:
            return state;
    }
};

export const userInfoReducer = (state = userInfo, action:any) => {
    switch (action.type) {
        case actionTypes.SET_USER_INFO:
            return action.payload;
        case actionTypes.REMOVE_USER_INFO:
            return null;
        default:
            return state;
    }
};
