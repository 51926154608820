import { actionTypes } from "./action";

export const initialScouts = {};

export const scoutReducer = (state = initialScouts, action: any) => {
  switch (action.type) {
    case actionTypes.APPEND_SCOUT:
      return { ...state, [action.payload?.id]: action.payload };
    case actionTypes.CLEAR_SCOUTS:
      return {};
    default:
      return state;
  }
};
