import { Channel, ChatMessage, ChatSections, ChatUserMetaData } from "../../types/chat";


export const actionTypes = {
    ADD_MESSAGE: 'ADD_MESSAGE',
    EDIT_MESSAGE: 'EDIT_MESSAGE',
    READ_MESSAGE: 'READ_MESSAGE',
    REMOVE_MESSAGE: 'REMOVE_MESSAGE',
    CLEAR_MESSAGES: 'CLEAR_MESSAGES',
    JOIN_CHANNEL: 'JOIN_CHANNEL',
    LEAVE_CHANNEL: 'LEAVE_CHANNEL',
    CLEAR_CHANNELS: 'CLEAR_CHANNELS',
    SET_ACTIVE_CHANNEL: 'SET_ACTIVE_CHANNEL',
    SET_META_DATA: 'SET_META_DATA',
    SET_ACTIVE_CHAT: 'SET_ACTIVE_CHAT',
    BLOCK_USER: 'BLOCK_USER',
    IGNORE_USER: 'IGNORE_USER',
    OPEN_CHAT: 'OPEN_CHAT',
    CLOSE_CHAT: 'CLOSE_CHAT',
    SET_CHAT_PAGE:"SET_CHAT_PAGE"
};

export const openChat = () => ({
    type: actionTypes.OPEN_CHAT,
});
export const closeChat = () => ({
    type: actionTypes.CLOSE_CHAT,
});
export const addMessage = (payload:ChatMessage) => ({
    type: actionTypes.ADD_MESSAGE,
    payload,
});

export const setChatPage = (payload:ChatSections) => ({
    type: actionTypes.ADD_MESSAGE,
    payload,
});


export const editMessage = (payload:ChatMessage) => ({
    type: actionTypes.EDIT_MESSAGE,
    payload,
});

export const readMessage = (payload:ChatMessage) => ({
    type: actionTypes.READ_MESSAGE,
    payload,
});


export const removeMessage = (payload:ChatMessage) => ({
    type: actionTypes.REMOVE_MESSAGE,
    payload,
});



export const clearMessages = () => ({
    type: actionTypes.CLEAR_MESSAGES,

});

export const setActiveChannel = (payload:Channel) => ({
    type: actionTypes.SET_ACTIVE_CHANNEL,
    payload,
});

export const joinChannel = (payload:Channel) => ({
    type: actionTypes.JOIN_CHANNEL,
    payload,
});

export const leaveChannel = (payload:Channel) => ({
    type: actionTypes.LEAVE_CHANNEL,
    payload,
});

export const clearChannels = () => ({
    type: actionTypes.CLEAR_CHANNELS,
});

export const setMetaData = (payload:ChatUserMetaData) => ({
    type: actionTypes.SET_META_DATA,
    payload,
});

export const setActiveChat = (payload:{targetId?:string,section:ChatSections}) => ({
    type: actionTypes.SET_ACTIVE_CHAT,
    payload,
});

export const ignoreUser = (payload:ChatMessage) => ({
    type: actionTypes.IGNORE_USER,
    payload,
});



