import React, { FC, useEffect, useState } from "react";
import Network, { BaseURL } from "../../../utils/Network";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GuildJoinTypeEnum, GuildPlayerStateEnum, GuildType } from "../types";
import { PlayerProfile } from "../../../containers/player-profile/types";
import ThousandsSeparator from "../../../utils/thousands-separator";
import { formatNumber } from "../../../utils/format-number";
import { GuildJoinValues, GuildPlayerState } from "../data";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../../../hooks/useToast";
import Button from "../../../components/Button";
import { CustomIcon } from "../../../components/Icons";
import GuildMemberBox from "../../../components/GuildMemberBox";
import Dialog from "../../../components/Dialog";
import { Form } from "react-bootstrap";
import { setUserInfo } from "../../../redux/user/action";
import { ConfirmDialogType } from "../../../types/global";
import useWindowSize from "../../../hooks/useWindowSize";
import CircularProgress from "../../../components/CircularProgress";

type Props = {
  id: number;
};

type GuildDetail = {
  guild: GuildType;
  members: any;
  stateInGuild?: GuildPlayerStateEnum;
};
const GuildDetail: FC<Props> = ({ id }) => {
  const axios = new Network();
  const [data, setData] = useState<GuildDetail>();
  const userInfo = useSelector((state: any) => state?.userInfo);
  const dispatch = useDispatch();
  const openToast = useToast();
  const windowSize = useWindowSize();
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogType>({
    id: "0",
    open: false,
    submit: () => console.log(),
    title: "",
    msg: "",
  });
  const [actionLoading, setActionLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changeRoleDialog, setChangeRoleDialog] = useState({
    open: false,
    state: 1,
    loading: false,
    id: 0,
  });
  const navigate = useNavigate();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const handleGetDetail = async () => {
    setLoading(true);
    const response = await axios.CallApi({
      url: `/guild/${id}`,
      method: "get",
    });
    if (response.ok) {
      setData(response.data);
    }
    setLoading(false);
  };

  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };

  const changeRole = async (playerId: number, state: GuildPlayerStateEnum) => {
    setChangeRoleDialog((prev) => ({ ...prev, loading: true }));
    const response = await axios.CallApi({
      method: "get",
      url: `/guild/${id}/change-role?playerIdToChangeRole=${playerId}&newRole=${state}`,
    });
    openToast(response);
    setChangeRoleDialog((prev) => ({ ...prev, loading: false, open: false }));
    if (response.ok) {
      handleGetDetail();
      getMe();
    }
  };
  const changePlayerRole = async (
    playerId: number,
    state: GuildPlayerStateEnum
  ) => {
    setActionLoading(true);
    const response = await axios.CallApi({
      method: "get",
      url: `/guild/${id}/change-role?playerIdToChangeRole=${playerId}&newRole=${state}`,
    });
    openToast(response);
    setActionLoading(false);
    if (response.ok) {
      handleGetDetail();
      setConfirmDialog((prev) => ({ ...prev, open: false }));
    }
  };

  const handleKick = async (playerId: number) => {
    setActionLoading(true);
    const response = await axios.CallApi({
      method: "get",
      url: `/guild/${id}/kick?playerIdToKick=${playerId}`,
    });
    openToast(response);
    setActionLoading(false);
    if (response.ok) {
      handleGetDetail();
      setConfirmDialog((prev) => ({ ...prev, open: false }));
    }
  };

  const handleLeave = async () => {
    setActionLoading(true);
    const response = await axios.CallApi({
      url: `/guild/${id}/left`,
      method: "get",
    });
    openToast(response);
    if (response.ok) {
      searchParameters.set("tab", "explore");
      setSearchParameters(searchParameters);
      getMe();
    }
    setActionLoading(false);
  };

  const handleAccept = async () => {
    setActionLoading(true);
    const response = await axios.CallApi({
      url: `/guild/${id}/accept`,
      method: "get",
    });
    openToast(response);
    if (response.ok) {
      searchParameters.set("tab", "my-guild");
      setSearchParameters(searchParameters);
      getMe();
    }
    setActionLoading(false);
  };

  const handleJoin = async () => {
    setActionLoading(true);
    const response = await axios.CallApi({
      url: `/guild/${id}/join`,
      method: "get",
    });
    openToast(response);
    if (response.ok) {
      searchParameters.set("tab", "my-guild");
      setSearchParameters(searchParameters);
      handleGetDetail();
      getMe();
    }
    setActionLoading(false);
  };

  const handleEdit = () => {
    searchParameters.set("tab", "setting");
    searchParameters.set("id", id + "");
    setSearchParameters(searchParameters);
  };

  useEffect(() => {
    handleGetDetail();
  }, [id]);

  const isJoined = userInfo?.guild?.id === id;
  const isOwner = data?.guild.ownerId === userInfo?.id;
  const playerRole = data?.stateInGuild;
  const MainActions = [
    {
      name: "Chat",
      action: () => handleLeave(),
      loading: actionLoading,
      icon: { name: "chat", type: "svg" },
      variant: "default",
      disabled: true,
      condition: isJoined,
    },
    {
      name: "Setting",
      action: () => handleEdit(),
      loading: actionLoading,
      variant: "default",
      icon: { name: "settings", type: "svg" },
      disabled: false,
      condition: isJoined && isOwner,
    },
    {
      name: "Leave",
      action: () => handleLeave(),
      loading: actionLoading,
      variant: "secondary",
      icon: { name: "leave", type: "png" },
      disabled: false,
      condition: isJoined,
    },
    {
      name: "Accept Request",
      action: () => handleAccept(),
      loading: actionLoading,
      variant: "wide-success",
      icon: { name: "leave", type: "png" },
      disabled: false,
      condition:
        !isJoined && data?.stateInGuild === GuildPlayerStateEnum.Invited,
    },
    {
      name: "Join",
      action: () => handleJoin(),
      loading: actionLoading,
      variant: "success",
      disabled: false,
      condition:
        !isJoined &&
        data?.stateInGuild !== GuildPlayerStateEnum.Invited &&
        data?.guild.joinType === GuildJoinTypeEnum.Public,
    },
    {
      name: "Request Membership",
      action: () => handleJoin(),
      loading: actionLoading,
      variant: "wide-success",
      disabled: false,
      condition:
        !isJoined &&
        data?.stateInGuild !== GuildPlayerStateEnum.Invited &&
        data?.stateInGuild !== GuildPlayerStateEnum.JoinRequest &&
        data?.guild.joinType === GuildJoinTypeEnum.JoinRequest,
    },
    {
      name: "Request Pending",
      action: () => handleJoin(),
      variant: "wide-success",
      disabled: true,
      condition:
        data?.stateInGuild === GuildPlayerStateEnum.JoinRequest &&
        data?.guild.joinType === GuildJoinTypeEnum.JoinRequest,
    },
  ] as const;

  if (loading)
    return (
      <div
        style={{ minHeight: 400 }}
        className="d-flex align-items-center justify-content-center "
      >
        <CircularProgress />
      </div>
    );
  return (
    <div className="p-md-4 p-2">
      <div className="row align-items-center">
        <div className="col-12 my-3 my-md-0 col-md-5 gap-2 align-items-center d-flex">
          <div
            style={{
              backgroundImage: "url(/assets/images/avatar-bg.png)",
              minWidth: windowSize.width < 500 ? 100 : 150,
              minHeight: windowSize.width < 500 ? 100 : 150,
            }}
            className={"main-avatar"}
          >
            <img
              src={
                data?.guild?.image?.includes("chainoflegends")
                  ? data?.guild?.image
                  : BaseURL + data?.guild?.image
              }
              width={"70%"}
              height={"70%"}
              style={{
                position: "absolute",
                borderRadius: "50%",
                top: "12%",
                right: 0,
                left: "12%",
              }}
            />
          </div>

          <div>
            <h6 className="fs-big">{data?.guild?.name}</h6>
            <p className="fs-med text-white-50">{data?.guild?.description}</p>
          </div>
        </div>
        <div className="col-12 col-md-7 fs-lg fs-sm-small">
          <div className="row">
            <div className="col-6 col-md-4 d-flex flex-column gap-2">
              <div className="d-flex fs-lg align-items-center gap-1">
                <img
                  width={40}
                  height={40}
                  src={"/assets/images/icons/crown.png"}
                />
                Worth: {formatNumber(data?.guild?.score!)}
              </div>
              <div className="d-flex fs-lg align-items-center gap-1">
                <img
                  width={40}
                  height={38}
                  src={"/assets/images/icons/crown-flag.png"}
                />
                Join Score : +{ThousandsSeparator(data?.guild?.minRankScore!)}
              </div>
            </div>
            <div className="col-6 col-md-4 d-flex flex-column gap-2">
              <div className="d-flex fs-lg align-items-center gap-1">
                <img
                  width={40}
                  height={40}
                  src={"/assets/images/icons/glory.png"}
                />
                War Power: {formatNumber(data?.guild?.power!)}
              </div>
              <div className="d-flex fs-lg align-items-center gap-1">
                <img
                  width={40}
                  height={38}
                  src={"/assets/images/icons/letter.png"}
                />
                Type: {GuildJoinValues[data?.guild?.joinType!]}
              </div>
            </div>
            <div className="col-6 col-md-4 d-flex flex-column gap-2">
              {/* <div className="d-flex fs-lg align-items-center gap-1">
                <img
                  width={40}
                  height={40}
                  src={"/assets/images/icons/active-raid.png"}
                />
                Raids: {ThousandsSeparator(data?.guild?.raids! || 0)}
              </div> */}

              <div className="d-flex fs-lg align-items-center gap-1">
                <img
                  width={40}
                  height={38}
                  src={"/assets/images/icons/earth.png"}
                />
                Language : {data?.guild?.language}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-2 justify-content-end d-flex align-items-center">
          {MainActions.map(
            (action) =>
              action.condition && (
                <Button
                  className="bg-100"
                  variant={action.variant}
                  disabled={action.loading || action.disabled}
                  onClick={action.action || undefined}
                >
                  {action.icon && (
                    <CustomIcon
                      width={25}
                      height={25}
                      className="me-1"
                      icon={action.icon.name}
                      type={action.icon.type}
                    />
                  )}
                  {action.name}
                </Button>
              )
          )}
        </div>
      </div>
      <div className="row pt-3">
        {data?.members?.map((player) => (
          <div className="col-12 col-md-4 p-1 ">
            <GuildMemberBox
              {...player}
              isOwner
              playerRole={playerRole}
              onAccept={(playerId) =>
                setConfirmDialog((prev) => ({
                  ...prev,
                  id: playerId,
                  msg: "Are you sure about it ?",
                  open: true,
                  submit() {
                    changePlayerRole(playerId, GuildPlayerStateEnum.Member);
                  },
                  title: "Confirmation",
                }))
              }
              onReject={(playerId) =>
                setConfirmDialog((prev) => ({
                  ...prev,
                  id: playerId,
                  msg: "Are you sure about it ?",
                  open: true,
                  submit() {
                    changePlayerRole(
                      playerId,
                      GuildPlayerStateEnum.InviteRejected
                    );
                  },
                  title: "Confirmation",
                }))
              }
              onKick={(playerId) =>
                setConfirmDialog((prev) => ({
                  ...prev,
                  id: playerId,
                  msg: "Are you sure about it ?",
                  open: true,
                  submit() {
                    handleKick(playerId);
                  },
                  title: "Confirmation",
                }))
              }
              onChangeRole={(state, id) =>
                setChangeRoleDialog((prev) => ({
                  ...prev,
                  state,
                  id,
                  open: true,
                }))
              }
            />
          </div>
        ))}
      </div>

      <Dialog
        loading={actionLoading}
        title={confirmDialog?.title}
        closeLabel="No"
        submitLabel="Yes"
        onSubmit={() => confirmDialog.submit()}
        open={confirmDialog?.open}
        onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
      >
        {confirmDialog?.msg}
      </Dialog>
      <Dialog
        title="Change Role"
        closeLabel="Cancel"
        loading={changeRoleDialog.loading}
        submitLabel="Save"
        onSubmit={() => changeRole(changeRoleDialog.id, changeRoleDialog.state)}
        open={changeRoleDialog.open}
        onClose={() =>
          setChangeRoleDialog((prev) => ({ ...prev, open: false }))
        }
      >
        <div>
          <Form.Select
            value={changeRoleDialog.state}
            onChange={(e: any) => {
              setChangeRoleDialog((prev) => ({
                ...prev,
                state: e.target.value,
              }));
            }}
          >
            <option disabled selected>
              Choose
            </option>
            {Object.entries(GuildPlayerState)
              .slice(0, 3)
              .map(([key, value]) => (
                <option value={key}>{value}</option>
              ))}
          </Form.Select>
        </div>
      </Dialog>
    </div>
  );
};

export default GuildDetail;
