import { tourDefaultState } from "./reducer";

export const actionTypes = {
    SET_TOUR: 'SET_TOUR',
};

export const SetTour = (payload : typeof tourDefaultState) => ({
    type: actionTypes.SET_TOUR,
    payload,
});

