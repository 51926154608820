import { motion } from "framer-motion";
import React from "react";
import { isMobile } from "react-device-detect";

const SecurityAlert = () => {
  return (
    <motion.div
      initial={isMobile ? undefined : { opacity: 0, y: 200 }}
      transition={{
        duration: 0.3,
        delay: 0.1,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{ opacity: 0, y: -200 }}
      className=" my-0 py-0"
    >
      <div
        className="alert alert-warning  mx-2"
        role="alert"
        style={{ margin: "15px 0 30px 0" }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-exclamation-diamond-fill"
            viewBox="0 0 16 16"
          >
            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
          </svg>
          &nbsp; For security reasons, all <b>withdrawal</b> requests will be
          processed within <b>24 hours</b>.
        </div>
      </div>
    </motion.div>
  );
};

export default SecurityAlert;
