import React, { useState } from 'react'
import CountDown from 'react-countdown'
import Dialog from './Dialog';
import { ContestEndTime, ContestTime } from '../constants';
import { IsBeta } from '../utils/Network';
type Props = {
    styles?: React.CSSProperties;
    className?: string;
}

const TrCountDown = (props: Props) => {
    const currentTime = Date.now()
    const StartedTime = ContestTime
    const EndTime = ContestEndTime
    const isStarted = currentTime > StartedTime.getTime()
    const isEnded = currentTime > EndTime.getTime()
    const [open, setOpen] = useState(false)
    if (isEnded || IsBeta) return <></>
    return (<>
        <div onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpen(true)
        }} style={{ backdropFilter: 'blur(15px)',maxWidth:300, ...props.styles }} className={'p-2 glass-effect text-white pointer ' + (props.className)}>
            {isStarted ? <>
                1000$ Contest for 1024 treasure is live!
            </> : <>
                1000$ Contest starts in     
            </>}
            {' '}
            <CountDown
                date={isStarted ? EndTime : StartedTime}
            />
        </div>

        <Dialog title='1000$ Contest' size='lg' open={open} onClose={() => setOpen(false)}>
            <div className='text-center'>
                <img style={{ maxWidth: 500 }} className='mx-auto rounded' width={"100%"} src='/assets/images/banners/1000$contest.jpg' />
            </div>

            <div style={{marginBottom:-50}} className='fs-med mt-3'>
                <b>⚠️ Rules & Exceptions:</b><br />
                🔹 The rewards of the Championship are additional to the perks earned in Menorca Thesaurus.<br />
                🔹 You can be the Winner of both parts; either in a 1-time battle or in 2 successful battles!<br />
                🔹 You can not win more than two rewards! If a player has multiple D1024 Solo attacks or Raids during the Championship, only his last attack will be counted for the reward distribution.<br />
                🔹 You can attack in a PvP mode and still win the First part prizes!<br />
                🔹 You should Finish collecting the Treasury to be eligible for the second part prizes!<br />
                🔹 If you win in front of Buccaneers and then lose the treasury spot to another player, you are only eligible for the first part prizes.<br />
                🔹 If you go on a Raid on D1024, each player will get rewards based on his impact on the battle.<br />
                🔹 The Championship period is from September 1st, 12:00 AM UTC to September 11th, 12:00 AM UTC<br />
                🔹 You should Finish the Collecting phase before the due time! Otherwise, you won't be eligible for the second part prizes.<br />
                🔹 You should keep all of your Heroes in your account until the end of the Championship. Otherwise, you'll be ruled out of the Championship.<br />
                🔹 Any kind of behavior that seems suspicious to our point of view, will rule you out of the Championship.<br />
                🔹 The "Chain Of Legends" Team is allowed to change the rules for the public interest's sake.<br />
            </div>


        </Dialog>
    </>

    )
}

export default TrCountDown