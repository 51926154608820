export enum ItemStateEnum {
  Ready = 1,
  InUse = 2,
  Merged = 3,
  ForSale = 4,
}
export enum ItemBonusStatEnum {
  Health = 0,
  Armor = 1,
  Healing = 3,
  MagicResistance = 4,
  Damage = 5,
  Range = 6,
}
export enum ItemSlotEnum {
  Helmet = 1,
  Chest = 2,
  Legs = 3,
  Boots = 4,
  Gloves = 5,
  Weapon = 6,
}
export const ItemStateType = {
  1: "Ready",
  2: "In Use",
  3: "Merged",
  4: "For Sale",
};

export const ItemBonusStat = {
  0: "Health",
  1: "Armor",
  3: "Healing",
  4: "Magic Resistance",
  5: "Damage",
  6: "Range",
};

export const ItemBonusStatIcons = {
  0: "/assets/images/icons/icon-health-restore.png",
  1: "/assets/images/icons/armor.png",
  // 3: "Healing",
  4: "/assets/images/icons/team-magic-resist.png",
  5: "/assets/images/icons/damage.png",
  6: "/assets/images/icons/range.png",
};

export const ItemSlotNames = {
  1: "Helmet",
  2: "Mail",
  3: "Chausses",
  4: "Boots",
  5: "Gloves",
  6: "Weapon",
};

export interface ItemType {
  id: number;
  name: string;
  slot: ItemSlotEnum;
  bonusStat: ItemBonusStatEnum;
  bonusValue: number;
  image: string;
  troopIds: string;
}
export interface PlayerItemType {
  bonusValue: number;
  id: number;
  image: string;
  price?: number;
  item: ItemType;
  itemId: number;
  level: number;
  playerId: number;
  state: ItemStateEnum;
  sellOrder?: {
    id: number;
    itemType: number;
    itemId: number;
    state: number;
    quantity: number;
    filled: number;
    orderType: number;
    playerId: number;
    registerDate: string;
    lastUpdate: string;
    clegPrice: number;
    itemLevel: number;
  };
}
