import React, { useEffect, useRef, useState } from "react";
import MidBox from "../components/Boxes/MidBox";
import AuthLayout from "../layouts/AuthLayout";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Network, { CoreUrl, IsBeta } from "../utils/Network";
import { useDispatch } from "react-redux";
import { setUserToken } from "../redux/user/action";
import { OpenAlert } from "../redux/alert/action";
import useToast from "../hooks/useToast";
import { GoogleLogin as ReactGoogleLogin } from "@react-oauth/google";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { CustomIcon } from "../components/Icons";
import { WhiteListRefs } from "../constants/whitelistReferrals";
import axios from "axios";
import ThousandsSeparator from "../utils/thousands-separator";
import Skeleton from "react-loading-skeleton";
import useWindowSize from "../hooks/useWindowSize";
import CircularProgress from "../components/CircularProgress";
import { useLoginRedirect } from "../hooks/useLoginRedirect";

type Props = {};

const Login = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [waitingForSign, setWaitingForSign] = useState(false);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openToast = useToast();
  const fetch = new Network();
  const { open, close } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const { disconnect } = useDisconnect();
  const isDone = useRef(true);
  const isLoaded = useRef(false);
  const handleLoginRedirect = useLoginRedirect();
  const [loaded, setLoaded] = useState(false);
  const windowSize = useWindowSize();
  const captchaRef = useRef("");
  const [statistics, setStatistics] = useState({
    players: 0,
    markeplaceVolume: 0,
    price: 0,
    burnt: 0,
  });

  function resetLoaded({ noAction }: { noAction: boolean }) {
    if (!noAction) excuteRecaptcha();
    if (!captchaRef.current) {
      setTimeout(() => {
        resetLoaded({ noAction: false });
      }, 6000);
    }
  }

  useEffect(() => resetLoaded({ noAction: true }), []);

  useEffect(() => {
    captchaRef.current = captcha;
  }, [captcha]);

  const setCaptchaCallBack = React.useCallback((token: string) => {
    setCaptcha(token);
  }, []);

  const handleGetStatistics = async () => {
    const config = {
      method: "get",
      url: "/statistics",
    };
    const response = await fetch.CallApi(config);
    if (response.status === 200)
      setStatistics((prev) => ({ ...prev, ...response.data }));
  };

  const handleGetPrice = async () => {
    const config = {
      method: "get",
      url: "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=chain-of-legends",
      notInterceptor: true,
    };
    const response = await axios(config);

    if (response.status === 200)
      setStatistics((prev) => ({
        ...prev,
        price: response.data[0]?.current_price,
        burnt: response.data[0]?.max_supply - response.data[0]?.total_supply,
      }));
  };

  useEffect(() => {
    handleGetPrice();
    handleGetStatistics();
  }, []);

  useEffect(() => {
    if (isConnected && !isDone.current) {
      isDone.current = true;
      handleVerifyMessage();
    }
  }, [isConnected, address]);

  const handleVerifyMessage = async () => {
    try {
      const message = await getMessageContent();
      if (typeof message === "object" && !message.ok) {
        openToast({
          ok: false,
          message:
            "Cannot sign the message. please check your network and try again.",
        });
        return;
      }
      setWaitingForSign(true);
      dispatch(
        OpenAlert({
          msg: "Please Switch to your wallet and sign the message.",
          open: true,
          variant: "warning",
        })
      );
      const signedMessage = await signMessageAsync({ message: message });
      setWaitingForSign(false);
      handleLoginApi(signedMessage, address!, "walletconnect");
    } catch (e) {
      setWaitingForSign(false);
      setLoading(false);

      openToast({
        ok: false,
        message: e?.message?.includes("Already processing")
          ? "Your previous request is still processing! Please wait! (make sure to enter your wallet's password first, then request again)"
          : e?.message,
      });
    }
  };

  const getMessageContent = async () => {
    const signMessage = await fetch.CallApi({
      url: "/player/message-to-sign",
      method: "get",
    });

    return signMessage;
  };
  function getSize() {
    var allStrings = "";
    for (var key in window.localStorage) {
      if (window.localStorage.hasOwnProperty(key)) {
        allStrings += window.localStorage[key];
      }
    }
    return allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) : 0;
  }
  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      const size = getSize();
      if (size > 200) {
        localStorage.clear();
      }
    }
  }, []);

  const excuteRecaptcha = () => {
    window?.grecaptcha
      ?.execute("6LfdbIYoAAAAACravcE9SShwtp3LI7EMz5rH4gMa")
      .then(function (token) {
        setCaptchaCallBack(token);
      });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      // window?.grecaptcha.reset();
      excuteRecaptcha();
    }, 110000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window?.grecaptcha?.ready(function () {
      excuteRecaptcha();
    });
  }, [document.readyState]);

  useEffect(() => {
    const token = searchParams.get("jwtToken");
    if (token) {
      localStorage.clear();
      dispatch(setUserToken(token));
      localStorage.setItem("user.token", token);
      localStorage.setItem("connector", "metamask");
      localStorage.setItem("isLoginedBefore", "true");
      navigate("/");
    }
  }, []);

  const loginWithGoogleApi = async (token: string) => {
    const response = await fetch.CallApi({
      url: `/player/google-login?jwt=${token}&refCode=${
        localStorage.getItem("refCode") || undefined
      }`,
      method: "get",
    });
    openToast(response);
    if (response?.ok) {
      const user = await fetch.CallApi({
        url: "/player",
        method: "get",
        token: response?.data?.token,
      });
      if (user.data?.State === 2) {
        return dispatch(
          OpenAlert({
            msg: "You may have violated the game's terms, If you have not , please contact suspends@chainoflegends.com",
            open: true,
            variant: "error",
          })
        );
      }
      if (user.data?.State === 3) {
        return dispatch(
          OpenAlert({
            msg: "Your accont has been suspended. to continue playing the game KYC is required Please send your wallet address to suspends@chainoflegends.com",
            open: true,
            variant: "error",
          })
        );
      }
      dispatch(setUserToken(response?.data?.token));
      localStorage.setItem("user.token", response?.data?.token);
      localStorage.setItem("connector", "email");
      localStorage.setItem("isLoginedBefore", "true");
      setLoading(false);
      navigate("/home");
    }
  };

  const loginWithWalletConnect = async () => {
    if (isConnected) {
      disconnect();
      setTimeout(async () => {
        isDone.current = false;
        open();
      }, 1000);
      return;
    }

    // else {
    isDone.current = false;
    await open();

    // }
  };

  const handleLoginApi = async (
    sign: string,
    account: string,
    connector: string
  ) => {
    const body = {
      walletAddress: account,
      signedMessage: sign,
      captcha,
      refCode: localStorage.getItem("refCode"),
    };
    setLoading(true);
    const response = await fetch.CallApi({
      url: "/player/authenticate",
      body,
    });
    openToast(response);
    setLoading(false);

    if (response?.ok) {
      setCaptcha("");
      const user = await fetch.CallApi({
        url: "/player",
        method: "get",
        token: response?.data?.token,
      });
      if (user.data?.State === 2) {
        return dispatch(
          OpenAlert({
            msg: "You may have violated the game's terms, If you have not , please contact suspends@chainoflegends.com",
            open: true,
            variant: "error",
          })
        );
      }
      if (user.data?.State === 3) {
        return dispatch(
          OpenAlert({
            msg: "Your accont has been suspended. to continue playing the game KYC is required Please send your wallet address to suspends@chainoflegends.com",
            open: true,
            variant: "error",
          })
        );
      }
      dispatch(setUserToken(response?.data?.token));
      localStorage.setItem("user.token", response?.data?.token);
      localStorage.setItem("connector", connector);
      localStorage.setItem("isLoginedBefore", "true");
      if (searchParams.get("return")) {
        handleLoginRedirect();
      } else navigate("/home");
    } else {
      setCaptcha("");
      excuteRecaptcha();
    }
  };

  useEffect(() => {
    const refCode = searchParams?.get("ref");
    if (refCode) {
      localStorage.setItem("refCode", refCode);
    }
  }, []);
  const refCode = searchParams.get("ref") || localStorage.getItem("refCode");
  const isCaptchaSolved = Boolean(captcha);
  return (
    <AuthLayout>
      {/* <Helmet>
        <title>
          Login to game| Chain Of Legends
        </title>
      </Helmet> */}
      <div className="w-100 container py-5 d-flex flex-wrap align-items-center ">
        <motion.div
          style={{ width: "fit-content" }}
          exit={{ y: 30, opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
          initial={isMobile ? undefined : { y: 50, opacity: 0 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {IsBeta && (
            <div>
              <img
                style={{ maxWidth: 500 }}
                className="rounded w-100 mb-2"
                src="/assets/images/banners/beta-login-banner.jpg"
              />
            </div>
          )}
          {!IsBeta &&
            refCode &&
            WhiteListRefs.find((ref) => ref === +refCode) && (
              <div className="text-center">
                <img
                  style={{ maxWidth: 450 }}
                  className="rounded w-100 mb-2"
                  src="/assets/images/banners/play-login-banner.jpg"
                />
              </div>
            )}
          <MidBox
            style={{
              maxWidth: 500,
              minWidth:
                windowSize.width > 450
                  ? windowSize.width > 600
                    ? 500
                    : 420
                  : 300,
            }}
            className="mx-auto text-center"
            title="Continue With"
          >
            <motion.div
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={isMobile ? undefined : { y: 50, opacity: 0 }}
              animate={{ textAlign: "center", opacity: 1, y: 0 }}
            >
              <p className=" bold fs-big">Enter the Legends Era</p>
              <div className="fs-med">
                Connect with your available wallet or create new wallet to join
                our Game
              </div>
            </motion.div>

            <motion.div
              transition={{ duration: 0.3, delay: 0.7 }}
              initial={isMobile ? undefined : { y: 50, opacity: 0 }}
              animate={{ textAlign: "center", opacity: 1, y: 0 }}
            >
              <div className="text-center mt-4 pt-3">
                <div
                  style={{ maxWidth: 240 }}
                  onClick={() =>
                    !loading && isCaptchaSolved && loginWithWalletConnect()
                  }
                  className={
                    "d-flex button pointer mx-auto w-auto align-items-center  bg-dark round-2 px-1 ps-2 py-1  justify-content-between " +
                    (!isCaptchaSolved || waitingForSign || loading
                      ? "button-disabled"
                      : " pointer")
                  }
                >
                  {loading || waitingForSign ? (
                    <CircularProgress
                      style={{ width: 30, height: 30, margin: 0 }}
                    />
                  ) : (
                    <img
                      src="/assets/images/icons/metamask-icon.webp"
                      width={30}
                    />
                  )}
                  <div>
                    {loading
                      ? "Please wait..."
                      : waitingForSign
                      ? "Signing Message"
                      : "Wallet"}
                  </div>
                  <img
                    src="/assets/images/icons/walletconnect.png"
                    width={35}
                  />
                </div>
              </div>
            </motion.div>

            {/* <Collapse in={!isCaptchaSolved && !loading}>
              <motion.div className="fs-tiny mt-2">
                <div className=" d-flex justify-content-center align-items-center gap-2">
                  <Spinner animation="border" size="sm" />
                  Solving Captcha
                </div>
              </motion.div>
            </Collapse> */}
            <motion.div
              className="d-flex mt-2 align-items-center"
              transition={{
                duration: 0.3,
                delay: 0.8,
              }}
              initial={isMobile ? undefined : { y: 50, opacity: 0 }}
              animate={{ textAlign: "center", opacity: 1, y: 0 }}
            >
              <hr className="flex-1 me-2" />
              <span className="dark-gray">OR</span>
              <hr className="flex-1 ms-2" />
            </motion.div>
            {/* {IsBeta && <motion.div transition={{ duration: 0.3, delay: 0.7 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <div className='text-center'>
                            <Button loading={loading} className="mx-auto" onClick={loginWithGoogle} variant='rounded'>
                                {loading && waitingForSign ? "Waiting for Sign" : "Login with Google"}
                            </Button>
                        </div>
                    </motion.div>} */}
            <div
              style={{ width: "fit-content" }}
              className="text-center mx-auto mt-2 pb-4"
            >
              <ReactGoogleLogin
                shape="pill"
                size="large"
                theme="filled_black"
                onSuccess={(credentialResponse) => {
                  loginWithGoogleApi(credentialResponse.credential!);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>

            {!localStorage.getItem("isLoginedBefore") ? (
              <motion.div className="fs-small mt-3">
                <span className="text-warning bold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-exclamation-diamond-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                  </svg>
                  &nbsp;Warning:
                </span>{" "}
                Either "Creating Multiple Accounts" or "Referring oneself" to
                earn extra income is considered an action against our "
                <a
                  href="https://chainoflegends.com/terms-of-use.html"
                  target={"_blank"}
                  className="text-link"
                >
                  Terms of Use
                </a>
                ". Doing so will put your account at risk of getting suspended.
              </motion.div>
            ) : (
              <div className="fs-small dark-gray  mt-3">
                We do not own your private keys and cannot access your <br />{" "}
                funds without your confirmation.
              </div>
            )}
          </MidBox>
        </motion.div>
        <div className="ms-auto">
          <div className="d-flex gap-4 flex-column align-items-center ">
            <motion.div
              transition={{
                duration: 0.3,
                delay: 0.1,
              }}
              initial={isMobile ? undefined : { y: 150, opacity: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ y: 150, opacity: 0 }}
              className=" p-relative  d-flex align-items-center"
            >
              <img
                style={{ zIndex: 50 }}
                src="/assets/images/icons/login/players.png"
              />
              <div
                style={{
                  marginLeft: -68,
                  maxWidth: 450,
                  paddingInlineStart: 88,
                }}
                className="bg-brown-50 bg-blur pe-3 py-2 round d-flex flex-column justify-content-center"
              >
                <div className="fs-big bold lg-box-title ">Total Players</div>
                <div className="fs-big bold lg-box-value ">
                  {statistics.players === 0 ? (
                    <Skeleton width={70} />
                  ) : (
                    ThousandsSeparator(statistics.players)
                  )}{" "}
                </div>
                <div className="lg-box-desc">
                  Legends who play "Chain Of Legends" are the creators of the
                  strategies to deal
                </div>
              </div>
            </motion.div>
            <motion.div
              transition={{
                duration: 0.3,
                delay: 0.3,
              }}
              initial={isMobile ? undefined : { y: 150, opacity: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ y: 150, opacity: 0 }}
              className=" p-relative  d-flex align-items-center"
            >
              <img
                style={{ zIndex: 50 }}
                src="/assets/images/icons/login/marketplace-volume.png"
              />
              <div
                style={{
                  marginLeft: -68,
                  maxWidth: 450,
                  paddingInlineStart: 88,
                }}
                className="bg-brown-50 bg-blur pe-3 py-2 round d-flex flex-column justify-content-center"
              >
                <div className="fs-big bold lg-box-title ">
                  Marketplace Trades Volume
                </div>
                <div className="fs-big bold lg-box-value ">
                  {statistics.markeplaceVolume === 0 ||
                  statistics.markeplaceVolume === 0 ? (
                    <Skeleton width={120} />
                  ) : (
                    <>
                      {Intl.NumberFormat("en", { notation: "compact" }).format(
                        statistics.markeplaceVolume
                      )}{" "}
                      CLEG{" "}
                      <div className="ms-5 d-inline-block">
                        {Intl.NumberFormat("en", {
                          notation: "compact",
                        }).format(
                          statistics.markeplaceVolume * statistics.price
                        )}{" "}
                        USD{" "}
                      </div>
                    </>
                  )}
                </div>
                <div className="lg-box-desc">
                  The total volume of trading in-game NFTs among players marks
                  the flow of in-game life
                </div>
              </div>
            </motion.div>

            <motion.div
              transition={{
                duration: 0.3,
                delay: 0.5,
              }}
              initial={isMobile ? undefined : { y: 150, opacity: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ y: 150, opacity: 0 }}
              className=" p-relative  d-flex align-items-center"
            >
              <img
                style={{ zIndex: 50 }}
                src="/assets/images/icons/login/candle.png"
              />
              <div
                style={{
                  marginLeft: -68,
                  maxWidth: 450,
                  paddingInlineStart: 88,
                }}
                className="bg-brown-50 bg-blur pe-3 py-2 round d-flex flex-column justify-content-center"
              >
                <div className="fs-big bold lg-box-title ">
                  Total Token Burnt
                </div>
                <div className="fs-big bold lg-box-value ">
                  {statistics.burnt === 0 ? (
                    <Skeleton className="d-block-" width={80} />
                  ) : (
                    ThousandsSeparator(statistics.burnt) + "  CLEG"
                  )}
                </div>
                <div className="lg-box-desc">
                  "Aggressive CLEG Burn" burns 70% of all in-game CLEG spent by
                  players.
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <div
        style={{ position: "absolute", bottom: 35, right: 0, left: 0 }}
        className="text-center mx-auto"
      >
        <Link
          to="/learn"
          className="bg-brown-50 round-2 px-2 mt-4 p-1  text-white text-shadow"
        >
          <CustomIcon icon="learn-book" width={20} height={20} />
          &nbsp; Need Help? Learn How to Play
        </Link>
      </div>
    </AuthLayout>
  );
};

export default Login;
