import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LandBox from "../components/Boxes/LandBox";
import Button from "../components/Button";
import useToast from "../hooks/useToast";
import { setUserInfo } from "../redux/user/action";
import Network, { NFTContractAddress } from "../utils/Network";
import NftAbi from "../utils/nftAbi.json";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";

import { usePublicClient, useWalletClient ,useContractRead} from "wagmi";

type Props = {};

const NftBox = (props: Props) => {
  const userInfo = useSelector((state: any) => state.userInfo);
  const [boxes, setBoxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState("");
  const openToast = useToast();
  const { data: w3mprovider } = useWalletClient();

  // const {data,} = useContractRead({abi: NftAbi.abi,address:NFTContractAddress,functionName:'balanceOf',args:[NFTContractAddress]})
    const publicClient = usePublicClient()
  const axios = new Network();
  // const connector = new WalletConnect({
  //     bridge: "https://bridge.walletconnect.org", // Required

  // });
  // const web3Provider = new WalletConnectProvider({
  //     chainId: 56,
  //     connector: connector,
  //     rpc: {
  //         56: 'https://bsc-dataseed1.binance.org',
  //     },
  // });

  async function getNfts() {
    try {
      // if (localStorage.getItem('connector') === "walletconnect") await web3Provider.enable()
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(publicClient as any);
      const contract = new ethers.Contract(
        NFTContractAddress,
        NftAbi,
        provider
      );
      const balance = await contract.balanceOf(userInfo?.walletAddress);
      // const balance = await contract.balanceOf("0xaA0b57Ce4dbC6BC8755104f71aE60dd42Bd77710")
      let data = [];
      for (let i = 0; i < parseInt(balance.toString()); i++) {
        // for (let i = 0; i < 10; i++) {
        const tokenID = await contract.tokenOfOwnerByIndex(
          userInfo?.walletAddress,
          i
        );
        const tokenURI = await contract.tokenURI(tokenID);
        const tokenMetaData = await (await fetch(tokenURI)).json();
        data.push({
          tokenID: parseInt(tokenID.toString()),
          tokenURI,
          ...tokenMetaData,
          name: tokenMetaData.title,
          cycleNumber: tokenMetaData.cycle,
        });
      }
      setBoxes(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  const getData = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };
  async function confirmTransfer(tx: string) {
    const response = await axios.CallApi({
      url: "/nftbox/mint/" + tx,
      method: "get",
    });
    if (response.ok) {
      getNfts();
      getData();
      if (!response.data.message)
        response.data.message = "NFT BOX opened successfully ";
    }
    openToast(response);
  }
  async function openBox(tokenID: string) {
    try {
      // if (localStorage.getItem('connector') === "walletconnect") await web3Provider.enable()
      const provider = new ethers.providers.Web3Provider(w3mprovider as any);
      const signer = provider.getSigner(userInfo?.walletAddress);
      const contract = new ethers.Contract(
        NFTContractAddress,
        NftAbi,
        signer
      );
      setBtnLoading(tokenID);
      const transaction = await contract.transferFrom(
        userInfo?.walletAddress,
        NFTContractAddress,
        tokenID
      );
      await confirmTransfer(transaction?.hash);
    } catch (e) {
      console.log(e);
    } finally {
      setBtnLoading("");
    }
    // setBtnLoading('deposit')
  }
  useEffect(() => {
    getNfts();
  }, []);
  return (
    <div>
      <motion.div
        initial={isMobile ? undefined : { opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
        exit={{ opacity: 0 }}
        className="alert alert-primary  py-2"
        role="alert"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-exclamation-diamond-fill"
          viewBox="0 0 16 16"
        >
          <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
        </svg>
        &nbsp;You can buy or sell NFT Genesis boxes in &nbsp;
        <a
          href="https://tofunft.com/collection/chain-of-legends-nft/items"
          className="link-style color-inherit"
          target="_blank"
        >
          Tofunft
        </a>
        &nbsp; marketplace.
      </motion.div>
      <motion.div
        initial={isMobile ? undefined : { y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
        exit={{ y: 200, opacity: 0 }}
        className="row"
      >
        {loading &&
          [1, 2, 3, 4].map((index) => (
            <div
              key={index + "loads"}
              className="col-12 col-sm-6 col-md-4 col-lg-3"
            >
              <LandBox loading name="" />
            </div>
          ))}
        {!loading &&
          boxes?.map((item, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
              <LandBox
                nft
                {...item}
                action={
                  <Button
                    onClick={() => openBox(item.tokenID)}
                    loading={btnLoading === item.tokenID}
                    variant="wide"
                  >
                    Open Box
                  </Button>
                }
              />
            </div>
          ))}
        {!loading && boxes.length === 0 && (
          <div className="col-12 text-center my-4">
            <p className="fs-bigger dark-yellow">You have no NFT box yet :(</p>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default NftBox;
