import React, { useEffect, useRef, useState } from 'react'
import { useConnector } from '../connector/context'
import { useEventBus } from './useEventBusListener'
import { EventNames } from '../utils/event-bus'
import { BattleRecord } from '../types'

type Props = {}

const useWatch = () => {
    const { connector, connectionStatus } = useConnector()
    const data = useEventBus(EventNames.BattleList)
    const newBattle = useEventBus(EventNames.NewBattle)
    const isInitialized = useRef(false)
    const [battleRecords, setBattleRecords] = useState<BattleRecord[]>([])
    const dataRef = useRef(data)

    useEventBus(EventNames.EndBattle, null, (data) => {
        if (dataRef?.current) {
            const target = dataRef?.current?.find(t => t.battleId === data.battleId)
            if (target) {
                setBattleRecords(prev => [...prev.filter(t => t.battleId !== data.battleId)])
            }
        }
    })

    useEffect(() => {
        if (data && !isInitialized.current) {
            isInitialized.current = true
            setBattleRecords(data)
        }
    }, [data])

    useEffect(() => {
        dataRef.current = battleRecords
    }, [battleRecords])

    useEffect(() => {
        if (connector) {
            connector.subscribeToBattleList()
        }
        return () => {
            if (connector) {
                connector.unsubscribeFromBattleList();
            }
        }
    }, [connectionStatus])

    useEffect(() => {
        if (newBattle) {
            if (dataRef?.current && !dataRef?.current?.find(t => t.battleId === newBattle.battleId)) {
                setBattleRecords(prev => [...prev, {
                    battleId: newBattle.battleId,
                    attackerNames: newBattle.attackerNames,
                    defenderNames: newBattle.defenderNames,
                    attackerPlayers: newBattle.attackers,
                    defenderPlayers: newBattle.defenders,
                }])
            }
        }
    }, [newBattle])

    return (
        { battleList: battleRecords, connector }
    )
}

export default useWatch