import React, { useEffect, useRef, useState } from 'react'
import useWindowSize from '../hooks/useWindowSize'
import Network from '../utils/Network';
import Button from '../components/Button';
import { Badge, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import useToast from '../hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Dialog from '../components/Dialog';
import moment from 'moment';
import Countdown from 'react-countdown';
import { isMobile, isTablet } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { setPlayable } from '../redux/music/action';
import { useDispatch } from 'react-redux';
import { createRef } from 'react';
import TreasureInfo from '../components/TreasureInfo';
import { Helmet } from 'react-helmet';
import { ItemBonusStat } from '../types/items';
type Props = {}
enum PlayerTroopTargetPriority {
    Closest = 1,
    LowestHealth = 2,
    HighestDamage = 3,

}
const defaultTargetPriority = 1
interface arr {
    index: number,
    x: number,
    displayX: number,
    y: number,
    "playerTroopId": number | undefined,
    "targetPriority": PlayerTroopTargetPriority
}
const defaultArray: arr[] = [
    {
        index: 0,
        x: 0,
        displayX: 0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    },
    {
        index: 1,
        x: 1,
        displayX: 1,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    },
    {
        index: 2,
        x: 2,
        displayX: 2,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 3,
        x: 3,
        displayX: 3,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 4,
        x: 4,
        displayX: 4,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 5,
        x: 0,
        displayX: 0.5,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 6,
        x: 1,
        displayX: 1.5,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 7,
        x: 2,
        displayX: 2.5,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 8,
        x: 3,
        displayX: 3.5,

        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 9,
        x: 4,
        displayX: 4.5,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 10,
        x: 0,
        displayX: 0,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 11,
        displayX: 1,
        x: 1,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 12,
        displayX: 2,
        x: 2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 13,
        displayX: 3,
        x: 3,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 14,
        displayX: 4,
        x: 4,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 15,
        displayX: 0.5,
        x: 0,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 16,
        displayX: 1.5,
        x: 1,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 17,
        displayX: 2.5,
        x: 2,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 18,
        displayX: 3.5,
        x: 3,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 19,
        displayX: 4.5,
        x: 4,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 20,
        displayX: 0,
        x: 0,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 21,
        displayX: 1,
        x: 1,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 22,
        displayX: 2,
        x: 2,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 23,
        displayX: 3,
        x: 3,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 24,
        displayX: 4,
        x: 4,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 25,
        displayX: 0.5,
        x: 0,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 26,
        displayX: 1.5,
        x: 1,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 27,
        displayX: 2.5,
        x: 2,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 28,
        displayX: 3.5,
        x: 3,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 29,
        displayX: 4.5,
        x: 4,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
]
const defaultSupply = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
function isEven(n: string) { return parseInt(n) % 2 === 0 ? true : parseInt(n) === 0 ? true : false }
const PreFight = (props: Props) => {
    const [startLoading, setStartLoading] = useState(false)
    const windowSize = useWindowSize()
    const contaierRef = useRef()
    const overFlowContainer = useRef()
    const supplyContainer = useRef()
    const [selectMode, setSelectMode] = useState(false)
    const [selectProperties, setSelectProperties] = useState({ checkRoom: 0, battlePlace: 0 })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [show, setShow] = useState(0);
    const [arrayList, setArrayList] = useState(defaultArray)
    const [supplyList, setSupplyList] = useState(defaultSupply)
    const [result, setResult] = useState<any>()
    let [searchParams, setSearchParams] = useSearchParams();
    const width = contaierRef?.current?.offsetWidth;
    const containerHeight = contaierRef?.current?.offsetHeight;
    const calculatedSize = (width - 400) / (windowSize?.width < 800 ? 10 : 10)
    const elementSize = calculatedSize > 200 ? 200 : calculatedSize
    const axios = new Network()
    const [activePopper, setActivePopper] = useState<any>({})
    const openToast = useToast()
    const [resultDialog, setResultDialog] = useState(false)
    async function getTroops() {
        const response = await axios.CallApi({ url: '/troop', method: 'get' })
        // setSupplyList(response?.data)
    }
    useEffect(() => {
        getTroops()

    }, [])

    useEffect(() => {
    }, [arrayList])

    const handleOnMouseEnter = (id) => {
        setShow(id);
    }
    const handleOnMouseLeave = () => {
        setShow(0);
    }

    function handleSelectCheckRoom(id: number) {
        setSelectMode(true)
        // if (selectProperties?.checkRoom) {
        //     setSelectProperties(prev => ({ ...prev, checkRoom: id }))
        //     setSelectMode(false)
        //     return
        // }
        if (selectProperties?.battlePlace) {
            // TODO CHANGE

            return
        }
        setSelectProperties({ battlePlace: 0, checkRoom: id })

    }
    function handleBattlePlace(id?: number | string, i?: number) {
        if (selectProperties.checkRoom) {
            setArrayList((prev: any) => ([...prev?.filter(item => item?.index !== i), { ...prev?.find(irt => irt?.index === i), playerTroopId: selectProperties.checkRoom }]?.sort((a, b) => a.index - b.index)))
            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
            setSelectMode(false)
            return
        }
        if (selectProperties.battlePlace) {
            if (id && id === selectProperties.battlePlace) {
                setSelectProperties({ battlePlace: 0, checkRoom: 0 })
                setSelectMode(false)
                return
            };

            setArrayList((prev: any) => ([...prev?.filter(item => item?.index !== i && item?.playerTroopId !== selectProperties.battlePlace),
            {
                ...arrayList?.find(irt => irt?.index === i),
                playerTroopId: selectProperties.battlePlace,
            },
            {
                ...arrayList?.find(irt => irt?.playerTroopId === selectProperties.battlePlace),
                playerTroopId: id ? id : undefined,
            }
            ]?.sort((a, b) => a.index - b.index)))
            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
            setSelectMode(false)
            return
        }
        if (id) {
            setSelectMode(true)
            setSelectProperties({ battlePlace: parseInt(id?.toString()), checkRoom: 0 })
        }
    }
    const popperActiveItem = arrayList?.find((item) => item?.playerTroopId === activePopper?.id)

    function changeTargetPriority(id: number, value: number) {
        const oldObj: any = arrayList?.find((item) => item?.playerTroopId === id)
        setArrayList(prev => ([...prev?.filter(item => item?.playerTroopId !== id), { ...oldObj, targetPriority: value }]?.sort((a, b) => a.index - b.index)))
    }

    const getPlayerToops = async () => {
        const response = await axios.CallApi({ url: '/playertroop?count=50', method: 'GET' })
        if (response.ok) setSupplyList(response?.data?.data)
    }


    useEffect(() => {
        getPlayerToops()
    }, [])
    useEffect(() => {
        if (overFlowContainer) {
            overFlowContainer.current.scrollLeft = (windowSize.width < 600 ? contaierRef?.current?.offsetWidth / 3.6 : 200)
        }
    }, [])

    useEffect(() => {
        dispatch(setPlayable(false))
    }, [])
    const startAdventure = async () => {
        setStartLoading(true)
        const activeArray = [...arrayList?.filter(item => Boolean(item.playerTroopId))]
        const response = await axios.CallApi({
            url: "/adventure/start",
            body: {
                "treasureId": parseInt(searchParams?.get('treasureId') || 1),
                "playerTroopIds": activeArray?.map((item) => (item.playerTroopId)),
                formation: activeArray?.map((item) => ({
                    ...item,
                    playerTroop: supplyList?.find((detail: any) => detail?.id === item.playerTroopId)
                }))
            }
        })
        if (response.ok) {
            navigate(`/adventure/waiting/${response?.data?.result?.id}`)
            // setResult(response?.data?.result)
            // setResultDialog(true)
        }
        openToast(response)
        setStartLoading(false)


    }

    const popover = (
        <Popover
            onClick={(e) => e.stopPropagation()}
            id="popover-basic" style={{
                backgroundColor: 'transparent',
                backgroundImage: 'url(/assets/images/backgrounds/popper-bg.png)',
                backgroundSize: '100% 100%',
                minHeight: 210,
                width: 250,
                color: '#fff',
                border: 'none'
            }}

            onMouseEnter={() => handleOnMouseEnter(popperActiveItem?.playerTroopId)}

            onMouseLeave={handleOnMouseLeave}
        >
            <Popover.Header
                style={{
                    backgroundColor: 'transparent',
                    border: 'none'
                }}
                className="p-4 bold d-flex align-items-center" as="h3">
                <div className='icon-badge'>
                    <img src={activePopper?.troop?.icon}
                        width={35}
                        height={35}
                        className=""
                    />
                </div>
                Target priority
            </Popover.Header>
            <Popover.Body style={{ marginTop: -20 }} className='text-white ps-4'>
                <div style={{ marginTop: -10, marginBottom: 10 }} className="fs-tiny">
                    <span>
                        HP: {activePopper?.health}/{activePopper?.maxHealth} | Damage: {activePopper?.damage} | Armor: {activePopper?.armor} | Magic Resistance: {activePopper?.magicResistance}
                    </span>
                </div>
                <div className='d-flex flex-wrap gap-1 mb-2'>
                    {activePopper?.items?.map((item) =>
                        <OverlayTrigger overlay={<Tooltip>
                            {item?.item?.name} - level {item?.level}  <div className='text-success-light'>
                           +{item?.bonusValue} {ItemBonusStat[item?.item?.bonusStat]}
                            </div>
                        </Tooltip>}>
                            <div className='circle border p-1'>
                                <img
                                    width={25}
                                    height={25}
                                    className='circle'
                                    src={item.image}
                                />
                            </div>
                        </OverlayTrigger>
                    )}
    
                </div>
                <Form.Check
                    type={"radio"}
                    value={1}
                    checked={popperActiveItem?.targetPriority === 1}
                    label={`Closest`}
                    onClick={(e) => changeTargetPriority(popperActiveItem?.playerTroopId, 1)}
                />
                <Form.Check
                    type={"radio"}
                    value={2}
                    checked={popperActiveItem?.targetPriority === 2}
                    onClick={(e) => changeTargetPriority(popperActiveItem?.playerTroopId, 2)}
                    label={`Lowest HP`}
                />
                <Form.Check
                    type={"radio"}
                    value={3}
                    checked={popperActiveItem?.targetPriority === 3}
                    onClick={(e) => changeTargetPriority(popperActiveItem?.playerTroopId, 3)}
                    label={`Highest Damage`}
                />
                <br />
            </Popover.Body>
        </Popover>
    );

    return (
        <div ref={overFlowContainer} style={{ overflow: 'auto' }}>
            <Helmet>
                <title key={'title'}>
                    Dungeon Attack | Chain Of Legends
                </title>
            </Helmet>
            <TreasureInfo treasureType={1} id={searchParams?.get('treasureId')!} />
            <div style={{
                display: 'flex',
                justifyContent: windowSize.width < 990 ? 'flex-start' : ''
            }} ref={contaierRef} className='pre-fight ' >
                <div style={{
                    maxWidth: windowSize.width < 990 ? 'none' : '',
                    paddingInlineStart: windowSize.width > 1500 ? '8%' : windowSize.width < 990 ? '8%' : '10%',
                }} className='w-100  pe-3 pf-step1 '>
                    <div style={{ minWidth: 800 }} className='row '>
                        <div style={{ position: 'relative' }} className="d-flex mt-4 align-items-center col-12">
                            {arrayList?.map((item, i) => {
                                const ref = createRef()
                                return (
                                    <div key={i} className='pre-polygon d-flex pf-step4 justify-content-center'
                                        onClick={() => isMobile || isTablet ? handleBattlePlace(item?.playerTroopId, i) : null}
                                        onDragEnter={(e) => {
                                            const id = parseInt(e.dataTransfer.getData("text/plain"));
                                            // if (id) e
                                            //     .currentTarget
                                            //     .style
                                            //     .filter = ''
                                        }}
                                        onDragLeave={(e) => {
                                            // if (item?.playerTroopId) return e.currentTarget
                                            //     .style
                                            //     .filter = ""

                                            // e.currentTarget
                                            //     .style
                                            //     .filter = ''
                                        }}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                        }}
                                        onDrop={(e) => {
                                            const id = parseInt(e.dataTransfer.getData("text/plain"));
                                            if (arrayList?.find((item) => item.playerTroopId === id)) {
                                                setArrayList(prev => ([...prev?.filter(item => item?.playerTroopId !== id), { ...arrayList?.find((item) => item.playerTroopId === id), playerTroopId: undefined }]?.sort((a, b) => a.index - b.index)))
                                            }
                                            if (!id) return
                                            setArrayList(prev => ([...prev?.filter(item => item?.index !== i), { ...item, playerTroopId: id }]?.sort((a, b) => a.index - b.index)))
                                        }}
                                        style={{
                                            width: elementSize, height: elementSize,
                                            // transform:    `translateY(${elementSize / 2}px)`,
                                            filter: selectMode && !item?.playerTroopId ? "invert(30%) sepia(99%) saturate(323%)" : '',
                                            position: 'absolute',
                                            top: item?.y * elementSize + (windowSize?.width < 1200 ? containerHeight / 5 : containerHeight / 5),
                                            textAlign: 'center',
                                            left: (item?.displayX) * elementSize + 70,
                                            // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                                        }}
                                    >
                                        {item?.playerTroopId && <OverlayTrigger
                                            onEnter={(e) => {
                                                setActivePopper(supplyList?.find((supp) => supp?.id === item?.playerTroopId))

                                            }}
                                            key={item?.playerTroopId}
                                            show={show === item?.playerTroopId}
                                            container={ref}
                                            placement="right"
                                            overlay={popover}>
                                            <div ref={ref} onMouseEnter={() => handleOnMouseEnter(item?.playerTroopId)} onMouseLeave={handleOnMouseLeave}>

                                                <div
                                                    style={{ height: '40%', width: 5, top: "30%", left: 0, borderRadius: 5, position: 'absolute', display: 'flex', alignItems: 'flex-end' }}
                                                    className="p-relative bg-dark-500 overflow-hidden"
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: (supplyList?.find(detail => detail?.id === item?.playerTroopId)?.health * 100 / supplyList?.find(detail => detail?.id === item?.playerTroopId)?.maxHealth) + "%"
                                                            ,
                                                            backgroundColor: 'var(--theme-color-green-dark)'
                                                        }}
                                                    />
                                                </div>
                                                <Badge style={{
                                                    position: 'absolute',
                                                    bottom: 0, right: 0, left: 0, marginInline: 'auto', width: 'fit-content'
                                                }} bg="dark" >
                                                    {supplyList?.find(detail => detail?.id === item?.playerTroopId)?.level}
                                                </Badge>
                                                <img
                                                    className='scaleX'
                                                    onDragStart={(event) => {
                                                        event
                                                            .dataTransfer
                                                            .setData('text/plain', item?.playerTroopId)
                                                    }}
                                                    draggable

                                                    style={{
                                                        maxWidth: '100%',
                                                        marginTop: -20,
                                                        maxHeight: windowSize?.width > 1700 ? 150 : windowSize?.width < 800 ? 88 : 100
                                                    }}
                                                    src={supplyList?.find(detail => detail?.id === item?.playerTroopId)?.troop?.image}
                                                />
                                            </div>
                                        </OverlayTrigger>}
                                    </div>

                                    // <div style={{
                                    //     width: elementSize,
                                    //     height: elementSize,
                                    //     transform:    `translateY(${elementSize / 2}px)`,
                                    // }} id={`position${item?.index}`} className='pre-polygon'>
                                    // </div>


                                )
                            })
                            }
                            {defaultArray?.map((item) => <div className='pre-polygon'
                                style={{
                                    width: elementSize, height: elementSize,
                                    // transform:    `translateY(${elementSize / 2}px)`,
                                    position: 'absolute',
                                    opacity: 0.4,
                                    top: item?.y * elementSize + (windowSize?.width < 1200 ? containerHeight / 5 : containerHeight / 5),
                                    filter: 'grayscale(100%)',
                                    left: (item?.displayX + 5) * elementSize + 70,
                                    // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'

                                }}
                            >


                            </div>)}
                        </div>

                    </div>
                </div>

                {windowSize?.width < 800 ? <div className='text-center' style={{ position: 'fixed', bottom: "120px", right: 0, left: 0, }}>
                    {selectMode && selectProperties.battlePlace ? <Button
                        onClick={() => {
                            setArrayList((prev: any) => (
                                [...prev?.filter(item => item?.playerTroopId !== selectProperties.battlePlace),
                                {
                                    ...prev?.find(irt => irt?.playerTroopId === selectProperties.battlePlace),
                                    playerTroopId: undefined
                                }
                                ]?.sort((a, b) => a.index - b.index)))
                            setSelectMode(false)
                            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
                        }}
                        variant='wide' className='mx-auto '>
                        Remove
                    </Button> : ''}
                    <Button loading={startLoading} onClick={startAdventure} className='mx-auto ' variant='wide-primary'>
                        Start adventure
                    </Button>
                </div> : ''}
                <div onDragOver={(e) => {
                    e.preventDefault();
                }}
                    onDrop={(e) => {
                        const id = parseInt(e.dataTransfer.getData("text/plain"));
                        if (arrayList?.find((item) => item.playerTroopId === id)) {
                            setArrayList((prev: any) => ([...prev?.filter(item => item?.playerTroopId !== id), { ...arrayList?.find((item) => item.playerTroopId === id), playerTroopId: undefined }]?.sort((a, b) => a.index - b.index)))
                        }
                        if (!id) return
                        // setArrayList(prev => ([...prev?.filter(item => item?.index !== i), { ...item, playerTroopId: id }]?.sort((a, b) => a.index - b.index)))
                    }}

                    className='picker-bar pf-step2 align-items-center'>

                    <div style={{ flex: 1, height: '100%', padding: '15px 0' }} ref={supplyContainer}>
                        <PerfectScrollbar style={{ width: supplyContainer?.current?.offsetWidth }} >

                            <div style={{ height: '100%' }} className='d-flex'>
                                {supplyList?.length === 0 && <div>
                                    No troop available
                                </div>}
                                {supplyList?.map((item: any) => {
                                    if (!arrayList?.find(obj => obj.playerTroopId === item?.id) && item?.state === 1) return (
                                        <div style={{
                                            width: windowSize?.width > 800 ? (width / 10 - 5) : 120,
                                            minWidth: 120,
                                            height: '100%',
                                            opacity: selectMode ? (selectProperties?.battlePlace ? 1 : selectProperties?.checkRoom === item?.id ? 1 : 0.4) : 1
                                        }} className='fight-placement pf-step3'>

                                            <div
                                                onClick={() => isMobile || isTablet ? handleSelectCheckRoom(item.id) : null}
                                                onDragStart={(event) => {
                                                    event
                                                        .dataTransfer
                                                        .setData('text/plain', item.id)
                                                }} draggable={true}
                                                className='d-flex w-100 h-100 text-center p-relative justify-content-center align-items-center'>

                                                <div
                                                    style={{ height: '40%', width: 5, top: "30%", left: "17%", transform: 'rotate(12deg)', borderRadius: 5, position: 'absolute', display: 'flex', alignItems: 'flex-end' }}
                                                    className="p-relative bg-dark-500 overflow-hidden"
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: (item?.health * 100 / item?.maxHealth) + "%",
                                                            backgroundColor: 'var(--theme-color-green-dark)'
                                                        }}
                                                    />
                                                </div>
                                                <Badge style={{
                                                    position: 'absolute',
                                                    bottom: 0, right: 0, left: 0, marginInline: 'auto', width: 'fit-content'
                                                }} bg="dark" >
                                                    {item?.level}
                                                </Badge>
                                                <img
                                                    draggable={false} className="mx-auto scaleX"

                                                    style={{ maxWidth: '75%', maxHeight: 100 }} src={item?.troop?.image} />

                                            </div>
                                        </div>
                                    )
                                    else {
                                        return ''
                                    }
                                })}
                            </div>

                        </PerfectScrollbar>


                    </div>


                    {windowSize?.width > 800 &&
                        <Button loading={startLoading} onClick={startAdventure} className='ms-auto mx-2' variant='wide-primary'>
                            Start adventure
                        </Button>}
                </div>
                <Dialog closeLabel='Return' title="Adventure Result" open={resultDialog} onClose={() => { setResultDialog(false); navigate('/adventure') }}>

                    <p className='fs-big text-center bold dark-yellow'>
                        Troops sent successfully
                    </p>
                    <div style={{ marginBottom: -30 }} className='row mt-5 flex-column'>
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            {/* <img width={80} height={90} src={}/> */}
                            <span>
                                <img src={"/assets/images/icons/timer.png"} width={25} height={25} />  Arrival time
                            </span>
                            <Countdown
                                date={new Date(result?.arriveDate)}
                                renderer={({ formatted, days }) => (
                                    <div className='fs-big d-flex'>
                                        {days ?
                                            <div className='time-badge me-2'>
                                                {formatted.days}
                                                <span className='dark-yellow'>
                                                    Days
                                                </span>
                                            </div> : ""}
                                        <div className='time-badge'>
                                            {formatted.hours}
                                            <span className='dark-yellow'>
                                                Hours
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.minutes}
                                            <span className='dark-yellow'>
                                                Minutes
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.seconds}
                                            <span className='dark-yellow'>
                                                Seconds
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <hr />
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            {/* <img width={80} height={90} src={}/> */}
                            <span>
                                <img src={"/assets/images/icons/timer.png"} width={25} height={25} />  Return time
                            </span>
                            <Countdown
                                date={new Date(result?.returnDate)}
                                renderer={({ formatted, days }) => (
                                    <div className='fs-big d-flex'>
                                        {days ?
                                            <div className='time-badge me-2'>
                                                {formatted.days}
                                                <span className='dark-yellow'>
                                                    Days
                                                </span>
                                            </div> : ""}
                                        <div className='time-badge'>
                                            {formatted.hours}
                                            <span className='dark-yellow'>
                                                Hours
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.minutes}
                                            <span className='dark-yellow'>
                                                Minutes
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.seconds}
                                            <span className='dark-yellow'>
                                                Seconds
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                    </div>
                </Dialog>
            </div >
        </div>

    )
}

export default PreFight