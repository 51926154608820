import React, { useState, useEffect, useRef } from "react";
import * as THREE from "three";
import { Model, Unit } from "../../types";
import { useBattleContext } from "../../context";
import { createHexagonShape } from "../../utils/create-hexagon";

interface SlotProps {
  position: THREE.Vector3;
  onClick: () => void;
  id: string;
  userData: any;
  model?: Unit;
}
const getDistance = (x1, x2, y1, y2) => {
  x1 += y1 % 2 === 1 ? 0 : -0.5;
  x2 += y2 % 2 === 1 ? 0 : -0.5;
  return Math.round(Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2)));
};

const Slot: React.FC<SlotProps> = ({
  position,
  onClick,
  userData,
  id,
  model,
}) => {
  const [hovered, setHovered] = useState(false);
  const meshRef = useRef<THREE.Mesh>(null!);
  const { battleState } = useBattleContext();
  const { selectedTroop, playerIsAttacker } = battleState;
  const [selectedY, selectedX] = [selectedTroop?.y, selectedTroop?.x];
  const isDraft = selectedTroop && selectedTroop?.x < 0;
  const [y, x] = [+id.split("-")[0], +id.split("-")[1]];
  const canAttack = React.useMemo(() => {
    // can attack if within range
    if (
      selectedTroop &&
      selectedTroop.isFriend &&
      model &&
      !model.isFriend &&
      !isDraft
    ) {
      const range = selectedTroop.range;
      const distance = getDistance(selectedX, x, selectedY, y);
      if (distance <= range) return true;
    }
    return false;
  }, [selectedTroop]);

  const canHeal = React.useMemo(() => {
    // can attack if within range
    if (
      selectedTroop &&
      selectedTroop.isFriend &&
      model &&
      model.isFriend &&
      selectedTroop.troopId===5 &&
      !isDraft
    ) {
      const range = selectedTroop.range;
      const distance = getDistance(selectedX, x, selectedY, y);
      if (distance <= range) return true;
    }
    return false;
  }, [selectedTroop]);

  const isHighlighted = React.useMemo(() => {
    try {
      if (!selectedTroop || !selectedTroop.isFriend) return false;
      if (!isDraft) {
        // const range = selectedTroop.range;
        if (model) {
          // const range = model.range;
          // const distance = getDistance(selectedX, x, selectedY, y);
          // if (distance <= range) return true;
          return false;
        }
        const range = selectedTroop.troopId === 4 ? 2 : 1;
        const distance = getDistance(selectedX, x, selectedY, y);
        if (distance <= range) return true;
      }
      if (isDraft) {
        if (playerIsAttacker) {
          if (x < 5 && !model) {
            return true;
          } else return false;
        }
        if (x >= 5 && !model) {
          return true;
        }
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, [selectedTroop, isDraft, id]);

  const isSelected = React.useMemo(() => {
    if (!selectedTroop) return false;
    if (
      selectedTroop.x === +id.split("-")[1] &&
      selectedTroop.y === +id.split("-")[0]
    )
      return true;
    return false;
  }, [selectedTroop, isDraft, id]);

  // const { color } = useSpring({
  //   color: isHighlighted
  //     ? 0xffffff
  //     : isSelected
  //     ? 0x305347
  //     : hovered
  //     ? 0x605347
  //     : 0xffffff,
  //   config: { tension: 280, friction: 30 },
  // });

  function handleClick() {
    if (!selectedTroop && !model) return;
    onClick();
  }

  const innerRadius = 0.5;
  const depth = 0.01;

  // Inner hexagon
  const innerShape = createHexagonShape(innerRadius);
  const innerGeometry = new THREE.ExtrudeGeometry(innerShape, {
    depth,
    bevelEnabled: false,
  });
  const innerMaterial = new THREE.MeshBasicMaterial({
    shadowSide: THREE.DoubleSide,
    color: canHeal
      ? 0x00ff00
      : canAttack
      ? 0x91262e
      : hovered && selectedTroop && !model
      ? 0x000000
      : isHighlighted
      ? 0x000000
      : isSelected
      ? 0xffffff
      : 0x000000,
    transparent: true,
    opacity: canAttack
      ? 0.4
      : isHighlighted
      ? 0.2
      : isSelected
      ? 0.1
      : hovered
      ? 0.2
      : 0.1,
    // opacity:  1,
  });

  // Hexagon Border
  let borderVertices: any = [];
  const points = innerShape.getPoints();
  points.forEach((point) => {
    borderVertices.push(point.x, point.y, 0);
  });
  // Close the loop
  borderVertices.push(borderVertices[0], borderVertices[1], 0);

  const borderGeometry = new THREE.BufferGeometry();
  borderGeometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(borderVertices, 3)
  );
  const borderMaterial = new THREE.LineBasicMaterial({
    color: 0x000000,
    transparent: true,
    opacity: 1,
  });

  // White inner shadow effect
  const innerShadowMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.2,
    side: THREE.DoubleSide,
  });

  return (
    <>
      <mesh
        receiveShadow
        ref={meshRef}
        onClick={handleClick}
        position={position}
        scale={[1.07, 1.15, 1]}
        castShadow
        rotation={[1.58, 0, 11]}
        onPointerOver={(e) => {
          e.stopPropagation();
          setHovered(true);
        }}
        onPointerOut={() => setHovered(false)}
        userData={userData}
        geometry={innerGeometry}
        material={innerMaterial}
      />
    </>
  );
};

export default Slot;
