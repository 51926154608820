import { Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { FC, useRef, useState } from "react";
import * as THREE from "three";
const DamageText: FC<{ damage: number; position: THREE.Vector3 }> = ({ damage, position }) => {
  const textRef = useRef<THREE.Mesh>(null);
  const [visible, setVisible] = useState(true);
  const [fade, setFade] = useState(1);

  useFrame(({camera}) => {
    if (textRef.current) {
      textRef.current.position.y += 0.03; // Move the text upwards
      textRef.current.lookAt(camera.position);
      textRef.current.rotation.x   = 0;
   
      setFade((prevFade) => {
        const newFade = prevFade - 0.01; // Reduce opacity over time
        if (newFade <= 0) {
          setVisible(false); // Hide the text once it's fully faded
        }
        if (textRef.current && textRef.current.material instanceof THREE.Material) {
          textRef.current.material.opacity = Math.max(newFade, 0); // Update the material opacity
        }
        return newFade;
      });
    }
  });

  if (!visible) return null;

  return (
    <Text
      ref={textRef}
      position={position}
      fontSize={0.3}
      color={damage < 0 ? "red" : "green"} // Set base color without opacity
      anchorX="center"
      anchorY="middle"
      fontWeight={500}
      fontStyle="normal"
      material-toneMapped={false} // To ensure color isn't tone mapped (optional)
      material-transparent={true} // Make sure the material is transparent
    >
      {damage > 0 && "+"} {damage}
    </Text>
  );
};

export default DamageText;