import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout";
import PlainLayout from "../layouts/PlainLayout";
import Authorize from "../containers/authorize";
import {
  Login,
  Home,
  Learn,
  DAO,
  DaoDetails,
  MarketPlace,
  MyLands,
  NftBox,
  Notifications,
  PreFight,
  Referrals,
  Trade,
  TransactionHistory,
  WithdrawHistory,
  Adventure,
  BattleReplay,
  MyLandsNew,
  TroopOrderBook,
  ItemOrderBook,
  DungeonWating,
  ReferralLeaderBoard,
  TreasurePage,
  TreasureReplay,
  GLogin,
  TreasureWating,
  TreasureDetail,
  TreasureOrder,
  JoinTreasure,
  ModifyTreasure,
  BetaLeaderBoard,
  DungeonSelect,
  TreasureLose,
  Ranking,
  PublicProfile,
  Growth,
  GuildPage,
  NotFound,
  BattleLayout,
  PreFightContainer,
  Profile as ProfilePage,
} from "../pages";

const Barracks = lazy(() => import("../pages/Barracks"));

const BattleContainer = lazy(() => import("../containers/battle-container"));
const BattleResultComponent = lazy(
  () => import("../components/BattleResultComponent")
);

const routeConfig = [
  { path: "/login", element: <Login /> },
  { path: "/g-login", element: <GLogin />, condition: "IsBeta" },
  { path: "/Default", element: <Navigate to="/" /> },
  { path: "/learn", element: <Learn /> },
  {
    path: "/battle",
    element: <PlainLayout transparent={true} />,
    children: [
      {
        path: "replay/:id",
        element: (
          <BattleContainer>
            <BattleReplay />
          </BattleContainer>
        ),
      },
      {
        path: "result/:id",
        element: <BattleResultComponent />,
      },
      {
        path: "order",
        element: (
          <PreFightContainer>
            <PreFight />
          </PreFightContainer>
        ),
      },
      {
        path: "treasure/:id",
        element: (
          <BattleContainer>
            <TreasureReplay />
          </BattleContainer>
        ),
      },
    ],
  },
  {
    path: "/treasure",
    element: <PlainLayout transparent={true} />,
    children: [
      { path: "select", element: <TreasurePage /> },
      { path: "order", element: <TreasureOrder /> },
      { path: "join/:id", element: <JoinTreasure /> },
      { path: "modify/:id", element: <ModifyTreasure /> },
      { path: ":treasureID", element: <TreasureDetail /> },
    ],
  },
  {
    path: "/dungeon",
    element: <PlainLayout transparent={true} />,
    children: [{ path: "select", element: <DungeonSelect /> }],
  },
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      { path: "marketplace/orderbook/troop", element: <TroopOrderBook /> },
      { path: "marketplace/orderbook/item", element: <ItemOrderBook /> },
      { path: "guild", element: <GuildPage /> },
      { path: "order", element: <TreasureOrder /> },
      { path: "ranking", element: <Ranking /> },
      { path: "trade/:id", element: <Trade /> },
      { path: "dao/:id", element: <DaoDetails /> },
      { path: "adventure/new", element: <Navigate to="/dungeon/select" /> },
      { path: "adventure/waiting/:id", element: <DungeonWating /> },
      { path: "adventure/waiting/treasure/:id", element: <TreasureWating /> },
      { path: "treasure-lose", element: <TreasureLose /> },
      { path: "adventure", element: <Adventure isPage /> },
      { path: "transaction-history", element: <TransactionHistory /> },
      { path: "withdraw-history", element: <WithdrawHistory /> },
      {
        path: "beta-leaderboard",
        element: <BetaLeaderBoard />,
        condition: "IsBeta",
      },
      {
        path: "barracks",
        element: (
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                isLoading...
              </div>
            }
          >
            <Barracks />
          </Suspense>
        ),
      },
      { path: "marketplace", element: <MarketPlace /> },
      { path: "lands", element: <MyLandsNew /> },
      { path: "my-lands", element: <MyLands /> },
      { path: "profile/:id", element: <PublicProfile /> },
      { path: "profile", element: <ProfilePage /> },
      { path: "referrals", element: <Referrals /> },
      { path: "referral-leaderboard", element: <ReferralLeaderBoard /> },
      { path: "mint-lands", element: <NftBox /> },
      { path: "dao", element: <DAO /> },
      { path: "notifications", element: <Notifications /> },
      { path: "trade", element: <NftBox /> },
      { path: "home", element: <Home /> },
      { path: "growth", element: <Growth /> },
      { path: "authorize", element: <Authorize /> },
      { path: "", element: <Navigate to="/home" /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  { path: "/arena/*", element: <BattleLayout /> },
  { path: "/MarketPlace", element: <Navigate to="/marketplace" /> },
  { path: "/Lands", element: <Navigate to="/my-lands" /> },
  { path: "/MintLands", element: <Navigate to="/mint-lands" /> },
];

const RoutesConfig = ({ enableBgMusic, userSettings, IsBeta }) => {
  const renderRoutes = (routes) => {
    return routes.map((route) => {
      const { path, element, children, condition } = route;

      if (
        condition &&
        ((condition === "IsBeta" && !IsBeta) ||
          (condition === "!IsBeta" && IsBeta))
      ) {
        return null;
      }

      return (
        <Route
          key={path}
          path={path}
          element={React.cloneElement(element, {
            enableBgMusic,
            userSettings,
          })}
        >
          {children && renderRoutes(children)}
        </Route>
      );
    });
  };

  return <Routes>{renderRoutes(routeConfig)}</Routes>;
};

export default RoutesConfig;
