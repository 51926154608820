import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Dialog from "../Dialog";
import Network from "../../utils/Network";
import { MonthlyCompetition } from "../../types/global";
import { CustomIcon } from "../Icons";
import { roundFloat } from "../../utils/round-float";
import ThousandsSeparator from "../../utils/thousands-separator";
import { ItemType } from "../../types/items";
import useEvents from "../../hooks/useEvents";
import { Challenge } from "../../constants/events";
import { ChallengeQuest } from "../../types/quests/indext";
import ChallengeItemCard from "./ChallengeItemCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import useToast from "../../hooks/useToast";
import CountUp from "react-countup";
import { useIsFirstRender, useWindowSize } from "usehooks-ts";
import CircularProgress from "../CircularProgress";
import { ProgressBar } from "react-bootstrap";
import Countdown from "react-countdown";
type Props = {
  open: boolean;
  onClose: () => void;
};
type DiscountData = {
  startDate: string;
  endDate: string;
  discount: number;
};

const GrowthChallenge: FC<Props> = ({ onClose, open }) => {
  const [info, setInfo] = useState<ChallengeQuest>();
  const [items, setItems] = useState<ItemType[]>();
  const [loading, setLoading] = useState<number | boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const { events } = useEvents();
  const isFirstRender = useIsFirstRender();
  const { width } = useWindowSize();
  const openToast = useToast();
  const axios = new Network();
  const [progressBar, setProgressBar] = useState(10);
  const [discountData, setDiscountData] = useState<DiscountData[]>();
  const handleGetData = async () => {
    setFetchLoading(true);
    const response = await axios.CallApi({
      url: "/quest/challenges",
      method: "get",
    });
    setInfo(response.data);
    setFetchLoading(false);
  };

  function findCurrentDiscount(
    discountData: DiscountData[]
  ): DiscountData | null {
    const now = new Date();

    const currentDiscount = discountData.find((discount) => {
      const startDate = new Date(discount.startDate);
      const endDate = new Date(discount.endDate);

      return startDate <= now && now <= endDate;
    });

    return currentDiscount || null; // Return the found object or null if none is found
  }

  const handleGetDiscountData = async () => {
    const response = await axios.CallApi({
      url: "/quest/challenges/discounts",
      method: "Get",
    });
    setDiscountData(response.data);
  };

  const handlePurchaseItem = async (id: number) => {
    setLoading(id);
    try {
      const response = await axios.CallApi({
        url: "/quest/challenge/claim",
        method: "post",
        body: {
          challengeId: id,
        },
      });
      openToast(response);
      if (response.ok) handleGetData();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isFirstRender) {
      handleGetData();
      handleGetDiscountData();
    }
  }, []);

  const progress = useMemo(() => {
    if (!discountData || discountData.length === 0) return 0;

    const start = new Date(discountData[0].startDate).getTime();
    const end = new Date(
      discountData[discountData.length - 1].endDate
    ).getTime();
    const now = new Date().getTime();

    // Check if the current date is out of the range
    if (now < start) return 0; // Not started
    if (now > end) return 100; // Completed

    const totalDuration = end - start;
    const elapsed = now - start;
    const percentage = (elapsed / totalDuration) * 100;

    return percentage;
  }, [discountData]);

  const currentDiscount = discountData && findCurrentDiscount(discountData);
  return (
    <Dialog
      noFooter
      title="Growth Mayham"
      size="xl"
      open={open}
      onClose={onClose}
    >
      <div
        style={{ marginTop: -10 }}
        className="align-items-center flex-wrap bg-2 d-flex rounded fs-sm-small p-2"
      >
        <div style={{ minWidth: 300 }} className="flex-1">
          <ProgressBar
            variant="warning"
            style={{
              border: "1px solid #9D8224",
              background: "#24201A",
            }}
            now={progress}
          />
          <div className="d-flex mt-1">
            {discountData?.map((data) => (
              <div
                className="flex-1"
                style={{ marginTop: width < 500 ? -20 : -25 }}
              >
                <span className="text-secondary">|</span> <br />
                {data.discount}% Off
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex align-items-center flex-row flex-md-column">
          <div className="ps-md-4 w-100">
            {currentDiscount?.discount} % OFF Ends in
          </div>
          <Countdown
            date={new Date(currentDiscount?.endDate||0)}
            // onComplete={() =>
            //   setTimeout(() => {
            //     props.refresh();
            //   }, 1000)
            // }
            renderer={({ formatted }) => (
              <div
                style={{ transform: "scale(0.8)" }}
                className="fs-big justify-content-center d-flex"
              >
                {parseInt(formatted.days) ? (
                  <div className="time-badge">
                    {formatted.days}
                    <span className="dark-yellow">Days</span>
                  </div>
                ) : (
                  ""
                )}
                <div className="ms-2 time-badge">
                  {formatted.hours}
                  <span className="dark-yellow">Hours</span>
                </div>
                <div className="ms-2 time-badge">
                  {formatted.minutes}
                  <span className="dark-yellow">Minutes</span>
                </div>
                <div className="ms-2 time-badge">
                  {formatted.seconds}
                  <span className="dark-yellow">Seconds</span>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <br />
      <div
        style={{ marginTop: -10 }}
        className=" bg-2 text-center rounded fs-sm-small p-2"
      >
        <CustomIcon
          draggable={false}
          width={width < 500 ? 20 : 25}
          height={width < 500 ? 20 : 25}
          icon="info"
        />{" "}
        You can earn "Growth Points" by strengthening your army and upgrading
        your buildings. and "Claim" free rewards by using <b>"Growth Points"</b>
        .
      </div>

      <div className="p-relative mt-3 shadow-b">
        <div></div>
        <PerfectScrollbar style={{ maxHeight: 400 }} className="pb-5 ">
          <div className="row m-0  align-items-center ">
            {fetchLoading ? (
              <div
                style={{ minHeight: 400 }}
                className="col-12  d-flex align-items-center justify-content-center text-center"
              >
                <CircularProgress />
              </div>
            ) : (
              info?.rewards?.map((item) => (
                <div className="col-6 col-sm-6 p-1 col-lg-3" key={item.id}>
                  <ChallengeItemCard
                    discount={currentDiscount?.discount}
                    loading={loading === item.id}
                    disabled={
                      Boolean(loading) ||
                      info.availableScore <
                        (currentDiscount?.discount
                          ? (item.requiredActionValue *
                              (100 - currentDiscount?.discount)) /
                            100
                          : item.requiredActionValue)
                    }
                    onClick={(id) => handlePurchaseItem(id)}
                    {...item}
                  />
                </div>
              ))
            )}
          </div>
        </PerfectScrollbar>
      </div>
      <div
        style={{ marginBottom: width < 500 ? -60 : -40 }}
        className="bg-2 justify-content-center  p-relative px-0 px-sm-2 w-100 mx-auto rounded w-100 row align-items-center py-3"
      >
        <div className="d-flex justify-content-center justify-content-sm-start  col-12 col-md-5 gap-2 align-items-center">
          <CustomIcon icon="crown" type="png" />
          Monthly Rank Score :
          <div className="primary-badge">
            <CountUp end={info?.totalScore!} separator="," duration={3} />
          </div>
        </div>

        <div
          style={{
            background: width < 500 ? "#e5a82346" : "",
          }}
          className="d-flex p-2 p-sm-0 mt-2 mt-sm-0  justify-content-center  justify-content-sm-start  gap-2 col-12 col-md-4 align-items-center"
        >
          Growth Points :
          <div className="primary-badge text-yellow bold">
            <CustomIcon icon="hammer" className="me-2" type="png" />
            <CountUp end={info?.availableScore!} separator="," duration={4} />
          </div>
        </div>

        <div className=" gap-1 mt-2 mt-sm-0 col-12 justify-content-center  justify-content-sm-start d-flex d-md-block col-md-2 align-items-center">
          <div>
            <CustomIcon icon="timer" width={20} height={20} type="png" />
            Ends in:
          </div>
          {/* <span className="badge bg-danger rounded">ENDED</span> */}
          <Countdown date={Challenge.end.date} />
        </div>
        <div className="col-12 text-center mt-2 mt-md-0 col-md-1 ">
          <CustomIcon
            className="pointer"
            onClick={() => handleGetData()}
            icon="refresh"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default GrowthChallenge;
