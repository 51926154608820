import React, { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import useToast from "../hooks/useToast";
import { isMobile } from "react-device-detect";
import Dialog from "./Dialog";
import useWindowSize from "../hooks/useWindowSize";
import { IsBeta } from "../utils/Network";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import Lottie from "lottie-react";
import UnderAttackAnimation from "../animations/icon_battle.json";
import { useSelector } from "react-redux";
import TrCountDown from "./TrCountDown";
import QuestDialog from "./QuestDialog";
import useQuests from "../hooks/useQuests";
import { QuestState } from "../types/quests/indext";
import useEvents from "../hooks/useEvents";
import { CustomIcon } from "../components/Icons";
import { useBattleContext } from "../game-engine/context";
import useArenaLiveChecking from "../game-engine/hooks/useArenaLiveChecking";

const BottomBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(IsBeta ? true : false);
  const [isQuestOpen, setIsQuestOpen] = useState(false);
  const { halloweenEvent } = useEvents();
  const [adventureTypeDialog, setAdventureTypeDialog] = useState(false);
  const openToast = useToast();
  const { isFindMatchOpen } = useArenaLiveChecking();
  const windowSize = useWindowSize();
  const userInfo = useSelector((state: any) => state?.userInfo);
  const [voteDialog, setVoteDialog] = React.useState(false);
  const isChatOpen = useSelector((state: any) => state.chatState);
  const { quests } = useQuests();
  const handleUserKeyPress = (event: KeyboardEvent | any) => {
    const { key, keyCode } = event;
    if (key === "k") {
      setIsActive((prev) => !prev);
    }
  };

  useEffect(() => {
    if (isQuestOpen) window.location.hash = "#quests";
    if (location.hash === "#quests" && !isQuestOpen) setIsQuestOpen(true);
  }, [location.hash, isQuestOpen]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  });
  const doneQuests = useMemo(() => {
    let count = 0;
    quests.map(({ state }: { state: QuestState }) => {
      if (state === QuestState.Done) count += 1;
    });
    return count;
  }, [quests]);
  return (
    <>
      <motion.section
        transition={{ duration: 0.6 }}
        initial={isMobile ? undefined : { opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={
          {
            // marginLeft: isChatOpen? -245:0
          }
        }
        className="bottom-bar transition"
      >
        <div className="container">
          <div draggable={false} className="row user-select-none">
            <div className="col-6 col-sm-6 px-0 pe-1 pe-md-0 col-md-4 order-0 order-md-0 footer-btn-list mb-2">
              <div className="d-flex gap-md-0 gap-1 align-items-center justify-content-center">
                <motion.div
                  transition={{ duration: 0.3 }}
                  initial={isMobile ? undefined : { y: 200 }}
                  animate={{ y: 0 }}
                  exit={{ y: 200 }}
                  className="flex-1"
                >
                  <Link draggable={false} to={"/marketplace"}>
                    <div
                      draggable={false}
                      className="footer-btn pgtr-5  text-center second-step"
                    >
                      <button className="footer-btn-item button p-relative cr-btn mx-0 mx-md-2 p-2 p-md-3 p-lg-4">
                        <img
                          draggable={false}
                          width={70}
                          height={70}
                          className="img-fluid  icon"
                          src="/assets/images/icons/market.png"
                        />
                      </button>
                      <h4 className="fs-med dark-yellow my-2">
                        <span className="dark-yellow fs-sm-small  text-decoration-none fw-normal ">
                          Market
                        </span>
                      </h4>
                    </div>
                  </Link>
                </motion.div>
                <motion.div
                  transition={{ duration: 0.3, delay: 0.1 }}
                  initial={isMobile ? undefined : { y: 200 }}
                  animate={{ y: 0 }}
                  exit={{ y: 200 }}
                  className="flex-1"
                >
                  <Link draggable={false} to={"/referrals"}>
                    <div
                      draggable={false}
                      className="footer-btn pgtr-6 text-center twenty-step"
                    >
                      <button className="footer-btn-item button cr-btn mx-0 mx-md-2 p-2 p-md-3 p-lg-4 ">
                        <img
                          draggable={false}
                          width={70}
                          height={70}
                          className="img-fluid  icon"
                          src="/assets/images/icons/referral.png"
                        />
                      </button>
                      <h4 className="fs-med  dark-yellow my-2">
                        <span className="dark-yellow fs-sm-small  text-decoration-none fw-normal">
                          Referrals
                        </span>
                      </h4>
                    </div>
                  </Link>
                </motion.div>
                <motion.div
                  transition={{ duration: 0.3, delay: 0.14 }}
                  initial={isMobile ? undefined : { y: 200 }}
                  animate={{ y: 0 }}
                  exit={{ y: 200 }}
                  className="flex-1"
                >
                  <Link draggable={false} to={"/lands"}>
                    <div
                      draggable={false}
                      className="footer-btn pgtr-6 text-center twenty-step"
                    >
                      <button className="footer-btn-item button cr-btn mx-0 mx-md-2 p-2 p-md-3 p-lg-4 ">
                        <img
                          draggable={false}
                          width={70}
                          height={70}
                          className="img-fluid  icon"
                          src="/assets/images/icons/lands.png"
                        />
                      </button>
                      <h4 className="fs-med  my-2">
                        <span className="dark-yellow  fs-sm-small  text-decoration-none fw-normal">
                          My Lands
                        </span>
                      </h4>
                    </div>
                  </Link>
                </motion.div>
              </div>
            </div>

            <motion.div
              transition={{ duration: 0.3, delay: 0.15 }}
              initial={isMobile ? undefined : { y: 200 }}
              animate={{ y: 0 }}
              exit={{ y: 200 }}
              className="col-12 col-sm-12 col-md-4 order-2 order-md-1 pt-0 pt-lg-4 main-tab p-relative"
            >
              {/* {userInfo?.underAttack && (
                <Link to="/adventure">
                  <div className="warning-bt align-items-center d-flex fs-tiny text-white">
                    <Lottie
                      style={{ width: isMobile ? 20 : 25 }}
                      animationData={UnderAttackAnimation}
                      loop
                      autoPlay
                    />
                    Under Attack
                  </div>
                </Link>
              )} */}
              <div className="mb-5 mb-md-4 mt-2 mt-lg-4">
                <motion.div
                  transition={{ duration: 0.3, delay: 0.3 }}
                  initial={isMobile ? undefined : { y: 200 }}
                  animate={{ y: 0 }}
                  exit={{ y: 200 }}
                >
                  <Link
                    draggable={false}
                    className={
                      `main-tab-btn w-fit p-0 selected ${
                        userInfo?.underAttack && "under-attack"
                      } ` + (location.pathname === "/home" ? "" : "")
                    }
                    to="/home"
                  >
                    <img
                      // style={{ marginTop: -10 }}
                      className="img-fluid"
                      src={
                        location.pathname === "/home"
                          ? "/assets/images/buttons/active-tab-btn.png"
                          : "/assets/images/buttons/off-tab-btn.png"
                      }
                    />

                    <div className="b-text d-flex align-items-center justify-content-center px-3 text-light">
                      <span>Home</span>
                    </div>
                  </Link>
                </motion.div>
              </div>
            </motion.div>
            <div className="col-6 col-sm-6 px-0 pe-1 col-md-4 order-1 order-md-2 footer-btn-list mb-2">
              <div className="d-flex gap-md-0 gap-1 align-items-center justify-content-center">
                <motion.div
                  transition={{ duration: 0.3, delay: 0.25 }}
                  initial={isMobile ? undefined : { y: 250, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 250, opacity: 0 }}
                  className="footer-btn flex-1 text-center"
                >
                  <Link to="/ranking">
                    <button className="footer-btn-item  p-relative  button cr-btn mx-0 mx-md-2  p-2 p-md-3 p-lg-4 py-lg-4">
                      <img
                        width={70}
                        height={70}
                        className="img-fluid ms-0 icon"
                        src="/assets/images/icons/crown.png"
                      />
                    </button>
                    <h4 className="fs-med dark-yellow fs-sm-small mt-2 pt-1 pt-md-0 mt-md-2">
                      Ranking
                    </h4>
                  </Link>
                </motion.div>

                <motion.div
                  transition={{ duration: 0.3, delay: 0.3 }}
                  initial={isMobile ? undefined : { y: 250, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 250, opacity: 0 }}
                  className="footer-btn blur flex-1 p-relative text-center pgtr-8"
                >
                  {/* {windowSize.width >= 1200 && IsBeta&&(
                    <>
                      <Link to={"/adventure"}>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Adventure History</Tooltip>}
                        >
                          <motion.div
                            transition={{ type: "spring", delay: 1.1 }}
                            initial={isMobile ? undefined : { scale: 0 }}
                            animate={{ scale: 1 }}
                            whileHover={{
                              width: 55,
                              height: 55,
                            }}
                            exit={{ scale: 0 }}
                            className="sec-icon-bt pointer circle "
                            style={{
                              left: -30,
                              bottom: 18,
                            }}
                          >
                            <img
                              src={"/assets/images/icons/history.svg"}
                              width={25}
                              height={25}
                            />
                          </motion.div>
                        </OverlayTrigger>
                      </Link>

                      <Link to={"/dungeon/select"}>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Dungeons</Tooltip>}
                        >
                          <motion.div
                            transition={{ type: "spring", delay: 1.3 }}
                            initial={isMobile ? undefined : { scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0 }}
                            className="sec-icon-bt pointer circle "
                            style={{
                              left: -48,
                              bottom: 79,
                            }}
                          >
                            <img
                              src={"/assets/images/dungeon.png"}
                              width={33}
                              height={33}
                            />
                          </motion.div>
                        </OverlayTrigger>
                      </Link>
                      <Link to={"/treasure/select"}>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Treasure Isands</Tooltip>}
                        >
                          <motion.div
                            transition={{ type: "spring", delay: 1.5 }}
                            initial={isMobile ? undefined : { scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0 }}
                            className="sec-icon-bt pointer circle "
                            style={{
                              left: -28,
                              bottom: 140,
                            }}
                          >
                            <img
                              src={"/assets/images/treasureIsland.png"}
                              width={33}
                              height={33}
                            />
                          </motion.div>
                        </OverlayTrigger>
                      </Link>
                    </>
                  )} */}

                  {/* <button
                                        onClick={() => setAdventureTypeDialog(true)}  */}
                  {/* transition={{ duration: 0.3, delay: 0.3 }} initial={isMobile ? undefined : { y: 250, opacity: 0 }} 
                                    animate={{ y: 0, opacity: 1 }} exit={{ y: 250, opacity: 0 }}
                                    className="footer-btn pgtr-8 text-center twentytwo-step"> */}
                  <button
                    onClick={() => setAdventureTypeDialog(true)}
                    className="footer-btn-item  p-relative button button cr-btn mx-0 mx-md-2 p-2 p-md-3 p-lg-4"
                    name="btnAdvanture"
                  >
                    {halloweenEvent.isActive && (
                      <div
                        className={
                          "fire-badge " + (isMobile ? "fs-large" : "fs-large")
                        }
                        style={{
                          width: isMobile ? 37 : 50,
                          height: isMobile ? 40 : 55,
                        }}
                      >
                        {halloweenEvent.extraTreasureRewardMultiplier}X
                      </div>
                    )}
                    {isFindMatchOpen && (
                      <Badge
                        className="ref-badge"
                        style={{
                          position: "absolute",
                          zIndex: 50,
                          bottom: 10,
                          right: 0,
                          left: 0,
                          marginInline: "auto",
                          width: "fit-content",
                        }}
                        bg="danger"
                      >
                        Live 🔥
                      </Badge>
                    )}
                    <img
                      draggable={false}
                      width={70}
                      height={70}
                      className="img-fluid  icon"
                      src="/assets/images/icons/blades.png"
                    />
                  </button>
                  <h4 className="fs-med fs-sm-small dark-yellow my-2">
                    Adventure
                  </h4>
                </motion.div>

                <motion.div
                  onClick={() => setIsQuestOpen(true)}
                  transition={{ duration: 0.3, delay: 0.4 }}
                  initial={isMobile ? undefined : { y: 250, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 250, opacity: 0 }}
                  className="footer-btn flex-1 text-center ten-step"
                >
                  <button
                    className="footer-btn-item  p-relative  pgtr-9 button cr-btn mx-0 mx-md-2 p-2 p-md-3 p-lg-4"
                    name="btnMintLands"
                  >
                    {doneQuests > 0 && (
                      <Badge
                        className="ref-badge"
                        style={{
                          position: "absolute",
                          zIndex: 50,
                          bottom: 10,
                          right: 0,
                          left: 0,
                          marginInline: "auto",
                          width: "fit-content",
                        }}
                        bg="danger"
                      >
                        {doneQuests}
                      </Badge>
                    )}
                    <img
                      draggable={false}
                      width={70}
                      height={70}
                      className="img-fluid  icon"
                      src="/assets/images/icons/leaderboard.png"
                    />
                  </button>
                  <h4 className="fs-med dark-yellow fs-sm-small mt-1 mt-md-0">
                    Quests
                  </h4>
                </motion.div>

                {/* <Link to={"/referral-leaderboard"}>
                    <motion.div
                      transition={{ duration: 0.3, delay: 0.4 }}
                      initial={isMobile ? undefined : { y: 250, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: 250, opacity: 0 }}
                      className="footer-btn text-center ten-step"
                    >
                      <button
                        className="footer-btn-item  p-relative  pgtr-9 button cr-btn mx-0 mx-md-2 p-2 p-md-3 p-lg-4"
                        name="btnMintLands"
                      >

                        <img
                          draggable={false}
                          style={{
                            filter: "sepia(100%) contrast(120%) saturate(6)",
                          }}
                          width={88}
                          height={88}
                          className="img-fluid icon"
                          src="/assets/images/icons/leaderboard.png"
                        />
                      </button>
                      <h4 className="fs-med dark-yellow mt-1 mt-md-0">
                        Leaderboard
                      </h4>
                    </motion.div>
                  </Link> */}

                {/* } */}
              </div>
            </div>
          </div>
        </div>
      </motion.section>

      <Dialog
        closeLabel="Not now"
        noFooter
        onClose={() => setAdventureTypeDialog(false)}
        open={adventureTypeDialog}
        title={"Adventure Type"}
      >
        <div
          onClick={() => setAdventureTypeDialog(false)}
          className="row align-items-center text-center"
        >
          <div className="col-6 pointer adventure-type">
            <Link to={"/dungeon/select"}>
              <motion.img
                transition={{ duration: 0.4, delay: 0.1 }}
                initial={isMobile ? undefined : { y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 250, opacity: 0 }}
                alt="dungeon "
                src={"/assets/images/adventure-icons/dungeon.png"}
                style={{ maxHeight: 167 }}
                width={"100%"}
              />
              <motion.p
                transition={{ duration: 0.4, delay: 0.2 }}
                initial={isMobile ? undefined : { y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 250, opacity: 0 }}
                className="dark-yellow  d-flex align-items-center justify-content-center mt-4 bold"
              >
                {halloweenEvent.extraRewardTreasureId <= 8 &&
                  halloweenEvent.extraRewardTreasureId !== 0 &&
                  halloweenEvent.extraTreasureRewardMultiplier > 1 && (
                    <div
                      style={{ position: "relative", marginTop: -8 }}
                      className="fire-badge"
                    >
                      {halloweenEvent.extraTreasureRewardMultiplier}X
                    </div>
                  )}{" "}
                Dungeon
              </motion.p>
            </Link>
          </div>

          <div className="col-6 p-relative pointer adventure-type">
            <Link to={"/treasure/select"}>
              <motion.img
                transition={{ duration: 0.4, delay: 0.2 }}
                initial={isMobile ? undefined : { y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 250, opacity: 0 }}
                alt="Treasure Island"
                src={"/assets/images/adventure-icons/treasure.png"}
                width={"100%"}
                style={{ maxHeight: 167 }}
              />
              <motion.p className="dark-yellow mt-4 bold justify-content-center d-flex align-items-center">
                {halloweenEvent.extraRewardTreasureId > 8 &&
                  halloweenEvent.extraRewardTreasureId !== 0 &&
                  halloweenEvent.extraTreasureRewardMultiplier > 1 && (
                    <div
                      className="fire-badge"
                      style={{ position: "relative", marginTop: -8 }}
                    >
                      {halloweenEvent.extraTreasureRewardMultiplier}X
                    </div>
                  )}{" "}
                Treasure Island
              </motion.p>
            </Link>
          </div>

          <div className="col-6 p-relative pointer adventure-type">
            <Link to={"/guild"}>
              <motion.img
                transition={{ duration: 0.4, delay: 0.2 }}
                initial={isMobile ? undefined : { y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 250, opacity: 0 }}
                alt="Guilds"
                src={"/assets/images/adventure-icons/castle.png"}
                width={"100%"}
                style={{ maxHeight: 167 }}
              />
              <motion.p className="dark-yellow mt-4 bold justify-content-center d-flex align-items-center">
                Guild
              </motion.p>
            </Link>
          </div>
          <div
            onClick={(e) => e.stopPropagation()}
            className="col-6 p-relative pointer adventure-type"
          >
            <Link to={"/arena"}>
              <motion.img
                transition={{ duration: 0.4, delay: 0.2 }}
                initial={isMobile ? undefined : { y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 250, opacity: 0 }}
                alt="Arena"
                src={"/assets/images/adventure-icons/arena.png"}
                style={{ maxHeight: 167, width: "100%" }}
              />
              {isFindMatchOpen && (
                <div
                  style={{
                    bottom: "20%",
                    right: 0,
                    left: 0,
                    width: "fit-content",
                  }}
                  className="badge mx-auto bg-danger  position-absolute"
                >
                  Live 🔥
                </div>
              )}
              <motion.p className="dark-yellow mt-4 bold justify-content-center d-flex align-items-center">
                Arena
              </motion.p>
            </Link>
          </div>
          <div
            className="col-12 text-center pt-3"
            style={{ marginBottom: -50 }}
          >
            <Link onClick={() => setAdventureTypeDialog(false)} to="/adventure">
              <a className="dark-yellow d-flex justify-content-center gap-2 align-items-center">
                <CustomIcon width={25} height={25} icon="history" type="svg" />
                Adventure History
              </a>
            </Link>
          </div>
        </div>
      </Dialog>

      <Dialog
        title="Whitelist Campaigns"
        size="lg"
        open={voteDialog}
        onClose={() => {
          setVoteDialog(false);
        }}
      >
        <div className="text-center">
          <img
            alt="Chain Of Legends Adventure"
            style={{ maxWidth: 400, marginInline: "auto" }}
            width={"100%"}
            src="/assets/images/beta.jpg"
          />
        </div>
        <h4 className="mt-4 text-center bold">
          📌 Are you ready for the Adventure?
        </h4>
        <p className="mt-3 ">
          Get Whitelisted for playing the Adventure Mode! Go for the Events
          announced on our social media and discuss them with other players.
          Participate in the campaigns and get whitelisted.{" "}
        </p>
        <p style={{ marginBottom: -40 }} className="text-center">
          {" "}
          <a href="https://discord.gg/PzAuDGbukY" target={"_blank"}>
            🔹 Discord
          </a>
          &nbsp;
          <a href="https://twitter.com/chainoflegends" target={"_blank"}>
            🔹 Twitter
          </a>
          &nbsp;
          <a href="https://t.me/chainoflegends" target={"_blank"}>
            🔹 Telegram
          </a>
        </p>
      </Dialog>
      <QuestDialog
        open={isQuestOpen}
        onClose={() => {
          setIsQuestOpen(false);
          navigate(location.pathname);
        }}
      />
    </>
  );
};

export default BottomBar;
