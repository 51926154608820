import React, { FC } from "react"
import Slider from "rc-slider"
import Button from "../../../components/Button"
import { AnimatePresence } from "framer-motion"
import ThousandsSeparator from "../../../utils/thousands-separator"
import getTroopPrice from "../../utils/get-troop-price"
import { FormationCampArmy, FormationCampArmyItem, Troop } from "../../types"
import getModelsData from "../../utils/get-model-data"
import ModelPreview from "./model-preview";
import { CustomIcon } from "../../../components/Icons"
import classNames from "classnames"

interface PreviewerContentProps {
  activeTroop?: Troop | FormationCampArmy
  isMyArmyOnly?: boolean
  windowWidth: number
  changeLevel: (val: number) => void    
  onSubmit: (troop: Troop) => void;

}

const  PreviewerContent:FC<PreviewerContentProps> = ({activeTroop,isMyArmyOnly,windowWidth,changeLevel,onSubmit}) => {
    const previewModel = activeTroop
    ? getModelsData((activeTroop as Troop).id || (activeTroop as FormationCampArmy).troopId)
    : undefined;
    
   return <div className="pb-4 pb-md-0">
      <div className="p-relative">
        <div
          style={{
            maxHeight: windowWidth > 768 ? 700 : 300,
            minHeight: windowWidth > 768 ? 500 : "48vh",
            marginTop: windowWidth > 768 ? 100 : 0,
          }}
        >
          {previewModel && <ModelPreview model={previewModel} />}
  
          {!activeTroop && (
            <div className="d-flex bg-dark-500 rounded bg-blur justify-content-center align-items-center dark-yellow fs-lg w-100 p-2 gap-3">
              <CustomIcon
                style={{ transform: "rotate(90deg)" }}
                icon="arrow"
                width={30}
                height={19}
              />{" "}
              Please select a troop from this box and choose your preferred
              level.
            </div>
          )}
        </div>
        <div className="position-absolute bottom-0 right-0 p-2 d-flex justify-content-between rounded align-items-center pt-3 pb-4 w-100">
          {activeTroop && (
            <div className="row w-100  py-0 align-items-center justify-content-center">
              {isMyArmyOnly
                ? (activeTroop as FormationCampArmy)?.items?.map(
                    (item: FormationCampArmyItem, index) => (
                      <div
                        className={classNames("col-6 my-1 p-0 d-flex", {
                          "justify-content-end": index % 2 === 1,
                        })}
                        key={index}
                      >
                        <div className="selection-item-frame ">
                          <img width={"90%"} src={item?.icon} alt="item" />
                        </div>
                      </div>
                    )
                  )
                : new Array(6).fill(0).map((_, index) => (
                    <div
                      className={classNames("col-6 my-1 p-0 d-flex", {
                        "justify-content-end": index % 2 === 1,
                      })}
                      key={index}
                    >
                      <div className="selection-item-frame ">
                        <img
                          width={"90%"}
                          src={`/assets/images/arena/items/${index + 1}.png`}
                          alt="item"
                        />
                      </div>
                    </div>
                  ))}
            </div>
          )}
        </div>
      </div>
      <AnimatePresence>
        {activeTroop && (
          <>
            <div className="d-flex justify-content-between align-items-center w-100 p-2">
              {
                <div className="selection-price-tag  text-white">
                  {ThousandsSeparator(getTroopPrice(activeTroop as Troop))}{" "}
                  <img src="/assets/images/arena/price-tag.svg" alt="cleg" />
                </div>
              }
  
              <Button
                disabled={!activeTroop.level || activeTroop.level < 1}
                onClick={() => onSubmit(activeTroop as Troop)}
                variant="default"
                className=""
              >
                Add &nbsp;+
              </Button>
            </div>
            {!isMyArmyOnly && (
              <div
              >
                <div
                className={"bg-dark-500 bg-blur  p-md-2 d-flex justify-content-between rounded align-items-center pb-4 pt-2  mb-md-0 pt-md-3 pb-md-4"}
                style={windowWidth < 768 ?  {background:"rgba(0,0,0,.2)",borderBottomLeftRadius:'0 !important',borderBottomRightRadius:0 ,padding:'0px !important'} : { }}
                >
                  <Slider
                    className="pb-3 mx-3"
                    style={{ flex: 1, minWidth: 100 }}
                    allowCross
                    dots
                    marks={{
                      1: 1,
                      2: 2,
                      3: 3,
                      4: 4,
                      5: 5,
                      6: 6,
                      7: 7,
                      8: 8,
                      9: 9,
                      10: 10,
                      11: 11,
                      12: 12,
                    }}
                    value={activeTroop?.level}
                    onChange={(val) => changeLevel(val as number)}
                    min={1}
                    max={12}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </AnimatePresence>
    </div>
  }

  export default PreviewerContent