export const actionTypes = {
    SET_USER_TOKEN: 'SET_USER_TOKEN',
    REMOVE_USER_TOKEN: 'REMOVE_USER_TOKEN',
    SET_USER_INFO: 'SET_USER_INFO',
    REMOVE_USER_INFO: 'REMOVE_USER_INFO',

};

export const setUserToken = (payload:any) => ({
    type: actionTypes.SET_USER_TOKEN,
    payload,
});

export const removeUserToken = () => ({
    type: actionTypes.REMOVE_USER_TOKEN,
});

export const setUserInfo = (payload:any) => ({
    type: actionTypes.SET_USER_INFO,
    payload,
});

export const removeUserInfo = () => ({
    type: actionTypes.REMOVE_USER_INFO,
});