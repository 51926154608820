import React, { FC, HtmlHTMLAttributes } from 'react'
import { isMobile } from 'react-device-detect';
import { motion ,MotionProps} from 'framer-motion';
import classNames from 'classnames';
type Props = {
    viewType:number;
    setViewType:(viewType:number)=>void;
}

const ViewTypeSwitcher:FC<Props & HtmlHTMLAttributes<HTMLDivElement> & MotionProps> = ({viewType,setViewType,className,...rest}) => {
    
  return (
    <motion.div
    initial={isMobile ? undefined : { opacity: 0 }}
    animate={{
      x: 0,
      opacity: 1,
    }}
    transition={{
      duration: 0.4,
    }}
    exit={{ x: -window.innerWidth, opacity: 0 }}
    {...rest}
    className={classNames("d-flex align-items-center justify-content-end mt-2 mt-md-0 mb-0 mb-md-3",className)}
  >
    <div
      onClick={() => {
        setViewType(1);
        localStorage.setItem("card-sort", "1");
      }}
      className={`sort-btn ${viewType === 1 && "active"}`}
    >
      <img width={22} height={22} src="/assets/images/icons/grid.png" />
    </div>
    <div
      onClick={() => {
        setViewType(2);
        localStorage.setItem("card-sort", "2");
      }}
      className={`ms-2 sort-btn  ${viewType === 2 && "active"}`}
    >
      <img width={22} height={22} src="/assets/images/icons/grid2x.png" />
    </div>
  </motion.div>
  )
}

export default ViewTypeSwitcher