import { ConnectionStatus } from "../types/enum";

export const getColorBasedOnPing = (ping: number, ) => {
    if (ping < 250) return "#4B9576";
    if (ping < 350) return "yellow";
    return "red";
  };

 export  const getConnectionStatusColor = (connectionStatus: ConnectionStatus) => {
    if (connectionStatus === ConnectionStatus.Connected) return "#4B9576";
    if (connectionStatus === ConnectionStatus.Connecting) return "yellow";
    return "red";
  };