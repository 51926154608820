import React from "react";
import { Unit } from "../../types";

type Props = {
  selectedTroop: Unit;
};

const TroopInfo = ({ selectedTroop }: Props) => {
  const healthPercentage =
    (selectedTroop.health / selectedTroop.maxHealth) * 100;
  const healthColor =
    healthPercentage > 50
      ? "#4B9576"
      : healthPercentage > 20
      ? "#FFA500"
      : "#FF0000";
  return (
    <div className="troop-info">
      <div className="troop-circle p-relative text-white d-flex align-items-center justify-content-center">
        <img
          src={selectedTroop.troop.image}
          alt={selectedTroop.name}
          width={"70%"}
        />
        <div className="troop-name">
          <div className="troop-level">{selectedTroop.level}</div>
          <div className="troop-name-text">{selectedTroop.troop.name}</div>
        </div>
      </div>
      <div className="troop-info-content">
        <div className="flex-1 align-items-center px-2 text-white gap-2 d-flex flex-column border-r">
          <div
            style={{ border: "1px solid #EBD197 ", maxWidth: "100px" }}
            className="w-100 p-relative  d-flex align-items-center justify-content-center fs-small rounded overflow-hidden"
          >
            <div
              style={{
                position: "absolute",
                height: "100%",
                left: 0,
                background: healthColor,
                width: healthPercentage + "%",
              }}
            />
            <div className="p-relative">
              {selectedTroop.health} / {selectedTroop.maxHealth}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-start gap-2 ">
            <div className="action-frame">
              <img
                src={"/assets/images/arena/move-icon.svg"}
                alt="attack"
                width={40}
              />
            </div>
            <div className="action-frame">
              <img
                src={"/assets/images/arena/attack-icon.svg"}
                alt="attack"
                width={40}
              />
            </div>
            <div className="action-frame">
              <img
                src={"/assets/images/arena/heal-icon.svg"}
                alt="attack"
                width={40}
              />
            </div>
          </div>
        </div>
        <div className="flex-1  px-2 border-r ">
          <div
            style={{ color: "#CACACA" }}
            className="row align-items-center justify-content-center h-100"
          >
            <div className="col-6 d-flex align-items-center ">
              <img
                src={"/assets/images/arena/range.svg"}
                alt="range"
                width={20}
                height={20}
              />
              {selectedTroop.range}
            </div>
            <div className="col-6 d-flex align-items-center">
              <img
                src={"/assets/images/arena/magic-resist.svg"}
                alt="magicResistance"
                width={20}
                height={20}
              />
              {selectedTroop.magicResistance}
            </div>
            <div className="col-6 d-flex align-items-center">
              <img
                src={"/assets/images/arena/attack-stat.svg"}
                alt="damage"
                width={20}
                height={20}
              />
              {selectedTroop.damage}
            </div>
            <div className="col-6 d-flex align-items-center">
              <img
                src={"/assets/images/arena/armor.svg"}
                alt="armor"
                width={20}
                height={20}
              />
              {selectedTroop.armor}
            </div>
          </div>
        </div>
        <div className="flex-1 ">
          <div className="row gap-1 p-3 py-0 align-items-center justify-content-center">
            {new Array(6).fill(0).map((_, index) => (
              <div className="col-4 p-0 p-relative  troop-item-frame" key={index}>
                <img
                  className="w-100 h-100"
                  width={'100%'}
                  src={`/assets/images/arena/items/${index + 1}.png`}
                  alt="item"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TroopInfo;
