import React, { useEffect, useRef, useState } from 'react'
import MidBox from '../components/Boxes/MidBox'
import Button from '../components/Button'
import AuthLayout from '../layouts/AuthLayout'
import { isMobile } from 'react-device-detect';
import { motion } from "framer-motion"
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Network, { ChainID, IsBeta } from '../utils/Network';
import { ethers } from 'ethers';
import { useDispatch } from 'react-redux';
import { setUserToken } from '../redux/user/action';
import { OpenAlert } from '../redux/alert/action';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import useToast from '../hooks/useToast'
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin as ReactGoogleLogin } from '@react-oauth/google';
import { ContestEndTime } from '../constants';
type Props = {}



const GLogin = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const [captcha, setCaptcha] = useState('')
    const [waitingForSign, setWaitingForSign] = useState(false)
    const captchaRef = useRef<any>()
    let [searchParams] = useSearchParams();
    const lastMethod = localStorage.getItem('connector')
    const [method, setMethod] = useState<"metamask" | "walletconnect" | "coinbase" | null | string>(lastMethod || 'metamask')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const openToast = useToast()
    const axios = new Network()



    useEffect(() => {
        if (searchParams.get('jwtToken')) {
            const token = searchParams.get('jwtToken')
            dispatch(setUserToken(token))
            localStorage.setItem('user.token', token)
            localStorage.setItem('connector', 'metamask')
            localStorage.setItem('isLoginedBefore', "true")
            navigate('/')
        }
    }, [])


    const loginWithGoogleApi = async (token: string) => {
        const response = await axios.CallApi({
            url: `/player/google-login?jwt=${token}&refCode=${localStorage.getItem('refCode') || undefined}`,
            method: 'get'
        })
        openToast(response)
        if (response?.ok) {
            const user = await axios.CallApi({
                url: '/player',
                method: 'get',
                token: response?.data?.token
            })
            if (user.data?.State === 2) {
                return dispatch(OpenAlert({ msg: "You may have violated the game's terms, If you have not , please contact suspends@chainoflegends.com", open: true, variant: 'error' }));
            }
            if (user.data?.State === 3) {
                return dispatch(OpenAlert({ msg: 'Your accont has been suspended. to continue playing the game KYC is required Please send your wallet address to suspends@chainoflegends.com', open: true, variant: 'error' }));
            }
            dispatch(setUserToken(response?.data?.token))
            localStorage.setItem('user.token', response?.data?.token)
            localStorage.setItem('connector', 'email')
            localStorage.setItem('isLoginedBefore', "true")
            setLoading(false)
            navigate('/home')
        }
    }





    return (
        <AuthLayout>
            <motion.div
                style={{ width: 'fit-content' }}
                exit={{ y: 30, opacity: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
                initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                animate={{ marginInline: 'auto', opacity: 1, y: 0 }} >
                {IsBeta && <div>
                    <img style={{ maxWidth: 500 }} className="rounded mb-2" src="/assets/images/banners/beta-login-banner.jpg" />
                </div>}

                <MidBox style={{ maxWidth: 500 }} className="mx-auto text-center" title="Chain Of Legends">
                    {/* <motion.div className=' d-flex justify-content-center' transition={{ duration: 0.3, delay: 0.4 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <div onClick={() => setMethod('metamask')} className={'method-tab ' + (method === "metamask" && "active")}>
                            <img src="/assets/images/icons/metamask-icon.webp" width={'100%'} alt="MetaMask" />
                        </div>
                        <div onClick={() => setMethod('walletconnect')} className={'method-tab ms-3 ' + (method === "walletconnect" && "active")}>
                            <img src="/assets/images/icons/walletconnect.png" width={'100%'} alt="walletconnect" />
                        </div>
                        <div onClick={() => setMethod('coinbase')} className={'method-tab ms-3 ' + (method === "coinbase" && "active")}>
                            <img src="/assets/images/icons/coinbase.png" width={'100%'} alt="coinbase" />
                        </div>
                    </motion.div>
                    <motion.div transition={{ duration: 0.3, delay: 0.3 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <p className='mt-4 bold fs-big'>
                            Connect with {method === "metamask" ? <span style={{ color: "#f6851b" }}>MetaMask</span> :
                                method === "coinbase" ?
                                    <span className="text-primary">Coinbase Wallet</span>
                                    :
                                    <span className="text-info">WalletConnect</span>}
                        </p>
                    </motion.div>
                    <motion.div transition={{ duration: 0.3, delay: 0.5 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <p className='mt-2'>
                            Connect with your available wallet or create new wallet to join our Game
                        </p>
                    </motion.div> */}
                    <br />
                    <motion.div transition={{ duration: 0.3, delay: 0.5 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <p style={{ marginTop: -10 }}>
                            Sign-In with your Gmail or try other methods.
                        </p>
                    </motion.div>
                    <motion.div transition={{ duration: 0.3, delay: 0.6 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <div style={{ width: 'fit-content' }} className='text-center mx-auto mt-4'>
                            <ReactGoogleLogin
                                shape='circle'
                                size='large'
                                theme='outline'
                                onSuccess={credentialResponse => {
                                    loginWithGoogleApi(credentialResponse.credential!)
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </div>
                    </motion.div>
                  <br />

                    <motion.div transition={{ duration: 0.3, delay: 0.7 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <div className='text-center'>
                            <Link to="/login">
                                <Button loading={loading} className="mx-auto" variant='rounded'>
                                    {loading && waitingForSign ? "Waiting for Sign" : "Other Methods..."}
                                </Button>
                            </Link>

                        </div>
                    </motion.div>
                    {/* {IsBeta && <motion.div transition={{ duration: 0.3, delay: 0.7 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <div className='text-center'>
                            <Button loading={loading} className="mx-auto" onClick={loginWithGoogle} variant='rounded'>
                                {loading && waitingForSign ? "Waiting for Sign" : "Login with Google"}
                            </Button>
                        </div>
                    </motion.div>} */}


                    {!localStorage.getItem('isLoginedBefore') && <motion.p className='fs-small mt-3'>
                        <span className='text-warning bold'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16"><path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path></svg>
                            &nbsp;Warning:
                        </span> Either "Creating Multiple Accounts" or "Referring oneself" to earn extra income is considered an action against our "<a href='https://chainoflegends.com/terms-of-use.html' target={"_blank"} className='text-link'>Terms of Use</a>".
                        Doing so will put your account at risk of getting suspended.
                    </motion.p>}
                    <motion.div transition={{ duration: 0.3, delay: 0.8 }} initial={isMobile ? undefined : { y: 50, opacity: 0 }}
                        animate={{ textAlign: 'center', opacity: 1, y: 0 }}>
                        <div style={{ maxWidth: 300 }} className={'mt-2 dark-gray fs-tiny mx-auto ' + (localStorage.getItem('isLoginedBefore') && 'mt-4')}>
                            We do not own your private keys and cannot access your funds without your confirmation.
                        </div>
                    </motion.div>
                </MidBox>
                {/* <div className='text-center w-100'>
                    <a href='https://old.chainoflegends.com' className=' mt-4 dark-yellow'>
                        Old Version
                    </a>
                </div> */}
            </motion.div>

        </AuthLayout>
    )
}

export default GLogin