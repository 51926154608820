import { duration } from "moment";
import React, { useEffect, useRef } from "react";
import ReactConfetti from "react-confetti";
import { Props as ConfettiProps } from "react-confetti";
import ReactDOM from "react-dom/client";
import { useDispatch, useSelector } from "react-redux";
import { setEffects } from "../redux/effects/action";


export default function useConfetti() {
  const dispatch = useDispatch()
  const effects = useSelector((state:any)=>state.effects)


  function showConfetti(props: ConfettiProps & { duration: number }) {
    dispatch(setEffects({...effects,confetti:true}))
    setTimeout(() => {
      dispatch(setEffects({...effects,confetti:false}))
    }, (props.duration * 1000));
  }

  return {
    showConfetti,
  };
}
