import React, { useEffect, useState } from "react";
import useEvents from "../hooks/useEvents";
import SwiperClass, {
  Navigation,
  Pagination,
  EffectFlip,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Countdown from "react-countdown";
import axios from "axios";
import Dialog from "./Dialog";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import GrowthChallenge from "./Campaigns/GrowthChallenge";
type Props = {};

const Board = (props: Props) => {
  const { events } = useEvents();
  const [progress, setProgress] = useState(15);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [challengeDialog, setChallengeDialog] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const { hash } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (hash && dialogData) {
      setIsDialogOpen(false);
    }
  }, [hash]);

  const BoardTimer = ({
    date,
    timerSrc,
    isStarted,
  }: {
    date: Date;
    isStarted: boolean;
    timerSrc: string;
  }) => {
    return (
      <div
        style={
          timerSrc
            ? {
                backgroundImage: `url(${timerSrc})`,
              }
            : undefined
        }
        className={"board-timer pt-1 "}
      >
        <Countdown date={date} className={isStarted ? " " : "text-success "} />
      </div>
    );
  };
  console.log(events)
  return (
    <motion.div
      initial={
        !isMobile
          ? {
              y: -200,
            }
          : undefined
      }
      animate={{ y: 0 }}
      exit={
        !isMobile
          ? {
              y: -200,
            }
          : undefined
      }
      transition={{ delay: 0.2 }}
      className="board w-100 mt-2 mt-md-0"
      style={{ maxWidth: 400 }}
    >
      <Swiper
        effect="flip"
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        flipEffect={{
          slideShadows: false,
        }}
        modules={[Navigation, EffectFlip, Autoplay]}
        navigation
      >
        {events?.map((data) => (
          <SwiperSlide>
            <div
              className="pointer"
              onClick={() => {
                if (data.id === "challenge" && data.isStarted)
                  return setChallengeDialog(true);
                if (data.description) {
                  setIsDialogOpen(true);
                  setDialogData(data.description);
                }
                if (data.link) {
                  if (data?.link?.includes("http")) {
                    window.open(data.link, "_blank");
                    return;
                  }
                  navigate(data.link);
                }
              }}
            >
              {!data.isEnded && (
                <BoardTimer
                  timerSrc={data.timerSrc!}
                  isStarted={data.isStarted}
                  date={new Date(data.date)}
                />
              )}
              <img height={120} className="mx-auto w-100" src={data.image} />
              {data.id === "tokenlock" && data.isStarted && (
                <>
                  <div className="board-progress">
                    <div
                      className="p-absolute "
                      style={{
                        left: 5,
                        width: progress - 3 + "%",
                        position: "absolute",
                        background: "rgba(229, 167, 35,0.7)",
                        borderRadius: "20px",
                        height: 17,
                        top: 2,
                      }}
                    />
                    <img
                      className="board-progress-icon"
                      src="/assets/images/icons/beer.webp"
                    />
                  </div>
                  <div
                    className="fs-tiny dark-yellow"
                    style={{ position: "absolute", right: 10, bottom: 22 }}
                  >
                    <span
                      style={{ lineHeight: "80%" }}
                      className="fs-big bold color-orange "
                    >
                      {100 - progress}
                      <small>%</small>
                    </span>{" "}
                    remaining <div>of 5M CLEG</div>
                  </div>
                </>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Dialog
        size="lg"
        title="Event Information"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <div
          style={{ marginBottom: "-30px" }}
          dangerouslySetInnerHTML={{ __html: dialogData }}
        />
      </Dialog>
      <GrowthChallenge
        open={challengeDialog}
        onClose={() => setChallengeDialog(false)}
      />
    </motion.div>
  );
};

export default Board;
