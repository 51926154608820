import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MidBox from "../components/Boxes/MidBox";
import { roundFloat } from "../utils/round-float";
import Network from "../utils/Network";
import { motion } from "framer-motion";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TipList from "../components/TipList";
import Button from "../components/Button";
import { isMobile } from "react-device-detect";
import Dialog from "../components/Dialog";
import useToast from "../hooks/useToast";
import { Pagination } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { setUserInfo } from "../redux/user/action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
import extractLink from "../utils/extract-link";

type Props = {};

const Notifications = (props: Props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginations, setPaginations] = useState({
    page: 1,
    count: 10,
    total: 1,
  });
  const [markLoading, setMarkLoading] = useState(false);
  const dispatch = useDispatch();
  const [detailDialog, setDetailDialog] = useState({
    open: false,
    data: {},
  });
  const openToast = useToast();
  const axios = new Network();
  const navigate = useNavigate();

  const getNotifs = async (page = 1) => {
    const response = await axios.CallApi({
      url: "/notification/list?count=" + 15 + `&page=${page}`,
      method: "get",
    });
    if (response.ok) {
      setData(response?.data?.data || []);
      let totals = [];
      for (let i = 1; i <= Math.ceil(response.data.totalCount / 15); i++) {
        totals.push(i);
      }
      setPaginations({
        total: Math.ceil(response.data.totalCount / 15),
        page,
        count: 15,
      });
    }
    setLoading(false);
  };
  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };

  async function MarkAllAsRead() {
    setMarkLoading(true);
    const response = await axios.CallApi({
      url: "/notification/readall",
      method: "get",
    });
    // openToast(response)
    getNotifs();
    getMe();
    setMarkLoading(false);
  }
  async function MarkAsRead(id: number) {
    const response = await axios.CallApi({
      url: "/notification/read",
      method: "post",
      body: [id],
    });
    getNotifs();
  }
  useEffect(() => {
    setLoading(true);
    getNotifs();
  }, []);
  return (
    <SkeletonTheme baseColor="#2d271b" highlightColor="#3e382b">
      <Helmet>
        <title key={"title"}>Notifications | Chain Of Legends</title>
      </Helmet>
      <motion.div
        exit={{ opacity: 0, y: 200 }}
        transition={{ duration: 0.4 }}
        className="row"
        initial={isMobile ? undefined : { opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <MidBox modal title="Notifications">
          {!loading && (
            <Button
              disabled={data?.length === 0}
              onClick={MarkAllAsRead}
              loading={markLoading}
              className="ms-auto me-3"
              variant="wide-primary"
            >
              Mark All As Read
            </Button>
          )}
          <div
            style={{ overflow: "auto" }}
            className="pe-1 pt-3 pb-5 ps-1 pe-sm-3 ps-sm-3 pb-4 table-box"
          >
            <table className="table table-borderless m-0 text-white">
              {" "}
              <thead>
                <tr>
                  <th scope="col">Message</th>
                  <th scope="col"></th>
                  <th scope="col">Date</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {loading &&
                  [1, 2, 3].map((item) => (
                    <tr key={"loading" + item} className="pointer">
                      <td width={"50%"}>
                        <Skeleton width={"100%"} />
                      </td>
                      <td>
                        {" "}
                        <Skeleton width={"60px"} />{" "}
                      </td>
                      <td>
                        {" "}
                        <Skeleton width={"60px"} />
                      </td>
                      <td>
                        <Skeleton width={"100px"} height={25} />
                      </td>
                    </tr>
                  ))}
                {data.length === 0 && !loading && (
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        textAlign: "center",
                        paddingTop: 40,
                        fontSize: 20,
                        paddingBottom: 40,
                      }}
                      className="dark-yellow"
                    >
                      You have no notification here ...
                    </td>
                  </tr>
                )}
                {!loading &&
                  data?.map((item, key) => (
                    <>
                      <tr
                        className="pointer"
                        onClick={() => {
                          setDetailDialog({
                            open: true,
                            data: item,
                          });
                          MarkAsRead(item.id);
                        }}
                        style={{ opacity: !item?.isRead ? 1 : 0.7 }}
                        key={"row" + key}
                      >
                        <td style={{ minWidth: 200 }}>
                          <span className="fs-big">
                            {!item?.isRead && <div className="badge-dot" />}{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item?.message, {
                                  ALLOWED_TAGS: ["a"],
                                }),
                              }}
                            />{" "}
                          </span>
                        </td>
                        <td>
                          {item.iconUrl && (
                            <img src={item?.iconUrl} width={40} />
                          )}
                        </td>
                        <td>
                          {moment(new Date(item?.registerDate)).format(
                            "HH:mm - MM/DD/YYYY"
                          )}
                        </td>
                        <td>
                          <Button variant="default">Open</Button>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
            <br />
            <br />
            <ReactPaginate
              className="pagination justify-content-center"
              activeClassName="active-pg"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              breakClassName="page-item"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              previousClassName="page-item"
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              onPageChange={(e) => getNotifs(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={paginations.total}
              // renderOnZeroPageCount={null}
            />
          </div>
        </MidBox>
      </motion.div>
      <Dialog
        title="Detail"
        open={detailDialog.open}
        onClose={() => setDetailDialog((prev) => ({ ...prev, open: false }))}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(detailDialog?.data?.message, {
              ALLOWED_TAGS: ["a"],
            }),
          }}
        />{" "}
      </Dialog>
    </SkeletonTheme>
  );
};

export default Notifications;
