import React, { useEffect, useState } from "react";
import Network from "../utils/Network";
import Dialog from "./Dialog";
import { CustomIcon } from "./Icons";
import Button from "./Button";
import { Badge, ProgressBar } from "react-bootstrap";
import { Quest, QuestState } from "../types/quests/indext";
import { Link } from "react-router-dom";
import useToast from "../hooks/useToast";
import { setUserInfo } from "../redux/user/action";
import { useDispatch, useSelector } from "react-redux";
import { SetQuests } from "../redux/quests/action";
import useQuests from "../hooks/useQuests";
import CircularProgress from "./CircularProgress";
import { isMobile } from "react-device-detect";
import { roundFloat } from "../utils/round-float";
import Countdown from "react-countdown";
type Props = {
  open: boolean;
  onClose: () => void;
};

const QuestDialog = (props: Props) => {
  const { actionLoading, handleClaim, loading, quests, getQuests } =
    useQuests();

  useEffect(() => {
    getQuests();
  }, [props.open]);
  function addDays(date: string, duration: number) {
    let result = new Date(date);
    result.setDate(result.getDate() + duration);
    return result;
  }
  const getBetweenTime = (date: string, duration: number) => {
    return addDays(date, duration);
  };

  return (
    <Dialog size="lg" title="Quests" open={props.open} onClose={props.onClose}>
      <div>
        {loading && (
          <div
            style={{ minHeight: 200 }}
            className="d-flex pt-5 align-items-center justify-content-center"
          >
            <CircularProgress style={{ margin: 0 }} />
          </div>
        )}
        {!loading && quests.length === 0 && (
          <div
            style={{ minHeight: 200 }}
            className="d-flex pt-5 align-items-center justify-content-center"
          >
            <div className="text-secondary">
              Sorry there is not quest for now.
            </div>
          </div>
        )}
        {loading &&
          Array(10)
            .fill("")
            .map(() => <div></div>)}
        {!loading &&
          (isMobile
            ? quests?.map(
                ({
                  id,
                  quest,
                  state,
                  progress,
                  startDate,
                }: {
                  id: number;
                  quest: Quest;
                  state: QuestState;
                  progress?: number;
                  startDate?: string;
                }) => (
                  <div
                    style={{
                      opacity: state === QuestState.Expired ? 0.6 : 1,
                      borderBottom: "1px solid rgba(255,255,255,0.1)",
                    }}
                    key={id}
                    className="row gap-2 py-2 flex-wrap  align-items-center"
                  >
                    <div className="col-12 d-flex align-items-center">
                      <img
                        className="circle me-2"
                        style={{
                          maxWidth: 70,
                          maxHeight: 75,
                        }}
                        src={quest.icon}
                      />
                      <div className="flex-1">
                        <div className="text-title">{quest.title}</div>
                        <div className=" fs-small fs-med">
                          {quest.description}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-2 flex-column">
                      {quest.requiredActionValue !== 1 && (
                        <ProgressBar
                          className="progress-dark-orange"
                          variant="warning"
                          style={{
                            minWidth: 90,
                            height: 10,
                            color: "#000",
                            background: "rgba(0,0,0,.2)",
                          }}
                          label={
                            roundFloat(
                              ((progress || 0) * 100) /
                                quest.requiredActionValue || 0,
                              0
                            ) + " %"
                          }
                          now={
                            ((progress || 0) * 100) /
                              quest.requiredActionValue || 0
                          }
                        />
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      {JSON.parse(quest.rewards)?.map(
                        (reward) =>
                          reward && (
                            <div className="d-flex p-relative flex-column align-items-center">
                              <img
                                className="circle"
                                width={reward.level ? 40 : 31}
                                height={reward.level ? 40 : 31}
                                src={reward.icon}
                                alt={reward.name}
                              />
                              <div className="d-flex">
                                {reward.level && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                    }}
                                    className="circle level-badge bg-dark "
                                  >
                                    {reward.level}
                                  </div>
                                )}
                                <div className="fs-tiny">
                                  x{reward.quantity}
                                </div>
                              </div>
                            </div>
                          )
                      )}
                      <div className="ms-auto">
                        {state === QuestState.Open && (
                          <>
                            {quest.link?.includes("http") ? (
                              <a href={quest.link}>
                                <Button
                                  onClick={props.onClose}
                                  className=" bold bg-100"
                                  variant="primary"
                                >
                                  Go
                                </Button>
                              </a>
                            ) : quest.link && (
                              <>
                                <Link to={quest.link}>
                                  <Button
                                    onClick={props.onClose}
                                    className=" bold bg-100"
                                    variant="primary"
                                  >
                                    Go
                                  </Button>
                                </Link>
                              </>
                            )}
                            {quest.durtaion && startDate && (
                              <div className="text-center">
                                <Countdown
                                  date={getBetweenTime(
                                    startDate,
                                    quest.durtaion
                                  )}
                                />
                              </div>
                            )}
                          </>
                        )}
                        {state === QuestState.Done && (
                          <>
                            <Button
                              loading={actionLoading === id}
                              disabled={Boolean(actionLoading)}
                              onClick={() => handleClaim(id)}
                              className="bold "
                              variant="success"
                            >
                              Claim
                            </Button>
                          </>
                        )}

                        {state === QuestState.Claimed && (
                          <>
                            <div className="px-3 bg-2 py-2 rounded nohover text-secondary">
                              <CustomIcon icon="tick" width={20} height={23} />
                              &nbsp; Claimed
                            </div>
                          </>
                        )}

                        {state === QuestState.Expired && (
                          <Button disabled variant="secondary">
                            Expired
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )
            : quests?.map(
                ({
                  id,
                  quest,
                  state,
                  progress,
                  startDate,
                }: {
                  id: number;
                  quest: Quest;
                  state: QuestState;
                  progress?: number;
                  startDate?: string;
                }) => (
                  <div
                    style={{
                      opacity: state === QuestState.Expired ? 0.6 : 1,
                      borderBottom: "1px solid rgba(255,255,255,0.1)",
                    }}
                    key={id}
                    className="d-flex gap-2 py-2 flex-wrap  align-items-center"
                  >
                    <img src={quest.icon} width={80} height={85} />
                    <div className="flex-1">
                      <div className="text-title">{quest.title}</div>
                      <div className="  fs-med">{quest.description}</div>
                    </div>
                    <div className="d-flex gap-2">
                      {JSON.parse(quest.rewards)?.map(
                        (reward) =>
                          reward && (
                            <div className="d-flex p-relative flex-column align-items-center">
                              <img
                                className="circle"
                                width={reward.level ? 40 : 31}
                                height={reward.level ? 40 : 31}
                                src={reward.icon}
                                alt={reward.name}
                              />
                              <div className="d-flex">
                                {reward.level && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                    }}
                                    className="circle level-badge bg-dark "
                                  >
                                    {reward.level}
                                  </div>
                                )}
                                <div className="fs-tiny">
                                  x{reward.quantity}
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                    <div className="d-flex gap-2 flex-column">
                      {state === QuestState.Open && (
                        <>
                          {quest?.link?.includes?.("http") ? (
                            <a href={quest.link}>
                              <Button
                                onClick={props.onClose}
                                className=" bold bg-100"
                                variant="primary"
                              >
                                Go
                              </Button>
                            </a>
                          ) :quest.link &&  (
                            <>
                              <Link to={quest.link}>
                                <Button
                                  onClick={props.onClose}
                                  className=" bold bg-100"
                                  variant="primary"
                                >
                                  Go
                                </Button>
                              </Link>
                            </>
                          )}
                          {quest.requiredActionValue !== 1 && (
                            <ProgressBar
                              className="progress-dark-orange"
                              variant="warning"
                              style={{
                                minWidth: 90,
                                height: 15,
                                color: "#000",
                                background: "rgba(0,0,0,.2)",
                              }}
                              now={
                                ((progress || 0) * 100) /
                                  quest.requiredActionValue || 0
                              }
                            />
                          )}
                          {quest.durtaion && startDate && (
                            <div className="text-center">
                              <Countdown
                                date={getBetweenTime(startDate, quest.durtaion)}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {state === QuestState.Done && (
                        <>
                          <Button
                            loading={actionLoading === id}
                            disabled={Boolean(actionLoading)}
                            onClick={() => handleClaim(id)}
                            className="bold "
                            variant="success"
                          >
                            Claim
                          </Button>
                          {quest.requiredActionValue !== 1 && (
                            <ProgressBar
                              className="progress-dark-orange"
                              variant="warning"
                              style={{
                                minWidth: 90,
                                height: 15,
                                color: "#000",
                                background: "rgba(0,0,0,.2)",
                              }}
                              now={
                                ((progress || 0) * 100) /
                                  quest.requiredActionValue || 0
                              }
                              label={`Done`}
                            />
                          )}
                        </>
                      )}

                      {state === QuestState.Claimed && (
                        <>
                          <div className="px-3 bg-2 py-2 rounded nohover text-secondary">
                            <CustomIcon icon="tick" width={20} height={23} />
                            &nbsp; Claimed
                          </div>
                        </>
                      )}

                      {state === QuestState.Expired && (
                        <Button disabled variant="secondary">
                          Expired
                        </Button>
                      )}
                    </div>
                  </div>
                )
              ))}
      </div>
    </Dialog>
  );
};

export default QuestDialog;
