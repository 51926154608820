import { useContext } from 'react';
import { SlotContext } from '../components/slot/context';

const useGameState = () => {
  const context = useContext(SlotContext);
  if (!context) {
    throw new Error('useGameState must be used within a SlotProvider');
  }
  return context;
};

export default useGameState;
