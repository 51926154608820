enum PlayerTroopTargetPriority {
    Closest = 1,
    LowestHealth = 2,
    HighestDamage = 3,
}

const defaultTargetPriority = 1
interface arr {
    index: number,
    x: number,
    displayX: number,
    displayY: number,
    y: number,
    troopId?:number,
    level?:number,
    isLocked?:boolean,
    "playerTroopId": number | undefined,
    "targetPriority": PlayerTroopTargetPriority
}


export const defaultBattlePositions: arr[] = [
    {
        index: 0,
        x: 0,
        displayX: 0,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    },
    {
        index: 1,
        x: 1,
        displayX: 1,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    },
    {
        index: 2,
        x: 2,
        displayX: 2,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 3,
        x: 3,
        displayX: 3,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 4,
        x: 4,
        displayX: 4,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 5,
        x: 0,
        displayX: 0.5,
        displayY:1,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 6,
        x: 1,
        displayX: 1.5,
        displayY:1,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 7,
        x: 2,
        displayX: 2.5,
        displayY:1,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 8,
        x: 3,
        displayX: 3.5,
        displayY:1,

        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 9,
        x: 4,
        displayX: 4.5,
        displayY:1,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 10,
        x: 0,
        displayX: 0,
        displayY:2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 11,
        displayX: 1,
        x: 1,
        displayY:2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 12,
        displayX: 2,
        displayY:2,
        x: 2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 13,
        displayX: 3,
        displayY:2,
        x: 3,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 14,
        displayX: 4,
        x: 4,
        displayY:2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 15,
        displayX: 0.5,
        displayY:3,
        x: 0,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 16,
        displayX: 1.5,
        x: 1,
        displayY:3,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 17,
        displayX: 2.5,
        displayY:3,
        x: 2,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 18,
        displayX: 3.5,
        displayY:3,
        x: 3,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 19,
        displayX: 4.5,
        x: 4,
        displayY:3,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 20,
        displayX: 0,
        displayY:4,
        x: 0,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 21,
        displayX: 1,
        displayY:4,
        x: 1,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 22,
        displayY:4,
        displayX: 2,
        x: 2,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 23,
        displayX: 3,
        x: 3,
        displayY:4,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 24,
        displayX: 4,
        x: 4,
        displayY:4,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 25,
        displayX: 0.5,
        x: 0,
        displayY:5,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 26,
        displayX: 1.5,
        displayY:5,
        x: 1,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 27,
        displayX: 2.5,
        x: 2,
        displayY:5,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 28,
        displayX: 3.5,
        x: 3,
        displayY:5,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 29,
        displayX: 4.5,
        x: 4,
        displayY:5,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
]


export const defaultDefendPositions: arr[] = [
    {
        index: 0,
        x: 0,
        displayX: 0,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    },
    {
        index: 1,
        x: 1,
        displayX: 1,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    },
    {
        index: 2,
        x: 2,
        displayX: 2,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 3,
        x: 3,
        displayX: 3,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 4,
        x: 4,
        displayX: 4,
        displayY:0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 5,
        x: 0,
        displayX: 0.5,
        displayY:1,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 6,
        x: 1,
        displayX: 1.5,
        displayY:1,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 7,
        x: 2,
        displayX: 2.5,
        displayY:1,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 8,
        x: 3,
        displayX: 3.5,
        displayY:1,

        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 9,
        x: 4,
        displayX: 4.5,
        displayY:1,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 10,
        x: 0,
        displayX: 0,
        displayY:2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 11,
        displayX: 1,
        x: 1,
        displayY:2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 12,
        displayX: 2,
        displayY:2,
        x: 2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 13,
        displayX: 3,
        displayY:2,
        x: 3,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 14,
        displayX: 4,
        x: 4,
        displayY:2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 15,
        displayX: 0.5,
        displayY:3,
        x: 0,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 16,
        displayX: 1.5,
        x: 1,
        displayY:3,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 17,
        displayX: 2.5,
        displayY:3,
        x: 2,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 18,
        displayX: 3.5,
        displayY:3,
        x: 3,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 19,
        displayX: 4.5,
        x: 4,
        displayY:3,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 20,
        displayX: 0,
        displayY:4,
        x: 0,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 21,
        displayX: 1,
        displayY:4,
        x: 1,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 22,
        displayY:4,
        displayX: 2,
        x: 2,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 23,
        displayX: 3,
        x: 3,
        displayY:4,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 24,
        displayX: 4,
        x: 4,
        displayY:4,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 25,
        displayX: 0.5,
        x: 0,
        displayY:5,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 26,
        displayX: 1.5,
        displayY:5,
        x: 1,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 27,
        displayX: 2.5,
        x: 2,
        displayY:5,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 28,
        displayX: 3.5,
        x: 3,
        displayY:5,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 29,
        displayX: 4.5,
        x: 4,
        displayY:5,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
]