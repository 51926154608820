// src/game-engine/layouts/BattleLayout.tsx
import React from "react";
import "./battle-layout.css";
import { ConnectorProvider } from "../connector/context";
import GameNavigator from "../screens/game-navigator";
import { BattleProvider } from "../context";
import { EnvConfig } from "../../constants/EnvConfig";

export const hubUrl =  EnvConfig.HUB_URL+"/battlehub";

const BattleLayout: React.FC = () => {
  return (

        <GameNavigator />
  );
};

export default BattleLayout;
