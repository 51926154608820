import React, { useEffect, useMemo, useState } from "react";
import {
  ClegIcon,
  CustomIcon,
  IronIcon,
  StoneIcon,
} from "../../components/Icons";
import Network from "../../utils/Network";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
type Props = {};

const Buildings = (props: Props) => {
  const [data, setData] = useState([]);
  const [active, setActive] = useState(3);
  const [level, setLevel] = useState(1);
  const axios = new Network();
  const currentCycle = useSelector((state: any) => state.currentCycle);

  const handleGetData = async () => {
    const response = await axios.CallApi({
      url: "/building/all",
      method: "get",
    });
    setData(response.data);
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const activeTabData: any = React.useMemo(() => {
    if (data) {
      return data?.find((item: any) => item.id === active);
    }
  }, [data, active]);

  const PhotoUrl =
    "https://core.chainoflegends.com/Admin/files/Photos/Buildings";
  const buildingInfo = activeTabData?.buildingInfos[active === 3 ? level : level - 1];
  const buildingDescriptions = {
    3: (
      <>
        This building extract CLEG tokens from <Link to="/lands">Lands</Link>.
        In order to Make this building works you need to obtain lands from
        Marketplace. Land Contains <ClegIcon width={20} height={20} /> CLEG,{" "}
        <StoneIcon width={20} height={20} /> Stone, and{" "}
        <IronIcon width={20} height={20} /> Iron.
      </>
    ),
    4: (
      <>
        This building extract Stone tokens from <Link to="/lands">Lands</Link>.
      </>
    ),
    5: (
      <>
        This building extract Stone tokens from <Link to="/lands">Lands</Link>.
      </>
    ),
    6: <>Barracks are used to train troops and heal them after a battle.</>,
  };

  return (
    <div>
      <div className="py-4 mt-4 rounded bg-2">
        <div className="d-flex gap-2 flex-wrap justify-content-center">
          {tabs?.map((item) => (
            <div onClick={() => setActive(item.id)}>
              <div className="main-tab-btn learn p-0 pointer no-hover">
                <img
                  className="img-fluid"
                  src={`/assets/images/buttons/${active === item.id
                    ? "active-tab-btn.png"
                    : "off-tab-btn.png"
                    }`}
                />
                <div className="b-text d-flex align-items-center justify-content-center px-3 text-light">
                  <span>
                    {item.icon}&nbsp;
                    {item.title}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="my-4 text-white p-2 py-4 bg-2 rounded">
        <div className="row ">
          <div className="col-md-6 text-center col-12">
            <img
              style={{ maxWidth: 500 }}
              width={"100%"}
              src={PhotoUrl + `/${active}/${activeTabData?.photoFile}`}
            />
          </div>
          <div className="col-md-6 col-12">
            <div className="fs-big bold ">{activeTabData?.name}</div>
            <div className="text-secondary mt-2">
              {buildingDescriptions[active]}
            </div>
            <div className="learn-line mt-3 d-flex flex-column gap-4">
              <div className="line-item">
                Capacity :{" "}
                <CountUp
                  separator=","
                  duration={0.44}
                  end={
                    buildingInfo?.capacity *
                    (active === 6
                      ? 1
                      : currentCycle?.ReductionMultiplier)
                  }
                />
              </div>
              <div className="line-item">
                Health : <CountUp separator="," duration={0.44} end={100} />
              </div>
              <div className="line-item">
                {active === 6 ? <>
                  Daily Healing
                </> : <>
                  Daily Production
                </>}
                :{" "}
                <CountUp
                  separator=","
                  duration={0.44}
                  end={
                    buildingInfo?.dailyProduction *
                    (active === 6
                      ? 1
                      : currentCycle?.ReductionMultiplier)
                  }
                />
              </div>
              <div className="line-item">
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  Cost :
                  <CountUp
                    separator=","
                    duration={0.44}
                    end={buildingInfo?.clegCost}
                  />{" "}
                  <ClegIcon width={20} height={20} />
                </motion.span>
                {Boolean(buildingInfo?.stoneCost) && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                  >
                    |{" "}
                    <CountUp
                      separator=","
                      duration={0.44}
                      end={buildingInfo?.stoneCost}
                    />{" "}
                    <StoneIcon width={20} height={20} />
                  </motion.span>
                )}
                {Boolean(buildingInfo?.ironCost) && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                  >
                    |{" "}
                    <CountUp
                      separator=","
                      duration={0.44}
                      end={buildingInfo?.ironCost}
                    />{" "}
                    <IronIcon width={20} height={20} />
                  </motion.span>
                )}
              </div>
              {active !== 6 && <div className="line-item">
                Repair Cost :
                <CountUp separator="," duration={0.44}
                  end={buildingInfo?.value * 0.1 * currentCycle?.ReductionMultiplier} /> <ClegIcon width={20} height={20} />
              </div>}
              <div className="line-item">
                Upgrade Time :{" "}
                <CountUp duration={0.77} end={buildingInfo?.upgradeTime} />{" "}
                Hours
              </div>
            </div>
            <div className="mt-4 pe-4">
              <Slider
                step={1}
                value={level}
                onChange={(val) => setLevel(val as number)}
                max={8}
                min={1}
                marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buildings;
const tabs = [
  {
    title: "Cleg Miner",
    id: 3,
    icon: <ClegIcon />,
  },
  {
    title: "Stone Miner",
    id: 4,
    icon: <StoneIcon />,
  },
  {
    title: "Iron Miner",
    id: 5,
    icon: <IronIcon />,
  },
  {
    title: "Barracks",
    id: 6,
    icon: <CustomIcon icon="blades" />,
  },
];
