import React, { useEffect, useRef, useState } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useAnimations } from "@react-three/drei";
import * as THREE from "three";
import HorizontalOrbitControls from "../orbit-controls";
import SpinnerLoader from "../model-loaders/spinner-loader";
import { AnimatePresence, motion } from "framer-motion";
import ModelCache from "../model-loaders/model-cache";

interface Props {
  model: {
    src: string;
    defaultScale?: number;
  };
  onLoad?: (model: Props["model"]) => void;
  onStart?: () => void;
}

const ModelPreview: React.FC<Props> = ({ model, onLoad, onStart }) => {
  const [isLoading, setIsLoading] = useState(true);
  const modelCache = ModelCache.getInstance();
  const modelRef = useRef<THREE.Group>(null);

  const { scene, animations } = useLoader(GLTFLoader, model.src, (loader) => {
    loader.manager.onStart = () => {
      if (onStart) onStart();
      setIsLoading(true);
    };

    loader.manager.onLoad = () => {
      modelCache.markModelAsLoaded(model.src);
      setIsLoading(false);
      if (onLoad) {
        onLoad(model);
      }
    };
  });

  const { actions } = useAnimations(animations, modelRef);

  useEffect(() => {
    if (actions && animations.length > 0) {
      const actionName = animations[1]?.name || animations[0]?.name;
      if (actions[actionName]) {
        actions[actionName].play();
      }
    }
  }, [animations, actions]);

const defaultScale = (model.defaultScale || 1)+.5;

  return (
    <mesh  position={[0, -1.4, 0]}>
      <primitive
        ref={modelRef}
        object={scene}
        position={[0, 0, 0]}
        scale={[defaultScale , defaultScale, defaultScale]}
      />
    </mesh>
  );
};

const PreviewContainer: React.FC<Props> = ({ model }) => {
  const [modelLoaded, setModelLoaded] = useState(false);
  const modelCache = ModelCache.getInstance();

  useEffect(() => {
    // Check if the model is already loaded in the cache
    if (modelCache.isModelLoaded(model.src)) {
      setModelLoaded(true);
    } else {
      setModelLoaded(false);
    }
  }, [model, modelCache]);

  return (
    <>
      <Canvas  className="no-height" camera={{ zoom: 1.4 }}>
        <HorizontalOrbitControls autoRotate />
        <ambientLight  intensity={0.4} />
        <directionalLight  position={[0, 10, 10]} />
        <ModelPreview
          onLoad={(model) => {
            setModelLoaded(true);
          }}
          model={model}
        />
      </Canvas>
      <AnimatePresence exitBeforeEnter>
        {!modelLoaded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
            exit={{ opacity: 0 }}
          >
            <SpinnerLoader />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default PreviewContainer;
