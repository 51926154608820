import React, { useEffect, useState } from "react";
import ReactConfetti, { Props as ConfettiProps } from "react-confetti";
import { useSelector } from "react-redux";
import useWindowSize from "../hooks/useWindowSize";

const ConfettiCompontent = (props: ConfettiProps) => {
  const { width, height } = useWindowSize();
  const [run, setRun] = useState(false);

  const { confetti } = useSelector((state: any) => state.effects);
  useEffect(() => {
    if (confetti) setRun(true);
  }, [confetti]);

  return (
    <ReactConfetti
      className="p-relative"
      recycle={confetti}
      run={run}
      width={width}
      height={height}
      style={{
        ...props.style,
        zIndex: 3000,
        // animation: `slow-fade-out ${props.duration}s forwards`,
      }}
      {...props}
    />
  );
};

export default ConfettiCompontent;
