import React, { useEffect, useRef, useState } from "react";
import { Col, Collapse, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import BottomBar from "../components/BottomBar";
import Navbar from "../components/Navbar";
import {
  removeUserInfo,
  removeUserToken,
  setUserInfo,
} from "../redux/user/action";
import Network, { IsBeta } from "../utils/Network";
import useSteps from "../utils/Steps";
import { motion } from "framer-motion";
import Helmet from "react-helmet";
import OneSignal from "react-onesignal";
import Drawer from "react-modern-drawer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-modern-drawer/dist/index.css";
import MidBox from "../components/Boxes/MidBox";
import { isMobile } from "react-device-detect";
import useToast from "../hooks/useToast";
import SettingsDialog from "../components/SettingsDialog";
import ErrorBoundary from "./ErrorBoundary";
import Dialog from "../components/Dialog";
import { useTour } from "@reactour/tour";
import { tourDefaultState } from "../redux/tour/reducer";
import { SetTour } from "../redux/tour/action";
import Button from "../components/Button";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAccount, useDisconnect, useSwitchNetwork, useNetwork } from "wagmi";
import { SkeletonTheme } from "react-loading-skeleton";
import SwiperClass from "swiper";
import { clearScoutsData } from "../redux/scout/action";
import { ContestEndTime, ContestTime } from "../constants";
import { clearChannels, clearMessages } from "../redux/chat/actions";
import useQuests from "../hooks/useQuests";
import { Quest, QuestAction, QuestState } from "../types/quests/indext";
import ConfettiCompontent from "../components/Confetti";
import DisconnectDialog from "../components/Dialogs/DisconnectDialog";
import { Assets } from "../constants/assets";

type Props = {
  title?: string;
};

const DefaultLayout = ({ title }: Props) => {
  const userInfo = useSelector((state: any) => state.userInfo);
  const tourState: typeof tourDefaultState = useSelector(
    (state: any) => state.tour
  );
  const [settingsDialog, setSettingsDialog] = useState(false);
  const axios = new Network();
  const [offerDialog, setOfferDialog] = useState(false);
  const [depositDialog, setDepositDialog] = useState(false);
  const [eventDialog, setEventDialog] = useState(false);
  const navigate = useNavigate();
  const openToast = useToast();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const { isDisconnected, address, connector, isConnected } = useAccount();
  // const [isHide, setIsHide] = useState(Boolean(localStorage.getItem('dao-collapse')))
  const [isHide, setIsHide] = useState(
    Boolean(localStorage.getItem("dao-troop-xp"))
  );
  const [isOpen, setIsOpen] = useState(false);

  const [voteDialog, setVoteDialog] = useState(false);
  const [dontShow, setDontShow] = useState(false);
  const [isTourModalOpen, setIsTourModalOpen] = useState(false);
  const { switchNetworkAsync } = useSwitchNetwork();
  const { chains, chain } = useNetwork();
  const { setIsOpen: setTourState, setSteps, setCurrentStep } = useTour();
  const location = useLocation();
  const sliderRef = React.useRef<SwiperClass>();
  const steps: any = useSteps();
  const { quests, handleDone } = useQuests();
  const connectMethod = localStorage.getItem("connector");
  const refCode = localStorage.getItem("refCode");
  const IsDone = useRef(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  useEffect(() => {
    setCurrentStep(0);
    setSteps(steps);
  }, [location.pathname]);

  useEffect(() => {
    if (location.hash === "#tour-guide") setIsTourModalOpen(true);
  }, [location.hash]);

  useEffect(() => {
    if (!localStorage.getItem("tour"))
      setTimeout(() => {
        setIsTourModalOpen(true);
      }, 5000);
  }, []);

  const handleLogout = async () => {
    dispatch(removeUserInfo());
    dispatch(removeUserToken());
    dispatch(clearScoutsData());
    dispatch(clearMessages());
    localStorage.removeItem("user.token");
    disconnect();
    if (location.pathname !== "/login") {
      navigate(
        "/login" +
          `?return=${location.pathname}${
            location.search ? location.search : ""
          }`
      );
    }
  };

  useEffect(() => {
    if (
      userInfo &&
      connectMethod === "walletconnect" &&
      isConnected &&
      address
    ) {
      if (userInfo?.walletAddress.toLowerCase() !== address.toLowerCase()) {
        openToast({ ok: false, message: "Your WalletAddress does not match!" });
        handleLogout();
      }
    }
  }, [userInfo, connectMethod, isConnected, address]);
  useEffect(() => {
    if (window.location.hash?.includes("settings")) {
      setSettingsDialog(true);
    }
  }, [window.location.hash]);

  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
      if (response.data.state === 2 || response.data.state === 3)
        handleLogout();
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("user.token")) {
      return navigate(
        "/login" +
          `?return=${location.pathname}${
            location.search ? location.search : ""
          }`
      );
    }
    getMe();
    if (!document.location.pathname.includes("dao"))
      setIsHide(Boolean(localStorage.getItem("notif-dao2-collapse")));
  }, []);

  const setUserId = async () => {
    const isSubscribed = await OneSignal.isPushNotificationsEnabled();
    if (isSubscribed) {
      const userID = await OneSignal.getExternalUserId();
      await OneSignal.setExternalUserId(userInfo.id);
    }
  };
  const isCorrectTime =
  ContestTime.getTime() < Date.now() && ContestEndTime.getTime() > Date.now();

  // useEffect(() => {
  //   if (!localStorage.getItem("dao-troop-xp") && !IsBeta) {
  //     setTimeout(() => {
  //       setOfferDialog(true);
  //     }, 2000);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!localStorage.getItem("depositx-dc3") && !IsBeta && location.pathname==="/profile") {
  //     setTimeout(() => {
  //       setDepositDialog(true);
  //     }, 2000);
  //   }
  // }, [location.pathname]);

  // useEffect(() => {
  //   if (!localStorage.getItem("xEvent") && !IsBeta&&userInfo?.tags?.[0] > 1) {
  //     setTimeout(() => {
  //       setEventDialog(true);
  //     }, 2000);
  //   }
  // }, [userInfo]);

  useEffect(() => {
    if (userInfo) setUserId();
  }, [userInfo]);

  useEffect(() => {
    if (quests && !IsDone.current) {
      IsDone.current = true;
      const targetQuest = quests.find(
        ({ quest }: { quest: Quest }) =>
          quest?.requiredAction === QuestAction.CompleteTourGuide &&
          quest?.state === QuestState.Open
      );
      if (targetQuest) {
        const progress = handleCalculateTourProgress();
        if (progress > (targetQuest.progress || 0)) {
          handleDone(QuestAction.CompleteTourGuide, progress);
        }
      }
    }
  }, [quests]);

  const handleCalculateTourProgress = () => {
    let progress = 0;
    if (tourState.pages.home) progress += 1;
    if (tourState.pages.marketplace) progress += 1;
    if (tourState.pages.adventure) progress += 1;
    if (tourState.pages.myLands) progress += 1;
    if (tourState.pages.preFight) progress += 1;
    if (tourState.pages.profile) progress += 1;
    if (tourState.pages.referral) progress += 1;
    if (tourState.pages.treasure) progress += 1;
    return progress;
  };
  const handleOpenTour = ({ force }: { force?: boolean }) => {
    if (location.pathname === "/home") {
      if (!force && tourState.pages.home) return;
      setTourState(true);
      dispatch(
        SetTour({
          ...tourState,
          isStared: true,
          pages: { ...tourState.pages, home: true },
        })
      );
    }
    if (location.pathname === "/marketplace") {
      if (!force && tourState.pages.marketplace) return;
      setTourState(true);
      dispatch(
        SetTour({
          ...tourState,
          isStared: true,
          pages: { ...tourState.pages, marketplace: true },
        })
      );
    }
    if (location.pathname === "/referrals") {
      if (!force && tourState.pages.referral) return;
      setTourState(true);
      dispatch(
        SetTour({
          ...tourState,
          isStared: true,
          pages: { ...tourState.pages, referral: true },
        })
      );
    }
    if (location.pathname === "/profile") {
      if (!force && tourState.pages.profile) return;
      setTourState(true);
      dispatch(
        SetTour({
          ...tourState,
          isStared: true,
          pages: { ...tourState.pages, profile: true },
        })
      );
    }
    if (location.pathname === "/adventure/new") {
      if (!force && tourState.pages.adventure) return;
      setTourState(true);
      dispatch(
        SetTour({
          ...tourState,
          isStared: true,
          pages: { ...tourState.pages, adventure: true },
        })
      );
    }
    if (location.pathname === "/lands") {
      if (!force && tourState.pages.myLands) return;
      setTourState(true);
      dispatch(
        SetTour({
          ...tourState,
          isStared: true,
          pages: { ...tourState.pages, myLands: true },
        })
      );
    }
  };

  useEffect(() => {
    if (!tourState.isStared) return;
    if (tourState.isDone) return;
    setTimeout(() => {
      handleOpenTour({ force: false });
    }, 2000);
  }, [location.pathname]);

  useEffect(() => {}, []);
  // useEffect(() => {
  //   if (isDisconnected && !isConnected && connectMethod === "walletconnect") {
  //     handleLogout();
  //   }
  // }, [isDisconnected, isConnected]);

  async function CheckNetworkWagmi() {
    if (
      !isDisconnected &&
      chain?.id !== 56 &&
      connectMethod === "walletconnect" &&
      switchNetworkAsync
    ) {
      try {
        openToast({
          ok: false,
          message: "Invalid Network! Please your switch network to BSC",
        });
        const change = await switchNetworkAsync(chains[0]?.id);
      } catch (e) {
        handleLogout();
      }
    }
  }

  const containerRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    CheckNetworkWagmi();
  }, [chain, switchNetworkAsync]);

  const drawerActions = [
    {
      image: "/assets/images/icons/referral.svg",
      title: "Referral Program",
      link: "/referrals",
    },
    {
      image: "/assets/images/icons/inventory.svg",
      title: "Account Details",
      link: "/profile",
    },

    {
      image: "/assets/images/icons/dao.svg",
      title: "Dao",
      link: "/dao",
    },
    {
      image: "/assets/images/icons/tx.svg",
      title: "Transactions",
      link: "/transaction-history",
    },
    {
      image: "/assets/images/icons/withdraw-tx.svg",
      title: "Withdraw History",
      link: "/withdraw-history",
    },
    {
      image: "/assets/images/icons/settings.svg",
      title: "Settings",
      link: "#settings",
    },
    {
      image: "/assets/images/icons/withdraw-tx.svg",
      title: "NFT Box",
      link: "/mint-lands",
    },
    {
      image: "/assets/images/icons/edu.png",
      title: "Learn",
      link: "/learn",
    },
    {
      image: "/assets/images/icons/support.png",
      title: "Support",
      link: "https://support.chainoflegends.com",
    },
  ];

  // const isWhiteList =
  //   userInfo?.tags?.[0] === 1 ||
  //   refCode === "199678" ||
  //   refCode === "202163" ||
  //   refCode === "202387";

  return (
    <div
      className={
        "main-layout  pb-right transition p-relative " +
        (location?.pathname.includes("/guild") ? "guild-layout" : "")
      }
    >
      <Helmet>
        <title key={"title"}>
          {title ? <>{title} | Chain Of Legends</> : "Chain Of Legends"}
        </title>
      </Helmet>

      {/* {!IsBeta && (
        <>
          <Collapse appear={!isMobile} in={!isHide}>
            <motion.div
              exit={{ opacity: 0, y: -300 }}
              onClick={() => setOfferDialog(true)}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.5 }}
              className="pt-2 pb-2 text-center pointer text-white bg-orange"
            >
              <Container className="d-flex text-shadow flex-wrap fs-sm-small ">
                <div className=" ">
                  🚩 Troops XP System Update to Stop XP farming, Do you Agree?
                </div>
                <Link
                onClick={(e)=>{e.stopPropagation();
                  setIsHide(prev=>!prev);
                }}
                  to="/dao/672f29c0799e3839c4e85761"
                  className="ms-auto text-white me-2"
                >
                  Vote Now
                </Link>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsHide(prev=>!prev);
                  }}
                  className="button text-white btn-close bg-white"
                ></button>
              </Container>
            </motion.div>
          </Collapse>
          <Collapse in={isHide}>
            <motion.div
              exit={{ opacity: 0, y: -300 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.5 }}
            >
              <Container
                style={{
                  textAlign: "end",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                className=" w-100"
              >
                <img
                  width={40}
                  height={40}
                  className="button"
                  onClick={() => {
                    setIsHide(false);
                    localStorage.removeItem("notif-dao2-collapse");
                  }}
                  src={"/assets/images/icons/warning.png"}
                />
              </Container>
            </motion.div>
          </Collapse>
        </>
      )} */}
      {/* <HolloweenBoard /> */}
      {IsBeta && (
        <>
          <Collapse appear={!isMobile} in={!isHide}>
            <motion.div
              exit={{ opacity: 0, y: -300 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.5 }}
              className="pt-2 pb-2 text-center pointer text-white bg-orange"
            >
              <Container className="d-flex text-shadow flex-wrap fs-sm-small">
                <div style={{ textAlign: "justify" }}>
                  🔧This is the Beta version , 🔥Play the Main Game , Earn Real
                  Money + Special Offer🔥
                </div>
                <div style={{ flexGrow: 1 }} />
                <a
                  target="_blank"
                  href="https://chainoflegends.com/?ref=202387"
                >
                  <div style={{ color: "white" }} className="me-2 bold">
                    Click Here
                  </div>
                </a>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsHide(true);
                    localStorage.setItem("notif-dao2-collapse", "true");
                  }}
                  className="button ms-auto text-white btn-close bg-white"
                ></button>
              </Container>
            </motion.div>
          </Collapse>
          <Collapse in={isHide}>
            <motion.div
              exit={{ opacity: 0, y: -300 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.5 }}
            >
              <Container
                style={{
                  textAlign: "end",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                className=" w-100"
              >
                <img
                  width={40}
                  height={40}
                  className="button"
                  onClick={() => {
                    setIsHide(false);
                    localStorage.removeItem("notif-dao2-collapse");
                  }}
                  src={"/assets/images/icons/warning.png"}
                />
              </Container>
            </motion.div>
          </Collapse>
        </>
      )}
      <ConfettiCompontent />
      <div className="container">
        <Navbar handleLogout={handleLogout} toggleDrawer={toggleDrawer} />
        <div className="text-white ">
          <ErrorBoundary openToast={openToast}>
            <SkeletonTheme baseColor="#2d271b" highlightColor="#3e382b">
              <Outlet />
            </SkeletonTheme>
          </ErrorBoundary>
        </div>
        {/* <KYC open={false} onClose={() => null} /> */}
      </div>

      <BottomBar />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        size={isMobile ? 300 : 350}
        className="bg-transparent"
      >
        <MidBox
          style={{ minHeight: "100%" }}
          title="d"
          noTitle
          containerClass="px-lg-4"
        >
          <button
            onClick={toggleDrawer}
            className="button hard btn-close"
          ></button>
          <PerfectScrollbar style={{ height: "90vh", marginTop: -30 }}>
            <div className="px-0 px-lg-3">
              <div className="d-flex justify-content-between align-items-center  rounded-10 px-4 py-2 mb-2">
                <div className="fs-big dark-yellow ">
                  {userInfo?.walletAddress?.slice(0, 4)}...
                  {userInfo?.walletAddress?.slice(
                    userInfo?.walletAddress?.length - 4,
                    userInfo?.walletAddress?.length
                  )}
                </div>
                <div className="d-flex  align-items-center justify-content-center">
                  <CopyToClipboard
                    text={userInfo?.walletAddress}
                    onCopy={() =>
                      openToast({
                        ok: true,
                        data: { message: "Wallet Address copied to clipboard" },
                      })
                    }
                  >
                    <img
                      width={60}
                      className="button"
                      src="/assets/images/buttons/copy-btn.png"
                    />
                  </CopyToClipboard>
                </div>
              </div>
              <div className="mt-2 mt-md-2 dark-bg rounded-10 px-4 py-2 mb-4">
                <div className="fs-small">Balance</div>
                <div className="fs-big dark-yellow bold">
                  {userInfo?.totalCleg} Cleg
                </div>
              </div>
              <hr />
              <div className="mt-2 d-flex flex-column">
                {drawerActions.map((item, index) =>
                  item.link?.includes("https") ? (
                    <a
                      onClick={() => toggleDrawer()}
                      target="_blank"
                      href={item.link}
                      key={index + "oxw"}
                      className="d-flex mt-2 row-item align-items-center"
                    >
                      <img src={item.image} style={{ width: 26, height: 26 }} />
                      <a className="ms-2  dark-yellow">{item.title}</a>
                    </a>
                  ) : (
                    <Link
                      onClick={() => toggleDrawer()}
                      to={item.link}
                      key={index + "oxw"}
                      className="d-flex mt-2 row-item align-items-center"
                    >
                      <img src={item.image} style={{ width: 26, height: 26 }} />
                      <a className="ms-2  dark-yellow">{item.title}</a>
                    </Link>
                  )
                )}
              </div>
              <hr />
              <div
                onClick={() => {
                  toggleDrawer();
                  handleLogout();
                }}
                className="d-flex mt-2 pointer w-100 text-center align-items-center"
              >
                <a className=" text-center w-100 text-danger">Log Out</a>
              </div>
            </div>
          </PerfectScrollbar>
        </MidBox>
      </Drawer>
      <Dialog
        submitVariant="wide-primary"
        submitLabel="Start the Tour "
        closeLabel="Not now"
        submitStyle={{
          backgroundSize: "100% 100%",
          marginInline: 5,
        }}
        onSubmit={() => {
          setIsTourModalOpen(false);
          localStorage.setItem("tour", "done");
          handleOpenTour({ force: true });
        }}
        title="Tour Guide"
        open={isTourModalOpen}
        onClose={() => {
          setIsTourModalOpen(false);
          localStorage.setItem("tour", "done");
          navigate(location.pathname);
        }}
      >
        <Row className="align-items-center">
          <Col md={4} className="text-center" xs={12}>
            <img
              style={{ maxWidth: "180px" }}
              width={"100%"}
              src="/assets/images/tour-1.png"
            />
          </Col>
          <Col md={8} xs={12}>
            <div style={{ textAlign: "justify", fontSize: 15 }}>
              Hello master, <br />
              Welcome to the "Chain of Legends" community.
              <br />
              Hope to witness the day you become one of the legends!
              <br />
              <b>Are you interested in having a Tour with us?</b>
            </div>
          </Col>
        </Row>
      </Dialog>
      <SettingsDialog
        open={settingsDialog}
        onClose={() => {
          setSettingsDialog(false);
          // console.log(window.location.pathname)
          navigate(window.location.pathname);
        }}
      />
      <Dialog
        size="lg"
        onClose={() => {
          setOfferDialog(false);
          localStorage.setItem("dao-troop-xp", "true");
        }}
        title="DAO"
        onSubmit={() => {
          localStorage.setItem("dao-troop-xp", "true");
          setOfferDialog(false);
          navigate("/dao/672f29c0799e3839c4e85761");
        }}
        submitLabel="Vote Now"
        open={offerDialog}
      >
        <div style={{ marginBottom: -90 }}>
          <div className="fs-med text-center">
            <h2 className="text-center fs-bigger">
              <p>
                <small>📣 Chain of Legends DAO</small>:{" "}
                <strong>Troops XP System Update</strong>
                <small>📣</small>
              </p>
            </h2>
            <br />
            <img
              width={"80%"}
              className="rounded"
              alt={"DAO Banner"}
              src={Assets.campaigns.troopDao}
            />
            <div className="text-start fs-lg mt-4">
              <p>Hey Legends! 👋</p>
              <p>
                We&rsquo;ve been listening to your feedback and are dedicated to
                keeping the Troops XP System balanced and rewarding for
                everyone. Here&rsquo;s the latest on what we&rsquo;re working to
                improve:
              </p>
              <p>
                <b className="fs-big">🔍 Current Issue:</b>
                <br />
                Some players are withdrawing large amounts of tokens with
                minimal investment, creating an imbalance and impacting token
                value. By earning tokens without equivalent contributions, the
                system&rsquo;s fairness is affected, leading to a drop in token
                stability.
              </p>
              <p>
                🎯 Our Goals: &nbsp;
                <br />
                To restore balance and protect the value of our ecosystem,
                we&rsquo;re proposing adjustments to the Troops XP System:
              </p>
              <p>
                🔥 Troops Burn System: Players can now burn troops in exchange
                for credit, which can be used in a Special Shop for purchasing
                exclusive items and new troops. This will help maintain troop
                value and introduce fresh options for dedicated players.
                <br />
                &nbsp;&nbsp;
                <br />⚔ Troop XP Refinement: Base XP values for troops will be
                adjusted to promote fair gameplay and meaningful progression.
                Here&rsquo;s a look at the proposed changes:
              </p>
              <p>
                &nbsp;Troop &nbsp; &nbsp;New Base XP&nbsp;
                <br />
                &nbsp;Warrior: 60
                <br />
                &nbsp;Archer: 60
                <br />
                &nbsp;Guardian: 100
                <br />
                &nbsp;Knight: 150
                <br />
                &nbsp;Mage: 100
                <br />
                &nbsp;Catapult: 400
                <br />
                &nbsp;Dragon: 300
                <br />
                &nbsp;Goblin: 100
                <br />
                &nbsp;Big Jack: 150
                <br />
                &nbsp;Red Hunter: 200
                <br />
                &nbsp;Captain Leroy: 700
                <br />
                &nbsp;Raze: 300
                <br />
                &nbsp;Lyra: 300
              </p>
              <hr />
              <p>
                <br />
                🗓 Vote Date &amp; Duration
                <br />
                Starts on: Sunday, November 10th, from 00:01 AM to 23:59 PM UTC.
                Log in to cast your vote and make a difference in shaping the
                future!
              </p>
              <p>
                🙏 Thank you for supporting Chain of Legends and working with us
                to maintain a balanced and rewarding experience for all players.
                We&rsquo;re excited to see how this community-led decision
                shapes our shared vision!
              </p>
              <p>
                💬 Share your feedback &ndash; but please keep the conversation
                within the DAO so we can finalize the best approach together.
              </p>
              <p className="text-center">&nbsp;</p>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        onClose={() => {
          setDepositDialog(false);
          localStorage.setItem("depositx-dc3", "true");
        }}
        size="lg"
        title="CLEG x PinkLock"
        open={depositDialog}
      >
        <div style={{ marginBottom: -40 }}>
          <div ref={containerRef}>
            <img
              src="/assets/images/banners/deposit-campaign.jpg"
              width={"100%"}
            />
          </div>
          <h5 className="mt-3 text-center">🔥 LIVE & HOT 🔥</h5>
          <p className="fs-lg text-center">
            🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸
            <br />
            📥 Stake & Lock = Earn up to 7% Instant Rewards 💰
            <br />
            🚀{" "}
            <a
              href="https://www.pinksale.finance/pinklock/create?chain=BSC"
              target="_blank"
              className="text-info"
              referrerPolicy="no-referrer"
            >
              PinkSale
            </a>
          </p>

          <div style={{ marginBottom: -60 }} className="text-center">
            ✅ Verify your token lock through 👇
            <a
              onClick={() => {
                setDepositDialog(false);
                localStorage.setItem("depositx-dc3", "true");
              }}
              href={"https://forms.gle/ER8VGqLJkzt7odSL9"}
              target="_blank"
            >
              <Button className="bg-100 mx-auto mt-2" variant="primary">
                Click Here
              </Button>
            </a>
          </div>
        </div>
      </Dialog>

      {/* <Dialog
        noFooter
        onClose={() => {
          setEventDialog(false);
          localStorage.setItem("xEvent", "true");
        }}
        size="lg"
        title="CLEG x PinkLock"
        open={eventDialog}
      >
        <div style={{ marginBottom: -40, zIndex: 40, position: "relative" }}>
          <div ref={containerRef} className="text-center">
            <img
              src={`/assets/images/banners/stake/${userInfo?.tags[0] - 1}.png`}
              width={"100%"}
              className="rounded"
              style={{ maxWidth: 450 }}
            />
          </div>
          <h5 className="mt-3 text-center">
            📥 Stake & Lock = Earn up to 7% Instant Rewards 💰
          </h5>
          <p className="fs-lg text-center">
            🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸
            <br />
            📥 Stake & Lock = Earn up to 7% Instant Rewards 💰
            <br />
            🚀{" "}
            <a
              href="https://www.pinksale.finance/pinklock/create?chain=BSC"
              target="_blank"
              className="text-info"
              referrerPolicy="no-referrer"
            >
              PinkSale
            </a>
          </p>

          <div style={{ marginBottom: -50 }} className="text-center">
            ▶️ Watch the Youtube Video to the end & Pay attention to the details
            ⚠️
            <a
              href={"https://youtu.be/ZtLQX4nEHuc?si=irtdT7sXQkc0Erps"}
              target="_blank"
            >
              <Button className="bg-100 mx-auto mt-2" variant="secondary">
                Youtube
              </Button>
            </a>
            <br />
            <div>
              🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸 <br />✅ Verify your token lock through
              👇
            </div>
            <a
              onClick={() => {
                setEventDialog(false);
                localStorage.setItem("xEvent", "true");
              }}
              href={"https://forms.gle/ER8VGqLJkzt7odSL9"}
              target="_blank"
            >
              <Button className="bg-100 mx-auto mt-2" variant="primary">
                Click Here
              </Button>
            </a>
          </div>
        </div>
      </Dialog> */}

      <DisconnectDialog handleLogout={handleLogout} />
    </div>
  );
};

export default DefaultLayout;
