import React, { Component } from 'react'
import { roundFloat } from '../utils/round-float'

type Props = {
    userConfig: any;
    children: React.ReactNode;
    enableBgMusic: Function;
}

type State = {

}

class PreFightContainer extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.handleMusic = this.handleMusic.bind(this)
    }
    state = {}
    private backgroundSound = new Audio("https://core.chainoflegends.com/assets/audio/formation.txt")


    componentDidMount(): void {
        this.backgroundSound.volume = parseFloat(roundFloat(this.props?.userConfig?.backgroundMusicVolume / 10, 1));
        this.backgroundSound.loop = true
        this.backgroundSound.addEventListener("canplaythrough", (event) => {
            this.backgroundSound.play();
        })
        document.addEventListener('visibilitychange', this.handleMusic, false)
    }

    componentWillUnmount(): void {
        this.backgroundSound.pause();
        document.removeEventListener('visibilitychange', this.handleMusic, false)
        this.props.enableBgMusic()

    }

    handleMusic(evt?: any) {
        if (document.hidden) {
            // bufferSource.stop(audioCtx.currentTime)
            // bufferSource.disconnect()
            this.backgroundSound.pause()

        }
        else {
            if (this.props?.userConfig?.backgroundMusicVolume == 0) return;
            this.backgroundSound.play()
        }
    }
    render() {
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

export default PreFightContainer