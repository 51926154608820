import React from "react";
import PlayerPublicProfile from "../containers/player-profile";

type Props = {};

const PublicProfile = (props: Props) => {
  return <PlayerPublicProfile />;
};

export default PublicProfile;
