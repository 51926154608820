import React, { useEffect, useMemo, useState } from "react";
import Dialog from "../../../components/Dialog";
import Button from "../../../components/Button";
import { CustomIcon } from "../../../components/Icons";
import EventBus, { EventNames } from "../../utils/event-bus";
import { useNavigate } from "react-router-dom";
import { useBattleContext } from "../../context";
import { BattleResult } from "../../types";
import { useSelector } from "react-redux";

const BattleResultDialog = () => {
  const [open, setOpen] = React.useState(false);
  const { messages } = useBattleContext();
  const [battleResult, setBattleResult] = useState<BattleResult>();
  const userInfo = useSelector((state: any) => state.userInfo);
  const navigate = useNavigate();
  
  useEffect(() => {
    EventBus.on(EventNames.BattleEnd, () => {
      setOpen(true);
    });
    return () => {
      EventBus.off(EventNames.BattleEnd, () => {
        setOpen(true);
      });
    };
  }, []);

  useEffect(() => {
    EventBus.on(EventNames.BattleResult, (d) => {
      setBattleResult(d);
    });
    return () => {
      EventBus.off(EventNames.BattleResult, (d) => {
        setBattleResult(d);
      });
    };
  }, []);

  const filteredResults = useMemo(() => {
    if (!battleResult) return undefined;
    const isWinner = battleResult.winners.players.find(
      (p) => p.playerId === userInfo.id
    );
    const isLoser = battleResult.losers.players.find(
      (p) => p.playerId === userInfo.id
    );
    if (isWinner) return battleResult.winners;
    if (isLoser) return battleResult.losers;
  }, [battleResult]);

  return (
    <Dialog
      title="Battle Is Ended"
      onSubmit={() => {
        navigate("/arena");
        setOpen(false);
      }}
      submitLabel="Home"
      closeLabel="Result"
      open={open}
      onClose={() => {
        navigate("/arena", { replace: true });
        setOpen(false);
      }}
    >
      {messages && messages[messages.length - 1]?.message && (
        <div className="text-center w-100">
          <span className="fs-md dark-yellow">
            {messages[messages.length - 1]?.message}
          </span>
        </div>
      )}
      {filteredResults && (
        <>
          <div className="d-flex gap-3 mt-3 align-items-end justify-content-center">
            <div
              style={{ maxWidth: 80, height: "fit-content" }}
              className="item-frame mx-0 active-slot  my-0 py-2 flex-column "
            >
              <img
                width={30}
                className="circle mt-1"
                src="/assets/images/arena/icons/energy-icon.png"
              />
              <span className="fs-md dark-yellow text-center mb-2">
                -{filteredResults?.energyCost}
              </span>
            </div>

            <div
              style={{ maxWidth: 80, height: "fit-content" }}
              className="item-frame mx-0 active-slot  my-0 py-2 flex-column "
            >
              <img
                width={30}
                className="circle mt-1"
                src="/assets/images/arena/icons/gem-icon.png"
              />
              <span className="fs-md  dark-yellow text-center mb-2">
                +{filteredResults?.bloodGemReward}
              </span>
            </div>
            <div
              style={{ maxWidth: 80, height: "fit-content" }}
              className="item-frame active-slot mx-0 my-0 py-2 flex-column "
            >
              <img
                width={30}
                height={31}
                className="circle mt-1"
                src="/assets/images/icons/xp.svg"
              />
              <span className="fs-md dark-yellow text-center mb-2">
                +{filteredResults?.xp}
              </span>
            </div>
          </div>
          <div className="d-flex gap-3 mt-3 align-items-center justify-content-center flex-column">
            <Button
              variant="rounded"
              style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "bold" }}
              className="mt-3 p-1 px-4 bg-100 fs-small  no-hover"
            >
              SCORE
            </Button>
            <div className="dark-yellow fs-big ">
              +{filteredResults?.score}{" "}
              <CustomIcon icon="cup" width={24} className="mb-1" height={24} />
            </div>
          </div>{" "}
        </>
      )}
    </Dialog>
  );
};

export default BattleResultDialog;
