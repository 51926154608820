import React from "react";
import { Assets } from "../../../constants/assets";
import "./style.css";
import Button from "../../../components/Button";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
type Props = {};

const ComingSoon = (props: Props) => {
  return (
    <motion.div
      className="coming-soon-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="d-flex  justify-content-center align-items-center gap-2 flex-column  ">
        <img
          src={Assets.placeholder.comingSoon}
          alt="coming soon"
          className="w-100 coming-soon-image"
        />
        <div>
          <h1 className="text-center bold dark-yellow">Coming Soon</h1>
        </div>
        <div>
          <p className="text-center px-2 dark-yellow">
            We are working hard to bring you the best experience possible. The
            mobile version will be ready in a couple of days. In the meantime,
            feel free to try the desktop version. Please check back soon!
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Link to={"/home"}>
            <Button variant="primary">Back</Button>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default ComingSoon;
