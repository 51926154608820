import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { removeUserInfo, removeUserToken } from "../redux/user/action";
import { clearScoutsData } from "../redux/scout/action";
import { clearChannels, clearMessages } from "../redux/chat/actions";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userToken = useSelector((state: any) => state?.userToken);
  const userSettings = useSelector((state: any) => state?.userSettings);

  const handleLogout = () => {
    dispatch(removeUserInfo());
    dispatch(removeUserToken());
    dispatch(clearScoutsData());
    dispatch(clearChannels());
    dispatch(clearMessages());
    localStorage.removeItem("user.token");
    if (location.pathname === "/login") return;
    navigate("/login");
  };

  return { handleLogout, userToken, userSettings };
};

export default useAuth;
