import React, { FC } from "react";
import { GuildTabType } from ".";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {
  tabs: GuildTabType[];
  value: string;
};

const GuildTabs: FC<Props> = ({ tabs, value }) => {
  const navigate = useNavigate();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const changeQuery = (val: number | string | boolean, name: string) => {
    val ? searchParameters.set(name, val + "") : searchParameters.delete(name);
    setSearchParameters(searchParameters);
  };

  return (
    <motion.div
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ delay: 0.2, duration: 0.3 }}
      className=""
    > 
      <div
        style={{ background: "#00000049", backdropFilter: "blur(20px)" }}
        className="d-flex tab-container rounded x align-items-center justify-content-center"
      >
        {tabs.map((tab, i) => {
          if (tab.hidden) return;
          const isActive = value === tab.value;
          return (
            <motion.div
              initial={{ y: -100 }}
              style={{
                opacity: tab.disabled ? 0.4 : 1,
              }}
              animate={{ y: 0 }}
              onClick={() => {
                if (tab.disabled) return;
                changeQuery(tab.value, "tab");
              }}
              transition={{ delay: i * 0.1 + 0.2 }}
              className={classNames("tab-action flex-column flex-md-row ", {
                "tab-border": i == 1,
                active: isActive,
              })}
            >
              <img src={tab.icon} alt={tab.title} />
              {tab.title}
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default GuildTabs;
