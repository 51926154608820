import { actionTypes } from './action';

export const audioDefaultState=true

export const audioPlayableReducer = (state = audioDefaultState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_AUDIO:
            return action.payload;
        default:
            return state;
    }
};
