
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MidBox from '../components/Boxes/MidBox'
import { roundFloat } from '../utils/round-float'
import Network, { ProfileURL } from '../utils/Network'
import { motion } from 'framer-motion'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Button from '../components/Button'
import { isMobile } from 'react-device-detect'
import Dialog from '../components/Dialog'
import useToast from '../hooks/useToast'
import { Alert, Form, OverlayTrigger, Pagination, Tooltip } from 'react-bootstrap'
import ThousandsSeparator from '../utils/thousands-separator'
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import truncateString from '../utils/truncate-string'
import { stat } from 'fs'
import Countdown from 'react-countdown'
import LevelBadge from '../components/LevelBadge'
import { CustomIcon } from '../components/Icons'
import { Helmet } from 'react-helmet'

type Props = {}
export const WithdrawStates = {
    1: "Pending",
    2: "Transfered",
    3: "Transfered",
    4: "Rejected",
}


const BetaLeaderBoard = (props: Props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [paginations, setPaginations] = useState({ page: 1, count: 100, total: 1 })
    const [markLoading, setMarkLoading] = useState(false)
    const [offerDialog, setOfferDialog] = useState(false)
    const [stats, setStats] = useState({
        totalCleg: 0
    })

    const userInfo = useSelector((state: any) => state.userInfo)

    const openToast = useToast()
    const axios = new Network()
    const navigate = useNavigate();

    const getData = async (page = 1) => {
        const response = await axios.CallApi({
            url: '/treasure/leaderboard',
            method: 'get'
        })
        if (response.ok) {
            setData(response?.data || [])
            let totalCleg = 0
            for (let key in response?.data) {
                totalCleg += (response?.data[key]?.collectedCleg)
            }
            setStats({
                totalCleg
            })
            setPaginations({ total: Math.ceil(response.data.totalCount / 100), page, count: 100 })
        }
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        getData()
    }, [])
    return (
        <SkeletonTheme baseColor='#2d271b' highlightColor='#3e382b'>
                  <Helmet>
        <title key={'title'}>
         Leaderboard | Chain Of Legends
        </title>
      </Helmet>
            <motion.div
                exit={{ opacity: 0, y: 200 }}
                transition={{ duration: 0.4 }}
                initial={isMobile ? undefined : { opacity: 0, y: 200 }} animate={{ opacity: 1, y: 0 }}
                className='row'
            >

                <MidBox modal title="Leaderboard">

                    <img style={{ position: 'absolute', top: 10, left: 10 }} className="" src="/assets/images/icons/referral.png" width={80} height="auto" />
                    <img style={{ position: 'absolute', top: 10, right: 10 }} className="" src="/assets/images/icons/referral.png" width={80} height="auto" />
                    <div className='text-center d-flex  justify-content-center flex-wrap align-items-center'>
                        <h3>
                            Treasure LeaderBoard
                        </h3>
                    </div>
                    <div className='d-flex mt-3 flex-wrap align-items-center justify-content-center'>
                        {new Date('Wed, 23 Jul 2023 12:00:00 GMT').getTime() < Date.now() ?
                            <Alert variant='danger'>
                                <CustomIcon icon="warning" width={20} height={20} className='scaleX me-1 mb-1' />    Event Has Ended
                            </Alert> : <>
                                <div className='text-center  text-danger'>
                                    Remaining Time :
                                </div>
                                <div className='ms-2'>
                                    <Countdown
                                        date={new Date('Wed, 23 Jul 2023 12:00:00 GMT')}
                                        renderer={({ formatted, days }) => (
                                            <div className='fs-big d-flex'>
                                                {days ?
                                                    <div className='time-badge'>
                                                        {formatted.days}
                                                        <span className='dark-yellow'>
                                                            Days
                                                        </span>
                                                    </div> : ''}
                                                <div className='ms-2 time-badge'>
                                                    {formatted.hours}
                                                    <span className='dark-yellow'>
                                                        Hours
                                                    </span>
                                                </div>
                                                <div className='ms-2 time-badge'>
                                                    {formatted.minutes}
                                                    <span className='dark-yellow'>
                                                        Minutes
                                                    </span>
                                                </div>
                                                <div className='ms-2 time-badge'>
                                                    {formatted.seconds}
                                                    <span className='dark-yellow'>
                                                        Seconds
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </>
                        }    </div>

                    <div style={{ overflow: 'auto' }} className="pe-1 pt-3 pb-5 ps-1 mt-2 pe-sm-3 ps-sm-3 pb-4 table-box">
                        <table className="table table-borderless m-0 text-white" > <thead>
                            <tr>
                                <th scope="col">Rank</th>
                                <th style={{ textAlign: 'center' }} align='center'>
                                    ID
                                </th>
                                <th scope="col">
                                    Player
                                </th>

                                <th style={{ textAlign: 'left' }} scope="col">
                                    Collected Cleg
                                </th>
                                <th style={{ textAlign: 'left' }} scope="col">
                                    Reward
                                </th>
                                <th style={{ textAlign: 'left' }} scope="col">

                                </th>
                            </tr>
                        </thead>
                            <tbody>
                                {loading && [1, 2, 3].map((item) =>
                                    <tr key={"loading" + item} className=''
                                    >
                                        <td width={"50%"}>
                                            <Skeleton width={'100%'} />
                                        </td>
                                        <td> <Skeleton width={'60px'} /> </td>
                                        <td> <Skeleton width={'60px'} /></td>
                                        <td >
                                            <Skeleton width={'100px'}
                                                height={25}
                                            />
                                        </td>
                                        <td >
                                        <Skeleton width={'60px'}
                                                circle
                                                height={60}
                                            />
                                        </td>
                                        <td >
                                            <Skeleton width={'60px'}
                                                circle
                                                height={60}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {data.length === 0 && !loading && <tr>
                                    <td colSpan={6} style={{ textAlign: 'center', paddingTop: 40, fontSize: 20, paddingBottom: 40, }} className="dark-yellow">
                                        Sorry no data Available right now ..
                                    </td>
                                </tr>}
                                {!loading && data?.map((item: any, key) => (
                                    <>
                                        <tr className=''
                                            onClick={() => { }}
                                            style={{ opacity: !item?.isRead ? 1 : 0.7 }}
                                            key={"row" + key}>

                                            <td >
                                                <span className='fs-big' >
                                                    {key < 3 ? <img width={25} src={`/assets/images/icons/${key === 0 ? 'first' : key === 1 ? 'second' : 'third'}-place.png`} />
                                                        : <>#{key + 1}</>
                                                    }
                                                </span>
                                            </td>
                                            <td align='center' >
                                                <span className='fs-big ' >
                                                    {item.id}
                                                </span>
                                            </td>
                                            <td  >
                                                <div className='d-flex gap-2 align-items-center'>
                                                    {item?.photoFile ?
                                                        <div style={{ backgroundImage: 'url(/assets/images/avatar-bg.png)', width: 80, height: 80 }} className={'main-avatar'}>
                                                            <img src={
                                                                `${ProfileURL}/${item?.id}/${item?.photoFile}`
                                                            }
                                                                width={"70%"}
                                                                height={"70%"}
                                                                style={{
                                                                    position: 'absolute',
                                                                    borderRadius: '50%',
                                                                    top: '12%', right: 0, left: '12%',

                                                                }}
                                                            />
                                                        </div>
                                                        : <div style={{ width: 80, height: 80 }} className={'main-avatar'} />}
                                                    <div className='d-flex  flex-column'>
                                                        <div className='fs-big bold'>
                                                            {truncateString(item.walletAddress)}
                                                        </div>
                                                        {item.username && <div className='dark-gray'>
                                                            username:    {item.username?.length > 15 ? truncateString(item.username) : item.username}
                                                        </div>}
                                                    </div>
                                                </div>

                                            </td>
                                            <td   >
                                                <span
                                                    style={{
                                                        color: key === 0 ? '#f6e89f' : key === 1 ? '#87dde0' : key === 2 ? '#e19b7b' : ''
                                                    }}
                                                    className='fs-big'>
                                                    {ThousandsSeparator(item?.collectedCleg)}&nbsp;<small>
                                                        CLEG
                                                    </small>
                                                </span>
                                            </td>
                                            <td   >
                                                <div>
                                                    {item?.reward && <OverlayTrigger
                                                        overlay={<Tooltip>
                                                            {item?.reward?.name} Level {item?.reward?.level}
                                                        </Tooltip>}
                                                    >
                                                        <div
                                                            style={{ width: 60, height: 60 }}
                                                            className='d-flex align-items-center justify-content-center circle bg-2 p-relative'>
                                                            <img className='circle' src={item?.reward?.icon} width={50} height={50} />
                                                            <LevelBadge style={{ left: 'unset', right: 0 }} level={item?.reward?.level} />
                                                        </div>
                                                    </OverlayTrigger>}
                                                </div>
                                            </td>
                                            <td   >
                                                <div>
                                                    {item?.reward2 && <OverlayTrigger
                                                        overlay={<Tooltip>
                                                            {item?.reward2?.name} Level {item?.reward2?.level}
                                                        </Tooltip>}
                                                    >
                                                        <div
                                                            style={{ width: 60, height: 60 }}
                                                            className='d-flex align-items-center justify-content-center circle bg-2 p-relative'>
                                                            <img className='circle' src={item?.reward2?.icon} width={50} height={50} />
                                                            <LevelBadge style={{ left: 'unset', right: 0 }} level={item?.reward2?.level} />
                                                        </div>
                                                    </OverlayTrigger>}
                                                </div>
                                            </td>
                                        </tr>

                                    </>
                                ))}

                            </tbody>
                        </table>
                        <br />
                        {/* <ReactPaginate

                            className='pagination justify-content-center'
                            activeClassName='active-pg'
                            pageClassName='page-item'
                            pageLinkClassName='page-link'
                            breakClassName='page-item'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            breakLinkClassName='page-link'
                            previousLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            onPageChange={(e) => getData(e.selected + 1)}
                            pageRangeDisplayed={3}
                            pageCount={paginations.total}
                        // renderOnZeroPageCount={null}
                        />*/}
                    </div>
                </MidBox>
            </motion.div>
        </SkeletonTheme >
    )
}

export default BetaLeaderBoard