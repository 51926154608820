import React, { useEffect, useState } from "react";
import Network from "../../utils/Network";
import TroopBox from "../../components/Boxes/TroopBox";
import Slider from "rc-slider";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";
import ThousandsSeparator from "../../utils/thousands-separator";

type Props = {};

const Troops = (props: Props) => {
  const axios = new Network();
  const [data, setData] = React.useState([]);
  const [level, setLevel] = useState(1);
  const [loading, setLoading] = useState(true);
  const [viewType, setViewType] = useState(
    +localStorage.getItem("card-sort")! || 1
  );

  const handleGetTroops = async () => {
    setLoading(true);
    const response = await axios.CallApi({
      url: "/troop/levels",
      method: "get",
    });
    setData(
      response.data?.map((res) => ({
        ...res,
        abilities: res.abilities ? JSON.parse(res.abilities) : undefined,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    handleGetTroops();
  }, []);

  return (
    <div>
      <div className="text-secondary mt-4 text-center">
        In Barracks, you can train units. Some of the units are not trainable in
        barracks, but you can find them on adventures.
      </div>
      <div className="d-flex mb-3 justify-content-between align-items-center py-4">
        <div style={{ maxWidth: 600 }} className="w-100 ">
          <Slider
            step={1}
            value={level}
            onChange={(val) => setLevel(val as number)}
            max={15}
            min={1}
            marks={{
              1: 1,
              2: 2,
              3: 3,
              4: 4,
              5: 5,
              6: 6,
              7: 7,
              8: 8,
              9: 9,
              10: 10,
              11: 11,
              12: 12,
              13: 13,
              14: 14,
              15: 15,
            }}
          />
        </div>

        <motion.div
          initial={isMobile ? undefined : { opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            duration: 0.4,
          }}
          exit={{ x: -window.innerWidth, opacity: 0 }}
          className="d-flex align-items-center ms-3 justify-content-end mt-1  "
        >
          <div
            onClick={() => {
              setViewType(1);
              localStorage.setItem("card-sort", "1");
            }}
            className={`sort-btn ${viewType === 1 && "active"}`}
          >
            <img width={22} height={22} src="/assets/images/icons/grid.png" />
          </div>
          <div
            onClick={() => {
              setViewType(2);
              localStorage.setItem("card-sort", "2");
            }}
            className={`ms-2 sort-btn  ${viewType === 2 && "active"}`}
          >
            <img width={22} height={22} src="/assets/images/icons/grid2x.png" />
          </div>
        </motion.div>
      </div>

      <div className="row">
        {loading &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
            <div
              key={index + "loading"}
              className="col-xl-3 col-lg-4 col-sm-6 col-12"
            >
              <TroopBox loading type={viewType} />
            </div>
          ))}
        {!loading &&
          data?.map((troop: any, index) => {
            const currentTroop = troop.playerTroops.find(
              (trp) => trp.level === level
            );
            return (
              <div key={index} className="col-xl-3 col-lg-4 col-sm-6 col-12">
                <TroopBox
                  {...troop}
                  isLearn
                  type={viewType}
                  level={level}
                  damage={ThousandsSeparator(currentTroop.damage)}
                  armor={ThousandsSeparator(currentTroop.armor)}
                  clegCost={ThousandsSeparator(
                    Math.pow(2, level - 1) * troop.clegCost
                  )}
                  ironCost={ThousandsSeparator(
                    Math.pow(2, level - 1) * troop.ironCost
                  )}
                  stoneCost={ThousandsSeparator(
                    Math.pow(2, level - 1) * troop.stoneCost
                  )}
                  magicResistance={ThousandsSeparator(
                    currentTroop.magicResistance
                  )}
                  range={ThousandsSeparator(currentTroop.range)}
                  health={currentTroop.maxHealth}
                  maxHealth={currentTroop.maxHealth}
                  abilities={
                    currentTroop.abilities
                      ? JSON.parse(currentTroop.abilities)
                      : troop.abilities
                  }
                />
              </div>
            );
          })}
      </div>
      <div className="text-white mt-5">
        <div>
          Each troop earns 10 XP if they win the battle, but if they lose the
          battle, troops will earn 5 XP. Required XP for level up to level 2 is
          100 and for next levels is previous level required XP + 50%. For
          example: Troop level 1 requires 100 XP to level up, and troop level 2
          needs 150 XP to reach to the next level.
        </div>

        <div className="mt-4">
          <h2>Merging Unit</h2>
          <p>
            You can merge 2 units with the same level to get 1 stronger unit
            with one level higher. The XP of the new unit is equivalent to the
            average of previous units. For example: if you combine two warriors
            with Level 3 and the first one has 100 XP and the second one has 150
            XP, then you will get a new warrior with level 4 and 125 XP
          </p>
          <h2>Damage and Resistance</h2>
          <p>
            Armor and Magic Resistance help units to get damaged less in
            battles.
          </p>
        </div>
        <div className="text-center mt-5">
          <img
            alt="Resistance Percent Chart"
            className="rounded "
            src="/assets/images/troop-chart.jpg"
            style={{ maxWidth: 900 }}
          />
          <div className="fs-med mt-2 text-secondary">
            How armor and magic resistance mitigate incoming magical or physical
            damage
          </div>
        </div>
        <div className="mt-4 text-med lh-lg">
          Here is how Armor affects Physical Damage:
          <br />
          Physical Resistance = 100 / ( 200 / Armor + 1.25)
          <br />
          Example: A unit with 50 Armor will get 20% less physical Damage:
          <br />
          Physical Resistance = 100 / ( 200 / 50 + 1.25) = 20%
          <br />
          Magic Resistance has the same formula:
          <br />
          Magic Resistance = 100 / ( 200 / Magic Resistance + 1.25)
        </div>
      </div>
    </div>
  );
};

export default Troops;
