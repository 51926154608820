import React from 'react'
import GrowthChallenge from '../components/Campaigns/GrowthChallenge'
import { useNavigate } from 'react-router-dom'

type Props = {}

const Growth = (props: Props) => {
    const navigate = useNavigate()
  return (
    <div>
        <GrowthChallenge open={true} onClose={()=>navigate('/home')}/>
    </div>
  )
}

export default Growth