import { useTour } from "@reactour/tour";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useRoutes, useLocation } from "react-router-dom";
import Button from "../components/Button";

interface StepsType {
  selector: string;
  content: string | React.ReactNode | any;
  action?: Function;
  actionAfter?: Function;
}




const Steps = () => {
  const navigate = useNavigate()
  const { setIsOpen, } = useTour()
  const location = useLocation()

  const homeSteps: StepsType[] = [
    {
      "selector": ".step-1",
      "content": () => <div className="d-flex align-items-center">
        <img width={"111px"} src="/assets/images/tour-2.png" />
        <div style={{ marginLeft: '10px' }}>
          This is your CLEG Mine!<br />It extracts CLEG out of your purchased lands.
        </div>
      </div>,
      action: () => navigate('/home#cleg')
    },
    {
      "selector": ".pgtr-2",
      "content": () => <> This number shows your current Mine level. </>,

    },
    {

      "selector": ".pgtr-3",
      "content": () => <> You can collect the amount of mined CLEG.<br />
        The minimum collect request is 10 CLEG!"</>,
    },
    {

      "selector": ".pgtr-3-1",

      "content": () => <>Your CLEG Mine stops working if it gets full!</>,

    },
    {

      "selector": ".pgtr-3-2",

      "content": () => <>It shows the amount of CLEG that is possible for you to extract per day.</>,
      action: () => navigate('/home#cleg')

    },
    {

      "selector": ".pgtr-3-3",

      "content": () => <> Your Mine loses 2 HP/Day. If it lose 20 HP, the Production speed falls to 95% . Repairing it would help to always have Max production rate</>,
    },
    {

      "selector": ".pgtr-3-4",
      "content": () => <> Click on the Upgrade button to see the details of upgrading and repairing costs</>,
      action: () => navigate('/home#cleg')

    },
    {
      "selector": ".step-2",
      "content": () => <div className="d-flex align-items-center">
        <img width={"111px"} src="/assets/images/tour-2.png" />
        <div style={{ marginLeft: '10px' }}>
          This is your Stone Mine!<br /> It extracts Stone out of your lands.
        </div>
      </div>,
      action: () => navigate('/home#stone')

    },
    {
      "selector": ".step-3",
      "content": () => <div className="d-flex align-items-center">
        <img width={"111px"} src="/assets/images/tour-2.png" />
        <div style={{ marginLeft: '10px' }}>
          This is your Iron Mine!<br /> It extracts Iron out of your lands.
        </div>
      </div>
    },
    {
      "selector": ".step-4",
      "content": () => <>
        Use <img width={25} src="/assets/images/icons/cleg.png" /> CLEG, <img width={25} src="/assets/images/icons/metal.png" /> Iron, and <img width={25} src="/assets/images/icons/stone.png" /> Stone to build your Barracks. Barracks allows you to train heroes and go to the adventures.</>,
      action: () => navigate('/home#barracks')
    },
    {
      "selector": ".pgtr-5",
      "content": () => <>This is our game's marketplace where you can trade NFT lands, NFT Heroes, and resources.</>,
    },
    {
      "selector": ".pgtr-6",
      "content": () => <>By sharing your Referral Link and inviting friends, you earn more <img width={25} src="/assets/images/icons/cleg.png" /> CLEG
      </>,
    },
    {
      "selector": ".pgtr-7",
      "content": () => <>All of the purchased lands will get stocked in the "My Lands" tab..
      </>,
    },
    {
      "selector": ".pgtr-8",
      "content": () => <>
        Adventure: Here is the exciting part! <br />
        Enjoy a thrilling adventure by sending your army off to find Dungeons or Treasure Islands.
      </>
    },
    {
      "selector": ".pgtr-9",
      "content": () => <> You can buy or sell NFT Genesis boxes in the proper marketplace.
      </>
    },
    {
      "selector": ".pgtr-10",
      "content": () => <>  All "Chain of Legends" Messages are shown here.
      </>
    },
    {
      "selector": ".pgtr-11-1",
      "content": () => <>Click on this yellow button whenever you needed an explanation
      </>
    },
    {
      "selector": ".pgtr-11",
      "content": () => <> The shortcut of some sections are available for you here in the sidebar.
      </>

    }
    ,
    {
      "selector": ".pgtr-12",
      "content": () => <>Cycles are one of our deflationary system that helps to maintain the value of CLEG. Read more here: <a href="https://whitepaper.chainoflegends.com/price-stability" target={"_blank"}>
        https://whitepaper.chainoflegends.com/price-stability </a>
        <div className="text-center">
          <Button size="small" className="mt-2 mx-auto fs-med px-3 mb-n2" onClick={() => setIsOpen(false)} variant="primary">
            Finish
          </Button>
        </div>

      </>

    }

  ]

  const marketSteps: StepsType[] = [
    {
      selector: '.first-step',
      content: () => <>
        This is a free market. Pay attention to the prices! They are sorted from the cheapest to the most expensive.,
      </>,
      action: () => {
        navigate('/marketplace?type=0');
      }
    },
    {
      selector: ".third-step",
      content: () => <>  You can buy free desert lands here. But, you can only buy them one at a time! <br />
        They have a 25% slower production speed than a regular land.
        They get restocked each hour!</>,
      action: () => {
        if (location?.pathname !== "/marketplace") navigate('/marketplace');
      }
    }
    ,
    {
      selector: ".mrkt-stp2",
      content: () => <> The other types of NFT lands are sorted out here in each section. Each one has a unique production boost. First lands are always the cheapest.</>,
      action: () => {
        navigate('/marketplace?id=2');
      }
    },
    {
      selector: ".mrkt-stp3",
      content: () => <> Pay attention to the cycle tag of each land. Primitive cycles have more resources within.</>,
      action: () => {
        if (location?.pathname !== "/marketplace?id=2") navigate('/marketplace?id=2');
      }
    },
    {
      selector: ".mrkt-stp4",
      content: () => <> Here you can check the price of lands. By Clicking "BUY", you own the land.</>,
      action: () => {
        if (location?.pathname !== "/marketplace?id=2") navigate('/marketplace?id=2');
      }
    },
    {
      selector: ".mrkt-stp5",
      content: () => <>  All of the land resources are shown here. Choose them based on your needs</>,
      action: () => {
        if (location?.pathname !== "/marketplace?id=2") navigate('/marketplace?type=0&id=2');
      }
    },
    {
      selector: ".mrkt-stp6",
      content: () => <> This shows the proportion of lands' price and CLEG in this land. The Lower, the more profit you get from this land</>,
      action: () => {
        if (location?.pathname !== "/marketplace?id=2") navigate('/marketplace?type=0&id=2');
      }
    },
    {
      selector: ".mrkt-stp6-2",
      content: () => <>Stone Tab, Iron tab: Trading stone & Iron with CLEG is possible by clicking on these tabs. </>,
      action: () => {
        navigate('/marketplace?type=2');
      }

    },
    {
      selector: ".mrkt-stp6-3",
      content: () => <>Pay attention to the Buy & Sell button when you decided to make a move.</>,
      action: () => {
        if (location?.pathname !== "/marketplace?type=2") navigate('/marketplace?type=2');
      }
    },
    {
      selector: ".mrkt-stp7",
      content: () => <>The troop tab lets you buy Heroes you like to have in your army and sell those you don't want.</>,
      action: () => {
        navigate('/marketplace?type=3');
      }
    },
    {
      selector: ".mrkt-stp8",
      content: () => <>All orders of Heroes are sorted out here. Click on each one of them to see all of the set orders.</>,
      action: () => {
        if (location?.pathname !== "/marketplace?type=3") navigate('/marketplace?type=3');
      }
    },
    {
      selector: ".mrkt-stp9",
      content: () => <>Here, you can select Heroes and levels you are looking for to find it sooner.</>,
      action: () => {
        if (location?.pathname !== "/marketplace?type=3") navigate('/marketplace?type=3');
      }
    },
    {
      selector: ".mrkt-stp10",
      content: () => <>If you know what you are looking for, click on this button and set your order.
        <div className="text-center">
          <Button size="small" className="mt-2 mx-auto fs-med px-3 mb-n2" onClick={() => setIsOpen(false)} variant="primary">
            Finish
          </Button>
        </div>
      </>,
      action: () => {
        if (location?.pathname !== "/marketplace?type=3") navigate('/marketplace?type=3');
      }
    }

  ]
  const walletSteps: StepsType[] = [
    {
      selector: ".prof-stp1",
      content: () => <>
        As you can see, it shows all amount of CLEG you have in your wallet.<br />
        If you have 0 CLEG, you can buy from <a href="https://www.xt.com/trade/cleg_usdt" referrerPolicy="no-referrer" target={"_blank"}> CEX </a>
        or <a href="https://jumper.exchange/exchange?toChain=56&toToken=0x4027d91eCD3140e53AE743d657549adfeEbB27AB" referrerPolicy="no-referrer" target={"_blank"}> DEX</a>
      </>,
    },
    {
      selector: ".prof-step2",
      content: () => <> "How much <img src="/assets/images/icons/cleg.png" width={25} /> CLEG do you want to deposit to your game?"<br />
        "You can type the amount you like, or hit the total button"<br />
        By clicking on the Deposit button, you will invest in the game</>,

    },
    {
      selector: ".prof-step3",
      content: () => <>The record of all of the actions you have done is available here for you.</>,

    },
    {
      selector: ".prof-step4",
      content: () => <>Your CLEG balance which is available for you to withdraw.</>,

    },
    {
      selector: ".prof-step5",
      content: () => <>Type the amount and click on the Withdraw button, it will be delivered to your wallet in the next 24 hours.
        <div className="text-center">
          <Button size="small" className="mt-2 mx-auto fs-med px-3 mb-n2" onClick={() => setIsOpen(false)} variant="primary">
            Finish
          </Button>
        </div>
      </>,

    },

  ]
  const referralSteps: StepsType[] = [
    {
      selector: ".ref-step1",
      content: () => <>
        By sharing your Referral Link and inviting friends, you earn more <img width={25} src="/assets/images/icons/cleg.png" /> CLEG
      </>,
    },
    {
      selector: ".ref-step2",
      content: () => <> It shows the amount of CLEG you earned from your invitations.</>,
    },
    {
      selector: ".ref-step3",
      content: () => <>
        Your total number of invites and the referral revenue details are shown here.
        <div className="text-center">
          <Button size="small" className="mt-2 mx-auto fs-med px-3 mb-n2" onClick={() => setIsOpen(false)} variant="primary">
            Finish
          </Button>
        </div>
      </>,
    },

  ]

  const barracksSteps: StepsType[] = [
    {
      selector: ".brk-step1",
      content: () => <>
        This number shows the current level of your Barracks
      </>,
    },
    {
      selector: ".brk-step2",
      content: () => <>
        A summary of your Barracks stats is shown here for you
      </>,
    },
    {
      selector: ".brk-step3",
      content: () => <>
        By clicking on this button, you can train your first troop. (If you are at level 1, you can only train warriors)      </>,
    },
    {
      selector: ".brk-step4",
      content: () => <>
        This number shows the level of your hero
      </>,
    },
    {
      selector: ".brk-step5",
      content: () => <>
        This number shows the experience gained through battles. If you gain enough XP, your hero gets upgraded automatically to a higher level.
      </>,
    },
    {
      selector: ".brk-step6",
      content: () => <>
        By looking at these numbers, you can strategize your battles by training enough troops.
      </>,
    },
    {
      selector: ".brk-step7",
      content: () => <>
        A shortcut to upgrading your Barracks to a higher level is right here!
      </>,
    },
    {
      selector: ".brk-step8",
      content: () => <>
        If you have 2 heroes of the same kind and level, you can merge them together to create a stronger hero.
        <div className="text-center">
          <Button size="small" className="mt-2 mx-auto fs-med px-3 mb-n2" onClick={() => setIsOpen(false)} variant="primary">
            Finish
          </Button>
        </div>
      </>,
    }


  ]
  const myLandsSteps: StepsType[] = [
    {
      selector: ".lands-stats",
      content: () => <>
        This bar shows the amount of each resources available in all of your lands.
      </>,
    },
    {
      selector: ".lands-step2",
      content: () => <>You can see your lands organized sorted out by cycles and remaining resources.</>,
    },
    {
      selector: ".lands-step3",
      content: () => <>
        If you see resources icon down here, it means that the respected Mine is currently mining that land.</>,
    },
    {
      selector: ".lands-step4",
      content: () => <>
        Sell Order: You can place a sell order for any type of land at any preferred price by clicking on this button.
        <div className="text-center">
          <Button size="small" className="mt-2 mx-auto fs-med px-3 mb-n2" onClick={() => setIsOpen(false)} variant="primary">
            Finish
          </Button>
        </div>
      </>,
    },


  ]
  const adventrueSelectSteps: StepsType[] = [
    // {
    //   selector: ".adv-step1",
    //   content: () => <>
    //     Creepy Dungeons are sorted out here!
    //   </>,
    // },
    {
      selector: ".adv-step2",
      content: () => <>
        There are various levels of difficulty from 1 to 128. Each Dungeon is twice hard as the previous one.
      </>,
    },
    {
      selector: ".adv-step3",
      content: () => <>
        You'll gain perks of war. Of course, if you win! Each Dungeon's rewards is twice more than the previous one.
      </>,
    },
    {
      selector: ".adv-step4",
      content: () => <>
        There's a defined chance to capture enemy troops in case of winning. Make sure to always have free spots in your Barracks
      </>,
    },
    {
      selector: ".adv-step5",
      content: () => <>
        It's at least a 2 day quest to find a Dungeon, fight the battle, and return to Barracks. It may take longer!
      </>,
    },
    {
      selector: ".adv-step6",
      content: () => <>
        If you have chosen the proper Dungeon, click on the "Select" button.
        <div className="text-center">
          <Button size="small" className="mt-2 mx-auto fs-med px-3 mb-n2" onClick={() => setIsOpen(false)} variant="primary">
            Finish
          </Button>
        </div>
      </>,
    },
  ]

  const PreFightSteps: StepsType[] = [
    // {
    //   selector: ".adv-step1",
    //   content: () => <>
    //     Creepy Dungeons are sorted out here!
    //   </>,
    // },
    {
      selector: ".pf-step1",
      content: () => <>
        Dungeons are the enemy territory. Be careful!
      </>,
    },
    {
      selector: ".pf-step2",
      content: () => <>
        Now strategize and set the best formation for your troops based on the chosen difficulty.
      </>,
    },
    {
      selector: ".pf-step3",
      content: () => <>
        Simply click on each troop and drag him to the right spot on the battlefield.
      </>,
    },
    {
      selector: ".pf-step4",
      content: () => <>
        Or tap on the Hero and put him anywhere on your side by selecting the proper spot.
        <div className="text-center">
          <Button size="small" className="mt-2 mx-auto fs-med px-3 mb-n2" onClick={() => setIsOpen(false)} variant="primary">
            Finish
          </Button>
        </div>
      </>,
    }
  ]
  function getStep() {
    switch (location.pathname) {
      case '/home': return homeSteps;
      case '/marketplace': return marketSteps;
      case '/profile': return walletSteps;
      case '/referrals': return referralSteps;
      case '/barracks': return barracksSteps;
      case '/battle/order': return PreFightSteps;
      case '/lands': return myLandsSteps;
      case '/adventure/new': return adventrueSelectSteps;

      default: return [];
    }
  }
  const [steps, setSteps] = useState<StepsType[]>(getStep())




  useEffect(() => {
    setSteps(getStep())
  }, [location.pathname])



  return steps
}

export default Steps