import React from 'react'
import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect';
type Props = {
    item: string[];
    icon?: string;
    className?: string;
    delay?:number;
    style?:React.CSSProperties
}

const TipList = (props: Props) => {
    const delay=props.delay||0
    return (
        <div  className={'row flex-column  ' + props.className} style={props.style}>

            {props.item?.map((item: any, index) => (
                <motion.div key={index + "tip"} className="col-12 py-2" initial={isMobile?undefined:{
                    x: -150,
                    opacity: 0
                }}
                    animate={{
                        x: 0,
                        opacity: 1
                    }}
                    exit={{
                        x: -150,
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.3,
                        delay: index * 0.1 + delay
                    }}
                >
                    <img width={30} height="30px" draggable={false} alt="shield"
                        src={props.icon ? props.icon : "/assets/images/icons/icon-shield.png"} />
                    &nbsp;
                    {item}
                </motion.div>
            ))}

        </div>
    )
}

export default TipList