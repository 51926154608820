import { Unit } from "../types";

function calculateManhattanDistance(unit1: Unit, unit2: Unit): number {
  const dx = Math.abs(unit1.x - unit2.x);
  const dy = Math.abs(unit1.y - unit2.y);
  return dx + dy;
}

// Find the nearest target based on Manhattan distance
export function findNearestTarget(currentUnit: Unit, potentialTargets: Unit[]): Unit | null {
  
  if (potentialTargets.length === 0) {
    return null; // No targets available
  }

  let nearestTarget = potentialTargets[0];
  let nearestDistance = calculateManhattanDistance(currentUnit, nearestTarget);

  for (let i = 1; i < potentialTargets.length; i++) {
    const target = potentialTargets[i];
    const distance = calculateManhattanDistance(currentUnit, target);

    if (distance < nearestDistance) {
      nearestTarget = target;
      nearestDistance = distance;
    }
  }

  return nearestTarget;
}