import React, { useEffect, useState } from 'react'
import { Collapse, Container, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import BottomBar from '../components/BottomBar'
import Navbar from '../components/Navbar'
import { removeUserInfo, removeUserToken, setUserInfo } from '../redux/user/action'
import Network from '../utils/Network'
import { motion } from 'framer-motion'
import Helmet from 'react-helmet'
import OneSignal from 'react-onesignal';
// import component 👇
import Drawer from 'react-modern-drawer'
import { CopyToClipboard } from 'react-copy-to-clipboard';
//import styles 👇
import 'react-modern-drawer/dist/index.css'
import MidBox from '../components/Boxes/MidBox'
import { isMobile } from 'react-device-detect'
import useToast from '../hooks/useToast'
import SettingsDialog from '../components/SettingsDialog'
import ErrorBoundary from './ErrorBoundary'
import Dialog from '../components/Dialog'
import PlainNavBar from '../components/PlainNavbar'
import { tourDefaultState } from '../redux/tour/reducer'
import useSteps from '../utils/Steps'
import { useTour } from '@reactour/tour'
import { SetTour } from '../redux/tour/action'
import { clearScoutsData } from '../redux/scout/action'
import { clearChannels, clearMessages } from '../redux/chat/actions'

type Props = {
    transparent?: boolean
}

const PlainLayout = ({ transparent }: Props) => {
    const userToken = useSelector((state: any) => state.userToken)
    const userInfo = useSelector((state: any) => state.userInfo)
    const [settingsDialog, setSettingsDialog] = useState(false)
    const tourState: typeof tourDefaultState = useSelector((state: any) => state.tour)
    const location = useLocation()
    const axios = new Network()
    const navigate = useNavigate()
    const openToast = useToast()
    const dispatch = useDispatch()
    // const [isHide, setIsHide] = useState(Boolean(localStorage.getItem('dao-collapse')))
    const [isHide, setIsHide] = useState(Boolean(localStorage.getItem('notif-collapse')))
    const [isOpen, setIsOpen] = useState(false)
    const [voteDialog, setVoteDialog] = useState(false)
    const [dontShow, setDontShow] = useState(false)
    const steps = useSteps()
    const { setIsOpen: setTourState, setSteps, setCurrentStep } = useTour()


    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    useEffect(() => {
        setCurrentStep(0)
        setSteps(steps)
    }, [location.pathname])

    const handleLogout = async () => {
        dispatch(removeUserInfo())
        dispatch(removeUserToken())
        dispatch(clearMessages());
        localStorage.removeItem('user.token')
        dispatch(clearScoutsData())
        // if (localStorage.getItem('connector') === "walletconnect") connector.killSession()
        navigate("/login"+`?return=${location.pathname}`);
    }

    useEffect(() => {
        if (window.location.hash?.includes('settings')) {
            setSettingsDialog(true)
        }
    }, [window.location.hash])

    const handleOpenTour = () => {
        if (location.pathname === "/battle/order") {
            if (tourState.pages.preFight) return;
            setTourState(true);
            dispatch(SetTour({
                ...tourState,
                isStared: true,
                pages: { ...tourState.pages, preFight: true }
            }))
        };

    }

    useEffect(() => {
        if (!tourState.isStared) return
        if (tourState.isDone) return
        setTimeout(() => {
            handleOpenTour()
        }, 2000);
    }, [location.pathname])

    const getData = async () => {
        const response = await axios.CallApi({
            url: "/player",
            method: "get"
        })
        if (response.ok) {
            dispatch(setUserInfo(response.data))
            if (response.data.State === 2 || response.data.State === 3) handleLogout()
        }
    }
    useEffect(() => {
        if (!localStorage.getItem('user.token')) {
            return navigate(`/login?return=${location.pathname}`)
        }
        getData()
        if (!document.location.pathname.includes('dao')) setIsHide(Boolean(localStorage.getItem('notif-collapse')))
    }, [])

    const setUserId = async () => {
        const isSubscribed = await OneSignal.isPushNotificationsEnabled()
        if (isSubscribed) {
            const userID = await OneSignal.getExternalUserId()
            await OneSignal.setExternalUserId(userInfo.ID)
        }
    }

    useEffect(() => {
        if (userInfo) setUserId()
    }, [userInfo])

    useEffect(() => {
        // if (connector.connected && localStorage.getItem('connector') === "walletconnect") {
        //     connector.on("disconnect", (error, payload) => {
        //         if (error) {
        //             console.log(error)
        //         }
        //         handleLogout()
        //         // Delete connector
        //     });
        // }
        // if (localStorage.getItem('connector') === "walletconnect" && !connector.connected) {
        //     handleLogout()
        // }
    }, [])

    const drawerActions = [
        {
            image: "/assets/images/icons/referral.svg",
            title: "Referral Program",
            link: "/referrals"
        },
        {
            image: "/assets/images/icons/inventory.svg",
            title: "Investory",
            link: "/profile"
        },

        {
            image: "/assets/images/icons/dao.svg",
            title: "Dao",
            link: "/dao"
        },
        {
            image: "/assets/images/icons/tx.svg",
            title: "Transactions",
            link: "/transaction-history"
        },
        {
            image: "/assets/images/icons/withdraw-tx.svg",
            title: "Withdraw History",
            link: "/withdraw-history"
        },
        {
            image: "/assets/images/icons/settings.svg",
            title: "Settings",
            link: "#settings",
        },

    ]

    return (
        <div className='main-layout'>
            <Helmet>
                    
            </Helmet>
            <div>
                <PlainNavBar transparent={transparent} />
                {/* <Navbar handleLogout={handleLogout} toggleDrawer={toggleDrawer} /> */}
                <div className='text-white '>
                    <ErrorBoundary openToast={openToast}>
                        <Outlet />
                    </ErrorBoundary>
                </div>

            </div>
            {/* <BottomBar /> */}
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                size={isMobile ? 300 : 350}

                className='bg-transparent'
            >
                <MidBox style={{ minHeight: '100%' }} title="d" noTitle>
                    <button onClick={toggleDrawer} className='button hard btn-close'>
                    </button>

                    <div style={{ minHeight: '79vh' }}>
                        <div className="d-flex justify-content-between align-items-center  rounded-10 px-4 py-2 mb-2">
                            <div className="fs-big dark-yellow ">
                                {userInfo?.walletAddress?.slice(0, 4)}...{userInfo?.walletAddress?.slice(userInfo?.walletAddress?.length - 4, userInfo?.walletAddress?.length)}
                            </div>
                            <div className="d-flex  align-items-center justify-content-center">
                                <CopyToClipboard text={userInfo?.walletAddress}
                                    onCopy={() => openToast({ ok: true, data: { message: 'Wallet Address copied to clipboard' } })}
                                >
                                    <img
                                        width={60}
                                        className="button"
                                        src="/assets/images/buttons/copy-btn.png" />
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="mt-2 mt-md-2 dark-bg rounded-10 px-4 py-2 mb-4">
                            <div className="fs-small">
                                Balance
                            </div>
                            <div className="fs-big dark-yellow bold">
                                {userInfo?.totalCleg} Cleg
                            </div>
                        </div>

                        <hr />
                        <div className='mt-2 d-flex flex-column' >
                            {drawerActions.map((item, index) => (
                                <Link onClick={() => toggleDrawer()} to={item.link} key={index + "oxw"} className='d-flex mt-2 row-item align-items-center'>
                                    <img src={item.image} style={{ width: 26, height: 26 }} />
                                    <a className='ms-2  dark-yellow'>
                                        {item.title}
                                    </a>
                                </Link>
                            ))}
                        </div>
                        <hr />
                        <div onClick={() => {
                            toggleDrawer()
                            handleLogout()
                        }} className='d-flex mt-2 pointer w-100 text-center   align-items-center'>
                            <a className=' text-center w-100 text-danger'>
                                Log Out
                            </a>
                        </div>
                    </div>
                </MidBox>
            </Drawer>

            <SettingsDialog
                open={settingsDialog}
                onClose={() => {
                    setSettingsDialog(false)
                    // console.log(window.location.pathname)
                    navigate(window.location.pathname)
                }}

            />
        </div>
    )
}

export default PlainLayout