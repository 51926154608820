import React from "react";
import Network, { ProfileURL } from "../../utils/Network";
import { motion } from "framer-motion";
import truncateString from "../../utils/truncate-string";
import ThousandsSeparator from "../../utils/thousands-separator";
import Skeleton from "react-loading-skeleton";
import { Collapse } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { Link } from "react-router-dom";
type Props = {};

const tabs = [
  {
    id: "ranking",
    title: "Ranking",
    icon: "/assets/images/icons/crown.png",
    subTabs: [
      {
        id: 1,
        title: "All Time",
      },
      {
        id: 2,
        title: "Month",
      },
    ],
  },
  {
    id: "social",
    title: "Society Builders",
    icon: "/assets/images/icons/rank-flag.png",
    subTabs: [
      {
        id: 3,
        title: "All Time",
      },
      {
        id: 4,
        title: "Month",
      },
    ],
  },
  {
    id: "war",
    title: "War Lords",
    icon: "/assets/images/icons/glory.png",
    subTabs: [
      {
        id: 5,
        title: "Impact",
      },
      {
        id: 6,
        title: "Invades",
      },
      {
        id: 7,
        title: "Defenses",
      },
      {
        id: 8,
        title: "Raiders",
      },
    ],
  },
];

const Ranking = (props: Props) => {
  const [activeTab, setActiveTab] = React.useState<
    "war" | "social" | "ranking" | String
  >("ranking");
  const [activeSubTab, setActiveSubTab] = React.useState<number>(1);
  const [debouncedSubTab] = useDebounce(activeSubTab, 1000);
  const [data, setData] = React.useState<any>({});
  const userInfo = useSelector((state: any) => state?.userInfo);
  const [loading, setLoading] = React.useState<boolean>(false);
  const axios = new Network();
  const [paginations, setPaginations] = React.useState({ total: 1, page: 1 });
  const handleGetRankings = async (page = 1) => {
    // if(page===paginations.page && debouncedSubTab===data?.id) return
    setLoading(true);
    const res = await axios.CallApi({
      url: "/ranking" + `?rankingType=${debouncedSubTab}&page=${page}`,
      method: "get",
    });
    setData({ ...res?.data, id: debouncedSubTab });
    setPaginations({
      page,
      total: Math.floor(res?.data?.totalCount / 50),
    });
    setLoading(false);
  };
  React.useEffect(() => {
    handleGetRankings();
  }, [debouncedSubTab]);
  const activeTabObj = React.useMemo(
    () => tabs.find((item) => item.id === activeTab),
    [activeTab]
  );
  function changeTab(tab: {
    id: string;
    title: string;
    icon: string;
    subTabs: {
      id: number;
      title: string;
    }[];
  }) {
    if (activeTab === tab.id) return;
    setActiveTab(tab.id);
    setActiveSubTab(tab.subTabs[0]?.id);
  }
  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 0,
          left: 0,
          top: 0,
          height: "100%",
          background: "rgba(0,0,0,0.44)",
        }}
      />
      <div className="p-relative mt-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ position: "sticky", top: 10 }}
        >
          <div className="d-flex p-relative tab-container">
            {tabs.map((tab, i) => (
              <div
                onClick={() => changeTab(tab)}
                style={{
                  border: tabs.length === i + 1 ? "none" : undefined,
                }}
                className={
                  "d-flex flex-1 py-3 align-items-center justify-content-center flex-column gap-2 tab-col " +
                  (activeTab === tab.id ? "tab-col-active" : "")
                }
              >
                <img style={{ maxWidth: 70 }} width={"100%"} src={tab.icon} />
                <div className="fs-med">{tab.title}</div>
              </div>
            ))}
          </div>
          <div style={{ marginTop: -20 }} className="d-flex tab-container">
            {activeTabObj?.subTabs?.map((subTab, i) => (
              <div
                onClick={() => setActiveSubTab(subTab.id)}
                style={{
                  border:
                    activeTabObj?.subTabs?.length === i + 1
                      ? "none"
                      : undefined,
                }}
                className={
                  "pt-4  tab-col flex-1 " +
                  (activeSubTab === subTab.id ? "tab-col-active" : "")
                }
              >
                {subTab.title}
              </div>
            ))}
          </div>
        </motion.div>

        <motion.div
        transition={{duration:.4}}
          initial={{ opacity: 0 ,y:500}}
          animate={{ opacity: 1 ,y:0}}
          exit={{ opacity: 0 ,y:500}}
          style={{ position: "sticky", top: 10 }}>
        <Collapse in={Boolean(data.description)} className="">
          <div className="text-white mt-3  fs-med text-center ">
            {data.description}
          </div>
        </Collapse>

        <div
          style={{ maxWidth: "700px" }}
          className="mt-4 bg-paper px-2 px-md-4 py-2 mx-auto rounded overflow-hidden"
        >
          <div>
            <table className="table table-borderless m-0 text-white   ">
              <thead className="border-bottom border-secondary ">
                <tr>
                  <th scope="col">Player</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody>
                {loading &&
                  [...Array(52)].map((d, i) => (
                    <tr
                      style={{
                        background: i % 2 === 0 ? undefined : "rgba(0,0,0,0.1)",
                      }}
                    >
                      <td>
                        <div className="d-flex align-items-center ">
                          <div>
                            <Skeleton circle width={60} height={60} />
                          </div>
                          <div className="fs-med ps-2">
                            <div
                              style={{ maxWidth: 120 }}
                              className="text-truncate"
                            >
                              <Skeleton width={90} />
                            </div>

                            <div className="mt-1 fs-small text-secondary">
                              <Skeleton width={60} />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Skeleton width={70} />
                      </td>
                    </tr>
                  ))}
                {!loading &&
                  data?.data?.map((d, i) => (
                    <tr
                      style={{
                        background:
                          d.playerId === userInfo?.id
                            ? "#ffd94717"
                            : i % 2 === 0
                            ? undefined
                            : "rgba(0,0,0,0.1)",
                      }}
                      className={
                        d.playerId === userInfo?.id ? "dark-orange" : ""
                      }
                    >
                      <td>
                        <div className="d-flex align-items-center ">
                          <Link
                            to={"/profile/" + d.playerId}
                            className="p-relative"
                          >
                            {d?.photoFile ? (
                              <div className="p-relative">
                                <div
                                  style={{
                                    backgroundImage:
                                      "url(/assets/images/avatar-bg.png)",
                                    width: 60,
                                    height: 60,
                                  }}
                                  className={"main-avatar"}
                                />

                                <img
                                  src={`${ProfileURL}/${d?.playerId}/${d?.photoFile}`}
                                  width={"70%"}
                                  height={"70%"}
                                  style={{
                                    position: "absolute",
                                    borderRadius: "50%",
                                    top: "12%",
                                    right: 0,
                                    left: "12%",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{ width: 60, height: 60 }}
                                className={"main-avatar"}
                              />
                            )}
                            {d.rank <= 3 ? (
                              <span
                                style={{
                                  position: "absolute",
                                  zIndex: 10,
                                  bottom: -12,
                                  right: 0,
                                }}
                                className="fs-big"
                              >
                                <img
                                  width={25}
                                  src={`/assets/images/icons/${
                                    i === 0
                                      ? "first"
                                      : i === 1
                                      ? "second"
                                      : "third"
                                  }-place.png`}
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </Link>
                          <div className="fs-med ps-3">
                            {d.rank > 3 && <div>#{d.rank}</div>}
                            <Link
                              to={"/profile/" + d.playerId}
                              style={{ maxWidth: 140 }}
                              className="text-truncate text-info"
                            >
                              {d.username && !d.username?.startsWith("0x")
                                ? d.username
                                : "Player#" + d.playerId}
                            </Link>
                            {d.walletaddress?.startsWith("0x") && (
                              <div className="mt-1 fs-small text-secondary">
                                {truncateString(d.walletaddress)}
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>{ThousandsSeparator(d.score)}</td>
                    </tr>
                  ))}
                {!loading &&
                  data?.data?.[data?.data?.length - 1]?.rank < data?.myRank && (
                    <>
                      <tr className="dark-yellow">
                        <td>
                          .<br />
                          .<br />
                          .<br />
                        </td>
                      </tr>
                      <tr style={{ background: "#ffd94717" }}>
                        <td>
                          <div className="d-flex align-items-center ">
                          <Link to={"/profile/"+userInfo.id} className="p-relative">
                              {userInfo?.photoFile ? (
                                <div className="p-relative">
                                  <div
                                    style={{
                                      backgroundImage:
                                        "url(/assets/images/avatar-bg.png)",
                                      width: 60,
                                      height: 60,
                                    }}
                                    className={"main-avatar"}
                                  />

                                  <img
                                    src={`${ProfileURL}/${userInfo?.id}/${userInfo?.photoFile}`}
                                    width={"70%"}
                                    height={"70%"}
                                    style={{
                                      position: "absolute",
                                      borderRadius: "50%",
                                      top: "12%",
                                      right: 0,
                                      left: "12%",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ width: 60, height: 60 }}
                                  className={"main-avatar"}
                                />
                              )}
                            </Link>
                            <div className="fs-med dark-orange ps-3">
                              <div>#{data.myRank}</div>
                              <Link to={"/profile/"+userInfo.id} 
                                style={{ maxWidth: 140 }}
                                className="text-truncate text-info bold"
                              >
                                {userInfo?.username &&
                                !userInfo?.username?.startsWith("0x")
                                  ? userInfo?.username
                                  : "Player#" + userInfo.id}
                              </Link>
                              {userInfo?.walletAddress?.startsWith("0x") && (
                                <div className="mt-1 fs-small text-secondary">
                                  {truncateString(userInfo?.walletAddress)}
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="dark-orange">
                          {ThousandsSeparator(data?.myScore)}
                        </td>
                      </tr>
                    </>
                  )}
              </tbody>
            </table>
            <ReactPaginate
              key={activeSubTab}
              className="pagination pagination-sm mt-2  justify-content-center"
              activeClassName="active-pg fs-tiny"
              pageClassName="page-item fs-tiny"
              pageLinkClassName="page-link"
              breakClassName="page-item"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              previousClassName="page-item"
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              initialPage={0}
              onClick={(e) =>
                handleGetRankings((e.nextSelectedPage ?? e.selected) + 1)
              }
              // onPageChange={(e) => handleGetRankings(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={paginations.total}
              // renderOnZeroPageCount={null}
            />
          </div>
        </div>
          </motion.div>
      </div>
    </div>
  );
};

export default Ranking;
