import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./style.css";
import useCamp from "../../hooks/useCamp";
import LevelBadge from "../../../components/LevelBadge";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewTypeSwitcher from "../../../components/ViewTypeSwitcher";
import Slider from "rc-slider";
import Button from "../../../components/Button";
import TroopItemsDialog from "../../../components/TroopItemsDialog";
import {
  PlayerTroopType,
  TroopItemsDialogStateType,
} from "../../../types/troop";
import { ItemBonusStatEnum, PlayerItemType } from "../../../types/items";
import TroopBox from "../../../components/Boxes/TroopBox";
import ThousandsSeparator from "../../../utils/thousands-separator";
import { CustomIcon } from "../../../components/Icons";
import { roundFloat } from "../../../utils/round-float";
import { Link } from "react-router-dom";
import Dialog from "../../../components/Dialog";
import { Assets } from "../../../constants/assets";
import { MoveTroopType } from "../../types/enum";
import Network, { BattleURL } from "../../../utils/Network";
import { CampInfo } from "../../types";
interface ConfirmDialogType {
  open: boolean;
  submit?: () => void;
  id?: number;
  title: string;
  msg: string;
}
const Camp = (props: any) => {
  const { fetchTroops, playerTroops, isLoading, isMoveLoading, moveTroop } =
    useCamp();
  const [barrackInfo, setBarracksInfo] = useState<any>();
  const [campInfo, setCampInfo] = useState<CampInfo>();
  const axios = new Network();
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogType>({
    open: false,
    title: "",
    msg: "",
  });
  const [viewType, setViewType] = useState(
    +localStorage.getItem("card-sort")! || 1
  );
  const [troopItemsDialog, setTroopItemsDialog] =
    useState<TroopItemsDialogStateType>({
      open: false,
      troop: undefined,
    });

  const handleGetCampInfo = async () => {
    const res = await axios.CallApi({
      customHost: BattleURL,
      url: "/api/battle/arena/camp-info",
      method: "GET",
    });
    if (res.ok) {
      setCampInfo(res.data);
    }
  };

  const handleGetBarracks = async () => {
    const response = await axios.CallApi({
      url: "/building/info/6",
      method: "get",
    });
    if (response.ok) {
      setBarracksInfo(response?.data);
    }
  };
  useEffect(() => {
    fetchTroops();
    handleGetBarracks()
    handleGetCampInfo();
  }, []);

  function getDetailedStat(
    items: PlayerItemType[],
    value,
    bonusStat: ItemBonusStatEnum
  ) {
    const bonusValue = !barrackInfo?.bonusStats
      ? 0
      : (value * barrackInfo?.bonusStats) / 100;
    let itemValue = 0;
    const relatedItem = items?.filter(
      (item) => item.item.bonusStat === bonusStat
    );
    if (relatedItem && relatedItem?.length > 0) {
      for (let key in relatedItem) {
        itemValue += relatedItem[key].bonusValue;
      }
    }
    return {
      value: ThousandsSeparator(value),
      bonusValue: bonusValue,
      itemValue: itemValue,
      total: ThousandsSeparator(value + bonusValue + itemValue),
    };
  }

  function handleOpenItemDialog(troop: PlayerTroopType) {
    setTroopItemsDialog({
      open: true,
      troop,
    });
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="h-100 min-vh-100 w-100  d-flex flex-column "
    >
      <div className="d-flex w-100 flex-row align-items-center justify-content-center flex-wrap justify-content-md-between">
        <div>
          <div className="text-center d-flex align-items-center">
            <h1 className="dark-yellow text-shadow">Arena Camp</h1>{" "}
            <LevelBadge
              style={{ width: 50, height: 50 }}
              level={campInfo?.arenaLevel||0}
              className="position-static bold d-flex align-items-center justify-content-center text-white fs-big"
            />
          </div>
          <div className="fs-md dark-yellow">
            Capacity:{campInfo?.troopsCount}/{campInfo?.arenaCampCapacity}
          </div>
          <div className="fs-md dark-yellow">
            <OverlayTrigger
              placement="bottom"
              flip
              overlay={
                <Tooltip className="fs-tiny" {...props}>
                  Will add to Troops' Damage, Armor, Magic Resistance, and
                  Healing Power.
                </Tooltip>
              }
              delay={{ show: 100, hide: 200 }}
            >
              <span>
                Bonus Stats{" "}
                <img
                  width={15}
                  height={15}
                  src={"/assets/images/icons/info.svg"}
                />{" "}
                : {barrackInfo?.bonusStats}%
              </span>
            </OverlayTrigger>{" "}
          </div>
        </div>
        <div className="my-4 my-md-0">
          {/* <div className="bold dark-yellow text-center" >Level : 
            
            {campInfo?.arenaLevel}</div> */}
          <Slider
            min={1}
            max={900}
            value={(campInfo?.troopsValue || 0) / 1000}
            className="camp-slider"
            marks={{ 1: "0", 100: "100K", 300: "300K", 900: "900K" }}
          />
        </div>
        <div className="d-flex flex-column gap-2  mt-3">
          <Link to="/barracks">
            <Button variant="default" className="bg-100 ">
              Open Barracks
            </Button>
          </Link>
        </div>
      </div>
      <ViewTypeSwitcher viewType={viewType} setViewType={setViewType} />
      <div className="h-100  mt-5  ">
        {!isLoading && playerTroops.length === 0 && (
          <div className=" mt-5 d-flex flex-column align-items-center justify-content-center gap-2">
            <div className="text-center text-white">
              You don't have any Troops here! You can move your troops from the
              Barracks.
            </div>
            <Link to="/barracks">
              <Button variant="wide" className="">
                Move from Barracks
              </Button>
            </Link>
          </div>
        )}
        <div className="row ">
          {!isLoading &&
            playerTroops?.map((troop: PlayerTroopType) => {
              const damageStats = getDetailedStat(
                troop.items!,
                troop.damage,
                ItemBonusStatEnum.Damage
              );
              const armorStats = getDetailedStat(
                troop.items!,
                troop.armor,
                ItemBonusStatEnum.Armor
              );
              const magicStats = getDetailedStat(
                troop.items!,
                troop.magicResistance,
                ItemBonusStatEnum.MagicResistance
              );
              const rangeStats = getDetailedStat(
                troop.items!,
                troop.range,
                ItemBonusStatEnum.Range
              );
              const healthStats = getDetailedStat(
                troop.items!,
                troop.health,
                ItemBonusStatEnum.Health
              );
              let discountPercentage: boolean | string | number = false;
              if (
                troop.clegUpgradeCost &&
                troop.clegLastUpgradeCost &&
                troop.clegUpgradeCost !== troop.clegLastUpgradeCost
              ) {
                discountPercentage = roundFloat(
                  100 -
                    (troop.clegLastUpgradeCost * 100) / troop.clegUpgradeCost,
                  0,
                  2
                );
              }

              return (
                <div className="col-sm-6 col-12 col-lg-4 col-xl-3">
                  <TroopBox
                    key={troop?.id}
                    {...troop}
                    type={viewType}
                    // leverage={troop.troopId===7?1.5:undefined}
                    refresh={() => fetchTroops()}
                    troop={troop?.troop}
                    bonus={barrackInfo?.bonusStats}
                    damage={
                      <div>
                        {damageStats.total}
                        <OverlayTrigger
                          trigger={"click"}
                          rootClose
                          overlay={
                            <Tooltip className="fs-tiny">
                              <b>{damageStats.value}</b> Damage
                              {+damageStats.bonusValue > 0 && (
                                <>
                                  <b className="text-info">
                                    +{ThousandsSeparator(damageStats.bonusValue)}
                                  </b>{" "}
                                  Bonus
                                </>
                              )}
                              {+damageStats.itemValue > 0 && (
                                <>
                                  <b className="text-info">
                                    +{ThousandsSeparator(damageStats.itemValue)}
                                  </b>{" "}
                                  Item Damage
                                </>
                              )}
                            </Tooltip>
                          }
                        >
                          <span>
                            <CustomIcon
                              style={{ width: 17, height: 17, marginBottom: 1 }}
                              className="pointer ms-1 "
                              icon="info"
                            />
                          </span>
                        </OverlayTrigger>
                      </div>
                    }
                    healthBonus={healthStats.itemValue}
                    armor={
                      <>
                        {armorStats.total}
                        <OverlayTrigger
                          trigger={"click"}
                          rootClose
                          overlay={
                            <Tooltip className="fs-tiny">
                              <b>{armorStats.value}</b> Armor
                              {+armorStats.bonusValue > 0 && (
                                <>
                                  <b className="text-info">
                                    +{ThousandsSeparator(armorStats.bonusValue)}
                                  </b>{" "}
                                  Bonus
                                </>
                              )}
                              {+armorStats.itemValue > 0 && (
                                <>
                                  <b className="text-info">
                                    +{ThousandsSeparator(armorStats.itemValue)}
                                  </b>{" "}
                                  Item Armor
                                </>
                              )}
                            </Tooltip>
                          }
                        >
                          <span>
                            <CustomIcon
                              style={{ width: 17, height: 17, marginBottom: 1 }}
                              className="pointer ms-1 "
                              icon="info"
                            />
                          </span>
                        </OverlayTrigger>
                      </>
                    }
                    magicResistance={
                      <>
                        {magicStats.total}
                        <OverlayTrigger
                          trigger={"click"}
                          rootClose
                          overlay={
                            <Tooltip className="fs-tiny">
                              <b>{magicStats.value}</b> Magic Resistance
                              {+magicStats.bonusValue > 0 && (
                                <>
                                  <b className="text-info">
                                    +{ThousandsSeparator(magicStats.bonusValue)}
                                  </b>{" "}
                                  Bonus
                                </>
                              )}
                              {+magicStats.itemValue > 0 && (
                                <>
                                  <b className="text-info">
                                    +{ThousandsSeparator(magicStats.itemValue)}
                                  </b>{" "}
                                  Item Magic Resistance
                                </>
                              )}
                            </Tooltip>
                          }
                        >
                          <span>
                            <CustomIcon
                              style={{ width: 17, height: 17, marginBottom: 1 }}
                              className="pointer ms-1 "
                              icon="info"
                            />
                          </span>
                        </OverlayTrigger>
                      </>
                    }
                    range={
                      <>
                        {troop.range}
                        {rangeStats.itemValue > 0 && (
                          <span className="text-info">
                            +{rangeStats.itemValue}
                          </span>
                        )}
                      </>
                    }
                    abilities={troop?.abilities && JSON.parse(troop?.abilities)}
                    action={
                      <>
                        {viewType === 2 && (
                          <div className="dark-yellow text-shadow">
                            XP {troop.xp}/{troop.maxXp}
                          </div>
                        )}

                        <>
                          <OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="top"
                            overlay={
                              <div className="overlay-actions text-white">
                                <motion.div
                                  initial={{
                                    y: 100,
                                    opacity: 0,
                                  }}
                                  animate={{ y: 0, opacity: 1 }}
                                  onClick={() => handleOpenItemDialog(troop)}
                                  transition={{ delay: 0 }}
                                  className="d-flex bt-1  px-2 py-1 justify-content-start pointer align-items-center"
                                >
                                  <img
                                    width={25}
                                    className="me-3"
                                    src="/assets/images/icons/items.png"
                                  />{" "}
                                  Items
                                </motion.div>
                                <motion.div
                                  initial={{
                                    y: 100,
                                    opacity: 0,
                                  }}
                                  animate={{ y: 0, opacity: 1 }}
                                  onClick={() =>
                                    setConfirmDialog({
                                      open: true,
                                      submit: () =>
                                        moveTroop(
                                          troop.id,
                                          MoveTroopType.BARRACKS
                                        ).then((res) => {
                                          if (!res.ok) return;
                                          setConfirmDialog((prev) => ({
                                            ...prev,
                                            open: false,
                                          }));
                                          fetchTroops();
                                        }),
                                      msg: `Are you sure to move ${troop?.troop?.name} to barracks?`,
                                      title: "Are you Sure?",
                                    })
                                  }
                                  transition={{ delay: 0.1 }}
                                  className="d-flex bt-1  px-2 py-1 justify-content-start pointer align-items-center"
                                >
                                  <img
                                    width={20}
                                    className="me-3"
                                    src={Assets.icons.barracks}
                                  />{" "}
                                  Move To Barracks
                                </motion.div>
                              </div>
                            }
                          >
                            <div
                              className={viewType === 2 ? "ms-auto" : "mx-auto"}
                            >
                              <Button
                                disabled={troop.state === 4}
                                className="ms-auto p-relative px-0 bg-100"
                                variant="primary"
                              >
                                <img
                                  onClick={(e) =>
                                    troop.state === 4 && e.stopPropagation()
                                  }
                                  src="/assets/images/icons/menu.png"
                                  width={20}
                                />
                                Options
                              </Button>
                            </div>
                          </OverlayTrigger>
                        </>
                      </>
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>

      {troopItemsDialog.open && (
        <TroopItemsDialog
          onRefresh={() => {
            fetchTroops();
          }}
          open={troopItemsDialog.open}
          troop={troopItemsDialog.troop}
          onClose={() => {
            setTroopItemsDialog((prev) => ({ ...prev, open: false }));
            fetchTroops();
          }}
        />
      )}

      <Dialog
        loading={Boolean(isMoveLoading)}
        title={confirmDialog?.title}
        closeLabel="No"
        submitLabel="Yes"
        onSubmit={confirmDialog?.submit}
        open={confirmDialog?.open}
        onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
      >
        {confirmDialog?.msg}
      </Dialog>
    </motion.div>
  );
};

export default Camp;
