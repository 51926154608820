import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MidBox from '../components/Boxes/MidBox'
import { roundFloat } from '../utils/round-float'
import Network from '../utils/Network'
import { motion } from 'framer-motion'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import TipList from '../components/TipList'
import Button from '../components/Button'
import { isMobile } from 'react-device-detect'
import Dialog from '../components/Dialog'
import useToast from '../hooks/useToast'
import { Form, Pagination } from 'react-bootstrap'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

type Props = {}
export const WithdrawStates = {
    1: "Pending",
    2: "Transfered",
    3: "Transfered",
    4: "Rejected",
}


const WithdrawHistory = (props: Props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [paginations, setPaginations] = useState({ page: 1, count: 10, total: 1 })
    const [markLoading, setMarkLoading] = useState(false)
    const userInfo = useSelector((state: any) => state.userInfo)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [state, setState] = useState(0)
    const [detailDialog, setDetailDialog] = useState({
        open: false,
        data: {}
    })

    const openToast = useToast()
    const axios = new Network()
    const navigate = useNavigate();

    const getPolls = async (page = 1) => {
        const response = await axios.CallApi({
            url: '/withdrawrequest/list?count=' + 15 + `&page=${page}&state=${state}&fromDate=${startDate ? new Date(startDate)?.toISOString() : ''}&toDate=${endDate ? new Date(endDate)?.toISOString() : ''}`,
            method: 'get'
        })
        if (response.ok) {
            setData(response?.data?.data || [])
            setPaginations({ total: Math.ceil(response.data.totalCount / 15), page, count: 15 })
        }
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        getPolls()
    }, [startDate, endDate, state])
    return (
        <SkeletonTheme baseColor='#2d271b' highlightColor='#3e382b'>
            <Helmet>
                <title key="title">
                    Window History | Chain Of Legends
                </title>
            </Helmet>
            <motion.div
                exit={{ opacity: 0, y: 200 }}
                transition={{ duration: 0.4 }} className='row' initial={isMobile ? undefined : { opacity: 0, y: 200 }} animate={{ opacity: 1, y: 0 }} >
                <MidBox modal title="Withdraw history">

                    <div className='row px-2 px-md-3 align-items-end'>
                        {/* <div className='col-6 col-md-4 col-lg-2 '>
                            <label className='mb-2 dark-yellow'>
                                State:
                            </label>
                            <Form.Select value={state} onChange={(e) => setState(e.target.value)} aria-label="Default select example">
                                <option value={0}>{"All"}</option>
                                {Object.entries(WithdrawStates)?.map(([key, title]) =>
                                    <option key={key} value={key}>{title}</option>
                                )}
                            </Form.Select>
                        </div> */}

                        <div className='col-6 col-md-4 col-lg-3 '>
                            <label className='mb-2 mt-2 mt-md-0 dark-yellow'>
                                Starting Date:
                            </label>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <div className='col-6 col-md-4 col-lg-3 '>
                            <label className='mb-2 mt-2 mt-md-0 dark-yellow'>
                                Ending Date:
                            </label>
                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />

                        </div>
                        <div style={{ height: '77px' }} className='col-8 col-md-4 col-lg-2 d-flex align-items-end'>
                            <Button onClick={() => {
                                setState(0)
                                setEndDate('')
                                setStartDate('')
                            }} style={{ margin: 0 }} variant='wide'>
                                Clear Filters
                            </Button>
                        </div>
                    </div>
                    <div style={{ overflow: 'auto' }} className="pe-1 pt-3 pb-5 ps-1 pe-sm-3 ps-sm-3 pb-4 table-box">


                        <table className="table table-borderless m-0 text-white" > <thead>
                            <tr>
                                <th scope="col">Amount</th>
                                <th scope="col">State</th>
                                <th scope="col">Request Date</th>
                                <th scope="col">Update Date</th>
                            </tr>
                        </thead>
                            <tbody>
                                {loading && [1, 2, 3].map((item) =>
                                    <tr key={"loading" + item} className='pointer'
                                    >
                                        <td width={"50%"}>
                                            <Skeleton width={'100%'} />
                                        </td>
                                        <td> <Skeleton width={'60px'} /> </td>
                                        <td> <Skeleton width={'60px'} /></td>
                                        <td >
                                            <Skeleton width={'100px'}
                                                height={25}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {data.length === 0 && !loading && <tr>
                                    <td colSpan={4} style={{ textAlign: 'center', paddingTop: 40, fontSize: 20, paddingBottom: 40, }} className="dark-yellow">
                                        You have no withdraw here ...
                                    </td>
                                </tr>}
                                {!loading && data?.map((item, key) => (
                                    <>
                                        <tr className='pointer'
                                            onClick={() => {

                                            }}
                                            style={{ opacity: !item?.isRead ? 1 : 0.7 }}
                                            key={"row" + key}>

                                            <td >
                                                <span className='fs-big' >
                                                    {roundFloat(item.count, 4)}  $CLEG

                                                </span>
                                            </td>
                                            <td >
                                                <span className='fs-big' style={{ color: item?.state === 4 ? "var(--theme-color-red)" : (item?.state === 3 || item?.state === 2) ? 'var(--theme-color-green)' : 'var(--theme-color-blue)' }}>
                                                    {WithdrawStates[item?.state]}
                                                </span>
                                            </td>
                                            <td>{moment(new Date(item?.registerDate)).format('HH:mm - MM/DD/YYYY')}</td>
                                            <td>{moment(new Date(item?.modifyDate)).format('HH:mm - MM/DD/YYYY')}</td>
                                        </tr>

                                    </>
                                ))}

                            </tbody>
                        </table>
                        <br />
                        <ReactPaginate

                            className='pagination justify-content-center'
                            activeClassName='active-pg'
                            pageClassName='page-item'
                            pageLinkClassName='page-link'
                            breakClassName='page-item'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            breakLinkClassName='page-link'
                            previousLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            onPageChange={(e) => getPolls(e.selected + 1)}
                            pageRangeDisplayed={3}
                            pageCount={paginations.total}
                        // renderOnZeroPageCount={null}
                        />
                    </div>
                </MidBox>
            </motion.div>
            <Dialog title="Detail" open={detailDialog.open} onClose={() => setDetailDialog((prev) => ({ ...prev, open: false }))}>
                <div className='row flex-column'>
                    <div className='d-flex'>


                    </div>
                </div>
                {detailDialog?.data?.message}
            </Dialog>
        </SkeletonTheme >
    )
}

export default WithdrawHistory