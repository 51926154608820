import React, { useEffect } from "react";
import ShopBoxEmptyState from "./empty-state";
import useArenaShop from "../../../../hooks/useArenaShop";
import CircularProgress from "../../../../components/CircularProgress";
import { Assets } from "../../../../constants/assets";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
type Props = {};

const ShopBox = (props: Props) => {
  
  const { data, loading, getShopData } = useArenaShop();
  const navigate = useNavigate();
  useEffect(() => {
    getShopData();
  }, []);

  return (
    <PerfectScrollbar
      style={{ minHeight: 111 }}
      className="bg-paper-2 p-2 rounded-3 d-flex flex-column   overflow-auto "
    >
      {!loading && data?.rewards?.length && (
        <div className="d-flex  gap-2 align-items-center ">
          <div className="d-flex flex-column align-items-center me-3 ms-2">
            <div className="light-yellow ">Best Sellers</div>
            <div>
              <img
                width={90}
                src={Assets.icons.bloodGemPack}
                alt="bloodgem-pack"
              />
            </div>
          </div>
          {data.rewards.map((reward) => (
            <div
              key={reward.id}
              style={{minWidth:120}}
              onClick={() => navigate("/marketplace?type=6")}
              className="item-frame button pointer pb-2 flex-column"
            >
              <div>
                <img
                  style={{ maxHeight: 60 }}
                  src={reward.icon}
                  className="w-100"
                  alt={reward.title}
                />
              </div>
              <div
                className="fs-tiny light-yellow"
                dangerouslySetInnerHTML={{ __html: reward.description }}
              />
            </div>
          ))}
        </div>
      )}
      {loading && (
        <div className="flex-1 w-100 h-100 d-flex align-items-center justify-content-center">
          <CircularProgress />
        </div>
      )}
      {!loading && (!data || data.rewards?.length === 0) && (
        <ShopBoxEmptyState />
      )}
    </PerfectScrollbar>
  );
};

export default ShopBox;
