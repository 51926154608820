import React from "react";
import { Col, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Helmet } from "react-helmet";
import TrCountDown from "../components/TrCountDown";
import { useSelector } from "react-redux";
import Countdown from "react-countdown";
import useEvents from "../hooks/useEvents";
import CaptainCampaign from "../components/CaptainCampaign";
import { SkeletonTheme } from "react-loading-skeleton";
type Props = {};
const labels = [
  {
    id: 9,
    title: 64,
    position: {
      right: "17%",
      top: "20%",
    },
  },
  {
    id: 10,
    title: 128,
    position: {
      bottom: "25%",
      right: "44%",
    },
  },

  {
    id: 11,
    title: 256,
    position: {
      top: "20%",
      left: "30%",
    },
  },
  {
    id: 12,
    title: 512,
    position: {
      bottom: "38%",
      left: "35%",
    },
  },

  {
    id: 13,
    title: 1024,
    position: {
      top: "38%",
      left: "15%",
    },
  },
  {
    id: 14,
    title: 2048,
    position: {
      bottom: "18%",
      left: "25%",
    },
  },
];
const TreasurePage = (props: Props) => {
  const { halloweenEvent } = useEvents();
  return (
    <>
      <Helmet>
        <style>
          {`
                    .main-layout {
                        background: url('/assets/images/backgrounds/main-bg.jpg');
                     }
                    `}
        </style>
        <title key={"title"}>Select a Treasury | Chain Of Legends</title>
      </Helmet>
      <br />
      <div
        style={{ maxWidth: 1920, minHeight: "100vh" }}
        className="mx-auto d-flex align-items-center "
      >
        <PerfectScrollbar style={{ overflow: "scroll" }}>
          <div style={{ minWidth: 1024 }} className="p-relative">
            {labels?.map((item) => (
              <>
                {" "}
                {/* {userInfo?.doubleRewardTreasure &&userInfo.doubleRewardTreasure===item.id&&
                 (
                    <OverlayTrigger trigger={"click"}
                      overlay={
                        <Popover>
                          <Popover.Body>
                          Capture this treasure Island to receive <span className="bold text-danger">
                            2x Reward </span> for first time without extra collecting time!    <Countdown date={Date.UTC(2023,9,7,15,0,0,0)}/>
                          <br/>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div
                        className="badge-red pointer rounded"
                        style={{
                          position: "absolute",
                          ...item.position,
                          transform: `translateX(-5px) translateY(${item.id ===9 ||item.id ===11||item.id ===13?'-40px':'-90px' })`,
                          zIndex: 10,
                        }}
                      >
                        2x Reward
                      </div>
                    </OverlayTrigger>
                  )} */}
                <Link to={`/treasure/${item.id}`}>
                  <div
                    style={{
                      position: "absolute",
                      ...item.position,
                      zIndex: 10,
                    }}
                    className="button"
                  >
                    <div className="p-relative">
                      <img
                        width={"75px"}
                        src="/assets/images/lands/treasure/label.png"
                      />
                      <div
                        className="text-center text-dark bold"
                        style={{
                          position: "absolute",
                          fontSize: 14,
                          top: "35%",
                          right: -5,
                          left: 0,
                        }}
                      >
                        {item.title}
                      </div>
                    </div>
                    {halloweenEvent.extraRewardTreasureId === item.id && (
                      <OverlayTrigger
                        rootClose
                        trigger={"click"}
                        overlay={
                          <Tooltip>
                            Capture this treasure Island to receive{" "}
                            {halloweenEvent.extraTreasureRewardMultiplier}X
                            Reward for for first time without extra collecting
                            time!
                          </Tooltip>
                        }
                      >
                        <div
                          onClick={(e) => e.preventDefault()}
                          style={{ top: -15 }}
                          className="fire-badge mx-auto"
                        >
                          {halloweenEvent.extraTreasureRewardMultiplier}X
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </Link>
              </>
            ))}
            <TrCountDown
              className="rounded fs-sm-small"
              styles={{
                position: "absolute",
                background: "rgba(209, 52, 8,0.4)",
                ...labels.find((l) => l.id === 13)?.position,
                transform: "translateY(80px) translateX(-80px)",
              }}
            />
                 <SkeletonTheme enableAnimation={true} baseColor="#352d22" highlightColor="#3e362d">
            {/* <CaptainCampaign/> */}
            </SkeletonTheme>
            <img src="/assets/images/lands/treasure/maps.png" width={"100%"} />
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default TreasurePage;
