 import React, { useEffect, useRef, useState } from 'react'
import useWindowSize from '../hooks/useWindowSize'
import DefaultLayout from '../layouts/DefaultLayout'
import Draggable from 'react-draggable';
import Network from '../utils/Network';
import ThousandsSeparator from '../utils/thousands-separator';
import Button from '../components/Button';
import { Alert, Badge, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import useToast from '../hooks/useToast';
import { motion } from 'framer-motion'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Dialog from '../components/Dialog';
import moment from 'moment';
import Countdown from 'react-countdown';
import { isMobile, isTablet } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { setPlayable } from '../redux/music/action';
import { useDispatch, useSelector } from 'react-redux';
import { createRef } from 'react';
import { defaultBattlePositions } from '../utils/battle-positions';
import LevelBadge from '../components/LevelBadge';
import Slider from 'rc-slider';
import LeaderShare from '../components/LeaderShare';
import useLongPress from '../hooks/useLongPress'
import { roundFloat } from '../utils/round-float';
import { Helmet } from 'react-helmet';
import { ItemBonusStat } from '../types/items';

type Props = {}
export const makeUpperCase = (object) => {
    let entries = Object.entries(object);
    let capsEntries = entries.map((entry) => [entry[0][0].toUpperCase() + entry[0].slice(1), entry[1]]);
    let capsPopulations = Object.fromEntries(capsEntries);
    return capsPopulations
}
const ModifyTreasure = (props: Props) => {
    const [startLoading, setStartLoading] = useState(false)
    const userInfo = useSelector((state: any) => state?.userInfo)
    const windowSize = useWindowSize()
    const contaierRef = useRef<HTMLDivElement>(null)
    const { id } = useParams()
    const overFlowContainer = useRef<HTMLDivElement>(null)
    const supplyContainer = useRef<HTMLDivElement>(null)
    const [selectMode, setSelectMode] = useState(false)
    const [leaderShare, setLeaderShare] = useState()
    const [selectProperties, setSelectProperties] = useState({ checkRoom: 0, battlePlace: 0 })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [show, setShow] = useState(0);
    const [arrayList, setArrayList] = useState(defaultBattlePositions)
    const [supplyList, setSupplyList] = useState<any>()
    const [result, setResult] = useState<any>()
    let [searchParams, setSearchParams] = useSearchParams();
    const width = contaierRef?.current?.offsetWidth;
    const containerHeight = contaierRef?.current?.offsetHeight;
    const calculatedSize = (width! - 400) / (windowSize?.width < 800 ? 10 : 10)
    const elementSize = calculatedSize > 200 ? 200 : calculatedSize
    const axios = new Network()
    const [activePopper, setActivePopper] = useState<any>({})
    const [allTroops, setAllTroops] = useState([])
    const [dragging, setDragging] = useState(false)
    const [data, setData] = useState<any>()
    const [xBowData, setXBowData] = useState([])
    const openToast = useToast()
    const timer = useRef<any>(null)
    const [isOwner, setIsOwner] = useState(false)
    const [resultDialog, setResultDialog] = useState(false)
    const [towerDialog, setTowerDialog] = useState({
        open: false,
        index: 1,
        level: 1,
        type: 'add',
        previousLevel: 0,
        minLevel: 1
    })
    const isRaid = Boolean(searchParams.get('raid'))

    const [selectDialog, setSelectDialog] = useState({
        open: false,
        item: null
    })
    async function getTroops() {
        const response = await axios.CallApi({ url: '/troop', method: 'get' })
        setAllTroops(response?.data)
    }
    async function getXBow() {
        const response = await axios.CallApi({
            url: '/adventure/xbow',
            method: 'get'
        })
        setXBowData(response.data)
    }
    async function getAdv() {
        const response = await axios.CallApi({ url: '/adventure/' + id, method: 'get' })
        let formation = JSON.parse(response?.data?.formation)
        let defendFormation = []
        const isUserOwner = response?.data?.playerId === userInfo?.id
        if (isUserOwner) {
            setIsOwner(true)
        }
        if (response?.data?.state === 2) {
            formation = JSON.parse(response?.data?.defendFormation)
        }

        setData(response?.data)
        setLeaderShare(response?.data?.leaderShare)
        let positions = defaultBattlePositions?.map((item) => ({
            ...item,
            displayX: item.displayX + (response?.data?.state === 2 ? 5 : 0),
            x: item.x + (response?.data?.state === 2 ? 5 : 0),
        }))
        for (let key in formation) {
            const target = positions.find((item) => (item?.displayX === formation[key].displayX) && (item?.displayY === formation[key].displayY))
            positions = [...positions?.filter((item) => item.index !== target?.index),
            {
                ...target,
                ...formation[key],
                isLocked: isUserOwner ? false : formation[key]?.troopId ? false : true
            }
            ]?.sort((a, b) => a.index - b.index)

        }
        setArrayList(positions)


    }

    useEffect(() => {
        if (!userInfo) return
        getAdv()
        getTroops()
        getXBow()

    }, [userInfo])

    useEffect(() => {
    }, [arrayList])

    const handleOnMouseEnter = (id) => {
        setShow(id);
    }
    const handleOnMouseLeave = () => {
        setShow(0);
    }
    const isDefender = data?.state === 2
    function handleSelectCheckRoom(id: number) {
        setSelectMode(true)
        // if (selectProperties?.checkRoom) {
        //     setSelectProperties(prev => ({ ...prev, checkRoom: id }))
        //     setSelectMode(false)
        //     return
        // }
        if (selectProperties?.battlePlace) {
            // TODO CHANGE

            return
        }
        setSelectProperties({ battlePlace: 0, checkRoom: id })

    }
    function handleBattlePlace(id?: number | string, i?: number) {
        if (selectProperties.checkRoom) {
            const playerTroop = supplyList?.find((item: any) => item.id === selectProperties.checkRoom)
            setArrayList((prev: any) => ([...prev?.filter(item => item?.index !== i), {
                ...prev?.find(irt => irt?.index === i),
                playerTroopId: selectProperties.checkRoom,
                playerTroop: { ...makeUpperCase(playerTroop), Troop: makeUpperCase(playerTroop.troop),items: playerTroop.items?.map((item)=>makeUpperCase({...item,Item:makeUpperCase(item.item)})) }
            }]?.sort((a, b) => a.index - b.index)))
            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
            setSelectMode(false)
            return
        }
        if (selectProperties.battlePlace) {
            if (id && id === selectProperties.battlePlace) {
                setSelectProperties({ battlePlace: 0, checkRoom: 0 })
                setSelectMode(false)
                return
            };

            setArrayList((prev: any) => ([...prev?.filter(item => item?.index !== i && item?.playerTroopId !== selectProperties.battlePlace),
            {
                ...arrayList?.find(irt => irt?.index === i),
                playerTroopId: selectProperties.battlePlace,
                playerTroop: arrayList?.find(irt => irt?.playerTroopId === selectProperties.battlePlace)?.playerTroop

            },
            {
                ...arrayList?.find(irt => irt?.playerTroopId === selectProperties.battlePlace),
                playerTroopId: id ? id : undefined,
            }
            ]?.sort((a, b) => a.index - b.index)))
            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
            setSelectMode(false)
            return
        }
        if (id) {
            if (!isOwner) return
            setSelectMode(true)
            setSelectProperties({ battlePlace: parseInt(id?.toString()), checkRoom: 0 })
        }
    }
    const popperActiveItem = React.useMemo(()=>arrayList?.find((item) => item?.playerTroopId ===  (activePopper?.Id || activePopper?.id) ),[activePopper,arrayList])
    

    function changeTargetPriority(id: number, value: number) {
        const oldObj: any = arrayList?.find((item) => item?.playerTroopId === id)
        setArrayList(prev => ([...prev?.filter(item => item?.playerTroopId !== id), { ...oldObj, targetPriority: value }]?.sort((a, b) => a.index - b.index)))
    }

    const getPlayerToops = async () => {
        const response = await axios.CallApi({ url: '/playertroop?count=50', method: 'GET' })
        if (response.ok) setSupplyList(response?.data?.data)
    }


    useEffect(() => {
        getPlayerToops()
    }, [])

    useEffect(() => {
        if (overFlowContainer) {
            overFlowContainer.current.scrollLeft = (windowSize.width < 600 ? contaierRef?.current?.offsetWidth / 3.6 : 200)
        }
    }, [])

    useEffect(() => {
        dispatch(setPlayable(false))
    }, [])
    const leaveAdventure = async () => {
        if (isOwner) return
        // setStartLoading(true)
        const activeArray = [...arrayList?.filter(item => (Boolean(supplyList?.find((troop) => troop?.id === item.playerTroopId))))]
        let response = await axios.CallApi({
            url: "/adventure/" + id + '/modify',
            body: {
                "playerTroopIds": activeArray?.filter(item => Boolean(item?.playerTroopId))?.map((item) => (item.playerTroopId)),
                leaderShare: leaderShare,
                formation: activeArray?.map((item) => ({
                    ...item,
                    playerTroopId: 0,
                    troopId: undefined,
                    playerTroop: undefined
                }))
            }
        })
        setStartLoading(false)
        openToast(response)
        if (response.ok) {
            if (!response.data?.message) response.data.message = "Changes Saved Successfully"
            else {
                navigate(`/adventure`)
            }
            // setResult(response?.data?.result)
            // setResultDialog(true)
        }


    }
    const updateAdventure = async () => {
        setStartLoading(true)
        const activeArray = [...arrayList?.filter(item => (Boolean(item.playerTroopId) || Boolean(item.troopId)))]
        let response = await axios.CallApi({
            url: "/adventure/" + id + '/modify',
            body: {
                "playerTroopIds": activeArray?.filter(item => Boolean(item?.playerTroopId))?.map((item) => (item.playerTroopId)),
                leaderShare: leaderShare,
                formation: activeArray?.map((item) => ({
                    ...item,
                    x: data?.state === 2 ? item.x : item.x,
                    playerTroopId: item?.playerTroopId || 0,
                    playerTroop: supplyList?.find((detail: any) => detail?.id === item.playerTroopId) || undefined
                }))
            }
        })
        setStartLoading(false)
        openToast(response)
        if (response.ok) {
            if (!response.data?.message) response.data.message = "Changes Saved Successfully"
            if (response.data?.result?.state === 2) {
                navigate(`/adventure`)
                return
            }
            else {
                navigate(`/adventure`)
            }
            // setResult(response?.data?.result)
            // setResultDialog(true)
        }


    }
    const cancelAdventure = async () => {
        setStartLoading(true)
        const response = await axios.CallApi({
            url: "/adventure/" + id + '/cancel',
            method: 'delete'
        })
        openToast(response)

        if (response.ok) {
            navigate(`/adventure`)
        }
        setStartLoading(false)
    }
    function openSelect(item) {
        setSelectDialog({ open: true, item: { troopId: 1, level: 1, ...item, } })
    }

    function closeSelectDialog() {
        setSelectDialog(prev => ({ ...prev, open: false }))
    }
    const popover = (
        <Popover
            onClick={(e) => e.stopPropagation()}
            id="popover-basic" style={{
                backgroundColor: 'transparent',
                backgroundImage: 'url(/assets/images/backgrounds/popper-bg.png)',
                backgroundSize: '100% 100%',
                minHeight: 210,
                width: 250,
                color: '#fff',
                border: 'none'
            }}

        >
            <Popover.Header
                style={{
                    backgroundColor: 'transparent',
                    border: 'none'
                }}
                className="p-4 bold d-flex align-items-center" as="h3">
                <div className='icon-badge'>
                    <img src={activePopper?.Troop?.Icon}
                        width={35}
                        height={35}
                        className=""
                    />
                </div>
                Target priority
            </Popover.Header>
            <Popover.Body style={{ marginTop: -20 }} className='text-white ps-4'>
                <div style={{ marginTop: -10, marginBottom: 10 }} className="fs-tiny">
                    <span>
                        HP: {activePopper?.Health}/{activePopper?.MaxHealth} | Damage: {activePopper?.Damage} | Armor: {activePopper?.Armor} | Magic Resistance: {activePopper?.MagicResistance}
                    </span>
                </div>
                <div className='d-flex flex-wrap gap-1 mb-2'>
                    {activePopper?.items?.map((item) =>
                        <OverlayTrigger overlay={<Tooltip>
                            {item?.Item?.Name} - level {item?.Level}  <div className='text-success-light'>
                                +{item?.BonusValue} {ItemBonusStat[item?.Item?.BonusStat]}
                            </div>
                        </Tooltip>}>
                            <div className='circle border p-1'>
                                <img
                                    width={25}
                                    height={25}
                                    className='circle'
                                    src={item?.Image}
                                />
                            </div>
                        </OverlayTrigger>
                    )}
                </div>
                <Form.Check
                    type={"radio"}
                    value={1}
                    checked={popperActiveItem?.targetPriority === 1}
                    label={`Closest`}
                    onClick={(e) => changeTargetPriority(activePopper?.Id, 1)}
                />
                <Form.Check
                    type={"radio"}
                    value={2}
                    checked={popperActiveItem?.targetPriority === 2}
                    onClick={(e) => changeTargetPriority(activePopper?.Id, 2)}
                    label={`Lowest HP`}
                />
                <Form.Check
                    type={"radio"}
                    value={3}
                    checked={popperActiveItem?.targetPriority === 3}
                    onClick={(e) => changeTargetPriority(activePopper?.Id, 3)}
                    label={`Highest Damage`}
                />
                <br />
            </Popover.Body>
        </Popover>
    );



    // LongPress
    function handleButtonPress(e: any, item) {

        timer.current = setTimeout(() => {
            if (!isMobile && !isTablet) return;
            if (item.isLocked) return;
            handleOnMouseEnter(item?.playerTroopId)
        }
            , 300);
    }

    function handleButtonRelease() {
        clearTimeout(timer.current);
    }



    return (
        <div onClick={() => handleOnMouseLeave()} ref={overFlowContainer} style={{ overflow: 'auto' }}>
            {/* <TreasureInfo id={searchParams.get('treasureId')}/> */}
            <Helmet>
                <title key={'title'}>
                    {isDefender?"Defend":"Modify"} Adventure | Chain Of Legends
                </title>
            </Helmet>

            <div style={{
                display: 'flex',
                minHeight: windowSize.width > 1000 ? windowSize.width > 1800 ? elementSize * 8.5 : '950px' : elementSize * 10,
                justifyContent: windowSize.width < 990 ? 'flex-start' : ''
            }} ref={contaierRef} className='treasure ' >
                <div style={{
                    maxWidth: windowSize.width < 990 ? 'none' : '',
                    paddingInlineStart: windowSize.width > 1500 ? '8%' : windowSize.width < 990 ? '8%' : '10%',
                }} className='w-100  transition pe-3 '>
                    <div style={{ minWidth: 800 }} className='row '>
                        <div style={{ position: 'relative' }} className="d-flex mt-4 align-items-center col-12">
                            {arrayList?.map((item, i) => {
                                const ref = createRef<HTMLDivElement>()
                                return (
                                    <div key={i}
                                        className='tr-polygon transition d-flex pf-step4 justify-content-center'
                                        onClick={() => isMobile || isTablet ? handleBattlePlace(item?.playerTroopId, i) : null}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                        }}
                                        onDrop={(e) => {
                                            setDragging(false)
                                            if (item?.isLocked) return;
                                            if (!item?.troopId && !isOwner && data?.state !== 2) return;
                                            const id = parseInt(e.dataTransfer.getData("text/plain"));
                                            if (item?.troopId) {
                                                const troop = supplyList?.find((item) => item?.id === id)
                                                if (item?.troopId !== troop.troopId) {
                                                    openToast({
                                                        ok: false,
                                                        message: allTroops?.find((e) => e.id === item.troopId)?.name + ' Required'
                                                    })
                                                    return
                                                }

                                                if (item?.level! > troop.level) {
                                                    openToast({
                                                        ok: false,
                                                        message: 'Minimum Level Is ' + item?.level
                                                    })
                                                    return
                                                }

                                            }
                                            const isThereBefore = arrayList?.find((item) => item.playerTroopId === id)
                                            if (item.playerTroopId && id) {
                                                return
                                            }
                                            if (isThereBefore) {
                                                setArrayList((prev: any) => ([
                                                    ...prev?.filter(item => item?.playerTroopId !== id),
                                                    {
                                                        ...arrayList?.find((item) => item.playerTroopId === id),
                                                        playerTroopId: undefined
                                                    }]?.sort((a, b) => a.index - b.index)))
                                            }
                                            if (!id) return

                                            setArrayList(prev => ([...prev?.filter(item => item?.index !== i), {
                                                ...item,
                                                playerTroopId: id,
                                                playerTroop: isThereBefore ? isThereBefore.playerTroop : {
                                                    ...makeUpperCase(supplyList?.find((item) => item.id === id)),
                                                    Troop: makeUpperCase(supplyList?.find((item) => item.id === id).troop),
                                                    items:supplyList?.find((item) => item.id === id).items?.map((item)=>makeUpperCase({...item,Item:makeUpperCase(item.item)})),
                                                },

                                                troopId: 0
                                            }]?.sort((a, b) => a.index - b.index)))
                                            if (!isThereBefore) {
                                                setTimeout(() => {
                                                    handleOnMouseEnter(id)
                                                }, 200);
                                            }
                                        }}
                                        style={{
                                            opacity: dragging ? ((isOwner || data?.state === 2) ? 1 : item?.troopId ? 1 : 0.2) : (item?.playerTroopId || item?.troopId) ? 1 : 1,
                                            width: elementSize, height: elementSize,
                                            backgroundImage: item?.troopId && item?.troopId !== 9 ? 'url(/assets/images/battle/rd-polygon.png)' : '',
                                            filter: selectMode && !item?.playerTroopId ? "invert(30%) sepia(99%) saturate(323%)" : '',
                                            position: 'absolute',
                                            top: item?.y * elementSize + (windowSize?.width < 1200 ? containerHeight / 7 : containerHeight / 7),
                                            textAlign: 'center',
                                            left: (item?.displayX) * elementSize + 70,
                                            // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                                        }}
                                    >
                                        {isOwner && data?.adventureMode > 1 && data?.state !== 2 && !item?.playerTroopId && !item?.troopId && <div

                                            style={{
                                                position: 'absolute', background: "#e8a55f",
                                                boxShadow: 'inset 1px 1px 8px  #be7134',
                                                bottom: 0
                                            }}
                                            onClick={() => openSelect(item)}
                                            className='pointer px-2 rounded'>
                                            +
                                        </div>}
                                        {((item.x === 5 && item.y === 0) || (item.x === 5 && item.y === 5)) && !item?.playerTroopId && !item?.troopId && <div
                                            onClick={() => setTowerDialog(prev => ({ ...prev, index: item.index, open: true }))}
                                            style={{
                                                background: "#88451b",
                                            }}
                                            className='rounded fs-tiny  pointer px-2 '>
                                            + X-BOW
                                        </div>}
                                        {!item.playerTroopId && item?.troopId ? item?.troopId === 9 ?
                                            <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1 }}
                                            >
                                                <LevelBadge
                                                    level={item.level}

                                                />
                                                <img
                                                    className={' transition ' + (data?.state !== 2 && 'scaleX')}
                                                    // onDragStart={(event) => {
                                                    //     event
                                                    //         .dataTransfer
                                                    //         .setData('text/plain', item?.playerTroopId)
                                                    // }}
                                                    draggable={false}
                                                    style={{
                                                        maxWidth: '100%',
                                                        marginTop: -20,
                                                        width: dragging && !isOwner ? '50px' : 'auto',
                                                        maxHeight: windowSize?.width > 1700 ? 150 : windowSize?.width < 800 ? 88 : 100
                                                    }}
                                                    src={item.y === 5 ? xBowData?.[item.level - 1]?.idleAnimation.replace('top', 'bottom') : xBowData?.[item.level - 1]?.idleAnimation}
                                                />
                                                {isOwner && <div
                                                    onClick={() => isOwner && setTowerDialog({
                                                        index: item.index,
                                                        level: item.level!,
                                                        minLevel: item.level!,
                                                        previousLevel: !item.isBow ? (item.orgLevel > 0 ? item.orgLevel : item.level) : 0,
                                                        open: true,
                                                        type: !item.isBow ? 'add' : 'upgrade'
                                                    })}
                                                    style={{
                                                        position: 'absolute', background: "#e8a55f",
                                                        boxShadow: 'inset 1px 1px 8px  #be7134',
                                                        top: 5, width: 'fit-content', right: 0, left: 0
                                                    }}
                                                    className='pointer px-1 rounded mx-auto'>
                                                    <img draggable={false} width={15} src={'/assets/images/icons/edit.svg'} />

                                                </div>}
                                            </motion.div>
                                            : <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1 }}
                                            >
                                                <img
                                                    className={' transition ' + (data?.state !== 2 && 'scaleX')}
                                                    // onDragStart={(event) => {
                                                    //     event
                                                    //         .dataTransfer
                                                    //         .setData('text/plain', item?.playerTroopId)
                                                    // }}
                                                    draggable={false}
                                                    style={{
                                                        maxWidth: '100%',
                                                        marginTop: -20,
                                                        width: dragging && !isOwner ? '50px' : 'auto',
                                                        maxHeight: windowSize?.width > 1700 ? 150 : windowSize?.width < 800 ? 88 : 100
                                                    }}
                                                    src={allTroops?.find(detail => detail?.id === item?.troopId)?.image}
                                                />
                                                {!isOwner ? <LevelBadge

                                                    level={item.level!}

                                                /> :
                                                    <div
                                                        onClick={() => openSelect(item)}
                                                        style={{
                                                            position: 'absolute', background: "#e8a55f",
                                                            boxShadow: 'inset 1px 1px 8px  #be7134',
                                                            bottom: 0, width: 'fit-content', right: 0, left: 0
                                                        }}
                                                        className='pointer px-1 rounded mx-auto'>
                                                        <img draggable={false} width={15} src={'/assets/images/icons/edit.svg'} /> 
                                                       
                                                    </div>}
                                            </motion.div> : ''}
                                        {item?.playerTroopId  ? <OverlayTrigger
                                            onEnter={(e) => {
                                                if (item?.isLocked) return;
                                                setActivePopper(item?.playerTroop)
                                            }}
                                            key={item?.playerTroopId}
                                            show={item?.troopId !== 9 && show === item?.playerTroopId}
                                            container={ref}
                                            trigger={'focus'}
                                            placement={isDefender?'left':"right"}
                                            overlay={popover}>
                                            <div ref={ref}
                                                onTouchStart={(e) => handleButtonPress(e, item)}
                                                onTouchEnd={handleButtonRelease}
                                                onMouseDown={(e) => handleButtonPress(e, item)}
                                                onMouseUp={handleButtonRelease}
                                                onMouseLeave={handleButtonRelease}
                                                onClick={(e) => {
                                                    if (isTablet || isMobile) return
                                                    if (item.isLocked) return;
                                                    e.stopPropagation()
                                                    handleOnMouseEnter(item?.playerTroopId)
                                                }}
                                            >

                                                <div
                                                    style={{ height: '40%', width: 5, top: "30%", left: 0, borderRadius: 5, position: 'absolute', display: 'flex', alignItems: 'flex-end' }}
                                                    className="p-relative bg-dark-500 overflow-hidden"
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: (item?.playerTroop?.Health * 100 / item?.playerTroop?.MaxHealth) + "%"
                                                            ,
                                                            backgroundColor: 'var(--theme-color-green-dark)'
                                                        }}
                                                    />
                                                </div>
                                                {supplyList?.find((troop) => troop.id === item.playerTroopId)   ?
                                                    <LevelBadge level={item?.level || item?.playerTroop?.Level} /> : <LevelBadge isReq={item?.troopId !==9} level={item?.level ||item?.playerTroop?.Level} />}
                                                <img
                                                    className={'  ' + (data?.state !== 2 && 'scaleX')}
                                                    onDragStart={(event) => {
                                                        if (item?.isLocked) return;
                                                        event
                                                            .dataTransfer
                                                            .setData('text/plain', item?.playerTroopId)
                                                    }}
                                                    draggable={!item?.isLocked}
                                                    style={{
                                                        maxWidth: '100%',
                                                        marginTop: -20,
                                                        maxHeight: windowSize?.width > 1700 ? 150 : windowSize?.width < 800 ? 88 : 100
                                                    }}
                                                    src={item?.playerTroop?.Troop?.Image}
                                                />
                                                {isOwner && item.playerTroop?.TroopId === 9 && <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setTowerDialog({
                                                            index: item.index,
                                                            level: item.level,
                                                            minLevel: item.level,
                                                            previousLevel: !item.isBow ? (item.orgLevel > 0 ? item.orgLevel : item.level) : 0,
                                                            open: true,
                                                            type: !item.isBow ? 'add' : 'upgrade'
                                                        })
                                                    }}
                                                    style={{
                                                        position: 'absolute', background: "#e8a55f",
                                                        boxShadow: 'inset 1px 1px 8px  #be7134',
                                                        top: 5, width: 'fit-content', right: 0, left: 0
                                                    }}
                                                    className='pointer px-1 rounded mx-auto'>
                                                    <img draggable={false} width={15} src={'/assets/images/icons/edit.svg'} />

                                                </div>}
                                            </div>
                                        </OverlayTrigger> : ''}
                                    </div>

                                    // <div style={{
                                    //     width: elementSize,
                                    //     height: elementSize,
                                    //     transform:    `translateY(${elementSize / 2}px)`,
                                    // }} id={`position${item?.index}`} className='pre-polygon'>
                                    // </div>


                                )
                            })
                            }
                            {defaultBattlePositions?.map((item) => <div className='tr-polygon'
                                style={{
                                    width: elementSize, height: elementSize,
                                    // transform:    `translateY(${elementSize / 2}px)`,
                                    position: 'absolute',
                                    opacity: 0.4,
                                    top: item?.y * elementSize + (windowSize?.width < 1200 ? containerHeight / 7 : containerHeight / 7),
                                    left: (item?.displayX + (data?.state === 2 ? 0 : 5)) * elementSize + 70,
                                    // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'

                                }}
                            >


                            </div>)}
                        </div>

                    </div>
                </div>

                {windowSize?.width < 800 ? <div className='text-center d-flex' style={{ position: 'fixed', zIndex: 500, bottom: "140px", right: 0, left: 0, }}>
                    {selectMode && selectProperties.battlePlace ? <Button
                        onClick={() => {
                            setArrayList((prev: any) => (
                                [...prev?.filter(item => item?.playerTroopId !== selectProperties.battlePlace),
                                {
                                    ...prev?.find(irt => irt?.playerTroopId === selectProperties.battlePlace),
                                    playerTroopId: undefined
                                }
                                ]?.sort((a, b) => a.index - b.index)))
                            setSelectMode(false)
                            setSelectProperties({ battlePlace: 0, checkRoom: 0 })
                        }}
                        size="small"
                        style={{ flex: 1, maxWidth: 160 }}
                        variant='wide' className='mx-auto '>
                        Remove
                    </Button> : ''}

                    <Button size='small' style={{ flex: 1, maxWidth: 160 }} loading={startLoading} onClick={updateAdventure} className='mx-auto bg-100 ' variant='wide-primary'>
                        Save Changes
                    </Button>
                    {/* {!isOwner && data?.state===0 && 
                    <Button size='small' style={{ flex: 1, maxWidth: 160 }} loading={startLoading} onClick={leaveAdventure} className='mx-auto bg-100 ' variant='wide'>
                        Leave Adventure
                    </Button> } */}

                </div> : ''}
                <LeaderShare readOnly value={leaderShare} onChange={setLeaderShare} />
                <div onDragOver={(e) => {
                    e.preventDefault();
                }}
                    onDrop={(e) => {
                        const id = parseInt(e.dataTransfer.getData("text/plain"));
                        if (arrayList?.find((item) => item.playerTroopId === id)) {
                            setArrayList((prev: any) => ([...prev?.filter(item => item?.playerTroopId !== id), { ...arrayList?.find((item) => item.playerTroopId === id), playerTroopId: undefined }]?.sort((a, b) => a.index - b.index)))
                            if (supplyList.find((item) => item.id === id)?.state !== 1) {
                                setSupplyList((prev) => ([
                                    ...prev.filter(item => item.id !== id),
                                    {
                                        ...supplyList.find((item) => item.id === id),
                                        state: 1
                                    }]))
                            }

                        }
                        if (!id) return
                        // setArrayList(prev => ([...prev?.filter(item => item?.index !== i), { ...item, playerTroopId: id }]?.sort((a, b) => a.index - b.index)))
                    }}

                    className='picker-bar pf-step2 align-items-center'>

                    <div style={{ flex: 1, height: '100%', padding: '15px 0' }} ref={supplyContainer}>
                        <PerfectScrollbar style={{ width: supplyContainer?.current?.offsetWidth }} >

                            <div style={{ height: '100%' }} className='d-flex'>
                                {supplyList?.length === 0 && <div>
                                    No troop available
                                </div>}
                                {supplyList?.map((item: any) => {
                                    if (!arrayList?.find(obj => obj.playerTroopId === item?.id) && item?.state === 1) return (
                                        <div style={{
                                            width: windowSize?.width > 800 ? (width / 10 - 5) : 120,
                                            minWidth: 120,
                                            height: '100%',
                                            opacity: selectMode ? (selectProperties?.battlePlace ? 1 : selectProperties?.checkRoom === item?.id ? 1 : 0.4) : 1
                                        }} className='fight-placement pf-step3'>

                                            <div
                                                onClick={() => isMobile || isTablet ? handleSelectCheckRoom(item.id) : null}
                                                onDragEnd={() => {
                                                    setDragging(false)
                                                }}
                                                onDragStart={(event) => {
                                                    setDragging(true)
                                                    event
                                                        .dataTransfer
                                                        .setData('text/plain', item.id)
                                                }} draggable={true}
                                                className='d-flex w-100 h-100 text-center p-relative justify-content-center align-items-center'>

                                                <div
                                                    style={{ height: '40%', width: 5, top: "30%", left: "17%", transform: 'rotate(12deg)', borderRadius: 5, position: 'absolute', display: 'flex', alignItems: 'flex-end' }}
                                                    className="p-relative bg-dark-500 overflow-hidden"
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: (item?.health * 100 / item?.maxHealth) + "%",
                                                            backgroundColor: 'var(--theme-color-green-dark)'
                                                        }}
                                                    />
                                                </div>
                                                <LevelBadge level={item?.level} />
                                                <img
                                                    draggable={false} className={"mx-auto " + (!isDefender && 'scaleX')}
                                                    style={{ maxWidth: '75%', maxHeight: 100 }} src={item?.troop?.image} />

                                            </div>
                                        </div>
                                    )
                                    else {
                                        return ''
                                    }
                                })}
                            </div>

                        </PerfectScrollbar>


                    </div>


                    {windowSize?.width > 800 && <div className='d-flex flex-column pe-2'>
                        <Button loading={startLoading} onClick={updateAdventure} className='ms-auto mx-2' variant='wide-primary'>
                            Save Changes
                        </Button>
                        {data?.state === 0 &&
                            <Button disabled={startLoading} onClick={cancelAdventure} className='ms-auto mx-2' variant='wide'>
                                Cancel Adventure
                            </Button>}
                    </div>}
                </div>
                <Dialog closeLabel='Return' title="Adventure Result" open={resultDialog} onClose={() => { setResultDialog(false); navigate('/adventure') }}>

                    <p className='fs-big text-center bold dark-yellow'>
                        Troops sent successfully
                    </p>
                    <div style={{ marginBottom: -30 }} className='row mt-5 flex-column'>
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            {/* <img width={80} height={90} src={}/> */}
                            <span>
                                <img src={"/assets/images/icons/timer.png"} width={25} height={25} />  Arrival time
                            </span>
                            <Countdown
                                date={new Date(result?.arriveDate)}
                                renderer={({ formatted, days }) => (
                                    <div className='fs-big d-flex'>
                                        {days ?
                                            <div className='time-badge me-2'>
                                                {formatted.days}
                                                <span className='dark-yellow'>
                                                    Days
                                                </span>
                                            </div> : ""}
                                        <div className='time-badge'>
                                            {formatted.hours}
                                            <span className='dark-yellow'>
                                                Hours
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.minutes}
                                            <span className='dark-yellow'>
                                                Minutes
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.seconds}
                                            <span className='dark-yellow'>
                                                Seconds
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <hr />
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            {/* <img width={80} height={90} src={}/> */}
                            <span>
                                <img src={"/assets/images/icons/timer.png"} width={25} height={25} />  Return time
                            </span>
                            <Countdown
                                date={new Date(result?.returnDate)}
                                renderer={({ formatted, days }) => (
                                    <div className='fs-big d-flex'>
                                        {days ?
                                            <div className='time-badge me-2'>
                                                {formatted.days}
                                                <span className='dark-yellow'>
                                                    Days
                                                </span>
                                            </div> : ""}
                                        <div className='time-badge'>
                                            {formatted.hours}
                                            <span className='dark-yellow'>
                                                Hours
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.minutes}
                                            <span className='dark-yellow'>
                                                Minutes
                                            </span>
                                        </div>
                                        <div className='ms-2 time-badge'>
                                            {formatted.seconds}
                                            <span className='dark-yellow'>
                                                Seconds
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                    </div>
                </Dialog>

                <Dialog onSubmit={() => {
                    setArrayList(prev => ([...prev?.filter(item => item.index !== selectDialog.item.index), selectDialog.item]?.sort((a, b) => a?.index - b?.index)))
                    setSelectDialog(prev => ({ ...prev, open: false }))
                }} open={selectDialog.open} submitLabel='Request' title="Request Troop" onClose={closeSelectDialog}>
                    <div >
                        Selected Troop :  {allTroops?.find((item) => item?.id === selectDialog?.item?.troopId)?.name}
                    </div>
                    <PerfectScrollbar style={{ maxWidth: '100%' }} className='d-flex mt-2 pb-2'>
                        {allTroops?.map((troop: any) => (
                            <img onClick={() => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, troopId: troop.id } }))}
                                className={`select-troop ${selectDialog?.item?.troopId === troop.id && 'active'}`} src={troop.icon} />
                        ))}

                    </PerfectScrollbar>

                    <div className='d-flex mt-2 align-items-center'>
                        <div style={{ minWidth: 111 }} className='pe-2 fs-med mt-1'>
                            Minimum Level
                        </div>
                        <Slider
                            marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10, 11: 11, 12: 12 }}
                            max={12}
                            value={selectDialog?.item?.level}
                            min={1}
                            className={'mx-auto '}
                            onChange={(val) => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, level: val } }))}
                            style={{ width: ' 100%', maxWidth: 400 }}
                            step={1} />
                    </div>
                    <div className='mt-5 mb-2'>
                        Target Priority :
                    </div>
                    <div className='d-flex  align-items-center gap-2'>
                        <div onClick={(e) => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, targetPriority: 1 } }))}>
                            <Form.Check
                                type={"radio"}
                                value={1}
                                checked={selectDialog?.item?.targetPriority === 1}
                                label={`Closest`}
                            />
                        </div>
                        <div onClick={(e) => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, targetPriority: 2 } }))}>

                            <Form.Check
                                type={"radio"}
                                value={2}
                                checked={selectDialog?.item?.targetPriority === 2}
                                label={`Lowest HP`}
                            />
                        </div>
                        <div onClick={(e) => setSelectDialog((prev: any) => ({ open: true, item: { ...prev.item, targetPriority: 3 } }))}>
                            <Form.Check
                                type={"radio"}
                                value={3}
                                checked={selectDialog?.item?.targetPriority === 3}
                                label={`Highest Damage`}
                            />
                        </div>

                    </div>
                    {arrayList?.find((item) => item?.index === selectDialog?.item?.index)?.troopId &&
                        <div style={{ marginBottom: -60 }} className='text-center mt-2 w-100'>
                            <Button onClick={() => {
                                setArrayList(prev => ([...prev?.filter(item => item.index !== selectDialog.item.index), { ...selectDialog.item, level: undefined, troopId: undefined, targetPriority: undefined }]?.sort((a, b) => a?.index - b?.index)))
                                setSelectDialog(prev => ({ ...prev, open: false }))
                            }} variant='wide-secondary' className='mx-auto' style={{ minWidth: 70 }} >
                                <img height={18} src={'/assets/images/icons/trash.svg'} />
                                Delete Troop
                            </Button>
                        </div>}
                </Dialog>

                <Dialog onSubmit={() => {
                    setArrayList((prev: any) => ([
                        ...prev.filter((item) => item.index !== towerDialog.index),
                        {
                            ...prev.find((item) => item.index === towerDialog.index),
                            troopId: 9,
                            level: towerDialog?.level,
                            isBow: towerDialog?.previousLevel > 0 ? false : true,
                            orgLevel: towerDialog?.previousLevel > 0 ? towerDialog?.previousLevel : undefined,
                            troop: xBowData[towerDialog?.level - 1],
                            // playerTroopId: undefined

                        }
                    ].sort((a, b) => a.index - b.index)

                    ))
                    setTowerDialog(prev => ({ ...prev, previousLevel: 0, level: 1, open: false }))
                }} onClose={() => setTowerDialog(prev => ({ ...prev, previousLevel: 0, level: 1, open: false }))} open={towerDialog.open} title="X-BOW">
                    <div className='d-flex align-items-center' >
                        <img width={120} src="/assets/images/icons/x-bow.png" />
                        <div className='pb-4 px-2' style={{ flex: 1, overflow: 'hidden' }}>
                            <div className='mb-1'>
                                Add X-Bow Level {towerDialog?.level}
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div>
                                    <div className='fs-med'>
                                        <img src="/assets/images/icons/damage.png" width={20} />  Damage :  {xBowData[towerDialog?.level - 1]?.damage}
                                    </div>
                                    <div className='fs-med'>
                                        <img src="/assets/images/icons/health.png" width={20} />    Health :   {xBowData[towerDialog?.level - 1]?.maxHealth}
                                    </div>

                                </div>
                                <div className='border-start ps-2 ms-2'>
                                    <div className='fs-tiny'>
                                        Range :   {xBowData[towerDialog?.level - 1]?.range}
                                    </div>
                                    <div className='fs-tiny'>
                                        Armor :   {xBowData[towerDialog?.level - 1]?.armor}
                                    </div>
                                    <div className='fs-tiny'>
                                        Magic Resistance :   {xBowData[towerDialog?.level - 1]?.magicResistance}
                                    </div>
                                </div>
                            </div>

                            <Slider
                                marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, }}
                                max={8}
                                value={towerDialog?.level}
                                min={towerDialog.previousLevel > 0 ? towerDialog.previousLevel : 1}
                                className={'mx-auto '}
                                onChange={(val) => setTowerDialog((prev: any) => ({ ...prev, level: val }))}
                                style={{ width: ' 100%', maxWidth: 400 }}
                                step={1} />
                        </div>
                    </div>

                    <Alert variant='warning' className='mt-5 py-2 d-flex justify-content-between' style={{ marginBottom: -50 }}>
                        {xBowData[towerDialog?.level - 1]?.clegCost > 0 &&
                            <div className=''>
                                <img src="/assets/images/icons/cleg.png" width={30} />
                                {towerDialog?.previousLevel > 0 ?
                                    <b>{ThousandsSeparator(
                                        xBowData[towerDialog?.level - 1]?.clegCost - xBowData[towerDialog?.previousLevel - 1]?.clegCost
                                    )}</b>
                                    : <b>{ThousandsSeparator(xBowData[towerDialog?.level - 1]?.clegCost)}</b>}
                                <small>CLEG</small>
                            </div>}
                        {xBowData[towerDialog?.level - 1]?.stoneCost > 0 &&
                            <div className=''>
                                <img src="/assets/images/icons/stone.png" width={30} />
                                <b>{towerDialog?.previousLevel > 0 ? ThousandsSeparator(
                                    xBowData[towerDialog?.level - 1]?.stoneCost - xBowData[towerDialog?.previousLevel - 1]?.stoneCost
                                )
                                    : <b>{ThousandsSeparator(xBowData[towerDialog?.level - 1]?.stoneCost)}</b>}
                                </b>
                                <small>Stone</small>
                            </div>}
                        {xBowData[towerDialog?.level - 1]?.ironCost > 0 &&
                            <div className=''>
                                <img src="/assets/images/icons/metal.png" width={30} />
                                <b>{towerDialog?.previousLevel > 0 ? ThousandsSeparator(
                                    xBowData[towerDialog?.level - 1]?.ironCost - xBowData[towerDialog?.previousLevel - 1]?.ironCost
                                )
                                    : <b>{ThousandsSeparator(xBowData[towerDialog?.level - 1]?.ironCost)}</b>
                                }
                                </b>
                                <small>Iron</small>
                            </div>}
                    </Alert>
                </Dialog>
            </div >
        </div>

    )
}

export default ModifyTreasure