import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import Dialog from "../Dialog";
import Countdown from "react-countdown";
import CountUp from "react-countup";
import { roundFloat } from "../../utils/round-float";
import Decimal from "decimal.js-light";
import useWindowSize from "../../hooks/useWindowSize";
import Network from "../../utils/Network";
import useToast from "../../hooks/useToast";
import CircularProgress from "../CircularProgress";
import useEvents from "../../hooks/useEvents";
import { useSelector } from "react-redux";

type Props = {
  id?: string | number;
  title: string;
  count?: string | number;
  video?: string;
  className?: string;
  image: string;
  capacity: string | number;
  filled: number | number;
  health: string | number;
  disabled?: boolean;
  onDetail: Function;
  getMe: Function;
  loading?: boolean;
  rawDetail?: any;
  maxQueue?: number;
  trainingQueue?: number;
  submit?: Function;
  repair?: Function;
  status?: number;
  refresh?: Function;
  buildingId?: number;
  upgradeTime?: string;
  currentDailyProduction?: number;
  firstLevelBuildingInfo?: any;
  countDown?: string;
  bonusLevel?: number;
  offerAction?: Function;
  discount?: number;
};

const MainBox = (props: Props) => {
  const windowSize = useWindowSize();
  const [dialog, setDialog] = useState(false);
  const [challangeDialog, setChallengeDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filled, setFilled] = useState(props.filled);
  const openToast = useToast();
  const axios = new Network();
  let intervalId = useRef<NodeJS.Timer | null>(null);
  const isMounted = useRef(false);
  const [instantUpgradeData, setInstantUpgradeData] = useState<any>();
  const [upgradeLoading, setUpgradeLoading] = useState<boolean>(false);
  const counterRef = useRef(props.filled);
  const userInfo = useSelector((state: any) => state.userInfo);
  const { halloweenEvent } = useEvents();
  function stop() {
    if (!intervalId.current) return;
    counterRef.current = 0;
    clearInterval(intervalId.current);
    intervalId.current = null;
  }

  async function acceptChallenge() {
    setLoading(true);
    const response = await axios.CallApi({
      url: "/quest/activate?questId=130",
      method: "get",
    });
    setLoading(false);
    openToast(response);
    if (response.ok) {
      setChallengeDialog(false);
      window.location.hash = "#quests";
    }
  }

  useEffect(() => {
    if (props.status === 5 && props.id) {
      handleGetInstantUpgradePrice(props.id);
    }
  }, [props.id, props.status, dialog]);

  const progress = () => {
    if (+props.capacity > 0) {
      if (props.buildingId === 6) return;
      var oneCapacityEveryMinute = 1440 / (props.currentDailyProduction || 1);
      var oneHundredPercentCapacityEverySecond = parseFloat(
        ((oneCapacityEveryMinute * 60) / 100).toString()
      );
      var oneHundredPercentCapacityEveryNMilliSecond =
        oneHundredPercentCapacityEverySecond * 1000;
      const startDate = Date.now();
      const startCapacity = parseFloat(props.filled.toString());
      if (props.status === 2) {
        intervalId.current = setInterval(
          () => {
            //asdad
            let value = counterRef.current;
            var pastMilliseconds = Date.now() - startDate;
            if (props.capacity === value) {
              setTimeout(() => {
                props.refresh?.();
              }, 1000);
              return stop();
            }
            if (
              parseFloat(props.capacity?.toString()) <
              startCapacity +
                pastMilliseconds /
                  oneHundredPercentCapacityEveryNMilliSecond /
                  100
            ) {
              setFilled(parseFloat(props.capacity?.toString()));
              setTimeout(() => {
                props.refresh?.();
              }, 1000);
              return stop();
            }
            setFilled(
              (prev) =>
                startCapacity +
                pastMilliseconds /
                  oneHundredPercentCapacityEveryNMilliSecond /
                  100
            );
            counterRef.current =
              startCapacity +
              pastMilliseconds /
                oneHundredPercentCapacityEveryNMilliSecond /
                100;
          },
          oneHundredPercentCapacityEveryNMilliSecond > 300
            ? oneHundredPercentCapacityEveryNMilliSecond
            : 300
        );
      }
    }
  };

  const handleConfirmInstantUpgrade = async () => {
    setUpgradeLoading(true);
    const response = await axios.CallApi({
      url: `/building/${props.id}/instantupgrade`,
      method: "get",
    });
    openToast(response);
    if (response.ok) {
      setInstantUpgradeData(response.data);
      setDialog(false);
      props.refresh?.();
      props.getMe();
    }
    setUpgradeLoading(false);
  };

  const handleGetInstantUpgradePrice = async (id?: string | number) => {
    const response = await axios.CallApi({
      url: `/building/${id}/instantupgradeprice`,
      method: "get",
    });
    if (response.ok) {
      setInstantUpgradeData(response.data);
    }
  };
  useEffect(() => {
    if (props.status === 5 && props.id) {
      handleGetInstantUpgradePrice(props.id);
    }
  }, [props.id, props.status, dialog]);

  useEffect(() => {
    progress();
    return () => stop();
  }, [props.filled]);

  useEffect(() => {
    if (isMounted.current) {
      setFilled(props.filled);
    }
  }, [props.filled]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const isOfferActive =
    // new Date("2024-10-09T00:00:00.024Z")?.getTime() > Date.now() &&
    // new Date("2024-10-01T00:00:00.024Z")?.getTime() < Date.now() &&
    props.buildingId === 6 && userInfo?.betaChallenge;

  return (
    <div
      className={`main-box-item pt-2 text-light pb-5 ${
        !props.countDown && props.disabled ? "button-disabled" : ""
      } ${props.className}`}
    >
      <div className={"mb-title text-center "}>
        <h3
          style={{ minHeight: 30 }}
          className={`h6 fw-bold thirteen-step ${
            props.disabled || props.status === 0 ? "pt-2" : ""
          }`}
        >
          {props.title}
          {!props.disabled && props.status !== 0 && props?.count && (
            <button className="mb-title-btn fw-bold text-light mx-2 pgtr-2">
              {props.count?.toString()}
            </button>
          )}
        </h3>
      </div>
      {isOfferActive && (
        <div
          style={{ animation: "pulse 1.5s infinite" }}
          onClick={() => setChallengeDialog(true)}
          className="special-offer badge bg-danger"
        >
          <div className="mb-1">Clash & Claim</div>
          <div className="mb-1">Free Barracks & Heroes</div>
          {/* <Countdown
            date={new Date("2024-05-09T00:00:00.024Z")}
            renderer={({ formatted }) => (
              <div className="d-flex align-items-center justify-content-center">
                <img width={14} src="/assets/images/icons/timer.png" />
                &nbsp;
                {formatted.days}:{formatted.hours}:{formatted.minutes}:
                {formatted.seconds}
              </div>
            )}
          /> */}
        </div>
      )}

      <div className="mb-body px-4">
        <div
          style={{ position: "relative" }}
          onClick={() =>
            props.status !== -1 && props.status !== 5 && props.onDetail(props)
          }
          className="image px-2 pointer text-center position-relative"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 20,
            }}
          />
          {!props.disabled &&
            parseInt(props.count?.toString() || "") !== 8 &&
            props.status !== 0 &&
            props.status !== 5 && (
              <a className="badge pgtr-3-4 upgrade-badge  ">
                {props.discount ? (
                  <div
                    className="circle bg-danger"
                    style={{
                      minWidth: 8,
                      minHeight: 8,
                      position: "absolute",
                      top: 5,
                      right: 12,
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <img
                  src="/assets/images/buttons/upgrade-land.png"
                  className=" "
                />
              </a>
            )}
          {Boolean(props.discount) &&
            props.discount! > 0 &&
            props.status !== 0 &&
            props.status !== 5 && (
              <div style={{ top: 25, bottom: "unset" }} className="off-badge">
                <div className="dark-yellow fs-lg">{props.discount}%</div>
                <small className="dark-yellow">OFF</small>
              </div>
            )}
          {props?.status === 2 ? (
            <div
              className="d-flex w-100 align-items-center"
              style={{
                height:
                  windowSize.width > 1199 && windowSize.width < 1400
                    ? 200
                    : 250,
              }}
            >
              <img
                style={{ width: "100%" }}
                src={props.video}
                className="img-fluid cursor-pointer"
              />{" "}
            </div>
          ) : (
            <img
              style={{
                filter: props.status === 0 ? "grayscale(100%)" : "",
                height:
                  windowSize.width > 1199 && windowSize.width < 1400
                    ? 210
                    : 250,
              }}
              src={props.image}
              title="CLEG Token Mine"
              alt="CLEG Token Mine"
              className="img-fluid cursor-pointer"
            />
          )}
        </div>

        <div className=" p-relative">
          <div
            style={{
              filter:
                props.status === 5 || props.status === 3 || props.countDown
                  ? "blur(5px)"
                  : "none",
            }}
            className="form-processbar  fs-med px-3 px-md-2 px-lg-3"
          >
            {props.status !== 3 && (
              <div className="mb-2 pgtr-3-1">
                <label className="d-flex justify-content-between">
                  <span>{props.buildingId !== 6 ? "Capacity" : "Troops"}</span>
                  <span>
                    {props.buildingId !== 6 ? (
                      new Decimal(filled).toFixed(2).toString()
                    ) : (
                      //   <CountUp decimals={2}
                      //     onUpdate={() => setTimeout(() => {
                      //         setLastValue(filled)
                      //     }, 980)} duration={0.9}
                      //     start={lastValue} end={parseFloat(roundFloat(filled))} />
                      <CountUp
                        duration={1}
                        start={0}
                        end={parseFloat(roundFloat(filled) + "")}
                      />
                    )}{" "}
                    / {props.capacity}
                  </span>
                </label>
                <div className="progress">
                  <div
                    style={{
                      width:
                        (
                          (parseInt(String(filled)) /
                            parseInt(String(props.capacity))) *
                          100
                        ).toString() + "%",
                    }}
                    className="progress-bar progress-bar-striped yellow"
                  ></div>
                </div>
              </div>
            )}

            {props.buildingId !== 6 ? (
              <div className="mb-2 fifteen-step pgtr-3-3">
                <label className="d-flex justify-content-between">
                  <span>Health</span>
                  <span>{props.health} / 100</span>
                </label>
                <div className="progress">
                  <div
                    style={{
                      width:
                        (
                          (parseInt(String(props.health)) / 100) *
                          100
                        ).toString() + "%",
                    }}
                    className={`progress-bar progress-bar-striped ${
                      +props.health >= 80
                        ? "green"
                        : +props.health >= 60
                        ? "blue"
                        : +props.health >= 40
                        ? "orange"
                        : +props.health >= 20
                        ? "darkOrange"
                        : "red"
                    }`}
                  ></div>
                </div>
              </div>
            ) : (
              <div className="mb-2 fifteen-step">
                <label className="d-flex justify-content-between">
                  <span>Training Queue</span>
                  <span>
                    {props.trainingQueue} / {props.maxQueue}
                  </span>
                </label>
                <div className="progress">
                  <div
                    style={{
                      width:
                        (
                          (parseInt(String(props.trainingQueue)) /
                            (props.maxQueue || 1)) *
                          100
                        ).toString() + "%",
                    }}
                    className={`progress-bar progress-bar-striped blue `}
                  ></div>
                </div>
              </div>
            )}
            <div
              style={{ minHeight: 22, marginTop: 10, fontSize: 12 }}
              className="d-flex mb-2 pgtr-3-2  justify-content-between"
            >
              <>
                <span>
                  <img src="/assets/images/icons/flash.png" />
                  {props.buildingId !== 6 ? "Production Rate " : "Healing"}
                </span>{" "}
                <span>
                  {!props.disabled && props.status !== 0
                    ? roundFloat(props.currentDailyProduction || 0, 0)
                    : "--"}
                  /Day{" "}
                </span>
              </>
            </div>
          </div>

          {props.countDown && (
            <div className="fs-med overlay px-3 px-md-2 px-lg-3 mb-2 ">
              <h5 className="text-center">Available In :</h5>
              <div>
                <Countdown
                  date={new Date(props.countDown)}
                  onComplete={() =>
                    setTimeout(() => {
                      props.refresh?.();
                    }, 1000)
                  }
                  renderer={({ formatted }) => (
                    <div
                      style={{ transform: "scale(0.8)" }}
                      className="fs-big justify-content-center d-flex"
                    >
                      {parseInt(formatted.days) ? (
                        <div className="time-badge">
                          {formatted.days}
                          <span className="dark-yellow">Days</span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="ms-2 time-badge">
                        {formatted.hours}
                        <span className="dark-yellow">Hours</span>
                      </div>
                      <div className="ms-2 time-badge">
                        {formatted.minutes}
                        <span className="dark-yellow">Minutes</span>
                      </div>
                      <div className="ms-2 time-badge">
                        {formatted.seconds}
                        <span className="dark-yellow">Seconds</span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          )}
          {props.status === 5 && (
            <div className="fs-med overlay px-3 px-md-2 px-lg-3 mb-2 ">
              <h5 className="text-center">Upgrading</h5>
              <div>
                <Countdown
                  date={new Date(props.upgradeTime || new Date())}
                  onComplete={() => props.refresh?.()}
                  renderer={({ formatted }) => (
                    <div
                      style={{ transform: "scale(0.8)" }}
                      className="fs-big justify-content-center d-flex"
                    >
                      {parseInt(formatted.days) ? (
                        <div className="time-badge">
                          {formatted.days}
                          <span className="dark-yellow">Days</span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="ms-2 time-badge">
                        {formatted.hours}
                        <span className="dark-yellow">Hours</span>
                      </div>
                      <div className="ms-2 time-badge">
                        {formatted.minutes}
                        <span className="dark-yellow">Minutes</span>
                      </div>
                      <div className="ms-2 time-badge">
                        {formatted.seconds}
                        <span className="dark-yellow">Seconds</span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          )}
          {props.status === 3 && (
            <div className="fs-med overlay px-3 my-3 px-md-2 px-lg-3">
              <h6 className="text-center fs-big text-danger">
                {props.title} is broken
              </h6>
            </div>
          )}
        </div>
      </div>
      <div className={"mb-button    text-center "}>
        {props.status === 3 ? (
          <Button
            onClick={() => props.repair?.(props?.id, props.title)}
            disabled={props.disabled}
            variant="rounded"
            className="form-btn lightbrown-btn mb-4  mx-auto "
          >
            <span className={"b-text fs-6 " + "px-5"}>Repair</span>
          </Button>
        ) : (
          <div className="spring-layer">
            <Button
              badgeStyles={{ right: 0 }}
              badge={
                props.status === 0 &&
                props.discount &&
                props.discount > 0 &&
                halloweenEvent.isActive ? (
                  <>
                    <div className="dark-yellow fs-lg"> {props.discount}%</div>
                    <small className="dark-yellow">OFF</small>
                  </>
                ):""
              }
              onClick={() =>
                props.status === 5
                  ? setDialog(true)
                  : props.submit?.(
                      props.status === 0 ? props?.buildingId : props?.id,
                      props.title,
                      props.firstLevelBuildingInfo
                    )
              }
              disabled={props.loading || props.disabled}
              variant={props.status !== 5 ? "rounded" : "wide-primary"}
              style={
                props.status === 5
                  ? { backgroundSize: "100% 100%", fontWeight: "bold" }
                  : undefined
              }
              className="form-btn pgtr-3 lightbrown-btn mb-4 mx-auto "
            >
              <span
                className={
                  "b-text fs-6 " +
                  (props.status !== 5 ? "px-5 twelve-step" : "px-2")
                }
              >
                {props.loading ? (
                  <CircularProgress style={{ width: 23 }} />
                ) : props.status === 0 ? (
                  "Build"
                ) : props.status !== 5 ? (
                  props.buildingId === 6 ? (
                    "Open "
                  ) : (
                    "Collect"
                  )
                ) : (
                  "Instant Upgrade"
                )}
              </span>
            </Button>
          </div>
        )}
      </div>

      <Dialog
        open={dialog}
        submitLabel={"Upgrade"}
        onSubmit={handleConfirmInstantUpgrade}
        loading={upgradeLoading}
        closeLabel="Cancel"
        onClose={() => setDialog(false)}
        title="Instant Upgrade"
      >
        <p>
          Are you sure to pay this amount in order to instant upgrade your{" "}
          {props.title} ?
        </p>
        <div
          style={{ marginBottom: -20 }}
          className="d-flex mt-3 fs-big flex-wrap justify-content-center"
        >
          <div className="">Cost:</div>
          {instantUpgradeData?.clegCost && (
            <div className="d-flex ms-2 align-items-center">
              <img
                src={"/assets/images/icons/cleg.png"}
                width={25}
                height={25}
              />
              {roundFloat(instantUpgradeData?.clegCost, 3)}
            </div>
          )}
          {instantUpgradeData?.stoneCost ? (
            <div className="d-flex ms-3 align-items-center">
              <img
                src={"/assets/images/icons/stone.png"}
                width={25}
                height={25}
              />
              {roundFloat(instantUpgradeData?.stoneCost, 3)}
            </div>
          ) : (
            ""
          )}
          {instantUpgradeData?.ironCost ? (
            <div className="d-flex ms-3 align-items-center">
              <img
                src={"/assets/images/icons/metal.png"}
                width={25}
                height={25}
              />
              {roundFloat(instantUpgradeData?.ironCost, 3)}
            </div>
          ) : (
            ""
          )}
        </div>
      </Dialog>

      <Dialog
        title="Beta Challenge"
        size="lg"
        open={challangeDialog}
        className="text-center"
        submitLabel="Accept Challenge"
        submitVariant="wide-primary"
        onSubmit={() => acceptChallenge()}
        loading={loading}
        onClose={() => setChallengeDialog(false)}
      >
        <img width={"100%"} src="/assets/images/beta-challenge.jpg" />
        <p className="text-center">
          <span style={{ fontSize: "18pt" }}>
            🏰⚔️ Free-To-Play Challenge ⚔️🏰
          </span>
          <br />
          (Beta)
        </p>
        <p className="text-center">
          🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹
          <br />✅ "F2P Challenge" is a Quest to be accomplished on our
          Beta!&nbsp;
        </p>
        <p>
          The speed of the Beta game is 100x! You'll be given 100k $CLEG, 100k
          Stone, 100k Iron in your Beta account. You've got 2 weeks period for
          training, attacking and winning.&nbsp;
          <br />
          ⚔️ The Ultimate Goal is to achieve a Victory in Treasure Island 128,
          Corsica Summits!
        </p>
        <p>🎁 Winners Prizes (for each person):</p>
        <p>
          1️⃣ Barracks Lvl 2<br />
          2️⃣ 1 Knight Lvl 1<br />
          3️⃣ 1 Mage Lvl 1<br />
          4️⃣ 1 Guardian Lvl 1
        </p>
        <p className="text-center">
          🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹
          <br />
          🛡⚔️ Get to Work and Conquer Treasure Island 128 ⚔️🛡
        </p>
      </Dialog>
    </div>
  );
};

export default MainBox;
