import React, { useEffect, useState } from "react";
import MidBox from "../../components/Boxes/MidBox";
import { Link, useParams } from "react-router-dom";
import { CustomIcon } from "../../components/Icons";
import ThousandsSeparator from "../../utils/thousands-separator";
import Network, { IsBeta, ProfileURL } from "../../utils/Network";
import { PlayerProfile } from "./types";
import TimeAgo from "javascript-time-ago";
import Button from "../../components/Button";
import useWindowSize from "../../hooks/useWindowSize";
import LevelBadge from "../../components/LevelBadge";
import { motion } from "framer-motion";
import CircularProgress from "../../components/CircularProgress";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../../hooks/useToast";
import useChat from "../../hooks/useChat";
import { IS_CHAT_ENABLED } from "../../config";
type Props = {};

const PlayerPublicProfile = (props: Props) => {
  const { id } = useParams();
  const axios = new Network();
  const dispatch = useDispatch();
  const [data, setData] = useState<PlayerProfile>();
  const [loading, setLoading] = useState<boolean>(false);
  const timeAgo = new TimeAgo("en-US");
  const userInfo = useSelector((state: any) => state.userInfo);
  const windowSize = useWindowSize();
  const openToast = useToast();
  const { userChat } = useChat();
  const handleGetUserData = async () => {
    const res = await axios.CallApi({
      url: "/player/profile?playerId=" + id,
      method: "get",
    });
    if (res.ok) setData(res.data);
  };
  const handleInviteToGuild = async () => {
    setLoading(true);
    const res = await axios.CallApi({
      url: `/guild/${userInfo?.guild?.id}/invite?playerIdToInvite=` + id,
      method: "get",
    });
    openToast(res);
    setLoading(false);
  };
  const handleSendPrivateMessage = () => {
    userChat(id?.toString());
  };

  useEffect(() => {
    handleGetUserData();
  }, [id]);

  return (
    <motion.div
      transition={{
        duration: 0.4,
      }}
      initial={{
        y: 500,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      exit={{
        y: 500,
        opacity: 0,
      }}
    >
      <MidBox
        title={data ? data.username : <Skeleton height={20} width={80} />}
        modal
      >
        {!data && (
          <div
            style={{ minHeight: 200 }}
            className="d-flex align-items-center  justify-content-center"
          >
            <CircularProgress />
          </div>
        )}
        {data && (
          <div
            style={{ maxWidth: 800 }}
            className="d-flex mx-auto flex-column gap-4"
          >
            <div className="d-flex bg-2 p-2 rounded align-items-center gap-2 ">
              <div className="flex-1">
                <div className="p-relative ">
                  <Link
                    to="/ranking"
                    className="status-badge pointer d-flex align-items-center gap-1"
                  >
                    <CustomIcon
                      width={25}
                      height={25}
                      className="mb-0"
                      icon="crown"
                    />
                    {ThousandsSeparator(data.ranks.GlobalRank)}
                  </Link>

                  <div
                    style={{
                      backgroundImage: "url(/assets/images/avatar-bg.png)",
                    }}
                    className={"main-avatar"}
                  >
                    {data.photoFile && (
                      <img
                        src={`${ProfileURL}/${data?.id}/${data?.photoFile}`}
                        width={"70%"}
                        height={"70%"}
                        draggable={false}
                        style={{
                          position: "absolute",
                          borderRadius: "50%",
                          top: "12%",
                          right: 0,
                          left: "12%",
                        }}
                      />
                    )}
                  </div>

                  <Button
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: windowSize.width < 500 ? 70 : 100,
                    }}
                    disabled={data?.id === userInfo?.id || !IS_CHAT_ENABLED}
                    onClick={() => {
                      handleSendPrivateMessage();
                    }}
                    className="px-2  "
                    variant="primary"
                    size="small"
                  >
                    <CustomIcon
                      width={14}
                      height={14}
                      icon="chat"
                      className="me-1"
                      type="svg"
                    />
                    Message
                  </Button>
                </div>
              </div>
              <div className="flex-1 text-md-right ">
                <p>
                  Ranking Score: <b>{ThousandsSeparator(data.score)}</b>
                </p>
                <p className="fs-sm-small mb-0 ">
                  Joined at:{" "}
                  {timeAgo.format(new Date(data.registerDate), "twitter")}{" "}
                  <small>
                    (about {timeAgo.format(new Date(data.registerDate))})
                  </small>
                </p>
                {userInfo?.guild?.id && +id! !== +userInfo?.id && (
                  <Button
                    disabled={loading}
                    onClick={() => handleInviteToGuild()}
                    className="px-2 bg-100 pb-3 mt-2  ms-auto"
                    variant="wide-success"
                    size="small"
                  >
                    Invite To Guild
                  </Button>
                )}
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <h3 className="mb-0">Ranks</h3>
                <hr className=" my-0 ms-2 flex-1" />
              </div>
              <div className="row align-items-center">
                <div className="col-4 d-flex text-center flex-column ">
                  <h4 className="text-yellow fs-sm-lg">
                    #{ThousandsSeparator(data.ranks.GlobalRank)}
                  </h4>
                  <div>All</div>
                </div>
                <div className="col-4 d-flex text-center align-items-center flex-column ">
                  <CustomIcon
                    icon="crown"
                    className="me-1"
                    width={65}
                    height={65}
                  />
                  <h6 className="bold mt-1">Rank</h6>
                </div>
                <div className="col-4 d-flex text-center flex-column ">
                  <h4 className="text-yellow fs-sm-lg">
                    #{ThousandsSeparator(data.ranks.GlobalRankMonth)}
                  </h4>
                  <div>Month</div>
                </div>
              </div>
              <div className="row mt-3 bg-2 p-3 align-items-center">
                <div className="col-4 d-flex text-center flex-column ">
                  <h4 className="text-yellow fs-sm-lg">
                    #{ThousandsSeparator(data.ranks.ReferralRank)}
                  </h4>
                  <div>All</div>
                </div>
                <div className="col-4 d-flex text-center align-items-center flex-column ">
                  <CustomIcon
                    icon="rank-flag"
                    className="me-1"
                    width={65}
                    height={"auto"}
                  />
                  <h6 className="bold mt-1">Society Builders</h6>
                </div>
                <div className="col-4 d-flex text-center flex-column ">
                  <h4 className="text-yellow fs-sm-lg">
                    #{ThousandsSeparator(data.ranks.ReferralRankMonth)}
                  </h4>
                  <div>Month</div>
                </div>
              </div>
              <div className="row mt-3 align-items-center">
                <div className="col-4 flex-wrap d-flex text-center gap-3 justify-content-center ">
                  <div>
                    <h4 className="text-yellow fs-sm-lg">
                      #{ThousandsSeparator(data.ranks.WarlordRank)}
                    </h4>
                    <div>Impact</div>
                  </div>
                  <div>
                    <h4 className="text-yellow fs-sm-lg">
                      #{ThousandsSeparator(data.ranks.WarlordRankInvades)}
                    </h4>
                    <div>Invades</div>
                  </div>
                </div>
                <div className="col-4 d-flex text-center align-items-center flex-column ">
                  <CustomIcon
                    icon="glory"
                    className="me-1"
                    width={65}
                    height={"auto"}
                  />
                  <h6 className="bold mt-1">War Lords</h6>
                </div>
                <div className="col-4 flex-wrap d-flex text-center gap-3 justify-content-center ">
                  <div>
                    <h4 className="text-yellow fs-sm-lg">
                      #{ThousandsSeparator(data.ranks.WarlordRankDefenses)}
                    </h4>
                    <div>Defences</div>
                  </div>
                  <div>
                    <h4 className="text-yellow fs-sm-lg">
                      #{ThousandsSeparator(data.ranks.WarlordRankRaids)}
                    </h4>
                    <div>Raids</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <h3 className="mb-0">Badges and Trophies</h3>
                <hr className=" my-0 ms-2 flex-1" />
              </div>
              <div className="py-5 pb-4 text-center">
                <div className="width-fit d-inline px-4 py-2 rounded bg-2">
                  Coming Soon...
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <h3 className="mb-0">Village</h3>
                <hr className=" my-0 ms-2 flex-1" />
              </div>
              <div className="row">
                {data.buildings.map((building) => (
                  <div className="col-4 col-sm-3    p-relative text-center">
                    <img
                      className="w-100"
                      style={{ maxWidth: 150 }}
                      src={`https://core.chainoflegends.com/Admin/files/Photos/Buildings/${building.buildingId}/${building.photoFile}`}
                    />
                    <LevelBadge
                      style={{
                        zIndex: 1,
                        top:
                          windowSize.width < 420
                            ? "40%"
                            : windowSize.width < 790
                            ? "50%"
                            : "60%",
                        bottom: "unset",
                      }}
                      level={building.currentLevel}
                    />
                    <div>{building.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="d-flex mt-3 align-items-center">
                <h3 className="mb-0">Troops</h3>
                <hr className=" my-0 ms-2 flex-1" />
              </div>
              {/* <PerfectScrollbar style={{ maxHeight: 500 ,width:'100%'}}> */}
              <div className="row mx-0 mt-3">
                {data.troops.length === 0 && (
                  <div className="py-4 d-flex justify-content-center align-items-center">
                    <div className="p-3 bg-2 rounded">
                      Can not find any troops...
                    </div>
                  </div>
                )}
                {data.troops
                  ?.sort((a, b) => b.level - a.level)
                  .sort((a, b) => b.items?.length - a.items?.length)
                  ?.map((troop) => (
                    <div className="col-12 col-sm-6 p-relative text-center">
                      <div
                        className={
                          "land-box-tiny d-flex flex-column justify-content-center  py-4 text-light "
                        }
                      >
                        {/* {troop.level && (
                      <button className="level-btn brk-step4 fw-bold text-light mx-2">
                        {troop.level}
                      </button>
                    )} */}
                        <div
                          style={{ marginTop: -10 }}
                          className="lb-body  align-items-center d-flex px-2"
                        >
                          <div
                            style={{ position: "relative" }}
                            className="image px-2 text-center"
                          >
                            {/* {!props.train && props.xp?.toString() ? <div style={{ fontSize: 14 }} className='xp-badge brk-step5'>
                                XP: {props.xp}/{props.maxXp}
                            </div> : ''} */}
                            <div
                              style={{
                                width: "100%",
                                background: "#332e27",
                                borderRadius: "50%",
                              }}
                              className="d-flex align-items-center p-1 justify-content-center"
                            >
                              <img
                                style={{
                                  marginBottom: 0,
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                                width={50}
                                src={troop?.icon}
                                alt={troop?.name}
                                className="circle"
                              />
                            </div>
                          </div>
                          <div className="lb-title w-100">
                            <div className="d-flex w-100  align-items-center">
                              <h3 style={{ fontSize: 18 }} className="h5 mb-0">
                                <>{troop?.name}</>
                              </h3>
                              <LevelBadge
                                style={{
                                  position: "static",
                                  width: 47,
                                  height: 48,
                                  fontSize: 24,
                                  marginInline: "auto 10px",
                                  zIndex: 1,
                                }}
                                level={troop?.level}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-100 px-4 px-md-3 mb-2 mt-1 align-items-center justify-content-end d-flex flex-wrap">
                          {troop.items?.map((item, index) => (
                            <div>
                              <div
                                style={{ width: "fit-content" }}
                                className={
                                  "p-relative p-1  circle bg-3  " +
                                  (index !== 0 && "ms-1")
                                }
                              >
                                <img src={item.image} width={40} height={40} />
                                <div className="item-level1">{item.level}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* </PerfectScrollbar> */}
            </div>
          </div>
        )}
      </MidBox>
    </motion.div>
  );
};

export default PlayerPublicProfile;
