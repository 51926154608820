import { EventEmitter } from 'events';
import { BattleModeInfo, BattlePlayerInfo, BattleRecord, BattleResult, BattleRules, FormationCampArmy } from '../types';

export enum EventNames {
  StartAttack = 'startAttack',
  Damage = 'damage',
  BattleEnd = "battleEnd",
  BattleResult = "battleResult",
  Ping = "ping",
  StartFormation = "startFormation",
  BattleList = "battleList",
  NewBattle = "newBattle",
  EndBattle = "endBattle",
}

export interface EventMap {
  [EventNames.StartAttack]: { modelId: string };
  [EventNames.Damage]: { modelId: string, damage: number };
  [EventNames.BattleEnd]: null;
  [EventNames.Ping]: number;
  [EventNames.BattleList]: BattleRecord[];
  [EventNames.StartFormation]: { troops: FormationCampArmy[], rules: BattleRules, battleModeInfo: BattleModeInfo };
  [EventNames.BattleResult]: BattleResult;
  [EventNames.NewBattle]: { battleId: string, attackerNames: string[], defenderNames: string[], attackers: BattlePlayerInfo[], defenders: BattlePlayerInfo[] };
  [EventNames.EndBattle]: { battleId: string, winnerPlayerIds: number[], battleResult: BattleResult, attackers: BattlePlayerInfo[], defenders: BattlePlayerInfo[] };
}

class EventBus {
  private emitter = new EventEmitter();

  on<K extends keyof EventMap>(event: K, listener: (payload: EventMap[K]) => void): this {
    this.emitter.on(event, listener);
    return this;
  }

  off<K extends keyof EventMap>(event: K, listener: (payload: EventMap[K]) => void): this {
    this.emitter.off(event, listener);
    return this;
  }

  emit<K extends keyof EventMap>(event: K, payload: EventMap[K]): boolean {
    return this.emitter.emit(event, payload);
  }
}

export default new EventBus();