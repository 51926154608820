import React from "react";
import Button from "../../../../components/Button";
import { Link } from "react-router-dom";
type Props = {};

const RankingBoxEmptyState: React.FC<Props> = () => {
  return (
    <div className="d-flex flex-column gap-2 fs-small align-items-center justify-content-center">
      <div className="dark-yellow text-center">
        There is no Ranking Data yet!
        <br />
        Stay tuned for updates!
      </div>
      {/* <Link to="/barracks">
        <Button variant="default" className="bg-100 text-yellow-2 px-lg-3 ">
          Add Troops
        </Button>
      </Link> */}
    </div>
  );
};

export default RankingBoxEmptyState;
