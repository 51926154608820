import React, { useEffect, useState } from 'react'
import LandBox from '../components/Boxes/LandBox'
import { motion } from 'framer-motion'
import Button from '../components/Button'
import TipList from '../components/TipList'
import Network, { FTPURL } from '../utils/Network'
import { roundFloat } from '../utils/round-float'
import Dialog from '../components/Dialog'
import useToast from '../hooks/useToast'
import CountUp from 'react-countup';

// import {
//     SortableContainer,
//     SortableElement, arrayMove,
//     SortableHandle
// } from "react-sortable-hoc";
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ArrowSvg } from './MyLands'
import CircularProgress from '../components/CircularProgress'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Helmet } from 'react-helmet'


type Props = {}

const MyLandsNew = (props: Props) => {
    const axios = new Network()
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const openToast = useToast()
    const [btnLoading, setBtnLoading] = useState(false)
    const [stats, setStats] = useState()
    const [viewType, setViewType] = useState(+localStorage.getItem('card-sort') || 1)
    const [currentLevel, setCurrentLevel] = useState(1)
    const [sellDialog, setSellDialog] = useState({ open: false, id: [], sellPrice: null, qty: 1 })
    const [confimDialog, setConfirmDialog] = useState({
        open: false,
        submit: null,
        title: '',
        msg: ''
    })

    const handlePlaceOrder = async (id: number[], price: string, qty: number) => {
        if (qty > id?.length) {
            return openToast({
                ok: false,
                message: "You can't sell more than what you have."
            })
        }
        if (!price) {
            return openToast({
                ok: false,
                message: "Please enter an amount."
            })
        }
        setBtnLoading(true)
        const response = await axios.CallApi({
            url: `/playerland/place-order`,
            body: {
                clegPrice: price,
                playerLandIds: id?.slice(0, qty)
            }
        })
        openToast(response)
        if (response.ok) {
            handleGetLands()
        }
        setSellDialog(prev => ({ ...prev, open: false }))
        setBtnLoading(false)
    }


    const handleGetLevel = async () => {
        const response = await axios.CallApi({
            url: "/building",
            method: 'get'
        })
        if (response?.ok) {

            setCurrentLevel(response.data[0]?.currentLevel)
        }
    }

    const handleCancelOrder = async (id: number[]) => {
        setBtnLoading(true)
        const response = await axios.CallApi({
            url: `/playerland/cancel-order`,
            method: 'post',
            body: {
                playerLandIds: id
            }
        })
        openToast(response)
        if (response.ok) {
            setConfirmDialog(prev => ({ ...prev, open: false }))
            handleGetLands()
        }
        setBtnLoading(false)
    }

    const handleGetLands = async () => {
        setLoading(true)
        const response = await axios.CallApi({
            url: '/playerland/my-lands',
            method: 'get'
        })
        if (response.ok) {
            setStats(
                {
                    ...response.data,
                    data: undefined
                }
            )
            setData(response.data?.data?.map((land: any, index: number) => ({
                ...land?.info,
                image: FTPURL + "/" + land?.info?.photo,
                video: FTPURL + "/" + land?.info?.video,
                name: land?.info?.name,
                rate: land?.info?.productionRatePercent,
                qty: land?.ids?.length,
                ids: land.ids,
                cleg: roundFloat(land?.info?.clegMinedCount) + "/" + roundFloat(land?.info?.totalCleg),
                stone: roundFloat(land?.info?.stoneMinedCount) + "/" + roundFloat(land?.info?.totalStone),
                metal: roundFloat(land?.info?.ironMinedCount) + "/" + roundFloat(land?.info?.totalIron)
            })))
        }
        setLoading(false)
    }

    useEffect(() => {
        handleGetLevel()
        handleGetLands()
    }, [])

    // const Handle = SortableHandle<any>(({ tabIndex, item }) => (
    //     <LandBox  {...item}
    //         action={
    //             item.state === 2 ? <Button loading variant='wide'
    //             >
    //                 Mining ...
    //             </Button> : item.state === 1 ? <Button onClick={() => setSellDialog({ open: true, id: item?.id, sellPrice: null })} variant='wide'>
    //                 Sell
    //             </Button> : item.state === 4 ? <div className=''>
    //                 <Button
    //                     onClick={() => setConfirmDialog({
    //                         open: true,
    //                         title: 'Are you sure?',
    //                         msg: 'Are you sure to cancel your sell order ?',
    //                         submit: () => handleCancelOrder(item?.id)
    //                     })}
    //                     variant='wide'>
    //                     Cancel Order
    //                 </Button>
    //             </div> : <>
    //             </>
    //         } />
    // ));

    // const HandlerItem = SortableElement<any>(({ item, }) => (<motion.div
    //     key={item.id + "-land-box"} initial={isMobile ? undefined : { x: window.innerWidth, opacity: 0 }}
    //     animate={{
    //         x: 0, opacity: 1
    //     }}
    //     transition={{
    //         duration: 0.4, delay: item.id * 0.1
    //     }}
    //     exit={{ x: -window.innerWidth, opacity: 0 }}
    //     className='col-12 col-sm-6 col-md-4 col-lg-3'>
    //     <Handle item={item} />
    // </motion.div>
    // ))

    // const SortableList = SortableContainer((props: any) => {
    //     const { items, ...restProps } = props;
    //     return (
    //         <div className='row'>
    //             {data.map((item: any, index: number) => (
    //                 <HandlerItem item={item} />
    //             ))}
    //         </div>
    //     );
    // });

    // const onSortEnd = ({ oldIndex, newIndex }) => {
    //     setData(arrayMove(data, oldIndex, newIndex));
    // };


    return (
        <SkeletonTheme enableAnimation={!isMobile} baseColor="#352d22" highlightColor="#3e362d">
            <Helmet>
                <title key={'title'}>
                    My Lands | Chain Of Legends
                </title>
            </Helmet>
            <div className='row mt-3 lands-step2'>
                {/* <SortableList
                    useDragHandle
                    axis="xy"
                    onSortEnd={onSortEnd}
                /> */}
                <motion.div className='col-12 d-flex align-items-center  justify-content-between pb-3'
                    initial={isMobile ? undefined : { opacity: 0 }}
                    animate={{
                        x: 0, opacity: 1
                    }}
                    transition={{
                        duration: 0.4, delay: 0.5
                    }}
                    exit={{ y: -700, opacity: 0 }}
                >
                    <Link to="/my-lands">
                        <a className='dark-yellow'  >
                            <ArrowSvg width={40} height={30} style={{ transform: 'rotate(180deg)' }} />   My lands(Old Version)
                        </a>
                    </Link>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip  {...props}>
                            Refresh
                        </Tooltip>}
                    >
                        <a onClick={handleGetLands} className='pointer refresh'>
                            <img src='/assets/images/icons/refresh.png' width={30} height={30} />
                        </a>
                    </OverlayTrigger>



                </motion.div>

                <motion.div initial={isMobile ? undefined : { opacity: 0 }}
                    animate={{
                        x: 0, opacity: 1
                    }}
                    transition={{
                        duration: 0.4,
                    }}
                    exit={{ x: -window.innerWidth, opacity: 0 }} className='lands-stats ' >
                    <div className='row p-relative text-center'>

                        <div className='col-6 col-sm-4 col-md-3'>
                            <div className='dark-yellow'>
                                <img width={25} height={25} src="https://core.chainoflegends.com/Admin/files/Photos/Lands/2/land-common.png" />&nbsp;Total Lands :
                                <b> {loading ? <Skeleton
                                    width={50}
                                    height={15}
                                    enableAnimation
                                /> : <CountUp duration={1} decimals={0} separator="," end={stats?.totallands} />
                                }</b>
                            </div>

                        </div>
                        <div className='col-6 col-sm-4 col-md-3'>
                            <div className='dark-yellow'>
                                <img width={25} height={25} src="/assets/images/icons/cleg.png" />&nbsp;Total Cleg :
                                <b> {loading ? <Skeleton
                                    width={50}
                                    height={15}
                                    enableAnimation
                                /> : <CountUp duration={1.1} decimals={0} separator="," delay={0.1} end={stats?.totalCleg} />
                                }</b>
                            </div>
                        </div>
                        <div className='col-6 col-sm-4 col-md-3'>
                            <div className='dark-yellow'>
                                <img width={25} height={25} src="/assets/images/icons/stone.png" />&nbsp;Total Stone :
                                <b> {loading ? <Skeleton
                                    width={50}
                                    height={15}
                                    enableAnimation
                                /> : <CountUp separator="," duration={1.2} decimals={0} delay={0.2} end={stats?.totalStone} />
                                }</b>
                            </div>
                        </div>
                        <div className='col-6 col-sm-4 col-md-3'>
                            <div className='dark-yellow'>
                                <img width={25} height={25} src="/assets/images/icons/metal.png" />&nbsp;Total Iron :
                                <b> {loading ? <Skeleton
                                    width={50}
                                    height={17}
                                    enableAnimation
                                /> : <CountUp separator="," duration={1} decimals={0} delay={0.3} end={stats?.totalIron} />
                                }</b>
                            </div>
                        </div>
                    </div>

                </motion.div>

                <motion.div
                    initial={isMobile ? undefined : { opacity: 0 }}
                    animate={{
                        x: 0, opacity: 1
                    }}
                    transition={{
                        duration: 0.4,
                    }}
                    exit={{ x: -window.innerWidth, opacity: 0 }}
                    className='col-12 d-flex align-items-center justify-content-end mt-2 mt-md-0 mb-0 mb-md-3'>
                    <div onClick={() => {
                        setViewType(1)
                        localStorage.setItem('card-sort', '1')
                    }} className={`sort-btn ${viewType === 1 && 'active'}`}>
                        <img width={22} height={22} src="/assets/images/icons/grid.png" />
                    </div>
                    <div onClick={() => {
                        setViewType(2)
                        localStorage.setItem('card-sort', '2')
                    }} className={`ms-2 sort-btn  ${viewType === 2 && 'active'}`} >
                        <img width={22} height={22} src="/assets/images/icons/grid2x.png" />
                    </div>
                </motion.div>
                {loading && [1, 2, 3, 4].map((key) => (
                    <motion.div key={key + "-loading-land-box"} initial={isMobile ? undefined : { opacity: 0 }}
                        animate={{
                            x: 0, opacity: 1
                        }}
                        transition={{
                            duration: 0.4, delay: key * 0.05
                        }}
                        exit={{ x: -window.innerWidth, opacity: 0 }}
                        className='col-12 col-sm-6 col-md-4 mt-2 col-lg-3'>
                        <LandBox
                            loading={true}
                            type={viewType}
                            action={
                                <Button className='w-100' disabled variant='wide'>
                                    Sell
                                </Button>
                            } />
                    </motion.div>
                ))}

                {!loading && data?.length === 0 && <Link to="/marketplace">
                    <motion.div
                        initial={isMobile ? undefined : { opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.3 }} exit={{ opacity: 0 }}
                        className="alert alert-warning pointer  py-2" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16"><path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path></svg>
                        &nbsp;&nbsp;You don't have any land , you can buy in <span className="link-style color-inherit bold"> MarketPlace</span> . &nbsp;
                    </motion.div>
                </Link>}
                {!loading && viewType === 1
                    && data?.map((item: any, key: number) => <motion.div key={key + "-land-box"} initial={isMobile ? undefined : { opacity: 0 }}
                        animate={{
                            x: 0, opacity: 1
                        }}
                        transition={{
                            duration: 0.4, delay: key * 0.05
                        }}
                        exit={{ x: -window.innerWidth, opacity: 0 }}
                        className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'>
                        <LandBox  {...item}
                            hint={item.landId > 1 && currentLevel === 0}
                            className="land-box1"
                            image={item?.video}
                            action={
                                item.state === 2 ? <a
                                    style={{ marginBottom: -25 }}
                                    className='d-flex w-100 text-center align-items-center lands-step3 justify-content-center dark-yellow'
                                >
                                    <CircularProgress />    Mining  {item?.buildings && item?.buildings?.length !== 0 ? <>
                                        (
                                        {item?.buildings?.find((building: string) => building === "ClegMine") && <img width={22} src="/assets/images/icons/cleg.png" />}
                                        {item?.buildings?.find((building: string) => building === "StoneMine") && <img width={22} src="/assets/images/icons/stone.png" />}
                                        {item?.buildings?.find((building: string) => building === "IronMine") && <img width={22} src="/assets/images/icons/metal.png" />}
                                        )
                                    </>
                                        : '...'}
                                </a> : item.state === 1 ? <Button
                                    className='w-100 lands-step4'
                                    onClick={() => setSellDialog({ open: true, id: item?.ids, sellPrice: null, qty: 1 })} variant='wide'>
                                    Sell
                                </Button> : item.state === 4 ? <div className=''>
                                    <Button
                                        className='w-100'
                                        onClick={() => setConfirmDialog({
                                            open: true,
                                            title: 'Are you sure?',
                                            msg: 'Are you sure to cancel your sell order ?',
                                            submit: () => handleCancelOrder(item?.ids)
                                        })}
                                        variant='wide'>
                                        Cancel Order
                                    </Button>
                                </div> : <>
                                </>
                            } />
                    </motion.div>)}
                {!loading && viewType === 2 && data?.map((item: any, key: number) => <motion.div key={key + "-land-box"} initial={isMobile ? undefined : { opacity: 0 }}
                    animate={{
                        x: 0, opacity: 1
                    }}
                    transition={{
                        duration: 0.4, delay: key * 0.05
                    }}
                    exit={{ x: -window.innerWidth, opacity: 0 }}
                    className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2 mt-md-0'>
                    <LandBox  {...item}
                        hint={item.landId > 1 && currentLevel === 0}
                        className="land-boxt1"
                        image={item?.video}
                        type={viewType}
                        action={
                            item.state === 2 ? <a
                                className='d-flex ms-2  w-100 text-center fs-tiny align-items-center flex-column lands-step3  justify-content-center dark-yellow'
                            >
                                <CircularProgress style={{ width: 15 }} />  <div>Mining
                                </div>    {item?.buildings && item?.buildings?.length !== 0 ? <div >
                                    (
                                    {item?.buildings?.find((building: string) => building === "ClegMine") && <img width={15} src="/assets/images/icons/cleg.png" />}
                                    {item?.buildings?.find((building: string) => building === "StoneMine") && <img width={15} src="/assets/images/icons/stone.png" />}
                                    {item?.buildings?.find((building: string) => building === "IronMine") && <img width={15} src="/assets/images/icons/metal.png" />}
                                    )
                                </div>
                                    : '...'}
                            </a> : item.state === 1 ? <Button
                                className=' ms-2 lands-step4'
                                size='small'
                                style={{}}
                                onClick={() => setSellDialog({ open: true, id: item?.ids, sellPrice: null, qty: 1 })}
                                variant='default'>
                                Sell
                            </Button> : item.state === 4 ? <div className=''>
                                <Button
                                    className=' ms-2 px-2'
                                    size='small'
                                    onClick={() => setConfirmDialog({
                                        open: true,
                                        title: 'Are you sure?',
                                        msg: 'Are you sure to cancel your sell order ?',
                                        submit: () => handleCancelOrder(item?.ids)
                                    })}
                                    variant='secondary'>
                                    Cancel Order
                                </Button>
                            </div> : <>
                            </>
                        } />
                </motion.div>)}
            </div>
            <br />
            <div className='mt-4'>
                <TipList
                    className='mt-4'
                    item={[
                        'You need lands to mine resources. Mining buildings use lands with priority from left to right.',
                        'When land resources are finished up, It will be dismissed.',
                        'The Mining Building can only mine one land at a time.',

                    ]}
                />
            </div>
            <Dialog loading={btnLoading} title={"Sell Land"}
                closeLabel='Cancel' submitLabel='Submit'
                onSubmit={() => handlePlaceOrder(sellDialog?.id, sellDialog?.sellPrice, sellDialog?.qty)} open={sellDialog.open}
                onClose={() => setSellDialog(prev => ({ ...prev, open: false }))}>
                <p className='dark-yellow'>
                    Land Price
                </p>
                <input
                    onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                    onChange={(e) => setSellDialog(prev => ({ ...prev, sellPrice: e.target.value }))}
                    placeholder='Enter your price for this land, example:500'
                    type="text"
                    className='w-100 form-control form-input dark-yellow'
                />
                <br />
                <p className='dark-yellow d-flex flex-wrap justify-content-between'>
                    Quantity

                    <a className='pointer text-decoration-underline text-info' onClick={() => setSellDialog(prev => ({ ...prev, qty: sellDialog?.id?.length }))}>
                        Total<small>(x{sellDialog?.id?.length})</small>
                    </a>
                </p>
                <input
                    onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                    onChange={(e) => setSellDialog(prev => ({ ...prev, qty: parseFloat(e.target.value) }))}
                    value={sellDialog?.qty}
                    type="number"
                    min={1}
                    max={sellDialog?.id?.length}
                    style={{ marginBottom: -35 }}
                    className='w-100 form-control form-input dark-yellow'
                />
            </Dialog>

            <Dialog loading={btnLoading} title={confimDialog?.title}
                closeLabel='No' submitLabel='Yes'
                onSubmit={confimDialog?.submit} open={confimDialog?.open}
                onClose={() => setConfirmDialog(prev => ({ ...prev, open: false }))}>
                {confimDialog?.msg}
            </Dialog>
        </SkeletonTheme>

    )
}

export default MyLandsNew