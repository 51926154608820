import React, { useRef } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { roundFloat } from "../../utils/round-float";
import "react-loading-skeleton/dist/skeleton.css";
import useWindowSize from "../../hooks/useWindowSize";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import Countdown from "react-countdown";
import Decimal from "decimal.js-light";
import {  OverlayTrigger, Tooltip } from "react-bootstrap";
import ThousandsSeparator from "../../utils/thousands-separator";
import { PlayerTroopType } from "../../types/troop";
import { ItemBonusStat, } from "../../types/items";
import { CustomIcon } from "../Icons";

type Props = {
  action?: React.ReactNode;
  upgrade?: boolean;
  isLearn?: boolean;
  xp?: number;
  marketOrderPrice?: number;
  rate?: string | number;
  abilities?: any[];
  upgradeMinutes?: number;
  sellCard?: boolean;
  tokenID?: string | number;
  refresh?: Function;
  icon?: string;
  finishUpgradeTime?: string;
  isMarket?: boolean;
  name?: string;
  train?: boolean;
  cleg?: string | number;
  stone?: string | number;
  metal?: string | number;
  image?: string;
  hourlyHealing?: number;
  bid?: number;
  ask?: number;
  description?: string;
  draggable?: boolean;
  nft?: boolean;
  state?: number;
  healthBonus?: number;
  damage?: Element | React.ReactNode | number;
  armor?: React.ReactNode | number;
  bonus?: React.ReactNode | number;
  type?: React.ReactNode | number;
  range?: React.ReactNode | number;
  magicResistance?: React.ReactNode | number;
  clegPrice?: number;
  clegCost?: number;
  lastClegCost?: number;
  lastIronCost?: number;
  lastStoneCost?: number;
  hint?: boolean;
  loading?: boolean;
  PricePerCleg?: string | number;
  tabIndex?: number;
  minerLevel?: boolean;
  className?: string;
  troop?: any;
  level?: number;
  leverage?: number;
};
export const TroopState = {
  1: "Ready",
  2: "Training",
  3: "Upgrading",
  4: "In Adventure",
  5: "Merged",
  6: "For Sale",
};
const TroopBox = (props: Props & PlayerTroopType) => {
  const ref = useRef<any>();
  const windowSize = useWindowSize();
  const getWidth = () => ref?.current?.offsetWidth;

  return (
    <SkeletonTheme
      enableAnimation={!isMobile}
      baseColor="#352d22"
      highlightColor="#3e362d"
    >
      {!props.type || props.type === 1 ? (
        <div>
          <div
            ref={ref}
            className={"land-box py-4 text-light " + props.className}
          >
            {props.level && (
              <button className="level-btn brk-step4 fw-bold text-light mx-2">
                {props.level}
              </button>
            )}
            <div className="lb-body px-2 mb-3">
              <div
                ref={ref}
                className={"text-center mb-4 " + (!props.loading && "d-none")}
              >
                <span>
                  <Skeleton
                    width={getWidth() - 75}
                    height={getWidth() - 75}
                    borderRadius={15}
                    circle={windowSize.width >= 1400 ? true : false}
                  />
                </span>
              </div>
              {!props.loading && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  style={{ position: "relative" }}
                  className="image px-3  text-center"
                >
                  {!props.train && props.xp?.toString() ? (
                    <div
                      style={{ fontSize: 14 }}
                      className="xp-badge brk-step5"
                    >
                      XP: {props.xp}/{props.maxXp}
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      width: "100%",
                      height: 201,
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {props.leverage && (
                      <div className="p-absolute leverage">
                        {props.leverage}X
                      </div>
                    )}
                    <img
                      style={{
                        marginBottom: props.nft ? 20 : 0,
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      src={props?.image || props?.troop?.image}
                      alt={props?.name || props?.troop?.name}
                      className=""
                    />
                    <div className="abilities">
                      {props.abilities &&
                        props.abilities?.map((ability) => (
                          <OverlayTrigger
                            placement="bottom"
                            flip
                            overlay={
                              <Tooltip className="fs-tiny" {...props as any}>
                                <b>
                                  {" "}
                                  {ability?.Name} (+
                                  {ability?.Name === "Heal" && !props.isMarket
                                    ? ThousandsSeparator(
                                        roundFloat(
                                          ability?.Value +
                                            (parseFloat(ability?.Value) *
                                              props.bonus) /
                                              100
                                        )?.toString()
                                      )
                                    : ThousandsSeparator(ability?.Value)}
                                  )
                                </b>
                                <br />
                                <small>{ability.Comment}</small>
                              </Tooltip>
                            }
                            delay={{ show: 100, hide: 200 }}
                          >
                            <div style={{ marginTop: 5 }}>
                              {ability?.Icon && (
                                <img src={ability?.Icon} width={35} />
                              )}
                            </div>
                          </OverlayTrigger>
                        ))}
                    </div>
                  </div>
                </motion.div>
              )}
              <div className="lb-title">
                <h3 className="h5 mb-0">
                  {props.loading ? (
                    <Skeleton width={"30%"} borderRadius={10} height={20} />
                  ) : (
                    props?.name || props?.troop?.name
                  )}{" "}
                </h3>
                <div className="d-flex align-items-center justify-content-between">
                  {props.loading ? (
                    <>
                      <Skeleton width={100} borderRadius={10} height={15} />
                      <Skeleton width={30} borderRadius={10} height={20} />
                    </>
                  ) : (
                    <>
                      <h5 className="fs-med text-success-light m-0">
                        {props.isLearn && (
                          <>Health : {ThousandsSeparator(props.health)}</>
                        )}

                        {props.sellCard
                          ? "Click for details"
                          : props.train
                          ? "Available"
                          : TroopState[props?.state]}
                      </h5>
                      <div>
                        {!props.isLearn && props?.health?.toString() && (
                          <div
                            style={{
                              borderRadius: 15,
                              textAlign: "center",
                              marginTop: -18,
                            }}
                            className="fs-tiny bg-gray p-relative overflow-hidden percent-badge"
                          >
                            <div
                              style={{
                                width:
                                  (props?.health * 100) / props?.maxHealth +
                                  "%",
                                position: "absolute",
                                height: "100%",
                                left: 0,
                                backgroundColor:
                                  "var(--theme-color-green-dark)",
                                top: 0,
                              }}
                            />

                            <span style={{ zIndex: 10, position: "relative" }}>
                              Health :{" "}
                              {ThousandsSeparator(roundFloat(props?.health))}/
                              {ThousandsSeparator(props?.maxHealth)}
                            </span>
                          </div>
                        )}

                        {props.hourlyHealing ? (
                          <div className="fs-tiny text-end ms-auto mt-1 ">
                            <span style={{ color: "#44c97a" }}>
                              +{props?.hourlyHealing}/hour
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {!props.hourlyHealing && props.healthBonus ? (
                          <>
                            <div className="fs-tiny text-end mt-1 ">
                              <CustomIcon
                                icon="icon-health-restore"
                                className="me-1"
                                width={18}
                                height={20}
                              />
                              <span style={{ color: "#44c97a" }}>
                                +{" "}
                                {ThousandsSeparator(
                                  roundFloat(props?.healthBonus, 1, 1)
                                )}{" "}
                                Health
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      {props.train && (
                        <span className="fs-tiny bg-gray p-relative overflow-hidden percent-badge">
                          <div
                            style={{
                              width: "100%",
                              position: "absolute",
                              height: "100%",
                              left: 0,
                              backgroundColor: "var(--theme-color-green-dark)",
                              top: 0,
                            }}
                          />
                          <span style={{ zIndex: 10, position: "relative" }}>
                            Health : {props?.maxHealth}
                          </span>
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
              {props.loading ? (
                <div className="lb-list fs-med px-4 my-3">
                  <div className="lb-list-item mb-1">
                    <div>
                      <Skeleton width={70} borderRadius={5} height={18} />
                    </div>
                    <div className="fs-med">
                      {" "}
                      <Skeleton width={40} borderRadius={5} height={18} />
                    </div>
                  </div>
                  <div className="lb-list-item mb-1">
                    <div>
                      <Skeleton width={70} borderRadius={5} height={18} />
                    </div>
                    <div className="fs-med">
                      {" "}
                      <Skeleton width={40} borderRadius={5} height={18} />
                    </div>
                  </div>
                  <div className="lb-list-item mb-1">
                    <div>
                      <Skeleton width={70} borderRadius={5} height={18} />
                    </div>
                    <div className="fs-med">
                      {" "}
                      <Skeleton width={40} borderRadius={5} height={18} />
                    </div>
                  </div>
                </div>
              ) : (
                (!props.train || !isMobile) && (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        fontSize: props.train ? 12 : 15,
                        filter:
                          props.state === 3 ||
                          props.state === 2 ||
                          props.state === 6
                            ? "blur(5px)"
                            : "none",
                      }}
                      className="lb-list brk-step6 fs-med px-4 my-3"
                    >
                      <div className="lb-list-item mb-1">
                        <div>
                          <img
                            style={{ width: 25, height: 28 }}
                            className="me-1"
                            src="/assets/images/icons/damage.png"
                          />
                          Damage
                        </div>
                        <div className="fs-med">{props.damage}</div>
                      </div>
                      <div className="lb-list-item mb-1">
                        <div>
                          <img
                            style={{ width: 25, height: 28 }}
                            className="me-1"
                            src="/assets/images/icons/armor.png"
                          />
                          Armor
                        </div>
                        <div className="fs-med">{props.armor}</div>
                      </div>
                      <div className="lb-list-item mb-1">
                        <div>
                          <img
                            style={{ width: 25, height: 28 }}
                            className="me-1"
                            src="https://core.chainoflegends.com/assets/images/team-magic-resist.png"
                          />
                          Magic Resistance
                        </div>
                        <div className="fs-med">{props.magicResistance}</div>
                      </div>
                      <div className="lb-list-item mb-1">
                        <div>
                          <img
                            style={{ width: 25, height: 28 }}
                            className="me-1"
                            src="/assets/images/icons/range.png"
                          />
                          Range
                        </div>
                        <div className="fs-med">{props.range}</div>
                      </div>

                      {props.upgrade && (
                        <div className="lb-list-item mb-1">
                          <div>Health</div>
                          <div className="fs-med d-flex align-items-center">
                            {props.maxHealth}{" "}
                            <img
                              src="/assets/images/icons/arrow-right.svg"
                              className="mx-1"
                              style={{ width: 15 }}
                            />
                            <span className="color-blue bold">
                              {new Decimal(
                                props.maxHealth + props.maxHealth / 2
                              )
                                .toDecimalPlaces(1)
                                .toString()}
                            </span>
                          </div>
                        </div>
                      )}

                      {props.ask || props.bid ? (
                        <div
                          style={{
                            marginBottom: -30,
                            borderTop: "1px solid rgba(255,255,255,0.15)",
                          }}
                          className="lb-list-item fs-small mt-3 pt-3"
                        >
                          <div>
                            Buyer Price:{" "}
                            <span className="text-success bold">
                              {ThousandsSeparator(
                                props.bid?.toString() || "0"
                              ) || 0}
                            </span>
                          </div>

                          <div>
                            Seller Price:{" "}
                            <span className="text-danger bold">
                              {ThousandsSeparator(props.ask?.toString() || "0")}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {props?.state === 6 && (
                      <div className="fs-med overlay px-3 px-md-2 px-lg-3 mb-2">
                        <h5 className="text-center mt-3">For sale</h5>
                        <h6 className="bold dark-yellow ">
                          Price : {ThousandsSeparator(props.marketOrderPrice)}{" "}
                          <img
                            src="/assets/images/icons/cleg.png"
                            width={22}
                            height={22}
                          />
                          CLEG
                        </h6>
                      </div>
                    )}
                    {(props.state === 3 || props.state === 2) && (
                      <div className="fs-med overlay px-3 px-md-2 px-lg-3 mb-2 ">
                        <h5 className="text-center">
                          {props.state === 3 ? "Upgrading" : "Training"}
                        </h5>
                        <div>
                          <Countdown
                            date={new Date(props.finishUpgradeTime)}
                            onComplete={() =>
                              setTimeout(() => {
                                props.refresh();
                              }, 6000)
                            }
                            renderer={({ formatted }) => (
                              <div
                                style={{ transform: "scale(0.8)" }}
                                className="fs-big justify-content-center d-flex"
                              >
                                {parseInt(formatted.days) ? (
                                  <div className="time-badge">
                                    {formatted.days}
                                    <span className="dark-yellow">Days</span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="ms-2 time-badge">
                                  {formatted.hours}
                                  <span className="dark-yellow">Hours</span>
                                </div>
                                <div className="ms-2 time-badge">
                                  {formatted.minutes}
                                  <span className="dark-yellow">Minutes</span>
                                </div>
                                <div className="ms-2 time-badge">
                                  {formatted.seconds}
                                  <span className="dark-yellow">Seconds</span>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
            {props.upgrade && (
              <div className="fs-tiny text-center dark-yellow">
                Estimated Upgrade Time : <b>{props.upgradeMinutes}</b> minutes
              </div>
            )}
            {(props.train || props.isLearn) && (
              <div style={props.isLearn ? { marginBottom: -40 } : undefined}>
                {+props.clegCost?.toString()?.replaceAll(",", "") ? (
                  <div className="d-flex flex-wrap  justify-content-center fs-small mt-3 ">
                    <div className="">Cost:</div>
                    {props?.clegCost &&
                    (!props?.lastClegCost ||
                      props?.clegCost !== props?.lastClegCost) ? (
                      <div className="d-flex ms-2 align-items-center">
                        <img
                          src={"/assets/images/icons/cleg.png"}
                          width={25}
                          height={25}
                        />
                        {props?.clegCost}
                      </div>
                    ) : props?.clegCost ? (
                      <div className="d-flex ms-2 align-items-end">
                        <img
                          src={"/assets/images/icons/cleg.png"}
                          width={25}
                          height={25}
                        />
                        <div className="d-flex align-items-center flex-column">
                          <del className="text-tiny">{props?.lastClegCost}</del>
                          <div className="text-success-light bold">
                            {props?.clegCost}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {props?.stoneCost &&
                    (!props?.lastStoneCost ||
                      props?.stoneCost !== props?.lastStoneCost) ? (
                      <div className="d-flex ms-3 align-items-center">
                        <img
                          src={"/assets/images/icons/stone.png"}
                          width={25}
                          height={25}
                        />
                        {props?.stoneCost}
                      </div>
                    ) : props?.stoneCost ? (
                      <div className="d-flex ms-2 align-items-end">
                        <img
                          src={"/assets/images/icons/stone.png"}
                          width={25}
                          height={25}
                        />
                        <div className="d-flex align-items-center flex-column">
                          <del className="text-tiny">
                            {props?.lastStoneCost}
                          </del>
                          <div className="text-success-light bold">
                            {props?.stoneCost}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {props?.ironCost &&
                    (!props?.lastIronCost ||
                      props?.ironCost !== props?.lastIronCost) ? (
                      <div className="d-flex ms-3 align-items-center">
                        <img
                          src={"/assets/images/icons/metal.png"}
                          width={25}
                          height={25}
                        />
                        {props?.ironCost}
                      </div>
                    ) : props?.ironCost ? (
                      <div className="d-flex ms-2 align-items-end">
                        <img
                          src={"/assets/images/icons/metal.png"}
                          width={25}
                          height={25}
                        />
                        <div className="d-flex align-items-center flex-column">
                          <del className="text-tiny">{props?.lastIronCost}</del>
                          <div className="text-success-light bold">
                            {props?.ironCost}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="lb-button text-center px-2 d-flex justify-content-center">
              {props.loading ? (
                <div className="mt-1" style={{ marginBottom: -50 }}>
                  <Skeleton width={"90%"} borderRadius={10} height={50} />
                </div>
              ) : (
                props.action
              )}
            </div>
          </div>

          <div className="mb-3  d-flex align-items-center">
            {props.items?.map((item, index) => (
              <OverlayTrigger
                overlay={
                  <Tooltip className="fs-tiny">
                    {item?.item?.name}{" "}
                    <span className="fs-tiny">
                      (+{roundFloat(item.bonusValue)}{" "}
                      {ItemBonusStat[item?.item?.bonusStat]} ){" "}
                    </span>
                  </Tooltip>
                }
              >
                <div
                  className={
                    "p-relative p-1 rounded bg-dark  " + (index !== 0 && "ms-1")
                  }
                >
                  <img src={item.image} width={37} height={37} />
                  <div className="item-level1">{item.level}</div>
                </div>
              </OverlayTrigger>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div
            ref={ref}
            style={{ paddingBottom: 30 }}
            className={
              "land-box-tiny overflow-hidden pt-3  text-light " +
              props.className
            }
          >
            <div className="lb-body d-flex px-2 ">
              <div
                ref={ref}
                className={"text-center mb-4 " + (!props.loading && "d-none")}
              >
                <span>
                  <Skeleton
                    className="ms-2"
                    width={65}
                    height={65}
                    borderRadius={15}
                    circle={windowSize.width >= 1400 ? true : false}
                  />
                </span>
              </div>
              {!props.loading && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  style={{ position: "relative" }}
                  className="image px-2 text-center"
                >
                  {/* {!props.train && props.xp?.toString() ? <div style={{ fontSize: 14 }} className='xp-badge brk-step5'>
                                XP: {props.xp}/{props.maxXp}
                            </div> : ''} */}
                  <div
                    style={{
                      width: "100%",
                      background: "#332e27",
                      borderRadius: "50%",
                    }}
                    className="d-flex p-relative align-items-center p-1 justify-content-center"
                  >
                    <img
                      style={{
                        borderRadius: "50%",
                        marginBottom: props.nft ? 20 : 0,
                      }}
                      width={50}
                      src={props?.icon || props?.troop?.icon}
                      alt={props?.name || props?.troop?.name}
                      className=""
                    />
                    {props.leverage && (
                      <div  style={{bottom:-10,width:30,height:35,right:0,left:0,fontSize:10}} className="p-absolute mx-auto leverage">
                        {props.leverage}X
                      </div>
                    )}
                  </div>
                </motion.div>
              )}
              <div className="lb-title w-100">
                <div className="d-flex w-100  align-items-center">
                  <h3 style={{ fontSize: 18 }} className="h5 mb-0">
                    {props.loading ? (
                      <Skeleton width={"90px"} borderRadius={10} height={20} />
                    ) : (
                      <>
                        {props?.name || props?.troop?.name}
                        {!props.isLearn && !props.sellCard && (
                          <small style={{ fontSize: 11 }}>
                            {" "}
                            (
                            {props.sellCard
                              ? "Click for details"
                              : props.train
                              ? "Available"
                              : TroopState[props?.state]}
                            )
                          </small>
                        )}
                      </>
                    )}
                  </h3>
                  {props.sellCard && (
                    <div
                      style={{ color: "#399C61", fontWeight: 400 }}
                      className="fs-med ms-1"
                    >
                      HP: {props.maxHealth}
                    </div>
                  )}
                </div>

                <div className="d-flex align-items-center">
                  {props.loading && (
                    <Skeleton
                      width={"90px"}
                      className="ms-2"
                      borderRadius={5}
                      height={15}
                    />
                  )}
                  {props.loading && (
                    <Skeleton
                      width={"30px"}
                      className="ms-2"
                      borderRadius={5}
                      height={15}
                    />
                  )}
                  {props.ask || props.bid ? (
                    <div
                      style={{ fontWeight: 400 }}
                      className="align-items-center mt-1 justify-content-between w-100 fs-med "
                    >
                      <div>
                        Buyers Price: &nbsp;
                        <span className="text-success ">
                          {ThousandsSeparator(props.bid?.toString() || "0") || 0}
                        </span>{" "}
                        CLEG
                      </div>
                      <div>
                        Seller Price: &nbsp;
                        <span className="text-danger ">
                          {ThousandsSeparator(props.ask?.toString() || "0")}
                        </span>{" "}
                        CLEG
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!props.sellCard && props?.health?.toString() && (
                    <div className="fs-tiny mt-2 px-2 text-center rounded bg-gray p-relative overflow-hidden percent-badge">
                      <div
                        style={{
                          width: (props?.health * 100) / props?.maxHealth + "%",
                          position: "absolute",
                          height: "100%",
                          left: 0,
                          backgroundColor: "var(--theme-color-green-dark)",
                          top: 0,
                        }}
                      />
                      <span style={{ zIndex: 10, position: "relative" }}>
                        Health : {ThousandsSeparator(roundFloat(props?.health))}/
                        {ThousandsSeparator(props?.maxHealth)}
                      </span>
                    </div>
                  )}
                  {props.hourlyHealing ? (
                    <div className="fs-tiny ms-1 mt-2">
                      <span style={{ color: "#44c97a" }}>
                        +{props?.hourlyHealing}/hr
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!props.hourlyHealing && props.healthBonus ? (
                    <>
                      <div className="fs-tiny text-end mt-2 ms-1 ">
                        <CustomIcon
                          icon="icon-health-restore"
                          className="me-1"
                          width={18}
                          height={20}
                        />
                        <span style={{ color: "#44c97a" }}>
                          +{roundFloat(props?.healthBonus)}{" "}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              {props.level && (
                <button className="level-btn  brk-step4 fw-bold text-light mx-2 ms-4">
                  {props.level}
                </button>
              )}

              {props.loading ? (
                <div className="lb-list fs-med px-4 my-0">
                  <div className="lb-list-item mb-1">
                    <div>
                      <Skeleton width={70} borderRadius={5} height={18} />
                    </div>
                    <div className="fs-med ms-2">
                      <Skeleton width={40} borderRadius={5} height={18} />
                    </div>
                  </div>
                  <div className="lb-list-item ms-2">
                    <div>
                      <Skeleton width={70} borderRadius={5} height={18} />
                    </div>
                    <div className="fs-med ms-2">
                      {" "}
                      <Skeleton width={40} borderRadius={5} height={18} />
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ position: "relative", flex: 1 }}>
                  <div
                    style={{
                      fontSize: props.train ? 12 : 15,
                      filter:
                        props.state === 3 ||
                        props.state === 2 ||
                        props.state === 6
                          ? "blur(5px)"
                          : "none",
                    }}
                    className="lb-list brk-step6 fs-med px-0 my-1"
                  >
                    <div
                      style={{ flex: props?.abilities ? 0.8 : 1 }}
                      className="lb-list-item  align-items-center "
                    >
                      <div>
                        <img
                          style={{ width: 20, height: 23 }}
                          className="me-1"
                          src="/assets/images/icons/damage.png"
                        />
                      </div>
                      <div className="fs-small">{props.damage}</div>
                    </div>
                    <div
                      style={{ flex: 1 }}
                      className="lb-list-item align-items-center "
                    >
                      <div>
                        <img
                          style={{ width: 20, height: 23 }}
                          className="me-1"
                          src="/assets/images/icons/armor.png"
                        />
                      </div>
                      <div className="fs-small">{props.armor}</div>
                    </div>
                    {props.sellCard && (
                      <>
                        <div
                          style={{ flex: props?.abilities ? 0.8 : 1 }}
                          className="lb-list-item align-items-center "
                        >
                          <div>
                            <img
                              style={{ width: 20, height: 23 }}
                              className="me-1"
                              src="https://core.chainoflegends.com/assets/images/team-magic-resist.png"
                            />
                          </div>
                          <div className="fs-small">
                            {props.magicResistance}
                          </div>
                        </div>
                        <div
                          style={{ flex: 1 }}
                          className="lb-list-item align-items-center "
                        >
                          <div>
                            <img
                              style={{ width: 20, height: 23 }}
                              className="me-1"
                              src="/assets/images/icons/range.png"
                            />
                          </div>
                          <div className="fs-small">{props.range}</div>
                        </div>
                      </>
                    )}
                  </div>
                  {!props.sellCard && (
                    <div
                      style={{
                        filter:
                          props.state === 3 ||
                          props.state === 2 ||
                          props.state === 6
                            ? "blur(5px)"
                            : "none",
                      }}
                      className="d-flex w-100"
                    >
                      <div
                        style={{ flex: props?.abilities ? 0.8 : 1 }}
                        className="lb-list-item align-items-center mb-1"
                      >
                        <div>
                          <img
                            style={{ width: 20, height: 23 }}
                            className="me-1"
                            src="https://core.chainoflegends.com/assets/images/team-magic-resist.png"
                          />
                        </div>
                        <div className="fs-small">{props.magicResistance}</div>
                      </div>
                      <div
                        style={{ flex: 1 }}
                        className="lb-list-item align-items-center mb-1"
                      >
                        <div>
                          <img
                            style={{ width: 20, height: 23 }}
                            className="me-1"
                            src="/assets/images/icons/range.png"
                          />
                        </div>
                        <div className="fs-small">{props.range}</div>
                      </div>
                    </div>
                  )}
                  {props?.state === 6 && (
                    <div className="fs-med overlay text-start px-3 px-md-2 px-lg-1 mb-2">
                      <h5 className="">For sale</h5>
                      <h6 className="bold dark-yellow">
                        Price : {ThousandsSeparator(props.marketOrderPrice)}{" "}
                        <img
                          src="/assets/images/icons/cleg.png"
                          width={22}
                          height={22}
                        />
                        CLEG
                      </h6>
                    </div>
                  )}
                  {(props.state === 3 || props.state === 2) && (
                    <div
                      style={{ top: 0, left: -15 }}
                      className="fs-med overlay  "
                    >
                      <div>
                        <Countdown
                          date={new Date(props.finishUpgradeTime)}
                          onComplete={() =>
                            setTimeout(() => {
                              props.refresh();
                            }, 6000)
                          }
                          renderer={({ formatted }) => (
                            <div
                              style={{ transform: "scale(0.8)" }}
                              className="fs-big justify-content-start d-flex"
                            >
                              {parseInt(formatted.days) ? (
                                <div className="time-badge">
                                  {formatted.days}
                                  <span className="dark-yellow">Days</span>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="ms-2 time-badge">
                                {formatted.hours}
                                <span className="dark-yellow">Hours</span>
                              </div>
                              <div className="ms-2 time-badge">
                                {formatted.minutes}
                                <span className="dark-yellow">Minutes</span>
                              </div>
                              <div className="ms-2 time-badge">
                                {formatted.seconds}
                                <span className="dark-yellow">Seconds</span>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div style={{ bottom: "unset", top: 10 }} className="abilities">
              {props.state !== 3 &&
                props?.abilities &&
                props?.abilities?.map((ability, index) => (
                  <OverlayTrigger
                    placement="bottom"
                    flip
                    overlay={
                      <Tooltip className="fs-tiny" {...props}>
                        <b>
                          {" "}
                          {ability?.Name} (+
                          {ability?.Name === "Heal" && !props.isMarket
                            ? roundFloat(
                                ability?.Value +
                                  (parseFloat(ability?.Value) * props.bonus) /
                                    100
                              )?.toString()
                            : ability?.Value}
                          )
                        </b>
                        <br />
                        <br />
                        <small>{ability.Comment}</small>
                      </Tooltip>
                    }
                    delay={{ show: 100, hide: 200 }}
                  >
                    <div
                      style={{
                        marginTop:
                          index !== 0 &&
                          (props.hourlyHealing || props.healthBonus)
                            ? 18
                            : 2,
                      }}
                    >
                      {ability?.Icon && <img src={ability?.Icon} width={35} />}
                    </div>
                  </OverlayTrigger>
                ))}
            </div>
          </div>
          <div
            style={{
              marginTop: -20,
              marginBottom: props.state === 1 || props.state === 4 ? 0 : 0,
            }}
            className="lb-button align-items-center nomb text-center px-2 d-flex justify-content-center"
          >
            {props.loading ? (
              <div className="mt-1">
                <Skeleton width={"90%"} borderRadius={10} height={50} />
              </div>
            ) : (
              props.action
            )}
          </div>
          {props.isLearn && (
            <div style={props.isLearn ? { marginBottom: -40 } : undefined}>
              {+props.clegCost?.toString()?.replaceAll(",", "") ? (
                <div className="d-flex flex-wrap text-white px-2 justify-content-between mb-5 fs-tiny mt-2 ">
                  {props?.clegCost &&
                  (!props?.lastClegCost ||
                    props?.clegCost !== props?.lastClegCost) ? (
                    <div className="d-flex ms-2 align-items-center">
                      <img
                        src={"/assets/images/icons/cleg.png"}
                        width={25}
                        height={25}
                      />
                      &nbsp;
                      {props?.clegCost}
                    </div>
                  ) : props?.clegCost ? (
                    <div className="d-flex ms-2 align-items-end">
                      <img
                        src={"/assets/images/icons/cleg.png"}
                        width={25}
                        height={25}
                      />
                      <div className="d-flex align-items-center flex-column">
                        <del className="text-tiny">{props?.lastClegCost}</del>
                        <div className="text-success-light bold">
                          {props?.clegCost}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props?.stoneCost &&
                  (!props?.lastStoneCost ||
                    props?.stoneCost !== props?.lastStoneCost) ? (
                    <div className="d-flex ms-3 align-items-center">
                      <img
                        src={"/assets/images/icons/stone.png"}
                        width={25}
                        height={25}
                      />
                      &nbsp;
                      {props?.stoneCost}
                    </div>
                  ) : props?.stoneCost ? (
                    <div className="d-flex ms-2 align-items-end">
                      <img
                        src={"/assets/images/icons/stone.png"}
                        width={25}
                        height={25}
                      />
                      <div className="d-flex align-items-center flex-column">
                        <del className="text-tiny">{props?.lastStoneCost}</del>
                        <div className="text-success-light bold">
                          {props?.stoneCost}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props?.ironCost &&
                  (!props?.lastIronCost ||
                    props?.ironCost !== props?.lastIronCost) ? (
                    <div className="d-flex ms-3 align-items-center">
                      <img
                        src={"/assets/images/icons/metal.png"}
                        width={25}
                        height={25}
                      />
                      &nbsp;
                      {props?.ironCost}
                    </div>
                  ) : props?.ironCost ? (
                    <div className="d-flex ms-2 align-items-end">
                      <img
                        src={"/assets/images/icons/metal.png"}
                        width={25}
                        height={25}
                      />
                      <div className="d-flex align-items-center flex-column">
                        <del className="text-tiny">{props?.lastIronCost}</del>
                        <div className="text-success-light bold">
                          {props?.ironCost}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <div className="mb-4  d-flex align-items-center">
            {props.items?.map((item, index) => (
              <OverlayTrigger
                overlay={
                  <Tooltip className="fs-tiny">
                    {item?.item?.name}{" "}
                    <span className="fs-tiny">
                      (+{roundFloat(item.bonusValue)}{" "}
                      {ItemBonusStat[item?.item?.bonusStat]} ){" "}
                    </span>
                  </Tooltip>
                }
              >
                <div
                  className={
                    "p-relative p-1 rounded bg-dark  " + (index !== 0 && "ms-1")
                  }
                >
                  <img src={item.image} width={37} height={37} />
                  <div className="item-level1">{item.level}</div>
                </div>
              </OverlayTrigger>
            ))}
          </div>
        </div>
      )}
    </SkeletonTheme>
  );
};

export default TroopBox;
