import React from 'react'
import Dialog from './Dialog';

import {
    EmailIcon,
    FacebookIcon,
    LineIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    VKIcon,
    WhatsappIcon,
    EmailShareButton,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    VKShareButton,
    LineShareButton,
} from "react-share";
import useToast from '../hooks/useToast';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CustomIcon } from './Icons';

type Props = {
    open: boolean;
    onClose: () => void;
    link: string;
    title?: string;
    shareNote?: string;
    description?: string;
}

const Share = (props: Props) => {
    
    const openToast = useToast()

    return (
        <Dialog submitLabel='Done' onSubmit={props.onClose} title={props.title || "Share"} open={props.open} onClose={props.onClose}>

            <p className='text-center'>
                {props.description}
            </p>

            <div style={{ marginBottom: -40 }} className='d-flex gap-2 justify-content-center mt-2 flex-wrap'>
              <CopyToClipboard onCopy={()=>{
     openToast({
        ok: true,
        message: 'Copied successfully'
    })
              }} text={props.link}>
                <EmailShareButton   className='icon-copy round' onClick={(e) => e.preventDefault()}  body={props.shareNote} url={props.link}>
                    <CustomIcon icon='copy' type='svg' />
                </EmailShareButton>
                </CopyToClipboard>
                <EmailShareButton className='round' body={props.shareNote} url={props.link}>
                    <EmailIcon />
                </EmailShareButton>
                <FacebookShareButton className='round' url={props.link}>
                    <FacebookIcon />
                </FacebookShareButton>
                {/* <FacebookMessengerShareButton  className='round' url={props.link}>
                    <FacebookMessengerIcon />
                </FacebookMessengerShareButton> */}
                <TwitterShareButton className='round' title={props.shareNote} url={props.link}>
                    <TwitterIcon />
                </TwitterShareButton>
                <TelegramShareButton className='round' title={props.shareNote} url={props.link}>
                    <TelegramIcon />
                </TelegramShareButton>
                <VKShareButton className='round' title={props.shareNote} url={props.link}>
                    <VKIcon />
                </VKShareButton>
                <LineShareButton className='round' title={props.shareNote} url={props.link}>
                    <LineIcon />
                </LineShareButton>
                <RedditShareButton className='round' title={props.shareNote} url={props.link}>
                    <RedditIcon />
                </RedditShareButton>
                <WhatsappShareButton className='round' title={props.shareNote} url={props.link}>
                    <WhatsappIcon />
                </WhatsappShareButton>
            </div>

        </Dialog>
    )
}

export default Share