import clsx from "clsx";
import React, { CSSProperties, ReactNode, useRef } from "react";
import { useSelector } from "react-redux";
import { roundFloat } from "../utils/round-float";
import CircularProgress from "./CircularProgress";

type Props = {
  variant?:
    | "default"
    | "wide"
    | "success"
    | "rounded"
    | "primary"
    | "wide-primary"
    | "secondary"
    | "wide-secondary"
    | "wide-success";
  children: React.ReactNode;
  onClick?: React.MouseEventHandler | Function;
  style?: React.CSSProperties;
  size?: "small" | "medium";
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  badge?: string | ReactNode;
  badgeStyles?: CSSProperties;
  type?: "button" | "submit" | "reset" | undefined;
};

const Button = (props: Props) => {
  const AudioContext =
    window.AudioContext || (window as any).webkitAudioContext;
  let audioCtx = useRef(new AudioContext())?.current;

  const userSettings = useSelector((state: any) => state.userSettings);

  function playSound(audioBuffer?: any) {
    const gainNode = audioCtx.createGain();
    const bufferSource = audioCtx.createBufferSource();
    gainNode.gain.value = +roundFloat(userSettings?.soundEffects / 10, 1);
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(gainNode).connect(audioCtx.destination);
    bufferSource.start(0);
  }

  // fetch()
  function initSound() {
    if (userSettings?.soundEffects > 0) {
      fetch("/sounds/click.sfxc")
        .then((resp) => resp.arrayBuffer())
        .then((arrayBuffer) => audioCtx.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          playSound(audioBuffer);
        });
    }
  }

  return (
    <button
      type={props?.type}
      onClick={
        !props?.loading && !props?.disabled
          ? (e) => {
              if (props?.onClick) {
                initSound();
                props?.onClick(e);
              }
            }
          : props?.disabled || props?.loading
          ? (e) => e.preventDefault()
          : undefined
      }
      disabled={props?.disabled}
      style={props?.style || undefined}
      className={clsx(
        `button d-flex p-relative align-items-center ${
          props?.className ?? ""
        } `,
        {
          "button-disabled": props?.loading || props?.disabled,
        },
        props?.variant && getBtnClass(props?.variant),
        props?.size === "small" ? "btn-small" : ""
      )}
    >
      {props?.loading && <CircularProgress style={{ width: 20 }} />}
      {props?.children}
      {props?.badge && (
        <div
          style={{ top: 0, right: -31, ...props?.badgeStyles }}
          className="off-badge"
        >
          {props?.badge}
        </div>
      )}
    </button>
  );
};

const getBtnClass = (variant?: string) => {
  switch (variant) {
    case "rounded":
      return "col-btn-rounded";
    case "default":
      return "col-btn-default";
    case "primary":
      return "col-btn-blue";
    case "success":
      return "col-btn-success";
    case "secondary":
      return "col-btn-secondary  text-white";
    case "wide-secondary":
      return "col-btn-wide-secondary  text-white";
    case "wide-success":
      return "col-btn-wide-success  text-white";
    case "wide":
      return "col-btn-wide";
    case "wide-primary":
      return "col-btn-wide-primary";
    default:
      break;
  }
};

export default Button;
