import React, { useEffect, useState } from "react";
import Dialog from "../Dialog";
import { useSelector } from "react-redux";
import { useAccount} from "wagmi";
import Lottie from "lottie-react";
import Disconnected from "../../animations/disconnected.json";
import Countdown from "react-countdown";
import Button from "../Button";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const DisconnectDialog = ({ handleLogout }: { handleLogout: () => void }) => {
  const userInfo = useSelector((state: any) => state.userInfo);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState<Date | null>();
  const { isDisconnected, isReconnecting, isConnected } = useAccount();
  const { open: openW3M } = useWeb3Modal();
  useEffect(() => {
    if (
      localStorage.getItem("connector") === "walletconnect" &&
      isDisconnected &&userInfo&&
      !isReconnecting
    ) {
      setOpen(true);
      setTimer(new Date(Date.now() + 59000));
    }
  }, [isDisconnected, isReconnecting]);
  useEffect(() => {
    if (isConnected && open) {
      setOpen(false);
      setTimer(null);
    }
  }, [isConnected]);
  return (
    <Dialog
    force
      disableSubmit
      noFooter
      title="Disconnected"
      open={open}
      
      onClose={() => null}
    >
      <div style={{ marginBottom: -40 }} className="d-flex  flex-column gap-2">
        <div className="w-100 ">
          <Lottie
            style={{ height: 300, marginTop: -50 }}
            animationData={Disconnected}
            autoPlay
            loop
          />
        </div>
        <div className="fs-big text-center ">Your wallet is disconnected !</div>
        <div className="fs-med">
        If you are using an extension, please<span className="text-info"> unlock your wallet</span> to continue playing; Or try to reconnect to the "Chain of Legends".
        </div>

        {timer && (
          <Countdown
            date={timer}
            renderer={({ formatted }) => (
              <>
                <div className="fs-bigger bold text-center text-danger">
                  {formatted.seconds}
                </div>
              </>
            )}
              onComplete={() => {
                setOpen(false)
                handleLogout();
                setTimer(null)
              }}
          />
        )}
        <div className="d-flex mt-2 gap-2 justify-content-center align-items-center">
          <Button onClick={()=>openW3M({view:'Connect'})} className="bg-100" variant="wide-primary">
            Reconnect
          </Button>
          <Button
            onClick={handleLogout}
            className="bg-100"
            variant="wide-secondary"
          >
            Disconnect
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DisconnectDialog;
