import React, { FC } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ArenaNavbar from "../components/navbar/arena-navbar";
import ArenaBottomBar from "../components/home/arena-bottom-bar";
import { Assets } from "../../constants/assets";
import Button from "../../components/Button";

type Props = {
  error?: boolean;
};

const ErrorFallback = () => {
  const navigate = useNavigate();
  return (
    <div className="min-vh-100 arena-layout">
      <div
        style={{
          minHeight: "50vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src="/assets/images/error.png"
          width={"80%"}
          style={{ maxWidth: 300 }}
        />
        <p className="light-yellow bg-dark-500 px-2 rounded text-center fs-big">
          Opps Something went wrong ...!
        </p>

        <div className="d-flex">
          <Button 
            onClick={() => navigate("/arena/dashboard")}
            className="mt-3 bg-100"
            variant="primary"
          >
            Back
          </Button>
          &nbsp;
          <Button
            onClick={() => window.location.reload()}
            className="mt-3 bg-100"
            variant="default"
          >
            Reload
          </Button>
        </div>
      </div>
    </div>
  );
};
const ArenaHomeLayout: FC<Props> = ({ error }) => {
  return (
    <div className=" arena-layout">
      <video autoPlay loop muted className="video-bg">
        <source src={Assets.videos.arenaBackground} type="video/mp4" />
      </video>
      <div className="mask-video-bg" />
      <div style={{ zIndex: 10 ,minHeight:'calc(100vh - 140px)'}} className="position-relative d-flex flex-column">
        <ArenaNavbar />
        <div style={{minHeight:'100%'}} className=" d-flex  flex-1 justify-content-center align-items-center">
          <div className="container">
            {error ? <ErrorFallback /> : <Outlet />}
          </div>
        </div>
      </div>
      <ArenaBottomBar />
    </div>
  );
};

export default ArenaHomeLayout;
