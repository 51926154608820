import React from "react";
import { Outlet } from "react-router-dom";
import ArenaNavbar from "../components/navbar/arena-navbar";
import ArenaBottomBar from "../components/home/arena-bottom-bar";

const CampLayout = () => {
  return (
    <div className="w-100 h-100 camp-container">
      {/* <video autoPlay loop muted className="video-bg">
        <source src="/videos/scene-video.mp4" type="video/mp4" />
      </video> */}
      <div className="mask-video-bg" />
      <div
        style={{ zIndex: 10 }}
        className="position-relative min-vh-100 min-vw-100"
      >
      <ArenaNavbar/>

        <div className="container min-vh-100 h-100">
          <Outlet />
        </div>
        <ArenaBottomBar />

      </div>
    </div>
  );
};

export default CampLayout;
