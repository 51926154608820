

export const actionTypes = {
    SET_CYCLE: 'SET_CYCLE'
};

export const setCurrentCycle = (payload:any) => ({
    type: actionTypes.SET_CYCLE,
    payload,
});

