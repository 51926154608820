import React, { FC, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface PropsCopyTooltip {
  position?: "top" | "right" | "bottom" | "left";
  trigger?: "hover" | "click" | "focus";
  children: React.ReactNode | string;
  text?: string;
  delay?: number;
  className?: string;
}
export const CopyTooltip: FC<PropsCopyTooltip> = ({
  children,
  text = null,
  position = "top",
  trigger = "click",
  delay = 600,
  className = "user-select-none",
}) => {
  const [show, setShow] = useState(false);
  const popoverCopied = (
    <Tooltip>
      <strong>Copied</strong>
    </Tooltip>
  );
  return (
    <div className={className} style={{ cursor: "pointer" }}>
      <OverlayTrigger
        show={show}
        onToggle={() => setShow(!show)}
        onEntered={() => setTimeout(() => setShow(false), delay)}
        trigger={trigger}
        placement={position}
        rootCloseEvent={"click"}
        overlay={popoverCopied}
      >
        <div
          title="Copy"
          className="cursor-pointer"
          aria-hidden="true"
          onClick={(event) => {
            navigator.clipboard.writeText(
              text ?? event.currentTarget.innerText
            );
          }}
        >
          {children}
        </div>
      </OverlayTrigger>
    </div>
  );
};
