import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import useWindowSize from "../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";

import PerfectScrollbar from "react-perfect-scrollbar";
import HowToPlay from "./HowToPlay";
import Cycles from "./Cycles";
import Buildings from "./Buildings";
import Troops from "./Troops";
import Items from "./Items";
import GameUpdates from "./GameUpdates";
import { Helmet } from "react-helmet";
type Props = {};

const LearnTabs = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const mainTabs = [
    {
      title: "How To play",
      type: 'how-to-play',
      imageSrc: "/assets/images/icons/youtube.png",
      content: <HowToPlay />
    },
    {
      title: "Cycles",
      type: 'cycles',
      imageSrc: "/assets/images/icons/clock-timer.png",
      content: <Cycles />
    },
    {
      title: "Buildings",
      type: 'buildings',
      imageSrc: "/assets/images/icons/land.png",
      content: <Buildings />
    },

    {
      title: "Troops",
      type: 'troops',
      imageSrc:
        "https://core.chainoflegends.com/assets/troops/icon/warrior.png",
      content: <Troops/>

    },
    {
      title: "Items",
      type: 'items',
      imageSrc: "/assets/images/icons/helmet-item.png",
      content:<Items/>
    },
    {
      title: "Game Updates",
      type: 'updates',
      imageSrc: "/assets/images/icons/updates.png",
      content:<GameUpdates/>
    },
  ];

  function handleChangeType(active: string) {
    navigate({
      pathname: location.pathname,
      search: "category=" + active,
    });
  }



  const type = React.useMemo(() => {
    const id: any = searchParams.get("category");
    if (id) {
      return id;
    } else return mainTabs[0].type;
  }, [location?.search]);

  const tab = React.useMemo(() => {
    const id: any = searchParams.get("category");
    if (id) {
      return mainTabs.find((item)=>item.type===id);
    } else return mainTabs[0];
  }, [location?.search]);


  return (
    <>
    <Helmet>
      <title>
        {tab?.title} | Chain Of Legends
      </title>
    </Helmet>
      <motion.div
        initial={isMobile ? undefined : { opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
        exit={{ opacity: 0 }}
        className="tab-bar mt-4"
      >
        <PerfectScrollbar style={{ maxWidth: "100%" }}>
          <div className="row flex-nowrap  m-0 p-0 justify-content-start justify-content-md-center">
            {mainTabs.map((tab) => (
              <button
                onClick={() => {
                  handleChangeType(tab.type);
                }}
                className={
                  "col-2 col-sm-4 pb-4 pt-3 col-md-3 col-lg-2  " +
                  (tab.type === type && "active")
                }
              >
                <div className="b-text">
                  <span className="text-light  fs-med d-flex flex-column align-items-center fs-lg  fs-sm-small">
                    <img
                      className="mb-1 circle fs-med"
                      src={tab.imageSrc}
                      height="55px"
                    />
                    {tab.title}
                  </span>
                </div>
              </button>
            ))}
          </div>
        </PerfectScrollbar>
      </motion.div>

      {mainTabs.find((item) => item.type === type)?.content}
    </>
  );
};

export default LearnTabs;
