import React from "react";
import { Helmet } from "react-helmet";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import Network from "../../utils/Network";
import Button from "../../components/Button";
import Dialog from "../../components/Dialog";
import CountUp from "react-countup";
import Countdown from "react-countdown";
import moment from "moment";
import CircularProgress from "../../components/CircularProgress";
import useEvents from "../../hooks/useEvents";
import { roundFloat } from "../../utils/round-float";

type Props = {};
const labels = [
  {
    id: 1,
    title: 64,
    position: {
      left: "29%",
      bottom: "28%",
    },
  },
  {
    id: 2,
    title: 128,
    position: {
      left: "21%",
      bottom: "33%",
    },
  },
  {
    id: 3,
    title: 256,
    position: {
      top: "42.5%",
      left: "14%",
    },
  },
  {
    id: 4,
    title: 512,
    position: {
      bottom: "30%",
      right: "26%",
    },
  },

  {
    id: 5,
    title: 1024,
    position: {
      top: "36%",
      right: "12%",
    },
  },
  {
    id: 6,
    title: 2048,
    position: {
      bottom: "39%",
      right: "39%",
    },
  },
  {
    id: 7,
    title: 2048,
    position: {
      top: "23%",
      left: "40%",
    },
  },
  {
    id: 8,
    title: 2048,
    position: {
      top: "15%",
      right: "35%",
    },
  },
];

const DungeonSelect = (props: Props) => {
  const windowSize = useWindowSize();
  const axios = new Network();
  const { halloweenEvent } = useEvents();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [detailsDialog, setDetailsDialog] = React.useState<any>({
    open: false,
    data: null,
  });

  const handleGetList = async () => {
    setLoading(true);
    // const response = await axios.CallApi({ url: '/treasure?treasureType=' + ((searchParams.get('type') ? searchParams.get('type') === "3" ? 3 : (searchParams.get('type')) : 1)), method: 'get' })
    const response = await axios.CallApi({
      url: "/treasure?treasureType=1",
      method: "get",
    });
    if (response.ok) setData(response?.data);
    setLoading(false);
  };
  React.useEffect(() => {
    handleGetList();
  }, []);

  return (
    <div>
      <Helmet>
        <style>
          {`
                    .main-layout {
                        background: #05090c;
                     }
                    `}
        </style>
        <title key={"title"}>Select a Dungeon | Chain Of Legends</title>
      </Helmet>
      <br />
      <div
        ref={containerRef}
        style={{ maxWidth: 1920, minHeight: "100vh", marginTop: -25 }}
        className="mx-auto  d-flex align-items-center  w-100"
      >
        <PerfectScrollbar style={{ overflow: "scroll" }} className="w-100">
          <div style={{ minWidth: 1024 }} className="p-relative w-100">
            {labels?.map((item: any) => {
              const details: any = data.find((p: any) => p.id === item.id);
              return (
                <>
                  <OverlayTrigger
                    rootClose
                    trigger={"click"}
                    placement={item.id > 6 || item.id === 5 ? "bottom" : "top"}
                    overlay={
                      <Popover
                        style={{ background: "#fff" }}
                        className="bg-paper"
                      >
                        <div>
                          <Popover.Header className="text-center">
                            Dungeon Information
                          </Popover.Header>
                          {loading ? (
                            <Popover.Body className="text-white">
                              <div
                                style={{ minHeight: 200, minWidth: 200 }}
                                className="d-flex w-100 justify-content-center align-items-center"
                              >
                                <CircularProgress style={{ margin: 0 }} />
                              </div>
                            </Popover.Body>
                          ) : (
                            <Popover.Body className="text-white">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="bold">Difficulty </div>
                                <hr className="mx-2" style={{ flex: 1 }} />
                                <span className="fs-lg d-flex gap-1 align-items-center ">
                                  <img
                                    src="/assets/images/icons/blades.png"
                                    width={24}
                                    height={24}
                                  />
                                  {details?.difficulty}
                                </span>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="bold">Travel Time </div>
                                <hr className="mx-2" style={{ flex: 1 }} />
                                <div className="d-flex gap-1 align-items-center">
                                  <img
                                    src="/assets/images/icons/timer.png"
                                    width={25}
                                    height={25}
                                  />
                                  <Countdown
                                    date={moment()
                                      .add(details?.minTravelMinutes, "m")
                                      ?.toDate()}
                                    autoStart={false}
                                    renderer={({ formatted }) => (
                                      <div>
                                        {formatted.days} days{" "}
                                        {parseFloat(formatted.hours) ? (
                                          <>{formatted.hours} hr</>
                                        ) : (
                                          ""
                                        )}
                                        {/* {formatted.minutes} min */}
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="bold">Details </div>
                                <hr className="mx-2" style={{ flex: 1 }} />
                                <a
                                  onClick={() => {
                                    containerRef.current?.click();
                                    setDetailsDialog({
                                      open: true,
                                      data: details,
                                    });
                                  }}
                                  className="text-info d-flex pointer gap-1 align-items-center"
                                >
                                  <img
                                    src="/assets/images/icons/info.png"
                                    width={20}
                                    height={20}
                                  />{" "}
                                  Click here
                                </a>
                              </div>
                              <div className="d-flex align-items-center">
                                <hr className="me-2" style={{ flex: 1 }} />
                                <div className="text-center bold">Rewards</div>
                                <hr className="ms-2" style={{ flex: 1 }} />
                              </div>

                              <div className="d-flex px-2  text-white align-items-end adv-step3 mt-1 justify-content-center">
                                <OverlayTrigger
                                  // popperConfig={{}}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                      {halloweenEvent.isActive &&
                                      halloweenEvent.extraRewardTreasureId ===
                                        details?.id
                                        ? details?.clegReward *
                                          halloweenEvent.extraTreasureRewardMultiplier
                                        : roundFloat(details?.clegReward)}{" "}
                                      Cleg
                                    </Tooltip>
                                  }
                                >
                                  <div className="d-flex  align-items-end">
                                    <div
                                      style={{ borderRadius: "50% 50% 0 50%" }}
                                      className="dark-badge"
                                    >
                                      <img
                                        style={{
                                          position: "relative",
                                          zIndex: 11,
                                        }}
                                        src="/assets/images/icons/cleg.png"
                                        width={25}
                                        height={25}
                                      />
                                      <div
                                        style={{ fontSize: 13 }}
                                        className=""
                                      ></div>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 13,
                                        marginLeft: -8,
                                        zIndex: 10,
                                        padding: "2px 6px",
                                        borderRadius: "0 10px 10px 0",
                                        background: "#2b271e",
                                        height: "fit-content",
                                        verticalAlign: "bottom",
                                      }}
                                      className=""
                                    >
                                      x
                                      {halloweenEvent.isActive &&
                                      halloweenEvent.extraRewardTreasureId ===
                                        details?.id
                                        ? details?.clegReward *
                                          halloweenEvent.extraTreasureRewardMultiplier
                                        : roundFloat(details?.clegReward)}{" "}
                                    </div>
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  // popperConfig={{}}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                      {halloweenEvent.isActive &&
                                      halloweenEvent.extraRewardTreasureId ===
                                        details?.id
                                        ? details?.stoneReward *
                                          halloweenEvent.extraTreasureRewardMultiplier
                                        : roundFloat(details?.stoneReward)} Stone
                                    </Tooltip>
                                  }
                                >
                                  <div className="d-flex  align-items-end">
                                    <div
                                      style={{ borderRadius: "50% 50% 0 50%" }}
                                      className="dark-badge ms-2"
                                    >
                                      <img
                                        style={{
                                          position: "relative",
                                          zIndex: 11,
                                        }}
                                        src="/assets/images/icons/stone.png"
                                        width={25}
                                        height={25}
                                      />
                                      <div
                                        style={{ fontSize: 13 }}
                                        className=""
                                      ></div>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 13,
                                        marginLeft: -8,
                                        zIndex: 10,
                                        padding: "2px 6px",
                                        borderRadius: "0 10px 10px 0",
                                        background: "#2b271e",
                                        height: "fit-content",
                                        verticalAlign: "bottom",
                                      }}
                                      className=""
                                    >
                                      x
                                      {halloweenEvent.isActive &&
                                      halloweenEvent.extraRewardTreasureId ===
                                        details?.id
                                        ? details?.stoneReward *
                                          halloweenEvent.extraTreasureRewardMultiplier
                                        : details?.stoneReward}{" "}
                                    </div>
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  // popperConfig={{}}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                      {halloweenEvent.isActive &&
                                      halloweenEvent.extraRewardTreasureId ===
                                        details?.id
                                        ? details?.ironReward *
                                          halloweenEvent.extraTreasureRewardMultiplier
                                        : details?.ironReward} Iron
                                    </Tooltip>
                                  }
                                >
                                  <div className="d-flex align-items-end">
                                    <div
                                      style={{ borderRadius: "50% 50% 0 50%" }}
                                      className="dark-badge ms-2"
                                    >
                                      <img
                                        style={{
                                          position: "relative",
                                          zIndex: 11,
                                        }}
                                        src="/assets/images/icons/metal.png"
                                        width={25}
                                        height={25}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 13,
                                        marginLeft: -8,
                                        zIndex: 10,
                                        padding: "2px 6px",
                                        borderRadius: "0 10px 10px 0",
                                        background: "#2b271e",
                                        height: "fit-content",
                                        verticalAlign: "bottom",
                                      }}
                                      className=""
                                    >
                                      x{halloweenEvent.isActive &&
                                      halloweenEvent.extraRewardTreasureId ===
                                        details?.id
                                        ? details?.ironReward *
                                          halloweenEvent.extraTreasureRewardMultiplier
                                        : details?.ironReward}
                                    </div>
                                  </div>
                                </OverlayTrigger>
                              </div>
                              <div className="d-flex mt-2 justify-content-center">
                                {details?.rewardTroops?.map(
                                  (troop: any, index) => (
                                    <OverlayTrigger
                                      // popperConfig={{}}
                                      placement={"top"}
                                      key={index + "p" + troop?.x + troop?.y}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          {troop?.name} - level {troop?.level}
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        style={{ padding: 5 }}
                                        className={
                                          "dark-badge " +
                                          (index !== 0 && "ms-2")
                                        }
                                      >
                                        <img
                                          style={{ borderRadius: 50 }}
                                          width={37}
                                          height={37}
                                          src={troop?.icon}
                                        />
                                        <div className="units fs-tiny">
                                          {troop?.chance}
                                          <small>%</small>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center unit-level">
                                          <small> {troop?.level}</small>
                                        </div>
                                      </div>
                                    </OverlayTrigger>
                                  )
                                )}
                              </div>
                              <Link to={`/battle/order?treasureId=${item.id}`}>
                                <Button
                                  style={{ marginBottom: -5 }}
                                  className="mx-auto mt-2"
                                  variant="wide"
                                >
                                  Attack
                                </Button>
                              </Link>
                            </Popover.Body>
                          )}
                        </div>
                      </Popover>
                    }
                  >
                    <div
                      style={{
                        position: "absolute",
                        ...item.position,
                        zIndex: 10,
                      }}
                      className="pointer p-relative"
                    >
                      <div className="p-relative">
                        <img
                          width={
                            windowSize.width < 1400
                              ? 55
                              : windowSize.width < 1900
                              ? 80
                              : 100
                          }
                          src={`/assets/images/lands/dungeon/${item.id}.png`}
                        />
                      </div>
                      {halloweenEvent.extraRewardTreasureId === item.id && (
                        <div style={{ top: -15 }} className="fire-badge mx-auto">
                          {halloweenEvent.extraTreasureRewardMultiplier}X
                        </div>
                      )}
                      <div
                   
                        className="fs-md dungeon-badge mx-auto "
                      >
                        <img
                          src="/assets/images/icons/blades.png"
                          width={25}
                          height={25}
                        />
                        <span>
                        {details?.difficulty}
                        </span>
                      </div>
                    </div>
                  </OverlayTrigger>
                </>
              );
            })}

            <img
              src="/assets/images/lands/dungeon/dungeon-map.jpg"
              width={"100%"}
            />
          </div>
        </PerfectScrollbar>
      </div>
      <Dialog
        size="lg"
        open={detailsDialog.open}
        onClose={() => setDetailsDialog((prev) => ({ ...prev, open: false }))}
        title="Dungeon Details"
      >
        <div style={{ marginBottom: -50 }} className="row ">
          <div className="col-md-3">
            <div className="d-flex border-right border-md-end  align-items-center flex-column">
              <img
                style={{ maxWidth: 63 }}
                width={"100%"}
                src="/assets/images/icons/blades.png"
              />
              <div className="fs-med mt-2">Difficulty</div>
              <p className="fs-big mt-1">
                <CountUp start={0} end={detailsDialog?.data?.difficulty} />
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex border-right border-md-end  align-items-center flex-column">
              <img
                style={{ maxWidth: 50 }}
                width={"100%"}
                src="/assets/images/icons/flash-icon.png"
              />
              <div className="fs-med mt-2">
                Active Adventures{" "}
                <OverlayTrigger
                  // popperConfig={{}}
                  placement={"top"}
                  trigger="click"
                  overlay={
                    <Tooltip>
                      Total number of players seeking this particular Dungeon
                    </Tooltip>
                  }
                >
                  <img
                    className="pointer"
                    src="/assets/images/icons/info.png"
                    width={20}
                  />
                </OverlayTrigger>
              </div>
              <p className="fs-big mt-1">
                <CountUp
                  start={0}
                  end={detailsDialog?.data?.activeAdventures}
                />
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex border-right border-md-end  align-items-center flex-column">
              <img
                style={{ maxWidth: 70 }}
                width={"100%"}
                src="/assets/images/icons/treasure.png"
              />
              <div className="fs-med mt-2">
                Base Reward{" "}
                <OverlayTrigger
                  // popperConfig={{}}
                  placement={"top"}
                  trigger="click"
                  overlay={
                    <Tooltip>
                      This is the maximum amount of CLEG one can get if there
                      are only a few players.
                    </Tooltip>
                  }
                >
                  <img
                    className="pointer"
                    src="/assets/images/icons/info.png"
                    width={20}
                  />
                </OverlayTrigger>
              </div>
              <p className="fs-big mt-1">
                <CountUp start={0} end={detailsDialog?.data?.baseReward} />{" "}
                <small>CLEG</small>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex border-right  align-items-center flex-column">
              <img
                style={{ maxWidth: 70 }}
                width={"100%"}
                src="/assets/images/icons/target.png"
              />
              <div className="fs-med mt-2">
                Daily Reward Pool{" "}
                <OverlayTrigger
                  // popperConfig={{}}
                  placement={"top"}
                  trigger="click"
                  overlay={
                    <Tooltip>
                      Total CLEG which is considered for this Dungeon type based
                      on the Cycles.
                    </Tooltip>
                  }
                >
                  <img
                    className=" pointer"
                    src="/assets/images/icons/info.png"
                    width={20}
                  />
                </OverlayTrigger>
              </div>
              <p className="fs-big mt-1">
                <CountUp start={0} end={detailsDialog?.data?.dailyRewardPool} />{" "}
                <small>CLEG</small>
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DungeonSelect;
