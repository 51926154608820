import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import CircularProgress from './CircularProgress'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    icon: React.ReactNode,
    tooltip?: string,
    loading?: boolean,
    disabled?: boolean,
    loadingStyle?: React.CSSProperties

}

const IconButton = (props: Props) => {
    const Action = () => <div {...props} onClick={props.loading || props.disabled ? undefined : props.onClick} className={props.className + ' icon-button ' + (props.disabled && ' button-disabled')}>
        {props.loading ? <CircularProgress style={{ margin: 0, ...props.loadingStyle }} /> : props.icon}
    </div>

    if (!props.tooltip) return <Action />

    return (
        <OverlayTrigger
            overlay={<Tooltip className='fs-tiny' {...props}>
                {props.tooltip}
            </Tooltip>}
            delay={{ show: 100, hide: 200 }} >
            <div>
                <Action />
            </div>
        </OverlayTrigger>

    )
}

export default IconButton