import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { ModelData, StoredModelData } from "../types";
import models from "../data/models";

type State = {
  isLoaded: boolean;
  isLoading: boolean;
  progress: number;
  error: Error | null;
  models: StoredModelData[];
};

type Action =
  | { type: "LOAD_START" }
  | { type: "LOAD_SUCCESS"; payload: StoredModelData }
  | { type: "LOAD_ERROR"; payload: Error }
  | { type: "SET_PROGRESS"; payload: number }
  | { type: "LOAD_COMPLETE" };

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  progress: 0,
  error: null,
  models: [],
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "LOAD_START":
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        progress: 0,
        error: null,
      };
    case "SET_PROGRESS":
      return {
        ...state,
        progress: Math.min(state.progress + action.payload, 100),
      };
    case "LOAD_SUCCESS":
      return { ...state, models: [...state.models, action.payload] };
    case "LOAD_ERROR":
      return { ...state, error: action.payload, isLoading: false };
    case "LOAD_COMPLETE":
      return { ...state, isLoaded: true, isLoading: false, progress: 100 };
    default:
      return state;
      
  }
};

type ModelLoaderContextProps = State & {
  loadModels: (models: ModelData[]) => void;
};

const ModelLoaderContext = createContext<ModelLoaderContextProps | undefined>(
  undefined
);

export const ModelLoaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useRef(false);

  const loadModel = async (
    url: string,
    modelIndex: number,
    totalModels: number
  ): Promise<Pick<StoredModelData, "scene" | "animations">> => {
    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader();
      loader.load(
        url,
        (gltf) => {
          resolve({
            animations: gltf.animations,
            scene: gltf.scene,
          });
        },
        (event) => {
          if (event.lengthComputable) {
            const percentComplete =
              (event.loaded / event.total) * (100 / totalModels);
            dispatch({ type: "SET_PROGRESS", payload: percentComplete });
          }
        },
        (err) => reject(err)
      );
    });
  };

  const loadModels = async (models: ModelData[]) => {
    try {
      dispatch({ type: "LOAD_START" });
      isMounted.current = true;
      for (let i = 0; i < models.length; i++) {
        const model = models[i];  
        const loadedModel = await loadModel(model.src, i, models.length);
        const modelData = {
          id: model.id.toString(),
          animations: loadedModel.animations,
          scene: loadedModel.scene,
          timestamp: Date.now(),
        };
        dispatch({ type: "LOAD_SUCCESS", payload : modelData });
      }

      dispatch({ type: "LOAD_COMPLETE" });
    } catch (err) {
      dispatch({ type: "LOAD_ERROR", payload: err as Error });
      console.log(err)
    }
  };
  useEffect(() => {
    if (isMounted.current) return;
    loadModels(Object.values(models));
  }, []);
  return (
    <ModelLoaderContext.Provider value={{ ...state, loadModels }}>
      {children}
    </ModelLoaderContext.Provider>
  );
};

export const useModelLoader = (): ModelLoaderContextProps => {
  const context = useContext(ModelLoaderContext);
  if (!context) {
    throw new Error("useModelLoader must be used within a ModelLoaderProvider");
  }
  return context;
};
