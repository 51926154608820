import { actionTypes } from './action';

export const defaultCycle = {};

export const cycleReducer = (state = defaultCycle, action: any) => {
    switch (action.type) {
        case actionTypes.SET_CYCLE:
            return action.payload;
        default:
            return state;
    }
};
