import React, { useState } from 'react'
import Network from '../../utils/Network';
import { MoveTroopType } from '../types/enum';
import useToast from '../../hooks/useToast';
import { PlayerTroopType } from '../../types/troop';


const useCamp = () => {
    const [playerTroops, setPlayerTroops] = useState<PlayerTroopType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMoveLoading, setIsMoveLoading] = useState<boolean | number>(false);
    const axios = new Network();
    const openToast = useToast();
    const moveTroop = async (id: number, destination: MoveTroopType) => {
        setIsMoveLoading(id);
        const response = await axios.CallApi({
            url: `/playertroop/${id}/moveto/${destination}`,
            method: "post",
            body: {
                destination,
            },
        });
        setIsMoveLoading(false);
        if (response.ok) {
            if (!response?.message) response.message = "Troop Moved Successfully";
            if (destination === MoveTroopType.BARRACKS) fetchTroops();
        }
        openToast(response);

        // if(! response.ok) throw new Error(response?.message);
        return response
    };
    
    const fetchTroops = async () => {
        setIsLoading(true);
        const response = await axios.CallApi({
            url: "/playertroop?count=50&arenaTroops=true",
            method: "get",
        });
        if (response.ok) {
            setPlayerTroops(response?.data?.data);
        }
        setIsLoading(false);
    };
    return (
        { fetchTroops, playerTroops, isLoading, isMoveLoading,moveTroop }
    )
}

export default useCamp