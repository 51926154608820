export const MapPositions = {
  9: [
    {
      xs: {
        top: "7%",
        left: "35%",
      },
    },
    {
      xs: {
        top: "25%",
        left: "40%",
      },
    },
    {
      xs: {
        top: "19%",
        left: "30%",
      },
    },
    {
      xs: {
        bottom: "48%",
        right: "18%",
      },
    },
    {
      xs: {
        bottom: "45%",
        right: "12%",
      },
    },
    {
      xs: {
        bottom: "35%",
        right: "8%",
      },
    },
    {
      xs: {
        bottom: "31%",
        right: "12%",
      },
    },
    {
      xs: {
        bottom: "45%",
        left: "35%",
      },
    },
    {
      xs: {
        top: "19%",
        right: "39%",
      },
    },
    {
      xs: {
        top: "35%",
        right: "49%",
      },
    },
    {
      xs: {
        bottom: "65%",
        left: "17%",
      },
    },
    {
      xs: {
        top: "52%",
        left: "10%",
      },
    },
    {
      xs: {
        top: "60%",
        right: "30%",
      },
    },
    {
      xs: {
        top: "54%",
        left: "6%",
      },
    },
    {
      xs: {
        top: "47%",
        right: "38%",
      },
    },
    {
      xs: {
        top: "48%",
        left: "19%",
      },
    },
    {
      xs: {
        top: "20%",
        left: "19%",
      },
    },
    {
      xs: {
        top: "27%",
        left: "27%",
      },
    },
    {
      xs: {
        top: "39%",
        left: "25%",
      },
    },
    {
      xs: {
        bottom: "57%",
        left: "40%",
      },
    },
    {
      xs: {
        bottom: "57%",
        left: "33%",
      },
    },
    {
        xs: {
          bottom: "17%",
          right: "33%",
        },
      },
      {
        xs: {
          top: "37%",
          right: "39%",
        },
      },
      {
        xs: {
          bottom: "52%",
          right: "29%",
        },
      },
      {
        xs: {
          bottom: "42%",
          right: "23%",
        },
      },
      {
        xs: {
          bottom: "22%",
          right: "43%",
        },
      },
  ],
};
