import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openChat, setActiveChat } from '../redux/chat/actions';


const useChat = () => {
    const chatState = useSelector((state: any) => state.chatState);
    const dispatch = useDispatch()
    const userChat = (id: string) => {
        console.log(id)
        dispatch(setActiveChat({
            section: 'chat',
            targetId: id
        }))
        dispatch(openChat())
    }
    return {
        userChat,
        chatState
    }
}

export default useChat