import React, { useEffect, useState } from 'react'
import LandBox from '../components/Boxes/LandBox'
import { motion } from 'framer-motion'
import Button from '../components/Button'
import TipList from '../components/TipList'
import Network, { FTPURL } from '../utils/Network'
import { roundFloat } from '../utils/round-float'
import Dialog from '../components/Dialog'
import useToast from '../hooks/useToast'
// import {
//     SortableContainer,
//     SortableElement, arrayMove,
//     SortableHandle
// } from "react-sortable-hoc";
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'


type Props = {}
export const ArrowSvg = (props: any) => <svg width="269" height="270" viewBox="0 0 269 270" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M201.828 56.5457V213.193C201.828 219.372 206.837 224.382 213.017 224.382C219.196 224.382 224.206 219.372 224.206 213.193V56.5457C224.206 50.3661 219.196 45.3566 213.017 45.3566C206.837 45.3566 201.828 50.3661 201.828 56.5457Z" fill="currentColor" />
    <path d="M190.638 224.382H213.017C219.196 224.382 224.206 219.372 224.206 213.193C224.206 207.013 219.196 202.004 213.017 202.004H190.638C184.459 202.004 179.449 207.013 179.449 213.193C179.449 219.372 184.459 224.382 190.638 224.382Z" fill="currentColor" />
    <path d="M190.638 67.7349H213.017C219.196 67.7349 224.206 62.7253 224.206 56.5458C224.206 50.3662 219.196 45.3567 213.017 45.3567H190.638C184.459 45.3567 179.449 50.3662 179.449 56.5458C179.449 62.7253 184.459 67.7349 190.638 67.7349Z" fill="currentColor" />
    <path d="M112.316 90.1129C112.316 88.3759 112.72 86.6627 113.497 85.109C114.274 83.5553 115.402 82.2039 116.791 81.1616C117.967 80.28 119.305 79.6386 120.728 79.2739C122.151 78.9092 123.633 78.8285 125.087 79.0363C126.542 79.2441 127.941 79.7364 129.206 80.485C130.47 81.2336 131.575 82.224 132.456 83.3995L166.023 128.156C167.424 130.072 168.178 132.384 168.178 134.757C168.178 137.131 167.424 139.443 166.023 141.359L134.47 186.115C132.759 188.527 130.162 190.164 127.248 190.667C124.333 191.17 121.338 190.499 118.917 188.801C117.707 187.953 116.677 186.873 115.886 185.625C115.096 184.377 114.56 182.984 114.31 181.528C114.061 180.071 114.102 178.58 114.432 177.14C114.761 175.699 115.373 174.338 116.232 173.136L143.198 134.869L114.554 96.8264C113.101 94.8896 112.316 92.5339 112.316 90.1129V90.1129Z" fill="currentColor" />
    <path d="M33.9914 134.869C33.9914 131.902 35.1703 129.056 37.2686 126.957C39.367 124.859 42.213 123.68 45.1805 123.68H134.693C137.661 123.68 140.507 124.859 142.605 126.957C144.703 129.056 145.882 131.902 145.882 134.869C145.882 137.837 144.703 140.683 142.605 142.781C140.507 144.879 137.661 146.058 134.693 146.058H45.1805C42.213 146.058 39.367 144.879 37.2686 142.781C35.1703 140.683 33.9914 137.837 33.9914 134.869Z" fill="currentColor" />
</svg>
const MyLands = (props: Props) => {
    const axios = new Network()
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const openToast = useToast()
    const [btnLoading, setBtnLoading] = useState(false)
    const [currentLevel, setCurrentLevel] = useState(1)
    const [sellDialog, setSellDialog] = useState({ open: false, id: '', sellPrice: null })
    const [confimDialog, setConfirmDialog] = useState({
        open: false,
        submit: null,
        title: '',
        msg: ''
    })

    const handlePlaceOrder = async (id: string, price: string) => {
        setBtnLoading(true)
        const response = await axios.CallApi({
            url: `/playerland/${id}/place-order`,
            body: {
                clegPrice: price
            }
        })
        openToast(response)
        if (response.ok) {
            handleGetLands()
        }
        setSellDialog(prev => ({ ...prev, open: false }))
        setBtnLoading(false)
    }


    const handleGetLevel = async () => {
        const response = await axios.CallApi({
            url: "/building",
            method: 'get'
        })
        if (response?.ok) {

            setCurrentLevel(response.data[0]?.currentLevel)
        }
    }

    const handleCancelOrder = async (id: string) => {
        setBtnLoading(true)
        const response = await axios.CallApi({
            url: `/playerland/${id}/cancel-order`,
            method: 'get'
        })
        openToast(response)
        if (response.ok) {
            setConfirmDialog(prev => ({ ...prev, open: false }))
            handleGetLands()
        }
        setBtnLoading(false)
    }

    const handleGetLands = async () => {
        setLoading(true)
        const response = await axios.CallApi({
            url: '/playerland',
            method: 'get'
        })
        if (response.ok) {
            setData(response.data?.map((land: any, index: number) => ({
                ...land,
                image: FTPURL + "/" + land.photo,
                name: land.name,
                rate: land.productionRatePercent,
                cleg: roundFloat(land.clegMinedCount) + "/" + roundFloat(land.totalCleg),
                stone: roundFloat(land.stoneMinedCount) + "/" + roundFloat(land.totalStone),
                metal: roundFloat(land.ironMinedCount) + "/" + roundFloat(land.totalIron)
            })))
        }
        setLoading(false)
    }

    useEffect(() => {
        handleGetLevel()
        handleGetLands()
    }, [])

    // const Handle = SortableHandle<any>(({ tabIndex, item }) => (
    //     <LandBox  {...item}
    //         action={
    //             item.state === 2 ? <Button loading variant='wide'
    //             >
    //                 Mining ...
    //             </Button> : item.state === 1 ? <Button onClick={() => setSellDialog({ open: true, id: item?.id, sellPrice: null })} variant='wide'>
    //                 Sell
    //             </Button> : item.state === 4 ? <div className=''>
    //                 <Button
    //                     onClick={() => setConfirmDialog({
    //                         open: true,
    //                         title: 'Are you sure?',
    //                         msg: 'Are you sure to cancel your sell order ?',
    //                         submit: () => handleCancelOrder(item?.id)
    //                     })}
    //                     variant='wide'>
    //                     Cancel Order
    //                 </Button>
    //             </div> : <>
    //             </>
    //         } />
    // ));

    // const HandlerItem = SortableElement<any>(({ item, }) => (<motion.div
    //     key={item.id + "-land-box"} initial={isMobile ? undefined : { x: window.innerWidth, opacity: 0 }}
    //     animate={{
    //         x: 0, opacity: 1
    //     }}
    //     transition={{
    //         duration: 0.4, delay: item.id * 0.1
    //     }}
    //     exit={{ x: -window.innerWidth, opacity: 0 }}
    //     className='col-12 col-sm-6 col-md-4 col-lg-3'>
    //     <Handle item={item} />
    // </motion.div>
    // ))

    // const SortableList = SortableContainer((props: any) => {
    //     const { items, ...restProps } = props;
    //     return (
    //         <div className='row'>
    //             {data.map((item: any, index: number) => (
    //                 <HandlerItem item={item} />
    //             ))}
    //         </div>
    //     );
    // });

    // const onSortEnd = ({ oldIndex, newIndex }) => {
    //     setData(arrayMove(data, oldIndex, newIndex));
    // };



    return (
        <>
            <div className='row'>
            <Helmet>
                <title key={'title'}>
                    My Lands | Chain Of Legends
                </title>
            </Helmet>

                <motion.div className='col-12 text-end pb-2'
                    initial={isMobile ? undefined : { opacity: 0 }}
                    animate={{
                        x: 0, opacity: 1
                    }}
                    transition={{
                        duration: 0.4, delay: 0.5
                    }}
                    exit={{ y: -700, opacity: 0 }}
                >
                    <Link to="/lands">
                        <a className='dark-yellow'  >
                            My lands(BETA)  <ArrowSvg width={40} height={30} />
                        </a>
                    </Link>

                </motion.div>
                {loading && [1, 2, 3, 4].map((key) => (
                    <motion.div key={key + "-loading-land-box"} initial={isMobile ? undefined : { opacity: 0 }}
                        animate={{
                            x: 0, opacity: 1
                        }}
                        transition={{
                            duration: 0.4, delay: key * 0.05
                        }}
                        exit={{ x: -window.innerWidth, opacity: 0 }}
                        className='col-12 col-sm-6 col-md-4 col-lg-3'>
                        <LandBox
                            loading={true}
                            action={
                                <Button disabled variant='wide'>
                                    Sell
                                </Button>
                            } />
                    </motion.div>
                ))}

                {!loading && data?.length === 0 && <Link to="/marketplace">
                    <motion.div

                        initial={isMobile ? undefined : { opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.3 }} exit={{ opacity: 0 }}
                        className="alert alert-warning pointer  py-2" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16"><path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path></svg>
                        &nbsp;&nbsp;You don't have any land , you can buy in <span className="link-style color-inherit bold"> MarketPlace</span> . &nbsp;

                    </motion.div>           </Link>}

                {!loading && data?.map((item: any, key: number) => <motion.div key={key + "-land-box"} initial={isMobile ? undefined : { opacity: 0 }}
                    animate={{
                        x: 0, opacity: 1
                    }}
                    transition={{
                        duration: 0.4, delay: key * 0.05
                    }}
                    exit={{ x: -window.innerWidth, opacity: 0 }}
                    className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-5 mt-md-0'>
                    <LandBox  {...item}
                        hint={item.landId > 1 && currentLevel === 0}
                        className="land-box1"
                        action={
                            item.state === 2 ? <Button loading variant='wide'
                            className='w-100'
                            >
                                Mining ...
                            </Button> : item.state === 1 ? <Button
                            className='w-100'
                            onClick={() => setSellDialog({ open: true, id: item?.id, sellPrice: null })} variant='wide'>
                                Sell
                            </Button> : item.state === 4 ? <div className=''>
                                <Button
                                className='w-100'
                                    onClick={() => setConfirmDialog({
                                        open: true,
                                        title: 'Are you sure?',
                                        msg: 'Are you sure to cancel your sell order ?',
                                        submit: () => handleCancelOrder(item?.id)
                                    })}
                                    variant='wide'>
                                    Cancel Order
                                </Button>
                            </div> : <>
                            </>
                        } />
                </motion.div>)}
            </div>
            <br />
            <div className='mt-4'>
                <TipList
                    className='mt-4'
                    item={[
                        'You need lands to mine resources. Mining buildings use lands with priority from left to right.',
                        'When land resources are finished up, It will be dismissed.',
                        'The Mining Building can only mine one land at a time.',
                    ]}
                />
            </div>
            <Dialog loading={btnLoading} title={"Sell Land"}
                closeLabel='Cancel' submitLabel='Submit'
                onSubmit={() => handlePlaceOrder(sellDialog?.id, sellDialog?.sellPrice)} open={sellDialog.open}
                onClose={() => setSellDialog(prev => ({ ...prev, open: false }))}>
                <p className='dark-yellow'>
                    Sell Price
                </p>
                <input
                    onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                    onChange={(e) => setSellDialog(prev => ({ ...prev, sellPrice: e.target.value }))}
                    placeholder='Enter your sell price for this land,example:500'
                    type="text"
                    className='w-100 form-control form-input dark-yellow'
                />

            </Dialog>

            <Dialog loading={btnLoading} title={confimDialog?.title}
                closeLabel='No' submitLabel='Yes'
                onSubmit={confimDialog?.submit} open={confimDialog?.open}
                onClose={() => setConfirmDialog(prev => ({ ...prev, open: false }))}>
                {confimDialog?.msg}
            </Dialog>
        </>

    )
}

export default MyLands