import React, { useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { OrbitControls as OrbitControlsImpl } from "three-stdlib";

interface OrbitControlsProps {
  autoRotate?: boolean;
  autoRotateSpeed?: number;
}

const defaultSpeed = 5;

const HorizontalOrbitControls: React.FC<OrbitControlsProps> = ({
  autoRotate = false,
  autoRotateSpeed = defaultSpeed,
}) => {
  const orbitControlsRef = useRef<OrbitControlsImpl>(null);

  useEffect(() => {
    const controls = orbitControlsRef.current;
    if (controls) {
      // Disabling unwanted controls
      controls.enableZoom = false;
      controls.enablePan = false;

      // Enable damping for smoother transitions
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;

      // Handle auto rotate settings
      controls.autoRotate = autoRotate;
      controls.autoRotateSpeed = autoRotateSpeed;

      // Constrain vertical rotation
      controls.minPolarAngle = Math.PI / 2; // 90 degrees
      controls.maxPolarAngle = Math.PI / 2; // 90 degrees
    }
  }, [autoRotate, autoRotateSpeed]);

  return <OrbitControls ref={orbitControlsRef} />;
};
export default HorizontalOrbitControls;
