import { createSlice } from "@reduxjs/toolkit";
import { PlayerTroopType } from "../../../types/troop";
import { BuildingInfoType } from "../../../types/pages/Barracks";

export interface BarracksState {
  playerTroopList: PlayerTroopType[];
  barrackBuildingInfo: BuildingInfoType;
  isLoading: boolean;
  isBaseLoading: boolean;
}
const initialState: BarracksState = {
  playerTroopList: [],
  barrackBuildingInfo: {} as BuildingInfoType,
  isLoading: false,
  isBaseLoading: false,
};

const barracksSlice = createSlice({
  name: "barracks",
  initialState: initialState,
  reducers: {
    startCall: (state) => {
      state.isLoading = false;
      state.isBaseLoading = false;
      state.playerTroopList = [];
    },
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
    baseLoading: (state, action) => {
      state.isBaseLoading = action.payload;
    },
    playerTroopFetched: (state, action) => {
      state.playerTroopList = action?.payload;
      state.isLoading = false;
      state.isBaseLoading = false;
    },
    barrackBuildingInfoFetched: (state, action) => {
      state.barrackBuildingInfo = action?.payload;
      state.isLoading = false;
    },
  },
});

export { barracksSlice };
