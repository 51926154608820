import React, { useEffect, useRef, useState } from 'react'
import useWindowSize from '../hooks/useWindowSize'
import DefaultLayout from '../layouts/DefaultLayout'
import Draggable from 'react-draggable';
import Network, { IsBeta } from '../utils/Network';
import Button from '../components/Button';
import { Badge, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import useToast from '../hooks/useToast';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Dialog from '../components/Dialog';
import moment from 'moment';
import Countdown from 'react-countdown';
import { isMobile, isTablet } from 'react-device-detect';
import { roundFloat } from '../utils/round-float';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayable } from '../redux/music/action';
type Props = {}
enum PlayerTroopTargetPriority {
    Closest = 1,
    LowestHealth = 2,
    HighestDamage = 3,

}
const troopTypes = {
    1: 'Physical',
    2: 'Magical',
    3: 'PhysicalAoe',
    4: 'MagicalAoe',
}
const defaultTargetPriority = 1
interface arr {
    index: number,
    x: number,
    displayX: number,
    y: number,
    "playerTroopId": number | undefined,
    "targetPriority": PlayerTroopTargetPriority
}
const defaultArray: arr[] = [
    {
        index: 0,
        x: 0,
        displayX: 0,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    },
    {
        index: 1,
        x: 1,
        displayX: 1,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    },
    {
        index: 2,
        x: 2,
        displayX: 2,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 3,
        x: 3,
        displayX: 3,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 4,
        x: 4,
        displayX: 4,
        y: 0,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 5,
        x: 0,
        displayX: 0.5,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 6,
        x: 1,
        displayX: 1.5,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 7,
        x: 2,
        displayX: 2.5,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 8,
        x: 3,
        displayX: 3.5,

        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 9,
        x: 4,
        displayX: 4.5,
        y: 1,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 10,
        x: 0,
        displayX: 0,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 11,
        displayX: 1,
        x: 1,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 12,
        displayX: 2,
        x: 2,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 13,
        displayX: 3,
        x: 3,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 14,
        displayX: 4,
        x: 4,
        y: 2,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 15,
        displayX: 0.5,
        x: 0,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 16,
        displayX: 1.5,
        x: 1,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 17,
        displayX: 2.5,
        x: 2,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 18,
        displayX: 3.5,
        x: 3,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
    , {
        index: 19,
        displayX: 4.5,
        x: 4,
        y: 3,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 20,
        displayX: 0,
        x: 0,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 21,
        displayX: 1,
        x: 1,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 22,
        displayX: 2,
        x: 2,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 23,
        displayX: 3,
        x: 3,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 24,
        displayX: 4,
        x: 4,
        y: 4,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 25,
        displayX: 0.5,
        x: 0,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 26,
        displayX: 1.5,
        x: 1,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 27,
        displayX: 2.5,
        x: 2,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 28,
        displayX: 3.5,
        x: 3,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }, {
        index: 29,
        displayX: 4.5,
        x: 4,
        y: 5,
        "playerTroopId": undefined,
        "targetPriority": defaultTargetPriority
    }
]
const defaultSupply = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
function isEven(n: string) { return parseInt(n) % 2 === 0 ? true : parseInt(n) === 0 ? true : false }
const BattleReplay = (props: Props) => {
    const [startLoading, setStartLoading] = useState(false)
    const windowSize = useWindowSize()
    const contaierRef = useRef()
    const overFlowContainer = useRef()
    const [selectMode, setSelectMode] = useState(false)
    const [selectProperties, setSelectProperties] = useState({ checkRoom: 0, battlePlace: 0 })
    const navigate = useNavigate()
    const [arrayList, setArrayList] = useState(defaultArray)
    const [supplyList, setSupplyList] = useState(defaultSupply)
    let [searchParams, setSearchParams] = useSearchParams();
    const width = contaierRef?.current?.offsetWidth;
    const containerHeight = contaierRef?.current?.offsetHeight;
    const calculatedSize = (width - 400) / (windowSize?.width < 800 ? 10 : 10)
    const elementSize = calculatedSize > 200 ? 200 : calculatedSize
    const axios = new Network()
    const [activePopper, setActivePopper] = useState<any>({})
    const [loading, setLoading] = useState(true)
    const [battleReport, setBattleReport] = useState<any>()
    const [attackers, setAttackers] = useState<any>([])
    const [defenders, setDefenders] = useState<any>([])
    const [attacking, setAttacking] = useState<any>('')
    const [isPlaying, setIsPlaying] = useState(false)
    const timeoutRef = useRef()
    const isPaused = useRef(false)
    const isStoped = useRef(false)
    const isMount = useRef(false)
    const [isPausedState, setIsPausedState] = useState(false)
    const [isCountDown, setIsCountDown] = useState<boolean | number>(false)
    const { id } = useParams()
    const dispatch = useDispatch()
    const isPlayable = useSelector((state: any) => state.audioPlayable)
    let AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    const location = useLocation()
    var backgroundMusic = '';
    const userSettingsRedux = useSelector((state: any) => state.userSettings)
    const settingsRef = useRef(userSettingsRedux)
    const userSettings = settingsRef.current


    const [audioBuffer, setAudioBuffer] = useState<any>()
    useEffect(() => {
        settingsRef.current = userSettingsRedux
    }, [userSettingsRedux])

    const initilizeAllImages = async (array: any[]) => {
        for (let key in array) {
            new Image().src = array[key]?.playerTroop?.Troop?.AttackAnimation
            new Image().src = array[key]?.playerTroop?.Troop?.IdleAnimation
        }
    }
    async function getData() {
        setLoading(true)
        const response = await axios.CallApi({ url: '/adventure/' + id, method: 'get' })
        let newArrayList: any = []
        let Defs = []
        setBattleReport(JSON.parse(response?.data?.battleReport))
        JSON.parse(response?.data?.battleReport)?.Attackers?.map(((item: any) => {
            // const detectedItem = newArrayList?.find((subItem) =>subItem?.displayX===item?.displayX &&subItem?.y === item?.y )
            // const newItem={...detectedItem,...item}
            let defaultData = {}

            for (let key in JSON.parse(response?.data?.battleReport)?.Actions[0]) {
                if (item.playerTroopId === JSON.parse(response?.data?.battleReport)?.Actions[0][key]?.playerTroopId) {
                    defaultData = JSON.parse(response?.data?.battleReport)?.Actions[0][key]
                }
            }
            newArrayList.push({
                ...item,
                initialX: defaultData?.displayX?.toString() ? defaultData?.displayX : item.displayX,
                initialY: defaultData?.displayY?.toString() ? defaultData?.displayY : item.displayY,
                ...defaultData,
                Health: item?.InitialHealth
            })
            // console.log(newItem)
            // if (detectedItem){
            // console.log(newItem)
            //     newArrayList = [...defaultArray.filter((rec)=>rec.displayX!==detectedItem?.displayX&&rec.y!==detectedItem?.y),{
            //         ...newItem
            //     }]?.sort((a, b) => a.index - b.index)
            // }
        }))
        setAttackers(newArrayList)
        JSON.parse(response?.data?.battleReport)?.Defenders?.map(((item: any) => {
            let defaultData = {}
            for (let key in JSON.parse(response?.data?.battleReport)?.Actions[0]) {
                if (item.playerTroopId === JSON.parse(response?.data?.battleReport)?.Actions[0][key]?.playerTroopId) {
                    defaultData = JSON.parse(response?.data?.battleReport)?.Actions[0][key]
                }
            }
            Defs.push({
                ...item,
                initialX: defaultData?.displayX?.toString() ? defaultData?.displayX : item.displayX,
                initialY: defaultData?.displayY?.toString() ? defaultData?.displayY : item.displayY,

                ...defaultData,
                Health: item?.InitialHealth
            })
        }))
        setDefenders(Defs)
        initilizeAllImages([...newArrayList, ...Defs])
        setLoading(false)
    }
    function handleTime() {
        setTimeout(() => {
            handlePlay()
        }, 1000)
    }
    useEffect(() => {
        if (!isMount.current) getData()
        isMount.current = true
    }, [])

    useEffect(() => {
        if (!loading) {
            if (searchParams?.get('action') === "play") {
                // handleTime()
            }
        }
    }, [loading])

    useEffect(() => {
        dispatch(setPlayable(false))
    }, [])
    const popperActiveItem = arrayList?.find((item) => item?.playerTroopId === activePopper?.id)

    function changeTargetPriority(id: number, value: number) {
        const oldObj: any = arrayList?.find((item) => item?.playerTroopId === id)
        setArrayList(prev => ([...prev?.filter(item => item?.playerTroopId !== id), { ...oldObj, targetPriority: value }]?.sort((a, b) => a.index - b.index)))
    }

    const getPlayerToops = async () => {
        const response = await axios.CallApi({ url: '/playertroop?count=50', method: 'GET' })
        if (response.ok) setSupplyList(response?.data?.data)
    }


    useEffect(() => {
        getPlayerToops()
    }, [])

    useEffect(() => {
        if (overFlowContainer) {
            overFlowContainer.current.scrollLeft = (windowSize.width < 600 ? contaierRef?.current?.offsetWidth / 3.6 : 200)
        }
    }, [])

    function playSound(audioBuffer: any, audioCtx: any) {
        let gainNode = audioCtx.createGain();
        let bufferSource = audioCtx.createBufferSource();
        gainNode.gain.value = parseFloat(roundFloat(settingsRef.current?.soundEffects / 10, 1))
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(gainNode).connect(audioCtx.destination);
        gainNode.connect(audioCtx.destination)
        bufferSource.loop = false;
        bufferSource.start(0);
    }

    function handleVisibility() {
        if (document.hidden) {
            setIsPausedState(true)
            isPaused.current = true
        }
        else {
            setIsPausedState(false)
            isPaused.current = false
        }
    }
    useEffect(() => {
        window.addEventListener('visibilitychange', handleVisibility, false)
        return () => document.removeEventListener('visibilitychange', handleVisibility, false)
    }, [])


    function initSound(src: string) {
        var myAudioElement = new Audio(src);
        myAudioElement.volume = parseFloat(roundFloat(settingsRef.current?.soundEffects / 10, 1))
        myAudioElement.addEventListener("canplaythrough", (event) => {
            myAudioElement.play();
        });
        return;

        // return;
        let audioCtx = new AudioContext()
        if (userSettings?.backgroundMusicVolume > 0) fetch(src)
            .then(resp => resp.arrayBuffer())
            .then(arrayBuffer => audioCtx.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                playSound(audioBuffer, audioCtx)
            })
    }
    useEffect(() => {
        // initBackgroundSound()
        // return () => audioCtx && audioCtx?.suspend()
    }, [])

    function handleNext(currentStp = 0) {
        const actions = battleReport.Actions[currentStp];
        let counter = 0
        try {
            function handlePerformRound() {
                if (!isPaused.current) {
                    const key = counter
                    const attacker = attackers?.find((item => item?.playerTroopId === actions[key].playerTroopId))
                    const defender = defenders?.find((item => item?.playerTroopId === actions[key].playerTroopId))
                    let attackTime = 1000;
                    if (attacker) {
                        const newItem: any = document.getElementById(actions[key].playerTroopId?.toString())
                        newItem.style.transform = `translateX(${(actions[key]?.displayToX * elementSize) - (attacker?.initialX * elementSize)}px) translateY(${(actions[key]?.displayToY * elementSize) - (attacker?.initialY * elementSize)}px)`
                        const elementToolTip: any = document.getElementById(actions[key].playerTroopId?.toString() + '-tooltip')
                        elementToolTip.style.transform = `translateX(${(actions[key]?.displayToX * elementSize) - (attacker?.initialX * elementSize)}px) translateY(${(actions[key]?.displayToY * elementSize) - (attacker?.initialY * elementSize)}px)`



                        if (actions[key]?.targets?.length !== 0) {
                            for (let key2 in actions[key]?.targets) {
                                const attackerTarget = defenders?.find((attItem) => attItem?.playerTroopId === actions[key]?.targets[key2]?.id)
                                const healingTarget = attackers?.find((attItem) => attItem?.playerTroopId === actions[key]?.targets[key2]?.id)
                                if (attackerTarget) {
                                    // if(attackerTarget.displayToX > attacker.displayToX){
                                    //     newItem.style.transform="scaleX(1)"
                                    // }

                                    const jsonData = JSON.parse(attacker?.playerTroop?.Troop?.JsonData)
                                    if (jsonData?.attackSound?.length > 0) {
                                        initSound(jsonData?.attackSound[0])
                                    }
                                    attackTime = jsonData?.attackAnimationTime * 1000
                                    setAttacking(actions[key]?.playerTroopId)
                                    // const targetElement = document.getElementById(`${attackerTarget?.displayToY}y-${attackerTarget?.displayToX}x`)
                                    const targetElement: any = document.getElementById(attackerTarget?.playerTroopId?.toString())
                                    targetElement.style.backgroundImage = "url(/assets/images/battle/red-polygon.png)"
                                    setTimeout(() => {
                                        setDefenders(prev => ([...prev?.filter(nw => nw?.playerTroopId !== attackerTarget.playerTroopId),
                                        {
                                            ...attackerTarget,
                                            Health: actions[key]?.targets[key2]?.newValue
                                        }
                                        ]))
                                        // troopImage.style.transform = `scaleX(-1)`
                                        if (targetElement.style.backgroundImage?.includes('red-')) {
                                            targetElement.style.backgroundImage = ""
                                        }
                                    }, attackTime - 100);
                                    const degree = (actions[key]?.displayToX * attackerTarget?.displayToY) / (attackerTarget?.displayToX * actions[key]?.displayToY)
                                    // troopImage.style.transform = `scaleX(-1) rotateZ(${degree*100}deg)`
                                }
                                if (healingTarget) {
                                    const targetElement: any = document.getElementById(healingTarget?.playerTroopId?.toString())
                                    targetElement.style.backgroundImage = "url(/assets/images/battle/blue-polygon.png)"
                                    setTimeout(() => {
                                        setAttackers(prev => ([...prev?.filter(nw => nw?.playerTroopId !== healingTarget.playerTroopId),
                                        {
                                            ...healingTarget,
                                            Health: actions[key]?.targets[key2]?.newValue
                                        }
                                        ]))
                                        // troopImage.style.transform = `scaleX(-1)`
                                        if (targetElement.style.backgroundImage?.includes('blue-')) {
                                            targetElement.style.backgroundImage = ""
                                        }
                                    }, attackTime - 100);

                                }                            // const itemPlace = document.getElementById(`${actions[key]?.displayToY}y-${actions[key]?.displayToX}x`)


                            }
                        }
                        newItem.style.backgroundImage = `url(/assets/images/battle/green-polygon.png)`
                        setTimeout(() => {
                            setAttacking('')

                            if (newItem.style.backgroundImage?.includes('green-')) newItem.style.backgroundImage = ``
                        }, attackTime - 100);

                        // setAttackers(prev => ([...prev.filter(item => item.playerTroopId !== actions[key].playerTroopId),
                        // {
                        //     ...attacker,
                        //     ...actions[key],
                        // }
                        // ]))
                    }
                    if (defender) {
                        // setDefenders(prev => ([...prev.filter(item => item.playerTroopId !== actions[key].playerTroopId),
                        // {
                        //     ...defender,
                        //     ...actions[key],
                        // }
                        // ]))
                        const newItem: any = document.getElementById(actions[key].playerTroopId?.toString())
                        const troopImage = document.getElementById(actions[key].playerTroopId?.toString() + "-img")
                        newItem.style.transform = `translateX(${(actions[key]?.displayToX * elementSize) - (defender?.initialX * elementSize)}px) translateY(${(actions[key]?.displayToY * elementSize) - (defender?.initialY * elementSize)}px)`
                        const elementToolTip: any = document.getElementById(actions[key].playerTroopId?.toString() + '-tooltip')
                        elementToolTip.style.transform = `translateX(${(actions[key]?.displayToX * elementSize) - (defender?.initialX * elementSize)}px) translateY(${(actions[key]?.displayToY * elementSize) - (defender?.initialY * elementSize)}px)`



                        if (actions[key]?.targets?.length !== 0) {
                            for (let key2 in actions[key]?.targets) {
                                const defenderTarget = attackers?.find((attItem) => attItem?.playerTroopId === actions[key]?.targets[key2]?.id)
                                if (defenderTarget) {
                                    // const nativeImage = document.getElementById(actions[key].playerTroopId + "-img")
                                    // if(defenderTarget.displayToX > defender.displayToX){
                                    //     nativeImage.style.transform="scaleX(-1) scale(2)"
                                    // }
                                    // else{
                                    //     nativeImage.style.transform="scaleX(-1) scale(2)"
                                    // }
                                    const jsonData = JSON.parse(defender?.playerTroop?.Troop?.JsonData)
                                    if (jsonData?.attackSound?.length > 0) {
                                        initSound(jsonData?.attackSound[0])
                                    }
                                    setAttacking(actions[key]?.playerTroopId)
                                    attackTime = jsonData?.attackAnimationTime * 1000
                                    // const targetElement:any = document.getElementById(`${defenderTarget?.displayY}y-${defenderTarget?.displayX}x`)
                                    const targetElement: any = document.getElementById(defenderTarget?.playerTroopId?.toString())
                                    targetElement.style.backgroundImage = "url(/assets/images/battle/red-polygon.png)"
                                    setTimeout(() => {
                                        setAttackers(prev => ([
                                            ...prev?.filter(nw => nw?.playerTroopId !== defenderTarget.playerTroopId),
                                            {
                                                ...defenderTarget,
                                                Health: actions[key]?.targets[key2]?.newValue
                                            }
                                        ]))
                                        const newElement: any = document.getElementById(defenderTarget?.playerTroopId?.toString())

                                        if (newElement.style.backgroundImage?.includes('red-')) {
                                            newElement.style.backgroundImage = ""
                                        }
                                    }, attackTime - 100)
                                };
                            }
                        }
                        // const itemPlace = document.getElementById(`${actions[key]?.displayToY}y-${actions[key]?.displayToX}x`)
                        newItem.style.backgroundImage = `url(/assets/images/battle/green-polygon.png)`
                        setTimeout(() => {
                            setAttacking('')
                            if (newItem.style.backgroundImage?.includes('green-')) newItem.style.backgroundImage = ``
                        }, attackTime - 100);
                    }
                    counter += 1
                    if (counter === actions?.length) {
                        if (battleReport.Actions?.length - 1 !== currentStp) {
                            timeoutRef.current = setTimeout(() => handleNext(currentStp + 1), attackTime + 100)
                        }
                        else {
                            setTimeout(() => {
                                navigate('/battle/result/' + id)
                            }, attackTime);
                            setIsPlaying(false)
                        }
                    }
                    else {
                        setTimeout(() => {
                            handlePerformRound()
                        }, attackTime);
                    }
                }
                else {
                    if (isStoped?.current) {
                        return;
                    }
                    else {
                        setTimeout(() => {
                            handlePerformRound()
                        }, 1500);
                    }

                }
            }
            handlePerformRound()


        }

        catch (e) {
            console.log(e)
        }
    }

    async function handleReplay() {
        window.location.href = `/battle/replay/${id}?action=play`
        // isStoped.current = true
        // setIsPlaying(false)
        // setIsPausedState(true)
        // isPaused.current = true
        // for (let key in attackers) {
        //     const element = document.getElementById(attackers[key]?.playerTroopId?.toString())
        //     element.style.transform = "none"
        // }
        // for (let key in defenders) {
        //     const element = document.getElementById(defenders[key]?.playerTroopId?.toString())
        //     element.style.transform = "none"
        // }
        // await getData()
        // handlePlay()

    }
    function handlePlay() {
        setIsCountDown(Date.now() + 3000)
        setIsPausedState(false)
        isPaused.current = false
        isStoped.current = false
        setIsPlaying(true)
        setTimeout(() => {
            handleNext()
        }, 3000);
    }

    return (
        <div ref={overFlowContainer} style={{ overflow: 'auto' }}>
            <div style={{
                display: 'flex',
                justifyContent: windowSize.width < 990 ? 'flex-start' : ''
            }} ref={contaierRef} className='pre-fight ' >
                {isCountDown && <div className='battle-countdown'>
                    <Countdown
                        onComplete={() => setIsCountDown(false)}
                        date={isCountDown}
                        renderer={({ seconds }) => <div>
                            <p style={{ fontSize: 70 }}>
                                {seconds}
                            </p>
                        </div>}
                    />
                </div>}
                <div style={{
                    filter: isCountDown ? 'blur(5px)' : '',
                    maxWidth: windowSize.width < 990 ? 'none' : '',
                    paddingInlineStart: windowSize.width > 1500 ? '8%' : windowSize.width < 990 ? '8%' : '10%',
                }} className='w-100 transition pe-3 '>
                    <div style={{ minWidth: 800 }} className='row '>
                        <div style={{ position: 'relative' }} className="d-flex mt-4 align-items-center col-12">
                            {arrayList?.map((item, i) => (
                                <div key={i} className='pre-polygon d-flex  justify-content-center'
                                    id={`${item?.y}y-${item?.displayX}x`}
                                    style={{
                                        width: elementSize, height: elementSize,
                                        // transform:    `translateY(${elementSize / 2}px)`,
                                        opacity: 0.7,
                                        filter: selectMode && !item?.playerTroopId ? "invert(30%) sepia(99%) saturate(323%)" : '',
                                        position: 'absolute',
                                        top: item?.y * elementSize + (windowSize?.width < 1200 ? containerHeight / 5 : containerHeight / 5),
                                        textAlign: 'center',
                                        left: (item?.displayX) * elementSize + 70,
                                        // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                                    }}
                                />
                            ))}

                            {defaultArray?.map((item) => <div className='pre-polygon'
                                id={`${item?.y}y-${item?.displayX + 5}x`}
                                style={{
                                    width: elementSize, height: elementSize,
                                    // transform: `scaleX(15deg)`,
                                    opacity: 0.7,
                                    position: 'absolute',
                                    top: item?.y * elementSize + (windowSize?.width < 1200 ? containerHeight / 5 : containerHeight / 5),
                                    // filter: 'grayscale(100%)',
                                    left: (item?.displayX + 5) * elementSize + 70,
                                    // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                                }}  >
                            </div>)}
                            {attackers.map((item, i) => (
                                <div
                                    id={item?.playerTroopId}
                                    key={item?.playerTroopId}
                                    style={{
                                        width: elementSize, height: elementSize,
                                        transition: '0.7s all ease-in-out',
                                        opacity: item?.Health === 0 ? 0 : 1,
                                        display: item?.Health === 0 ? 'none !important' : 'flex',
                                        backgroundSize: 'cover',
                                        zIndex: 600,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        // transform:    `translateY(${elementSize / 2}px)`,
                                        // filter: selectMode && !item?.playerTroopId ? "invert(30%) sepia(99%) saturate(323%)" : '',
                                        position: 'absolute',
                                        top: item?.initialY * elementSize + (windowSize?.width < 1200 ? containerHeight / 5 : containerHeight / 5),
                                        textAlign: 'center',
                                        left: (item?.initialX) * elementSize + 70,
                                        // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                                    }}
                                    className=' d-flex  justify-content-center'>
                                    <div className='p-relative'>



                                        <div
                                            style={{
                                                height: '40%', width: 5, top: "30%",
                                                left: 0, borderRadius: 5, position: 'absolute',
                                                display: 'flex', alignItems: 'flex-end'
                                            }}
                                            className="p-relative bg-dark-500 overflow-hidden"  >
                                            <div
                                                className='transition'
                                                style={{
                                                    width: '100%',
                                                    height: (item?.Health * 100 / item?.playerTroop?.MaxHealth) + "%"
                                                    ,
                                                    backgroundColor: 'var(--theme-color-green-dark)'
                                                }}
                                            />
                                        </div>
                                        <Badge style={{
                                            position: 'absolute',
                                            bottom: 0, right: 0, left: 0, marginInline: 'auto', width: 'fit-content'
                                        }} bg="dark" >
                                            {item?.playerTroop?.Level}
                                        </Badge>
                                        <img
                                            id={item?.playerTroopId + "-img"}
                                            className='scaleX'
                                            style={{
                                                position: 'relative', zIndex: 10,
                                                maxWidth: '100%',
                                                marginTop: windowSize?.width < 800 ? -50 : -66,
                                                maxHeight: windowSize?.width < 800 ? 88 : windowSize?.width < 1400 ? 95 : windowSize?.width < 1500 ? 120 : windowSize?.width > 1600 ? 140 : 120,
                                                transform: 'scaleX(-1) scale(2)'
                                            }}
                                            src={attacking === item?.playerTroopId ? item?.playerTroop?.Troop?.AttackAnimation : item?.playerTroop?.Troop?.IdleAnimation}
                                        />


                                    </div>
                                </div>
                            ))}

                            {attackers?.map((item) => (
                                <>
                                    {item?.Health === 0 ? '' : <OverlayTrigger placement="top" trigger={"click"} overlay={<Tooltip>

                                        <b>
                                            {item?.playerTroop?.Troop?.Name} - Level         {item?.playerTroop?.Level}
                                        </b>
                                        <div className='text-start fs-med'>
                                            <div className='d-flex align-items-center'>
                                                <hr style={{ flex: 1, marginInlineEnd: 5 }} />
                                                {troopTypes[item?.playerTroop?.DamageType]}
                                                <hr style={{ flex: 1, marginInlineStart: 5 }} />
                                            </div>
                                        
                                            <div>
                                                <img width={20} src="/assets/images/icons/damage.png" />   Damage:    {item?.playerTroop?.Damage}
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/health.png" />    Health:    {item?.Health} / {item?.playerTroop?.MaxHealth}
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/armor.png" />   Armor:    {item?.Armor} ({roundFloat(1 / (200 / item?.Armor + 1.25 ) * 100)}%)
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/range.png" />   Range:    {item?.playerTroop?.Range}
                                            </div>
                                            {item?.MagicResistance ?
                                                <div >
                                                    <img width={20} src="https://core.chainoflegends.com/assets/images/team-magic-resist.png" /> Magic Resistance: {item?.MagicResistance} ({roundFloat(1 / (200 / item?.MagicResistance + 1.25 ) * 100, 0)}%)
                                                </div> : ''}


                                        </div>

                                    </Tooltip>}>

                                        <div
                                            id={item?.playerTroopId + '-tooltip'}
                                            style={{
                                                position: 'absolute', width: elementSize, height: elementSize,
                                                top: item?.initialY * elementSize + (windowSize?.width < 1200 ? containerHeight / 5 : containerHeight / 5),
                                                left: (item?.initialX) * elementSize + 70,
                                                zIndex: 1000
                                            }}>

                                        </div>
                                    </OverlayTrigger>}
                                </>))}

                            {defenders.map((item, i) => (

                                <div
                                    key={item?.playerTroopId}
                                    id={item?.playerTroopId}
                                    style={{
                                        width: elementSize, height: elementSize,
                                        opacity: item?.Health === 0 ? 0 : 1,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        transition: '0.7s all',
                                        // transform:    `translateY(${elementSize / 2}px)`,
                                        // filter: selectMode && !item?.playerTroopId ? "invert(30%) sepia(99%) saturate(323%)" : '',
                                        position: 'absolute',
                                        top: item?.initialY * elementSize + (windowSize?.width < 1200 ? containerHeight / 5 : containerHeight / 5),
                                        textAlign: 'center',
                                        zIndex: 500,
                                        left: (item?.initialX) * elementSize + 70,
                                        // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                                    }}
                                    className=' d-flex   justify-content-center'>
                                    <div className='p-relative'>
                                        <div
                                            style={{ height: '40%', width: 5, top: "30%", left: 0, borderRadius: 5, position: 'absolute', display: 'flex', alignItems: 'flex-end' }}
                                            className="p-relative bg-dark-500 overflow-hidden"
                                        >
                                            <div
                                                className='transition'
                                                style={{
                                                    width: '100%',
                                                    height: (item?.Health * 100 / item?.playerTroop?.MaxHealth) + "%"
                                                    ,
                                                    backgroundColor: 'var(--theme-color-green-dark)'
                                                }}
                                            />
                                        </div>
                                        <Badge style={{
                                            position: 'absolute',
                                            bottom: 0, right: 0, left: 0, marginInline: 'auto', width: 'fit-content'
                                        }} bg="dark" >
                                            {item?.playerTroop?.Level}
                                        </Badge>
                                        <img
                                      
                                            style={{
                                                maxWidth: '100%',
                                                position: 'relative',
                                                zIndex: 20,
                                                marginTop: windowSize?.width < 800 ? -50 : -66,
                                                maxHeight: windowSize?.width < 800 ? 88 : windowSize?.width < 1400 ? 95 : windowSize?.width < 1500 ? 120 : windowSize?.width > 1600 ? 140 : 120,
                                                transform: 'scale(2)'
                                            }}
                                            src={attacking === item?.playerTroopId ? item?.playerTroop?.Troop?.AttackAnimation : item?.playerTroop?.Troop?.IdleAnimation}

                                        // src={item?.playerTroop?.Troop?.IdleAnimation || "https://core.chainoflegends.com/assets/troops/idle/goblin.webp"}
                                        />


                                    </div>
                                </div>



                            ))}
                            {defenders.map((item, i) => (
                                <>
                                    {item?.Health === 0 ? '' : <OverlayTrigger placement="top" trigger={"click"} overlay={<Tooltip>
                                        <b>
                                            {item?.playerTroop?.Troop?.Name} - Level         {item?.playerTroop?.Level}
                                        </b>
                                        <div className='text-start'>
                                            <div className='d-flex align-items-center'>
                                                <hr style={{ flex: 1, marginInlineEnd: 5 }} />
                                                {item?.playerTroop?.DamageType === 1 ? 'Physic' : "Magic"}
                                                <hr style={{ flex: 1, marginInlineStart: 5 }} />
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/damage.png" />   Damage:    {item?.playerTroop?.Damage}
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/health.png" />    Health:    {item?.Health} / {item?.playerTroop?.MaxHealth}
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/armor.png" />   Armor:    {item?.Armor} ({roundFloat(1 / (200 / item?.Armor + 1.25 ) * 100)}%)
                                            </div>
                                            <div>
                                                <img width={20} src="/assets/images/icons/range.png" />   Range:    {item?.playerTroop?.Range}
                                            </div>
                                            {item?.MagicResistance ?
                                                <div >
                                                    <img width={20} src="https://core.chainoflegends.com/assets/images/team-magic-resist.png" /> Magic Resistance: {item?.MagicResistance} ({roundFloat(1 / (200 / item?.MagicResistance + 1.25 ) * 100, 0)}%)
                                                </div> : ''}
                                        </div>

                                    </Tooltip>}>
                                        <div
                                            id={item?.playerTroopId + '-tooltip'}

                                            style={{
                                                position: 'absolute',
                                                width: elementSize, height: elementSize,
                                                top: item?.initialY * elementSize + (windowSize?.width < 1200 ? containerHeight / 5 : containerHeight / 5),
                                                left: (item?.initialX) * elementSize + 70,
                                                zIndex: 1000
                                            }}>
                                        </div>
                                    </OverlayTrigger>}
                                </>
                            ))}
                        </div>

                    </div>
                </div>
                <div style={{ position: 'fixed', bottom: 10, left: 20 }} className="d-flex w-100">

                    {isPlaying ?
                        <Button onClick={() => {
                            isPaused.current = !isPaused.current
                            setIsPausedState(prev => !prev)
                        }} variant='default' >
                            <img className='transition' src={isPausedState ? "/assets/images/icons/play.svg" : "/assets/images/icons/pause.svg"} width={isPausedState ? 10 : 12} />&nbsp;
                            {isPausedState ? 'Play' : 'Pause'}
                        </Button>
                        : <Button disabled={loading || isPlaying} onClick={handlePlay} variant='default' style={{ position: 'fixed', bottom: 10, left: 20 }}>

                            <img src="/assets/images/icons/play.svg" width={10} />&nbsp;
                            Play
                        </Button>}

                    {isPlaying && <Button variant='primary' onClick={handleReplay}>
                        <img src={"/assets/images/icons/replay.svg"} width={15} />&nbsp;Replay
                    </Button>}
                    <div className='ms-auto me-3 me-md-5'>
                        <img
                            onClick={() => navigate('/battle/replay/' + id + "#settings")}
                            width={60}
                            height={55}
                            style={{ padding: 0 }}
                            className='button '
                            src="/assets/images/icons/setting.png"
                        />
                    </div>

                </div>

            </div >
        </div>

    )
}

export default BattleReplay