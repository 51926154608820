// src/utils/redirect.js
import { useNavigate } from 'react-router-dom';

export const useLoginRedirect = () => {
    const navigate = useNavigate();
    const href = window.location.href;

    return () => {
        try {
            const currentUrl = new URL(href);
            
            const searchParams = new URLSearchParams(currentUrl.search);
            const returnParam = searchParams.get('return');
            if (returnParam) {
                const query = currentUrl.toString().split('return=')[1];
                navigate(query)
            }
        } catch (e) {
            console.error(e);
        }
    };
};
