import React, { useEffect, useState } from "react";
import GuildDetail from "./GuildDetail";
import Network from "../../../utils/Network";
import Build from "./Build";
import CircularProgress from "../../../components/CircularProgress";

type Props = {
  id: number | string;
  onSuccess:()=>void
};

const UpdateGuild = ({ id,onSuccess }: Props) => {
  const axios = new Network();
  const [data, setData] = useState<GuildDetail>();
  const [images, setImages] = useState<{images:string[];banners:string[]}>();
  const [loading, setLoading] = useState(true);

  const handleGetDetail = async () => {
    setLoading(true);
    const response = await axios.CallApi({
      url: `/guild/${id}`,
      method: "get",
    });
    if (response.ok) {
      setData(response.data);
    }
    setLoading(false);
  };
  const handleGetImages = async () => {
    const response = await axios.CallApi({
      url: `/guild/images`,
      method: "get",
    });
    if (response.ok) {
      setImages(response.data);
    }
  };

  useEffect(() => {
    handleGetImages()
    handleGetDetail();
  }, [id]);

  return (
    <div>
      {loading ? (<div style={{minHeight:300}} className="py-5 text-center d-flex align-items-center justify-content-center">
      <CircularProgress />

      </div>
      ) : (
        data && (
          <Build onSuccess={() => onSuccess()}  initialValue={{ ...data.guild, }} images={images!} />
        )
      )}
    </div>
  );
};

export default UpdateGuild;
