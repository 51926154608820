import React from "react";
import { ChallengeQuestItemType } from "../../types/quests/indext";
import LevelBadge from "../LevelBadge";
import ThousandsSeparator from "../../utils/thousands-separator";
import { CustomIcon } from "../Icons";
import Button from "../Button";
import { useWindowSize } from "usehooks-ts";

const ChallengeItemCard = ({
  rewards,
  icon,
  description,
  id,
  requiredActionValue,
  state,
  link,
  title,
  disabled,
  loading,
  discount,
  onClick,
  requiredAction,
}: ChallengeQuestItemType & {
  disabled: boolean;
  loading: boolean;
  discount?: number;
  onClick: (id: number) => void;
}) => {
  const { width } = useWindowSize();
  const reward: { level: number } = JSON.parse(rewards)[0];
  return (
    <div className="vertical-item-card py-2 py-sm-4 gap-2 align-items-center ">
      <LevelBadge
        className="align-items-center d-flex justify-content-center fs-big"
        style={{
          top: 10,
          right: 10,
          left: "unset",
          bottom: "unset",
          width: 44,
          height: 44,
          textAlign: "center",
        }}
        level={reward.level}
      />
      <img
        src={icon}
        width={"100%"}
        height={"auto"}
        style={{ maxWidth: width < 500 ? 66 : 130 }}
        className="circle"
      />
      <div
        style={{
          fontSize: width < 500 ? 14 : "25px",
        }}
        className="fs-lg "
      >
        {title}
      </div>
      <div className="fs-lg d-flex flex-column flex-sm-row align-items-center fs-med gap-1">
        Cost :
        <span className=" fs-lg text-center " style={{ color: "#E3A254" }}>
          <b>
            {discount ? (
              <>
                <div className="text-overline text-secondary fs-med">
                  
                  {ThousandsSeparator(requiredActionValue)}
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                <CustomIcon
                  width={19}
                  height={19}
                  icon="hammer"
                  className="me-1"
                />
                
                  {ThousandsSeparator(requiredActionValue * (100-discount) /100)} <small className="ps-1 text-danger fs-tiny">({discount}% OFF)</small></div>
              </>
            ) : (
              <>
                <CustomIcon
                  width={19}
                  height={19}
                  icon="hammer"
                  className="me-1"
                />
                {ThousandsSeparator(requiredActionValue)}
              </>
            )}{" "}
          </b>{" "}
          <small className="text-white fs-tiny d-none d-sm-inline opacity-50">
            points
          </small>
        </span>
      </div>
      <Button
        loading={loading}
        onClick={() => onClick(id)}
        variant="primary"
        size={width < 500 ? "small" : "medium"}
        className="mt-2"
        disabled={disabled}
      >
        Claim
      </Button>
    </div>
  );
};

export default ChallengeItemCard;
