import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import { AnimatePresence } from "framer-motion";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import ChatButton from "../containers/chat/ChatButton";
import RoutesConfig from "../routes/config";
import useAuth from "../hooks/useAuth";
import useSteps from "../utils/Steps";
import { closeChat, openChat } from "../redux/chat/actions";
import { IsBeta } from "../utils/Network";
import { setPlayable } from "../redux/music/action";
import ChatSystem from "../containers/ChatSystem";
import { IS_CHAT_ENABLED } from "../config";
import { ConnectorProvider } from "../game-engine/connector/context";
import { hubUrl } from "../game-engine/layouts/battle-layout";
import { BattleProvider } from "../game-engine/context";
import EventsProvider from "../game-engine/providers/events-prodvider";

const Navigation = () => {
  const steps = useSteps();
  const { userSettings } = useAuth();
  const dispatch = useDispatch();
  const enableBgMusic = () => {
    dispatch(setPlayable(true));
  };

  return (
    <TourProvider
      steps={steps as any}
      afterOpen={disableBodyScroll}
      beforeClose={enableBodyScroll}
    >
      <AnimatePresence exitBeforeEnter>
        <RoutesConfig
          enableBgMusic={enableBgMusic}
          userSettings={userSettings}
          IsBeta={IsBeta}
        />
      </AnimatePresence>
    </TourProvider>
  );
};

const Navigator = () => {
  const isOpen = useSelector((state: any) => state.chatState);
  const userInfo = useSelector((state: any) => state.userInfo);
  const userToken = useSelector((state: any) => state.userToken);
  const dispatch = useDispatch();
  const toggleChatDrawer = () => {
    isOpen ? dispatch(closeChat()) : dispatch(openChat());
  };

  return (
    <BattleProvider>
      <ConnectorProvider hubUrl={hubUrl!}>
        {IS_CHAT_ENABLED && !isOpen && userInfo && userToken && (
          <ChatButton onClick={toggleChatDrawer} />
        )}
        <BrowserRouter>
          <EventsProvider>
            <Navigation />
            {IS_CHAT_ENABLED && <ChatSystem />}
          </EventsProvider>
        </BrowserRouter>
      </ConnectorProvider>
    </BattleProvider>
  );
};

export default Navigator;
