import React from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import CircleProgressBar from "../circle-progress-bar";
import CircularProgress from "../../../components/CircularProgress";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type InfiniteLoadingProps = {
  size?: number; // Size of the loading circle
  color?: string; // Color of the loading ring
  speed?: number; // Speed of rotation, default is 1
  image: string; // Image URL
};

const LoaderIndicator: React.FC<InfiniteLoadingProps> = ({
  size = 1,
  color = "white",
  speed = 1,
  image,
}) => {
  return (
    <group position={[0, 0.7, 0]}>
      <Html center zIndexRange={[1000, 1000]} style={{ userSelect: "none" }}>
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip className="fs-tiny">Models Loading...</Tooltip>}

>
          <div>
            <div
              className="game-bar-troop-slot p-0  position-relative"
              style={{
                width: `${size * 15}px`,
                height: `${size * 15}px`,
                zIndex: 10,
                pointerEvents: "none",
              }}
            >
              <div className="content">
                <img
                  className="circle"
                  style={{ borderRadius: "50%" }}
                  width="100%"
                  src={image}
                  alt="loading indicator"
                />
                <CircularProgress
                  style={{
                    position: "absolute",
                    height: 15,
                    width: 15,
                    bottom: "-50%",
                    opacity: 0.888,
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                />
                {/* You can add a circular progress or spinner here if needed */}
              </div>
            </div>
          </div>
        </OverlayTrigger>
      </Html>
    </group>
  );
};

export default LoaderIndicator;
