import React, { useEffect, useMemo, useRef, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import Network from "../utils/Network";
import Button from "../components/Button";
import { Badge, Form, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import useToast from "../hooks/useToast";
import { motion } from "framer-motion";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Dialog from "../components/Dialog";
import Countdown from "react-countdown";
import { isMobile, isTablet } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { setPlayable } from "../redux/music/action";
import { useDispatch } from "react-redux";
import { createRef } from "react";
import { defaultBattlePositions } from "../utils/battle-positions";
import LevelBadge from "../components/LevelBadge";
import Slider from "rc-slider";
import BattleTitle from "../components/BattleTitle";
import LeaderShare from "../components/LeaderShare";
import TreasureInfo from "../components/TreasureInfo";
import { Helmet } from "react-helmet";
import { ItemBonusStat } from "../types/items";
import { CompetitionType } from "../types/global";
import { CustomIcon } from "../components/Icons";
import Skeleton from "react-loading-skeleton";
import useClegPrice from "../hooks/useClegPrice";
import CountUp from "react-countup";

type Props = {};

const defaultSupply = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
function isEven(n: string) {
  return parseInt(n) % 2 === 0 ? true : parseInt(n) === 0 ? true : false;
}
const TreasureOrder = (props: Props) => {
  const [startLoading, setStartLoading] = useState(false);
  const windowSize = useWindowSize();
  const contaierRef = useRef<HTMLDivElement>(null);
  const overFlowContainer = useRef<HTMLDivElement>(null);
  const supplyContainer = useRef<HTMLDivElement>(null);
  const [selectMode, setSelectMode] = useState(false);
  const [selectProperties, setSelectProperties] = useState({
    checkRoom: 0,
    battlePlace: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(0);
  const [arrayList, setArrayList] = useState<any>(defaultBattlePositions);
  const [supplyList, setSupplyList] = useState(defaultSupply);
  const [result, setResult] = useState<any>();
  let [searchParams, setSearchParams] = useSearchParams();
  const width = contaierRef?.current?.offsetWidth;
  const containerHeight = contaierRef?.current?.offsetHeight;
  const calculatedSize = (width! - 400) / (windowSize?.width < 800 ? 10 : 10);
  const elementSize = calculatedSize > 200 ? 200 : calculatedSize;
  const axios = new Network();
  const [activePopper, setActivePopper] = useState<any>({});
  const [allTroops, setAllTroops] = useState<any>([]);
  const openToast = useToast();
  const adventureId = parseInt(searchParams.get("adventureId") || "0");
  const isCaptainTreasure = Boolean(searchParams.get("captainTreasure"));
  const [resultDialog, setResultDialog] = useState(false);
  const isRaid = Boolean(searchParams.get("raid"));
  const adventureMode = parseInt(searchParams.get("adventureMode") || "1");
  const [data, setData] = useState<CompetitionType>();
  const [leaderShare, setLeaderShare] = useState(0);
  const { clegInUsd } = useClegPrice();
  const [selectDialog, setSelectDialog] = useState<any>({
    open: false,
    item: null,
  });
  async function getTroops() {
    const response = await axios.CallApi({ url: "/troop", method: "get" });
    setAllTroops(response?.data);
  }
  useEffect(() => {
    getTroops();
  }, []);

  useEffect(() => {}, [arrayList]);

  const handleOnMouseEnter = (id) => {
    setShow(id);
  };
  const handleOnMouseLeave = () => {
    setShow(0);
  };

  const handleCompetitionData = async () => {
    const response = await axios.CallApi({
      url: "/treasure/competition/monthly/info?treasureId=" + searchParams.get("treasureId"),
      method: "get",
    });
    setData(response.data);
  };
  useEffect(() => {
    if (isCaptainTreasure) handleCompetitionData();
  }, [isCaptainTreasure]);
  function handleSelectCheckRoom(id: number) {
    setSelectMode(true);
    // if (selectProperties?.checkRoom) {
    //     setSelectProperties(prev => ({ ...prev, checkRoom: id }))
    //     setSelectMode(false)
    //     return
    // }
    if (selectProperties?.battlePlace) {
      // TODO CHANGE
      return;
    }
    setSelectProperties({ battlePlace: 0, checkRoom: id });
  }
  function handleBattlePlace(id?: number | string, i?: number) {
    if (selectProperties.checkRoom) {
      setArrayList((prev: any) =>
        [
          ...prev?.filter((item) => item?.index !== i),
          {
            ...prev?.find((irt) => irt?.index === i),
            playerTroopId: selectProperties.checkRoom,
          },
        ]?.sort((a, b) => a.index - b.index)
      );
      setSelectProperties({ battlePlace: 0, checkRoom: 0 });
      setSelectMode(false);
      return;
    }
    if (selectProperties.battlePlace) {
      if (id && id === selectProperties.battlePlace) {
        setSelectProperties({ battlePlace: 0, checkRoom: 0 });
        setSelectMode(false);
        return;
      }

      setArrayList((prev: any) =>
        [
          ...prev?.filter(
            (item) =>
              item?.index !== i &&
              item?.playerTroopId !== selectProperties.battlePlace
          ),
          {
            ...arrayList?.find((irt) => irt?.index === i),
            playerTroopId: selectProperties.battlePlace,
          },
          {
            ...arrayList?.find(
              (irt) => irt?.playerTroopId === selectProperties.battlePlace
            ),
            playerTroopId: id ? id : undefined,
          },
        ]?.sort((a, b) => a.index - b.index)
      );
      setSelectProperties({ battlePlace: 0, checkRoom: 0 });
      setSelectMode(false);
      return;
    }
    if (id) {
      setSelectMode(true);
      setSelectProperties({
        battlePlace: parseInt(id?.toString()),
        checkRoom: 0,
      });
    }
  }
  const popperActiveItem: any = arrayList?.find(
    (item) => item?.playerTroopId === activePopper?.id
  );

  function changeTargetPriority(id: number, value: number) {
    const oldObj: any = arrayList?.find((item) => item?.playerTroopId === id);
    setArrayList((prev) =>
      [
        ...prev?.filter((item) => item?.playerTroopId !== id),
        { ...oldObj, targetPriority: value },
      ]?.sort((a, b) => a.index - b.index)
    );
  }

  const getPlayerToops = async () => {
    const response = await axios.CallApi({
      url: "/playertroop?count=50",
      method: "GET",
    });
    if (response.ok) setSupplyList(response?.data?.data);
  };

  useEffect(() => {
    getPlayerToops();
  }, []);
  useEffect(() => {
    if (overFlowContainer) {
      overFlowContainer.current.scrollLeft =
        windowSize.width < 600 ? contaierRef?.current?.offsetWidth / 3.6 : 200;
    }
  }, []);

  useEffect(() => {
    dispatch(setPlayable(false));
  }, []);
  const startAdventure = async () => {
    setStartLoading(true);
    const activeArray = [
      ...arrayList?.filter(
        (item) => Boolean(item.playerTroopId) || Boolean(item.troopId)
      ),
    ];
    const response = await axios.CallApi({
      url: "/adventure/start",
      body: {
        treasureId: parseInt(searchParams.get("treasureId")! || "1"),
        adventureMode: adventureMode,
        leaderShare,
        competition: isCaptainTreasure,
        invadeToAdventureId: adventureId ? adventureId : undefined,
        playerTroopIds: activeArray
          ?.filter((item) => Boolean(item?.playerTroopId))
          ?.map((item) => item.playerTroopId),
        formation: activeArray?.map((item) => ({
          ...item,
          playerTroopId: item?.playerTroopId || 0,
          playerTroop:
            supplyList?.find(
              (detail: any) => detail?.id === item.playerTroopId
            ) || undefined,
        })),
      },
    });
    if (response.ok) {
      navigate(`/adventure/waiting/treasure/${response?.data?.result?.id}`);
      // setResult(response?.data?.result)
      // setResultDialog(true)
    }
    openToast(response);
    setStartLoading(false);
  };
  function openSelect(item) {
    setSelectDialog({ open: true, item: { troopId: 1, level: 1, ...item } });
  }

  function closeSelectDialog() {
    setSelectDialog((prev) => ({ ...prev, open: false }));
  }

  const popover = (
    <Popover
      onClick={(e) => e.stopPropagation()}
      id="popover-basic"
      style={{
        backgroundColor: "transparent",
        backgroundImage: "url(/assets/images/backgrounds/popper-bg.png)",
        backgroundSize: "100% 100%",
        minHeight: 210,
        width: 250,
        color: "#fff",
        border: "none",
      }}
      onMouseEnter={() => handleOnMouseEnter(popperActiveItem?.playerTroopId)}
      onMouseLeave={handleOnMouseLeave}
    >
      <Popover.Header
        style={{
          backgroundColor: "transparent",
          border: "none",
        }}
        className="p-4 bold d-flex align-items-center"
        as="h3"
      >
        <div className="icon-badge">
          <img
            src={activePopper?.troop?.icon}
            width={35}
            height={35}
            className=""
          />
        </div>
        Target priority
      </Popover.Header>
      <Popover.Body style={{ marginTop: -20 }} className="text-white ps-4">
        <div style={{ marginTop: -10, marginBottom: 10 }} className="fs-tiny">
          <span>
            HP: {activePopper?.health}/{activePopper?.maxHealth} | Damage:{" "}
            {activePopper?.damage} | Armor: {activePopper?.armor} | Magic
            Resistance: {activePopper?.magicResistance}
          </span>
        </div>
        <div className="d-flex flex-wrap gap-1 mb-2">
          {activePopper?.items?.map((item) => (
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {item?.item?.name} - level {item?.level}{" "}
                  <div className="text-success-light">
                    +{item?.bonusValue} {ItemBonusStat[item?.item?.bonusStat]}
                  </div>
                </Tooltip>
              }
            >
              <div className="circle border p-1">
                <img
                  width={25}
                  height={25}
                  className="circle"
                  src={item.image}
                />
              </div>
            </OverlayTrigger>
          ))}
        </div>
        <Form.Check
          type={"radio"}
          value={1}
          checked={popperActiveItem?.targetPriority === 1}
          label={`Closest`}
          onClick={(e) =>
            changeTargetPriority(popperActiveItem?.playerTroopId, 1)
          }
        />
        <Form.Check
          type={"radio"}
          value={2}
          checked={popperActiveItem?.targetPriority === 2}
          onClick={(e) =>
            changeTargetPriority(popperActiveItem?.playerTroopId, 2)
          }
          label={`Lowest HP`}
        />
        <Form.Check
          type={"radio"}
          value={3}
          checked={popperActiveItem?.targetPriority === 3}
          onClick={(e) =>
            changeTargetPriority(popperActiveItem?.playerTroopId, 3)
          }
          label={`Highest Damage`}
        />
        <br />
      </Popover.Body>
    </Popover>
  );

  // LongPress
  const timer = useRef<any>(null);
  function handleButtonPress(e: Event, item) {
    timer.current = setTimeout(() => {
      if (!isMobile && !isTablet) return;
      if (item.isLocked) return;
      handleOnMouseEnter(item?.playerTroopId);
    }, 300);
  }

  function handleButtonRelease() {
    clearTimeout(timer.current);
  }

  const RewardInUsd = useMemo(() => {
    if (clegInUsd && data) {
      return data.rewardPool * clegInUsd;
    }
  }, [clegInUsd, data]);

  return (
    <div
      ref={overFlowContainer}
      onClick={() => handleOnMouseLeave()}
      style={{ overflow: "auto" }}
    >
      <Helmet>
        <title key={"title"}>Adventure | Chain Of Legends</title>
      </Helmet>
      {isCaptainTreasure ? (
        <>
          {data && (
            <div
              style={{
                background: "rgba(0,0,0,.5)",
                backdropFilter:'blur(10px)',
                position: "fixed",
                right: 15,
                top: 10,
              }}
              className="rounded p-2"
            >
              <div className="d-flex justify-content-center align-items-center gap-2">
                <CustomIcon width={18} height={18} icon="dollar" />
                {!RewardInUsd ? (
                  <Skeleton enableAnimation width={70} />
                ) : (
                  <CountUp
                    style={{ color: "#FFE266" }}
                    separator=","
                    className="bold fs-md"
                    end={RewardInUsd!}
                    suffix=" USD"
                  />
                )}
              </div>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <CustomIcon width={18} height={18} icon="cleg" />
                <CountUp
                  style={{ color: "#DB86AC" }}
                  separator=","
                  className="bold fs-md"
                  decimal="."
                  decimals={1}
                  end={data.rewardPool}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <TreasureInfo treasureType={2} id={searchParams.get("treasureId")!} />
      )}

      <div
        style={{
          display: "flex",
          minHeight:
            windowSize.width > 1000
              ? windowSize.width > 1800
                ? elementSize * 8.5
                : "1024px"
              : elementSize * 11,
          justifyContent: windowSize.width < 990 ? "flex-start" : "",
        }}
        ref={contaierRef}
        className="treasure "
      >
        <div
          style={{
            maxWidth: windowSize.width < 990 ? "none" : "",
            paddingInlineStart:
              windowSize.width > 1500
                ? "8%"
                : windowSize.width < 990
                ? "8%"
                : "10%",
          }}
          className="w-100  transition pe-3 "
        >
          <div style={{ minWidth: 800 }} className="row ">
            <div
              style={{ position: "relative" }}
              className="d-flex mt-4 align-items-center col-12"
            >
              {/* <BattleTitle/> */}

              {arrayList?.map((item, i) => {
                const ref = createRef();
                return (
                  <div
                    key={i}
                    className="tr-polygon d-flex pf-step4 justify-content-center"
                    onClick={() =>
                      (isMobile || isTablet) && !item.troopId
                        ? handleBattlePlace(item?.playerTroopId, i)
                        : null
                    }
                    onDragEnter={(e) => {
                      const id = parseInt(e.dataTransfer.getData("text/plain"));
                      // if (id) e
                      //     .currentTarget
                      //     .style
                      //     .filter = ''
                    }}
                    onDragLeave={(e) => {
                      // if (item?.playerTroopId) return e.currentTarget
                      //     .style
                      //     .filter = ""
                      // e.currentTarget
                      //     .style
                      //     .filter = ''
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      const id = parseInt(e.dataTransfer.getData("text/plain"));
                      if (item?.troopId) return;
                      const isThereBefore = arrayList?.find(
                        (item) => item.playerTroopId === id
                      );

                      if (isThereBefore) {
                        setArrayList((prev) =>
                          [
                            ...prev?.filter(
                              (item) => item?.playerTroopId !== id
                            ),
                            {
                              ...arrayList?.find(
                                (item) => item.playerTroopId === id
                              ),
                              playerTroopId: undefined,
                            },
                          ]?.sort((a, b) => a.index - b.index)
                        );
                      }
                      if (!id) return;
                      setArrayList((prev) =>
                        [
                          ...prev?.filter((item) => item?.index !== i),
                          { ...item, playerTroopId: id },
                        ]?.sort((a, b) => a.index - b.index)
                      );
                      if (!isThereBefore) {
                        setTimeout(() => {
                          handleOnMouseEnter(id);
                        }, 200);
                      }
                    }}
                    style={{
                      width: elementSize,
                      height: elementSize,
                      backgroundImage: item?.troopId
                        ? "url(/assets/images/battle/rd-polygon.png)"
                        : "",
                      // transform:    `translateY(${elementSize / 2}px)`,
                      filter:
                        selectMode && !item?.playerTroopId && !item?.troopId
                          ? "invert(30%) sepia(99%) saturate(323%)"
                          : "",
                      position: "absolute",
                      top:
                        item?.y * elementSize +
                        (windowSize?.width < 1200
                          ? containerHeight / 7
                          : containerHeight / 7),
                      textAlign: "center",
                      left: item?.displayX * elementSize + 70,
                      // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                    }}
                  >
                    {isRaid &&
                      item?.playerTroopId !== 0 &&
                      !item?.playerTroopId &&
                      !item?.troopId && (
                        <div
                          style={{
                            position: "absolute",
                            background: "#e8a55f",
                            boxShadow: "inset 1px 1px 8px  #be7134",
                            bottom: 0,
                          }}
                          onClick={() => !selectMode && openSelect(item)}
                          className="pointer px-2 rounded"
                        >
                          +
                        </div>
                      )}
                    {item?.troopId && (
                      <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}>
                        <img
                          className="scaleX"
                          // onDragStart={(event) => {
                          //     event
                          //         .dataTransfer
                          //         .setData('text/plain', item?.playerTroopId)
                          // }}
                          draggable={false}
                          style={{
                            maxWidth: "100%",
                            marginTop: -20,
                            maxHeight:
                              windowSize?.width > 1700
                                ? 150
                                : windowSize?.width < 800
                                ? 88
                                : 100,
                          }}
                          src={
                            allTroops?.find(
                              (detail) => detail?.id === item?.troopId
                            )?.image
                          }
                        />
                        <div
                          onClick={() => openSelect(item)}
                          style={{
                            position: "absolute",
                            background: "#e8a55f",
                            boxShadow: "inset 1px 1px 8px  #be7134",

                            bottom: 0,
                            width: "fit-content",
                            right: 0,
                            left: 0,
                          }}
                          className="pointer px-1 rounded mx-auto"
                        >
                          <img
                            draggable={false}
                            width={15}
                            src={"/assets/images/icons/edit.svg"}
                          />
                        </div>
                      </motion.div>
                    )}
                    {item?.playerTroopId && (
                      <OverlayTrigger
                        onEnter={(e) => {
                          setActivePopper(
                            supplyList?.find(
                              (supp) => supp?.id === item?.playerTroopId
                            )
                          );
                        }}
                        key={item?.playerTroopId}
                        show={show === item?.playerTroopId}
                        container={ref}
                        placement="right"
                        overlay={popover}
                      >
                        <div
                          ref={ref}
                          onTouchStart={(e) => handleButtonPress(e, item)}
                          onTouchEnd={handleButtonRelease}
                          onMouseDown={(e) => handleButtonPress(e, item)}
                          onMouseUp={handleButtonRelease}
                          onMouseLeave={handleButtonRelease}
                          onClick={(e) => {
                            if (isTablet || isMobile) return;
                            if (item.isLocked) return;
                            e.stopPropagation();
                            handleOnMouseEnter(item?.playerTroopId);
                          }}
                        >
                          <div
                            style={{
                              height: "40%",
                              width: 5,
                              top: "30%",
                              left: 0,
                              borderRadius: 5,
                              position: "absolute",
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                            className="p-relative bg-dark-500 overflow-hidden"
                          >
                            <div
                              style={{
                                width: "100%",
                                height:
                                  (supplyList?.find(
                                    (detail) =>
                                      detail?.id === item?.playerTroopId
                                  )?.health *
                                    100) /
                                    supplyList?.find(
                                      (detail) =>
                                        detail?.id === item?.playerTroopId
                                    )?.maxHealth +
                                  "%",
                                backgroundColor:
                                  "var(--theme-color-green-dark)",
                              }}
                            />
                          </div>
                          <LevelBadge
                            level={
                              supplyList?.find(
                                (detail) => detail?.id === item?.playerTroopId
                              )?.level
                            }
                          />
                          <img
                            className="scaleX"
                            onDragStart={(event) => {
                              event.dataTransfer.setData(
                                "text/plain",
                                item?.playerTroopId
                              );
                            }}
                            draggable
                            style={{
                              maxWidth: "100%",
                              marginTop: -20,
                              maxHeight:
                                windowSize?.width > 1700
                                  ? 150
                                  : windowSize?.width < 800
                                  ? 88
                                  : 100,
                            }}
                            src={
                              supplyList?.find(
                                (detail) => detail?.id === item?.playerTroopId
                              )?.troop?.image
                            }
                          />
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>

                  // <div style={{
                  //     width: elementSize,
                  //     height: elementSize,
                  //     transform:    `translateY(${elementSize / 2}px)`,
                  // }} id={`position${item?.index}`} className='pre-polygon'>
                  // </div>
                );
              })}
              {defaultBattlePositions?.map((item) => (
                <div
                  className="tr-polygon"
                  style={{
                    width: elementSize,
                    height: elementSize,
                    // transform:    `translateY(${elementSize / 2}px)`,
                    position: "absolute",
                    opacity: 0.4,
                    top:
                      item?.y * elementSize +
                      (windowSize?.width < 1200
                        ? containerHeight / 7
                        : containerHeight / 7),
                    left: (item?.displayX + 5) * elementSize + 70,
                    // backgroundImage: item?.playerTroopId ? 'url(/assets/images/battle/pre-polygon-active.png)' : 'url(/assets/images/battle/polygon.png)'
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
        {adventureMode > 1 && (
          <>
            <LeaderShare value={leaderShare} onChange={setLeaderShare} />
          </>
        )}

        {windowSize?.width < 800 ? (
          <div
            className="text-center d-flex"
            style={{
              position: "fixed",
              bottom: "140px",
              right: 0,
              left: 0,
              zIndex: 500,
            }}
          >
            {selectMode && selectProperties.battlePlace ? (
              <Button
                onClick={() => {
                  setArrayList((prev: any) =>
                    [
                      ...prev?.filter(
                        (item) =>
                          item?.playerTroopId !== selectProperties.battlePlace
                      ),
                      {
                        ...prev?.find(
                          (irt) =>
                            irt?.playerTroopId === selectProperties.battlePlace
                        ),
                        playerTroopId: undefined,
                      },
                    ]?.sort((a, b) => a.index - b.index)
                  );
                  setSelectMode(false);
                  setSelectProperties({ battlePlace: 0, checkRoom: 0 });
                }}
                variant="wide"
                size="small"
                style={{ flex: 1, maxWidth: 160 }}
                className="mx-auto "
              >
                Remove
              </Button>
            ) : (
              ""
            )}
            <Button
              size="small"
              style={{ flex: 1, maxWidth: 160 }}
              loading={startLoading}
              onClick={startAdventure}
              className="mx-auto "
              variant="wide-primary"
            >
              Start adventure
            </Button>
          </div>
        ) : (
          ""
        )}
        <div
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            const id = parseInt(e.dataTransfer.getData("text/plain"));
            if (arrayList?.find((item) => item.playerTroopId === id)) {
              setArrayList((prev: any) =>
                [
                  ...prev?.filter((item) => item?.playerTroopId !== id),
                  {
                    ...arrayList?.find((item) => item.playerTroopId === id),
                    playerTroopId: undefined,
                  },
                ]?.sort((a, b) => a.index - b.index)
              );
            }
            if (!id) return;
            // setArrayList(prev => ([...prev?.filter(item => item?.index !== i), { ...item, playerTroopId: id }]?.sort((a, b) => a.index - b.index)))
          }}
          className="picker-bar pf-step2 align-items-center"
        >
          <div
            style={{ flex: 1, height: "100%", padding: "15px 0" }}
            ref={supplyContainer}
          >
            <PerfectScrollbar
              style={{ width: supplyContainer?.current?.offsetWidth }}
            >
              <div
                style={{ height: "100%" }}
                className="d-flex align-items-center"
              >
                {supplyList?.filter((item) => item?.state === 1).length ===
                  0 && (
                  <div style={{ flex: 1 }} className="pt-2 ps-4">
                    No troop available , You can train troops in your
                    <Link to="/barracks">
                      <a className="text-info fw-normal"> Barracks</a>{" "}
                    </Link>
                  </div>
                )}
                {supplyList?.map((item: any) => {
                  if (
                    !arrayList?.find((obj) => obj.playerTroopId === item?.id) &&
                    item?.state === 1
                  )
                    return (
                      <div
                        style={{
                          width: windowSize?.width > 800 ? width / 10 - 5 : 120,
                          minWidth: 120,
                          height: "100%",
                          opacity: selectMode
                            ? selectProperties?.battlePlace
                              ? 1
                              : selectProperties?.checkRoom === item?.id
                              ? 1
                              : 0.4
                            : 1,
                        }}
                        className="fight-placement pf-step3"
                      >
                        <div
                          onClick={() =>
                            isMobile || isTablet
                              ? handleSelectCheckRoom(item.id)
                              : null
                          }
                          onDragStart={(event) => {
                            event.dataTransfer.setData("text/plain", item.id);
                          }}
                          draggable={true}
                          className="d-flex w-100 h-100 text-center p-relative justify-content-center align-items-center"
                        >
                          <div
                            style={{
                              height: "40%",
                              width: 5,
                              top: "30%",
                              left: "17%",
                              transform: "rotate(12deg)",
                              borderRadius: 5,
                              position: "absolute",
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                            className="p-relative bg-dark-500 overflow-hidden"
                          >
                            <div
                              style={{
                                width: "100%",
                                height:
                                  (item?.health * 100) / item?.maxHealth + "%",
                                backgroundColor:
                                  "var(--theme-color-green-dark)",
                              }}
                            />
                          </div>
                          <LevelBadge level={item?.level} />
                          <img
                            draggable={false}
                            className="mx-auto scaleX"
                            style={{ maxWidth: "75%", maxHeight: 100 }}
                            src={item?.troop?.image}
                          />
                        </div>
                      </div>
                    );
                  else {
                    return "";
                  }
                })}
              </div>
            </PerfectScrollbar>
          </div>

          {windowSize?.width > 800 && (
            <div className="d-flex flex-column">
              <Button
                loading={startLoading}
                onClick={startAdventure}
                className="ms-auto mx-2"
                variant="wide-primary"
              >
                Start adventure
              </Button>
              {/* {!isRaid &&
                            <Button variant='wide' disabled={startLoading} onClick={() => {
                                setSearchParams({ treasureId: searchParams.get('treasureId'), raid: true, })
                            }
                            } >
                                Convert to Raid
                            </Button>} */}
            </div>
          )}
        </div>
        <Dialog
          closeLabel="Return"
          title="Adventure Result"
          open={resultDialog}
          onClose={() => {
            setResultDialog(false);
            navigate("/adventure");
          }}
        >
          <p className="fs-big text-center bold dark-yellow">
            Troops sent successfully
          </p>
          <div style={{ marginBottom: -30 }} className="row mt-5 flex-column">
            <div className="d-flex mb-2 align-items-center justify-content-between">
              {/* <img width={80} height={90} src={}/> */}
              <span>
                <img
                  src={"/assets/images/icons/timer.png"}
                  width={25}
                  height={25}
                />{" "}
                Arrival time
              </span>
              <Countdown
                date={new Date(result?.arriveDate)}
                renderer={({ formatted, days }) => (
                  <div className="fs-big d-flex">
                    {days ? (
                      <div className="time-badge me-2">
                        {formatted.days}
                        <span className="dark-yellow">Days</span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="time-badge">
                      {formatted.hours}
                      <span className="dark-yellow">Hours</span>
                    </div>
                    <div className="ms-2 time-badge">
                      {formatted.minutes}
                      <span className="dark-yellow">Minutes</span>
                    </div>
                    <div className="ms-2 time-badge">
                      {formatted.seconds}
                      <span className="dark-yellow">Seconds</span>
                    </div>
                  </div>
                )}
              />
            </div>
            <hr />
            <div className="d-flex mb-2 align-items-center justify-content-between">
              {/* <img width={80} height={90} src={}/> */}
              <span>
                <img
                  src={"/assets/images/icons/timer.png"}
                  width={25}
                  height={25}
                />{" "}
                Return time
              </span>
              <Countdown
                date={new Date(result?.returnDate)}
                renderer={({ formatted, days }) => (
                  <div className="fs-big d-flex">
                    {days ? (
                      <div className="time-badge me-2">
                        {formatted.days}
                        <span className="dark-yellow">Days</span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="time-badge">
                      {formatted.hours}
                      <span className="dark-yellow">Hours</span>
                    </div>
                    <div className="ms-2 time-badge">
                      {formatted.minutes}
                      <span className="dark-yellow">Minutes</span>
                    </div>
                    <div className="ms-2 time-badge">
                      {formatted.seconds}
                      <span className="dark-yellow">Seconds</span>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          onSubmit={() => {
            setArrayList((prev) =>
              [
                ...prev?.filter(
                  (item) => item.index !== selectDialog.item.index
                ),
                selectDialog.item,
              ]?.sort((a, b) => a?.index - b?.index)
            );
            setSelectDialog((prev) => ({ ...prev, open: false }));
          }}
          open={selectDialog.open}
          title="Request Troop"
          submitLabel="Request"
          onClose={closeSelectDialog}
        >
          <div>
            Selected Troop :{" "}
            {
              allTroops?.find(
                (item: any) => item?.id === selectDialog?.item?.troopId
              )?.name
            }
          </div>
          <PerfectScrollbar
            style={{ maxWidth: "100%" }}
            className="d-flex mt-2 pb-2"
          >
            {allTroops?.map((troop: any) => (
              <img
                onClick={() =>
                  setSelectDialog((prev: any) => ({
                    open: true,
                    item: { ...prev.item, troopId: troop.id },
                  }))
                }
                className={`select-troop ${
                  selectDialog?.item?.troopId === troop.id && "active"
                }`}
                src={troop.icon}
              />
            ))}
          </PerfectScrollbar>

          <div className="d-flex mt-2 align-items-center">
            <div style={{ minWidth: 111 }} className="pe-2 fs-med mt-1">
              Minimum Level
            </div>
            <Slider
              marks={{
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                6: 6,
                7: 7,
                8: 8,
                9: 9,
                10: 10,
                11: 11,
                12: 12,
              }}
              max={12}
              value={selectDialog?.item?.level}
              min={1}
              className={"mx-auto "}
              onChange={(val) =>
                setSelectDialog((prev: any) => ({
                  open: true,
                  item: { ...prev.item, level: val },
                }))
              }
              style={{ width: " 100%", maxWidth: 400 }}
              step={1}
            />
          </div>
          <div className="mt-5 mb-2">Target Priority :</div>
          <div className="d-flex  align-items-center gap-2">
            <div
              onClick={(e) =>
                setSelectDialog((prev: any) => ({
                  open: true,
                  item: { ...prev.item, targetPriority: 1 },
                }))
              }
            >
              <Form.Check
                type={"radio"}
                value={1}
                checked={selectDialog?.item?.targetPriority === 1}
                label={`Closest`}
              />
            </div>
            <div
              onClick={(e) =>
                setSelectDialog((prev: any) => ({
                  open: true,
                  item: { ...prev.item, targetPriority: 2 },
                }))
              }
            >
              <Form.Check
                type={"radio"}
                value={2}
                checked={selectDialog?.item?.targetPriority === 2}
                label={`Lowest HP`}
              />
            </div>
            <div
              onClick={(e) =>
                setSelectDialog((prev: any) => ({
                  open: true,
                  item: { ...prev.item, targetPriority: 3 },
                }))
              }
            >
              <Form.Check
                type={"radio"}
                value={3}
                checked={selectDialog?.item?.targetPriority === 3}
                label={`Highest Damage`}
              />
            </div>
          </div>
          {arrayList?.find((item) => item?.index === selectDialog?.item?.index)
            ?.troopId && (
            <div
              style={{ marginBottom: -60 }}
              className="text-center mt-2 w-100"
            >
              <Button
                onClick={() => {
                  setArrayList((prev) =>
                    [
                      ...prev?.filter(
                        (item) => item.index !== selectDialog.item.index
                      ),
                      {
                        ...selectDialog.item,
                        level: undefined,
                        troopId: undefined,
                        targetPriority: undefined,
                      },
                    ]?.sort((a, b) => a?.index - b?.index)
                  );
                  setSelectDialog((prev) => ({ ...prev, open: false }));
                }}
                variant="wide-secondary"
                className="mx-auto"
                style={{ minWidth: 70 }}
              >
                <img height={18} src={"/assets/images/icons/trash.svg"} />
                Delete Troop
              </Button>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default TreasureOrder;
