import { motion } from "framer-motion";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import MidBox from "../../components/Boxes/MidBox";
import Button from "../../components/Button";
import useToast from "../../hooks/useToast";

const WalletAddressBox = () => {
  const userInfo = useSelector((state: any) => state.userInfo);

  const openToast = useToast();

  return (
    <motion.div
      initial={isMobile ? undefined : { opacity: 0, y: 200 }}
      transition={{
        duration: 0.3,
        delay: 0,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{ opacity: 0, y: -200 }}
      className="col-12 mb-2 "
    >
      <MidBox modal title="Wallet Address">
        <div className="d-flex flex-wrap px-4 px-md-3">
          <div className="col-12 col-md-7 col-lg-10 mb-3 mb-md-0">
            <label
              htmlFor="basic-url"
              className="form-label text-light fs-small fw-bold text-shadow mb-1"
            >
              Your Wallet Address
            </label>
            <br />
            <input
              readOnly
              value={userInfo?.walletAddress}
              className="fs-med fw-med dark-yellow text-wrap-force transparent-input w-100"
            />
          </div>
          <div className="col-12 col-md-3 col-lg-2 text-center text-md-end">
            <CopyToClipboard
              text={userInfo?.walletAddress}
              onCopy={() =>
                openToast({
                  ok: true,
                  data: { message: "Wallet Address copied to clipboard" },
                })
              }
            >
              <Button variant="default">Copy</Button>
            </CopyToClipboard>
          </div>
        </div>
      </MidBox>
    </motion.div>
  );
};

export default WalletAddressBox;
