import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRate } from '../redux/rates/action';
import { useIsFirstRender } from 'usehooks-ts';


const useClegPrice = () => {
  const rates = useSelector((state: any) => state.rates);
  const dispatch = useDispatch()
  const isFirst = useRef(true)
  const handleFetchClegPrice = async () => {
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=chain-of-legends"
    );
    const price = response.data?.[0]?.current_price
    if (price) dispatch(setRate({ clegInUsd: response.data?.[0]?.current_price, expiresAt: Date.now() + 600000 }))
  };

  useEffect(() => {
    if (!isFirst.current) return
    isFirst.current = false
    if (!rates || (!rates.expiresAt || rates.expiresAt < Date.now())) {
      handleFetchClegPrice()
    }
  }, [])

  return rates
}

export default useClegPrice