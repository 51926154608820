import React from 'react'
import { RankingType } from '../types';
import Network from '../../utils/Network';



const useArenaLeaderBoard = () => {

    const [data, setData] = React.useState<RankingType>();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [page, setPage] = React.useState<{ total: number; page: number }>({
        total: 0,
        page: 0,
    });
    const { CallApi } = new Network();

    const handleGetLeaderboard = async (page: number) => {
        setLoading(true);
        const { ok, data } = await CallApi<RankingType>({
            url: `/ranking?rankingType=9&page=${page}`,
            method: "get",
        });
        if (ok) {
            setData(data);
            setError(false);
            setPage({ total: data.totalCount, page: page });
        } else {
            setError(true);
        }
        setLoading(false);
    };

    return (
        { data, loading, error, page, fetch: handleGetLeaderboard,setPage }
    )
}

export default useArenaLeaderBoard