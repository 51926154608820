import Decimal from "decimal.js-light";

export function roundFloat(
  number: number | string,
  decimal: number = 2,
  round: number = Decimal.ROUND_DOWN
) {
  try {
    return new Decimal(number || 0).toDecimalPlaces(decimal, round)?.toString();
  } catch (e) {
    console.log(e)
    return 0;
  }
}
