import React from "react";
import MidBox from "../../components/Boxes/MidBox";
import Button from "../../components/Button";
import {
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";


const whitelist = [
  "http://alpha.chainoflegends.com:3000",
  "https://alpha.chainoflegends.com:3000",
  "http://alpha.chainoflegends.com",
  "http://localhost:3000",
  "http://localhost",
  "https://alpha.chainoflegends.com",
];


const Authorize = () => {
  const [searchParameters,] = useSearchParams();
  const appName = searchParameters.get("appName");
  const appUrl = searchParameters.get("appUrl");
  const token = useSelector((state: any) => state.userToken);
  // const navigate = useNavigate();
  const handleAuthorize = () => {
    const target = appUrl?.endsWith("/") ? appUrl : appUrl + "/";
    window.location.href =
      target + `?authorize=true&authorized-token=${encodeURIComponent(token!)}`;
  };

  const handleDeny = () => {
    const target = appUrl?.endsWith("/") ? appUrl : appUrl + "/";
    window.location.href = target + `?authorize=false`;
  };


  const isWhitelisted = whitelist.includes(appUrl || "none");

  if (!isWhitelisted) {
    return (
      <div>
        <MidBox
          modal
          containerClass=""
          noTitle
          style={{ maxWidth: 600 }}
          className="mx-auto"
        >
          <div
            style={{ marginTop: -30, marginBottom: -20 }}
            className="flex-column align-items-center gap-3 d-flex "
          >
            <img
              src="/assets/images/error.png"
              width={"80%"}
              style={{ maxWidth: 200 }}
            />
            <p className="text-center fs-big">
              This app is not whitelisted. Please contact the app developer.
            </p>
            <div className="d-flex">
              <Link to="/">
                <Button className="mt-2" variant="wide-primary">
                  Back to Home
                </Button>
              </Link>{" "}
            </div>
          </div>
        </MidBox>
      </div>
    );
  }

  return (
    <div>
      <div className="">
        <MidBox
          modal
          containerClass=""
          noTitle
          style={{ maxWidth: 600 }}
          className="mx-auto"
        >
          <div style={{ marginTop: -30, marginBottom: -20 }}>
            <h2 className="text-center bold mb-4 text-uppercase">
              Authorize <span className="text-info">{appName}</span>
            </h2>
            <p>
              <strong>{appName}</strong> is requesting permission to access your
              account. By authorizing this connection, you allow{" "}
              <strong>{appName}</strong> to access the following information:
            </p>
            <ul>
              <li>View your profile information</li>
              <li>Access your game stats and achievements</li>
              <li>Manage your in-game inventory and resources</li>
              <li>Post updates on your behalf</li>
            </ul>
            <p>
              <strong>Note:</strong> Authorizing this app does not give it
              permission to perform any actions without your consent. You can
              revoke this access at any time through your account settings.
            </p>
            <p>
              Do you want to authorize{" "}
              <a
                className="bold"
                target="_blank"
                rel="noreferrer"
                href={appUrl!}
              >
                {appUrl}
              </a>{" "}
              to connect with your account?
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="primary"
                onClick={() => handleAuthorize()}
                className="me-2 bg-100"
              >
                Authorize
              </Button>
              <Button
                variant="secondary"
                className="bg-100"
                onClick={handleDeny}
              >
                Deny
              </Button>
            </div>
          </div>
        </MidBox>
      </div>
    </div>
  );
};

export default Authorize;
