import axios from "axios";
import { myStore } from "../redux/store";
import { EnvConfig } from "../constants/EnvConfig";
export const BetaUrl = "https://dev.chainoflegends.com";
export const CoreUrl = "https://core.chainoflegends.com";
// export const NestURL = ;
export const FTPURL = "https://core.chainoflegends.com";
// export const FTPURL = 'https://dev.chainoflegends.com';
export const BattleURL = EnvConfig.HUB_URL

export const IsBeta = process.env.REACT_APP_IS_BETA === "true" ? true : false;
// export const NestURL = 'localhost:';
export const NestURL = "https://api.chainoflegends.com";
export const IsNextVersion = false;
export const ProfileURL = `https://${
  IsBeta ? "dev" : "core"
}.chainoflegends.com/Admin/files/Photos/Players`;
export const ISV = false;
export const BaseURL = IsBeta ? BetaUrl : CoreUrl;
export const ApiUrl = localStorage.getItem("rootURL") || BaseURL + "/api/v1";
export const ChainID = "0x38";
export const ContractAddress = "0x4027d91eCD3140e53AE743d657549adfeEbB27AB";
export const NFTContractAddress = "0xdF710790ca64B4074366D9ce8d29d4D435a251f4";

interface ApiCall {
  method?: string;
  url?: string;
  body?: object;
  content?: string;
  token?: string;
  customHost?: string;
  secApi?: boolean;
  responseType?: string;
  onUploadProgress?: Function;
  header?: object;
  notify?: boolean;
}

export default class Network {
  public token = null;
  constructor() {
    this.token = myStore.getState().userToken;
  }

  async LogOut() {
    // await axios({ url: ApiURL + url, data: body, method:'delete', headers: {Authorization: this.token.access_token} })
    myStore.dispatch({ type: "REMOVE_USER_TOKEN" });
    myStore.dispatch({ type: "REMOVE_USER_INFO" });
    myStore.dispatch({ type: "CLEAR_MESSAGES" });
    // myStore.dispatch({ type: 'CLEAR_CHANNELS' })
    localStorage.removeItem("user.token");
    if (window.location.pathname !== "/login") {
      document.location.href = `login?return=${window.location.pathname}`;
    }
  }

  async CallApi<T>({
    url,
    body,
    secApi,
    customHost,
    token,
    method = "post",
    content = "application/json",
    responseType,
    onUploadProgress,
    header = {
      Authorization: myStore.getState().userToken
        ? `Bearer ${myStore.getState().userToken}`
        : undefined,
    },
    notify,
  }: ApiCall): Promise<{
    ok: boolean;
    data: T;
    message?: string;
    status?: number;
  }> {
    let myHeader: any = header;
    myHeader["Content-Type"] = content || "application/json";
    myHeader["x-language"] = "en";
    myHeader["Access-Control-Allow-Origin"] = "*";
    if (token) myHeader["Authorization"] = token;
    if (responseType) myHeader.responseType = responseType;
    try {
      const config: any = {
        url: customHost ? customHost + url : (secApi ? NestURL : ApiUrl) + url,
        data: body,
        method,
        headers: header,
        onUploadProgress,
        withCredentials: secApi ? false : true,
        notInterceptor: true,
      };
      const { data } = await axios(config);
      if (data.ok && notify) {
        // this.Notify(data);
      }
      if (typeof data === "string" || secApi) {
        return data as any;
      } else {
        return { ok: true, data: data as T };
      }
    } catch (e: any) {
      process.env.REACT_APP_NODE_ENV === "development" && console.log(e);
      if (e.response?.status === 401 && myHeader?.Authorization) this.LogOut();
      let messages =
        e.response && e.response.data && (e.response?.data?.message || null);
      if (e?.response?.status === 503 || e?.response?.status === 502) {
        messages = "Server is updating please try again a few moments later.";
        myStore.dispatch({
          type: "SET_ALERT_OPEN",
          payload: {
            open: true,
            msg: "Server is updating please try again a few moments later.",
            variant: "error",
          },
        });
      }

      const error = {
        ok: false,
        status: e?.response?.status,
        message: messages
          ? typeof messages === "object"
            ? messages[0]
            : messages
          : "Request Failed - " + (e?.response?.status || "Connection Error !"),
      };
      return error as any;
    }
  }
}
