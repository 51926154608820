import { effectsDefaultState } from "./reducer";

export const actionTypes = {
    SET_EFFECTS: 'SET_EFFECTS',
};

export const setEffects = (payload : typeof effectsDefaultState) => ({
    type: actionTypes.SET_EFFECTS,
    payload,
});

