import React from "react";

interface CustomIconProps {
    icon: string;
    type?: "svg" | 'png'
}

export const CustomIcon = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & CustomIconProps) =>
    <img width={35} height={35} src={`/assets/images/icons/${props.icon}.${props.type||'png'}`} {...props} />;


export const ClegIcon = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) =>
    <img alt="CLEG" width={35} height={35} src={'/assets/images/icons/cleg.png'} {...props} />;

export const StoneIcon = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) =>
    <img alt="STONE" width={35} height={35} src={'/assets/images/icons/stone.png'} {...props} />;

export const IronIcon = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) =>
    <img alt="IRON" width={35} height={35} src={'/assets/images/icons/metal.png'} {...props} />;

export const CheckIcon = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) =>
    <img alt="Check" width={35} height={35} src={'/assets/images/icons/check.png'} {...props} />;