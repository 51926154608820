import React, { FC, useEffect, useState } from "react";
import Dialog from "../Dialog";
import { roundFloat } from "../../utils/round-float";
import Network from "../../utils/Network";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import useToast from "../../hooks/useToast";

type Props = {
  open: boolean;
  onClose: () => void;
};
type Config = {
  max: number;
  min: number;
  packages: number[];
};

const UsdToClegDialog: FC<Props> = ({ onClose, open }) => {
  const [amount, setAmount] = useState<string | number | undefined>(undefined);
  const [config, setConfig] = useState<Config>();
  const [price, setPrice] = useState<number>();
  const openToast = useToast();
  const axios = new Network();

  const userInfo = useSelector((state: any) => state.userInfo);

  const handleGetPrice = async () => {
    const response = await axios.CallApi({
      url: "/token/price",
      method: "get",
    });
    setPrice(response.data);
  };

  const handleCreate = async () => {
    if (!amount || +amount < config?.min!) {
      return openToast({
        ok: false,
        message: `Minimum amount is ${config?.min} USD`,
      });
    }
    if (+amount > config?.max!) {
      return openToast({
        ok: false,
        message: `Maximum amount is ${config?.max} USD`,
      });
    }

    const res = await axios.CallApi({
      url: `/transak/payment/create/test?playerid=${userInfo.id}&amount=${amount}`,
      method: "get",
    });

    window.location.href = res;
  };

  const handleGetPackages = async () => {
    const response = await axios.CallApi({
      url: "/transak/usd-packages",
      method: "get",
    });
    setConfig(response.data);
  };

  useEffect(() => {
    handleGetPackages();
    handleGetPrice();
  }, []);

  return (
    <Dialog
      onSubmit={() => handleCreate()}
      title="Buy Cleg"
      submitLabel="Buy"
      open={open}
      onClose={onClose}
    >
      <div style={{ marginBottom: -40 }} className="d-flex flex-column">
        <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-2 mb-2">
          <div>
            <div className="fs-small">Amount(USD)</div>
            <input
              onKeyPress={(event) => {
                if (!/[0-9\.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={amount}
              onChange={(e) => {
                try {
                  const value = e.target.value;
                  if (value?.split(".")?.length > 2) {
                    return;
                  }
                  setAmount(roundFloat(value, 1));
                } catch (e) {
                  return;
                }
              }}
              type="number"
              className="dark-yellow  fw-med fs-med transparent-input"
              min={config?.min}
              max={config?.max}
              step="1"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <a
              //   onClick={() => setAmount('')}
              className="fs-med fw-normal text-secondary"
            >
              USD
            </a>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2">
          {config?.packages.map((p) => (
            <div
              onClick={() => setAmount(p)}
              className="bg-2 fs-small pointer rounded p-1 px-2 "
            >
              {p}
            </div>
          ))}
        </div>

        <div className="bg-2 p-2 mt-2 flex-column d-flex gap-3 fs-med rounded">
          <div className="d-flex align-items-center">
            <span>Amount</span>
            <div className="flex-1 dashed-line mx-2" />
            <span>
              {amount && (
                <CountUp
                  duration={1}
                  decimals={1}
                  separator=","
                  end={+amount}
                />
              )}{" "}
              USD
            </span>
          </div>
          <div className="d-flex align-items-center">
            <span>Receive</span>
            <div className="flex-1 dashed-line mx-2" />
            <span>
              {amount && price && (
                <CountUp
                  duration={1}
                  decimals={2}
                  separator=","
                  end={+amount / price}
                />
              )}{" "}
              Cleg
            </span>
          </div>
          <div className="d-flex align-items-center">
            <span>
              Cleg Price <small>(In USD)</small>
            </span>
            <div className="flex-1 dashed-line mx-2" />
            <span>
              {price && (
                <CountUp duration={1} decimals={6} separator="," end={price} />
              )}{" "}
              USD
            </span>
          </div>
        </div>
        <div style={{marginBottom:-10}} className="fs-small mt-3 alert alert-warning p-2">
          <b>Notice:</b>
          <br />
          * The amount of received $CLEG might be a little less than the preview
          amount because of exchange fees!
          <br />
          * 5% Bonus $CLEG is considered for you for each transaction.
        </div>
      </div>
    </Dialog>
  );
};

export default UsdToClegDialog;
