import React, { FC, useMemo, useState } from "react";
import MidBox from "../../../components/Boxes/MidBox";
import { motion } from "framer-motion";
import Button from "../../../components/Button";
import Countdown from "react-countdown";
import classNames from "classnames";
import { useConnector } from "../../connector/context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBattleContext } from "../../context";
import displayUserName from "../../../utils/display-username";
type Props = {
  timeout: boolean | number;
  isJoinMatch: boolean;
};
const timeOut = 30000;
const MatchSuggestion: FC<Props> = ({ timeout, isJoinMatch }) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const { suggestedMatch } = useBattleContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.userInfo);
  const id = searchParams.get("id");
  const PlayerAvatar = ({ name, avatar }) => {
    return (
      <div className="text-center d-flex align-items-center justify-content-center flex-column">
        <img
          width={48}
          src={
            avatar ||
          "/assets/images/avatar-bg.png"
          }
          alt="Avatar"
          className="rounded-circle"
        />
        <span>{displayUserName(name)}</span>
      </div>
    );
  };
  const timeLeft = useMemo(() => {
    return new Date(Date.now() + timeOut);
  }, []);
  const { connector } = useConnector();

  const acceptMatch = () => {
    if (!connector) return;
    setIsAccepted(true);
    if (isJoinMatch && id) {
      connector.joinMatch(id, user.username, user.avatar);
    } else {
      connector.ready();
    }
  };
  const rejectMatch = () => {
    if (!connector) return;
    setIsAccepted(false);
    navigate("/arena");
    connector.notReady();
  };
  const players = suggestedMatch?.players;
  return (
    <motion.div
      initial={{ maxWidth: 0, opacity: 0 }}
      animate={{ opacity: 1, maxWidth: 450 }}
      className="mx-auto overflow-hidden"
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      <MidBox
        noMargin
        style={{ maxWidth: 450, opacity: 0.95 }}
        title="Match Found"
        className="mx-auto "
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="d-flex gap-2 flex-column align-items-center justify-content-center "
        >
          <div className="dark-yellow">Are you ready to join the match?</div>
          <div className="bg-3 p-3 pb-2 rounded-3 gap-2 d-flex align-items-center justify-content-center">
            <PlayerAvatar
              name={players?.[0].username}
              avatar={
                players?.[0]?.avatar ||
                 "/assets/images/avatar-bg.png"
              }
            />
            <img
              src="/assets/images/icons/blades.png"
              width={60}
              className="mb-3"
            />
            {players && players[1] && (
              <PlayerAvatar
                name={players[1].username}
                avatar={
                  players[1].avatar ||
                   "/assets/images/avatar-bg.png"
                }
              />
            )}
          </div>
          <hr className="border-top border-1 divider w-100 my-2"></hr>
          <div className="dark-yellow fs-lg bold">Battle Mode</div>
          <div className="d-flex gap-3 mt-3 align-items-end justify-content-center">
            <div
              style={{ maxWidth: 80, height: "fit-content" }}
              className="item-frame active-slot  my-0 py-2 flex-column "
            >
              <img
                width={30}
                className="circle mt-1"
                src="/assets/images/arena/icons/energy-icon.png"
              />
              <span className="fs-md dark-yellow text-center mb-2">
              {suggestedMatch?.cost.energyCost!>0?"-":""}  {suggestedMatch?.cost?.energyCost}
              </span>
            </div>
            <div className="item-frame my-0 flex-column ">
              <img
                width={40}
                style={{ marginTop: -33 }}
                className="circle scaleX mb-2"
                src={suggestedMatch?.battleMode.icon}
              />
              <span className="fs-md dark-yellow">{suggestedMatch?.battleMode.title}</span>
              <br />
              <span className="text-active fs-lg bold text-shadow">{suggestedMatch?.battleMode.armyValueTitle}</span>
            </div>
            <div
              style={{ maxWidth: 80, height: "fit-content" }}
              className="item-frame active-slot  my-0 py-2 flex-column "
            >
              <img
                width={30}
                className="circle mt-1"
                src="/assets/images/arena/icons/gem-icon.png"
              />
              <span className="fs-md dark-yellow text-center mb-2">
                {suggestedMatch?.rewards.bloodGemReward!>0?"+":""} {suggestedMatch?.rewards.bloodGemReward}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Button variant="default" className="bg-100" onClick={rejectMatch}>
              Reject
            </Button>
            <Button
              disabled={isAccepted}
              onClick={acceptMatch}
              variant="primary"
              className="bg-100"
            >
              {isAccepted ? "Accepted" : "Accept"}
              {timeout && !isAccepted && (
                <>
                  &nbsp;
                  <Countdown
                    date={timeLeft}
                    renderer={({ seconds, completed }) => {
                      if (completed) {
                        return <span className="">0</span>;
                      }
                      return (
                        <span
                          className={classNames("text-active", {
                            "text-danger": seconds < 10,
                          })}
                        >
                          {seconds}s
                        </span>
                      );
                    }}
                  />
                </>
              )}
            </Button>
          </div>
        </motion.div>
      </MidBox>
    </motion.div>
  );
};

export default MatchSuggestion;
