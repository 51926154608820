import React, { useEffect, useState } from "react";
import MidBox from "../components/Boxes/MidBox";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import TipList from "../components/TipList";
import { motion } from "framer-motion";
import Network from "../utils/Network";
import { roundFloat } from "../utils/round-float";
import useToast from "../hooks/useToast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useWindowSize from "../hooks/useWindowSize";
import { isMobile } from "react-device-detect";
import { CustomIcon } from "../components/Icons";
import Share from "../components/Share";
import { Helmet } from "react-helmet";

type Props = {};
function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const Referrals = (props: Props) => {
  const [referralDialog, setReferralDialog] = useState(false);
  const [referralName, setReferralName] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [shareDialog, setShareDialog] = useState({
    open: false,
    link: "",
  });
  const windowSize = useWindowSize();
  const openToast = useToast();
  const axios = new Network();
  const getData = async () => {
    const response = await axios.CallApi({
      url: "/referral",
      method: "get",
    });
    if (response.ok) {
      setData(
        response.data?.map((item) => ({
          ...item,
          total: roundFloat(
            parseFloat(item?.totalDepositRevenue) +
            parseFloat(item?.totalTradingRevenue) +
            parseFloat(item?.totalMiningRevenue) +
            parseFloat(item?.referralBuildingUpgrade) +
            parseFloat(item?.referralTroopUpgrade)

          ),
        }))
      );
    }
  };
  const createReferral = async () => {
    setLoading(true);
    const response = await axios.CallApi({
      url: "/referral/create",
      method: "post",
      body: {
        name: referralName,
      },
    });
    openToast(response);
    if (response.ok) {
      getData();
    }
  };
  useEffect(() => {
    getData();
  }, []);
  function getTotal() {
    let total = 0;
    data?.map((item: any) => {
      total += parseFloat(item?.revenue30Days);
    });
    return total;
  }
  function getAllTotals() {
    let total = 0;
    data?.map((item: any) => {
      total += parseFloat(item?.total);
    });
    return total;
  }
  const referralLink =
    data.length !== 0
      ? "https://play.chainoflegends.com/login?ref=" + data[0]?.code
      : "";
  return (
    <div>
      <section className="banner">
        <Helmet>
          <title key={'title'}>
            Referrals | Chain Of Legends
          </title>
        </Helmet>
        <motion.div
          initial={isMobile ? undefined : { opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.4,
            ease: "backInOut",
          }}
        >
          {/* {[1, 2, 3, 4, 5, 2, 123, 12, 34, 134, 2, 23, 21, 23, 5, 6, 6, 8, 21, 32, 3, 23, 1, 3, 21, 4, 12, 3, 2, 1, 2, 4, 5, 6].map((item, index) => {
                        const blur = randomInteger(0, 1)
                        return (
                            <div key={index + "cl"}>
                                <img
                                    alt={`clouds`}
                                    style={{
                                        width: randomInteger(150, 670) + "px",
                                        filter: 'blur(' + (blur) + "px)",
                                        animation: `clouds ${randomInteger(24, 35)}s linear infinite`,
                                        top: Math.random() * 200,
                                        position: 'absolute',
                                        left: (Math.random() * 190 * index) - 1
                                    }}
                                    src={`/assets/images/backgrounds/cloud${randomInteger(1, 3)}.png`} />
                            </div>)
                    })} */}
        </motion.div>
        <div
          style={{ zIndex: 50, position: "relative" }}
          className="container "
        >
          <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 pb-3">
            <div className="row align-items-center">
              <motion.div
                initial={isMobile ? undefined : { x: -600, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -600, opacity: 0 }}
                transition={{
                  duration: 0.4,
                  ease: "backInOut",
                }}
                className="col-12 col-md-5 "
              >
                <h2 className="h6 dark-yellow text-shadow fw-bold lh-lg text-center text-md-start pt-5 pb-3 px-3">
                  “ Invite your friends with your specific referral link to earn
                  passive income.”{" "}
                </h2>
              </motion.div>
              <motion.div
                initial={isMobile ? undefined : { x: 600, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 600, opacity: 0 }}
                transition={{
                  duration: 1,
                }}
                className="col-12 text-center col-md-7 text-center "
              >
                <img
                  width={"100%"}
                  style={{ marginTop: -100 }}
                  className="img-fluid"
                  src="/assets/images/dragon-flying.webp"
                  alt="dragon"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <section className="data-section p-relative mb-5 mb-md-3">
        <motion.div
          initial={isMobile ? undefined : { y: 600, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 600, opacity: 0 }}
          transition={{
            duration: 0.4,
            delay: 0.2,
            ease: "backInOut",
          }}
          className="container"
        >
          <MidBox modal title="My Referrals">
            {data?.map((item: any, key) => (
              <>
                <div className="pe-1 ps-1 pe-sm-3 ps-sm-3 pe-lg-3 ps-lg-3 d-none">
                  <label
                    htmlFor="basic-url"
                    className="form-label dark-brown fs-small fw-bold mb-1"
                  >
                    {item?.name || `Referral Link #${key + 1}`}
                  </label>
                  <div className="input-group align-items-center mb-3">
                    <input
                      type="text"
                      className="form-control form-input dark-yellow"
                      name="txtLink"
                      id="txtLink"
                      readOnly
                      placeholder="my link"
                      value=""
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                    />
                    <button className="form-btn blue-btn" type="button">
                      <i className="b-icon hlink"></i>
                      <span className="b-text px-1">Copy Link</span>
                    </button>
                  </div>
                </div>
                <div className="ref-step1">
                  <div className="pe-1 ps-1 pe-sm-3 ps-sm-3 pe-lg-3 ps-lg-3">
                    <label
                      htmlFor="basic-url"
                      className="form-label dark-brown fs-small fw-bold mb-1"
                    >
                      {item?.name || `Referral Link #${key + 1}`}
                    </label>
                    <div className="input-group align-items-center mb-3">
                      <input
                        type="text"
                        className="form-control me-1 rounded form-input dark-yellow"
                        id="basic-url"
                        readOnly
                        value={`https://chainoflegends.com/?ref=${item?.code}`}
                        aria-describedby="button-addon2"
                      />
                      <CopyToClipboard
                        text={`https://chainoflegends.com/?ref=${item?.code}`}
                        onCopy={() =>
                          openToast({
                            ok: true,
                            data: {
                              message: "Referral link copied to clipboard",
                            },
                          })
                        }
                      >
                        <Button
                          size={isMobile ? "small" : "medium"}
                          className="bg-100"
                          style={{ position: "relative", zIndex: 10 }}
                          variant="default"
                        >
                          <CustomIcon
                            width="17px"
                            height="17px"
                            icon="copy"
                            type="svg"
                            className="me-1"
                          />
                          Copy
                        </Button>
                      </CopyToClipboard>
                      <Button
                        size={isMobile ? "small" : "medium"}
                        onClick={() => {
                          setShareDialog({
                            open: true,
                            link: `https://chainoflegends.com/?ref=${item?.code}`,
                          });
                        }}
                        className="bg-100"
                        style={{ position: "relative", zIndex: 10 }}
                        variant="primary"
                      >
                        <CustomIcon
                          width="15px"
                          height="15px"
                          icon="share"
                          type="svg"
                          className="me-1"
                        />
                        Share
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ))}

            <div className="text-center text-md-start pe-1 ps-1 pe-sm-3 ps-sm-3">
              <Button onClick={() => setReferralDialog(true)} variant="wide">
                + Make New Invite Code
              </Button>
            </div>
          </MidBox>
        </motion.div>
      </section>
      <section className="data-section mb-5">
        <motion.div
          initial={isMobile ? undefined : { y: 600, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 600, opacity: 0 }}
          transition={{
            duration: 0.4,
            delay: 0.3,
            ease: "backInOut",
          }}
          className="container"
        >
          <MidBox modal title="Referral Status">
            <div className="pe-1 ps-1 pe-sm-3 ps-sm-3 mb-4 twentyone-step">
              <div className="row align-items-center">
                <div
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                  className="col-12 col-lg-6 mb-2 aos-init"
                >
                  <div className="info-box dark-yellow">
                    <div className="row align-items-center">
                      <div className="col-12 col-sm-5 fs-med mb-2 mb-md-0">
                        Revenue from Referrals :
                      </div>
                      <div className="col-7 col-sm-4 info-label fw-bold d-flex align-items-center justify-content-center">
                        <span>{roundFloat(getTotal())} CLEG</span>
                      </div>
                      <div className="col-5 col-sm-3 text-end fs-med fw-bold">
                        (30 Days)
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                  className="col-12 ref-step2 col-lg-6 mb-2 aos-init"
                >
                  <div className="info-box dark-yellow">
                    <div className="row align-items-center">
                      <div className="col-12 col-sm-5 fs-med mb-2 mb-md-0">
                        Revenue from Referrals :
                      </div>
                      <div className="col-7 col-sm-4 info-label fw-bold d-flex align-items-center justify-content-center">
                        {roundFloat(getAllTotals())} CLEG
                      </div>
                      <div className="col-5 col-sm-3 text-end fs-med fw-bold">
                        (All time)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pe-1 ps-1 pe-sm-3 ps-sm-3 pb-4 table-box">
              <table className="table ref-step3  table-borderless m-0">
                {" "}
                <thead>
                  <tr>
                    <th scope="col">Invite Code</th>
                    <th scope="col">Invites</th>
                    <th scope="col">Deposit Revenue</th>
                    <th scope="col">Trading Revenue</th>
                    <th scope="col">Mining Revenue</th>
                    <th scope="col">Building Upgrade</th>
                    <th scope="col">Troop Upgrade</th>
                    <th scope="col">Revenue (30Days)</th>
                    <th scope="col">Totals</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item: any, key) => (
                    <tr key={"row" + key}>
                      <td>
                        <a
                          target={"_blank"}
                          href={`https://chainoflegends.com/?ref=${item?.code}`}
                        >
                          {item?.name}
                        </a>
                      </td>
                      <td>{item?.inviteCount}</td>
                      <td>{roundFloat(item?.totalDepositRevenue)} CLEG</td>
                      <td>{roundFloat(item?.totalTradingRevenue)} CLEG</td>
                      <td>{roundFloat(item?.totalMiningRevenue)} CLEG</td>
                      <td>{roundFloat(item?.referralBuildingUpgrade)} CLEG</td>
                      <td>{roundFloat(item?.referralTroopUpgrade)} CLEG</td>
                      <td>{roundFloat(item?.revenue30Days)} CLEG</td>
                      <td>{roundFloat(item?.total)} CLEG</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </MidBox>
        </motion.div>
      </section>

      <section className="data-section mb-5">
        <div className="container">
          <motion.h5
            initial={isMobile ? undefined : { x: -600, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -600, opacity: 0 }}
            transition={{
              duration: 0.5,
              delay: 0.3,
              ease: "backInOut",
            }}
            className="h6 dark-yellow fw-bold lh-lg text-center text-md-start pt-5 pb-3 px-3"
          >
            ✅ Inviting friends to play along with you has the following permanent privileges for you:
          </motion.h5>

          <TipList
            delay={0.6}
            icon="/assets/images/icons/icon-dart.png"
            item={[
              "5% Deposit perks as a reward for each friend's deposit.",
              "5% perks as a reward for encouraging your friend to Upgrade or Instant Upgrade each of his (her) Buildings.",
              "5% perks as a reward for encouraging your friend to Upgrade or Instant Upgrade each of his (her) Heroes.",
              "1.5% Trading perks as a reward for each friend's marketplace Trade.",
            ]}
          />
          <TipList
            delay={0.9}
            item={[
              "All perks get paid from the reward pool and do not affect your friends' income.",
            ]}
          />
        </div>
      </section>
      <Dialog
        title="Generate Referral Link"
        open={referralDialog}
        loading={loading}
        submitLabel={"Create"}
        onSubmit={async () => {
          await createReferral();
          setReferralDialog(false);
        }}
        closeLabel="Cancel"
        onClose={() => setReferralDialog(false)}
      >
        <div>
          <div className="d-flex align-items-center">
            Title : &nbsp;
            <input
              value={referralName}
              onChange={(e) => setReferralName(e.target.value)}
              type={"text"}
              style={{ flex: 1 }}
              placeholder="Enter You Referral Link Title"
              className="w-100 form-control"
            />
          </div>
        </div>
      </Dialog>

      <Share
        open={shareDialog.open}
        shareNote={`
            👋 Hey fellows!!

I've been busy playing #ChainOfLegends recently, and I've enjoyed it a lot!
Battles, Strategies, and Earning #CLEG Income while having fun... It's kinda amazing! The whole package at the same time 😍

If you are interested to join me and become a #Guild, Click on the link below 👇

`}
        // description='Share This link with your friends and '
        onClose={() => setShareDialog((prev) => ({ ...prev, open: false }))}
        link={shareDialog.link}
      />
    </div>
  );
};

export default Referrals;
