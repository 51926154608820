export enum Actions {
    ADD_SLOTS = "ADD_SLOTS",
    SET_MODELS = "SET_MODELS",
    SELECT_SLOT = "SELECT_SLOT",
    MOVE_MODEL = "MOVE_MODEL",
    UPDATE_SLOT = "UPDATE_SLOT",
    UPDATE_MODEL = "UPDATE_MODEL",
    DELETE_MODEL = "DELETE_MODEL",
    ADD_MODEL = "ADD_MODEL",
    UPDATE_MODELS = "UPDATE_MODELS",
    DELETE_MODELS = "DELETE_MODELS",
    SET_MODEL = "SET_MODEL"
}


export enum ViewState {
    Home,
    FightMenu,
    LiveBattle,
    TroopSelection,
    WatchLiveBattle,
    WatchBattleReplay,
    ReadyModal
}

export enum SignalREvent {
    ReceiveMessage = "ReceiveMessage",
    BattleUpdate = "BattleUpdate",
    ReadyCheck = "ReadyCheck",
    StartFormation = "StartFormation",
    StartBattle = "StartBattle",
    Pong = "Pong",
    BattleList = "BattleList",
    NewBattle = "NewBattle",
    EndBattle = "EndBattle",
    SignIn = "SignIn",
    Close = "close",
    HostCreated = "HostCreated",
    Reconnecting = "reconnecting",
    Reconnected = "reconnected"
}
export enum BattleUpdateType {
    Message = 0,
    Info = 1,
    Warning = 2,
    Error = 3,
    Chat = 4,
    TroopAction = 5,
    EndBattle = 6,
    FindMatch = 7,
    FindMatchCanceled = 8,
    ReadyCheckCanceled = 9,
    FormationCanceled = 10,
    AFKTimeout = 11,
    BattleTimeout = 12,
}
export enum ArenaBattleStateEnum {
    Free = 1, // Default State: Player does not pressed find match button yet
    FindingMatch = 2, // Player pressed Find Match button but not found any match yet
    HostingMatch = 3, // Player is hosting a match but no one joined yet
    ReadyCheck = 4, // Ready Check State
    ReadyCheckSubmitted = 5, // Player submitted Ready Check but other player(s) not submitted yet
    Formation = 6, // Formation Page State
    FormationSubmitted = 7, // Player submitted Formation but other player(s) not submitted yet
    InBattle = 8 // Player is in live battle
}
export enum ConnectionStatus {
    Disconnected = "Disconnected",
    Connecting = "Connecting",
    Connected = "Connected"
}

export enum TroopActions {
    Enter = 1,
    Move = 2,
    Attack = 3,
    Heal = 4,
}

export enum MessagesVariant {
    Success = "success",
    Error = "error",
    Normal = "normal",
}
export enum TroopAttribute {
    Health = 0,
    Armor = 1,
    Healing = 3,
    MagicResistance = 4,
    Damage = 5,
    Range = 6,
    Buffs = 7
}

export enum SignalRAction {
    SendMessage = "SendMessage",
    FindMatch = "FindMatch",
    HostMatch = "HostMatch",
    JoinMatch = "JoinMatch",
    SubmitFormation = "SubmitFormation",
    SubmitMyArmy = "SubmitMyArmy",
    Ready = "Ready",
    NotReady = "NotReady",
    SubscribeToBattleList = "SubscribeToBattleList",
    UnsubscribeFromBattleList = "UnsubscribeFromBattleList",
    WatchBattle = "WatchBattle",
    Surrender = "Surrender",
    Ping = "Ping",
    Action = "Action"
}


export enum MoveTroopType {
    BARRACKS = "barracks",
    CAMP = "arena"
}

export enum AdventureMode {
    Arena100k = 5,
    Arena300k = 6,
    Arena900k = 7,
    Arena2700k = 8,
    ArenaFree100k = 9,
    ArenaFree300k = 10,
    ArenaFree900k = 11,
    ArenaFree2700k = 12
}