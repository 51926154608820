
import * as signalR from "@microsoft/signalr";
import { BattleModeInfo, BattleRules, commandAction, EventHandlers, FormationCampArmy } from "../types";
import { AdventureMode, ConnectionStatus, SignalRAction, SignalREvent } from "../types/enum";
import { Dispatch, SetStateAction } from "react";

var lastPing = new Date();

class Connector {
    public connection: signalR.HubConnection;
    private eventHandlers: EventHandlers = {};
    static instance: Connector;

    constructor(token: string, hubUrl: string, eventHandlers: EventHandlers = {}) {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl, { accessTokenFactory: () => token })
            .withAutomaticReconnect([1, 2, 3, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5])
            .build();
        this.setEventHandlers(eventHandlers);
    }

    public setEventHandlers(eventHandlers: EventHandlers) {
        this.clearEventHandlers();
        this.eventHandlers = eventHandlers;
        this.setupEvents();
    }

    public clearEventHandlers() {
        Object.values(SignalREvent).forEach(event => this.connection.off(event));
    }

    private setupEvents() {
        this.connection.on(SignalREvent.ReceiveMessage, (username, message) => {
            this.eventHandlers.onMessageReceived?.(username, message);
        });
        this.connection.on(SignalREvent.BattleUpdate, (message, updateType, action) => {
            this.eventHandlers.onBattleUpdate?.(message, updateType, action);
        });
        this.connection.on(SignalREvent.ReadyCheck, (data) => {
            // console.log("ReadyCheck", data);
            this.eventHandlers.onReadyCheck?.(data);
        });
        this.connection.on(SignalREvent.HostCreated, ({ battleId }: { battleId: string }) => {
            // console.log("HostCreated", battleId);
            this.eventHandlers.onHostCreated?.(battleId);
        });
        this.connection.on(SignalREvent.StartFormation, (battleId, troops: FormationCampArmy[], details: { battleId: string, rules: BattleRules, battleModeInfo: BattleModeInfo }) => {
            this.eventHandlers.onStartFormation?.(battleId, troops, details.rules, details.battleModeInfo);
        });
        this.connection.on(SignalREvent.StartBattle, (battleId, attackers, defenders, hostPlayer, opponentPlayer, battleRules,startTime,isWatcher) => {
            this.eventHandlers.onStartBattle?.(battleId, attackers, defenders, hostPlayer, opponentPlayer, battleRules,startTime,isWatcher);
        });
        this.connection.on(SignalREvent.Pong, () => {
            const pingValue = new Date().getTime() - lastPing.getTime();
            this.eventHandlers.onPong?.(pingValue);
        });
        this.connection.on(SignalREvent.BattleList, (liveBattles) => {
            this.eventHandlers.onBattleList?.(liveBattles);
        });
        this.connection.on(SignalREvent.NewBattle, (battleId, attackerNames, defenderNames,attackers,defenders) => {
            this.eventHandlers.onNewBattle?.(battleId, attackerNames, defenderNames,attackers,defenders);
        });
        this.connection.on(SignalREvent.EndBattle, (battleId, winnerPlayerIds, battleResult,attackers,defenders) => {
            this.eventHandlers.onEndBattle?.(battleId, winnerPlayerIds, battleResult,attackers,defenders);
        });
        this.connection.on(SignalREvent.SignIn, (gameInfo) => {
            this.eventHandlers.onSignIn?.(gameInfo);
        });
        this.connection.onclose(() => {
            this.eventHandlers.onStatusChange?.('Disconnected');
        });

        this.connection.onreconnecting(() => {
            this.eventHandlers.onStatusChange?.('Reconnecting');
        });

        this.connection.onreconnected(() => {
            this.eventHandlers.onStatusChange?.('Connected');
        });
    }

    public start = (setConnectionStatus: Dispatch<SetStateAction<ConnectionStatus>>) => {
        this.connection.start()
            .then(() => { setConnectionStatus(ConnectionStatus.Connected); })
            .catch(err => setConnectionStatus(ConnectionStatus.Disconnected));
    }

    public sendMessage = (username: string, messages: string) => {
        this.send(SignalRAction.SendMessage, username, messages);
    }
    public joinMatch = (id: string, username: string, avatar: string) => {
        this.send(SignalRAction.JoinMatch, id, username, avatar);
    }
    public findMatch = (username: string, avatar: string | null) => {
        this.send(SignalRAction.FindMatch, username, avatar);
    }
    public hostMatch = (username: string, avatar: string) => {
        this.send(SignalRAction.HostMatch, false, username, avatar, AdventureMode.Arena100k);
    }
    public submitFormation = (username: string, selectedArmy: { id: number, level: number }[]) => {
        const armyArray: number[][] = selectedArmy.map(item => [item.id, item.level]);
        this.send(SignalRAction.SubmitFormation, username, armyArray);
    }

    public submitMyArmy = (username: string, selectedArmy: number[]) => {
        this.send(SignalRAction.SubmitMyArmy, username, selectedArmy);
    }

    public ready = () => {
        this.send(SignalRAction.Ready);
    }

    public notReady = () => {
        this.send(SignalRAction.NotReady);
    }

    public subscribeToBattleList = () => {
        this.send(SignalRAction.SubscribeToBattleList);
        // console.log("Subscribed to Battle List");
    }

    public unsubscribeFromBattleList = () => {
        this.send(SignalRAction.UnsubscribeFromBattleList);
        // console.log("Unsubscribed from Battle List");
    }

    public watchBattle = async (battleId: string) => {
        this.send(SignalRAction.WatchBattle, battleId);
    }

    public surrender = async (battleId: string) => {
        this.send(SignalRAction.Surrender, battleId);
    }

    public ping = async () => {
        lastPing = new Date();
        this.send(SignalRAction.Ping);
    }

    public action = async (battleId: string, actionDetails: commandAction) => {
        if (this.connection.state === signalR.HubConnectionState.Disconnected) {
            try {
                await this.connection.start();
                console.log("Reconnected successfully.");
            } catch (err) {
                console.error("Reconnection failed: ", err);
            }
        }
        this.send(SignalRAction.Action, battleId, actionDetails);
    }

    private send(action: SignalRAction, ...args: any[]) {
        // console.log(action, args)
        this.connection.send(action, ...args)
            .then(() => action !== SignalRAction.Ping)
            .catch(err => console.error(`${action} send error: `, err));
    }

    public static getInstance(token: string, hubUrl: string, eventHandlers?: EventHandlers): Connector {
        if (!Connector.instance) {
            Connector.instance = new Connector(token, hubUrl, eventHandlers);
        } else if (eventHandlers) {
            Connector.instance.setEventHandlers(eventHandlers);
        }
        return Connector.instance;
    }

    public updateToken = (token: string, hubUrl: string) => {
        this.connection.stop();
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl, { accessTokenFactory: () => token })
            .withAutomaticReconnect([1, 2, 3, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5])
            .build();
        this.connection.start();
    }

    public checkConnection = async () => {
        if (this.connection.state === signalR.HubConnectionState.Disconnected) {
            try {
                await this.connection.start();
                console.log("Reconnected successfully.");
            } catch (err) {
                console.error("Reconnection failed: ", err);
            }
        }
    }
}
export default Connector;
