import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import MidBox from "../../components/Boxes/MidBox";
import Button from "../../components/Button";
import Dialog from "../../components/Dialog";
import { roundFloat } from "../../utils/round-float";
import Network from "../../utils/Network";
import { motion } from "framer-motion";
import ReactPaginate from "react-paginate";
import useToast from "../../hooks/useToast";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/user/action";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
type Props = {};

const TroopOrderBook = (props: Props) => {
  const axios = new Network();
  const [paginations, setPaginations] = useState({ total: 1 });
  const [searchParams] = useSearchParams();
  const [btnLoading, setBtnLoading] = useState("");
  const [myOrders, setMyOrders] = useState([]);
  const [troops, setTroops] = useState([]);
  const [oldOrders, setOldOrders] = useState([]);
  const [troopOrders, setTroopOrders] = useState({});
  const [loading, setLoading] = useState(true);
  const openToast = useToast();
  const levelRange: any = parseInt(searchParams.get("level") || "1") || 1;
  const selectedTroop = parseInt(searchParams.get("id"));
  const sellOrderRef = useRef();

  const [confirmDialog, setConfirmDialog] = useState({
    id: "0",
    open: false,
    submit: null,
    title: "",
    msg: "",
  });
  const dispatch = useDispatch();
  const handleGetAll = async () => {
    getOldOrders();
    getMyOrders();
    getOrders();
  };
  useEffect(() => {
    handleGetAll();
  }, []);
  async function getMyOrders(page = 1) {
    const response = await axios.CallApi({
      url: "/market/my-orders?itemTypes=3&states=1,2&count=5&page=" + page,
      method: "get",
    });
    if (response.ok) {
      setMyOrders(response.data?.data);
      setPaginations({ total: Math.ceil(response.data?.totalCount / 5) });
    }
  }
  useEffect(() => {
    if (troopOrders?.sellOrders) {
      sellOrderRef.current.scrollTop = sellOrderRef.current.scrollHeight;
    }
  }, [troopOrders]);
  async function handleCancelOrder(id: number) {
    setBtnLoading(id?.toString());
    const response = await axios.CallApi({
      url: "/market/my-order/" + id,
      method: "delete",
    });
    openToast(response);
    if (response.ok) {
      handleGetAll();
    }
    setConfirmDialog((prev: any) => ({ ...prev, open: false }));
    setBtnLoading("");
  }
  async function getOldOrders(page = 1) {
    const response = await axios.CallApi({
      url: "/market/my-orders?itemTypes=3&states=3,5&count=5&page=" + page,
      method: "get",
    });
    if (response.ok) {
      setOldOrders(response.data?.data);
      setPaginations({ total: Math.ceil(response.data?.totalCount / 5) });
    }
  }
  async function handleGetTroops() {
    const response = await axios.CallApi({ url: "/troop", method: "get" });
    if (response.ok) {
      setTroops(response.data);
    }
  }
  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };
  async function handleBuyOrder(item: any) {
    setBtnLoading(item?.itemId);
    const response = await axios.CallApi({
      url: "/market/my-orders",
      body: {
        itemType: 3,
        itemId: item?.itemid,
        // "itemLevel": 0,
        clegPrice: item.clegPrice,
        quantity: item?.quantity,
        orderType: 1,
      },
    });

    openToast(response);
    setConfirmDialog((prev) => ({ ...prev, open: false }));
    if (response.ok) {
      getMe();
      getOrders();
      getMyOrders();
      getOldOrders();
    }
    setBtnLoading("");
  }
  const getRange = (range, level, id) => {
    const whitelist = [2, 5, 6, 7];
    if (whitelist.find((item) => item === id)) {
      if (level < 4) {
        return range;
      }
      if (level < 7) {
        return range + 1;
      }
      if (level < 10) {
        return range + 2;
      }
      if (level >= 10) {
        return range + 3;
      }
    } else {
      return range;
    }
  };
  useEffect(() => {
    handleGetTroops();
  }, []);

  async function getOrders() {
    setLoading(true);
    let calculatedLevelRange: number[] = [];

    for (let i = levelRange[0]; i < levelRange[1] + 1; i++) {
      calculatedLevelRange.push(i);
    }
    const data = await axios.CallApi({
      url: `/market/3?itemTypeId=${selectedTroop}&itemLevel=${levelRange}`,
      method: "get",
    });
    if (data.ok) {
      setTroopOrders({
        ...data?.data,
        Range: getRange(
          data?.data?.Range,
          data?.data?.level,
          data?.data?.troopid
        ),
      });
    }
    setLoading(false);
  }
  const orderTroop = troops?.find((troop: any) => troop.id === selectedTroop);

  return (
    <div className="container-lg">
      <div style={{ width: "fit-content" }}>
        {orderTroop && (
          <title key="title">
            {orderTroop?.name} Orderbook | Chain Of Legends
          </title>
        )}
        <Link to="/marketplace?type=3">
          <motion.div
            initial={isMobile ? undefined : { opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0 }}
            exit={{ opacity: 0 }}
            className="dark-yellow my-2 ps-3 fs-big "
          >
            <img
              style={{ transform: "rotate(180deg)", fill: "white" }}
              src="/assets/images/icons/arrow-right.svg"
            />
            &nbsp; Back
          </motion.div>
        </Link>
      </div>

      <div className="row">
        <motion.div
          initial={isMobile ? undefined : { y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          exit={{ y: 200, opacity: 0 }}
          className="col-12 col-md-12 col-lg-4"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="750"
        >
          <div className="row">
            <div className="col-12  col-md-12 mb-2 mb-md-3">
              <MidBox title="Troop" className="mt-1">
                <div className="px-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="dark-bg-light rounded position-relative flex-wrap pe-2 d-flex align-items-center px-4 py-3 mb-4 mb-lg-0">
                        <h3 className="fw-med fs-big m-0">
                          {orderTroop?.name}
                        </h3>
                        <div className=" fs-med fw-bold ms-auto">
                          Level
                          <div className="title-box s-micro fw-bold text-light ms-2">
                            <img
                              width={"35px"}
                              height={"36px"}
                              className="img-fluid"
                              src="/assets/images/buttons/active-btn.png"
                            />
                            <div className="b-text">
                              <span className="text-light d-block">
                                {levelRange}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" mt-2 text-center">
                        <div
                          className="p-2 mb-2 p-relative rounded"
                          style={{ backgroundColor: "#28251c" }}
                        >
                          <img
                            className="ms-auto"
                            style={{ maxHeight: 180 }}
                            src={orderTroop?.image}
                          />
                          <div className=" bg-success py-1 rounded text-center mt-2 text-shadow text-light mb-0">
                            <div className="me-2">
                              Health:{" "}
                              {roundFloat(
                                Math.pow(1.5, levelRange - 1) *
                                  orderTroop?.maxHealth
                            )}
                            </div>
                          </div>
                          {/* {orderTroop?.id === 7 && (
                            <div className="p-absolute mb-3 leverage">
                              1.5X
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="d-flex">
                        <button className="theme-btn btn-exsmall btn-tab-style selected-arrow">
                          <img
                            className="img-fluid"
                            src="/assets/images/buttons/off-tab-btn.png"
                          />
                          <div className="b-text">
                            <span className="text-light fw-med fs-big">
                              Info
                            </span>
                          </div>
                        </button>
                        <Link className="ms-auto" to="/barracks">
                          <Button variant="secondary">Sell</Button>
                        </Link>
                      </div>
                      <div className="dark-bg-light rounded-10 p-3 p-lg-4">
                        <div className="d-flex align-items-center text-shadow text-light mb-0">
                          <img
                            style={{ width: 25, height: 27 }}
                            className="me-1"
                            src="/assets/images/icons/damage.png"
                          />
                          <div className="me-2">Damage:</div>
                          <div className="">
                            {roundFloat(
                              Math.pow(1.5, levelRange - 1) * orderTroop?.damage
                            )}
                          </div>
                        </div>

                        <div className="d-flex mt-2 align-items-center text-shadow text-light mb-0">
                          <img
                            style={{ width: 25, height: 27 }}
                            className="me-1"
                            src="/assets/images/icons/armor.png"
                          />
                          <div className="me-2">Armor:</div>
                          <div className="">{orderTroop?.armor}</div>
                        </div>
                        <div className="d-flex mt-2 align-items-center text-shadow text-light mb-0">
                          <img
                            style={{ width: 25, height: 27 }}
                            className="me-1"
                            src="/assets/images/icons/range.png"
                          />
                          <div className="me-2">Range:</div>
                          <div className="">
                            {getRange(
                              orderTroop?.range,
                              +levelRange,
                              +selectedTroop
                            )}
                          </div>
                        </div>
                        <div className="d-flex mt-2 align-items-center text-shadow text-light mb-0">
                          <img
                            style={{ width: 25, height: 27 }}
                            className="me-1"
                            src="/assets/images/icons/team-magic-resist.png"
                          />
                          <div className="me-2">Magic Resistance:</div>
                          <div className="">{orderTroop?.magicResistance}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MidBox>
            </div>

            {/* <div className="col-12 col-md-6 mb-2">
                        <MidBox containerClass='py-lg-0 py-0' noTitle>
                            <div >
                                <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-3 mb-2">
                                    <div className="dark-yellow fw-med fs-med">Price : 1500</div>
                                    <div className="text-light fw-normal fs-small">CLEG</div>
                                </div>
                                <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-2 mb-4">
                                    <div className="dark-yellow fw-med fs-med my-2">QUANTITY : 1</div>
                                    <div className="d-flex flex-column justify-content-between">
                                        <button className="theme-btn s-nano lh-1">
                                            <img width={20} className="img-fluid" src="/assets/images/icons/arrow-up.png" />
                                        </button>
                                        <button className="theme-btn s-nano lh-1">
                                            <img width={20} className="img-fluid" src="/assets/images/icons/arrow-down.png" />
                                        </button>
                                    </div>
                                </div>

                                <button className="theme-btn button py-0 btn-large px-0">
                                    <img className="img-fluid" src="/assets/images/buttons/blue-btn-wide.png" />
                                    <div className="b-text">
                                        <span className="text-light  d-block">BUY</span>
                                    </div>
                                </button>
                            </div>
                        </MidBox>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <MidBox noTitle>
                            <div>
                                <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-3 mb-2">
                                    <div className="dark-yellow fw-med fs-med">Price : 1500</div>
                                    <div className="text-light fw-normal fs-small">CLEG</div>
                                </div>
                                <div className="d-flex justify-content-between dark-bg rounded-10 px-4 py-2 mb-4">
                                    <div className="dark-yellow fw-med fs-med my-2">QUANTITY : 1</div>
                                    <div className="d-flex flex-column justify-content-between">
                                        <button className="theme-btn s-nano lh-1">
                                            <img width={20} className="img-fluid" src="/assets/images/icons/arrow-up.png" />
                                        </button>
                                        <button className="theme-btn s-nano lh-1">
                                            <img width={20} className="img-fluid" src="/assets/images/icons/arrow-down.png" />
                                        </button>
                                    </div>
                                </div>
                                <button className="theme-btn button py-0 btn-large px-0">
                                    <img className="img-fluid" width={"100%"} src="/assets/images/buttons/brown-btn-wide.png" />
                                    <div className="b-text">
                                        <span className="text-light  d-block">Sell</span>
                                    </div>
                                </button>
                            </div>
                        </MidBox>

                    </div> */}

            <Dialog
              loading={btnLoading === confirmDialog.id}
              title={confirmDialog?.title}
              closeLabel="No"
              submitLabel="Yes"
              onSubmit={() => confirmDialog?.submit()}
              open={confirmDialog?.open}
              onClose={() =>
                setConfirmDialog((prev) => ({ ...prev, open: false }))
              }
            >
              {confirmDialog?.msg}
            </Dialog>
          </div>
        </motion.div>
        <motion.div
          initial={isMobile ? undefined : { y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          exit={{ y: 200, opacity: 0 }}
          className="col-12 col-lg-8"
        >
          <MidBox modal title="Order Book" containerClass="px-4 px-sm-5">
            <div className=" text-start">
              <label className="mb-3 fw-med fs-med text-light">
                SELL ORDERS
              </label>
              <div style={{ overflow: "auto" }} className="table-box">
                <PerfectScrollbar ref={sellOrderRef} style={{ maxHeight: 400 }}>
                  <table className="table table-borderless m-0">
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        backdropFilter: "blur(8px)",
                      }}
                    >
                      <tr>
                        <th className="text-light" scope="col">
                          Price
                        </th>
                        <th className="text-light" scope="col">
                          XP
                        </th>
                        <th className="text-light" scope="col">
                          Quantity
                        </th>

                        <th className="text-light" scope="col">
                          Total
                        </th>
                        <th className="text-light" scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {troopOrders?.sellOrders?.map((item) => (
                        <tr>
                          <td className="color-red">{item.clegPrice} CLEG</td>
                          <td>
                            {item.xp || 0} /{" "}
                            {roundFloat(Math.pow(1.5, levelRange - 1) * 100)}
                          </td>
                          <td align="center">{item.quantity}</td>

                          <td>
                            {roundFloat(item.clegPrice * item.quantity)} CLEG
                          </td>
                          <td align="center">
                            <Button
                              onClick={() => {
                                setConfirmDialog({
                                  open: true,
                                  msg:
                                    "Are you Sure about buying this " +
                                    orderTroop?.name +
                                    "?",
                                  id: item?.itemid,
                                  title: "Confirm",
                                  submit: () => handleBuyOrder(item),
                                });
                              }}
                              className="m-0"
                              style={{ minWidth: 70 }}
                              variant="primary"
                            >
                              Buy
                            </Button>
                          </td>
                        </tr>
                      ))}

                      {troopOrders?.sellOrders?.length === 0 && (
                        <tr>
                          <td colSpan={5}>
                            <div className="text-center dark-yellow py-1">
                              There is no order yet.
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </PerfectScrollbar>
              </div>

              <div className="text-center my-3">
                {/* <img className="img-fluid" src="/assets/images/brown-sep.png" /> */}
                {/* <div className="py-3"><span className="fw-med fs-med text-light">0.934825 ≈ 0.934918 USD</span></div> */}
                <img className="img-fluid" src="/assets/images/brown-sep.png" />
              </div>
              <label className="mb-3 fw-med fs-med text-light">
                BUY ORDERS
              </label>
              <div style={{ overflow: "auto" }} className="table-box">
                <table className="table table-borderless m-0">
                  <thead>
                    <tr>
                      <th className="text-light" scope="col">
                        Price
                      </th>
                      <th className="text-light" scope="col">
                        Quantity
                      </th>
                      <th className="text-light" scope="col">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {troopOrders?.buyOrders?.map((item) => (
                      <tr>
                        <td className="teal-color ">{item.clegPrice} CLEG</td>
                        <td>{item.quantity}</td>
                        <td>
                          {roundFloat(item.clegPrice * item.quantity)} CLEG
                        </td>
                      </tr>
                    ))}
                    {troopOrders?.buyOrders?.length === 0 && (
                      <tr>
                        <td colSpan={3}>
                          <div className="text-center dark-yellow py-1">
                            There is no order yet.
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </MidBox>

          <motion.div
            initial={isMobile ? undefined : { y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.1 }}
            exit={{ y: 200, opacity: 0 }}
            className="col-12"
          >
            <MidBox modal noTitle>
              <Tabs
                style={{ marginTop: -30 }}
                defaultActiveKey="my-orders"
                id="uncontrolled-tab-example"
                className=""
                onSelect={(val) =>
                  val === "my-orders" ? getMyOrders(1) : getOldOrders(1)
                }
                // onClick={(e,val)=>handleRefreshData()}
              >
                <Tab eventKey="my-orders" title="Active Orders">
                  <>
                    <div style={{ overflow: "auto" }} className="table-box">
                      <table className="table table-borderless m-0 text-white">
                        <thead style={{ borderRadius: 0 }}>
                          <tr>
                            <th
                              style={{
                                borderRadius: "0 0  0 5px",
                                background: "rgba(0,0,0,.3)",
                              }}
                              scope="col"
                            >
                              Troop
                            </th>
                            <th
                              style={{ background: "rgba(0,0,0,.3)" }}
                              scope="col"
                            >
                              Level
                            </th>
                            <th
                              style={{ background: "rgba(0,0,0,.3)" }}
                              scope="col"
                            >
                              Order
                            </th>
                            <th
                              style={{ background: "rgba(0,0,0,.3)" }}
                              scope="col"
                            >
                              Quantity
                            </th>
                            <th
                              style={{ background: "rgba(0,0,0,.3)" }}
                              scope="col"
                            >
                              Price
                            </th>
                            <th
                              style={{ background: "rgba(0,0,0,.3)" }}
                              scope="col"
                            >
                              Fill
                            </th>
                            <th
                              style={{ background: "rgba(0,0,0,.3)" }}
                              scope="col"
                            >
                              Order Date
                            </th>
                            <th
                              style={{
                                borderRadius: "0 0 5px 0 ",
                                background: "rgba(0,0,0,.3)",
                              }}
                              scope="col"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {myOrders?.length === 0 && (
                            <tr>
                              <td colSpan={8}>
                                <div className="text-center py-3 dark-yellow">
                                  You haven't booked any orders yet
                                </div>
                              </td>
                            </tr>
                          )}
                          {myOrders?.map((item) => {
                            const relatedTroop = troops?.find(
                              (troop) => troop.id === item?.itemId
                            );
                            return (
                              <tr>
                                <td>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>{relatedTroop?.name}</Tooltip>
                                    }
                                  >
                                    <div
                                      style={{
                                        background: "#25221a",
                                        borderRadius: "50%",
                                        width: "fit-content",
                                        overflow: "hidden",
                                        padding: "3px",
                                      }}
                                    >
                                      <img
                                        width={45}
                                        height={45}
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        src={relatedTroop?.icon}
                                      />
                                    </div>
                                  </OverlayTrigger>
                                </td>
                                <td>
                                  <button
                                    style={{
                                      width: 30,
                                      height: 35,
                                      backgroundSize: "auto 100%",
                                    }}
                                    className="level-btn fs-big fw-bold text-light"
                                  >
                                    {item?.itemLevel}
                                  </button>
                                </td>
                                <td
                                  style={{ fontSize: 16 }}
                                  className={
                                    item?.orderType == 1
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {item?.orderType == 1 ? "Buy" : "Sell"}
                                </td>
                                <td>{item?.quantity} Unit</td>
                                <td>
                                  {item?.averagePrice || item?.clegPrice} Cleg
                                </td>
                                <td>
                                  {item?.filled} (
                                  {roundFloat(
                                    (item?.filled / item?.quantity) * 100,
                                    0
                                  )}
                                  <small>%</small>)
                                </td>
                                <td>
                                  {moment(new Date(item?.registerDate))?.format(
                                    "MM/DD/YYYY - HH:mm:ss"
                                  )}
                                </td>
                                <td>
                                  <Button
                                    onClick={() =>
                                      setConfirmDialog({
                                        open: true,
                                        id: item?.id?.toString(),
                                        submit: () =>
                                          handleCancelOrder(item?.id),
                                        msg: "Are you sure to cancel your order ?",
                                        title: "Confirmation",
                                      })
                                    }
                                    variant="default"
                                  >
                                    Cancel
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <ReactPaginate
                        className="pagination mt-2 justify-content-center"
                        activeClassName="active-pg"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        onPageChange={(e) => getMyOrders(e.selected + 1)}
                        pageRangeDisplayed={3}
                        pageCount={paginations.total}
                        // renderOnZeroPageCount={null}
                      />
                    </div>
                  </>
                </Tab>
                <Tab eventKey="hisotry" title="Order History">
                  <div style={{ overflow: "auto" }} className="table-box">
                    <table className="table table-borderless m-0 text-white">
                      <thead style={{ borderRadius: 0 }}>
                        <tr>
                          <th
                            style={{
                              borderRadius: "0 0  0 5px",
                              background: "rgba(0,0,0,.3)",
                            }}
                            scope="col"
                          >
                            Troop
                          </th>
                          <th
                            style={{ background: "rgba(0,0,0,.3)" }}
                            scope="col"
                          >
                            Level
                          </th>
                          <th
                            style={{ background: "rgba(0,0,0,.3)" }}
                            scope="col"
                          >
                            Order
                          </th>
                          <th
                            style={{ background: "rgba(0,0,0,.3)" }}
                            scope="col"
                          >
                            Quantity
                          </th>
                          <th
                            style={{ background: "rgba(0,0,0,.3)" }}
                            scope="col"
                          >
                            Price
                          </th>
                          <th
                            style={{ background: "rgba(0,0,0,.3)" }}
                            scope="col"
                          >
                            Fill
                          </th>
                          <th
                            style={{ background: "rgba(0,0,0,.3)" }}
                            scope="col"
                          >
                            Order Date
                          </th>
                          <th
                            style={{ background: "rgba(0,0,0,.3)" }}
                            scope="col"
                          >
                            Fill Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {oldOrders?.length === 0 && (
                          <tr>
                            <td colSpan={8}>
                              <div className="text-center py-3 dark-yellow">
                                You haven't booked any orders yet
                              </div>
                            </td>
                          </tr>
                        )}
                        {oldOrders?.map((item) => {
                          const relatedTroop = troops?.find(
                            (troop) => troop.id === item?.itemId
                          );

                          return (
                            <tr>
                              <td>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>{relatedTroop?.name}</Tooltip>
                                  }
                                >
                                  <div
                                    style={{
                                      background: "#25221a",
                                      borderRadius: "50%",
                                      width: "fit-content",
                                      overflow: "hidden",
                                      padding: "3px",
                                    }}
                                  >
                                    <img
                                      width={45}
                                      height={45}
                                      style={{
                                        borderRadius: "50%",
                                      }}
                                      src={relatedTroop?.icon}
                                    />
                                  </div>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <button
                                  style={{
                                    width: 30,
                                    height: 35,
                                    backgroundSize: "auto 100%",
                                  }}
                                  className="level-btn fs-big fw-bold text-light"
                                >
                                  {item?.itemLevel}
                                </button>
                              </td>
                              <td
                                style={{ fontSize: 16 }}
                                className={
                                  item?.orderType == 1
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {item?.orderType == 1 ? "Buy" : "Sell"}
                              </td>
                              <td>{item?.quantity} Unit</td>
                              <td>
                                {item?.averagePrice || item?.clegPrice} Cleg
                              </td>
                              <td>
                                {item?.filled} (
                                {roundFloat(
                                  (item?.filled / item?.quantity) * 100,
                                  0
                                )}
                                <small>%</small>)
                              </td>
                              <td>
                                {moment(new Date(item?.registerDate))?.format(
                                  "MM/DD/YYYY - HH:mm:ss"
                                )}
                              </td>
                              <td>
                                {moment(new Date(item?.lastUpdate))?.format(
                                  "MM/DD/YYYY - HH:mm:ss"
                                )}
                              </td>
                            </tr>
                          );
                        })}{" "}
                      </tbody>
                    </table>
                    <ReactPaginate
                      className="pagination mt-2 justify-content-center"
                      activeClassName="active-pg"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      onPageChange={(e) => getOldOrders(e.selected + 1)}
                      pageRangeDisplayed={3}
                      pageCount={paginations.total}
                      // renderOnZeroPageCount={null}
                    />
                  </div>
                </Tab>
              </Tabs>
            </MidBox>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default TroopOrderBook;
