import React from 'react'
import { ModelData } from '../types'
import { Assets } from '../../constants/assets'

type Props = {}


const models: Record<number, ModelData> = {
    1: {
        id: 1,
        src: Assets.models.warrior,
        name: "Warrior",
        defaultScale: 1,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    2: {
        id: 2,
        // src: Assets.models.archer,
        src: Assets.models.archer,
        name: "Archer",
        defaultScale: 1.2,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    3: {
        id: 3,
        src: Assets.models.guardian,
        name: "Guardian",
        defaultScale: .8,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    4: {
        id: 4,
        src: Assets.models.knight,
        name: "Knight",
        defaultScale: 1,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    5: {
        id: 5,
        src: Assets.models.mage,
        name: "Mage",
        defaultScale: 1,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    6: {
        id: 6,
        src: Assets.models.catapult,
        name: "Catapult",
        defaultScale: 1,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    7: {
        id: 7,
        src: Assets.models.dragon,
        name: "Dragon",
        defaultScale: .7,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    8: {
        id: 8,
        src: Assets.models.goblin,
        name: "Goblin",
        defaultScale: 1,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    10: {
        id: 10,
        src: Assets.models.bigJack,
        name: "Big Jack",
        defaultScale: 1,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    11: {
        id: 11,
        src: Assets.models.redHunter,
        name: "Red Hunter",
        defaultScale: 1,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    12: {
        id: 12,
        src: Assets.models.captain,
        name: "Captain Leroy",
        defaultScale: 1,
        animations: [
            { name: "Idle", fps: 30, loop: true, duration: 1, },
            { name: "Attack", fps: 30, loop: false, duration: 1, },
        ]
    },
    

}

export default models