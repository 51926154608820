import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MidBox from "../components/Boxes/MidBox";
import { roundFloat } from "../utils/round-float";
import Network from "../utils/Network";
import { motion } from "framer-motion";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TipList from "../components/TipList";
import Button from "../components/Button";
import { isMobile } from "react-device-detect";
import Dialog from "../components/Dialog";
import useToast from "../hooks/useToast";
import { Form, Pagination } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import CircularProgress from "../components/CircularProgress";
import { Helmet } from "react-helmet";

type Props = {};
export const TxTypes = {
  1: "Iron",
  2: "Stone",
  3: "Cleg",
};

const TransactionHistory = (props: Props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginations, setPaginations] = useState({
    page: 1,
    count: 10,
    total: 1,
  });
  const [markLoading, setMarkLoading] = useState(false);
  const userInfo = useSelector((state: any) => state.userInfo);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState(3);
  const [tag, setTag] = useState(0);
  const [tags, setTags] = useState<any>({});
  const [sum, setSum] = useState(0);
  const [detailDialog, setDetailDialog] = useState({
    open: false,
    data: {},
  });
  const openToast = useToast();
  const axios = new Network();
  const navigate = useNavigate();
  const getTags = async () => {
    const response = await axios.CallApi({
      url: "/playertransaction/tags",
      method: "get",
    });
    setTags(response.data);
  };
  const getPolls = async (page = 1) => {
    setSum(0);
    const response = await axios.CallApi({
      url:
        "/playertransaction/list?count=" +
        15 +
        `&page=${page}&type=${type}&tag=${tag}&fromDate=${startDate ? new Date(startDate)?.toISOString() : ""
        }&toDate=${endDate ? new Date(endDate)?.toISOString() : ""}`,
      method: "get",
    });
    if (response.ok) {
      setData(response?.data?.data || []);
      setSum(response?.data?.sum);
      setPaginations({
        total: Math.ceil(response.data.totalCount / 15),
        page,
        count: 15,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    setLoading(true);
    getPolls();
  }, [startDate, endDate, tag, type]);

  return (
    <SkeletonTheme baseColor="#2d271b" highlightColor="#3e382b">
      <Helmet>
        <title key={'title'}>
          Transactions History | Chain Of Legends
        </title>
      </Helmet>
      <motion.div
        exit={{ opacity: 0, y: 200 }}
        transition={{ duration: 0.4 }}
        className="row"
        initial={isMobile ? undefined : { opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <MidBox modal title="Transactions">
          <div className="row px-2 px-md-3 align-items-end">
            <div className="col-6  col-md-4 col-lg-3 col-xl-2 ">
              <label className="mb-2 dark-yellow">Type:</label>
              <Form.Select
                value={type}
                onChange={(e: any) => setType(e.target.value)}
                aria-label="Default select example"
              >
                <option value={0}>{"All"}</option>
                {Object.entries(TxTypes)?.map(([key, title]) => (
                  <option key={key} value={key}>
                    {title}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-6  col-md-4 col-lg-3 col-xl-2  ">
              <label className="mb-2 dark-yellow">Tag:</label>
              <Form.Select
                value={tag}
                onChange={(e: any) => setTag(e.target.value)}
                aria-label="Default select example"
              >
                <option value={0}>{"All"}</option>
                {Object.entries(tags)?.map(([key, title]) => (
                  <option key={key} value={key}>
                    {title}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-6 col-md-4 col-lg-3 ">
              <label className="mb-2 mt-2 mt-md-0 dark-yellow">
                Starting Date:
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="col-6 col-md-4 col-lg-3 ">
              <label className="mb-2 mt-2 mt-md-0 dark-yellow">
                Ending Date :
              </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            <div
              style={{ height: "77px" }}
              className="col-12 col-md-4 col-lg-3 col-xl-2  align-items-end"
            >
              <div className="dark-yellow mt-3 mt-md-0">
                Total :{" "}
                {loading ? (
                  <CircularProgress style={{ opacity: 0.7, width: 20 }} />
                ) : (
                  roundFloat(sum) + " " + (TxTypes[type] || "")
                )}
              </div>
              <Button
                onClick={() => {
                  setTag(0);
                  setType(3);
                  setEndDate("");
                  setStartDate("");
                }}
                style={{ margin: 0 }}
                variant="wide"
              >
                Clear Filters
              </Button>
            </div>
          </div>

          <div
            style={{ overflow: "auto" }}
            className="pe-1 pt-3 pb-5 ps-1 pe-sm-3 ps-sm-3 pb-4 table-box"
          >
            <table className="table table-borderless m-0 text-white">
              <thead>
                <tr>
                  <th scope="col">Description</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Tag</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                {loading &&
                  [1, 2, 3].map((item) => (
                    <tr key={"loading" + item} className="pointer">
                      <td width={"50%"}>
                        <Skeleton width={"100%"} />
                      </td>
                      <td>
                        <Skeleton width={"60px"} />
                      </td>
                      <td>
                        <Skeleton width={"60px"} />
                      </td>
                      <td>
                        <Skeleton width={"100px"} height={25} />
                      </td>
                    </tr>
                  ))}
                {data.length === 0 && !loading && (
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        textAlign: "center",
                        paddingTop: 40,
                        fontSize: 20,
                        paddingBottom: 40,
                      }}
                      className="dark-yellow"
                    >
                      You have no transaction here ...
                    </td>
                  </tr>
                )}
                {!loading &&
                  data?.map((item: any, key) => (
                    <>
                      <tr
                        className="pointer"
                        // onClick={() => {
                        //     setDetailDialog({
                        //         open: true,
                        //         data: item
                        //     });
                        //     MarkAsRead(item.id)
                        // }}
                        style={{ opacity: !item?.isRead ? 1 : 0.7 }}
                        key={"row" + key}
                      >
                        <td style={{ minWidth: 200 }}>
                          <span className="fs-big">{item?.description}</span>
                        </td>
                        <td>
                          <span
                            style={{
                              color:
                                item?.count > 0
                                  ? "var(--theme-color-green)"
                                  : item?.count < 0
                                    ? "var(--theme-color-red)"
                                    : "",
                            }}
                            className="fs-big"
                          >
                            {item?.count > 0 && "+"}
                            {roundFloat(item.count, 4)} {TxTypes[item.type]}
                          </span>
                        </td>
                        <td style={{ minWidth: 200 }}>
                          <span className="fs-big">{tags[item?.tag]}</span>
                        </td>
                        <td>
                          {moment(new Date(item?.registerDate)).format(
                            "HH:mm - MM/DD/YYYY"
                          )}
                        </td>
                      </tr>
                      {/* {isMobile && <tr
                                            style={{
                                                opacity: !item?.isRead ? 1 : 0.7
                                            }}
                                        >
                                            <td>
                                                <Button

                                                    // onClick={() => {
                                                    //     setDetailDialog({
                                                    //         open: true,
                                                    //         data: item
                                                    //     });
                                                    // }}
                                                    variant='default'>
                                                    Open
                                                </Button>
                                            </td>
                                        </tr>
                                        } */}
                    </>
                  ))}
              </tbody>
            </table>
            <br />
            <ReactPaginate
              className="pagination justify-content-center"
              activeClassName="active-pg"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              breakClassName="page-item"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              previousClassName="page-item"
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              onPageChange={(e) => getPolls(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={paginations.total}
            // renderOnZeroPageCount={null}
            />
          </div>
        </MidBox>
      </motion.div>
      {/* <Dialog title="Detail" open={detailDialog.open} onClose={() => setDetailDialog((prev) => ({ ...prev, open: false }))}>
                <div className='row flex-column'>
                    <div className='d-flex'>


                    </div>
                </div>
                {detailDialog?.data?.message}
            </Dialog> */}
    </SkeletonTheme>
  );
};

export default TransactionHistory;
