import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useToast from "../hooks/useToast";
import ErrorBoundary from "./ErrorBoundary";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "../components/Button";
import { Helmet } from "react-helmet";
import { SkeletonTheme } from "react-loading-skeleton";
import { isMobile } from "react-device-detect";
import HolloweenBoard from "../components/HolloweenBoard";

type Props = {
  children: React.ReactNode;
};

const AuthLayout = ({ children }: Props) => {
  const searchParams = useSearchParams();
  const openToast = useToast();

  return (

    <SkeletonTheme enableAnimation={!isMobile} baseColor="#3e382b" highlightColor="#474132">
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js?render=6LfdbIYoAAAAACravcE9SShwtp3LI7EMz5rH4gMa"></script>
        </Helmet>
        {/* <HolloweenBoard/> */}
        <Navbar sticky="top" expand="lg" className="transparent w-100 position-absolute">
          <Container>
            <a href="https://chainoflegends.com">
              <Navbar.Text className="text-white d-flex align-items-center text-shadow bold">
                <img
                  width={33}
                  className="me-2"
                  src="/assets/images/icons/home.png"
                />
                Back To Site
              </Navbar.Text>
            </a>
            <div className="d-none d-md-block logo-auth">
              <img width={60} src="/assets/images/chainoflegends-logo.png" />
            </div>
            <div>
              <a target="_blank" href="https://whitepaper.chainoflegends.com">
                <Button variant="wide" className="px-1">
                  <img
                    width={20}
                    className="me-2"
                    src="/assets/images/icons/whitepaper.png"
                  />
                  WhitePaper
                </Button>
              </a>
            </div>
          </Container>
        </Navbar>
        <div className="main-layout pb-5 p-relative flex-column justify-content-center d-flex align-items-center pt-5">
          <ErrorBoundary openToast={openToast}>{children}</ErrorBoundary>
        </div>
      </SkeletonTheme>

  );
};

export default AuthLayout;
