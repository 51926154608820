import { initialRates } from "../rates/reducer";

export const actionTypes = {
    APPEND_SCOUT: 'APPEND_SCOUT',
    CLEAR_SCOUTS: 'CLEAR_SCOUTS',

};

export const appendScout = (payload:typeof initialRates) => ({
    type: actionTypes.APPEND_SCOUT,
    payload,
});

export const clearScoutsData = () => ({
    type: actionTypes.CLEAR_SCOUTS,
});