import { actionTypes } from './action';

export const userDefaultSettings={
    backgroundMusicVolume:2,
    soundEffects:5,
    notification:false
}

export const userSettingsReducer = (state = userDefaultSettings, action:any) => {
    switch (action.type) {
        case actionTypes.SET_SETTINGS:
            return action.payload;
        default:
            return state;
    }
};
