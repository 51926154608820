import React, { useRef } from 'react'
import { useSelector } from 'react-redux';
import { roundFloat } from '../utils/round-float';

type Props = {}

const useSounds = () => {
    var AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    let audioCtx = useRef(new AudioContext())?.current
    const userSettings = useSelector((state: any) => state.userSettings);
    function initSound(audioBuffer?: any) {
        const gainNode = audioCtx.createGain();
        const bufferSource = audioCtx.createBufferSource();
        gainNode.gain.value = +roundFloat(userSettings?.soundEffects / 10, 1);
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(gainNode).connect(audioCtx.destination);
        bufferSource.start(0);
    }

    function playSound(name) {
        if (userSettings?.soundEffects > 0) {
            fetch(`/sounds/${name}.sfxc`)
                .then((resp) => resp.arrayBuffer())
                .then((arrayBuffer) => audioCtx.decodeAudioData(arrayBuffer))
                .then((audioBuffer) => {
                    initSound(audioBuffer);
                });
        }
    }


    return { playSound }

}

export default useSounds