import React, { useEffect, useMemo, useState } from "react";
import CircularProgress from "../../../components/CircularProgress";
import { useConnector } from "../../connector/context";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProfileURL } from "../../../utils/profile-url";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useBattleContext } from "../../context";
import EmptyState from "../../../components/EmptyState";
import { Assets } from "../../../constants/assets";
import Button from "../../../components/Button";

type Props = {};
const timeOut = 10000;
const JoinBattle = (props: Props) => {
  const { connector } = useConnector();
  const { gameInfo } = useBattleContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const userInfo = useSelector((state: any) => state.userInfo);
  const id = searchParams.get("id");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);
    }, timeOut);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (
      id &&
      connector &&
      connector.connection?.state === HubConnectionState.Connected &&
      gameInfo
    ) {
      connector.joinMatch(
        id,
        userInfo.username,
        getProfileURL(userInfo.id, userInfo.photoFile)
      );
    }
  }, [id, connector?.connection, gameInfo]);
  return (
    <div
      style={{ minHeight: "60vh" }}
      className="d-flex flex-column w-100  justify-content-center align-items-center "
    >
      {error ? (
        <EmptyState
          imageSrc={Assets.placeholder.brokenLink}
          className="text-white bg-3 p-2 rounded"
          imageProps={{ className: "img-fluid", width: 250 }}
          descriptionProps={{ className: "text-white" }}
          title="Battle Timed Out"
          description="The link you have provided is not valid or has expired. Please try again."
          action={
            <Button
              onClick={() => navigate("/arena", { replace: true })}
              variant="default"
            >
              Back
            </Button>
          }
        />
      ) : (
        <>
          <CircularProgress style={{ width: "30px", height: "30px" }} />
          <div className="fs-lg mt-2 text-center text-white">Please Wait</div>
        </>
      )}
    </div>
  );
};

export default JoinBattle;
