import React, { useEffect } from 'react'
import Network from '../../utils/Network'
import { ItemType, PlayerItemType } from '../../types/items'
import ItemCard from '../../components/ItemCard'
import { Link } from 'react-router-dom'
import Slider from 'rc-slider'
import { roundFloat } from '../../utils/round-float'
import { SkeletonTheme } from 'react-loading-skeleton'
import { isMobile } from 'react-device-detect'

type Props = {}

const Items = (props: Props) => {
    const axios = new Network()
    const [items, setItems] = React.useState<PlayerItemType[]>()
    const [level, setLevel] = React.useState<number>(1)
    const [loading, setLoading] = React.useState<boolean>(false)
    const handleGetData = async () => {
        setLoading(true)
        const response = await axios.CallApi({
            url: '/item',
            method: 'get'
        })
        setItems(response.data)
        setLoading(false)

    }
    useEffect(() => {
        handleGetData()
    }, [])
    return (
        <SkeletonTheme enableAnimation={!isMobile} baseColor="#352d22" highlightColor="#3e362d">
            <div>
                <div className='mt-4 text-secondary text-center'>
                    You can use Items on your troops to increase their damage, health, armor, and magic resistance. Items are obtainable
                    from
                    <Link to="/marketplace"> Marketplace
                    </Link> and
                    <Link to="/treasure/select"> Treasure Islands</Link>.
                </div>
                <div style={{ maxWidth: 600 }} className='w-100 mb-5 mt-4 mx-auto' >

                    <Slider
                        step={1}
                        value={level}
                        onChange={(val) => setLevel(val as number)}
                        max={8}
                        min={1}
                        marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, }}
                    />
                </div>
                <div className='row align-items-center justify-content-center mt-2'>
                    {loading && [1, 2, 3, 4, 5, 6, 7, 8]?.map((item) => (<div className='col-12 mt-2 text-white  col-xl-3 col-lg-4 col-sm-6 '>
                        <ItemCard
                        
                            loading={loading}
                        />
                    </div>
                    ))}
                    {!loading && items?.map((item) => (<div className='col-12 mt-2 text-white col-xl-3 col-lg-4 col-sm-6 '>
                        <ItemCard
                            data={{
                                ...item,
                                item: item as any,
                                image: `https://core.chainoflegends.com/assets/items/item${item.id}-level${level}.png`,
                                level,
                                bonusValue:+roundFloat(Math.pow(1.5, (level - 1)) * item.bonusValue, 0)
                            }}
                            loading={false}
                            noAction
                        />
                    </div>
                    ))}

                </div>
            </div>
        </SkeletonTheme>
    )
}

export default Items