import { actionTypes } from './action';

export const effectsDefaultState = {
    confetti: false
};

export const EffectsReducer = (state = effectsDefaultState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_EFFECTS:
            return action.payload;
        default:
            return state;
    }
};
