import React from "react";
import { Helmet } from "react-helmet";
import LearnTabs from "./Tabs";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CustomIcon } from "../../components/Icons";

type Props = {};

const Index = (props: Props) => {
  const userInfo = useSelector((state: any) => state.userInfo)
  return (
    <div className="main-layout">
      <Helmet>
        <style>
          {`
                    .main-layout {
                        background: url('/assets/images/backgrounds/main-bg.jpg');
                        background-size:cover
                     }  
            `}
        </style>
      </Helmet>
      <div className="container">
        <div className="d-flex justify-content-between dark-yellow bold mt-2  py-2">
          <Link className="dark-yellow bold " to="/">
            <img width={35}
              height={35}
              src="/assets/images/icons/home.png" className='me-2' />
            Home
          </Link>
          {!userInfo &&
            <Link className="dark-yellow bold " to="/login">
              Login
              <CustomIcon style={{ transform: 'rotate(180deg)' }} icon="exit" />

            </Link>}
        </div>
        <LearnTabs />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Index;
