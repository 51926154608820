import { actionTypes } from './action';

export const defaultBuilding=null;

export const buildingReducer = (state = defaultBuilding, action:any) => {
    switch (action.type) {
        case actionTypes.SET_BUILDING_DATA:
            return action.payload;
        case actionTypes.REMOVE_BUILDING_DATA:
            return null;
        default:
            return state;
    }
};

