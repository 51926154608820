import Decimal from "decimal.js-light";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import CountUp from "react-countup";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MidBox from "../../components/Boxes/MidBox";
import Button from "../../components/Button";
import Dialog from "../../components/Dialog";
import useDebounce from "../../hooks/useDebounce";
import useToast from "../../hooks/useToast";
import { setUserInfo } from "../../redux/user/action";
import Network from "../../utils/Network";
import { roundFloat } from "../../utils/round-float";

const WithdrawCard = () => {
  const userInfo = useSelector((state: any) => state.userInfo);

  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [calculatedAmountWithdrawFee, setCalculatedAmountWithdrawFee] =
    useState<number>(0);
  const [calculatedAmountNetCleg, setCalculatedAmountNetCleg] =
    useState<number>(0);
  const [btnLoading, setBtnLoading] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    submit: () => {},
    title: "",
    msg: "",
  });

  const dispatch = useDispatch();
  const openToast = useToast();
  const axios = new Network();

  const debouncedWithdrawAmount = useDebounce(withdrawAmount ?? "", 500);
  useEffect(() => {
    getClegWithdrawFee(debouncedWithdrawAmount);
    getNetCleg(debouncedWithdrawAmount);
  }, [debouncedWithdrawAmount]);

  const getClegWithdrawFee = (amount: number | string) => {
    try {
      const calculatedAmount = new Decimal(0)
        .plus(new Decimal(amount).times(5).dividedBy(100).toNumber())
        .plus(100)
        .toNumber();
      setCalculatedAmountWithdrawFee(
        calculatedAmount >= 0 ? calculatedAmount : 0
      );
    } catch (e) {
      setCalculatedAmountWithdrawFee(0);
    }
  };

  function getNetCleg(amount: number | string) {
    try {
      const calculatedAmount = new Decimal(amount)
        .minus(new Decimal(amount).times(5).dividedBy(100).toNumber())
        .minus(100)
        .toNumber();
      setCalculatedAmountNetCleg(calculatedAmount >= 0 ? calculatedAmount : 0);
    } catch (e) {
      setCalculatedAmountNetCleg(0);
    }
  }

  const getData = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };

  async function Withdraw(amount: string) {
    setBtnLoading("withdraw");
    const response = await axios.CallApi({
      url: "/player/withdraw",
      body: {
        amount,
      },
      method: "post",
    });
    if (response.ok) {
      if (!response.data.message)
        response.data.message = "Withdraw submitted successfully ";
      getData();
    }
    openToast(response);
    setBtnLoading("");
  }

  return (
    <motion.div
      initial={isMobile ? undefined : { opacity: 0, x: 800 }}
      transition={{
        duration: 0.3,
        delay: 0.3,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{ opacity: 0, x: 800 }}
      className="col-12 col-md-6"
    >
      <MidBox modal title="Withdraw" className="text-center nineteen-step">
        <div className="mb-2">
          <Image
            className="img-fluid"
            src="assets/images/icons/withdraw.png"
            alt="Withdraw"
          />
        </div>
        <div className="mb-3 prof-step4">
          <div className="text-light fw-normal fs-big mb-1">
            In-Game Balance
          </div>
          <div className="t-balance dark-yellow fw-bold fs-lg text-space">
            {userInfo?.totalCleg} CLEG
          </div>
        </div>
        <div className="px-1 prof-step5 px-xl-4 mb-3 text-start">
          <div className="d-flex flex-wrap justify-content-between dark-bg rounded-10 px-3 px-md-4 py-2 mb-2">
            <div>
              <div className="fs-small">Amount(CLEG)</div>
              <input
                onKeyDown={(event) => {
                  if (!/^[0-9.]$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={withdrawAmount}
                placeholder="Enter Amount"
                onChange={(e) => {
                  try {
                    const value = e.target.value;
                    if (value?.split(".")?.length > 2) {
                      return;
                    }
                    setWithdrawAmount(roundFloat(value)?.toString());
                  } catch (e) {
                    return;
                  }
                }}
                type="number"
                className="dark-yellow fw-med fs-med transparent-input"
                min="200"
                max="100000"
                step="0.1"
              />
            </div>
            <Button
              loading={btnLoading === "withdraw"}
              onClick={() => setWithdrawAmount(userInfo?.totalCleg)}
              style={{ marginLeft: "auto" }}
            >
               
              <span className="fs-med pointer fw-normal teal-color text-decoration-underline d-block">
                Total
              </span>
            </Button>
          </div>
          <div className="px-1 px-lg-3 px-lg-4 py-2 mb-2">
            <div className="mb-1 d-flex fs-sm-med justify-content-between">
              <span className="fs-small">Withdraw Fee (5% + 100 CLEG)</span>
              <span className="text-light fs-sm-med fs-med fw-med">
                <CountUp
                  duration={1}
                  decimals={2}
                  end={calculatedAmountWithdrawFee}
                />{" "}
                CLEG
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="fs-small fs-sm-med">You will receive</span>
              <span className="dark-brown fs-sm-med fs-big fw-med text-shadow">
                <CountUp
                  decimals={2}
                  duration={1}
                  end={calculatedAmountNetCleg}
                />{" "}
                CLEG
              </span>
            </div>
          </div>
        </div>
        <Button
          loading={btnLoading === "withdraw"}
          onClick={() =>
            setConfirmDialog({
              open: true,
              title: "Are you sure?",
              msg: `Are you sure to collect ${withdrawAmount} CLEG from your account?`,
              submit: () => {
                setConfirmDialog((prev) => ({ ...prev, open: false }));
                setTimeout(() => {
                  Withdraw(withdrawAmount);
                }, 500);
              },
            })
          }
          variant="wide"
          style={{ width: "240px" }}
          className="mx-auto"
        >
          <>
            <img
              width={30}
              height={30}
              alt={"MetaMask"}
              className="img-fluid"
              src="assets/images/logos/metamask-circle.png"
            />
             <span className="text-light d-block">Withdraw</span>
          </>
        </Button>
        <Link to="/withdraw-history">
          <div className="dark-yellow mt-3">
            <Image
              width={20}
              height={20}
              className="img-fluid"
              src="assets/images/icons/withdraw-tx.svg"
              alt="Withdraw History"
            />
              Withdraw History
          </div>
        </Link>
        <Dialog
          loading={btnLoading === "withdraw"}
          title={confirmDialog?.title}
          closeLabel="No"
          submitLabel="Yes"
          onSubmit={confirmDialog?.submit}
          open={confirmDialog?.open}
          onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
        >
          {confirmDialog?.msg}
        </Dialog>
      </MidBox>
    </motion.div>
  );
};
export default WithdrawCard;
