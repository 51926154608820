import React from "react";

type Props = {};

const ShopBoxEmptyState = (props: Props) => {
  return (
    <div className="dark-yellow text-center">
      There is no shop data yet!
      <br />
      Stay tuned for updates!
    </div>
  );
};

export default ShopBoxEmptyState;
